/* purgecss start ignore */

/*@import "tailwindcss/base";*/

.builder-container{
  width: 100%;
}

@media (min-width: 640px){
  .builder-container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .builder-container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .builder-container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .builder-container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){
  .builder-container{
    max-width: 1536px;
  }
}

/* purgecss end ignore */

.builder-sr-only{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.builder-not-sr-only{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.focus-within\:builder-sr-only:focus-within{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.focus-within\:builder-not-sr-only:focus-within{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.focus\:builder-sr-only:focus{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.focus\:builder-not-sr-only:focus{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.builder-pointer-events-none{
  pointer-events: none !important;
}

.builder-pointer-events-auto{
  pointer-events: auto !important;
}

.builder-visible{
  visibility: visible !important;
}

.builder-invisible{
  visibility: hidden !important;
}

.builder-static{
  position: static !important;
}

.builder-fixed{
  position: fixed !important;
}

.builder-absolute{
  position: absolute !important;
}

.builder-relative{
  position: relative !important;
}

.builder-sticky{
  position: sticky !important;
}

.builder-inset-0{
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.builder-inset-1{
  top: 0.25rem !important;
  right: 0.25rem !important;
  bottom: 0.25rem !important;
  left: 0.25rem !important;
}

.builder-inset-2{
  top: 0.5rem !important;
  right: 0.5rem !important;
  bottom: 0.5rem !important;
  left: 0.5rem !important;
}

.builder-inset-3{
  top: 0.75rem !important;
  right: 0.75rem !important;
  bottom: 0.75rem !important;
  left: 0.75rem !important;
}

.builder-inset-4{
  top: 1rem !important;
  right: 1rem !important;
  bottom: 1rem !important;
  left: 1rem !important;
}

.builder-inset-5{
  top: 1.25rem !important;
  right: 1.25rem !important;
  bottom: 1.25rem !important;
  left: 1.25rem !important;
}

.builder-inset-6{
  top: 1.5rem !important;
  right: 1.5rem !important;
  bottom: 1.5rem !important;
  left: 1.5rem !important;
}

.builder-inset-7{
  top: 1.75rem !important;
  right: 1.75rem !important;
  bottom: 1.75rem !important;
  left: 1.75rem !important;
}

.builder-inset-8{
  top: 2rem !important;
  right: 2rem !important;
  bottom: 2rem !important;
  left: 2rem !important;
}

.builder-inset-9{
  top: 2.25rem !important;
  right: 2.25rem !important;
  bottom: 2.25rem !important;
  left: 2.25rem !important;
}

.builder-inset-10{
  top: 2.5rem !important;
  right: 2.5rem !important;
  bottom: 2.5rem !important;
  left: 2.5rem !important;
}

.builder-inset-11{
  top: 2.75rem !important;
  right: 2.75rem !important;
  bottom: 2.75rem !important;
  left: 2.75rem !important;
}

.builder-inset-12{
  top: 3rem !important;
  right: 3rem !important;
  bottom: 3rem !important;
  left: 3rem !important;
}

.builder-inset-14{
  top: 3.5rem !important;
  right: 3.5rem !important;
  bottom: 3.5rem !important;
  left: 3.5rem !important;
}

.builder-inset-16{
  top: 4rem !important;
  right: 4rem !important;
  bottom: 4rem !important;
  left: 4rem !important;
}

.builder-inset-20{
  top: 5rem !important;
  right: 5rem !important;
  bottom: 5rem !important;
  left: 5rem !important;
}

.builder-inset-24{
  top: 6rem !important;
  right: 6rem !important;
  bottom: 6rem !important;
  left: 6rem !important;
}

.builder-inset-28{
  top: 7rem !important;
  right: 7rem !important;
  bottom: 7rem !important;
  left: 7rem !important;
}

.builder-inset-32{
  top: 8rem !important;
  right: 8rem !important;
  bottom: 8rem !important;
  left: 8rem !important;
}

.builder-inset-36{
  top: 9rem !important;
  right: 9rem !important;
  bottom: 9rem !important;
  left: 9rem !important;
}

.builder-inset-40{
  top: 10rem !important;
  right: 10rem !important;
  bottom: 10rem !important;
  left: 10rem !important;
}

.builder-inset-44{
  top: 11rem !important;
  right: 11rem !important;
  bottom: 11rem !important;
  left: 11rem !important;
}

.builder-inset-48{
  top: 12rem !important;
  right: 12rem !important;
  bottom: 12rem !important;
  left: 12rem !important;
}

.builder-inset-52{
  top: 13rem !important;
  right: 13rem !important;
  bottom: 13rem !important;
  left: 13rem !important;
}

.builder-inset-56{
  top: 14rem !important;
  right: 14rem !important;
  bottom: 14rem !important;
  left: 14rem !important;
}

.builder-inset-60{
  top: 15rem !important;
  right: 15rem !important;
  bottom: 15rem !important;
  left: 15rem !important;
}

.builder-inset-64{
  top: 16rem !important;
  right: 16rem !important;
  bottom: 16rem !important;
  left: 16rem !important;
}

.builder-inset-72{
  top: 18rem !important;
  right: 18rem !important;
  bottom: 18rem !important;
  left: 18rem !important;
}

.builder-inset-80{
  top: 20rem !important;
  right: 20rem !important;
  bottom: 20rem !important;
  left: 20rem !important;
}

.builder-inset-96{
  top: 24rem !important;
  right: 24rem !important;
  bottom: 24rem !important;
  left: 24rem !important;
}

.builder-inset-auto{
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.builder-inset-px{
  top: 1px !important;
  right: 1px !important;
  bottom: 1px !important;
  left: 1px !important;
}

.builder-inset-0\.5{
  top: 0.125rem !important;
  right: 0.125rem !important;
  bottom: 0.125rem !important;
  left: 0.125rem !important;
}

.builder-inset-1\.5{
  top: 0.375rem !important;
  right: 0.375rem !important;
  bottom: 0.375rem !important;
  left: 0.375rem !important;
}

.builder-inset-2\.5{
  top: 0.625rem !important;
  right: 0.625rem !important;
  bottom: 0.625rem !important;
  left: 0.625rem !important;
}

.builder-inset-3\.5{
  top: 0.875rem !important;
  right: 0.875rem !important;
  bottom: 0.875rem !important;
  left: 0.875rem !important;
}

.builder--inset-0{
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.builder--inset-1{
  top: -0.25rem !important;
  right: -0.25rem !important;
  bottom: -0.25rem !important;
  left: -0.25rem !important;
}

.builder--inset-2{
  top: -0.5rem !important;
  right: -0.5rem !important;
  bottom: -0.5rem !important;
  left: -0.5rem !important;
}

.builder--inset-3{
  top: -0.75rem !important;
  right: -0.75rem !important;
  bottom: -0.75rem !important;
  left: -0.75rem !important;
}

.builder--inset-4{
  top: -1rem !important;
  right: -1rem !important;
  bottom: -1rem !important;
  left: -1rem !important;
}

.builder--inset-5{
  top: -1.25rem !important;
  right: -1.25rem !important;
  bottom: -1.25rem !important;
  left: -1.25rem !important;
}

.builder--inset-6{
  top: -1.5rem !important;
  right: -1.5rem !important;
  bottom: -1.5rem !important;
  left: -1.5rem !important;
}

.builder--inset-7{
  top: -1.75rem !important;
  right: -1.75rem !important;
  bottom: -1.75rem !important;
  left: -1.75rem !important;
}

.builder--inset-8{
  top: -2rem !important;
  right: -2rem !important;
  bottom: -2rem !important;
  left: -2rem !important;
}

.builder--inset-9{
  top: -2.25rem !important;
  right: -2.25rem !important;
  bottom: -2.25rem !important;
  left: -2.25rem !important;
}

.builder--inset-10{
  top: -2.5rem !important;
  right: -2.5rem !important;
  bottom: -2.5rem !important;
  left: -2.5rem !important;
}

.builder--inset-11{
  top: -2.75rem !important;
  right: -2.75rem !important;
  bottom: -2.75rem !important;
  left: -2.75rem !important;
}

.builder--inset-12{
  top: -3rem !important;
  right: -3rem !important;
  bottom: -3rem !important;
  left: -3rem !important;
}

.builder--inset-14{
  top: -3.5rem !important;
  right: -3.5rem !important;
  bottom: -3.5rem !important;
  left: -3.5rem !important;
}

.builder--inset-16{
  top: -4rem !important;
  right: -4rem !important;
  bottom: -4rem !important;
  left: -4rem !important;
}

.builder--inset-20{
  top: -5rem !important;
  right: -5rem !important;
  bottom: -5rem !important;
  left: -5rem !important;
}

.builder--inset-24{
  top: -6rem !important;
  right: -6rem !important;
  bottom: -6rem !important;
  left: -6rem !important;
}

.builder--inset-28{
  top: -7rem !important;
  right: -7rem !important;
  bottom: -7rem !important;
  left: -7rem !important;
}

.builder--inset-32{
  top: -8rem !important;
  right: -8rem !important;
  bottom: -8rem !important;
  left: -8rem !important;
}

.builder--inset-36{
  top: -9rem !important;
  right: -9rem !important;
  bottom: -9rem !important;
  left: -9rem !important;
}

.builder--inset-40{
  top: -10rem !important;
  right: -10rem !important;
  bottom: -10rem !important;
  left: -10rem !important;
}

.builder--inset-44{
  top: -11rem !important;
  right: -11rem !important;
  bottom: -11rem !important;
  left: -11rem !important;
}

.builder--inset-48{
  top: -12rem !important;
  right: -12rem !important;
  bottom: -12rem !important;
  left: -12rem !important;
}

.builder--inset-52{
  top: -13rem !important;
  right: -13rem !important;
  bottom: -13rem !important;
  left: -13rem !important;
}

.builder--inset-56{
  top: -14rem !important;
  right: -14rem !important;
  bottom: -14rem !important;
  left: -14rem !important;
}

.builder--inset-60{
  top: -15rem !important;
  right: -15rem !important;
  bottom: -15rem !important;
  left: -15rem !important;
}

.builder--inset-64{
  top: -16rem !important;
  right: -16rem !important;
  bottom: -16rem !important;
  left: -16rem !important;
}

.builder--inset-72{
  top: -18rem !important;
  right: -18rem !important;
  bottom: -18rem !important;
  left: -18rem !important;
}

.builder--inset-80{
  top: -20rem !important;
  right: -20rem !important;
  bottom: -20rem !important;
  left: -20rem !important;
}

.builder--inset-96{
  top: -24rem !important;
  right: -24rem !important;
  bottom: -24rem !important;
  left: -24rem !important;
}

.builder--inset-px{
  top: -1px !important;
  right: -1px !important;
  bottom: -1px !important;
  left: -1px !important;
}

.builder--inset-0\.5{
  top: -0.125rem !important;
  right: -0.125rem !important;
  bottom: -0.125rem !important;
  left: -0.125rem !important;
}

.builder--inset-1\.5{
  top: -0.375rem !important;
  right: -0.375rem !important;
  bottom: -0.375rem !important;
  left: -0.375rem !important;
}

.builder--inset-2\.5{
  top: -0.625rem !important;
  right: -0.625rem !important;
  bottom: -0.625rem !important;
  left: -0.625rem !important;
}

.builder--inset-3\.5{
  top: -0.875rem !important;
  right: -0.875rem !important;
  bottom: -0.875rem !important;
  left: -0.875rem !important;
}

.builder-inset-1\/2{
  top: 50% !important;
  right: 50% !important;
  bottom: 50% !important;
  left: 50% !important;
}

.builder-inset-1\/3{
  top: 33.333333% !important;
  right: 33.333333% !important;
  bottom: 33.333333% !important;
  left: 33.333333% !important;
}

.builder-inset-2\/3{
  top: 66.666667% !important;
  right: 66.666667% !important;
  bottom: 66.666667% !important;
  left: 66.666667% !important;
}

.builder-inset-1\/4{
  top: 25% !important;
  right: 25% !important;
  bottom: 25% !important;
  left: 25% !important;
}

.builder-inset-2\/4{
  top: 50% !important;
  right: 50% !important;
  bottom: 50% !important;
  left: 50% !important;
}

.builder-inset-3\/4{
  top: 75% !important;
  right: 75% !important;
  bottom: 75% !important;
  left: 75% !important;
}

.builder-inset-full{
  top: 100% !important;
  right: 100% !important;
  bottom: 100% !important;
  left: 100% !important;
}

.builder--inset-1\/2{
  top: -50% !important;
  right: -50% !important;
  bottom: -50% !important;
  left: -50% !important;
}

.builder--inset-1\/3{
  top: -33.333333% !important;
  right: -33.333333% !important;
  bottom: -33.333333% !important;
  left: -33.333333% !important;
}

.builder--inset-2\/3{
  top: -66.666667% !important;
  right: -66.666667% !important;
  bottom: -66.666667% !important;
  left: -66.666667% !important;
}

.builder--inset-1\/4{
  top: -25% !important;
  right: -25% !important;
  bottom: -25% !important;
  left: -25% !important;
}

.builder--inset-2\/4{
  top: -50% !important;
  right: -50% !important;
  bottom: -50% !important;
  left: -50% !important;
}

.builder--inset-3\/4{
  top: -75% !important;
  right: -75% !important;
  bottom: -75% !important;
  left: -75% !important;
}

.builder--inset-full{
  top: -100% !important;
  right: -100% !important;
  bottom: -100% !important;
  left: -100% !important;
}

.builder-inset-x-0{
  left: 0px !important;
  right: 0px !important;
}

.builder-inset-x-1{
  left: 0.25rem !important;
  right: 0.25rem !important;
}

.builder-inset-x-2{
  left: 0.5rem !important;
  right: 0.5rem !important;
}

.builder-inset-x-3{
  left: 0.75rem !important;
  right: 0.75rem !important;
}

.builder-inset-x-4{
  left: 1rem !important;
  right: 1rem !important;
}

.builder-inset-x-5{
  left: 1.25rem !important;
  right: 1.25rem !important;
}

.builder-inset-x-6{
  left: 1.5rem !important;
  right: 1.5rem !important;
}

.builder-inset-x-7{
  left: 1.75rem !important;
  right: 1.75rem !important;
}

.builder-inset-x-8{
  left: 2rem !important;
  right: 2rem !important;
}

.builder-inset-x-9{
  left: 2.25rem !important;
  right: 2.25rem !important;
}

.builder-inset-x-10{
  left: 2.5rem !important;
  right: 2.5rem !important;
}

.builder-inset-x-11{
  left: 2.75rem !important;
  right: 2.75rem !important;
}

.builder-inset-x-12{
  left: 3rem !important;
  right: 3rem !important;
}

.builder-inset-x-14{
  left: 3.5rem !important;
  right: 3.5rem !important;
}

.builder-inset-x-16{
  left: 4rem !important;
  right: 4rem !important;
}

.builder-inset-x-20{
  left: 5rem !important;
  right: 5rem !important;
}

.builder-inset-x-24{
  left: 6rem !important;
  right: 6rem !important;
}

.builder-inset-x-28{
  left: 7rem !important;
  right: 7rem !important;
}

.builder-inset-x-32{
  left: 8rem !important;
  right: 8rem !important;
}

.builder-inset-x-36{
  left: 9rem !important;
  right: 9rem !important;
}

.builder-inset-x-40{
  left: 10rem !important;
  right: 10rem !important;
}

.builder-inset-x-44{
  left: 11rem !important;
  right: 11rem !important;
}

.builder-inset-x-48{
  left: 12rem !important;
  right: 12rem !important;
}

.builder-inset-x-52{
  left: 13rem !important;
  right: 13rem !important;
}

.builder-inset-x-56{
  left: 14rem !important;
  right: 14rem !important;
}

.builder-inset-x-60{
  left: 15rem !important;
  right: 15rem !important;
}

.builder-inset-x-64{
  left: 16rem !important;
  right: 16rem !important;
}

.builder-inset-x-72{
  left: 18rem !important;
  right: 18rem !important;
}

.builder-inset-x-80{
  left: 20rem !important;
  right: 20rem !important;
}

.builder-inset-x-96{
  left: 24rem !important;
  right: 24rem !important;
}

.builder-inset-x-auto{
  left: auto !important;
  right: auto !important;
}

.builder-inset-x-px{
  left: 1px !important;
  right: 1px !important;
}

.builder-inset-x-0\.5{
  left: 0.125rem !important;
  right: 0.125rem !important;
}

.builder-inset-x-1\.5{
  left: 0.375rem !important;
  right: 0.375rem !important;
}

.builder-inset-x-2\.5{
  left: 0.625rem !important;
  right: 0.625rem !important;
}

.builder-inset-x-3\.5{
  left: 0.875rem !important;
  right: 0.875rem !important;
}

.builder--inset-x-0{
  left: 0px !important;
  right: 0px !important;
}

.builder--inset-x-1{
  left: -0.25rem !important;
  right: -0.25rem !important;
}

.builder--inset-x-2{
  left: -0.5rem !important;
  right: -0.5rem !important;
}

.builder--inset-x-3{
  left: -0.75rem !important;
  right: -0.75rem !important;
}

.builder--inset-x-4{
  left: -1rem !important;
  right: -1rem !important;
}

.builder--inset-x-5{
  left: -1.25rem !important;
  right: -1.25rem !important;
}

.builder--inset-x-6{
  left: -1.5rem !important;
  right: -1.5rem !important;
}

.builder--inset-x-7{
  left: -1.75rem !important;
  right: -1.75rem !important;
}

.builder--inset-x-8{
  left: -2rem !important;
  right: -2rem !important;
}

.builder--inset-x-9{
  left: -2.25rem !important;
  right: -2.25rem !important;
}

.builder--inset-x-10{
  left: -2.5rem !important;
  right: -2.5rem !important;
}

.builder--inset-x-11{
  left: -2.75rem !important;
  right: -2.75rem !important;
}

.builder--inset-x-12{
  left: -3rem !important;
  right: -3rem !important;
}

.builder--inset-x-14{
  left: -3.5rem !important;
  right: -3.5rem !important;
}

.builder--inset-x-16{
  left: -4rem !important;
  right: -4rem !important;
}

.builder--inset-x-20{
  left: -5rem !important;
  right: -5rem !important;
}

.builder--inset-x-24{
  left: -6rem !important;
  right: -6rem !important;
}

.builder--inset-x-28{
  left: -7rem !important;
  right: -7rem !important;
}

.builder--inset-x-32{
  left: -8rem !important;
  right: -8rem !important;
}

.builder--inset-x-36{
  left: -9rem !important;
  right: -9rem !important;
}

.builder--inset-x-40{
  left: -10rem !important;
  right: -10rem !important;
}

.builder--inset-x-44{
  left: -11rem !important;
  right: -11rem !important;
}

.builder--inset-x-48{
  left: -12rem !important;
  right: -12rem !important;
}

.builder--inset-x-52{
  left: -13rem !important;
  right: -13rem !important;
}

.builder--inset-x-56{
  left: -14rem !important;
  right: -14rem !important;
}

.builder--inset-x-60{
  left: -15rem !important;
  right: -15rem !important;
}

.builder--inset-x-64{
  left: -16rem !important;
  right: -16rem !important;
}

.builder--inset-x-72{
  left: -18rem !important;
  right: -18rem !important;
}

.builder--inset-x-80{
  left: -20rem !important;
  right: -20rem !important;
}

.builder--inset-x-96{
  left: -24rem !important;
  right: -24rem !important;
}

.builder--inset-x-px{
  left: -1px !important;
  right: -1px !important;
}

.builder--inset-x-0\.5{
  left: -0.125rem !important;
  right: -0.125rem !important;
}

.builder--inset-x-1\.5{
  left: -0.375rem !important;
  right: -0.375rem !important;
}

.builder--inset-x-2\.5{
  left: -0.625rem !important;
  right: -0.625rem !important;
}

.builder--inset-x-3\.5{
  left: -0.875rem !important;
  right: -0.875rem !important;
}

.builder-inset-x-1\/2{
  left: 50% !important;
  right: 50% !important;
}

.builder-inset-x-1\/3{
  left: 33.333333% !important;
  right: 33.333333% !important;
}

.builder-inset-x-2\/3{
  left: 66.666667% !important;
  right: 66.666667% !important;
}

.builder-inset-x-1\/4{
  left: 25% !important;
  right: 25% !important;
}

.builder-inset-x-2\/4{
  left: 50% !important;
  right: 50% !important;
}

.builder-inset-x-3\/4{
  left: 75% !important;
  right: 75% !important;
}

.builder-inset-x-full{
  left: 100% !important;
  right: 100% !important;
}

.builder--inset-x-1\/2{
  left: -50% !important;
  right: -50% !important;
}

.builder--inset-x-1\/3{
  left: -33.333333% !important;
  right: -33.333333% !important;
}

.builder--inset-x-2\/3{
  left: -66.666667% !important;
  right: -66.666667% !important;
}

.builder--inset-x-1\/4{
  left: -25% !important;
  right: -25% !important;
}

.builder--inset-x-2\/4{
  left: -50% !important;
  right: -50% !important;
}

.builder--inset-x-3\/4{
  left: -75% !important;
  right: -75% !important;
}

.builder--inset-x-full{
  left: -100% !important;
  right: -100% !important;
}

.builder-inset-y-0{
  top: 0px !important;
  bottom: 0px !important;
}

.builder-inset-y-1{
  top: 0.25rem !important;
  bottom: 0.25rem !important;
}

.builder-inset-y-2{
  top: 0.5rem !important;
  bottom: 0.5rem !important;
}

.builder-inset-y-3{
  top: 0.75rem !important;
  bottom: 0.75rem !important;
}

.builder-inset-y-4{
  top: 1rem !important;
  bottom: 1rem !important;
}

.builder-inset-y-5{
  top: 1.25rem !important;
  bottom: 1.25rem !important;
}

.builder-inset-y-6{
  top: 1.5rem !important;
  bottom: 1.5rem !important;
}

.builder-inset-y-7{
  top: 1.75rem !important;
  bottom: 1.75rem !important;
}

.builder-inset-y-8{
  top: 2rem !important;
  bottom: 2rem !important;
}

.builder-inset-y-9{
  top: 2.25rem !important;
  bottom: 2.25rem !important;
}

.builder-inset-y-10{
  top: 2.5rem !important;
  bottom: 2.5rem !important;
}

.builder-inset-y-11{
  top: 2.75rem !important;
  bottom: 2.75rem !important;
}

.builder-inset-y-12{
  top: 3rem !important;
  bottom: 3rem !important;
}

.builder-inset-y-14{
  top: 3.5rem !important;
  bottom: 3.5rem !important;
}

.builder-inset-y-16{
  top: 4rem !important;
  bottom: 4rem !important;
}

.builder-inset-y-20{
  top: 5rem !important;
  bottom: 5rem !important;
}

.builder-inset-y-24{
  top: 6rem !important;
  bottom: 6rem !important;
}

.builder-inset-y-28{
  top: 7rem !important;
  bottom: 7rem !important;
}

.builder-inset-y-32{
  top: 8rem !important;
  bottom: 8rem !important;
}

.builder-inset-y-36{
  top: 9rem !important;
  bottom: 9rem !important;
}

.builder-inset-y-40{
  top: 10rem !important;
  bottom: 10rem !important;
}

.builder-inset-y-44{
  top: 11rem !important;
  bottom: 11rem !important;
}

.builder-inset-y-48{
  top: 12rem !important;
  bottom: 12rem !important;
}

.builder-inset-y-52{
  top: 13rem !important;
  bottom: 13rem !important;
}

.builder-inset-y-56{
  top: 14rem !important;
  bottom: 14rem !important;
}

.builder-inset-y-60{
  top: 15rem !important;
  bottom: 15rem !important;
}

.builder-inset-y-64{
  top: 16rem !important;
  bottom: 16rem !important;
}

.builder-inset-y-72{
  top: 18rem !important;
  bottom: 18rem !important;
}

.builder-inset-y-80{
  top: 20rem !important;
  bottom: 20rem !important;
}

.builder-inset-y-96{
  top: 24rem !important;
  bottom: 24rem !important;
}

.builder-inset-y-auto{
  top: auto !important;
  bottom: auto !important;
}

.builder-inset-y-px{
  top: 1px !important;
  bottom: 1px !important;
}

.builder-inset-y-0\.5{
  top: 0.125rem !important;
  bottom: 0.125rem !important;
}

.builder-inset-y-1\.5{
  top: 0.375rem !important;
  bottom: 0.375rem !important;
}

.builder-inset-y-2\.5{
  top: 0.625rem !important;
  bottom: 0.625rem !important;
}

.builder-inset-y-3\.5{
  top: 0.875rem !important;
  bottom: 0.875rem !important;
}

.builder--inset-y-0{
  top: 0px !important;
  bottom: 0px !important;
}

.builder--inset-y-1{
  top: -0.25rem !important;
  bottom: -0.25rem !important;
}

.builder--inset-y-2{
  top: -0.5rem !important;
  bottom: -0.5rem !important;
}

.builder--inset-y-3{
  top: -0.75rem !important;
  bottom: -0.75rem !important;
}

.builder--inset-y-4{
  top: -1rem !important;
  bottom: -1rem !important;
}

.builder--inset-y-5{
  top: -1.25rem !important;
  bottom: -1.25rem !important;
}

.builder--inset-y-6{
  top: -1.5rem !important;
  bottom: -1.5rem !important;
}

.builder--inset-y-7{
  top: -1.75rem !important;
  bottom: -1.75rem !important;
}

.builder--inset-y-8{
  top: -2rem !important;
  bottom: -2rem !important;
}

.builder--inset-y-9{
  top: -2.25rem !important;
  bottom: -2.25rem !important;
}

.builder--inset-y-10{
  top: -2.5rem !important;
  bottom: -2.5rem !important;
}

.builder--inset-y-11{
  top: -2.75rem !important;
  bottom: -2.75rem !important;
}

.builder--inset-y-12{
  top: -3rem !important;
  bottom: -3rem !important;
}

.builder--inset-y-14{
  top: -3.5rem !important;
  bottom: -3.5rem !important;
}

.builder--inset-y-16{
  top: -4rem !important;
  bottom: -4rem !important;
}

.builder--inset-y-20{
  top: -5rem !important;
  bottom: -5rem !important;
}

.builder--inset-y-24{
  top: -6rem !important;
  bottom: -6rem !important;
}

.builder--inset-y-28{
  top: -7rem !important;
  bottom: -7rem !important;
}

.builder--inset-y-32{
  top: -8rem !important;
  bottom: -8rem !important;
}

.builder--inset-y-36{
  top: -9rem !important;
  bottom: -9rem !important;
}

.builder--inset-y-40{
  top: -10rem !important;
  bottom: -10rem !important;
}

.builder--inset-y-44{
  top: -11rem !important;
  bottom: -11rem !important;
}

.builder--inset-y-48{
  top: -12rem !important;
  bottom: -12rem !important;
}

.builder--inset-y-52{
  top: -13rem !important;
  bottom: -13rem !important;
}

.builder--inset-y-56{
  top: -14rem !important;
  bottom: -14rem !important;
}

.builder--inset-y-60{
  top: -15rem !important;
  bottom: -15rem !important;
}

.builder--inset-y-64{
  top: -16rem !important;
  bottom: -16rem !important;
}

.builder--inset-y-72{
  top: -18rem !important;
  bottom: -18rem !important;
}

.builder--inset-y-80{
  top: -20rem !important;
  bottom: -20rem !important;
}

.builder--inset-y-96{
  top: -24rem !important;
  bottom: -24rem !important;
}

.builder--inset-y-px{
  top: -1px !important;
  bottom: -1px !important;
}

.builder--inset-y-0\.5{
  top: -0.125rem !important;
  bottom: -0.125rem !important;
}

.builder--inset-y-1\.5{
  top: -0.375rem !important;
  bottom: -0.375rem !important;
}

.builder--inset-y-2\.5{
  top: -0.625rem !important;
  bottom: -0.625rem !important;
}

.builder--inset-y-3\.5{
  top: -0.875rem !important;
  bottom: -0.875rem !important;
}

.builder-inset-y-1\/2{
  top: 50% !important;
  bottom: 50% !important;
}

.builder-inset-y-1\/3{
  top: 33.333333% !important;
  bottom: 33.333333% !important;
}

.builder-inset-y-2\/3{
  top: 66.666667% !important;
  bottom: 66.666667% !important;
}

.builder-inset-y-1\/4{
  top: 25% !important;
  bottom: 25% !important;
}

.builder-inset-y-2\/4{
  top: 50% !important;
  bottom: 50% !important;
}

.builder-inset-y-3\/4{
  top: 75% !important;
  bottom: 75% !important;
}

.builder-inset-y-full{
  top: 100% !important;
  bottom: 100% !important;
}

.builder--inset-y-1\/2{
  top: -50% !important;
  bottom: -50% !important;
}

.builder--inset-y-1\/3{
  top: -33.333333% !important;
  bottom: -33.333333% !important;
}

.builder--inset-y-2\/3{
  top: -66.666667% !important;
  bottom: -66.666667% !important;
}

.builder--inset-y-1\/4{
  top: -25% !important;
  bottom: -25% !important;
}

.builder--inset-y-2\/4{
  top: -50% !important;
  bottom: -50% !important;
}

.builder--inset-y-3\/4{
  top: -75% !important;
  bottom: -75% !important;
}

.builder--inset-y-full{
  top: -100% !important;
  bottom: -100% !important;
}

.builder-top-0{
  top: 0px !important;
}

.builder-top-1{
  top: 0.25rem !important;
}

.builder-top-2{
  top: 0.5rem !important;
}

.builder-top-3{
  top: 0.75rem !important;
}

.builder-top-4{
  top: 1rem !important;
}

.builder-top-5{
  top: 1.25rem !important;
}

.builder-top-6{
  top: 1.5rem !important;
}

.builder-top-7{
  top: 1.75rem !important;
}

.builder-top-8{
  top: 2rem !important;
}

.builder-top-9{
  top: 2.25rem !important;
}

.builder-top-10{
  top: 2.5rem !important;
}

.builder-top-11{
  top: 2.75rem !important;
}

.builder-top-12{
  top: 3rem !important;
}

.builder-top-14{
  top: 3.5rem !important;
}

.builder-top-16{
  top: 4rem !important;
}

.builder-top-20{
  top: 5rem !important;
}

.builder-top-24{
  top: 6rem !important;
}

.builder-top-28{
  top: 7rem !important;
}

.builder-top-32{
  top: 8rem !important;
}

.builder-top-36{
  top: 9rem !important;
}

.builder-top-40{
  top: 10rem !important;
}

.builder-top-44{
  top: 11rem !important;
}

.builder-top-48{
  top: 12rem !important;
}

.builder-top-52{
  top: 13rem !important;
}

.builder-top-56{
  top: 14rem !important;
}

.builder-top-60{
  top: 15rem !important;
}

.builder-top-64{
  top: 16rem !important;
}

.builder-top-72{
  top: 18rem !important;
}

.builder-top-80{
  top: 20rem !important;
}

.builder-top-96{
  top: 24rem !important;
}

.builder-top-auto{
  top: auto !important;
}

.builder-top-px{
  top: 1px !important;
}

.builder-top-0\.5{
  top: 0.125rem !important;
}

.builder-top-1\.5{
  top: 0.375rem !important;
}

.builder-top-2\.5{
  top: 0.625rem !important;
}

.builder-top-3\.5{
  top: 0.875rem !important;
}

.builder--top-0{
  top: 0px !important;
}

.builder--top-1{
  top: -0.25rem !important;
}

.builder--top-2{
  top: -0.5rem !important;
}

.builder--top-3{
  top: -0.75rem !important;
}

.builder--top-4{
  top: -1rem !important;
}

.builder--top-5{
  top: -1.25rem !important;
}

.builder--top-6{
  top: -1.5rem !important;
}

.builder--top-7{
  top: -1.75rem !important;
}

.builder--top-8{
  top: -2rem !important;
}

.builder--top-9{
  top: -2.25rem !important;
}

.builder--top-10{
  top: -2.5rem !important;
}

.builder--top-11{
  top: -2.75rem !important;
}

.builder--top-12{
  top: -3rem !important;
}

.builder--top-14{
  top: -3.5rem !important;
}

.builder--top-16{
  top: -4rem !important;
}

.builder--top-20{
  top: -5rem !important;
}

.builder--top-24{
  top: -6rem !important;
}

.builder--top-28{
  top: -7rem !important;
}

.builder--top-32{
  top: -8rem !important;
}

.builder--top-36{
  top: -9rem !important;
}

.builder--top-40{
  top: -10rem !important;
}

.builder--top-44{
  top: -11rem !important;
}

.builder--top-48{
  top: -12rem !important;
}

.builder--top-52{
  top: -13rem !important;
}

.builder--top-56{
  top: -14rem !important;
}

.builder--top-60{
  top: -15rem !important;
}

.builder--top-64{
  top: -16rem !important;
}

.builder--top-72{
  top: -18rem !important;
}

.builder--top-80{
  top: -20rem !important;
}

.builder--top-96{
  top: -24rem !important;
}

.builder--top-px{
  top: -1px !important;
}

.builder--top-0\.5{
  top: -0.125rem !important;
}

.builder--top-1\.5{
  top: -0.375rem !important;
}

.builder--top-2\.5{
  top: -0.625rem !important;
}

.builder--top-3\.5{
  top: -0.875rem !important;
}

.builder-top-1\/2{
  top: 50% !important;
}

.builder-top-1\/3{
  top: 33.333333% !important;
}

.builder-top-2\/3{
  top: 66.666667% !important;
}

.builder-top-1\/4{
  top: 25% !important;
}

.builder-top-2\/4{
  top: 50% !important;
}

.builder-top-3\/4{
  top: 75% !important;
}

.builder-top-full{
  top: 100% !important;
}

.builder--top-1\/2{
  top: -50% !important;
}

.builder--top-1\/3{
  top: -33.333333% !important;
}

.builder--top-2\/3{
  top: -66.666667% !important;
}

.builder--top-1\/4{
  top: -25% !important;
}

.builder--top-2\/4{
  top: -50% !important;
}

.builder--top-3\/4{
  top: -75% !important;
}

.builder--top-full{
  top: -100% !important;
}

.builder-right-0{
  right: 0px !important;
}

.builder-right-1{
  right: 0.25rem !important;
}

.builder-right-2{
  right: 0.5rem !important;
}

.builder-right-3{
  right: 0.75rem !important;
}

.builder-right-4{
  right: 1rem !important;
}

.builder-right-5{
  right: 1.25rem !important;
}

.builder-right-6{
  right: 1.5rem !important;
}

.builder-right-7{
  right: 1.75rem !important;
}

.builder-right-8{
  right: 2rem !important;
}

.builder-right-9{
  right: 2.25rem !important;
}

.builder-right-10{
  right: 2.5rem !important;
}

.builder-right-11{
  right: 2.75rem !important;
}

.builder-right-12{
  right: 3rem !important;
}

.builder-right-14{
  right: 3.5rem !important;
}

.builder-right-16{
  right: 4rem !important;
}

.builder-right-20{
  right: 5rem !important;
}

.builder-right-24{
  right: 6rem !important;
}

.builder-right-28{
  right: 7rem !important;
}

.builder-right-32{
  right: 8rem !important;
}

.builder-right-36{
  right: 9rem !important;
}

.builder-right-40{
  right: 10rem !important;
}

.builder-right-44{
  right: 11rem !important;
}

.builder-right-48{
  right: 12rem !important;
}

.builder-right-52{
  right: 13rem !important;
}

.builder-right-56{
  right: 14rem !important;
}

.builder-right-60{
  right: 15rem !important;
}

.builder-right-64{
  right: 16rem !important;
}

.builder-right-72{
  right: 18rem !important;
}

.builder-right-80{
  right: 20rem !important;
}

.builder-right-96{
  right: 24rem !important;
}

.builder-right-auto{
  right: auto !important;
}

.builder-right-px{
  right: 1px !important;
}

.builder-right-0\.5{
  right: 0.125rem !important;
}

.builder-right-1\.5{
  right: 0.375rem !important;
}

.builder-right-2\.5{
  right: 0.625rem !important;
}

.builder-right-3\.5{
  right: 0.875rem !important;
}

.builder--right-0{
  right: 0px !important;
}

.builder--right-1{
  right: -0.25rem !important;
}

.builder--right-2{
  right: -0.5rem !important;
}

.builder--right-3{
  right: -0.75rem !important;
}

.builder--right-4{
  right: -1rem !important;
}

.builder--right-5{
  right: -1.25rem !important;
}

.builder--right-6{
  right: -1.5rem !important;
}

.builder--right-7{
  right: -1.75rem !important;
}

.builder--right-8{
  right: -2rem !important;
}

.builder--right-9{
  right: -2.25rem !important;
}

.builder--right-10{
  right: -2.5rem !important;
}

.builder--right-11{
  right: -2.75rem !important;
}

.builder--right-12{
  right: -3rem !important;
}

.builder--right-14{
  right: -3.5rem !important;
}

.builder--right-16{
  right: -4rem !important;
}

.builder--right-20{
  right: -5rem !important;
}

.builder--right-24{
  right: -6rem !important;
}

.builder--right-28{
  right: -7rem !important;
}

.builder--right-32{
  right: -8rem !important;
}

.builder--right-36{
  right: -9rem !important;
}

.builder--right-40{
  right: -10rem !important;
}

.builder--right-44{
  right: -11rem !important;
}

.builder--right-48{
  right: -12rem !important;
}

.builder--right-52{
  right: -13rem !important;
}

.builder--right-56{
  right: -14rem !important;
}

.builder--right-60{
  right: -15rem !important;
}

.builder--right-64{
  right: -16rem !important;
}

.builder--right-72{
  right: -18rem !important;
}

.builder--right-80{
  right: -20rem !important;
}

.builder--right-96{
  right: -24rem !important;
}

.builder--right-px{
  right: -1px !important;
}

.builder--right-0\.5{
  right: -0.125rem !important;
}

.builder--right-1\.5{
  right: -0.375rem !important;
}

.builder--right-2\.5{
  right: -0.625rem !important;
}

.builder--right-3\.5{
  right: -0.875rem !important;
}

.builder-right-1\/2{
  right: 50% !important;
}

.builder-right-1\/3{
  right: 33.333333% !important;
}

.builder-right-2\/3{
  right: 66.666667% !important;
}

.builder-right-1\/4{
  right: 25% !important;
}

.builder-right-2\/4{
  right: 50% !important;
}

.builder-right-3\/4{
  right: 75% !important;
}

.builder-right-full{
  right: 100% !important;
}

.builder--right-1\/2{
  right: -50% !important;
}

.builder--right-1\/3{
  right: -33.333333% !important;
}

.builder--right-2\/3{
  right: -66.666667% !important;
}

.builder--right-1\/4{
  right: -25% !important;
}

.builder--right-2\/4{
  right: -50% !important;
}

.builder--right-3\/4{
  right: -75% !important;
}

.builder--right-full{
  right: -100% !important;
}

.builder-bottom-0{
  bottom: 0px !important;
}

.builder-bottom-1{
  bottom: 0.25rem !important;
}

.builder-bottom-2{
  bottom: 0.5rem !important;
}

.builder-bottom-3{
  bottom: 0.75rem !important;
}

.builder-bottom-4{
  bottom: 1rem !important;
}

.builder-bottom-5{
  bottom: 1.25rem !important;
}

.builder-bottom-6{
  bottom: 1.5rem !important;
}

.builder-bottom-7{
  bottom: 1.75rem !important;
}

.builder-bottom-8{
  bottom: 2rem !important;
}

.builder-bottom-9{
  bottom: 2.25rem !important;
}

.builder-bottom-10{
  bottom: 2.5rem !important;
}

.builder-bottom-11{
  bottom: 2.75rem !important;
}

.builder-bottom-12{
  bottom: 3rem !important;
}

.builder-bottom-14{
  bottom: 3.5rem !important;
}

.builder-bottom-16{
  bottom: 4rem !important;
}

.builder-bottom-20{
  bottom: 5rem !important;
}

.builder-bottom-24{
  bottom: 6rem !important;
}

.builder-bottom-28{
  bottom: 7rem !important;
}

.builder-bottom-32{
  bottom: 8rem !important;
}

.builder-bottom-36{
  bottom: 9rem !important;
}

.builder-bottom-40{
  bottom: 10rem !important;
}

.builder-bottom-44{
  bottom: 11rem !important;
}

.builder-bottom-48{
  bottom: 12rem !important;
}

.builder-bottom-52{
  bottom: 13rem !important;
}

.builder-bottom-56{
  bottom: 14rem !important;
}

.builder-bottom-60{
  bottom: 15rem !important;
}

.builder-bottom-64{
  bottom: 16rem !important;
}

.builder-bottom-72{
  bottom: 18rem !important;
}

.builder-bottom-80{
  bottom: 20rem !important;
}

.builder-bottom-96{
  bottom: 24rem !important;
}

.builder-bottom-auto{
  bottom: auto !important;
}

.builder-bottom-px{
  bottom: 1px !important;
}

.builder-bottom-0\.5{
  bottom: 0.125rem !important;
}

.builder-bottom-1\.5{
  bottom: 0.375rem !important;
}

.builder-bottom-2\.5{
  bottom: 0.625rem !important;
}

.builder-bottom-3\.5{
  bottom: 0.875rem !important;
}

.builder--bottom-0{
  bottom: 0px !important;
}

.builder--bottom-1{
  bottom: -0.25rem !important;
}

.builder--bottom-2{
  bottom: -0.5rem !important;
}

.builder--bottom-3{
  bottom: -0.75rem !important;
}

.builder--bottom-4{
  bottom: -1rem !important;
}

.builder--bottom-5{
  bottom: -1.25rem !important;
}

.builder--bottom-6{
  bottom: -1.5rem !important;
}

.builder--bottom-7{
  bottom: -1.75rem !important;
}

.builder--bottom-8{
  bottom: -2rem !important;
}

.builder--bottom-9{
  bottom: -2.25rem !important;
}

.builder--bottom-10{
  bottom: -2.5rem !important;
}

.builder--bottom-11{
  bottom: -2.75rem !important;
}

.builder--bottom-12{
  bottom: -3rem !important;
}

.builder--bottom-14{
  bottom: -3.5rem !important;
}

.builder--bottom-16{
  bottom: -4rem !important;
}

.builder--bottom-20{
  bottom: -5rem !important;
}

.builder--bottom-24{
  bottom: -6rem !important;
}

.builder--bottom-28{
  bottom: -7rem !important;
}

.builder--bottom-32{
  bottom: -8rem !important;
}

.builder--bottom-36{
  bottom: -9rem !important;
}

.builder--bottom-40{
  bottom: -10rem !important;
}

.builder--bottom-44{
  bottom: -11rem !important;
}

.builder--bottom-48{
  bottom: -12rem !important;
}

.builder--bottom-52{
  bottom: -13rem !important;
}

.builder--bottom-56{
  bottom: -14rem !important;
}

.builder--bottom-60{
  bottom: -15rem !important;
}

.builder--bottom-64{
  bottom: -16rem !important;
}

.builder--bottom-72{
  bottom: -18rem !important;
}

.builder--bottom-80{
  bottom: -20rem !important;
}

.builder--bottom-96{
  bottom: -24rem !important;
}

.builder--bottom-px{
  bottom: -1px !important;
}

.builder--bottom-0\.5{
  bottom: -0.125rem !important;
}

.builder--bottom-1\.5{
  bottom: -0.375rem !important;
}

.builder--bottom-2\.5{
  bottom: -0.625rem !important;
}

.builder--bottom-3\.5{
  bottom: -0.875rem !important;
}

.builder-bottom-1\/2{
  bottom: 50% !important;
}

.builder-bottom-1\/3{
  bottom: 33.333333% !important;
}

.builder-bottom-2\/3{
  bottom: 66.666667% !important;
}

.builder-bottom-1\/4{
  bottom: 25% !important;
}

.builder-bottom-2\/4{
  bottom: 50% !important;
}

.builder-bottom-3\/4{
  bottom: 75% !important;
}

.builder-bottom-full{
  bottom: 100% !important;
}

.builder--bottom-1\/2{
  bottom: -50% !important;
}

.builder--bottom-1\/3{
  bottom: -33.333333% !important;
}

.builder--bottom-2\/3{
  bottom: -66.666667% !important;
}

.builder--bottom-1\/4{
  bottom: -25% !important;
}

.builder--bottom-2\/4{
  bottom: -50% !important;
}

.builder--bottom-3\/4{
  bottom: -75% !important;
}

.builder--bottom-full{
  bottom: -100% !important;
}

.builder-left-0{
  left: 0px !important;
}

.builder-left-1{
  left: 0.25rem !important;
}

.builder-left-2{
  left: 0.5rem !important;
}

.builder-left-3{
  left: 0.75rem !important;
}

.builder-left-4{
  left: 1rem !important;
}

.builder-left-5{
  left: 1.25rem !important;
}

.builder-left-6{
  left: 1.5rem !important;
}

.builder-left-7{
  left: 1.75rem !important;
}

.builder-left-8{
  left: 2rem !important;
}

.builder-left-9{
  left: 2.25rem !important;
}

.builder-left-10{
  left: 2.5rem !important;
}

.builder-left-11{
  left: 2.75rem !important;
}

.builder-left-12{
  left: 3rem !important;
}

.builder-left-14{
  left: 3.5rem !important;
}

.builder-left-16{
  left: 4rem !important;
}

.builder-left-20{
  left: 5rem !important;
}

.builder-left-24{
  left: 6rem !important;
}

.builder-left-28{
  left: 7rem !important;
}

.builder-left-32{
  left: 8rem !important;
}

.builder-left-36{
  left: 9rem !important;
}

.builder-left-40{
  left: 10rem !important;
}

.builder-left-44{
  left: 11rem !important;
}

.builder-left-48{
  left: 12rem !important;
}

.builder-left-52{
  left: 13rem !important;
}

.builder-left-56{
  left: 14rem !important;
}

.builder-left-60{
  left: 15rem !important;
}

.builder-left-64{
  left: 16rem !important;
}

.builder-left-72{
  left: 18rem !important;
}

.builder-left-80{
  left: 20rem !important;
}

.builder-left-96{
  left: 24rem !important;
}

.builder-left-auto{
  left: auto !important;
}

.builder-left-px{
  left: 1px !important;
}

.builder-left-0\.5{
  left: 0.125rem !important;
}

.builder-left-1\.5{
  left: 0.375rem !important;
}

.builder-left-2\.5{
  left: 0.625rem !important;
}

.builder-left-3\.5{
  left: 0.875rem !important;
}

.builder--left-0{
  left: 0px !important;
}

.builder--left-1{
  left: -0.25rem !important;
}

.builder--left-2{
  left: -0.5rem !important;
}

.builder--left-3{
  left: -0.75rem !important;
}

.builder--left-4{
  left: -1rem !important;
}

.builder--left-5{
  left: -1.25rem !important;
}

.builder--left-6{
  left: -1.5rem !important;
}

.builder--left-7{
  left: -1.75rem !important;
}

.builder--left-8{
  left: -2rem !important;
}

.builder--left-9{
  left: -2.25rem !important;
}

.builder--left-10{
  left: -2.5rem !important;
}

.builder--left-11{
  left: -2.75rem !important;
}

.builder--left-12{
  left: -3rem !important;
}

.builder--left-14{
  left: -3.5rem !important;
}

.builder--left-16{
  left: -4rem !important;
}

.builder--left-20{
  left: -5rem !important;
}

.builder--left-24{
  left: -6rem !important;
}

.builder--left-28{
  left: -7rem !important;
}

.builder--left-32{
  left: -8rem !important;
}

.builder--left-36{
  left: -9rem !important;
}

.builder--left-40{
  left: -10rem !important;
}

.builder--left-44{
  left: -11rem !important;
}

.builder--left-48{
  left: -12rem !important;
}

.builder--left-52{
  left: -13rem !important;
}

.builder--left-56{
  left: -14rem !important;
}

.builder--left-60{
  left: -15rem !important;
}

.builder--left-64{
  left: -16rem !important;
}

.builder--left-72{
  left: -18rem !important;
}

.builder--left-80{
  left: -20rem !important;
}

.builder--left-96{
  left: -24rem !important;
}

.builder--left-px{
  left: -1px !important;
}

.builder--left-0\.5{
  left: -0.125rem !important;
}

.builder--left-1\.5{
  left: -0.375rem !important;
}

.builder--left-2\.5{
  left: -0.625rem !important;
}

.builder--left-3\.5{
  left: -0.875rem !important;
}

.builder-left-1\/2{
  left: 50% !important;
}

.builder-left-1\/3{
  left: 33.333333% !important;
}

.builder-left-2\/3{
  left: 66.666667% !important;
}

.builder-left-1\/4{
  left: 25% !important;
}

.builder-left-2\/4{
  left: 50% !important;
}

.builder-left-3\/4{
  left: 75% !important;
}

.builder-left-full{
  left: 100% !important;
}

.builder--left-1\/2{
  left: -50% !important;
}

.builder--left-1\/3{
  left: -33.333333% !important;
}

.builder--left-2\/3{
  left: -66.666667% !important;
}

.builder--left-1\/4{
  left: -25% !important;
}

.builder--left-2\/4{
  left: -50% !important;
}

.builder--left-3\/4{
  left: -75% !important;
}

.builder--left-full{
  left: -100% !important;
}

.builder-isolate{
  isolation: isolate !important;
}

.builder-isolation-auto{
  isolation: auto !important;
}

.builder-z-0{
  z-index: 0 !important;
}

.builder-z-10{
  z-index: 10 !important;
}

.builder-z-20{
  z-index: 20 !important;
}

.builder-z-30{
  z-index: 30 !important;
}

.builder-z-40{
  z-index: 40 !important;
}

.builder-z-50{
  z-index: 50 !important;
}

.builder-z-auto{
  z-index: auto !important;
}

.focus-within\:builder-z-0:focus-within{
  z-index: 0 !important;
}

.focus-within\:builder-z-10:focus-within{
  z-index: 10 !important;
}

.focus-within\:builder-z-20:focus-within{
  z-index: 20 !important;
}

.focus-within\:builder-z-30:focus-within{
  z-index: 30 !important;
}

.focus-within\:builder-z-40:focus-within{
  z-index: 40 !important;
}

.focus-within\:builder-z-50:focus-within{
  z-index: 50 !important;
}

.focus-within\:builder-z-auto:focus-within{
  z-index: auto !important;
}

.focus\:builder-z-0:focus{
  z-index: 0 !important;
}

.focus\:builder-z-10:focus{
  z-index: 10 !important;
}

.focus\:builder-z-20:focus{
  z-index: 20 !important;
}

.focus\:builder-z-30:focus{
  z-index: 30 !important;
}

.focus\:builder-z-40:focus{
  z-index: 40 !important;
}

.focus\:builder-z-50:focus{
  z-index: 50 !important;
}

.focus\:builder-z-auto:focus{
  z-index: auto !important;
}

.builder-order-1{
  order: 1 !important;
}

.builder-order-2{
  order: 2 !important;
}

.builder-order-3{
  order: 3 !important;
}

.builder-order-4{
  order: 4 !important;
}

.builder-order-5{
  order: 5 !important;
}

.builder-order-6{
  order: 6 !important;
}

.builder-order-7{
  order: 7 !important;
}

.builder-order-8{
  order: 8 !important;
}

.builder-order-9{
  order: 9 !important;
}

.builder-order-10{
  order: 10 !important;
}

.builder-order-11{
  order: 11 !important;
}

.builder-order-12{
  order: 12 !important;
}

.builder-order-first{
  order: -9999 !important;
}

.builder-order-last{
  order: 9999 !important;
}

.builder-order-none{
  order: 0 !important;
}

.builder-col-auto{
  grid-column: auto !important;
}

.builder-col-span-1{
  grid-column: span 1 / span 1 !important;
}

.builder-col-span-2{
  grid-column: span 2 / span 2 !important;
}

.builder-col-span-3{
  grid-column: span 3 / span 3 !important;
}

.builder-col-span-4{
  grid-column: span 4 / span 4 !important;
}

.builder-col-span-5{
  grid-column: span 5 / span 5 !important;
}

.builder-col-span-6{
  grid-column: span 6 / span 6 !important;
}

.builder-col-span-7{
  grid-column: span 7 / span 7 !important;
}

.builder-col-span-8{
  grid-column: span 8 / span 8 !important;
}

.builder-col-span-9{
  grid-column: span 9 / span 9 !important;
}

.builder-col-span-10{
  grid-column: span 10 / span 10 !important;
}

.builder-col-span-11{
  grid-column: span 11 / span 11 !important;
}

.builder-col-span-12{
  grid-column: span 12 / span 12 !important;
}

.builder-col-span-full{
  grid-column: 1 / -1 !important;
}

.builder-col-start-1{
  grid-column-start: 1 !important;
}

.builder-col-start-2{
  grid-column-start: 2 !important;
}

.builder-col-start-3{
  grid-column-start: 3 !important;
}

.builder-col-start-4{
  grid-column-start: 4 !important;
}

.builder-col-start-5{
  grid-column-start: 5 !important;
}

.builder-col-start-6{
  grid-column-start: 6 !important;
}

.builder-col-start-7{
  grid-column-start: 7 !important;
}

.builder-col-start-8{
  grid-column-start: 8 !important;
}

.builder-col-start-9{
  grid-column-start: 9 !important;
}

.builder-col-start-10{
  grid-column-start: 10 !important;
}

.builder-col-start-11{
  grid-column-start: 11 !important;
}

.builder-col-start-12{
  grid-column-start: 12 !important;
}

.builder-col-start-13{
  grid-column-start: 13 !important;
}

.builder-col-start-auto{
  grid-column-start: auto !important;
}

.builder-col-end-1{
  grid-column-end: 1 !important;
}

.builder-col-end-2{
  grid-column-end: 2 !important;
}

.builder-col-end-3{
  grid-column-end: 3 !important;
}

.builder-col-end-4{
  grid-column-end: 4 !important;
}

.builder-col-end-5{
  grid-column-end: 5 !important;
}

.builder-col-end-6{
  grid-column-end: 6 !important;
}

.builder-col-end-7{
  grid-column-end: 7 !important;
}

.builder-col-end-8{
  grid-column-end: 8 !important;
}

.builder-col-end-9{
  grid-column-end: 9 !important;
}

.builder-col-end-10{
  grid-column-end: 10 !important;
}

.builder-col-end-11{
  grid-column-end: 11 !important;
}

.builder-col-end-12{
  grid-column-end: 12 !important;
}

.builder-col-end-13{
  grid-column-end: 13 !important;
}

.builder-col-end-auto{
  grid-column-end: auto !important;
}

.builder-row-auto{
  grid-row: auto !important;
}

.builder-row-span-1{
  grid-row: span 1 / span 1 !important;
}

.builder-row-span-2{
  grid-row: span 2 / span 2 !important;
}

.builder-row-span-3{
  grid-row: span 3 / span 3 !important;
}

.builder-row-span-4{
  grid-row: span 4 / span 4 !important;
}

.builder-row-span-5{
  grid-row: span 5 / span 5 !important;
}

.builder-row-span-6{
  grid-row: span 6 / span 6 !important;
}

.builder-row-span-full{
  grid-row: 1 / -1 !important;
}

.builder-row-start-1{
  grid-row-start: 1 !important;
}

.builder-row-start-2{
  grid-row-start: 2 !important;
}

.builder-row-start-3{
  grid-row-start: 3 !important;
}

.builder-row-start-4{
  grid-row-start: 4 !important;
}

.builder-row-start-5{
  grid-row-start: 5 !important;
}

.builder-row-start-6{
  grid-row-start: 6 !important;
}

.builder-row-start-7{
  grid-row-start: 7 !important;
}

.builder-row-start-auto{
  grid-row-start: auto !important;
}

.builder-row-end-1{
  grid-row-end: 1 !important;
}

.builder-row-end-2{
  grid-row-end: 2 !important;
}

.builder-row-end-3{
  grid-row-end: 3 !important;
}

.builder-row-end-4{
  grid-row-end: 4 !important;
}

.builder-row-end-5{
  grid-row-end: 5 !important;
}

.builder-row-end-6{
  grid-row-end: 6 !important;
}

.builder-row-end-7{
  grid-row-end: 7 !important;
}

.builder-row-end-auto{
  grid-row-end: auto !important;
}

.builder-float-right{
  float: right !important;
}

.builder-float-left{
  float: left !important;
}

.builder-float-none{
  float: none !important;
}

.builder-clear-left{
  clear: left !important;
}

.builder-clear-right{
  clear: right !important;
}

.builder-clear-both{
  clear: both !important;
}

.builder-clear-none{
  clear: none !important;
}

.builder-m-0{
  margin: 0px !important;
}

.builder-m-1{
  margin: 0.25rem !important;
}

.builder-m-2{
  margin: 0.5rem !important;
}

.builder-m-3{
  margin: 0.75rem !important;
}

.builder-m-4{
  margin: 1rem !important;
}

.builder-m-5{
  margin: 1.25rem !important;
}

.builder-m-6{
  margin: 1.5rem !important;
}

.builder-m-7{
  margin: 1.75rem !important;
}

.builder-m-8{
  margin: 2rem !important;
}

.builder-m-9{
  margin: 2.25rem !important;
}

.builder-m-10{
  margin: 2.5rem !important;
}

.builder-m-11{
  margin: 2.75rem !important;
}

.builder-m-12{
  margin: 3rem !important;
}

.builder-m-14{
  margin: 3.5rem !important;
}

.builder-m-16{
  margin: 4rem !important;
}

.builder-m-20{
  margin: 5rem !important;
}

.builder-m-24{
  margin: 6rem !important;
}

.builder-m-28{
  margin: 7rem !important;
}

.builder-m-32{
  margin: 8rem !important;
}

.builder-m-36{
  margin: 9rem !important;
}

.builder-m-40{
  margin: 10rem !important;
}

.builder-m-44{
  margin: 11rem !important;
}

.builder-m-48{
  margin: 12rem !important;
}

.builder-m-52{
  margin: 13rem !important;
}

.builder-m-56{
  margin: 14rem !important;
}

.builder-m-60{
  margin: 15rem !important;
}

.builder-m-64{
  margin: 16rem !important;
}

.builder-m-72{
  margin: 18rem !important;
}

.builder-m-80{
  margin: 20rem !important;
}

.builder-m-96{
  margin: 24rem !important;
}

.builder-m-auto{
  margin: auto !important;
}

.builder-m-px{
  margin: 1px !important;
}

.builder-m-0\.5{
  margin: 0.125rem !important;
}

.builder-m-1\.5{
  margin: 0.375rem !important;
}

.builder-m-2\.5{
  margin: 0.625rem !important;
}

.builder-m-3\.5{
  margin: 0.875rem !important;
}

.builder--m-0{
  margin: 0px !important;
}

.builder--m-1{
  margin: -0.25rem !important;
}

.builder--m-2{
  margin: -0.5rem !important;
}

.builder--m-3{
  margin: -0.75rem !important;
}

.builder--m-4{
  margin: -1rem !important;
}

.builder--m-5{
  margin: -1.25rem !important;
}

.builder--m-6{
  margin: -1.5rem !important;
}

.builder--m-7{
  margin: -1.75rem !important;
}

.builder--m-8{
  margin: -2rem !important;
}

.builder--m-9{
  margin: -2.25rem !important;
}

.builder--m-10{
  margin: -2.5rem !important;
}

.builder--m-11{
  margin: -2.75rem !important;
}

.builder--m-12{
  margin: -3rem !important;
}

.builder--m-14{
  margin: -3.5rem !important;
}

.builder--m-16{
  margin: -4rem !important;
}

.builder--m-20{
  margin: -5rem !important;
}

.builder--m-24{
  margin: -6rem !important;
}

.builder--m-28{
  margin: -7rem !important;
}

.builder--m-32{
  margin: -8rem !important;
}

.builder--m-36{
  margin: -9rem !important;
}

.builder--m-40{
  margin: -10rem !important;
}

.builder--m-44{
  margin: -11rem !important;
}

.builder--m-48{
  margin: -12rem !important;
}

.builder--m-52{
  margin: -13rem !important;
}

.builder--m-56{
  margin: -14rem !important;
}

.builder--m-60{
  margin: -15rem !important;
}

.builder--m-64{
  margin: -16rem !important;
}

.builder--m-72{
  margin: -18rem !important;
}

.builder--m-80{
  margin: -20rem !important;
}

.builder--m-96{
  margin: -24rem !important;
}

.builder--m-px{
  margin: -1px !important;
}

.builder--m-0\.5{
  margin: -0.125rem !important;
}

.builder--m-1\.5{
  margin: -0.375rem !important;
}

.builder--m-2\.5{
  margin: -0.625rem !important;
}

.builder--m-3\.5{
  margin: -0.875rem !important;
}

.builder-mx-0{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.builder-mx-1{
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.builder-mx-2{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.builder-mx-3{
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.builder-mx-4{
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.builder-mx-5{
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.builder-mx-6{
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.builder-mx-7{
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.builder-mx-8{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.builder-mx-9{
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.builder-mx-10{
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.builder-mx-11{
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.builder-mx-12{
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.builder-mx-14{
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.builder-mx-16{
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.builder-mx-20{
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.builder-mx-24{
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.builder-mx-28{
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}

.builder-mx-32{
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.builder-mx-36{
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.builder-mx-40{
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.builder-mx-44{
  margin-left: 11rem !important;
  margin-right: 11rem !important;
}

.builder-mx-48{
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.builder-mx-52{
  margin-left: 13rem !important;
  margin-right: 13rem !important;
}

.builder-mx-56{
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.builder-mx-60{
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.builder-mx-64{
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.builder-mx-72{
  margin-left: 18rem !important;
  margin-right: 18rem !important;
}

.builder-mx-80{
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.builder-mx-96{
  margin-left: 24rem !important;
  margin-right: 24rem !important;
}

.builder-mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}

.builder-mx-px{
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.builder-mx-0\.5{
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}

.builder-mx-1\.5{
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}

.builder-mx-2\.5{
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.builder-mx-3\.5{
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important;
}

.builder--mx-0{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.builder--mx-1{
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.builder--mx-2{
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.builder--mx-3{
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.builder--mx-4{
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.builder--mx-5{
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.builder--mx-6{
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.builder--mx-7{
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.builder--mx-8{
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.builder--mx-9{
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.builder--mx-10{
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.builder--mx-11{
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.builder--mx-12{
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.builder--mx-14{
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.builder--mx-16{
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.builder--mx-20{
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.builder--mx-24{
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.builder--mx-28{
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.builder--mx-32{
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.builder--mx-36{
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.builder--mx-40{
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.builder--mx-44{
  margin-left: -11rem !important;
  margin-right: -11rem !important;
}

.builder--mx-48{
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.builder--mx-52{
  margin-left: -13rem !important;
  margin-right: -13rem !important;
}

.builder--mx-56{
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.builder--mx-60{
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.builder--mx-64{
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.builder--mx-72{
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.builder--mx-80{
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.builder--mx-96{
  margin-left: -24rem !important;
  margin-right: -24rem !important;
}

.builder--mx-px{
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.builder--mx-0\.5{
  margin-left: -0.125rem !important;
  margin-right: -0.125rem !important;
}

.builder--mx-1\.5{
  margin-left: -0.375rem !important;
  margin-right: -0.375rem !important;
}

.builder--mx-2\.5{
  margin-left: -0.625rem !important;
  margin-right: -0.625rem !important;
}

.builder--mx-3\.5{
  margin-left: -0.875rem !important;
  margin-right: -0.875rem !important;
}

.builder-my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.builder-my-1{
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.builder-my-2{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.builder-my-3{
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.builder-my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.builder-my-5{
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.builder-my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.builder-my-7{
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.builder-my-8{
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.builder-my-9{
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.builder-my-10{
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.builder-my-11{
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.builder-my-12{
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.builder-my-14{
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.builder-my-16{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.builder-my-20{
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.builder-my-24{
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.builder-my-28{
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.builder-my-32{
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.builder-my-36{
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.builder-my-40{
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.builder-my-44{
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.builder-my-48{
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.builder-my-52{
  margin-top: 13rem !important;
  margin-bottom: 13rem !important;
}

.builder-my-56{
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.builder-my-60{
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.builder-my-64{
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.builder-my-72{
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.builder-my-80{
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.builder-my-96{
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.builder-my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.builder-my-px{
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.builder-my-0\.5{
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}

.builder-my-1\.5{
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.builder-my-2\.5{
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.builder-my-3\.5{
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.builder--my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.builder--my-1{
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.builder--my-2{
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.builder--my-3{
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.builder--my-4{
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.builder--my-5{
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.builder--my-6{
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.builder--my-7{
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.builder--my-8{
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.builder--my-9{
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.builder--my-10{
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.builder--my-11{
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.builder--my-12{
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.builder--my-14{
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.builder--my-16{
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.builder--my-20{
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.builder--my-24{
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.builder--my-28{
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.builder--my-32{
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.builder--my-36{
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.builder--my-40{
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.builder--my-44{
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.builder--my-48{
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.builder--my-52{
  margin-top: -13rem !important;
  margin-bottom: -13rem !important;
}

.builder--my-56{
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.builder--my-60{
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.builder--my-64{
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.builder--my-72{
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.builder--my-80{
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.builder--my-96{
  margin-top: -24rem !important;
  margin-bottom: -24rem !important;
}

.builder--my-px{
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.builder--my-0\.5{
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important;
}

.builder--my-1\.5{
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.builder--my-2\.5{
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.builder--my-3\.5{
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.builder-mt-0{
  margin-top: 0px !important;
}

.builder-mt-1{
  margin-top: 0.25rem !important;
}

.builder-mt-2{
  margin-top: 0.5rem !important;
}

.builder-mt-3{
  margin-top: 0.75rem !important;
}

.builder-mt-4{
  margin-top: 1rem !important;
}

.builder-mt-5{
  margin-top: 1.25rem !important;
}

.builder-mt-6{
  margin-top: 1.5rem !important;
}

.builder-mt-7{
  margin-top: 1.75rem !important;
}

.builder-mt-8{
  margin-top: 2rem !important;
}

.builder-mt-9{
  margin-top: 2.25rem !important;
}

.builder-mt-10{
  margin-top: 2.5rem !important;
}

.builder-mt-11{
  margin-top: 2.75rem !important;
}

.builder-mt-12{
  margin-top: 3rem !important;
}

.builder-mt-14{
  margin-top: 3.5rem !important;
}

.builder-mt-16{
  margin-top: 4rem !important;
}

.builder-mt-20{
  margin-top: 5rem !important;
}

.builder-mt-24{
  margin-top: 6rem !important;
}

.builder-mt-28{
  margin-top: 7rem !important;
}

.builder-mt-32{
  margin-top: 8rem !important;
}

.builder-mt-36{
  margin-top: 9rem !important;
}

.builder-mt-40{
  margin-top: 10rem !important;
}

.builder-mt-44{
  margin-top: 11rem !important;
}

.builder-mt-48{
  margin-top: 12rem !important;
}

.builder-mt-52{
  margin-top: 13rem !important;
}

.builder-mt-56{
  margin-top: 14rem !important;
}

.builder-mt-60{
  margin-top: 15rem !important;
}

.builder-mt-64{
  margin-top: 16rem !important;
}

.builder-mt-72{
  margin-top: 18rem !important;
}

.builder-mt-80{
  margin-top: 20rem !important;
}

.builder-mt-96{
  margin-top: 24rem !important;
}

.builder-mt-auto{
  margin-top: auto !important;
}

.builder-mt-px{
  margin-top: 1px !important;
}

.builder-mt-0\.5{
  margin-top: 0.125rem !important;
}

.builder-mt-1\.5{
  margin-top: 0.375rem !important;
}

.builder-mt-2\.5{
  margin-top: 0.625rem !important;
}

.builder-mt-3\.5{
  margin-top: 0.875rem !important;
}

.builder--mt-0{
  margin-top: 0px !important;
}

.builder--mt-1{
  margin-top: -0.25rem !important;
}

.builder--mt-2{
  margin-top: -0.5rem !important;
}

.builder--mt-3{
  margin-top: -0.75rem !important;
}

.builder--mt-4{
  margin-top: -1rem !important;
}

.builder--mt-5{
  margin-top: -1.25rem !important;
}

.builder--mt-6{
  margin-top: -1.5rem !important;
}

.builder--mt-7{
  margin-top: -1.75rem !important;
}

.builder--mt-8{
  margin-top: -2rem !important;
}

.builder--mt-9{
  margin-top: -2.25rem !important;
}

.builder--mt-10{
  margin-top: -2.5rem !important;
}

.builder--mt-11{
  margin-top: -2.75rem !important;
}

.builder--mt-12{
  margin-top: -3rem !important;
}

.builder--mt-14{
  margin-top: -3.5rem !important;
}

.builder--mt-16{
  margin-top: -4rem !important;
}

.builder--mt-20{
  margin-top: -5rem !important;
}

.builder--mt-24{
  margin-top: -6rem !important;
}

.builder--mt-28{
  margin-top: -7rem !important;
}

.builder--mt-32{
  margin-top: -8rem !important;
}

.builder--mt-36{
  margin-top: -9rem !important;
}

.builder--mt-40{
  margin-top: -10rem !important;
}

.builder--mt-44{
  margin-top: -11rem !important;
}

.builder--mt-48{
  margin-top: -12rem !important;
}

.builder--mt-52{
  margin-top: -13rem !important;
}

.builder--mt-56{
  margin-top: -14rem !important;
}

.builder--mt-60{
  margin-top: -15rem !important;
}

.builder--mt-64{
  margin-top: -16rem !important;
}

.builder--mt-72{
  margin-top: -18rem !important;
}

.builder--mt-80{
  margin-top: -20rem !important;
}

.builder--mt-96{
  margin-top: -24rem !important;
}

.builder--mt-px{
  margin-top: -1px !important;
}

.builder--mt-0\.5{
  margin-top: -0.125rem !important;
}

.builder--mt-1\.5{
  margin-top: -0.375rem !important;
}

.builder--mt-2\.5{
  margin-top: -0.625rem !important;
}

.builder--mt-3\.5{
  margin-top: -0.875rem !important;
}

.builder-mr-0{
  margin-right: 0px !important;
}

.builder-mr-1{
  margin-right: 0.25rem !important;
}

.builder-mr-2{
  margin-right: 0.5rem !important;
}

.builder-mr-3{
  margin-right: 0.75rem !important;
}

.builder-mr-4{
  margin-right: 1rem !important;
}

.builder-mr-5{
  margin-right: 1.25rem !important;
}

.builder-mr-6{
  margin-right: 1.5rem !important;
}

.builder-mr-7{
  margin-right: 1.75rem !important;
}

.builder-mr-8{
  margin-right: 2rem !important;
}

.builder-mr-9{
  margin-right: 2.25rem !important;
}

.builder-mr-10{
  margin-right: 2.5rem !important;
}

.builder-mr-11{
  margin-right: 2.75rem !important;
}

.builder-mr-12{
  margin-right: 3rem !important;
}

.builder-mr-14{
  margin-right: 3.5rem !important;
}

.builder-mr-16{
  margin-right: 4rem !important;
}

.builder-mr-20{
  margin-right: 5rem !important;
}

.builder-mr-24{
  margin-right: 6rem !important;
}

.builder-mr-28{
  margin-right: 7rem !important;
}

.builder-mr-32{
  margin-right: 8rem !important;
}

.builder-mr-36{
  margin-right: 9rem !important;
}

.builder-mr-40{
  margin-right: 10rem !important;
}

.builder-mr-44{
  margin-right: 11rem !important;
}

.builder-mr-48{
  margin-right: 12rem !important;
}

.builder-mr-52{
  margin-right: 13rem !important;
}

.builder-mr-56{
  margin-right: 14rem !important;
}

.builder-mr-60{
  margin-right: 15rem !important;
}

.builder-mr-64{
  margin-right: 16rem !important;
}

.builder-mr-72{
  margin-right: 18rem !important;
}

.builder-mr-80{
  margin-right: 20rem !important;
}

.builder-mr-96{
  margin-right: 24rem !important;
}

.builder-mr-auto{
  margin-right: auto !important;
}

.builder-mr-px{
  margin-right: 1px !important;
}

.builder-mr-0\.5{
  margin-right: 0.125rem !important;
}

.builder-mr-1\.5{
  margin-right: 0.375rem !important;
}

.builder-mr-2\.5{
  margin-right: 0.625rem !important;
}

.builder-mr-3\.5{
  margin-right: 0.875rem !important;
}

.builder--mr-0{
  margin-right: 0px !important;
}

.builder--mr-1{
  margin-right: -0.25rem !important;
}

.builder--mr-2{
  margin-right: -0.5rem !important;
}

.builder--mr-3{
  margin-right: -0.75rem !important;
}

.builder--mr-4{
  margin-right: -1rem !important;
}

.builder--mr-5{
  margin-right: -1.25rem !important;
}

.builder--mr-6{
  margin-right: -1.5rem !important;
}

.builder--mr-7{
  margin-right: -1.75rem !important;
}

.builder--mr-8{
  margin-right: -2rem !important;
}

.builder--mr-9{
  margin-right: -2.25rem !important;
}

.builder--mr-10{
  margin-right: -2.5rem !important;
}

.builder--mr-11{
  margin-right: -2.75rem !important;
}

.builder--mr-12{
  margin-right: -3rem !important;
}

.builder--mr-14{
  margin-right: -3.5rem !important;
}

.builder--mr-16{
  margin-right: -4rem !important;
}

.builder--mr-20{
  margin-right: -5rem !important;
}

.builder--mr-24{
  margin-right: -6rem !important;
}

.builder--mr-28{
  margin-right: -7rem !important;
}

.builder--mr-32{
  margin-right: -8rem !important;
}

.builder--mr-36{
  margin-right: -9rem !important;
}

.builder--mr-40{
  margin-right: -10rem !important;
}

.builder--mr-44{
  margin-right: -11rem !important;
}

.builder--mr-48{
  margin-right: -12rem !important;
}

.builder--mr-52{
  margin-right: -13rem !important;
}

.builder--mr-56{
  margin-right: -14rem !important;
}

.builder--mr-60{
  margin-right: -15rem !important;
}

.builder--mr-64{
  margin-right: -16rem !important;
}

.builder--mr-72{
  margin-right: -18rem !important;
}

.builder--mr-80{
  margin-right: -20rem !important;
}

.builder--mr-96{
  margin-right: -24rem !important;
}

.builder--mr-px{
  margin-right: -1px !important;
}

.builder--mr-0\.5{
  margin-right: -0.125rem !important;
}

.builder--mr-1\.5{
  margin-right: -0.375rem !important;
}

.builder--mr-2\.5{
  margin-right: -0.625rem !important;
}

.builder--mr-3\.5{
  margin-right: -0.875rem !important;
}

.builder-mb-0{
  margin-bottom: 0px !important;
}

.builder-mb-1{
  margin-bottom: 0.25rem !important;
}

.builder-mb-2{
  margin-bottom: 0.5rem !important;
}

.builder-mb-3{
  margin-bottom: 0.75rem !important;
}

.builder-mb-4{
  margin-bottom: 1rem !important;
}

.builder-mb-5{
  margin-bottom: 1.25rem !important;
}

.builder-mb-6{
  margin-bottom: 1.5rem !important;
}

.builder-mb-7{
  margin-bottom: 1.75rem !important;
}

.builder-mb-8{
  margin-bottom: 2rem !important;
}

.builder-mb-9{
  margin-bottom: 2.25rem !important;
}

.builder-mb-10{
  margin-bottom: 2.5rem !important;
}

.builder-mb-11{
  margin-bottom: 2.75rem !important;
}

.builder-mb-12{
  margin-bottom: 3rem !important;
}

.builder-mb-14{
  margin-bottom: 3.5rem !important;
}

.builder-mb-16{
  margin-bottom: 4rem !important;
}

.builder-mb-20{
  margin-bottom: 5rem !important;
}

.builder-mb-24{
  margin-bottom: 6rem !important;
}

.builder-mb-28{
  margin-bottom: 7rem !important;
}

.builder-mb-32{
  margin-bottom: 8rem !important;
}

.builder-mb-36{
  margin-bottom: 9rem !important;
}

.builder-mb-40{
  margin-bottom: 10rem !important;
}

.builder-mb-44{
  margin-bottom: 11rem !important;
}

.builder-mb-48{
  margin-bottom: 12rem !important;
}

.builder-mb-52{
  margin-bottom: 13rem !important;
}

.builder-mb-56{
  margin-bottom: 14rem !important;
}

.builder-mb-60{
  margin-bottom: 15rem !important;
}

.builder-mb-64{
  margin-bottom: 16rem !important;
}

.builder-mb-72{
  margin-bottom: 18rem !important;
}

.builder-mb-80{
  margin-bottom: 20rem !important;
}

.builder-mb-96{
  margin-bottom: 24rem !important;
}

.builder-mb-auto{
  margin-bottom: auto !important;
}

.builder-mb-px{
  margin-bottom: 1px !important;
}

.builder-mb-0\.5{
  margin-bottom: 0.125rem !important;
}

.builder-mb-1\.5{
  margin-bottom: 0.375rem !important;
}

.builder-mb-2\.5{
  margin-bottom: 0.625rem !important;
}

.builder-mb-3\.5{
  margin-bottom: 0.875rem !important;
}

.builder--mb-0{
  margin-bottom: 0px !important;
}

.builder--mb-1{
  margin-bottom: -0.25rem !important;
}

.builder--mb-2{
  margin-bottom: -0.5rem !important;
}

.builder--mb-3{
  margin-bottom: -0.75rem !important;
}

.builder--mb-4{
  margin-bottom: -1rem !important;
}

.builder--mb-5{
  margin-bottom: -1.25rem !important;
}

.builder--mb-6{
  margin-bottom: -1.5rem !important;
}

.builder--mb-7{
  margin-bottom: -1.75rem !important;
}

.builder--mb-8{
  margin-bottom: -2rem !important;
}

.builder--mb-9{
  margin-bottom: -2.25rem !important;
}

.builder--mb-10{
  margin-bottom: -2.5rem !important;
}

.builder--mb-11{
  margin-bottom: -2.75rem !important;
}

.builder--mb-12{
  margin-bottom: -3rem !important;
}

.builder--mb-14{
  margin-bottom: -3.5rem !important;
}

.builder--mb-16{
  margin-bottom: -4rem !important;
}

.builder--mb-20{
  margin-bottom: -5rem !important;
}

.builder--mb-24{
  margin-bottom: -6rem !important;
}

.builder--mb-28{
  margin-bottom: -7rem !important;
}

.builder--mb-32{
  margin-bottom: -8rem !important;
}

.builder--mb-36{
  margin-bottom: -9rem !important;
}

.builder--mb-40{
  margin-bottom: -10rem !important;
}

.builder--mb-44{
  margin-bottom: -11rem !important;
}

.builder--mb-48{
  margin-bottom: -12rem !important;
}

.builder--mb-52{
  margin-bottom: -13rem !important;
}

.builder--mb-56{
  margin-bottom: -14rem !important;
}

.builder--mb-60{
  margin-bottom: -15rem !important;
}

.builder--mb-64{
  margin-bottom: -16rem !important;
}

.builder--mb-72{
  margin-bottom: -18rem !important;
}

.builder--mb-80{
  margin-bottom: -20rem !important;
}

.builder--mb-96{
  margin-bottom: -24rem !important;
}

.builder--mb-px{
  margin-bottom: -1px !important;
}

.builder--mb-0\.5{
  margin-bottom: -0.125rem !important;
}

.builder--mb-1\.5{
  margin-bottom: -0.375rem !important;
}

.builder--mb-2\.5{
  margin-bottom: -0.625rem !important;
}

.builder--mb-3\.5{
  margin-bottom: -0.875rem !important;
}

.builder-ml-0{
  margin-left: 0px !important;
}

.builder-ml-1{
  margin-left: 0.25rem !important;
}

.builder-ml-2{
  margin-left: 0.5rem !important;
}

.builder-ml-3{
  margin-left: 0.75rem !important;
}

.builder-ml-4{
  margin-left: 1rem !important;
}

.builder-ml-5{
  margin-left: 1.25rem !important;
}

.builder-ml-6{
  margin-left: 1.5rem !important;
}

.builder-ml-7{
  margin-left: 1.75rem !important;
}

.builder-ml-8{
  margin-left: 2rem !important;
}

.builder-ml-9{
  margin-left: 2.25rem !important;
}

.builder-ml-10{
  margin-left: 2.5rem !important;
}

.builder-ml-11{
  margin-left: 2.75rem !important;
}

.builder-ml-12{
  margin-left: 3rem !important;
}

.builder-ml-14{
  margin-left: 3.5rem !important;
}

.builder-ml-16{
  margin-left: 4rem !important;
}

.builder-ml-20{
  margin-left: 5rem !important;
}

.builder-ml-24{
  margin-left: 6rem !important;
}

.builder-ml-28{
  margin-left: 7rem !important;
}

.builder-ml-32{
  margin-left: 8rem !important;
}

.builder-ml-36{
  margin-left: 9rem !important;
}

.builder-ml-40{
  margin-left: 10rem !important;
}

.builder-ml-44{
  margin-left: 11rem !important;
}

.builder-ml-48{
  margin-left: 12rem !important;
}

.builder-ml-52{
  margin-left: 13rem !important;
}

.builder-ml-56{
  margin-left: 14rem !important;
}

.builder-ml-60{
  margin-left: 15rem !important;
}

.builder-ml-64{
  margin-left: 16rem !important;
}

.builder-ml-72{
  margin-left: 18rem !important;
}

.builder-ml-80{
  margin-left: 20rem !important;
}

.builder-ml-96{
  margin-left: 24rem !important;
}

.builder-ml-auto{
  margin-left: auto !important;
}

.builder-ml-px{
  margin-left: 1px !important;
}

.builder-ml-0\.5{
  margin-left: 0.125rem !important;
}

.builder-ml-1\.5{
  margin-left: 0.375rem !important;
}

.builder-ml-2\.5{
  margin-left: 0.625rem !important;
}

.builder-ml-3\.5{
  margin-left: 0.875rem !important;
}

.builder--ml-0{
  margin-left: 0px !important;
}

.builder--ml-1{
  margin-left: -0.25rem !important;
}

.builder--ml-2{
  margin-left: -0.5rem !important;
}

.builder--ml-3{
  margin-left: -0.75rem !important;
}

.builder--ml-4{
  margin-left: -1rem !important;
}

.builder--ml-5{
  margin-left: -1.25rem !important;
}

.builder--ml-6{
  margin-left: -1.5rem !important;
}

.builder--ml-7{
  margin-left: -1.75rem !important;
}

.builder--ml-8{
  margin-left: -2rem !important;
}

.builder--ml-9{
  margin-left: -2.25rem !important;
}

.builder--ml-10{
  margin-left: -2.5rem !important;
}

.builder--ml-11{
  margin-left: -2.75rem !important;
}

.builder--ml-12{
  margin-left: -3rem !important;
}

.builder--ml-14{
  margin-left: -3.5rem !important;
}

.builder--ml-16{
  margin-left: -4rem !important;
}

.builder--ml-20{
  margin-left: -5rem !important;
}

.builder--ml-24{
  margin-left: -6rem !important;
}

.builder--ml-28{
  margin-left: -7rem !important;
}

.builder--ml-32{
  margin-left: -8rem !important;
}

.builder--ml-36{
  margin-left: -9rem !important;
}

.builder--ml-40{
  margin-left: -10rem !important;
}

.builder--ml-44{
  margin-left: -11rem !important;
}

.builder--ml-48{
  margin-left: -12rem !important;
}

.builder--ml-52{
  margin-left: -13rem !important;
}

.builder--ml-56{
  margin-left: -14rem !important;
}

.builder--ml-60{
  margin-left: -15rem !important;
}

.builder--ml-64{
  margin-left: -16rem !important;
}

.builder--ml-72{
  margin-left: -18rem !important;
}

.builder--ml-80{
  margin-left: -20rem !important;
}

.builder--ml-96{
  margin-left: -24rem !important;
}

.builder--ml-px{
  margin-left: -1px !important;
}

.builder--ml-0\.5{
  margin-left: -0.125rem !important;
}

.builder--ml-1\.5{
  margin-left: -0.375rem !important;
}

.builder--ml-2\.5{
  margin-left: -0.625rem !important;
}

.builder--ml-3\.5{
  margin-left: -0.875rem !important;
}

.builder-box-border{
  box-sizing: border-box !important;
}

.builder-box-content{
  box-sizing: content-box !important;
}

.builder-block{
  display: block !important;
}

.builder-inline-block{
  display: inline-block !important;
}

.builder-inline{
  display: inline !important;
}

.builder-flex{
  display: flex !important;
}

.builder-inline-flex{
  display: inline-flex !important;
}

.builder-table{
  display: table !important;
}

.builder-inline-table{
  display: inline-table !important;
}

.builder-table-caption{
  display: table-caption !important;
}

.builder-table-cell{
  display: table-cell !important;
}

.builder-table-column{
  display: table-column !important;
}

.builder-table-column-group{
  display: table-column-group !important;
}

.builder-table-footer-group{
  display: table-footer-group !important;
}

.builder-table-header-group{
  display: table-header-group !important;
}

.builder-table-row-group{
  display: table-row-group !important;
}

.builder-table-row{
  display: table-row !important;
}

.builder-flow-root{
  display: flow-root !important;
}

.builder-grid{
  display: grid !important;
}

.builder-inline-grid{
  display: inline-grid !important;
}

.builder-contents{
  display: contents !important;
}

.builder-list-item{
  display: list-item !important;
}

.builder-hidden{
  display: none !important;
}

.builder-h-0{
  height: 0px !important;
}

.builder-h-1{
  height: 0.25rem !important;
}

.builder-h-2{
  height: 0.5rem !important;
}

.builder-h-3{
  height: 0.75rem !important;
}

.builder-h-4{
  height: 1rem !important;
}

.builder-h-5{
  height: 1.25rem !important;
}

.builder-h-6{
  height: 1.5rem !important;
}

.builder-h-7{
  height: 1.75rem !important;
}

.builder-h-8{
  height: 2rem !important;
}

.builder-h-9{
  height: 2.25rem !important;
}

.builder-h-10{
  height: 2.5rem !important;
}

.builder-h-11{
  height: 2.75rem !important;
}

.builder-h-12{
  height: 3rem !important;
}

.builder-h-14{
  height: 3.5rem !important;
}

.builder-h-16{
  height: 4rem !important;
}

.builder-h-20{
  height: 5rem !important;
}

.builder-h-24{
  height: 6rem !important;
}

.builder-h-28{
  height: 7rem !important;
}

.builder-h-32{
  height: 8rem !important;
}

.builder-h-36{
  height: 9rem !important;
}

.builder-h-40{
  height: 10rem !important;
}

.builder-h-44{
  height: 11rem !important;
}

.builder-h-48{
  height: 12rem !important;
}

.builder-h-52{
  height: 13rem !important;
}

.builder-h-56{
  height: 14rem !important;
}

.builder-h-60{
  height: 15rem !important;
}

.builder-h-64{
  height: 16rem !important;
}

.builder-h-72{
  height: 18rem !important;
}

.builder-h-80{
  height: 20rem !important;
}

.builder-h-96{
  height: 24rem !important;
}

.builder-h-auto{
  height: auto !important;
}

.builder-h-px{
  height: 1px !important;
}

.builder-h-0\.5{
  height: 0.125rem !important;
}

.builder-h-1\.5{
  height: 0.375rem !important;
}

.builder-h-2\.5{
  height: 0.625rem !important;
}

.builder-h-3\.5{
  height: 0.875rem !important;
}

.builder-h-1\/2{
  height: 50% !important;
}

.builder-h-1\/3{
  height: 33.333333% !important;
}

.builder-h-2\/3{
  height: 66.666667% !important;
}

.builder-h-1\/4{
  height: 25% !important;
}

.builder-h-2\/4{
  height: 50% !important;
}

.builder-h-3\/4{
  height: 75% !important;
}

.builder-h-1\/5{
  height: 20% !important;
}

.builder-h-2\/5{
  height: 40% !important;
}

.builder-h-3\/5{
  height: 60% !important;
}

.builder-h-4\/5{
  height: 80% !important;
}

.builder-h-1\/6{
  height: 16.666667% !important;
}

.builder-h-2\/6{
  height: 33.333333% !important;
}

.builder-h-3\/6{
  height: 50% !important;
}

.builder-h-4\/6{
  height: 66.666667% !important;
}

.builder-h-5\/6{
  height: 83.333333% !important;
}

.builder-h-full{
  height: 100% !important;
}

.builder-h-screen{
  height: 100vh !important;
}

.builder-max-h-0{
  max-height: 0px !important;
}

.builder-max-h-1{
  max-height: 0.25rem !important;
}

.builder-max-h-2{
  max-height: 0.5rem !important;
}

.builder-max-h-3{
  max-height: 0.75rem !important;
}

.builder-max-h-4{
  max-height: 1rem !important;
}

.builder-max-h-5{
  max-height: 1.25rem !important;
}

.builder-max-h-6{
  max-height: 1.5rem !important;
}

.builder-max-h-7{
  max-height: 1.75rem !important;
}

.builder-max-h-8{
  max-height: 2rem !important;
}

.builder-max-h-9{
  max-height: 2.25rem !important;
}

.builder-max-h-10{
  max-height: 2.5rem !important;
}

.builder-max-h-11{
  max-height: 2.75rem !important;
}

.builder-max-h-12{
  max-height: 3rem !important;
}

.builder-max-h-14{
  max-height: 3.5rem !important;
}

.builder-max-h-16{
  max-height: 4rem !important;
}

.builder-max-h-20{
  max-height: 5rem !important;
}

.builder-max-h-24{
  max-height: 6rem !important;
}

.builder-max-h-28{
  max-height: 7rem !important;
}

.builder-max-h-32{
  max-height: 8rem !important;
}

.builder-max-h-36{
  max-height: 9rem !important;
}

.builder-max-h-40{
  max-height: 10rem !important;
}

.builder-max-h-44{
  max-height: 11rem !important;
}

.builder-max-h-48{
  max-height: 12rem !important;
}

.builder-max-h-52{
  max-height: 13rem !important;
}

.builder-max-h-56{
  max-height: 14rem !important;
}

.builder-max-h-60{
  max-height: 15rem !important;
}

.builder-max-h-64{
  max-height: 16rem !important;
}

.builder-max-h-72{
  max-height: 18rem !important;
}

.builder-max-h-80{
  max-height: 20rem !important;
}

.builder-max-h-96{
  max-height: 24rem !important;
}

.builder-max-h-px{
  max-height: 1px !important;
}

.builder-max-h-0\.5{
  max-height: 0.125rem !important;
}

.builder-max-h-1\.5{
  max-height: 0.375rem !important;
}

.builder-max-h-2\.5{
  max-height: 0.625rem !important;
}

.builder-max-h-3\.5{
  max-height: 0.875rem !important;
}

.builder-max-h-full{
  max-height: 100% !important;
}

.builder-max-h-screen{
  max-height: 100vh !important;
}

.builder-min-h-0{
  min-height: 0px !important;
}

.builder-min-h-full{
  min-height: 100% !important;
}

.builder-min-h-screen{
  min-height: 100vh !important;
}

.builder-w-0{
  width: 0px !important;
}

.builder-w-1{
  width: 0.25rem !important;
}

.builder-w-2{
  width: 0.5rem !important;
}

.builder-w-3{
  width: 0.75rem !important;
}

.builder-w-4{
  width: 1rem !important;
}

.builder-w-5{
  width: 1.25rem !important;
}

.builder-w-6{
  width: 1.5rem !important;
}

.builder-w-7{
  width: 1.75rem !important;
}

.builder-w-8{
  width: 2rem !important;
}

.builder-w-9{
  width: 2.25rem !important;
}

.builder-w-10{
  width: 2.5rem !important;
}

.builder-w-11{
  width: 2.75rem !important;
}

.builder-w-12{
  width: 3rem !important;
}

.builder-w-14{
  width: 3.5rem !important;
}

.builder-w-16{
  width: 4rem !important;
}

.builder-w-20{
  width: 5rem !important;
}

.builder-w-24{
  width: 6rem !important;
}

.builder-w-28{
  width: 7rem !important;
}

.builder-w-32{
  width: 8rem !important;
}

.builder-w-36{
  width: 9rem !important;
}

.builder-w-40{
  width: 10rem !important;
}

.builder-w-44{
  width: 11rem !important;
}

.builder-w-48{
  width: 12rem !important;
}

.builder-w-52{
  width: 13rem !important;
}

.builder-w-56{
  width: 14rem !important;
}

.builder-w-60{
  width: 15rem !important;
}

.builder-w-64{
  width: 16rem !important;
}

.builder-w-72{
  width: 18rem !important;
}

.builder-w-80{
  width: 20rem !important;
}

.builder-w-96{
  width: 24rem !important;
}

.builder-w-auto{
  width: auto !important;
}

.builder-w-px{
  width: 1px !important;
}

.builder-w-0\.5{
  width: 0.125rem !important;
}

.builder-w-1\.5{
  width: 0.375rem !important;
}

.builder-w-2\.5{
  width: 0.625rem !important;
}

.builder-w-3\.5{
  width: 0.875rem !important;
}

.builder-w-1\/2{
  width: 50% !important;
}

.builder-w-1\/3{
  width: 33.333333% !important;
}

.builder-w-2\/3{
  width: 66.666667% !important;
}

.builder-w-1\/4{
  width: 25% !important;
}

.builder-w-2\/4{
  width: 50% !important;
}

.builder-w-3\/4{
  width: 75% !important;
}

.builder-w-1\/5{
  width: 20% !important;
}

.builder-w-2\/5{
  width: 40% !important;
}

.builder-w-3\/5{
  width: 60% !important;
}

.builder-w-4\/5{
  width: 80% !important;
}

.builder-w-1\/6{
  width: 16.666667% !important;
}

.builder-w-2\/6{
  width: 33.333333% !important;
}

.builder-w-3\/6{
  width: 50% !important;
}

.builder-w-4\/6{
  width: 66.666667% !important;
}

.builder-w-5\/6{
  width: 83.333333% !important;
}

.builder-w-1\/12{
  width: 8.333333% !important;
}

.builder-w-2\/12{
  width: 16.666667% !important;
}

.builder-w-3\/12{
  width: 25% !important;
}

.builder-w-4\/12{
  width: 33.333333% !important;
}

.builder-w-5\/12{
  width: 41.666667% !important;
}

.builder-w-6\/12{
  width: 50% !important;
}

.builder-w-7\/12{
  width: 58.333333% !important;
}

.builder-w-8\/12{
  width: 66.666667% !important;
}

.builder-w-9\/12{
  width: 75% !important;
}

.builder-w-10\/12{
  width: 83.333333% !important;
}

.builder-w-11\/12{
  width: 91.666667% !important;
}

.builder-w-full{
  width: 100% !important;
}

.builder-w-screen{
  width: 100vw !important;
}

.builder-w-min{
  width: min-content !important;
}

.builder-w-max{
  width: max-content !important;
}

.builder-min-w-0{
  min-width: 0px !important;
}

.builder-min-w-full{
  min-width: 100% !important;
}

.builder-min-w-min{
  min-width: min-content !important;
}

.builder-min-w-max{
  min-width: max-content !important;
}

.builder-max-w-0{
  max-width: 0rem !important;
}

.builder-max-w-none{
  max-width: none !important;
}

.builder-max-w-xs{
  max-width: 20rem !important;
}

.builder-max-w-sm{
  max-width: 24rem !important;
}

.builder-max-w-md{
  max-width: 28rem !important;
}

.builder-max-w-lg{
  max-width: 32rem !important;
}

.builder-max-w-xl{
  max-width: 36rem !important;
}

.builder-max-w-2xl{
  max-width: 42rem !important;
}

.builder-max-w-3xl{
  max-width: 48rem !important;
}

.builder-max-w-4xl{
  max-width: 56rem !important;
}

.builder-max-w-5xl{
  max-width: 64rem !important;
}

.builder-max-w-6xl{
  max-width: 72rem !important;
}

.builder-max-w-7xl{
  max-width: 80rem !important;
}

.builder-max-w-full{
  max-width: 100% !important;
}

.builder-max-w-min{
  max-width: min-content !important;
}

.builder-max-w-max{
  max-width: max-content !important;
}

.builder-max-w-prose{
  max-width: 65ch !important;
}

.builder-max-w-screen-sm{
  max-width: 640px !important;
}

.builder-max-w-screen-md{
  max-width: 768px !important;
}

.builder-max-w-screen-lg{
  max-width: 1024px !important;
}

.builder-max-w-screen-xl{
  max-width: 1280px !important;
}

.builder-max-w-screen-2xl{
  max-width: 1536px !important;
}

.builder-flex-1{
  flex: 1 1 0% !important;
}

.builder-flex-auto{
  flex: 1 1 auto !important;
}

.builder-flex-initial{
  flex: 0 1 auto !important;
}

.builder-flex-none{
  flex: none !important;
}

.builder-flex-shrink-0{
  flex-shrink: 0 !important;
}

.builder-flex-shrink{
  flex-shrink: 1 !important;
}

.builder-flex-grow-0{
  flex-grow: 0 !important;
}

.builder-flex-grow{
  flex-grow: 1 !important;
}

.builder-table-auto{
  table-layout: auto !important;
}

.builder-table-fixed{
  table-layout: fixed !important;
}

.builder-border-collapse{
  border-collapse: collapse !important;
}

.builder-border-separate{
  border-collapse: separate !important;
}

.builder-origin-center{
  transform-origin: center !important;
}

.builder-origin-top{
  transform-origin: top !important;
}

.builder-origin-top-right{
  transform-origin: top right !important;
}

.builder-origin-right{
  transform-origin: right !important;
}

.builder-origin-bottom-right{
  transform-origin: bottom right !important;
}

.builder-origin-bottom{
  transform-origin: bottom !important;
}

.builder-origin-bottom-left{
  transform-origin: bottom left !important;
}

.builder-origin-left{
  transform-origin: left !important;
}

.builder-origin-top-left{
  transform-origin: top left !important;
}

.builder-transform{
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.builder-transform-gpu{
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.builder-transform-none{
  transform: none !important;
}

.builder-translate-x-0{
  --tw-translate-x: 0px !important;
}

.builder-translate-x-1{
  --tw-translate-x: 0.25rem !important;
}

.builder-translate-x-2{
  --tw-translate-x: 0.5rem !important;
}

.builder-translate-x-3{
  --tw-translate-x: 0.75rem !important;
}

.builder-translate-x-4{
  --tw-translate-x: 1rem !important;
}

.builder-translate-x-5{
  --tw-translate-x: 1.25rem !important;
}

.builder-translate-x-6{
  --tw-translate-x: 1.5rem !important;
}

.builder-translate-x-7{
  --tw-translate-x: 1.75rem !important;
}

.builder-translate-x-8{
  --tw-translate-x: 2rem !important;
}

.builder-translate-x-9{
  --tw-translate-x: 2.25rem !important;
}

.builder-translate-x-10{
  --tw-translate-x: 2.5rem !important;
}

.builder-translate-x-11{
  --tw-translate-x: 2.75rem !important;
}

.builder-translate-x-12{
  --tw-translate-x: 3rem !important;
}

.builder-translate-x-14{
  --tw-translate-x: 3.5rem !important;
}

.builder-translate-x-16{
  --tw-translate-x: 4rem !important;
}

.builder-translate-x-20{
  --tw-translate-x: 5rem !important;
}

.builder-translate-x-24{
  --tw-translate-x: 6rem !important;
}

.builder-translate-x-28{
  --tw-translate-x: 7rem !important;
}

.builder-translate-x-32{
  --tw-translate-x: 8rem !important;
}

.builder-translate-x-36{
  --tw-translate-x: 9rem !important;
}

.builder-translate-x-40{
  --tw-translate-x: 10rem !important;
}

.builder-translate-x-44{
  --tw-translate-x: 11rem !important;
}

.builder-translate-x-48{
  --tw-translate-x: 12rem !important;
}

.builder-translate-x-52{
  --tw-translate-x: 13rem !important;
}

.builder-translate-x-56{
  --tw-translate-x: 14rem !important;
}

.builder-translate-x-60{
  --tw-translate-x: 15rem !important;
}

.builder-translate-x-64{
  --tw-translate-x: 16rem !important;
}

.builder-translate-x-72{
  --tw-translate-x: 18rem !important;
}

.builder-translate-x-80{
  --tw-translate-x: 20rem !important;
}

.builder-translate-x-96{
  --tw-translate-x: 24rem !important;
}

.builder-translate-x-px{
  --tw-translate-x: 1px !important;
}

.builder-translate-x-0\.5{
  --tw-translate-x: 0.125rem !important;
}

.builder-translate-x-1\.5{
  --tw-translate-x: 0.375rem !important;
}

.builder-translate-x-2\.5{
  --tw-translate-x: 0.625rem !important;
}

.builder-translate-x-3\.5{
  --tw-translate-x: 0.875rem !important;
}

.builder--translate-x-0{
  --tw-translate-x: 0px !important;
}

.builder--translate-x-1{
  --tw-translate-x: -0.25rem !important;
}

.builder--translate-x-2{
  --tw-translate-x: -0.5rem !important;
}

.builder--translate-x-3{
  --tw-translate-x: -0.75rem !important;
}

.builder--translate-x-4{
  --tw-translate-x: -1rem !important;
}

.builder--translate-x-5{
  --tw-translate-x: -1.25rem !important;
}

.builder--translate-x-6{
  --tw-translate-x: -1.5rem !important;
}

.builder--translate-x-7{
  --tw-translate-x: -1.75rem !important;
}

.builder--translate-x-8{
  --tw-translate-x: -2rem !important;
}

.builder--translate-x-9{
  --tw-translate-x: -2.25rem !important;
}

.builder--translate-x-10{
  --tw-translate-x: -2.5rem !important;
}

.builder--translate-x-11{
  --tw-translate-x: -2.75rem !important;
}

.builder--translate-x-12{
  --tw-translate-x: -3rem !important;
}

.builder--translate-x-14{
  --tw-translate-x: -3.5rem !important;
}

.builder--translate-x-16{
  --tw-translate-x: -4rem !important;
}

.builder--translate-x-20{
  --tw-translate-x: -5rem !important;
}

.builder--translate-x-24{
  --tw-translate-x: -6rem !important;
}

.builder--translate-x-28{
  --tw-translate-x: -7rem !important;
}

.builder--translate-x-32{
  --tw-translate-x: -8rem !important;
}

.builder--translate-x-36{
  --tw-translate-x: -9rem !important;
}

.builder--translate-x-40{
  --tw-translate-x: -10rem !important;
}

.builder--translate-x-44{
  --tw-translate-x: -11rem !important;
}

.builder--translate-x-48{
  --tw-translate-x: -12rem !important;
}

.builder--translate-x-52{
  --tw-translate-x: -13rem !important;
}

.builder--translate-x-56{
  --tw-translate-x: -14rem !important;
}

.builder--translate-x-60{
  --tw-translate-x: -15rem !important;
}

.builder--translate-x-64{
  --tw-translate-x: -16rem !important;
}

.builder--translate-x-72{
  --tw-translate-x: -18rem !important;
}

.builder--translate-x-80{
  --tw-translate-x: -20rem !important;
}

.builder--translate-x-96{
  --tw-translate-x: -24rem !important;
}

.builder--translate-x-px{
  --tw-translate-x: -1px !important;
}

.builder--translate-x-0\.5{
  --tw-translate-x: -0.125rem !important;
}

.builder--translate-x-1\.5{
  --tw-translate-x: -0.375rem !important;
}

.builder--translate-x-2\.5{
  --tw-translate-x: -0.625rem !important;
}

.builder--translate-x-3\.5{
  --tw-translate-x: -0.875rem !important;
}

.builder-translate-x-1\/2{
  --tw-translate-x: 50% !important;
}

.builder-translate-x-1\/3{
  --tw-translate-x: 33.333333% !important;
}

.builder-translate-x-2\/3{
  --tw-translate-x: 66.666667% !important;
}

.builder-translate-x-1\/4{
  --tw-translate-x: 25% !important;
}

.builder-translate-x-2\/4{
  --tw-translate-x: 50% !important;
}

.builder-translate-x-3\/4{
  --tw-translate-x: 75% !important;
}

.builder-translate-x-full{
  --tw-translate-x: 100% !important;
}

.builder--translate-x-1\/2{
  --tw-translate-x: -50% !important;
}

.builder--translate-x-1\/3{
  --tw-translate-x: -33.333333% !important;
}

.builder--translate-x-2\/3{
  --tw-translate-x: -66.666667% !important;
}

.builder--translate-x-1\/4{
  --tw-translate-x: -25% !important;
}

.builder--translate-x-2\/4{
  --tw-translate-x: -50% !important;
}

.builder--translate-x-3\/4{
  --tw-translate-x: -75% !important;
}

.builder--translate-x-full{
  --tw-translate-x: -100% !important;
}

.builder-translate-y-0{
  --tw-translate-y: 0px !important;
}

.builder-translate-y-1{
  --tw-translate-y: 0.25rem !important;
}

.builder-translate-y-2{
  --tw-translate-y: 0.5rem !important;
}

.builder-translate-y-3{
  --tw-translate-y: 0.75rem !important;
}

.builder-translate-y-4{
  --tw-translate-y: 1rem !important;
}

.builder-translate-y-5{
  --tw-translate-y: 1.25rem !important;
}

.builder-translate-y-6{
  --tw-translate-y: 1.5rem !important;
}

.builder-translate-y-7{
  --tw-translate-y: 1.75rem !important;
}

.builder-translate-y-8{
  --tw-translate-y: 2rem !important;
}

.builder-translate-y-9{
  --tw-translate-y: 2.25rem !important;
}

.builder-translate-y-10{
  --tw-translate-y: 2.5rem !important;
}

.builder-translate-y-11{
  --tw-translate-y: 2.75rem !important;
}

.builder-translate-y-12{
  --tw-translate-y: 3rem !important;
}

.builder-translate-y-14{
  --tw-translate-y: 3.5rem !important;
}

.builder-translate-y-16{
  --tw-translate-y: 4rem !important;
}

.builder-translate-y-20{
  --tw-translate-y: 5rem !important;
}

.builder-translate-y-24{
  --tw-translate-y: 6rem !important;
}

.builder-translate-y-28{
  --tw-translate-y: 7rem !important;
}

.builder-translate-y-32{
  --tw-translate-y: 8rem !important;
}

.builder-translate-y-36{
  --tw-translate-y: 9rem !important;
}

.builder-translate-y-40{
  --tw-translate-y: 10rem !important;
}

.builder-translate-y-44{
  --tw-translate-y: 11rem !important;
}

.builder-translate-y-48{
  --tw-translate-y: 12rem !important;
}

.builder-translate-y-52{
  --tw-translate-y: 13rem !important;
}

.builder-translate-y-56{
  --tw-translate-y: 14rem !important;
}

.builder-translate-y-60{
  --tw-translate-y: 15rem !important;
}

.builder-translate-y-64{
  --tw-translate-y: 16rem !important;
}

.builder-translate-y-72{
  --tw-translate-y: 18rem !important;
}

.builder-translate-y-80{
  --tw-translate-y: 20rem !important;
}

.builder-translate-y-96{
  --tw-translate-y: 24rem !important;
}

.builder-translate-y-px{
  --tw-translate-y: 1px !important;
}

.builder-translate-y-0\.5{
  --tw-translate-y: 0.125rem !important;
}

.builder-translate-y-1\.5{
  --tw-translate-y: 0.375rem !important;
}

.builder-translate-y-2\.5{
  --tw-translate-y: 0.625rem !important;
}

.builder-translate-y-3\.5{
  --tw-translate-y: 0.875rem !important;
}

.builder--translate-y-0{
  --tw-translate-y: 0px !important;
}

.builder--translate-y-1{
  --tw-translate-y: -0.25rem !important;
}

.builder--translate-y-2{
  --tw-translate-y: -0.5rem !important;
}

.builder--translate-y-3{
  --tw-translate-y: -0.75rem !important;
}

.builder--translate-y-4{
  --tw-translate-y: -1rem !important;
}

.builder--translate-y-5{
  --tw-translate-y: -1.25rem !important;
}

.builder--translate-y-6{
  --tw-translate-y: -1.5rem !important;
}

.builder--translate-y-7{
  --tw-translate-y: -1.75rem !important;
}

.builder--translate-y-8{
  --tw-translate-y: -2rem !important;
}

.builder--translate-y-9{
  --tw-translate-y: -2.25rem !important;
}

.builder--translate-y-10{
  --tw-translate-y: -2.5rem !important;
}

.builder--translate-y-11{
  --tw-translate-y: -2.75rem !important;
}

.builder--translate-y-12{
  --tw-translate-y: -3rem !important;
}

.builder--translate-y-14{
  --tw-translate-y: -3.5rem !important;
}

.builder--translate-y-16{
  --tw-translate-y: -4rem !important;
}

.builder--translate-y-20{
  --tw-translate-y: -5rem !important;
}

.builder--translate-y-24{
  --tw-translate-y: -6rem !important;
}

.builder--translate-y-28{
  --tw-translate-y: -7rem !important;
}

.builder--translate-y-32{
  --tw-translate-y: -8rem !important;
}

.builder--translate-y-36{
  --tw-translate-y: -9rem !important;
}

.builder--translate-y-40{
  --tw-translate-y: -10rem !important;
}

.builder--translate-y-44{
  --tw-translate-y: -11rem !important;
}

.builder--translate-y-48{
  --tw-translate-y: -12rem !important;
}

.builder--translate-y-52{
  --tw-translate-y: -13rem !important;
}

.builder--translate-y-56{
  --tw-translate-y: -14rem !important;
}

.builder--translate-y-60{
  --tw-translate-y: -15rem !important;
}

.builder--translate-y-64{
  --tw-translate-y: -16rem !important;
}

.builder--translate-y-72{
  --tw-translate-y: -18rem !important;
}

.builder--translate-y-80{
  --tw-translate-y: -20rem !important;
}

.builder--translate-y-96{
  --tw-translate-y: -24rem !important;
}

.builder--translate-y-px{
  --tw-translate-y: -1px !important;
}

.builder--translate-y-0\.5{
  --tw-translate-y: -0.125rem !important;
}

.builder--translate-y-1\.5{
  --tw-translate-y: -0.375rem !important;
}

.builder--translate-y-2\.5{
  --tw-translate-y: -0.625rem !important;
}

.builder--translate-y-3\.5{
  --tw-translate-y: -0.875rem !important;
}

.builder-translate-y-1\/2{
  --tw-translate-y: 50% !important;
}

.builder-translate-y-1\/3{
  --tw-translate-y: 33.333333% !important;
}

.builder-translate-y-2\/3{
  --tw-translate-y: 66.666667% !important;
}

.builder-translate-y-1\/4{
  --tw-translate-y: 25% !important;
}

.builder-translate-y-2\/4{
  --tw-translate-y: 50% !important;
}

.builder-translate-y-3\/4{
  --tw-translate-y: 75% !important;
}

.builder-translate-y-full{
  --tw-translate-y: 100% !important;
}

.builder--translate-y-1\/2{
  --tw-translate-y: -50% !important;
}

.builder--translate-y-1\/3{
  --tw-translate-y: -33.333333% !important;
}

.builder--translate-y-2\/3{
  --tw-translate-y: -66.666667% !important;
}

.builder--translate-y-1\/4{
  --tw-translate-y: -25% !important;
}

.builder--translate-y-2\/4{
  --tw-translate-y: -50% !important;
}

.builder--translate-y-3\/4{
  --tw-translate-y: -75% !important;
}

.builder--translate-y-full{
  --tw-translate-y: -100% !important;
}

.hover\:builder-translate-x-0:hover{
  --tw-translate-x: 0px !important;
}

.hover\:builder-translate-x-1:hover{
  --tw-translate-x: 0.25rem !important;
}

.hover\:builder-translate-x-2:hover{
  --tw-translate-x: 0.5rem !important;
}

.hover\:builder-translate-x-3:hover{
  --tw-translate-x: 0.75rem !important;
}

.hover\:builder-translate-x-4:hover{
  --tw-translate-x: 1rem !important;
}

.hover\:builder-translate-x-5:hover{
  --tw-translate-x: 1.25rem !important;
}

.hover\:builder-translate-x-6:hover{
  --tw-translate-x: 1.5rem !important;
}

.hover\:builder-translate-x-7:hover{
  --tw-translate-x: 1.75rem !important;
}

.hover\:builder-translate-x-8:hover{
  --tw-translate-x: 2rem !important;
}

.hover\:builder-translate-x-9:hover{
  --tw-translate-x: 2.25rem !important;
}

.hover\:builder-translate-x-10:hover{
  --tw-translate-x: 2.5rem !important;
}

.hover\:builder-translate-x-11:hover{
  --tw-translate-x: 2.75rem !important;
}

.hover\:builder-translate-x-12:hover{
  --tw-translate-x: 3rem !important;
}

.hover\:builder-translate-x-14:hover{
  --tw-translate-x: 3.5rem !important;
}

.hover\:builder-translate-x-16:hover{
  --tw-translate-x: 4rem !important;
}

.hover\:builder-translate-x-20:hover{
  --tw-translate-x: 5rem !important;
}

.hover\:builder-translate-x-24:hover{
  --tw-translate-x: 6rem !important;
}

.hover\:builder-translate-x-28:hover{
  --tw-translate-x: 7rem !important;
}

.hover\:builder-translate-x-32:hover{
  --tw-translate-x: 8rem !important;
}

.hover\:builder-translate-x-36:hover{
  --tw-translate-x: 9rem !important;
}

.hover\:builder-translate-x-40:hover{
  --tw-translate-x: 10rem !important;
}

.hover\:builder-translate-x-44:hover{
  --tw-translate-x: 11rem !important;
}

.hover\:builder-translate-x-48:hover{
  --tw-translate-x: 12rem !important;
}

.hover\:builder-translate-x-52:hover{
  --tw-translate-x: 13rem !important;
}

.hover\:builder-translate-x-56:hover{
  --tw-translate-x: 14rem !important;
}

.hover\:builder-translate-x-60:hover{
  --tw-translate-x: 15rem !important;
}

.hover\:builder-translate-x-64:hover{
  --tw-translate-x: 16rem !important;
}

.hover\:builder-translate-x-72:hover{
  --tw-translate-x: 18rem !important;
}

.hover\:builder-translate-x-80:hover{
  --tw-translate-x: 20rem !important;
}

.hover\:builder-translate-x-96:hover{
  --tw-translate-x: 24rem !important;
}

.hover\:builder-translate-x-px:hover{
  --tw-translate-x: 1px !important;
}

.hover\:builder-translate-x-0\.5:hover{
  --tw-translate-x: 0.125rem !important;
}

.hover\:builder-translate-x-1\.5:hover{
  --tw-translate-x: 0.375rem !important;
}

.hover\:builder-translate-x-2\.5:hover{
  --tw-translate-x: 0.625rem !important;
}

.hover\:builder-translate-x-3\.5:hover{
  --tw-translate-x: 0.875rem !important;
}

.hover\:builder--translate-x-0:hover{
  --tw-translate-x: 0px !important;
}

.hover\:builder--translate-x-1:hover{
  --tw-translate-x: -0.25rem !important;
}

.hover\:builder--translate-x-2:hover{
  --tw-translate-x: -0.5rem !important;
}

.hover\:builder--translate-x-3:hover{
  --tw-translate-x: -0.75rem !important;
}

.hover\:builder--translate-x-4:hover{
  --tw-translate-x: -1rem !important;
}

.hover\:builder--translate-x-5:hover{
  --tw-translate-x: -1.25rem !important;
}

.hover\:builder--translate-x-6:hover{
  --tw-translate-x: -1.5rem !important;
}

.hover\:builder--translate-x-7:hover{
  --tw-translate-x: -1.75rem !important;
}

.hover\:builder--translate-x-8:hover{
  --tw-translate-x: -2rem !important;
}

.hover\:builder--translate-x-9:hover{
  --tw-translate-x: -2.25rem !important;
}

.hover\:builder--translate-x-10:hover{
  --tw-translate-x: -2.5rem !important;
}

.hover\:builder--translate-x-11:hover{
  --tw-translate-x: -2.75rem !important;
}

.hover\:builder--translate-x-12:hover{
  --tw-translate-x: -3rem !important;
}

.hover\:builder--translate-x-14:hover{
  --tw-translate-x: -3.5rem !important;
}

.hover\:builder--translate-x-16:hover{
  --tw-translate-x: -4rem !important;
}

.hover\:builder--translate-x-20:hover{
  --tw-translate-x: -5rem !important;
}

.hover\:builder--translate-x-24:hover{
  --tw-translate-x: -6rem !important;
}

.hover\:builder--translate-x-28:hover{
  --tw-translate-x: -7rem !important;
}

.hover\:builder--translate-x-32:hover{
  --tw-translate-x: -8rem !important;
}

.hover\:builder--translate-x-36:hover{
  --tw-translate-x: -9rem !important;
}

.hover\:builder--translate-x-40:hover{
  --tw-translate-x: -10rem !important;
}

.hover\:builder--translate-x-44:hover{
  --tw-translate-x: -11rem !important;
}

.hover\:builder--translate-x-48:hover{
  --tw-translate-x: -12rem !important;
}

.hover\:builder--translate-x-52:hover{
  --tw-translate-x: -13rem !important;
}

.hover\:builder--translate-x-56:hover{
  --tw-translate-x: -14rem !important;
}

.hover\:builder--translate-x-60:hover{
  --tw-translate-x: -15rem !important;
}

.hover\:builder--translate-x-64:hover{
  --tw-translate-x: -16rem !important;
}

.hover\:builder--translate-x-72:hover{
  --tw-translate-x: -18rem !important;
}

.hover\:builder--translate-x-80:hover{
  --tw-translate-x: -20rem !important;
}

.hover\:builder--translate-x-96:hover{
  --tw-translate-x: -24rem !important;
}

.hover\:builder--translate-x-px:hover{
  --tw-translate-x: -1px !important;
}

.hover\:builder--translate-x-0\.5:hover{
  --tw-translate-x: -0.125rem !important;
}

.hover\:builder--translate-x-1\.5:hover{
  --tw-translate-x: -0.375rem !important;
}

.hover\:builder--translate-x-2\.5:hover{
  --tw-translate-x: -0.625rem !important;
}

.hover\:builder--translate-x-3\.5:hover{
  --tw-translate-x: -0.875rem !important;
}

.hover\:builder-translate-x-1\/2:hover{
  --tw-translate-x: 50% !important;
}

.hover\:builder-translate-x-1\/3:hover{
  --tw-translate-x: 33.333333% !important;
}

.hover\:builder-translate-x-2\/3:hover{
  --tw-translate-x: 66.666667% !important;
}

.hover\:builder-translate-x-1\/4:hover{
  --tw-translate-x: 25% !important;
}

.hover\:builder-translate-x-2\/4:hover{
  --tw-translate-x: 50% !important;
}

.hover\:builder-translate-x-3\/4:hover{
  --tw-translate-x: 75% !important;
}

.hover\:builder-translate-x-full:hover{
  --tw-translate-x: 100% !important;
}

.hover\:builder--translate-x-1\/2:hover{
  --tw-translate-x: -50% !important;
}

.hover\:builder--translate-x-1\/3:hover{
  --tw-translate-x: -33.333333% !important;
}

.hover\:builder--translate-x-2\/3:hover{
  --tw-translate-x: -66.666667% !important;
}

.hover\:builder--translate-x-1\/4:hover{
  --tw-translate-x: -25% !important;
}

.hover\:builder--translate-x-2\/4:hover{
  --tw-translate-x: -50% !important;
}

.hover\:builder--translate-x-3\/4:hover{
  --tw-translate-x: -75% !important;
}

.hover\:builder--translate-x-full:hover{
  --tw-translate-x: -100% !important;
}

.hover\:builder-translate-y-0:hover{
  --tw-translate-y: 0px !important;
}

.hover\:builder-translate-y-1:hover{
  --tw-translate-y: 0.25rem !important;
}

.hover\:builder-translate-y-2:hover{
  --tw-translate-y: 0.5rem !important;
}

.hover\:builder-translate-y-3:hover{
  --tw-translate-y: 0.75rem !important;
}

.hover\:builder-translate-y-4:hover{
  --tw-translate-y: 1rem !important;
}

.hover\:builder-translate-y-5:hover{
  --tw-translate-y: 1.25rem !important;
}

.hover\:builder-translate-y-6:hover{
  --tw-translate-y: 1.5rem !important;
}

.hover\:builder-translate-y-7:hover{
  --tw-translate-y: 1.75rem !important;
}

.hover\:builder-translate-y-8:hover{
  --tw-translate-y: 2rem !important;
}

.hover\:builder-translate-y-9:hover{
  --tw-translate-y: 2.25rem !important;
}

.hover\:builder-translate-y-10:hover{
  --tw-translate-y: 2.5rem !important;
}

.hover\:builder-translate-y-11:hover{
  --tw-translate-y: 2.75rem !important;
}

.hover\:builder-translate-y-12:hover{
  --tw-translate-y: 3rem !important;
}

.hover\:builder-translate-y-14:hover{
  --tw-translate-y: 3.5rem !important;
}

.hover\:builder-translate-y-16:hover{
  --tw-translate-y: 4rem !important;
}

.hover\:builder-translate-y-20:hover{
  --tw-translate-y: 5rem !important;
}

.hover\:builder-translate-y-24:hover{
  --tw-translate-y: 6rem !important;
}

.hover\:builder-translate-y-28:hover{
  --tw-translate-y: 7rem !important;
}

.hover\:builder-translate-y-32:hover{
  --tw-translate-y: 8rem !important;
}

.hover\:builder-translate-y-36:hover{
  --tw-translate-y: 9rem !important;
}

.hover\:builder-translate-y-40:hover{
  --tw-translate-y: 10rem !important;
}

.hover\:builder-translate-y-44:hover{
  --tw-translate-y: 11rem !important;
}

.hover\:builder-translate-y-48:hover{
  --tw-translate-y: 12rem !important;
}

.hover\:builder-translate-y-52:hover{
  --tw-translate-y: 13rem !important;
}

.hover\:builder-translate-y-56:hover{
  --tw-translate-y: 14rem !important;
}

.hover\:builder-translate-y-60:hover{
  --tw-translate-y: 15rem !important;
}

.hover\:builder-translate-y-64:hover{
  --tw-translate-y: 16rem !important;
}

.hover\:builder-translate-y-72:hover{
  --tw-translate-y: 18rem !important;
}

.hover\:builder-translate-y-80:hover{
  --tw-translate-y: 20rem !important;
}

.hover\:builder-translate-y-96:hover{
  --tw-translate-y: 24rem !important;
}

.hover\:builder-translate-y-px:hover{
  --tw-translate-y: 1px !important;
}

.hover\:builder-translate-y-0\.5:hover{
  --tw-translate-y: 0.125rem !important;
}

.hover\:builder-translate-y-1\.5:hover{
  --tw-translate-y: 0.375rem !important;
}

.hover\:builder-translate-y-2\.5:hover{
  --tw-translate-y: 0.625rem !important;
}

.hover\:builder-translate-y-3\.5:hover{
  --tw-translate-y: 0.875rem !important;
}

.hover\:builder--translate-y-0:hover{
  --tw-translate-y: 0px !important;
}

.hover\:builder--translate-y-1:hover{
  --tw-translate-y: -0.25rem !important;
}

.hover\:builder--translate-y-2:hover{
  --tw-translate-y: -0.5rem !important;
}

.hover\:builder--translate-y-3:hover{
  --tw-translate-y: -0.75rem !important;
}

.hover\:builder--translate-y-4:hover{
  --tw-translate-y: -1rem !important;
}

.hover\:builder--translate-y-5:hover{
  --tw-translate-y: -1.25rem !important;
}

.hover\:builder--translate-y-6:hover{
  --tw-translate-y: -1.5rem !important;
}

.hover\:builder--translate-y-7:hover{
  --tw-translate-y: -1.75rem !important;
}

.hover\:builder--translate-y-8:hover{
  --tw-translate-y: -2rem !important;
}

.hover\:builder--translate-y-9:hover{
  --tw-translate-y: -2.25rem !important;
}

.hover\:builder--translate-y-10:hover{
  --tw-translate-y: -2.5rem !important;
}

.hover\:builder--translate-y-11:hover{
  --tw-translate-y: -2.75rem !important;
}

.hover\:builder--translate-y-12:hover{
  --tw-translate-y: -3rem !important;
}

.hover\:builder--translate-y-14:hover{
  --tw-translate-y: -3.5rem !important;
}

.hover\:builder--translate-y-16:hover{
  --tw-translate-y: -4rem !important;
}

.hover\:builder--translate-y-20:hover{
  --tw-translate-y: -5rem !important;
}

.hover\:builder--translate-y-24:hover{
  --tw-translate-y: -6rem !important;
}

.hover\:builder--translate-y-28:hover{
  --tw-translate-y: -7rem !important;
}

.hover\:builder--translate-y-32:hover{
  --tw-translate-y: -8rem !important;
}

.hover\:builder--translate-y-36:hover{
  --tw-translate-y: -9rem !important;
}

.hover\:builder--translate-y-40:hover{
  --tw-translate-y: -10rem !important;
}

.hover\:builder--translate-y-44:hover{
  --tw-translate-y: -11rem !important;
}

.hover\:builder--translate-y-48:hover{
  --tw-translate-y: -12rem !important;
}

.hover\:builder--translate-y-52:hover{
  --tw-translate-y: -13rem !important;
}

.hover\:builder--translate-y-56:hover{
  --tw-translate-y: -14rem !important;
}

.hover\:builder--translate-y-60:hover{
  --tw-translate-y: -15rem !important;
}

.hover\:builder--translate-y-64:hover{
  --tw-translate-y: -16rem !important;
}

.hover\:builder--translate-y-72:hover{
  --tw-translate-y: -18rem !important;
}

.hover\:builder--translate-y-80:hover{
  --tw-translate-y: -20rem !important;
}

.hover\:builder--translate-y-96:hover{
  --tw-translate-y: -24rem !important;
}

.hover\:builder--translate-y-px:hover{
  --tw-translate-y: -1px !important;
}

.hover\:builder--translate-y-0\.5:hover{
  --tw-translate-y: -0.125rem !important;
}

.hover\:builder--translate-y-1\.5:hover{
  --tw-translate-y: -0.375rem !important;
}

.hover\:builder--translate-y-2\.5:hover{
  --tw-translate-y: -0.625rem !important;
}

.hover\:builder--translate-y-3\.5:hover{
  --tw-translate-y: -0.875rem !important;
}

.hover\:builder-translate-y-1\/2:hover{
  --tw-translate-y: 50% !important;
}

.hover\:builder-translate-y-1\/3:hover{
  --tw-translate-y: 33.333333% !important;
}

.hover\:builder-translate-y-2\/3:hover{
  --tw-translate-y: 66.666667% !important;
}

.hover\:builder-translate-y-1\/4:hover{
  --tw-translate-y: 25% !important;
}

.hover\:builder-translate-y-2\/4:hover{
  --tw-translate-y: 50% !important;
}

.hover\:builder-translate-y-3\/4:hover{
  --tw-translate-y: 75% !important;
}

.hover\:builder-translate-y-full:hover{
  --tw-translate-y: 100% !important;
}

.hover\:builder--translate-y-1\/2:hover{
  --tw-translate-y: -50% !important;
}

.hover\:builder--translate-y-1\/3:hover{
  --tw-translate-y: -33.333333% !important;
}

.hover\:builder--translate-y-2\/3:hover{
  --tw-translate-y: -66.666667% !important;
}

.hover\:builder--translate-y-1\/4:hover{
  --tw-translate-y: -25% !important;
}

.hover\:builder--translate-y-2\/4:hover{
  --tw-translate-y: -50% !important;
}

.hover\:builder--translate-y-3\/4:hover{
  --tw-translate-y: -75% !important;
}

.hover\:builder--translate-y-full:hover{
  --tw-translate-y: -100% !important;
}

.focus\:builder-translate-x-0:focus{
  --tw-translate-x: 0px !important;
}

.focus\:builder-translate-x-1:focus{
  --tw-translate-x: 0.25rem !important;
}

.focus\:builder-translate-x-2:focus{
  --tw-translate-x: 0.5rem !important;
}

.focus\:builder-translate-x-3:focus{
  --tw-translate-x: 0.75rem !important;
}

.focus\:builder-translate-x-4:focus{
  --tw-translate-x: 1rem !important;
}

.focus\:builder-translate-x-5:focus{
  --tw-translate-x: 1.25rem !important;
}

.focus\:builder-translate-x-6:focus{
  --tw-translate-x: 1.5rem !important;
}

.focus\:builder-translate-x-7:focus{
  --tw-translate-x: 1.75rem !important;
}

.focus\:builder-translate-x-8:focus{
  --tw-translate-x: 2rem !important;
}

.focus\:builder-translate-x-9:focus{
  --tw-translate-x: 2.25rem !important;
}

.focus\:builder-translate-x-10:focus{
  --tw-translate-x: 2.5rem !important;
}

.focus\:builder-translate-x-11:focus{
  --tw-translate-x: 2.75rem !important;
}

.focus\:builder-translate-x-12:focus{
  --tw-translate-x: 3rem !important;
}

.focus\:builder-translate-x-14:focus{
  --tw-translate-x: 3.5rem !important;
}

.focus\:builder-translate-x-16:focus{
  --tw-translate-x: 4rem !important;
}

.focus\:builder-translate-x-20:focus{
  --tw-translate-x: 5rem !important;
}

.focus\:builder-translate-x-24:focus{
  --tw-translate-x: 6rem !important;
}

.focus\:builder-translate-x-28:focus{
  --tw-translate-x: 7rem !important;
}

.focus\:builder-translate-x-32:focus{
  --tw-translate-x: 8rem !important;
}

.focus\:builder-translate-x-36:focus{
  --tw-translate-x: 9rem !important;
}

.focus\:builder-translate-x-40:focus{
  --tw-translate-x: 10rem !important;
}

.focus\:builder-translate-x-44:focus{
  --tw-translate-x: 11rem !important;
}

.focus\:builder-translate-x-48:focus{
  --tw-translate-x: 12rem !important;
}

.focus\:builder-translate-x-52:focus{
  --tw-translate-x: 13rem !important;
}

.focus\:builder-translate-x-56:focus{
  --tw-translate-x: 14rem !important;
}

.focus\:builder-translate-x-60:focus{
  --tw-translate-x: 15rem !important;
}

.focus\:builder-translate-x-64:focus{
  --tw-translate-x: 16rem !important;
}

.focus\:builder-translate-x-72:focus{
  --tw-translate-x: 18rem !important;
}

.focus\:builder-translate-x-80:focus{
  --tw-translate-x: 20rem !important;
}

.focus\:builder-translate-x-96:focus{
  --tw-translate-x: 24rem !important;
}

.focus\:builder-translate-x-px:focus{
  --tw-translate-x: 1px !important;
}

.focus\:builder-translate-x-0\.5:focus{
  --tw-translate-x: 0.125rem !important;
}

.focus\:builder-translate-x-1\.5:focus{
  --tw-translate-x: 0.375rem !important;
}

.focus\:builder-translate-x-2\.5:focus{
  --tw-translate-x: 0.625rem !important;
}

.focus\:builder-translate-x-3\.5:focus{
  --tw-translate-x: 0.875rem !important;
}

.focus\:builder--translate-x-0:focus{
  --tw-translate-x: 0px !important;
}

.focus\:builder--translate-x-1:focus{
  --tw-translate-x: -0.25rem !important;
}

.focus\:builder--translate-x-2:focus{
  --tw-translate-x: -0.5rem !important;
}

.focus\:builder--translate-x-3:focus{
  --tw-translate-x: -0.75rem !important;
}

.focus\:builder--translate-x-4:focus{
  --tw-translate-x: -1rem !important;
}

.focus\:builder--translate-x-5:focus{
  --tw-translate-x: -1.25rem !important;
}

.focus\:builder--translate-x-6:focus{
  --tw-translate-x: -1.5rem !important;
}

.focus\:builder--translate-x-7:focus{
  --tw-translate-x: -1.75rem !important;
}

.focus\:builder--translate-x-8:focus{
  --tw-translate-x: -2rem !important;
}

.focus\:builder--translate-x-9:focus{
  --tw-translate-x: -2.25rem !important;
}

.focus\:builder--translate-x-10:focus{
  --tw-translate-x: -2.5rem !important;
}

.focus\:builder--translate-x-11:focus{
  --tw-translate-x: -2.75rem !important;
}

.focus\:builder--translate-x-12:focus{
  --tw-translate-x: -3rem !important;
}

.focus\:builder--translate-x-14:focus{
  --tw-translate-x: -3.5rem !important;
}

.focus\:builder--translate-x-16:focus{
  --tw-translate-x: -4rem !important;
}

.focus\:builder--translate-x-20:focus{
  --tw-translate-x: -5rem !important;
}

.focus\:builder--translate-x-24:focus{
  --tw-translate-x: -6rem !important;
}

.focus\:builder--translate-x-28:focus{
  --tw-translate-x: -7rem !important;
}

.focus\:builder--translate-x-32:focus{
  --tw-translate-x: -8rem !important;
}

.focus\:builder--translate-x-36:focus{
  --tw-translate-x: -9rem !important;
}

.focus\:builder--translate-x-40:focus{
  --tw-translate-x: -10rem !important;
}

.focus\:builder--translate-x-44:focus{
  --tw-translate-x: -11rem !important;
}

.focus\:builder--translate-x-48:focus{
  --tw-translate-x: -12rem !important;
}

.focus\:builder--translate-x-52:focus{
  --tw-translate-x: -13rem !important;
}

.focus\:builder--translate-x-56:focus{
  --tw-translate-x: -14rem !important;
}

.focus\:builder--translate-x-60:focus{
  --tw-translate-x: -15rem !important;
}

.focus\:builder--translate-x-64:focus{
  --tw-translate-x: -16rem !important;
}

.focus\:builder--translate-x-72:focus{
  --tw-translate-x: -18rem !important;
}

.focus\:builder--translate-x-80:focus{
  --tw-translate-x: -20rem !important;
}

.focus\:builder--translate-x-96:focus{
  --tw-translate-x: -24rem !important;
}

.focus\:builder--translate-x-px:focus{
  --tw-translate-x: -1px !important;
}

.focus\:builder--translate-x-0\.5:focus{
  --tw-translate-x: -0.125rem !important;
}

.focus\:builder--translate-x-1\.5:focus{
  --tw-translate-x: -0.375rem !important;
}

.focus\:builder--translate-x-2\.5:focus{
  --tw-translate-x: -0.625rem !important;
}

.focus\:builder--translate-x-3\.5:focus{
  --tw-translate-x: -0.875rem !important;
}

.focus\:builder-translate-x-1\/2:focus{
  --tw-translate-x: 50% !important;
}

.focus\:builder-translate-x-1\/3:focus{
  --tw-translate-x: 33.333333% !important;
}

.focus\:builder-translate-x-2\/3:focus{
  --tw-translate-x: 66.666667% !important;
}

.focus\:builder-translate-x-1\/4:focus{
  --tw-translate-x: 25% !important;
}

.focus\:builder-translate-x-2\/4:focus{
  --tw-translate-x: 50% !important;
}

.focus\:builder-translate-x-3\/4:focus{
  --tw-translate-x: 75% !important;
}

.focus\:builder-translate-x-full:focus{
  --tw-translate-x: 100% !important;
}

.focus\:builder--translate-x-1\/2:focus{
  --tw-translate-x: -50% !important;
}

.focus\:builder--translate-x-1\/3:focus{
  --tw-translate-x: -33.333333% !important;
}

.focus\:builder--translate-x-2\/3:focus{
  --tw-translate-x: -66.666667% !important;
}

.focus\:builder--translate-x-1\/4:focus{
  --tw-translate-x: -25% !important;
}

.focus\:builder--translate-x-2\/4:focus{
  --tw-translate-x: -50% !important;
}

.focus\:builder--translate-x-3\/4:focus{
  --tw-translate-x: -75% !important;
}

.focus\:builder--translate-x-full:focus{
  --tw-translate-x: -100% !important;
}

.focus\:builder-translate-y-0:focus{
  --tw-translate-y: 0px !important;
}

.focus\:builder-translate-y-1:focus{
  --tw-translate-y: 0.25rem !important;
}

.focus\:builder-translate-y-2:focus{
  --tw-translate-y: 0.5rem !important;
}

.focus\:builder-translate-y-3:focus{
  --tw-translate-y: 0.75rem !important;
}

.focus\:builder-translate-y-4:focus{
  --tw-translate-y: 1rem !important;
}

.focus\:builder-translate-y-5:focus{
  --tw-translate-y: 1.25rem !important;
}

.focus\:builder-translate-y-6:focus{
  --tw-translate-y: 1.5rem !important;
}

.focus\:builder-translate-y-7:focus{
  --tw-translate-y: 1.75rem !important;
}

.focus\:builder-translate-y-8:focus{
  --tw-translate-y: 2rem !important;
}

.focus\:builder-translate-y-9:focus{
  --tw-translate-y: 2.25rem !important;
}

.focus\:builder-translate-y-10:focus{
  --tw-translate-y: 2.5rem !important;
}

.focus\:builder-translate-y-11:focus{
  --tw-translate-y: 2.75rem !important;
}

.focus\:builder-translate-y-12:focus{
  --tw-translate-y: 3rem !important;
}

.focus\:builder-translate-y-14:focus{
  --tw-translate-y: 3.5rem !important;
}

.focus\:builder-translate-y-16:focus{
  --tw-translate-y: 4rem !important;
}

.focus\:builder-translate-y-20:focus{
  --tw-translate-y: 5rem !important;
}

.focus\:builder-translate-y-24:focus{
  --tw-translate-y: 6rem !important;
}

.focus\:builder-translate-y-28:focus{
  --tw-translate-y: 7rem !important;
}

.focus\:builder-translate-y-32:focus{
  --tw-translate-y: 8rem !important;
}

.focus\:builder-translate-y-36:focus{
  --tw-translate-y: 9rem !important;
}

.focus\:builder-translate-y-40:focus{
  --tw-translate-y: 10rem !important;
}

.focus\:builder-translate-y-44:focus{
  --tw-translate-y: 11rem !important;
}

.focus\:builder-translate-y-48:focus{
  --tw-translate-y: 12rem !important;
}

.focus\:builder-translate-y-52:focus{
  --tw-translate-y: 13rem !important;
}

.focus\:builder-translate-y-56:focus{
  --tw-translate-y: 14rem !important;
}

.focus\:builder-translate-y-60:focus{
  --tw-translate-y: 15rem !important;
}

.focus\:builder-translate-y-64:focus{
  --tw-translate-y: 16rem !important;
}

.focus\:builder-translate-y-72:focus{
  --tw-translate-y: 18rem !important;
}

.focus\:builder-translate-y-80:focus{
  --tw-translate-y: 20rem !important;
}

.focus\:builder-translate-y-96:focus{
  --tw-translate-y: 24rem !important;
}

.focus\:builder-translate-y-px:focus{
  --tw-translate-y: 1px !important;
}

.focus\:builder-translate-y-0\.5:focus{
  --tw-translate-y: 0.125rem !important;
}

.focus\:builder-translate-y-1\.5:focus{
  --tw-translate-y: 0.375rem !important;
}

.focus\:builder-translate-y-2\.5:focus{
  --tw-translate-y: 0.625rem !important;
}

.focus\:builder-translate-y-3\.5:focus{
  --tw-translate-y: 0.875rem !important;
}

.focus\:builder--translate-y-0:focus{
  --tw-translate-y: 0px !important;
}

.focus\:builder--translate-y-1:focus{
  --tw-translate-y: -0.25rem !important;
}

.focus\:builder--translate-y-2:focus{
  --tw-translate-y: -0.5rem !important;
}

.focus\:builder--translate-y-3:focus{
  --tw-translate-y: -0.75rem !important;
}

.focus\:builder--translate-y-4:focus{
  --tw-translate-y: -1rem !important;
}

.focus\:builder--translate-y-5:focus{
  --tw-translate-y: -1.25rem !important;
}

.focus\:builder--translate-y-6:focus{
  --tw-translate-y: -1.5rem !important;
}

.focus\:builder--translate-y-7:focus{
  --tw-translate-y: -1.75rem !important;
}

.focus\:builder--translate-y-8:focus{
  --tw-translate-y: -2rem !important;
}

.focus\:builder--translate-y-9:focus{
  --tw-translate-y: -2.25rem !important;
}

.focus\:builder--translate-y-10:focus{
  --tw-translate-y: -2.5rem !important;
}

.focus\:builder--translate-y-11:focus{
  --tw-translate-y: -2.75rem !important;
}

.focus\:builder--translate-y-12:focus{
  --tw-translate-y: -3rem !important;
}

.focus\:builder--translate-y-14:focus{
  --tw-translate-y: -3.5rem !important;
}

.focus\:builder--translate-y-16:focus{
  --tw-translate-y: -4rem !important;
}

.focus\:builder--translate-y-20:focus{
  --tw-translate-y: -5rem !important;
}

.focus\:builder--translate-y-24:focus{
  --tw-translate-y: -6rem !important;
}

.focus\:builder--translate-y-28:focus{
  --tw-translate-y: -7rem !important;
}

.focus\:builder--translate-y-32:focus{
  --tw-translate-y: -8rem !important;
}

.focus\:builder--translate-y-36:focus{
  --tw-translate-y: -9rem !important;
}

.focus\:builder--translate-y-40:focus{
  --tw-translate-y: -10rem !important;
}

.focus\:builder--translate-y-44:focus{
  --tw-translate-y: -11rem !important;
}

.focus\:builder--translate-y-48:focus{
  --tw-translate-y: -12rem !important;
}

.focus\:builder--translate-y-52:focus{
  --tw-translate-y: -13rem !important;
}

.focus\:builder--translate-y-56:focus{
  --tw-translate-y: -14rem !important;
}

.focus\:builder--translate-y-60:focus{
  --tw-translate-y: -15rem !important;
}

.focus\:builder--translate-y-64:focus{
  --tw-translate-y: -16rem !important;
}

.focus\:builder--translate-y-72:focus{
  --tw-translate-y: -18rem !important;
}

.focus\:builder--translate-y-80:focus{
  --tw-translate-y: -20rem !important;
}

.focus\:builder--translate-y-96:focus{
  --tw-translate-y: -24rem !important;
}

.focus\:builder--translate-y-px:focus{
  --tw-translate-y: -1px !important;
}

.focus\:builder--translate-y-0\.5:focus{
  --tw-translate-y: -0.125rem !important;
}

.focus\:builder--translate-y-1\.5:focus{
  --tw-translate-y: -0.375rem !important;
}

.focus\:builder--translate-y-2\.5:focus{
  --tw-translate-y: -0.625rem !important;
}

.focus\:builder--translate-y-3\.5:focus{
  --tw-translate-y: -0.875rem !important;
}

.focus\:builder-translate-y-1\/2:focus{
  --tw-translate-y: 50% !important;
}

.focus\:builder-translate-y-1\/3:focus{
  --tw-translate-y: 33.333333% !important;
}

.focus\:builder-translate-y-2\/3:focus{
  --tw-translate-y: 66.666667% !important;
}

.focus\:builder-translate-y-1\/4:focus{
  --tw-translate-y: 25% !important;
}

.focus\:builder-translate-y-2\/4:focus{
  --tw-translate-y: 50% !important;
}

.focus\:builder-translate-y-3\/4:focus{
  --tw-translate-y: 75% !important;
}

.focus\:builder-translate-y-full:focus{
  --tw-translate-y: 100% !important;
}

.focus\:builder--translate-y-1\/2:focus{
  --tw-translate-y: -50% !important;
}

.focus\:builder--translate-y-1\/3:focus{
  --tw-translate-y: -33.333333% !important;
}

.focus\:builder--translate-y-2\/3:focus{
  --tw-translate-y: -66.666667% !important;
}

.focus\:builder--translate-y-1\/4:focus{
  --tw-translate-y: -25% !important;
}

.focus\:builder--translate-y-2\/4:focus{
  --tw-translate-y: -50% !important;
}

.focus\:builder--translate-y-3\/4:focus{
  --tw-translate-y: -75% !important;
}

.focus\:builder--translate-y-full:focus{
  --tw-translate-y: -100% !important;
}

.builder-rotate-0{
  --tw-rotate: 0deg !important;
}

.builder-rotate-1{
  --tw-rotate: 1deg !important;
}

.builder-rotate-2{
  --tw-rotate: 2deg !important;
}

.builder-rotate-3{
  --tw-rotate: 3deg !important;
}

.builder-rotate-6{
  --tw-rotate: 6deg !important;
}

.builder-rotate-12{
  --tw-rotate: 12deg !important;
}

.builder-rotate-45{
  --tw-rotate: 45deg !important;
}

.builder-rotate-90{
  --tw-rotate: 90deg !important;
}

.builder-rotate-180{
  --tw-rotate: 180deg !important;
}

.builder--rotate-180{
  --tw-rotate: -180deg !important;
}

.builder--rotate-90{
  --tw-rotate: -90deg !important;
}

.builder--rotate-45{
  --tw-rotate: -45deg !important;
}

.builder--rotate-12{
  --tw-rotate: -12deg !important;
}

.builder--rotate-6{
  --tw-rotate: -6deg !important;
}

.builder--rotate-3{
  --tw-rotate: -3deg !important;
}

.builder--rotate-2{
  --tw-rotate: -2deg !important;
}

.builder--rotate-1{
  --tw-rotate: -1deg !important;
}

.hover\:builder-rotate-0:hover{
  --tw-rotate: 0deg !important;
}

.hover\:builder-rotate-1:hover{
  --tw-rotate: 1deg !important;
}

.hover\:builder-rotate-2:hover{
  --tw-rotate: 2deg !important;
}

.hover\:builder-rotate-3:hover{
  --tw-rotate: 3deg !important;
}

.hover\:builder-rotate-6:hover{
  --tw-rotate: 6deg !important;
}

.hover\:builder-rotate-12:hover{
  --tw-rotate: 12deg !important;
}

.hover\:builder-rotate-45:hover{
  --tw-rotate: 45deg !important;
}

.hover\:builder-rotate-90:hover{
  --tw-rotate: 90deg !important;
}

.hover\:builder-rotate-180:hover{
  --tw-rotate: 180deg !important;
}

.hover\:builder--rotate-180:hover{
  --tw-rotate: -180deg !important;
}

.hover\:builder--rotate-90:hover{
  --tw-rotate: -90deg !important;
}

.hover\:builder--rotate-45:hover{
  --tw-rotate: -45deg !important;
}

.hover\:builder--rotate-12:hover{
  --tw-rotate: -12deg !important;
}

.hover\:builder--rotate-6:hover{
  --tw-rotate: -6deg !important;
}

.hover\:builder--rotate-3:hover{
  --tw-rotate: -3deg !important;
}

.hover\:builder--rotate-2:hover{
  --tw-rotate: -2deg !important;
}

.hover\:builder--rotate-1:hover{
  --tw-rotate: -1deg !important;
}

.focus\:builder-rotate-0:focus{
  --tw-rotate: 0deg !important;
}

.focus\:builder-rotate-1:focus{
  --tw-rotate: 1deg !important;
}

.focus\:builder-rotate-2:focus{
  --tw-rotate: 2deg !important;
}

.focus\:builder-rotate-3:focus{
  --tw-rotate: 3deg !important;
}

.focus\:builder-rotate-6:focus{
  --tw-rotate: 6deg !important;
}

.focus\:builder-rotate-12:focus{
  --tw-rotate: 12deg !important;
}

.focus\:builder-rotate-45:focus{
  --tw-rotate: 45deg !important;
}

.focus\:builder-rotate-90:focus{
  --tw-rotate: 90deg !important;
}

.focus\:builder-rotate-180:focus{
  --tw-rotate: 180deg !important;
}

.focus\:builder--rotate-180:focus{
  --tw-rotate: -180deg !important;
}

.focus\:builder--rotate-90:focus{
  --tw-rotate: -90deg !important;
}

.focus\:builder--rotate-45:focus{
  --tw-rotate: -45deg !important;
}

.focus\:builder--rotate-12:focus{
  --tw-rotate: -12deg !important;
}

.focus\:builder--rotate-6:focus{
  --tw-rotate: -6deg !important;
}

.focus\:builder--rotate-3:focus{
  --tw-rotate: -3deg !important;
}

.focus\:builder--rotate-2:focus{
  --tw-rotate: -2deg !important;
}

.focus\:builder--rotate-1:focus{
  --tw-rotate: -1deg !important;
}

.builder-skew-x-0{
  --tw-skew-x: 0deg !important;
}

.builder-skew-x-1{
  --tw-skew-x: 1deg !important;
}

.builder-skew-x-2{
  --tw-skew-x: 2deg !important;
}

.builder-skew-x-3{
  --tw-skew-x: 3deg !important;
}

.builder-skew-x-6{
  --tw-skew-x: 6deg !important;
}

.builder-skew-x-12{
  --tw-skew-x: 12deg !important;
}

.builder--skew-x-12{
  --tw-skew-x: -12deg !important;
}

.builder--skew-x-6{
  --tw-skew-x: -6deg !important;
}

.builder--skew-x-3{
  --tw-skew-x: -3deg !important;
}

.builder--skew-x-2{
  --tw-skew-x: -2deg !important;
}

.builder--skew-x-1{
  --tw-skew-x: -1deg !important;
}

.builder-skew-y-0{
  --tw-skew-y: 0deg !important;
}

.builder-skew-y-1{
  --tw-skew-y: 1deg !important;
}

.builder-skew-y-2{
  --tw-skew-y: 2deg !important;
}

.builder-skew-y-3{
  --tw-skew-y: 3deg !important;
}

.builder-skew-y-6{
  --tw-skew-y: 6deg !important;
}

.builder-skew-y-12{
  --tw-skew-y: 12deg !important;
}

.builder--skew-y-12{
  --tw-skew-y: -12deg !important;
}

.builder--skew-y-6{
  --tw-skew-y: -6deg !important;
}

.builder--skew-y-3{
  --tw-skew-y: -3deg !important;
}

.builder--skew-y-2{
  --tw-skew-y: -2deg !important;
}

.builder--skew-y-1{
  --tw-skew-y: -1deg !important;
}

.hover\:builder-skew-x-0:hover{
  --tw-skew-x: 0deg !important;
}

.hover\:builder-skew-x-1:hover{
  --tw-skew-x: 1deg !important;
}

.hover\:builder-skew-x-2:hover{
  --tw-skew-x: 2deg !important;
}

.hover\:builder-skew-x-3:hover{
  --tw-skew-x: 3deg !important;
}

.hover\:builder-skew-x-6:hover{
  --tw-skew-x: 6deg !important;
}

.hover\:builder-skew-x-12:hover{
  --tw-skew-x: 12deg !important;
}

.hover\:builder--skew-x-12:hover{
  --tw-skew-x: -12deg !important;
}

.hover\:builder--skew-x-6:hover{
  --tw-skew-x: -6deg !important;
}

.hover\:builder--skew-x-3:hover{
  --tw-skew-x: -3deg !important;
}

.hover\:builder--skew-x-2:hover{
  --tw-skew-x: -2deg !important;
}

.hover\:builder--skew-x-1:hover{
  --tw-skew-x: -1deg !important;
}

.hover\:builder-skew-y-0:hover{
  --tw-skew-y: 0deg !important;
}

.hover\:builder-skew-y-1:hover{
  --tw-skew-y: 1deg !important;
}

.hover\:builder-skew-y-2:hover{
  --tw-skew-y: 2deg !important;
}

.hover\:builder-skew-y-3:hover{
  --tw-skew-y: 3deg !important;
}

.hover\:builder-skew-y-6:hover{
  --tw-skew-y: 6deg !important;
}

.hover\:builder-skew-y-12:hover{
  --tw-skew-y: 12deg !important;
}

.hover\:builder--skew-y-12:hover{
  --tw-skew-y: -12deg !important;
}

.hover\:builder--skew-y-6:hover{
  --tw-skew-y: -6deg !important;
}

.hover\:builder--skew-y-3:hover{
  --tw-skew-y: -3deg !important;
}

.hover\:builder--skew-y-2:hover{
  --tw-skew-y: -2deg !important;
}

.hover\:builder--skew-y-1:hover{
  --tw-skew-y: -1deg !important;
}

.focus\:builder-skew-x-0:focus{
  --tw-skew-x: 0deg !important;
}

.focus\:builder-skew-x-1:focus{
  --tw-skew-x: 1deg !important;
}

.focus\:builder-skew-x-2:focus{
  --tw-skew-x: 2deg !important;
}

.focus\:builder-skew-x-3:focus{
  --tw-skew-x: 3deg !important;
}

.focus\:builder-skew-x-6:focus{
  --tw-skew-x: 6deg !important;
}

.focus\:builder-skew-x-12:focus{
  --tw-skew-x: 12deg !important;
}

.focus\:builder--skew-x-12:focus{
  --tw-skew-x: -12deg !important;
}

.focus\:builder--skew-x-6:focus{
  --tw-skew-x: -6deg !important;
}

.focus\:builder--skew-x-3:focus{
  --tw-skew-x: -3deg !important;
}

.focus\:builder--skew-x-2:focus{
  --tw-skew-x: -2deg !important;
}

.focus\:builder--skew-x-1:focus{
  --tw-skew-x: -1deg !important;
}

.focus\:builder-skew-y-0:focus{
  --tw-skew-y: 0deg !important;
}

.focus\:builder-skew-y-1:focus{
  --tw-skew-y: 1deg !important;
}

.focus\:builder-skew-y-2:focus{
  --tw-skew-y: 2deg !important;
}

.focus\:builder-skew-y-3:focus{
  --tw-skew-y: 3deg !important;
}

.focus\:builder-skew-y-6:focus{
  --tw-skew-y: 6deg !important;
}

.focus\:builder-skew-y-12:focus{
  --tw-skew-y: 12deg !important;
}

.focus\:builder--skew-y-12:focus{
  --tw-skew-y: -12deg !important;
}

.focus\:builder--skew-y-6:focus{
  --tw-skew-y: -6deg !important;
}

.focus\:builder--skew-y-3:focus{
  --tw-skew-y: -3deg !important;
}

.focus\:builder--skew-y-2:focus{
  --tw-skew-y: -2deg !important;
}

.focus\:builder--skew-y-1:focus{
  --tw-skew-y: -1deg !important;
}

.builder-scale-0{
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
}

.builder-scale-50{
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
}

.builder-scale-75{
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
}

.builder-scale-90{
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
}

.builder-scale-95{
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
}

.builder-scale-100{
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
}

.builder-scale-105{
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
}

.builder-scale-110{
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
}

.builder-scale-125{
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
}

.builder-scale-150{
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
}

.hover\:builder-scale-0:hover{
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
}

.hover\:builder-scale-50:hover{
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
}

.hover\:builder-scale-75:hover{
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
}

.hover\:builder-scale-90:hover{
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
}

.hover\:builder-scale-95:hover{
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
}

.hover\:builder-scale-100:hover{
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
}

.hover\:builder-scale-105:hover{
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
}

.hover\:builder-scale-110:hover{
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
}

.hover\:builder-scale-125:hover{
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
}

.hover\:builder-scale-150:hover{
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
}

.focus\:builder-scale-0:focus{
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
}

.focus\:builder-scale-50:focus{
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
}

.focus\:builder-scale-75:focus{
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
}

.focus\:builder-scale-90:focus{
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
}

.focus\:builder-scale-95:focus{
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
}

.focus\:builder-scale-100:focus{
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
}

.focus\:builder-scale-105:focus{
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
}

.focus\:builder-scale-110:focus{
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
}

.focus\:builder-scale-125:focus{
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
}

.focus\:builder-scale-150:focus{
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
}

.builder-scale-x-0{
  --tw-scale-x: 0 !important;
}

.builder-scale-x-50{
  --tw-scale-x: .5 !important;
}

.builder-scale-x-75{
  --tw-scale-x: .75 !important;
}

.builder-scale-x-90{
  --tw-scale-x: .9 !important;
}

.builder-scale-x-95{
  --tw-scale-x: .95 !important;
}

.builder-scale-x-100{
  --tw-scale-x: 1 !important;
}

.builder-scale-x-105{
  --tw-scale-x: 1.05 !important;
}

.builder-scale-x-110{
  --tw-scale-x: 1.1 !important;
}

.builder-scale-x-125{
  --tw-scale-x: 1.25 !important;
}

.builder-scale-x-150{
  --tw-scale-x: 1.5 !important;
}

.builder-scale-y-0{
  --tw-scale-y: 0 !important;
}

.builder-scale-y-50{
  --tw-scale-y: .5 !important;
}

.builder-scale-y-75{
  --tw-scale-y: .75 !important;
}

.builder-scale-y-90{
  --tw-scale-y: .9 !important;
}

.builder-scale-y-95{
  --tw-scale-y: .95 !important;
}

.builder-scale-y-100{
  --tw-scale-y: 1 !important;
}

.builder-scale-y-105{
  --tw-scale-y: 1.05 !important;
}

.builder-scale-y-110{
  --tw-scale-y: 1.1 !important;
}

.builder-scale-y-125{
  --tw-scale-y: 1.25 !important;
}

.builder-scale-y-150{
  --tw-scale-y: 1.5 !important;
}

.hover\:builder-scale-x-0:hover{
  --tw-scale-x: 0 !important;
}

.hover\:builder-scale-x-50:hover{
  --tw-scale-x: .5 !important;
}

.hover\:builder-scale-x-75:hover{
  --tw-scale-x: .75 !important;
}

.hover\:builder-scale-x-90:hover{
  --tw-scale-x: .9 !important;
}

.hover\:builder-scale-x-95:hover{
  --tw-scale-x: .95 !important;
}

.hover\:builder-scale-x-100:hover{
  --tw-scale-x: 1 !important;
}

.hover\:builder-scale-x-105:hover{
  --tw-scale-x: 1.05 !important;
}

.hover\:builder-scale-x-110:hover{
  --tw-scale-x: 1.1 !important;
}

.hover\:builder-scale-x-125:hover{
  --tw-scale-x: 1.25 !important;
}

.hover\:builder-scale-x-150:hover{
  --tw-scale-x: 1.5 !important;
}

.hover\:builder-scale-y-0:hover{
  --tw-scale-y: 0 !important;
}

.hover\:builder-scale-y-50:hover{
  --tw-scale-y: .5 !important;
}

.hover\:builder-scale-y-75:hover{
  --tw-scale-y: .75 !important;
}

.hover\:builder-scale-y-90:hover{
  --tw-scale-y: .9 !important;
}

.hover\:builder-scale-y-95:hover{
  --tw-scale-y: .95 !important;
}

.hover\:builder-scale-y-100:hover{
  --tw-scale-y: 1 !important;
}

.hover\:builder-scale-y-105:hover{
  --tw-scale-y: 1.05 !important;
}

.hover\:builder-scale-y-110:hover{
  --tw-scale-y: 1.1 !important;
}

.hover\:builder-scale-y-125:hover{
  --tw-scale-y: 1.25 !important;
}

.hover\:builder-scale-y-150:hover{
  --tw-scale-y: 1.5 !important;
}

.focus\:builder-scale-x-0:focus{
  --tw-scale-x: 0 !important;
}

.focus\:builder-scale-x-50:focus{
  --tw-scale-x: .5 !important;
}

.focus\:builder-scale-x-75:focus{
  --tw-scale-x: .75 !important;
}

.focus\:builder-scale-x-90:focus{
  --tw-scale-x: .9 !important;
}

.focus\:builder-scale-x-95:focus{
  --tw-scale-x: .95 !important;
}

.focus\:builder-scale-x-100:focus{
  --tw-scale-x: 1 !important;
}

.focus\:builder-scale-x-105:focus{
  --tw-scale-x: 1.05 !important;
}

.focus\:builder-scale-x-110:focus{
  --tw-scale-x: 1.1 !important;
}

.focus\:builder-scale-x-125:focus{
  --tw-scale-x: 1.25 !important;
}

.focus\:builder-scale-x-150:focus{
  --tw-scale-x: 1.5 !important;
}

.focus\:builder-scale-y-0:focus{
  --tw-scale-y: 0 !important;
}

.focus\:builder-scale-y-50:focus{
  --tw-scale-y: .5 !important;
}

.focus\:builder-scale-y-75:focus{
  --tw-scale-y: .75 !important;
}

.focus\:builder-scale-y-90:focus{
  --tw-scale-y: .9 !important;
}

.focus\:builder-scale-y-95:focus{
  --tw-scale-y: .95 !important;
}

.focus\:builder-scale-y-100:focus{
  --tw-scale-y: 1 !important;
}

.focus\:builder-scale-y-105:focus{
  --tw-scale-y: 1.05 !important;
}

.focus\:builder-scale-y-110:focus{
  --tw-scale-y: 1.1 !important;
}

.focus\:builder-scale-y-125:focus{
  --tw-scale-y: 1.25 !important;
}

.focus\:builder-scale-y-150:focus{
  --tw-scale-y: 1.5 !important;
}

@keyframes builder-spin{
  to{
    transform: rotate(360deg);
  }
}

@keyframes builder-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes builder-pulse{
  50%{
    opacity: .5;
  }
}

@keyframes builder-bounce{
  0%, 100%{
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.builder-animate-none{
  animation: none !important;
}

.builder-animate-spin{
  animation: builder-spin 1s linear infinite !important;
}

.builder-animate-ping{
  animation: builder-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.builder-animate-pulse{
  animation: builder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

.builder-animate-bounce{
  animation: builder-bounce 1s infinite !important;
}

.builder-cursor-auto{
  cursor: auto !important;
}

.builder-cursor-default{
  cursor: default !important;
}

.builder-cursor-pointer{
  cursor: pointer !important;
}

.builder-cursor-wait{
  cursor: wait !important;
}

.builder-cursor-text{
  cursor: text !important;
}

.builder-cursor-move{
  cursor: move !important;
}

.builder-cursor-help{
  cursor: help !important;
}

.builder-cursor-not-allowed{
  cursor: not-allowed !important;
}

.builder-select-none{
  -webkit-user-select: none !important;
          user-select: none !important;
}

.builder-select-text{
  -webkit-user-select: text !important;
          user-select: text !important;
}

.builder-select-all{
  -webkit-user-select: all !important;
          user-select: all !important;
}

.builder-select-auto{
  -webkit-user-select: auto !important;
          user-select: auto !important;
}

.builder-resize-none{
  resize: none !important;
}

.builder-resize-y{
  resize: vertical !important;
}

.builder-resize-x{
  resize: horizontal !important;
}

.builder-resize{
  resize: both !important;
}

.builder-list-inside{
  list-style-position: inside !important;
}

.builder-list-outside{
  list-style-position: outside !important;
}

.builder-list-none{
  list-style-type: none !important;
}

.builder-list-disc{
  list-style-type: disc !important;
}

.builder-list-decimal{
  list-style-type: decimal !important;
}

.builder-appearance-none{
  appearance: none !important;
}

.builder-auto-cols-auto{
  grid-auto-columns: auto !important;
}

.builder-auto-cols-min{
  grid-auto-columns: min-content !important;
}

.builder-auto-cols-max{
  grid-auto-columns: max-content !important;
}

.builder-auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr) !important;
}

.builder-grid-flow-row{
  grid-auto-flow: row !important;
}

.builder-grid-flow-col{
  grid-auto-flow: column !important;
}

.builder-grid-flow-row-dense{
  grid-auto-flow: row dense !important;
}

.builder-grid-flow-col-dense{
  grid-auto-flow: column dense !important;
}

.builder-auto-rows-auto{
  grid-auto-rows: auto !important;
}

.builder-auto-rows-min{
  grid-auto-rows: min-content !important;
}

.builder-auto-rows-max{
  grid-auto-rows: max-content !important;
}

.builder-auto-rows-fr{
  grid-auto-rows: minmax(0, 1fr) !important;
}

.builder-grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.builder-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.builder-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.builder-grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.builder-grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.builder-grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.builder-grid-cols-7{
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.builder-grid-cols-8{
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.builder-grid-cols-9{
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.builder-grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.builder-grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.builder-grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.builder-grid-cols-none{
  grid-template-columns: none !important;
}

.builder-grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.builder-grid-rows-2{
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.builder-grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.builder-grid-rows-4{
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.builder-grid-rows-5{
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.builder-grid-rows-6{
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.builder-grid-rows-none{
  grid-template-rows: none !important;
}

.builder-flex-row{
  flex-direction: row !important;
}

.builder-flex-row-reverse{
  flex-direction: row-reverse !important;
}

.builder-flex-col{
  flex-direction: column !important;
}

.builder-flex-col-reverse{
  flex-direction: column-reverse !important;
}

.builder-flex-wrap{
  flex-wrap: wrap !important;
}

.builder-flex-wrap-reverse{
  flex-wrap: wrap-reverse !important;
}

.builder-flex-nowrap{
  flex-wrap: nowrap !important;
}

.builder-place-content-center{
  place-content: center !important;
}

.builder-place-content-start{
  place-content: start !important;
}

.builder-place-content-end{
  place-content: end !important;
}

.builder-place-content-between{
  place-content: space-between !important;
}

.builder-place-content-around{
  place-content: space-around !important;
}

.builder-place-content-evenly{
  place-content: space-evenly !important;
}

.builder-place-content-stretch{
  place-content: stretch !important;
}

.builder-place-items-start{
  place-items: start !important;
}

.builder-place-items-end{
  place-items: end !important;
}

.builder-place-items-center{
  place-items: center !important;
}

.builder-place-items-stretch{
  place-items: stretch !important;
}

.builder-content-center{
  align-content: center !important;
}

.builder-content-start{
  align-content: flex-start !important;
}

.builder-content-end{
  align-content: flex-end !important;
}

.builder-content-between{
  align-content: space-between !important;
}

.builder-content-around{
  align-content: space-around !important;
}

.builder-content-evenly{
  align-content: space-evenly !important;
}

.builder-items-start{
  align-items: flex-start !important;
}

.builder-items-end{
  align-items: flex-end !important;
}

.builder-items-center{
  align-items: center !important;
}

.builder-items-baseline{
  align-items: baseline !important;
}

.builder-items-stretch{
  align-items: stretch !important;
}

.builder-justify-start{
  justify-content: flex-start !important;
}

.builder-justify-end{
  justify-content: flex-end !important;
}

.builder-justify-center{
  justify-content: center !important;
}

.builder-justify-between{
  justify-content: space-between !important;
}

.builder-justify-around{
  justify-content: space-around !important;
}

.builder-justify-evenly{
  justify-content: space-evenly !important;
}

.builder-justify-items-start{
  justify-items: start !important;
}

.builder-justify-items-end{
  justify-items: end !important;
}

.builder-justify-items-center{
  justify-items: center !important;
}

.builder-justify-items-stretch{
  justify-items: stretch !important;
}

.builder-gap-0{
  gap: 0px !important;
}

.builder-gap-1{
  gap: 0.25rem !important;
}

.builder-gap-2{
  gap: 0.5rem !important;
}

.builder-gap-3{
  gap: 0.75rem !important;
}

.builder-gap-4{
  gap: 1rem !important;
}

.builder-gap-5{
  gap: 1.25rem !important;
}

.builder-gap-6{
  gap: 1.5rem !important;
}

.builder-gap-7{
  gap: 1.75rem !important;
}

.builder-gap-8{
  gap: 2rem !important;
}

.builder-gap-9{
  gap: 2.25rem !important;
}

.builder-gap-10{
  gap: 2.5rem !important;
}

.builder-gap-11{
  gap: 2.75rem !important;
}

.builder-gap-12{
  gap: 3rem !important;
}

.builder-gap-14{
  gap: 3.5rem !important;
}

.builder-gap-16{
  gap: 4rem !important;
}

.builder-gap-20{
  gap: 5rem !important;
}

.builder-gap-24{
  gap: 6rem !important;
}

.builder-gap-28{
  gap: 7rem !important;
}

.builder-gap-32{
  gap: 8rem !important;
}

.builder-gap-36{
  gap: 9rem !important;
}

.builder-gap-40{
  gap: 10rem !important;
}

.builder-gap-44{
  gap: 11rem !important;
}

.builder-gap-48{
  gap: 12rem !important;
}

.builder-gap-52{
  gap: 13rem !important;
}

.builder-gap-56{
  gap: 14rem !important;
}

.builder-gap-60{
  gap: 15rem !important;
}

.builder-gap-64{
  gap: 16rem !important;
}

.builder-gap-72{
  gap: 18rem !important;
}

.builder-gap-80{
  gap: 20rem !important;
}

.builder-gap-96{
  gap: 24rem !important;
}

.builder-gap-px{
  gap: 1px !important;
}

.builder-gap-0\.5{
  gap: 0.125rem !important;
}

.builder-gap-1\.5{
  gap: 0.375rem !important;
}

.builder-gap-2\.5{
  gap: 0.625rem !important;
}

.builder-gap-3\.5{
  gap: 0.875rem !important;
}

.builder-gap-x-0{
  column-gap: 0px !important;
}

.builder-gap-x-1{
  column-gap: 0.25rem !important;
}

.builder-gap-x-2{
  column-gap: 0.5rem !important;
}

.builder-gap-x-3{
  column-gap: 0.75rem !important;
}

.builder-gap-x-4{
  column-gap: 1rem !important;
}

.builder-gap-x-5{
  column-gap: 1.25rem !important;
}

.builder-gap-x-6{
  column-gap: 1.5rem !important;
}

.builder-gap-x-7{
  column-gap: 1.75rem !important;
}

.builder-gap-x-8{
  column-gap: 2rem !important;
}

.builder-gap-x-9{
  column-gap: 2.25rem !important;
}

.builder-gap-x-10{
  column-gap: 2.5rem !important;
}

.builder-gap-x-11{
  column-gap: 2.75rem !important;
}

.builder-gap-x-12{
  column-gap: 3rem !important;
}

.builder-gap-x-14{
  column-gap: 3.5rem !important;
}

.builder-gap-x-16{
  column-gap: 4rem !important;
}

.builder-gap-x-20{
  column-gap: 5rem !important;
}

.builder-gap-x-24{
  column-gap: 6rem !important;
}

.builder-gap-x-28{
  column-gap: 7rem !important;
}

.builder-gap-x-32{
  column-gap: 8rem !important;
}

.builder-gap-x-36{
  column-gap: 9rem !important;
}

.builder-gap-x-40{
  column-gap: 10rem !important;
}

.builder-gap-x-44{
  column-gap: 11rem !important;
}

.builder-gap-x-48{
  column-gap: 12rem !important;
}

.builder-gap-x-52{
  column-gap: 13rem !important;
}

.builder-gap-x-56{
  column-gap: 14rem !important;
}

.builder-gap-x-60{
  column-gap: 15rem !important;
}

.builder-gap-x-64{
  column-gap: 16rem !important;
}

.builder-gap-x-72{
  column-gap: 18rem !important;
}

.builder-gap-x-80{
  column-gap: 20rem !important;
}

.builder-gap-x-96{
  column-gap: 24rem !important;
}

.builder-gap-x-px{
  column-gap: 1px !important;
}

.builder-gap-x-0\.5{
  column-gap: 0.125rem !important;
}

.builder-gap-x-1\.5{
  column-gap: 0.375rem !important;
}

.builder-gap-x-2\.5{
  column-gap: 0.625rem !important;
}

.builder-gap-x-3\.5{
  column-gap: 0.875rem !important;
}

.builder-gap-y-0{
  row-gap: 0px !important;
}

.builder-gap-y-1{
  row-gap: 0.25rem !important;
}

.builder-gap-y-2{
  row-gap: 0.5rem !important;
}

.builder-gap-y-3{
  row-gap: 0.75rem !important;
}

.builder-gap-y-4{
  row-gap: 1rem !important;
}

.builder-gap-y-5{
  row-gap: 1.25rem !important;
}

.builder-gap-y-6{
  row-gap: 1.5rem !important;
}

.builder-gap-y-7{
  row-gap: 1.75rem !important;
}

.builder-gap-y-8{
  row-gap: 2rem !important;
}

.builder-gap-y-9{
  row-gap: 2.25rem !important;
}

.builder-gap-y-10{
  row-gap: 2.5rem !important;
}

.builder-gap-y-11{
  row-gap: 2.75rem !important;
}

.builder-gap-y-12{
  row-gap: 3rem !important;
}

.builder-gap-y-14{
  row-gap: 3.5rem !important;
}

.builder-gap-y-16{
  row-gap: 4rem !important;
}

.builder-gap-y-20{
  row-gap: 5rem !important;
}

.builder-gap-y-24{
  row-gap: 6rem !important;
}

.builder-gap-y-28{
  row-gap: 7rem !important;
}

.builder-gap-y-32{
  row-gap: 8rem !important;
}

.builder-gap-y-36{
  row-gap: 9rem !important;
}

.builder-gap-y-40{
  row-gap: 10rem !important;
}

.builder-gap-y-44{
  row-gap: 11rem !important;
}

.builder-gap-y-48{
  row-gap: 12rem !important;
}

.builder-gap-y-52{
  row-gap: 13rem !important;
}

.builder-gap-y-56{
  row-gap: 14rem !important;
}

.builder-gap-y-60{
  row-gap: 15rem !important;
}

.builder-gap-y-64{
  row-gap: 16rem !important;
}

.builder-gap-y-72{
  row-gap: 18rem !important;
}

.builder-gap-y-80{
  row-gap: 20rem !important;
}

.builder-gap-y-96{
  row-gap: 24rem !important;
}

.builder-gap-y-px{
  row-gap: 1px !important;
}

.builder-gap-y-0\.5{
  row-gap: 0.125rem !important;
}

.builder-gap-y-1\.5{
  row-gap: 0.375rem !important;
}

.builder-gap-y-2\.5{
  row-gap: 0.625rem !important;
}

.builder-gap-y-3\.5{
  row-gap: 0.875rem !important;
}

.builder-space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.builder-space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
}

.builder--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
}

.builder-space-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 1 !important;
}

.builder-space-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 1 !important;
}

.builder-divide-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.builder-divide-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.builder-divide-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.builder-divide-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.builder-divide-x > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.builder-divide-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
}

.builder-divide-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
}

.builder-divide-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
}

.builder-divide-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
}

.builder-divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.builder-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 1 !important;
}

.builder-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 1 !important;
}

.builder-divide-solid > :not([hidden]) ~ :not([hidden]){
  border-style: solid !important;
}

.builder-divide-dashed > :not([hidden]) ~ :not([hidden]){
  border-style: dashed !important;
}

.builder-divide-dotted > :not([hidden]) ~ :not([hidden]){
  border-style: dotted !important;
}

.builder-divide-double > :not([hidden]) ~ :not([hidden]){
  border-style: double !important;
}

.builder-divide-none > :not([hidden]) ~ :not([hidden]){
  border-style: none !important;
}

.builder-divide-transparent > :not([hidden]) ~ :not([hidden]){
  border-color: transparent !important;
}

.builder-divide-current > :not([hidden]) ~ :not([hidden]){
  border-color: currentColor !important;
}

.builder-divide-black > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
}

.builder-divide-white > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
}

.builder-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
}

.builder-divide-red-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
}

.builder-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
}

.builder-divide-green-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
}

.builder-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
}

.builder-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
}

.builder-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
}

.builder-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
}

.builder-divide-primary > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-divide-opacity)) !important;
}

.builder-divide-secondary-dark > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(52, 52, 52, var(--tw-divide-opacity)) !important;
}

.builder-divide-secondary-darker > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(33, 33, 33, var(--tw-divide-opacity)) !important;
}

.builder-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0 !important;
}

.builder-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.05 !important;
}

.builder-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.1 !important;
}

.builder-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.2 !important;
}

.builder-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.25 !important;
}

.builder-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.3 !important;
}

.builder-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.4 !important;
}

.builder-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.5 !important;
}

.builder-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.6 !important;
}

.builder-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.7 !important;
}

.builder-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.75 !important;
}

.builder-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.8 !important;
}

.builder-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.9 !important;
}

.builder-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.95 !important;
}

.builder-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
}

.builder-place-self-auto{
  place-self: auto !important;
}

.builder-place-self-start{
  place-self: start !important;
}

.builder-place-self-end{
  place-self: end !important;
}

.builder-place-self-center{
  place-self: center !important;
}

.builder-place-self-stretch{
  place-self: stretch !important;
}

.builder-self-auto{
  align-self: auto !important;
}

.builder-self-start{
  align-self: flex-start !important;
}

.builder-self-end{
  align-self: flex-end !important;
}

.builder-self-center{
  align-self: center !important;
}

.builder-self-stretch{
  align-self: stretch !important;
}

.builder-self-baseline{
  align-self: baseline !important;
}

.builder-justify-self-auto{
  justify-self: auto !important;
}

.builder-justify-self-start{
  justify-self: start !important;
}

.builder-justify-self-end{
  justify-self: end !important;
}

.builder-justify-self-center{
  justify-self: center !important;
}

.builder-justify-self-stretch{
  justify-self: stretch !important;
}

.builder-overflow-auto{
  overflow: auto !important;
}

.builder-overflow-hidden{
  overflow: hidden !important;
}

.builder-overflow-visible{
  overflow: visible !important;
}

.builder-overflow-scroll{
  overflow: scroll !important;
}

.builder-overflow-x-auto{
  overflow-x: auto !important;
}

.builder-overflow-y-auto{
  overflow-y: auto !important;
}

.builder-overflow-x-hidden{
  overflow-x: hidden !important;
}

.builder-overflow-y-hidden{
  overflow-y: hidden !important;
}

.builder-overflow-x-visible{
  overflow-x: visible !important;
}

.builder-overflow-y-visible{
  overflow-y: visible !important;
}

.builder-overflow-x-scroll{
  overflow-x: scroll !important;
}

.builder-overflow-y-scroll{
  overflow-y: scroll !important;
}

.builder-overscroll-auto{
  overscroll-behavior: auto !important;
}

.builder-overscroll-contain{
  overscroll-behavior: contain !important;
}

.builder-overscroll-none{
  overscroll-behavior: none !important;
}

.builder-overscroll-y-auto{
  overscroll-behavior-y: auto !important;
}

.builder-overscroll-y-contain{
  overscroll-behavior-y: contain !important;
}

.builder-overscroll-y-none{
  overscroll-behavior-y: none !important;
}

.builder-overscroll-x-auto{
  overscroll-behavior-x: auto !important;
}

.builder-overscroll-x-contain{
  overscroll-behavior-x: contain !important;
}

.builder-overscroll-x-none{
  overscroll-behavior-x: none !important;
}

.builder-truncate{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.builder-overflow-ellipsis{
  text-overflow: ellipsis !important;
}

.builder-overflow-clip{
  text-overflow: clip !important;
}

.builder-whitespace-normal{
  white-space: normal !important;
}

.builder-whitespace-nowrap{
  white-space: nowrap !important;
}

.builder-whitespace-pre{
  white-space: pre !important;
}

.builder-whitespace-pre-line{
  white-space: pre-line !important;
}

.builder-whitespace-pre-wrap{
  white-space: pre-wrap !important;
}

.builder-break-normal{
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.builder-break-words{
  overflow-wrap: break-word !important;
}

.builder-break-all{
  word-break: break-all !important;
}

.builder-rounded-none{
  border-radius: 0px !important;
}

.builder-rounded-sm{
  border-radius: 0.125rem !important;
}

.builder-rounded{
  border-radius: 0.25rem !important;
}

.builder-rounded-md{
  border-radius: 0.375rem !important;
}

.builder-rounded-lg{
  border-radius: 0.5rem !important;
}

.builder-rounded-xl{
  border-radius: 0.75rem !important;
}

.builder-rounded-2xl{
  border-radius: 1rem !important;
}

.builder-rounded-3xl{
  border-radius: 1.5rem !important;
}

.builder-rounded-full{
  border-radius: 9999px !important;
}

.builder-rounded-t-none{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.builder-rounded-t-sm{
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.builder-rounded-t{
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.builder-rounded-t-md{
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.builder-rounded-t-lg{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.builder-rounded-t-xl{
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.builder-rounded-t-2xl{
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.builder-rounded-t-3xl{
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.builder-rounded-t-full{
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.builder-rounded-r-none{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.builder-rounded-r-sm{
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.builder-rounded-r{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.builder-rounded-r-md{
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.builder-rounded-r-lg{
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.builder-rounded-r-xl{
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.builder-rounded-r-2xl{
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.builder-rounded-r-3xl{
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.builder-rounded-r-full{
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.builder-rounded-b-none{
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.builder-rounded-b-sm{
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.builder-rounded-b{
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.builder-rounded-b-md{
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.builder-rounded-b-lg{
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.builder-rounded-b-xl{
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.builder-rounded-b-2xl{
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.builder-rounded-b-3xl{
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.builder-rounded-b-full{
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.builder-rounded-l-none{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.builder-rounded-l-sm{
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.builder-rounded-l{
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.builder-rounded-l-md{
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.builder-rounded-l-lg{
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.builder-rounded-l-xl{
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.builder-rounded-l-2xl{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.builder-rounded-l-3xl{
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.builder-rounded-l-full{
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.builder-rounded-tl-none{
  border-top-left-radius: 0px !important;
}

.builder-rounded-tl-sm{
  border-top-left-radius: 0.125rem !important;
}

.builder-rounded-tl{
  border-top-left-radius: 0.25rem !important;
}

.builder-rounded-tl-md{
  border-top-left-radius: 0.375rem !important;
}

.builder-rounded-tl-lg{
  border-top-left-radius: 0.5rem !important;
}

.builder-rounded-tl-xl{
  border-top-left-radius: 0.75rem !important;
}

.builder-rounded-tl-2xl{
  border-top-left-radius: 1rem !important;
}

.builder-rounded-tl-3xl{
  border-top-left-radius: 1.5rem !important;
}

.builder-rounded-tl-full{
  border-top-left-radius: 9999px !important;
}

.builder-rounded-tr-none{
  border-top-right-radius: 0px !important;
}

.builder-rounded-tr-sm{
  border-top-right-radius: 0.125rem !important;
}

.builder-rounded-tr{
  border-top-right-radius: 0.25rem !important;
}

.builder-rounded-tr-md{
  border-top-right-radius: 0.375rem !important;
}

.builder-rounded-tr-lg{
  border-top-right-radius: 0.5rem !important;
}

.builder-rounded-tr-xl{
  border-top-right-radius: 0.75rem !important;
}

.builder-rounded-tr-2xl{
  border-top-right-radius: 1rem !important;
}

.builder-rounded-tr-3xl{
  border-top-right-radius: 1.5rem !important;
}

.builder-rounded-tr-full{
  border-top-right-radius: 9999px !important;
}

.builder-rounded-br-none{
  border-bottom-right-radius: 0px !important;
}

.builder-rounded-br-sm{
  border-bottom-right-radius: 0.125rem !important;
}

.builder-rounded-br{
  border-bottom-right-radius: 0.25rem !important;
}

.builder-rounded-br-md{
  border-bottom-right-radius: 0.375rem !important;
}

.builder-rounded-br-lg{
  border-bottom-right-radius: 0.5rem !important;
}

.builder-rounded-br-xl{
  border-bottom-right-radius: 0.75rem !important;
}

.builder-rounded-br-2xl{
  border-bottom-right-radius: 1rem !important;
}

.builder-rounded-br-3xl{
  border-bottom-right-radius: 1.5rem !important;
}

.builder-rounded-br-full{
  border-bottom-right-radius: 9999px !important;
}

.builder-rounded-bl-none{
  border-bottom-left-radius: 0px !important;
}

.builder-rounded-bl-sm{
  border-bottom-left-radius: 0.125rem !important;
}

.builder-rounded-bl{
  border-bottom-left-radius: 0.25rem !important;
}

.builder-rounded-bl-md{
  border-bottom-left-radius: 0.375rem !important;
}

.builder-rounded-bl-lg{
  border-bottom-left-radius: 0.5rem !important;
}

.builder-rounded-bl-xl{
  border-bottom-left-radius: 0.75rem !important;
}

.builder-rounded-bl-2xl{
  border-bottom-left-radius: 1rem !important;
}

.builder-rounded-bl-3xl{
  border-bottom-left-radius: 1.5rem !important;
}

.builder-rounded-bl-full{
  border-bottom-left-radius: 9999px !important;
}

.builder-border-0{
  border-width: 0px !important;
}

.builder-border-2{
  border-width: 2px !important;
}

.builder-border-4{
  border-width: 4px !important;
}

.builder-border-8{
  border-width: 8px !important;
}

.builder-border{
  border-width: 1px !important;
}

.builder-border-t-0{
  border-top-width: 0px !important;
}

.builder-border-t-2{
  border-top-width: 2px !important;
}

.builder-border-t-4{
  border-top-width: 4px !important;
}

.builder-border-t-8{
  border-top-width: 8px !important;
}

.builder-border-t{
  border-top-width: 1px !important;
}

.builder-border-r-0{
  border-right-width: 0px !important;
}

.builder-border-r-2{
  border-right-width: 2px !important;
}

.builder-border-r-4{
  border-right-width: 4px !important;
}

.builder-border-r-8{
  border-right-width: 8px !important;
}

.builder-border-r{
  border-right-width: 1px !important;
}

.builder-border-b-0{
  border-bottom-width: 0px !important;
}

.builder-border-b-2{
  border-bottom-width: 2px !important;
}

.builder-border-b-4{
  border-bottom-width: 4px !important;
}

.builder-border-b-8{
  border-bottom-width: 8px !important;
}

.builder-border-b{
  border-bottom-width: 1px !important;
}

.builder-border-l-0{
  border-left-width: 0px !important;
}

.builder-border-l-2{
  border-left-width: 2px !important;
}

.builder-border-l-4{
  border-left-width: 4px !important;
}

.builder-border-l-8{
  border-left-width: 8px !important;
}

.builder-border-l{
  border-left-width: 1px !important;
}

.builder-border-solid{
  border-style: solid !important;
}

.builder-border-dashed{
  border-style: dashed !important;
}

.builder-border-dotted{
  border-style: dotted !important;
}

.builder-border-double{
  border-style: double !important;
}

.builder-border-none{
  border-style: none !important;
}

.builder-border-transparent{
  border-color: transparent !important;
}

.builder-border-current{
  border-color: currentColor !important;
}

.builder-border-black{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.builder-border-white{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.builder-border-gray-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.builder-border-gray-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.builder-border-gray-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.builder-border-gray-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.builder-border-gray-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.builder-border-gray-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.builder-border-gray-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.builder-border-gray-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.builder-border-gray-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.builder-border-gray-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.builder-border-red-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.builder-border-red-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.builder-border-red-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.builder-border-red-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.builder-border-red-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.builder-border-red-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.builder-border-red-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.builder-border-red-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.builder-border-red-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.builder-border-red-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.builder-border-yellow-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.builder-border-green-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.builder-border-green-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.builder-border-green-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.builder-border-green-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.builder-border-green-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.builder-border-green-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.builder-border-green-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.builder-border-green-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.builder-border-green-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.builder-border-green-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.builder-border-blue-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.builder-border-blue-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.builder-border-blue-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.builder-border-blue-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.builder-border-blue-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.builder-border-blue-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.builder-border-blue-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.builder-border-blue-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.builder-border-blue-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.builder-border-blue-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.builder-border-indigo-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.builder-border-purple-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.builder-border-purple-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.builder-border-purple-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.builder-border-purple-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.builder-border-purple-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.builder-border-purple-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.builder-border-purple-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.builder-border-purple-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.builder-border-purple-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.builder-border-purple-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.builder-border-pink-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.builder-border-pink-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.builder-border-pink-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.builder-border-pink-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.builder-border-pink-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.builder-border-pink-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.builder-border-pink-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.builder-border-pink-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.builder-border-pink-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.builder-border-pink-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.builder-border-primary{
  --tw-border-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
}

.builder-border-secondary-dark{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
}

.builder-border-secondary-darker{
  --tw-border-opacity: 1 !important;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-transparent{
  border-color: transparent !important;
}

.builder-group:hover .group-hover\:builder-border-current{
  border-color: currentColor !important;
}

.builder-group:hover .group-hover\:builder-border-black{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-white{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-gray-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-red-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-yellow-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-green-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-blue-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-indigo-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-purple-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-pink-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-primary{
  --tw-border-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-secondary-dark{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-border-secondary-darker{
  --tw-border-opacity: 1 !important;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-transparent:focus-within{
  border-color: transparent !important;
}

.focus-within\:builder-border-current:focus-within{
  border-color: currentColor !important;
}

.focus-within\:builder-border-black:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-white:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-gray-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-red-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-yellow-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-green-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-blue-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-indigo-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-purple-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-pink-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-primary:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-secondary-dark:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
}

.focus-within\:builder-border-secondary-darker:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-transparent:hover{
  border-color: transparent !important;
}

.hover\:builder-border-current:hover{
  border-color: currentColor !important;
}

.hover\:builder-border-black:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-white:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-gray-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-red-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-yellow-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-green-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-blue-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-indigo-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-purple-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-pink-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-primary:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-secondary-dark:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
}

.hover\:builder-border-secondary-darker:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-transparent:focus{
  border-color: transparent !important;
}

.focus\:builder-border-current:focus{
  border-color: currentColor !important;
}

.focus\:builder-border-black:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-white:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-gray-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-red-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-yellow-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-green-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-blue-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-indigo-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-purple-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-pink-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-primary:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-secondary-dark:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
}

.focus\:builder-border-secondary-darker:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
}

.builder-border-opacity-0{
  --tw-border-opacity: 0 !important;
}

.builder-border-opacity-5{
  --tw-border-opacity: 0.05 !important;
}

.builder-border-opacity-10{
  --tw-border-opacity: 0.1 !important;
}

.builder-border-opacity-20{
  --tw-border-opacity: 0.2 !important;
}

.builder-border-opacity-25{
  --tw-border-opacity: 0.25 !important;
}

.builder-border-opacity-30{
  --tw-border-opacity: 0.3 !important;
}

.builder-border-opacity-40{
  --tw-border-opacity: 0.4 !important;
}

.builder-border-opacity-50{
  --tw-border-opacity: 0.5 !important;
}

.builder-border-opacity-60{
  --tw-border-opacity: 0.6 !important;
}

.builder-border-opacity-70{
  --tw-border-opacity: 0.7 !important;
}

.builder-border-opacity-75{
  --tw-border-opacity: 0.75 !important;
}

.builder-border-opacity-80{
  --tw-border-opacity: 0.8 !important;
}

.builder-border-opacity-90{
  --tw-border-opacity: 0.9 !important;
}

.builder-border-opacity-95{
  --tw-border-opacity: 0.95 !important;
}

.builder-border-opacity-100{
  --tw-border-opacity: 1 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-0{
  --tw-border-opacity: 0 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-5{
  --tw-border-opacity: 0.05 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-10{
  --tw-border-opacity: 0.1 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-20{
  --tw-border-opacity: 0.2 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-25{
  --tw-border-opacity: 0.25 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-30{
  --tw-border-opacity: 0.3 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-40{
  --tw-border-opacity: 0.4 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-50{
  --tw-border-opacity: 0.5 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-60{
  --tw-border-opacity: 0.6 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-70{
  --tw-border-opacity: 0.7 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-75{
  --tw-border-opacity: 0.75 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-80{
  --tw-border-opacity: 0.8 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-90{
  --tw-border-opacity: 0.9 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-95{
  --tw-border-opacity: 0.95 !important;
}

.builder-group:hover .group-hover\:builder-border-opacity-100{
  --tw-border-opacity: 1 !important;
}

.focus-within\:builder-border-opacity-0:focus-within{
  --tw-border-opacity: 0 !important;
}

.focus-within\:builder-border-opacity-5:focus-within{
  --tw-border-opacity: 0.05 !important;
}

.focus-within\:builder-border-opacity-10:focus-within{
  --tw-border-opacity: 0.1 !important;
}

.focus-within\:builder-border-opacity-20:focus-within{
  --tw-border-opacity: 0.2 !important;
}

.focus-within\:builder-border-opacity-25:focus-within{
  --tw-border-opacity: 0.25 !important;
}

.focus-within\:builder-border-opacity-30:focus-within{
  --tw-border-opacity: 0.3 !important;
}

.focus-within\:builder-border-opacity-40:focus-within{
  --tw-border-opacity: 0.4 !important;
}

.focus-within\:builder-border-opacity-50:focus-within{
  --tw-border-opacity: 0.5 !important;
}

.focus-within\:builder-border-opacity-60:focus-within{
  --tw-border-opacity: 0.6 !important;
}

.focus-within\:builder-border-opacity-70:focus-within{
  --tw-border-opacity: 0.7 !important;
}

.focus-within\:builder-border-opacity-75:focus-within{
  --tw-border-opacity: 0.75 !important;
}

.focus-within\:builder-border-opacity-80:focus-within{
  --tw-border-opacity: 0.8 !important;
}

.focus-within\:builder-border-opacity-90:focus-within{
  --tw-border-opacity: 0.9 !important;
}

.focus-within\:builder-border-opacity-95:focus-within{
  --tw-border-opacity: 0.95 !important;
}

.focus-within\:builder-border-opacity-100:focus-within{
  --tw-border-opacity: 1 !important;
}

.hover\:builder-border-opacity-0:hover{
  --tw-border-opacity: 0 !important;
}

.hover\:builder-border-opacity-5:hover{
  --tw-border-opacity: 0.05 !important;
}

.hover\:builder-border-opacity-10:hover{
  --tw-border-opacity: 0.1 !important;
}

.hover\:builder-border-opacity-20:hover{
  --tw-border-opacity: 0.2 !important;
}

.hover\:builder-border-opacity-25:hover{
  --tw-border-opacity: 0.25 !important;
}

.hover\:builder-border-opacity-30:hover{
  --tw-border-opacity: 0.3 !important;
}

.hover\:builder-border-opacity-40:hover{
  --tw-border-opacity: 0.4 !important;
}

.hover\:builder-border-opacity-50:hover{
  --tw-border-opacity: 0.5 !important;
}

.hover\:builder-border-opacity-60:hover{
  --tw-border-opacity: 0.6 !important;
}

.hover\:builder-border-opacity-70:hover{
  --tw-border-opacity: 0.7 !important;
}

.hover\:builder-border-opacity-75:hover{
  --tw-border-opacity: 0.75 !important;
}

.hover\:builder-border-opacity-80:hover{
  --tw-border-opacity: 0.8 !important;
}

.hover\:builder-border-opacity-90:hover{
  --tw-border-opacity: 0.9 !important;
}

.hover\:builder-border-opacity-95:hover{
  --tw-border-opacity: 0.95 !important;
}

.hover\:builder-border-opacity-100:hover{
  --tw-border-opacity: 1 !important;
}

.focus\:builder-border-opacity-0:focus{
  --tw-border-opacity: 0 !important;
}

.focus\:builder-border-opacity-5:focus{
  --tw-border-opacity: 0.05 !important;
}

.focus\:builder-border-opacity-10:focus{
  --tw-border-opacity: 0.1 !important;
}

.focus\:builder-border-opacity-20:focus{
  --tw-border-opacity: 0.2 !important;
}

.focus\:builder-border-opacity-25:focus{
  --tw-border-opacity: 0.25 !important;
}

.focus\:builder-border-opacity-30:focus{
  --tw-border-opacity: 0.3 !important;
}

.focus\:builder-border-opacity-40:focus{
  --tw-border-opacity: 0.4 !important;
}

.focus\:builder-border-opacity-50:focus{
  --tw-border-opacity: 0.5 !important;
}

.focus\:builder-border-opacity-60:focus{
  --tw-border-opacity: 0.6 !important;
}

.focus\:builder-border-opacity-70:focus{
  --tw-border-opacity: 0.7 !important;
}

.focus\:builder-border-opacity-75:focus{
  --tw-border-opacity: 0.75 !important;
}

.focus\:builder-border-opacity-80:focus{
  --tw-border-opacity: 0.8 !important;
}

.focus\:builder-border-opacity-90:focus{
  --tw-border-opacity: 0.9 !important;
}

.focus\:builder-border-opacity-95:focus{
  --tw-border-opacity: 0.95 !important;
}

.focus\:builder-border-opacity-100:focus{
  --tw-border-opacity: 1 !important;
}

.builder-bg-transparent{
  background-color: transparent !important;
}

.builder-bg-current{
  background-color: currentColor !important;
}

.builder-bg-black{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.builder-bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.builder-bg-gray-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.builder-bg-red-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.builder-bg-yellow-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.builder-bg-green-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.builder-bg-blue-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.builder-bg-indigo-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.builder-bg-purple-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.builder-bg-pink-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.builder-bg-primary{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
}

.builder-bg-secondary-dark{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
}

.builder-bg-secondary-darker{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-transparent{
  background-color: transparent !important;
}

.builder-group:hover .group-hover\:builder-bg-current{
  background-color: currentColor !important;
}

.builder-group:hover .group-hover\:builder-bg-black{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-gray-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-red-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-yellow-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-green-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-blue-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-indigo-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-purple-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-pink-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-primary{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-secondary-dark{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-bg-secondary-darker{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-transparent:focus-within{
  background-color: transparent !important;
}

.focus-within\:builder-bg-current:focus-within{
  background-color: currentColor !important;
}

.focus-within\:builder-bg-black:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-white:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-gray-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-red-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-yellow-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-green-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-blue-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-indigo-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-purple-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-pink-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-primary:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-secondary-dark:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
}

.focus-within\:builder-bg-secondary-darker:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-transparent:hover{
  background-color: transparent !important;
}

.hover\:builder-bg-current:hover{
  background-color: currentColor !important;
}

.hover\:builder-bg-black:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-white:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-gray-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-red-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-yellow-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-green-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-blue-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-indigo-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-purple-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-pink-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-primary:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-secondary-dark:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
}

.hover\:builder-bg-secondary-darker:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-transparent:focus{
  background-color: transparent !important;
}

.focus\:builder-bg-current:focus{
  background-color: currentColor !important;
}

.focus\:builder-bg-black:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-white:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-gray-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-red-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-yellow-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-green-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-blue-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-indigo-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-purple-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-pink-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-primary:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-secondary-dark:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
}

.focus\:builder-bg-secondary-darker:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
}

.builder-bg-opacity-0{
  --tw-bg-opacity: 0 !important;
}

.builder-bg-opacity-5{
  --tw-bg-opacity: 0.05 !important;
}

.builder-bg-opacity-10{
  --tw-bg-opacity: 0.1 !important;
}

.builder-bg-opacity-20{
  --tw-bg-opacity: 0.2 !important;
}

.builder-bg-opacity-25{
  --tw-bg-opacity: 0.25 !important;
}

.builder-bg-opacity-30{
  --tw-bg-opacity: 0.3 !important;
}

.builder-bg-opacity-40{
  --tw-bg-opacity: 0.4 !important;
}

.builder-bg-opacity-50{
  --tw-bg-opacity: 0.5 !important;
}

.builder-bg-opacity-60{
  --tw-bg-opacity: 0.6 !important;
}

.builder-bg-opacity-70{
  --tw-bg-opacity: 0.7 !important;
}

.builder-bg-opacity-75{
  --tw-bg-opacity: 0.75 !important;
}

.builder-bg-opacity-80{
  --tw-bg-opacity: 0.8 !important;
}

.builder-bg-opacity-90{
  --tw-bg-opacity: 0.9 !important;
}

.builder-bg-opacity-95{
  --tw-bg-opacity: 0.95 !important;
}

.builder-bg-opacity-100{
  --tw-bg-opacity: 1 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-0{
  --tw-bg-opacity: 0 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-5{
  --tw-bg-opacity: 0.05 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-10{
  --tw-bg-opacity: 0.1 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-20{
  --tw-bg-opacity: 0.2 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-25{
  --tw-bg-opacity: 0.25 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-30{
  --tw-bg-opacity: 0.3 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-40{
  --tw-bg-opacity: 0.4 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-50{
  --tw-bg-opacity: 0.5 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-60{
  --tw-bg-opacity: 0.6 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-70{
  --tw-bg-opacity: 0.7 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-75{
  --tw-bg-opacity: 0.75 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-80{
  --tw-bg-opacity: 0.8 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-90{
  --tw-bg-opacity: 0.9 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-95{
  --tw-bg-opacity: 0.95 !important;
}

.builder-group:hover .group-hover\:builder-bg-opacity-100{
  --tw-bg-opacity: 1 !important;
}

.focus-within\:builder-bg-opacity-0:focus-within{
  --tw-bg-opacity: 0 !important;
}

.focus-within\:builder-bg-opacity-5:focus-within{
  --tw-bg-opacity: 0.05 !important;
}

.focus-within\:builder-bg-opacity-10:focus-within{
  --tw-bg-opacity: 0.1 !important;
}

.focus-within\:builder-bg-opacity-20:focus-within{
  --tw-bg-opacity: 0.2 !important;
}

.focus-within\:builder-bg-opacity-25:focus-within{
  --tw-bg-opacity: 0.25 !important;
}

.focus-within\:builder-bg-opacity-30:focus-within{
  --tw-bg-opacity: 0.3 !important;
}

.focus-within\:builder-bg-opacity-40:focus-within{
  --tw-bg-opacity: 0.4 !important;
}

.focus-within\:builder-bg-opacity-50:focus-within{
  --tw-bg-opacity: 0.5 !important;
}

.focus-within\:builder-bg-opacity-60:focus-within{
  --tw-bg-opacity: 0.6 !important;
}

.focus-within\:builder-bg-opacity-70:focus-within{
  --tw-bg-opacity: 0.7 !important;
}

.focus-within\:builder-bg-opacity-75:focus-within{
  --tw-bg-opacity: 0.75 !important;
}

.focus-within\:builder-bg-opacity-80:focus-within{
  --tw-bg-opacity: 0.8 !important;
}

.focus-within\:builder-bg-opacity-90:focus-within{
  --tw-bg-opacity: 0.9 !important;
}

.focus-within\:builder-bg-opacity-95:focus-within{
  --tw-bg-opacity: 0.95 !important;
}

.focus-within\:builder-bg-opacity-100:focus-within{
  --tw-bg-opacity: 1 !important;
}

.hover\:builder-bg-opacity-0:hover{
  --tw-bg-opacity: 0 !important;
}

.hover\:builder-bg-opacity-5:hover{
  --tw-bg-opacity: 0.05 !important;
}

.hover\:builder-bg-opacity-10:hover{
  --tw-bg-opacity: 0.1 !important;
}

.hover\:builder-bg-opacity-20:hover{
  --tw-bg-opacity: 0.2 !important;
}

.hover\:builder-bg-opacity-25:hover{
  --tw-bg-opacity: 0.25 !important;
}

.hover\:builder-bg-opacity-30:hover{
  --tw-bg-opacity: 0.3 !important;
}

.hover\:builder-bg-opacity-40:hover{
  --tw-bg-opacity: 0.4 !important;
}

.hover\:builder-bg-opacity-50:hover{
  --tw-bg-opacity: 0.5 !important;
}

.hover\:builder-bg-opacity-60:hover{
  --tw-bg-opacity: 0.6 !important;
}

.hover\:builder-bg-opacity-70:hover{
  --tw-bg-opacity: 0.7 !important;
}

.hover\:builder-bg-opacity-75:hover{
  --tw-bg-opacity: 0.75 !important;
}

.hover\:builder-bg-opacity-80:hover{
  --tw-bg-opacity: 0.8 !important;
}

.hover\:builder-bg-opacity-90:hover{
  --tw-bg-opacity: 0.9 !important;
}

.hover\:builder-bg-opacity-95:hover{
  --tw-bg-opacity: 0.95 !important;
}

.hover\:builder-bg-opacity-100:hover{
  --tw-bg-opacity: 1 !important;
}

.focus\:builder-bg-opacity-0:focus{
  --tw-bg-opacity: 0 !important;
}

.focus\:builder-bg-opacity-5:focus{
  --tw-bg-opacity: 0.05 !important;
}

.focus\:builder-bg-opacity-10:focus{
  --tw-bg-opacity: 0.1 !important;
}

.focus\:builder-bg-opacity-20:focus{
  --tw-bg-opacity: 0.2 !important;
}

.focus\:builder-bg-opacity-25:focus{
  --tw-bg-opacity: 0.25 !important;
}

.focus\:builder-bg-opacity-30:focus{
  --tw-bg-opacity: 0.3 !important;
}

.focus\:builder-bg-opacity-40:focus{
  --tw-bg-opacity: 0.4 !important;
}

.focus\:builder-bg-opacity-50:focus{
  --tw-bg-opacity: 0.5 !important;
}

.focus\:builder-bg-opacity-60:focus{
  --tw-bg-opacity: 0.6 !important;
}

.focus\:builder-bg-opacity-70:focus{
  --tw-bg-opacity: 0.7 !important;
}

.focus\:builder-bg-opacity-75:focus{
  --tw-bg-opacity: 0.75 !important;
}

.focus\:builder-bg-opacity-80:focus{
  --tw-bg-opacity: 0.8 !important;
}

.focus\:builder-bg-opacity-90:focus{
  --tw-bg-opacity: 0.9 !important;
}

.focus\:builder-bg-opacity-95:focus{
  --tw-bg-opacity: 0.95 !important;
}

.focus\:builder-bg-opacity-100:focus{
  --tw-bg-opacity: 1 !important;
}

.builder-bg-none{
  background-image: none !important;
}

.builder-bg-gradient-to-t{
  background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-tr{
  background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-b{
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-bl{
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-l{
  background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
}

.builder-bg-gradient-to-tl{
  background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
}

.builder-from-transparent{
  --tw-gradient-from: transparent !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.builder-from-current{
  --tw-gradient-from: currentColor !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.builder-from-black{
  --tw-gradient-from: #000 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.builder-from-white{
  --tw-gradient-from: #fff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.builder-from-gray-50{
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.builder-from-gray-100{
  --tw-gradient-from: #f3f4f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.builder-from-gray-200{
  --tw-gradient-from: #e5e7eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.builder-from-gray-300{
  --tw-gradient-from: #d1d5db !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.builder-from-gray-400{
  --tw-gradient-from: #9ca3af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.builder-from-gray-500{
  --tw-gradient-from: #6b7280 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.builder-from-gray-600{
  --tw-gradient-from: #4b5563 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.builder-from-gray-700{
  --tw-gradient-from: #374151 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.builder-from-gray-800{
  --tw-gradient-from: #1f2937 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.builder-from-gray-900{
  --tw-gradient-from: #111827 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.builder-from-red-50{
  --tw-gradient-from: #fef2f2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.builder-from-red-100{
  --tw-gradient-from: #fee2e2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.builder-from-red-200{
  --tw-gradient-from: #fecaca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.builder-from-red-300{
  --tw-gradient-from: #fca5a5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.builder-from-red-400{
  --tw-gradient-from: #f87171 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.builder-from-red-500{
  --tw-gradient-from: #ef4444 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.builder-from-red-600{
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.builder-from-red-700{
  --tw-gradient-from: #b91c1c !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.builder-from-red-800{
  --tw-gradient-from: #991b1b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.builder-from-red-900{
  --tw-gradient-from: #7f1d1d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.builder-from-yellow-50{
  --tw-gradient-from: #fffbeb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.builder-from-yellow-100{
  --tw-gradient-from: #fef3c7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.builder-from-yellow-200{
  --tw-gradient-from: #fde68a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.builder-from-yellow-300{
  --tw-gradient-from: #fcd34d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.builder-from-yellow-400{
  --tw-gradient-from: #fbbf24 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.builder-from-yellow-500{
  --tw-gradient-from: #f59e0b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.builder-from-yellow-600{
  --tw-gradient-from: #d97706 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.builder-from-yellow-700{
  --tw-gradient-from: #b45309 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.builder-from-yellow-800{
  --tw-gradient-from: #92400e !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.builder-from-yellow-900{
  --tw-gradient-from: #78350f !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.builder-from-green-50{
  --tw-gradient-from: #ecfdf5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.builder-from-green-100{
  --tw-gradient-from: #d1fae5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.builder-from-green-200{
  --tw-gradient-from: #a7f3d0 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.builder-from-green-300{
  --tw-gradient-from: #6ee7b7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.builder-from-green-400{
  --tw-gradient-from: #34d399 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.builder-from-green-500{
  --tw-gradient-from: #10b981 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.builder-from-green-600{
  --tw-gradient-from: #059669 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.builder-from-green-700{
  --tw-gradient-from: #047857 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.builder-from-green-800{
  --tw-gradient-from: #065f46 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.builder-from-green-900{
  --tw-gradient-from: #064e3b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.builder-from-blue-50{
  --tw-gradient-from: #eff6ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.builder-from-blue-100{
  --tw-gradient-from: #dbeafe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.builder-from-blue-200{
  --tw-gradient-from: #bfdbfe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.builder-from-blue-300{
  --tw-gradient-from: #93c5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.builder-from-blue-400{
  --tw-gradient-from: #60a5fa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.builder-from-blue-500{
  --tw-gradient-from: #3b82f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.builder-from-blue-600{
  --tw-gradient-from: #2563eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.builder-from-blue-700{
  --tw-gradient-from: #1d4ed8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.builder-from-blue-800{
  --tw-gradient-from: #1e40af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.builder-from-blue-900{
  --tw-gradient-from: #1e3a8a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.builder-from-indigo-50{
  --tw-gradient-from: #eef2ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.builder-from-indigo-100{
  --tw-gradient-from: #e0e7ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.builder-from-indigo-200{
  --tw-gradient-from: #c7d2fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.builder-from-indigo-300{
  --tw-gradient-from: #a5b4fc !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.builder-from-indigo-400{
  --tw-gradient-from: #818cf8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.builder-from-indigo-500{
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.builder-from-indigo-600{
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.builder-from-indigo-700{
  --tw-gradient-from: #4338ca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.builder-from-indigo-800{
  --tw-gradient-from: #3730a3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.builder-from-indigo-900{
  --tw-gradient-from: #312e81 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.builder-from-purple-50{
  --tw-gradient-from: #f5f3ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.builder-from-purple-100{
  --tw-gradient-from: #ede9fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.builder-from-purple-200{
  --tw-gradient-from: #ddd6fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.builder-from-purple-300{
  --tw-gradient-from: #c4b5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.builder-from-purple-400{
  --tw-gradient-from: #a78bfa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.builder-from-purple-500{
  --tw-gradient-from: #8b5cf6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.builder-from-purple-600{
  --tw-gradient-from: #7c3aed !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.builder-from-purple-700{
  --tw-gradient-from: #6d28d9 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.builder-from-purple-800{
  --tw-gradient-from: #5b21b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.builder-from-purple-900{
  --tw-gradient-from: #4c1d95 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.builder-from-pink-50{
  --tw-gradient-from: #fdf2f8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.builder-from-pink-100{
  --tw-gradient-from: #fce7f3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.builder-from-pink-200{
  --tw-gradient-from: #fbcfe8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.builder-from-pink-300{
  --tw-gradient-from: #f9a8d4 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.builder-from-pink-400{
  --tw-gradient-from: #f472b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.builder-from-pink-500{
  --tw-gradient-from: #ec4899 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.builder-from-pink-600{
  --tw-gradient-from: #db2777 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.builder-from-pink-700{
  --tw-gradient-from: #be185d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.builder-from-pink-800{
  --tw-gradient-from: #9d174d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.builder-from-pink-900{
  --tw-gradient-from: #831843 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.builder-from-primary{
  --tw-gradient-from: #2d375a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
}

.builder-from-secondary-dark{
  --tw-gradient-from: #343434 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
}

.builder-from-secondary-darker{
  --tw-gradient-from: #212121 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
}

.hover\:builder-from-transparent:hover{
  --tw-gradient-from: transparent !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:builder-from-current:hover{
  --tw-gradient-from: currentColor !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:builder-from-black:hover{
  --tw-gradient-from: #000 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:builder-from-white:hover{
  --tw-gradient-from: #fff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:builder-from-gray-50:hover{
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.hover\:builder-from-gray-100:hover{
  --tw-gradient-from: #f3f4f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.hover\:builder-from-gray-200:hover{
  --tw-gradient-from: #e5e7eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.hover\:builder-from-gray-300:hover{
  --tw-gradient-from: #d1d5db !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.hover\:builder-from-gray-400:hover{
  --tw-gradient-from: #9ca3af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.hover\:builder-from-gray-500:hover{
  --tw-gradient-from: #6b7280 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.hover\:builder-from-gray-600:hover{
  --tw-gradient-from: #4b5563 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.hover\:builder-from-gray-700:hover{
  --tw-gradient-from: #374151 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.hover\:builder-from-gray-800:hover{
  --tw-gradient-from: #1f2937 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.hover\:builder-from-gray-900:hover{
  --tw-gradient-from: #111827 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.hover\:builder-from-red-50:hover{
  --tw-gradient-from: #fef2f2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.hover\:builder-from-red-100:hover{
  --tw-gradient-from: #fee2e2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.hover\:builder-from-red-200:hover{
  --tw-gradient-from: #fecaca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.hover\:builder-from-red-300:hover{
  --tw-gradient-from: #fca5a5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.hover\:builder-from-red-400:hover{
  --tw-gradient-from: #f87171 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.hover\:builder-from-red-500:hover{
  --tw-gradient-from: #ef4444 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.hover\:builder-from-red-600:hover{
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.hover\:builder-from-red-700:hover{
  --tw-gradient-from: #b91c1c !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.hover\:builder-from-red-800:hover{
  --tw-gradient-from: #991b1b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.hover\:builder-from-red-900:hover{
  --tw-gradient-from: #7f1d1d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.hover\:builder-from-yellow-50:hover{
  --tw-gradient-from: #fffbeb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.hover\:builder-from-yellow-100:hover{
  --tw-gradient-from: #fef3c7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.hover\:builder-from-yellow-200:hover{
  --tw-gradient-from: #fde68a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.hover\:builder-from-yellow-300:hover{
  --tw-gradient-from: #fcd34d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.hover\:builder-from-yellow-400:hover{
  --tw-gradient-from: #fbbf24 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.hover\:builder-from-yellow-500:hover{
  --tw-gradient-from: #f59e0b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.hover\:builder-from-yellow-600:hover{
  --tw-gradient-from: #d97706 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.hover\:builder-from-yellow-700:hover{
  --tw-gradient-from: #b45309 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.hover\:builder-from-yellow-800:hover{
  --tw-gradient-from: #92400e !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.hover\:builder-from-yellow-900:hover{
  --tw-gradient-from: #78350f !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.hover\:builder-from-green-50:hover{
  --tw-gradient-from: #ecfdf5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.hover\:builder-from-green-100:hover{
  --tw-gradient-from: #d1fae5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.hover\:builder-from-green-200:hover{
  --tw-gradient-from: #a7f3d0 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.hover\:builder-from-green-300:hover{
  --tw-gradient-from: #6ee7b7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.hover\:builder-from-green-400:hover{
  --tw-gradient-from: #34d399 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.hover\:builder-from-green-500:hover{
  --tw-gradient-from: #10b981 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.hover\:builder-from-green-600:hover{
  --tw-gradient-from: #059669 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.hover\:builder-from-green-700:hover{
  --tw-gradient-from: #047857 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.hover\:builder-from-green-800:hover{
  --tw-gradient-from: #065f46 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.hover\:builder-from-green-900:hover{
  --tw-gradient-from: #064e3b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.hover\:builder-from-blue-50:hover{
  --tw-gradient-from: #eff6ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.hover\:builder-from-blue-100:hover{
  --tw-gradient-from: #dbeafe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.hover\:builder-from-blue-200:hover{
  --tw-gradient-from: #bfdbfe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.hover\:builder-from-blue-300:hover{
  --tw-gradient-from: #93c5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.hover\:builder-from-blue-400:hover{
  --tw-gradient-from: #60a5fa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.hover\:builder-from-blue-500:hover{
  --tw-gradient-from: #3b82f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.hover\:builder-from-blue-600:hover{
  --tw-gradient-from: #2563eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.hover\:builder-from-blue-700:hover{
  --tw-gradient-from: #1d4ed8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.hover\:builder-from-blue-800:hover{
  --tw-gradient-from: #1e40af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.hover\:builder-from-blue-900:hover{
  --tw-gradient-from: #1e3a8a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.hover\:builder-from-indigo-50:hover{
  --tw-gradient-from: #eef2ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.hover\:builder-from-indigo-100:hover{
  --tw-gradient-from: #e0e7ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.hover\:builder-from-indigo-200:hover{
  --tw-gradient-from: #c7d2fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.hover\:builder-from-indigo-300:hover{
  --tw-gradient-from: #a5b4fc !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.hover\:builder-from-indigo-400:hover{
  --tw-gradient-from: #818cf8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.hover\:builder-from-indigo-500:hover{
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.hover\:builder-from-indigo-600:hover{
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.hover\:builder-from-indigo-700:hover{
  --tw-gradient-from: #4338ca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.hover\:builder-from-indigo-800:hover{
  --tw-gradient-from: #3730a3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.hover\:builder-from-indigo-900:hover{
  --tw-gradient-from: #312e81 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.hover\:builder-from-purple-50:hover{
  --tw-gradient-from: #f5f3ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.hover\:builder-from-purple-100:hover{
  --tw-gradient-from: #ede9fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.hover\:builder-from-purple-200:hover{
  --tw-gradient-from: #ddd6fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.hover\:builder-from-purple-300:hover{
  --tw-gradient-from: #c4b5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.hover\:builder-from-purple-400:hover{
  --tw-gradient-from: #a78bfa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.hover\:builder-from-purple-500:hover{
  --tw-gradient-from: #8b5cf6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.hover\:builder-from-purple-600:hover{
  --tw-gradient-from: #7c3aed !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.hover\:builder-from-purple-700:hover{
  --tw-gradient-from: #6d28d9 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.hover\:builder-from-purple-800:hover{
  --tw-gradient-from: #5b21b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.hover\:builder-from-purple-900:hover{
  --tw-gradient-from: #4c1d95 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.hover\:builder-from-pink-50:hover{
  --tw-gradient-from: #fdf2f8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.hover\:builder-from-pink-100:hover{
  --tw-gradient-from: #fce7f3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.hover\:builder-from-pink-200:hover{
  --tw-gradient-from: #fbcfe8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.hover\:builder-from-pink-300:hover{
  --tw-gradient-from: #f9a8d4 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.hover\:builder-from-pink-400:hover{
  --tw-gradient-from: #f472b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.hover\:builder-from-pink-500:hover{
  --tw-gradient-from: #ec4899 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.hover\:builder-from-pink-600:hover{
  --tw-gradient-from: #db2777 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.hover\:builder-from-pink-700:hover{
  --tw-gradient-from: #be185d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.hover\:builder-from-pink-800:hover{
  --tw-gradient-from: #9d174d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.hover\:builder-from-pink-900:hover{
  --tw-gradient-from: #831843 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.hover\:builder-from-primary:hover{
  --tw-gradient-from: #2d375a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
}

.hover\:builder-from-secondary-dark:hover{
  --tw-gradient-from: #343434 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
}

.hover\:builder-from-secondary-darker:hover{
  --tw-gradient-from: #212121 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
}

.focus\:builder-from-transparent:focus{
  --tw-gradient-from: transparent !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:builder-from-current:focus{
  --tw-gradient-from: currentColor !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:builder-from-black:focus{
  --tw-gradient-from: #000 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:builder-from-white:focus{
  --tw-gradient-from: #fff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:builder-from-gray-50:focus{
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.focus\:builder-from-gray-100:focus{
  --tw-gradient-from: #f3f4f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.focus\:builder-from-gray-200:focus{
  --tw-gradient-from: #e5e7eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.focus\:builder-from-gray-300:focus{
  --tw-gradient-from: #d1d5db !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.focus\:builder-from-gray-400:focus{
  --tw-gradient-from: #9ca3af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.focus\:builder-from-gray-500:focus{
  --tw-gradient-from: #6b7280 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.focus\:builder-from-gray-600:focus{
  --tw-gradient-from: #4b5563 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.focus\:builder-from-gray-700:focus{
  --tw-gradient-from: #374151 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.focus\:builder-from-gray-800:focus{
  --tw-gradient-from: #1f2937 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.focus\:builder-from-gray-900:focus{
  --tw-gradient-from: #111827 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.focus\:builder-from-red-50:focus{
  --tw-gradient-from: #fef2f2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.focus\:builder-from-red-100:focus{
  --tw-gradient-from: #fee2e2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.focus\:builder-from-red-200:focus{
  --tw-gradient-from: #fecaca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.focus\:builder-from-red-300:focus{
  --tw-gradient-from: #fca5a5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.focus\:builder-from-red-400:focus{
  --tw-gradient-from: #f87171 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.focus\:builder-from-red-500:focus{
  --tw-gradient-from: #ef4444 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.focus\:builder-from-red-600:focus{
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.focus\:builder-from-red-700:focus{
  --tw-gradient-from: #b91c1c !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.focus\:builder-from-red-800:focus{
  --tw-gradient-from: #991b1b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.focus\:builder-from-red-900:focus{
  --tw-gradient-from: #7f1d1d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.focus\:builder-from-yellow-50:focus{
  --tw-gradient-from: #fffbeb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.focus\:builder-from-yellow-100:focus{
  --tw-gradient-from: #fef3c7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.focus\:builder-from-yellow-200:focus{
  --tw-gradient-from: #fde68a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.focus\:builder-from-yellow-300:focus{
  --tw-gradient-from: #fcd34d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.focus\:builder-from-yellow-400:focus{
  --tw-gradient-from: #fbbf24 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.focus\:builder-from-yellow-500:focus{
  --tw-gradient-from: #f59e0b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.focus\:builder-from-yellow-600:focus{
  --tw-gradient-from: #d97706 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.focus\:builder-from-yellow-700:focus{
  --tw-gradient-from: #b45309 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.focus\:builder-from-yellow-800:focus{
  --tw-gradient-from: #92400e !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.focus\:builder-from-yellow-900:focus{
  --tw-gradient-from: #78350f !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.focus\:builder-from-green-50:focus{
  --tw-gradient-from: #ecfdf5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.focus\:builder-from-green-100:focus{
  --tw-gradient-from: #d1fae5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.focus\:builder-from-green-200:focus{
  --tw-gradient-from: #a7f3d0 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.focus\:builder-from-green-300:focus{
  --tw-gradient-from: #6ee7b7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.focus\:builder-from-green-400:focus{
  --tw-gradient-from: #34d399 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.focus\:builder-from-green-500:focus{
  --tw-gradient-from: #10b981 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.focus\:builder-from-green-600:focus{
  --tw-gradient-from: #059669 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.focus\:builder-from-green-700:focus{
  --tw-gradient-from: #047857 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.focus\:builder-from-green-800:focus{
  --tw-gradient-from: #065f46 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.focus\:builder-from-green-900:focus{
  --tw-gradient-from: #064e3b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.focus\:builder-from-blue-50:focus{
  --tw-gradient-from: #eff6ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.focus\:builder-from-blue-100:focus{
  --tw-gradient-from: #dbeafe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.focus\:builder-from-blue-200:focus{
  --tw-gradient-from: #bfdbfe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.focus\:builder-from-blue-300:focus{
  --tw-gradient-from: #93c5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.focus\:builder-from-blue-400:focus{
  --tw-gradient-from: #60a5fa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.focus\:builder-from-blue-500:focus{
  --tw-gradient-from: #3b82f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.focus\:builder-from-blue-600:focus{
  --tw-gradient-from: #2563eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.focus\:builder-from-blue-700:focus{
  --tw-gradient-from: #1d4ed8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.focus\:builder-from-blue-800:focus{
  --tw-gradient-from: #1e40af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.focus\:builder-from-blue-900:focus{
  --tw-gradient-from: #1e3a8a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.focus\:builder-from-indigo-50:focus{
  --tw-gradient-from: #eef2ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.focus\:builder-from-indigo-100:focus{
  --tw-gradient-from: #e0e7ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.focus\:builder-from-indigo-200:focus{
  --tw-gradient-from: #c7d2fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.focus\:builder-from-indigo-300:focus{
  --tw-gradient-from: #a5b4fc !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.focus\:builder-from-indigo-400:focus{
  --tw-gradient-from: #818cf8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.focus\:builder-from-indigo-500:focus{
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.focus\:builder-from-indigo-600:focus{
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.focus\:builder-from-indigo-700:focus{
  --tw-gradient-from: #4338ca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.focus\:builder-from-indigo-800:focus{
  --tw-gradient-from: #3730a3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.focus\:builder-from-indigo-900:focus{
  --tw-gradient-from: #312e81 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.focus\:builder-from-purple-50:focus{
  --tw-gradient-from: #f5f3ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.focus\:builder-from-purple-100:focus{
  --tw-gradient-from: #ede9fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.focus\:builder-from-purple-200:focus{
  --tw-gradient-from: #ddd6fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.focus\:builder-from-purple-300:focus{
  --tw-gradient-from: #c4b5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.focus\:builder-from-purple-400:focus{
  --tw-gradient-from: #a78bfa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.focus\:builder-from-purple-500:focus{
  --tw-gradient-from: #8b5cf6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.focus\:builder-from-purple-600:focus{
  --tw-gradient-from: #7c3aed !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.focus\:builder-from-purple-700:focus{
  --tw-gradient-from: #6d28d9 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.focus\:builder-from-purple-800:focus{
  --tw-gradient-from: #5b21b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.focus\:builder-from-purple-900:focus{
  --tw-gradient-from: #4c1d95 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.focus\:builder-from-pink-50:focus{
  --tw-gradient-from: #fdf2f8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.focus\:builder-from-pink-100:focus{
  --tw-gradient-from: #fce7f3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.focus\:builder-from-pink-200:focus{
  --tw-gradient-from: #fbcfe8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.focus\:builder-from-pink-300:focus{
  --tw-gradient-from: #f9a8d4 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.focus\:builder-from-pink-400:focus{
  --tw-gradient-from: #f472b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.focus\:builder-from-pink-500:focus{
  --tw-gradient-from: #ec4899 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.focus\:builder-from-pink-600:focus{
  --tw-gradient-from: #db2777 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.focus\:builder-from-pink-700:focus{
  --tw-gradient-from: #be185d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.focus\:builder-from-pink-800:focus{
  --tw-gradient-from: #9d174d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.focus\:builder-from-pink-900:focus{
  --tw-gradient-from: #831843 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.focus\:builder-from-primary:focus{
  --tw-gradient-from: #2d375a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
}

.focus\:builder-from-secondary-dark:focus{
  --tw-gradient-from: #343434 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
}

.focus\:builder-from-secondary-darker:focus{
  --tw-gradient-from: #212121 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
}

.builder-via-transparent{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.builder-via-current{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.builder-via-black{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.builder-via-white{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.builder-via-gray-50{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.builder-via-gray-100{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.builder-via-gray-200{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.builder-via-gray-300{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.builder-via-gray-400{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.builder-via-gray-500{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.builder-via-gray-600{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.builder-via-gray-700{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.builder-via-gray-800{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.builder-via-gray-900{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.builder-via-red-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.builder-via-red-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.builder-via-red-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.builder-via-red-300{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.builder-via-red-400{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.builder-via-red-500{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.builder-via-red-600{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.builder-via-red-700{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.builder-via-red-800{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.builder-via-red-900{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.builder-via-yellow-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.builder-via-yellow-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.builder-via-yellow-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.builder-via-yellow-300{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.builder-via-yellow-400{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.builder-via-yellow-500{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.builder-via-yellow-600{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.builder-via-yellow-700{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.builder-via-yellow-800{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.builder-via-yellow-900{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.builder-via-green-50{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.builder-via-green-100{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.builder-via-green-200{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.builder-via-green-300{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.builder-via-green-400{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.builder-via-green-500{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.builder-via-green-600{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.builder-via-green-700{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.builder-via-green-800{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.builder-via-green-900{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.builder-via-blue-50{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.builder-via-blue-100{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.builder-via-blue-200{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.builder-via-blue-300{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.builder-via-blue-400{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.builder-via-blue-500{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.builder-via-blue-600{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.builder-via-blue-700{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.builder-via-blue-800{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.builder-via-blue-900{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.builder-via-indigo-50{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.builder-via-indigo-100{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.builder-via-indigo-200{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.builder-via-indigo-300{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.builder-via-indigo-400{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.builder-via-indigo-500{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.builder-via-indigo-600{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.builder-via-indigo-700{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.builder-via-indigo-800{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.builder-via-indigo-900{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.builder-via-purple-50{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.builder-via-purple-100{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.builder-via-purple-200{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.builder-via-purple-300{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.builder-via-purple-400{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.builder-via-purple-500{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.builder-via-purple-600{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.builder-via-purple-700{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.builder-via-purple-800{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.builder-via-purple-900{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.builder-via-pink-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.builder-via-pink-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.builder-via-pink-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.builder-via-pink-300{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.builder-via-pink-400{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.builder-via-pink-500{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.builder-via-pink-600{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.builder-via-pink-700{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.builder-via-pink-800{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.builder-via-pink-900{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.builder-via-primary{
  --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
}

.builder-via-secondary-dark{
  --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
}

.builder-via-secondary-darker{
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
}

.hover\:builder-via-transparent:hover{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:builder-via-current:hover{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:builder-via-black:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:builder-via-white:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:builder-via-gray-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.hover\:builder-via-gray-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.hover\:builder-via-gray-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.hover\:builder-via-gray-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.hover\:builder-via-gray-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.hover\:builder-via-gray-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.hover\:builder-via-gray-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.hover\:builder-via-gray-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.hover\:builder-via-gray-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.hover\:builder-via-gray-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.hover\:builder-via-red-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.hover\:builder-via-red-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.hover\:builder-via-red-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.hover\:builder-via-red-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.hover\:builder-via-red-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.hover\:builder-via-red-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.hover\:builder-via-red-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.hover\:builder-via-red-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.hover\:builder-via-red-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.hover\:builder-via-red-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.hover\:builder-via-yellow-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.hover\:builder-via-yellow-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.hover\:builder-via-yellow-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.hover\:builder-via-yellow-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.hover\:builder-via-yellow-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.hover\:builder-via-yellow-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.hover\:builder-via-yellow-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.hover\:builder-via-yellow-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.hover\:builder-via-yellow-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.hover\:builder-via-yellow-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.hover\:builder-via-green-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.hover\:builder-via-green-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.hover\:builder-via-green-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.hover\:builder-via-green-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.hover\:builder-via-green-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.hover\:builder-via-green-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.hover\:builder-via-green-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.hover\:builder-via-green-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.hover\:builder-via-green-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.hover\:builder-via-green-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.hover\:builder-via-blue-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.hover\:builder-via-blue-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.hover\:builder-via-blue-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.hover\:builder-via-blue-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.hover\:builder-via-blue-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.hover\:builder-via-blue-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.hover\:builder-via-blue-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.hover\:builder-via-blue-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.hover\:builder-via-blue-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.hover\:builder-via-blue-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.hover\:builder-via-indigo-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.hover\:builder-via-indigo-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.hover\:builder-via-indigo-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.hover\:builder-via-indigo-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.hover\:builder-via-indigo-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.hover\:builder-via-indigo-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.hover\:builder-via-indigo-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.hover\:builder-via-indigo-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.hover\:builder-via-indigo-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.hover\:builder-via-indigo-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.hover\:builder-via-purple-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.hover\:builder-via-purple-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.hover\:builder-via-purple-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.hover\:builder-via-purple-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.hover\:builder-via-purple-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.hover\:builder-via-purple-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.hover\:builder-via-purple-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.hover\:builder-via-purple-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.hover\:builder-via-purple-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.hover\:builder-via-purple-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.hover\:builder-via-pink-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.hover\:builder-via-pink-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.hover\:builder-via-pink-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.hover\:builder-via-pink-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.hover\:builder-via-pink-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.hover\:builder-via-pink-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.hover\:builder-via-pink-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.hover\:builder-via-pink-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.hover\:builder-via-pink-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.hover\:builder-via-pink-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.hover\:builder-via-primary:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
}

.hover\:builder-via-secondary-dark:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
}

.hover\:builder-via-secondary-darker:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
}

.focus\:builder-via-transparent:focus{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:builder-via-current:focus{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:builder-via-black:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:builder-via-white:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:builder-via-gray-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.focus\:builder-via-gray-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.focus\:builder-via-gray-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.focus\:builder-via-gray-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.focus\:builder-via-gray-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.focus\:builder-via-gray-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.focus\:builder-via-gray-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.focus\:builder-via-gray-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.focus\:builder-via-gray-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.focus\:builder-via-gray-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.focus\:builder-via-red-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.focus\:builder-via-red-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.focus\:builder-via-red-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.focus\:builder-via-red-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.focus\:builder-via-red-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.focus\:builder-via-red-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.focus\:builder-via-red-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.focus\:builder-via-red-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.focus\:builder-via-red-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.focus\:builder-via-red-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.focus\:builder-via-yellow-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.focus\:builder-via-yellow-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.focus\:builder-via-yellow-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.focus\:builder-via-yellow-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.focus\:builder-via-yellow-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.focus\:builder-via-yellow-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.focus\:builder-via-yellow-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.focus\:builder-via-yellow-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.focus\:builder-via-yellow-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.focus\:builder-via-yellow-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.focus\:builder-via-green-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.focus\:builder-via-green-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.focus\:builder-via-green-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.focus\:builder-via-green-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.focus\:builder-via-green-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.focus\:builder-via-green-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.focus\:builder-via-green-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.focus\:builder-via-green-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.focus\:builder-via-green-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.focus\:builder-via-green-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.focus\:builder-via-blue-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.focus\:builder-via-blue-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.focus\:builder-via-blue-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.focus\:builder-via-blue-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.focus\:builder-via-blue-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.focus\:builder-via-blue-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.focus\:builder-via-blue-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.focus\:builder-via-blue-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.focus\:builder-via-blue-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.focus\:builder-via-blue-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.focus\:builder-via-indigo-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.focus\:builder-via-indigo-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.focus\:builder-via-indigo-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.focus\:builder-via-indigo-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.focus\:builder-via-indigo-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.focus\:builder-via-indigo-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.focus\:builder-via-indigo-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.focus\:builder-via-indigo-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.focus\:builder-via-indigo-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.focus\:builder-via-indigo-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.focus\:builder-via-purple-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.focus\:builder-via-purple-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.focus\:builder-via-purple-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.focus\:builder-via-purple-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.focus\:builder-via-purple-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.focus\:builder-via-purple-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.focus\:builder-via-purple-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.focus\:builder-via-purple-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.focus\:builder-via-purple-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.focus\:builder-via-purple-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.focus\:builder-via-pink-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.focus\:builder-via-pink-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.focus\:builder-via-pink-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.focus\:builder-via-pink-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.focus\:builder-via-pink-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.focus\:builder-via-pink-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.focus\:builder-via-pink-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.focus\:builder-via-pink-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.focus\:builder-via-pink-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.focus\:builder-via-pink-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.focus\:builder-via-primary:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
}

.focus\:builder-via-secondary-dark:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
}

.focus\:builder-via-secondary-darker:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
}

.builder-to-transparent{
  --tw-gradient-to: transparent !important;
}

.builder-to-current{
  --tw-gradient-to: currentColor !important;
}

.builder-to-black{
  --tw-gradient-to: #000 !important;
}

.builder-to-white{
  --tw-gradient-to: #fff !important;
}

.builder-to-gray-50{
  --tw-gradient-to: #f9fafb !important;
}

.builder-to-gray-100{
  --tw-gradient-to: #f3f4f6 !important;
}

.builder-to-gray-200{
  --tw-gradient-to: #e5e7eb !important;
}

.builder-to-gray-300{
  --tw-gradient-to: #d1d5db !important;
}

.builder-to-gray-400{
  --tw-gradient-to: #9ca3af !important;
}

.builder-to-gray-500{
  --tw-gradient-to: #6b7280 !important;
}

.builder-to-gray-600{
  --tw-gradient-to: #4b5563 !important;
}

.builder-to-gray-700{
  --tw-gradient-to: #374151 !important;
}

.builder-to-gray-800{
  --tw-gradient-to: #1f2937 !important;
}

.builder-to-gray-900{
  --tw-gradient-to: #111827 !important;
}

.builder-to-red-50{
  --tw-gradient-to: #fef2f2 !important;
}

.builder-to-red-100{
  --tw-gradient-to: #fee2e2 !important;
}

.builder-to-red-200{
  --tw-gradient-to: #fecaca !important;
}

.builder-to-red-300{
  --tw-gradient-to: #fca5a5 !important;
}

.builder-to-red-400{
  --tw-gradient-to: #f87171 !important;
}

.builder-to-red-500{
  --tw-gradient-to: #ef4444 !important;
}

.builder-to-red-600{
  --tw-gradient-to: #dc2626 !important;
}

.builder-to-red-700{
  --tw-gradient-to: #b91c1c !important;
}

.builder-to-red-800{
  --tw-gradient-to: #991b1b !important;
}

.builder-to-red-900{
  --tw-gradient-to: #7f1d1d !important;
}

.builder-to-yellow-50{
  --tw-gradient-to: #fffbeb !important;
}

.builder-to-yellow-100{
  --tw-gradient-to: #fef3c7 !important;
}

.builder-to-yellow-200{
  --tw-gradient-to: #fde68a !important;
}

.builder-to-yellow-300{
  --tw-gradient-to: #fcd34d !important;
}

.builder-to-yellow-400{
  --tw-gradient-to: #fbbf24 !important;
}

.builder-to-yellow-500{
  --tw-gradient-to: #f59e0b !important;
}

.builder-to-yellow-600{
  --tw-gradient-to: #d97706 !important;
}

.builder-to-yellow-700{
  --tw-gradient-to: #b45309 !important;
}

.builder-to-yellow-800{
  --tw-gradient-to: #92400e !important;
}

.builder-to-yellow-900{
  --tw-gradient-to: #78350f !important;
}

.builder-to-green-50{
  --tw-gradient-to: #ecfdf5 !important;
}

.builder-to-green-100{
  --tw-gradient-to: #d1fae5 !important;
}

.builder-to-green-200{
  --tw-gradient-to: #a7f3d0 !important;
}

.builder-to-green-300{
  --tw-gradient-to: #6ee7b7 !important;
}

.builder-to-green-400{
  --tw-gradient-to: #34d399 !important;
}

.builder-to-green-500{
  --tw-gradient-to: #10b981 !important;
}

.builder-to-green-600{
  --tw-gradient-to: #059669 !important;
}

.builder-to-green-700{
  --tw-gradient-to: #047857 !important;
}

.builder-to-green-800{
  --tw-gradient-to: #065f46 !important;
}

.builder-to-green-900{
  --tw-gradient-to: #064e3b !important;
}

.builder-to-blue-50{
  --tw-gradient-to: #eff6ff !important;
}

.builder-to-blue-100{
  --tw-gradient-to: #dbeafe !important;
}

.builder-to-blue-200{
  --tw-gradient-to: #bfdbfe !important;
}

.builder-to-blue-300{
  --tw-gradient-to: #93c5fd !important;
}

.builder-to-blue-400{
  --tw-gradient-to: #60a5fa !important;
}

.builder-to-blue-500{
  --tw-gradient-to: #3b82f6 !important;
}

.builder-to-blue-600{
  --tw-gradient-to: #2563eb !important;
}

.builder-to-blue-700{
  --tw-gradient-to: #1d4ed8 !important;
}

.builder-to-blue-800{
  --tw-gradient-to: #1e40af !important;
}

.builder-to-blue-900{
  --tw-gradient-to: #1e3a8a !important;
}

.builder-to-indigo-50{
  --tw-gradient-to: #eef2ff !important;
}

.builder-to-indigo-100{
  --tw-gradient-to: #e0e7ff !important;
}

.builder-to-indigo-200{
  --tw-gradient-to: #c7d2fe !important;
}

.builder-to-indigo-300{
  --tw-gradient-to: #a5b4fc !important;
}

.builder-to-indigo-400{
  --tw-gradient-to: #818cf8 !important;
}

.builder-to-indigo-500{
  --tw-gradient-to: #6366f1 !important;
}

.builder-to-indigo-600{
  --tw-gradient-to: #4f46e5 !important;
}

.builder-to-indigo-700{
  --tw-gradient-to: #4338ca !important;
}

.builder-to-indigo-800{
  --tw-gradient-to: #3730a3 !important;
}

.builder-to-indigo-900{
  --tw-gradient-to: #312e81 !important;
}

.builder-to-purple-50{
  --tw-gradient-to: #f5f3ff !important;
}

.builder-to-purple-100{
  --tw-gradient-to: #ede9fe !important;
}

.builder-to-purple-200{
  --tw-gradient-to: #ddd6fe !important;
}

.builder-to-purple-300{
  --tw-gradient-to: #c4b5fd !important;
}

.builder-to-purple-400{
  --tw-gradient-to: #a78bfa !important;
}

.builder-to-purple-500{
  --tw-gradient-to: #8b5cf6 !important;
}

.builder-to-purple-600{
  --tw-gradient-to: #7c3aed !important;
}

.builder-to-purple-700{
  --tw-gradient-to: #6d28d9 !important;
}

.builder-to-purple-800{
  --tw-gradient-to: #5b21b6 !important;
}

.builder-to-purple-900{
  --tw-gradient-to: #4c1d95 !important;
}

.builder-to-pink-50{
  --tw-gradient-to: #fdf2f8 !important;
}

.builder-to-pink-100{
  --tw-gradient-to: #fce7f3 !important;
}

.builder-to-pink-200{
  --tw-gradient-to: #fbcfe8 !important;
}

.builder-to-pink-300{
  --tw-gradient-to: #f9a8d4 !important;
}

.builder-to-pink-400{
  --tw-gradient-to: #f472b6 !important;
}

.builder-to-pink-500{
  --tw-gradient-to: #ec4899 !important;
}

.builder-to-pink-600{
  --tw-gradient-to: #db2777 !important;
}

.builder-to-pink-700{
  --tw-gradient-to: #be185d !important;
}

.builder-to-pink-800{
  --tw-gradient-to: #9d174d !important;
}

.builder-to-pink-900{
  --tw-gradient-to: #831843 !important;
}

.builder-to-primary{
  --tw-gradient-to: #2d375a !important;
}

.builder-to-secondary-dark{
  --tw-gradient-to: #343434 !important;
}

.builder-to-secondary-darker{
  --tw-gradient-to: #212121 !important;
}

.hover\:builder-to-transparent:hover{
  --tw-gradient-to: transparent !important;
}

.hover\:builder-to-current:hover{
  --tw-gradient-to: currentColor !important;
}

.hover\:builder-to-black:hover{
  --tw-gradient-to: #000 !important;
}

.hover\:builder-to-white:hover{
  --tw-gradient-to: #fff !important;
}

.hover\:builder-to-gray-50:hover{
  --tw-gradient-to: #f9fafb !important;
}

.hover\:builder-to-gray-100:hover{
  --tw-gradient-to: #f3f4f6 !important;
}

.hover\:builder-to-gray-200:hover{
  --tw-gradient-to: #e5e7eb !important;
}

.hover\:builder-to-gray-300:hover{
  --tw-gradient-to: #d1d5db !important;
}

.hover\:builder-to-gray-400:hover{
  --tw-gradient-to: #9ca3af !important;
}

.hover\:builder-to-gray-500:hover{
  --tw-gradient-to: #6b7280 !important;
}

.hover\:builder-to-gray-600:hover{
  --tw-gradient-to: #4b5563 !important;
}

.hover\:builder-to-gray-700:hover{
  --tw-gradient-to: #374151 !important;
}

.hover\:builder-to-gray-800:hover{
  --tw-gradient-to: #1f2937 !important;
}

.hover\:builder-to-gray-900:hover{
  --tw-gradient-to: #111827 !important;
}

.hover\:builder-to-red-50:hover{
  --tw-gradient-to: #fef2f2 !important;
}

.hover\:builder-to-red-100:hover{
  --tw-gradient-to: #fee2e2 !important;
}

.hover\:builder-to-red-200:hover{
  --tw-gradient-to: #fecaca !important;
}

.hover\:builder-to-red-300:hover{
  --tw-gradient-to: #fca5a5 !important;
}

.hover\:builder-to-red-400:hover{
  --tw-gradient-to: #f87171 !important;
}

.hover\:builder-to-red-500:hover{
  --tw-gradient-to: #ef4444 !important;
}

.hover\:builder-to-red-600:hover{
  --tw-gradient-to: #dc2626 !important;
}

.hover\:builder-to-red-700:hover{
  --tw-gradient-to: #b91c1c !important;
}

.hover\:builder-to-red-800:hover{
  --tw-gradient-to: #991b1b !important;
}

.hover\:builder-to-red-900:hover{
  --tw-gradient-to: #7f1d1d !important;
}

.hover\:builder-to-yellow-50:hover{
  --tw-gradient-to: #fffbeb !important;
}

.hover\:builder-to-yellow-100:hover{
  --tw-gradient-to: #fef3c7 !important;
}

.hover\:builder-to-yellow-200:hover{
  --tw-gradient-to: #fde68a !important;
}

.hover\:builder-to-yellow-300:hover{
  --tw-gradient-to: #fcd34d !important;
}

.hover\:builder-to-yellow-400:hover{
  --tw-gradient-to: #fbbf24 !important;
}

.hover\:builder-to-yellow-500:hover{
  --tw-gradient-to: #f59e0b !important;
}

.hover\:builder-to-yellow-600:hover{
  --tw-gradient-to: #d97706 !important;
}

.hover\:builder-to-yellow-700:hover{
  --tw-gradient-to: #b45309 !important;
}

.hover\:builder-to-yellow-800:hover{
  --tw-gradient-to: #92400e !important;
}

.hover\:builder-to-yellow-900:hover{
  --tw-gradient-to: #78350f !important;
}

.hover\:builder-to-green-50:hover{
  --tw-gradient-to: #ecfdf5 !important;
}

.hover\:builder-to-green-100:hover{
  --tw-gradient-to: #d1fae5 !important;
}

.hover\:builder-to-green-200:hover{
  --tw-gradient-to: #a7f3d0 !important;
}

.hover\:builder-to-green-300:hover{
  --tw-gradient-to: #6ee7b7 !important;
}

.hover\:builder-to-green-400:hover{
  --tw-gradient-to: #34d399 !important;
}

.hover\:builder-to-green-500:hover{
  --tw-gradient-to: #10b981 !important;
}

.hover\:builder-to-green-600:hover{
  --tw-gradient-to: #059669 !important;
}

.hover\:builder-to-green-700:hover{
  --tw-gradient-to: #047857 !important;
}

.hover\:builder-to-green-800:hover{
  --tw-gradient-to: #065f46 !important;
}

.hover\:builder-to-green-900:hover{
  --tw-gradient-to: #064e3b !important;
}

.hover\:builder-to-blue-50:hover{
  --tw-gradient-to: #eff6ff !important;
}

.hover\:builder-to-blue-100:hover{
  --tw-gradient-to: #dbeafe !important;
}

.hover\:builder-to-blue-200:hover{
  --tw-gradient-to: #bfdbfe !important;
}

.hover\:builder-to-blue-300:hover{
  --tw-gradient-to: #93c5fd !important;
}

.hover\:builder-to-blue-400:hover{
  --tw-gradient-to: #60a5fa !important;
}

.hover\:builder-to-blue-500:hover{
  --tw-gradient-to: #3b82f6 !important;
}

.hover\:builder-to-blue-600:hover{
  --tw-gradient-to: #2563eb !important;
}

.hover\:builder-to-blue-700:hover{
  --tw-gradient-to: #1d4ed8 !important;
}

.hover\:builder-to-blue-800:hover{
  --tw-gradient-to: #1e40af !important;
}

.hover\:builder-to-blue-900:hover{
  --tw-gradient-to: #1e3a8a !important;
}

.hover\:builder-to-indigo-50:hover{
  --tw-gradient-to: #eef2ff !important;
}

.hover\:builder-to-indigo-100:hover{
  --tw-gradient-to: #e0e7ff !important;
}

.hover\:builder-to-indigo-200:hover{
  --tw-gradient-to: #c7d2fe !important;
}

.hover\:builder-to-indigo-300:hover{
  --tw-gradient-to: #a5b4fc !important;
}

.hover\:builder-to-indigo-400:hover{
  --tw-gradient-to: #818cf8 !important;
}

.hover\:builder-to-indigo-500:hover{
  --tw-gradient-to: #6366f1 !important;
}

.hover\:builder-to-indigo-600:hover{
  --tw-gradient-to: #4f46e5 !important;
}

.hover\:builder-to-indigo-700:hover{
  --tw-gradient-to: #4338ca !important;
}

.hover\:builder-to-indigo-800:hover{
  --tw-gradient-to: #3730a3 !important;
}

.hover\:builder-to-indigo-900:hover{
  --tw-gradient-to: #312e81 !important;
}

.hover\:builder-to-purple-50:hover{
  --tw-gradient-to: #f5f3ff !important;
}

.hover\:builder-to-purple-100:hover{
  --tw-gradient-to: #ede9fe !important;
}

.hover\:builder-to-purple-200:hover{
  --tw-gradient-to: #ddd6fe !important;
}

.hover\:builder-to-purple-300:hover{
  --tw-gradient-to: #c4b5fd !important;
}

.hover\:builder-to-purple-400:hover{
  --tw-gradient-to: #a78bfa !important;
}

.hover\:builder-to-purple-500:hover{
  --tw-gradient-to: #8b5cf6 !important;
}

.hover\:builder-to-purple-600:hover{
  --tw-gradient-to: #7c3aed !important;
}

.hover\:builder-to-purple-700:hover{
  --tw-gradient-to: #6d28d9 !important;
}

.hover\:builder-to-purple-800:hover{
  --tw-gradient-to: #5b21b6 !important;
}

.hover\:builder-to-purple-900:hover{
  --tw-gradient-to: #4c1d95 !important;
}

.hover\:builder-to-pink-50:hover{
  --tw-gradient-to: #fdf2f8 !important;
}

.hover\:builder-to-pink-100:hover{
  --tw-gradient-to: #fce7f3 !important;
}

.hover\:builder-to-pink-200:hover{
  --tw-gradient-to: #fbcfe8 !important;
}

.hover\:builder-to-pink-300:hover{
  --tw-gradient-to: #f9a8d4 !important;
}

.hover\:builder-to-pink-400:hover{
  --tw-gradient-to: #f472b6 !important;
}

.hover\:builder-to-pink-500:hover{
  --tw-gradient-to: #ec4899 !important;
}

.hover\:builder-to-pink-600:hover{
  --tw-gradient-to: #db2777 !important;
}

.hover\:builder-to-pink-700:hover{
  --tw-gradient-to: #be185d !important;
}

.hover\:builder-to-pink-800:hover{
  --tw-gradient-to: #9d174d !important;
}

.hover\:builder-to-pink-900:hover{
  --tw-gradient-to: #831843 !important;
}

.hover\:builder-to-primary:hover{
  --tw-gradient-to: #2d375a !important;
}

.hover\:builder-to-secondary-dark:hover{
  --tw-gradient-to: #343434 !important;
}

.hover\:builder-to-secondary-darker:hover{
  --tw-gradient-to: #212121 !important;
}

.focus\:builder-to-transparent:focus{
  --tw-gradient-to: transparent !important;
}

.focus\:builder-to-current:focus{
  --tw-gradient-to: currentColor !important;
}

.focus\:builder-to-black:focus{
  --tw-gradient-to: #000 !important;
}

.focus\:builder-to-white:focus{
  --tw-gradient-to: #fff !important;
}

.focus\:builder-to-gray-50:focus{
  --tw-gradient-to: #f9fafb !important;
}

.focus\:builder-to-gray-100:focus{
  --tw-gradient-to: #f3f4f6 !important;
}

.focus\:builder-to-gray-200:focus{
  --tw-gradient-to: #e5e7eb !important;
}

.focus\:builder-to-gray-300:focus{
  --tw-gradient-to: #d1d5db !important;
}

.focus\:builder-to-gray-400:focus{
  --tw-gradient-to: #9ca3af !important;
}

.focus\:builder-to-gray-500:focus{
  --tw-gradient-to: #6b7280 !important;
}

.focus\:builder-to-gray-600:focus{
  --tw-gradient-to: #4b5563 !important;
}

.focus\:builder-to-gray-700:focus{
  --tw-gradient-to: #374151 !important;
}

.focus\:builder-to-gray-800:focus{
  --tw-gradient-to: #1f2937 !important;
}

.focus\:builder-to-gray-900:focus{
  --tw-gradient-to: #111827 !important;
}

.focus\:builder-to-red-50:focus{
  --tw-gradient-to: #fef2f2 !important;
}

.focus\:builder-to-red-100:focus{
  --tw-gradient-to: #fee2e2 !important;
}

.focus\:builder-to-red-200:focus{
  --tw-gradient-to: #fecaca !important;
}

.focus\:builder-to-red-300:focus{
  --tw-gradient-to: #fca5a5 !important;
}

.focus\:builder-to-red-400:focus{
  --tw-gradient-to: #f87171 !important;
}

.focus\:builder-to-red-500:focus{
  --tw-gradient-to: #ef4444 !important;
}

.focus\:builder-to-red-600:focus{
  --tw-gradient-to: #dc2626 !important;
}

.focus\:builder-to-red-700:focus{
  --tw-gradient-to: #b91c1c !important;
}

.focus\:builder-to-red-800:focus{
  --tw-gradient-to: #991b1b !important;
}

.focus\:builder-to-red-900:focus{
  --tw-gradient-to: #7f1d1d !important;
}

.focus\:builder-to-yellow-50:focus{
  --tw-gradient-to: #fffbeb !important;
}

.focus\:builder-to-yellow-100:focus{
  --tw-gradient-to: #fef3c7 !important;
}

.focus\:builder-to-yellow-200:focus{
  --tw-gradient-to: #fde68a !important;
}

.focus\:builder-to-yellow-300:focus{
  --tw-gradient-to: #fcd34d !important;
}

.focus\:builder-to-yellow-400:focus{
  --tw-gradient-to: #fbbf24 !important;
}

.focus\:builder-to-yellow-500:focus{
  --tw-gradient-to: #f59e0b !important;
}

.focus\:builder-to-yellow-600:focus{
  --tw-gradient-to: #d97706 !important;
}

.focus\:builder-to-yellow-700:focus{
  --tw-gradient-to: #b45309 !important;
}

.focus\:builder-to-yellow-800:focus{
  --tw-gradient-to: #92400e !important;
}

.focus\:builder-to-yellow-900:focus{
  --tw-gradient-to: #78350f !important;
}

.focus\:builder-to-green-50:focus{
  --tw-gradient-to: #ecfdf5 !important;
}

.focus\:builder-to-green-100:focus{
  --tw-gradient-to: #d1fae5 !important;
}

.focus\:builder-to-green-200:focus{
  --tw-gradient-to: #a7f3d0 !important;
}

.focus\:builder-to-green-300:focus{
  --tw-gradient-to: #6ee7b7 !important;
}

.focus\:builder-to-green-400:focus{
  --tw-gradient-to: #34d399 !important;
}

.focus\:builder-to-green-500:focus{
  --tw-gradient-to: #10b981 !important;
}

.focus\:builder-to-green-600:focus{
  --tw-gradient-to: #059669 !important;
}

.focus\:builder-to-green-700:focus{
  --tw-gradient-to: #047857 !important;
}

.focus\:builder-to-green-800:focus{
  --tw-gradient-to: #065f46 !important;
}

.focus\:builder-to-green-900:focus{
  --tw-gradient-to: #064e3b !important;
}

.focus\:builder-to-blue-50:focus{
  --tw-gradient-to: #eff6ff !important;
}

.focus\:builder-to-blue-100:focus{
  --tw-gradient-to: #dbeafe !important;
}

.focus\:builder-to-blue-200:focus{
  --tw-gradient-to: #bfdbfe !important;
}

.focus\:builder-to-blue-300:focus{
  --tw-gradient-to: #93c5fd !important;
}

.focus\:builder-to-blue-400:focus{
  --tw-gradient-to: #60a5fa !important;
}

.focus\:builder-to-blue-500:focus{
  --tw-gradient-to: #3b82f6 !important;
}

.focus\:builder-to-blue-600:focus{
  --tw-gradient-to: #2563eb !important;
}

.focus\:builder-to-blue-700:focus{
  --tw-gradient-to: #1d4ed8 !important;
}

.focus\:builder-to-blue-800:focus{
  --tw-gradient-to: #1e40af !important;
}

.focus\:builder-to-blue-900:focus{
  --tw-gradient-to: #1e3a8a !important;
}

.focus\:builder-to-indigo-50:focus{
  --tw-gradient-to: #eef2ff !important;
}

.focus\:builder-to-indigo-100:focus{
  --tw-gradient-to: #e0e7ff !important;
}

.focus\:builder-to-indigo-200:focus{
  --tw-gradient-to: #c7d2fe !important;
}

.focus\:builder-to-indigo-300:focus{
  --tw-gradient-to: #a5b4fc !important;
}

.focus\:builder-to-indigo-400:focus{
  --tw-gradient-to: #818cf8 !important;
}

.focus\:builder-to-indigo-500:focus{
  --tw-gradient-to: #6366f1 !important;
}

.focus\:builder-to-indigo-600:focus{
  --tw-gradient-to: #4f46e5 !important;
}

.focus\:builder-to-indigo-700:focus{
  --tw-gradient-to: #4338ca !important;
}

.focus\:builder-to-indigo-800:focus{
  --tw-gradient-to: #3730a3 !important;
}

.focus\:builder-to-indigo-900:focus{
  --tw-gradient-to: #312e81 !important;
}

.focus\:builder-to-purple-50:focus{
  --tw-gradient-to: #f5f3ff !important;
}

.focus\:builder-to-purple-100:focus{
  --tw-gradient-to: #ede9fe !important;
}

.focus\:builder-to-purple-200:focus{
  --tw-gradient-to: #ddd6fe !important;
}

.focus\:builder-to-purple-300:focus{
  --tw-gradient-to: #c4b5fd !important;
}

.focus\:builder-to-purple-400:focus{
  --tw-gradient-to: #a78bfa !important;
}

.focus\:builder-to-purple-500:focus{
  --tw-gradient-to: #8b5cf6 !important;
}

.focus\:builder-to-purple-600:focus{
  --tw-gradient-to: #7c3aed !important;
}

.focus\:builder-to-purple-700:focus{
  --tw-gradient-to: #6d28d9 !important;
}

.focus\:builder-to-purple-800:focus{
  --tw-gradient-to: #5b21b6 !important;
}

.focus\:builder-to-purple-900:focus{
  --tw-gradient-to: #4c1d95 !important;
}

.focus\:builder-to-pink-50:focus{
  --tw-gradient-to: #fdf2f8 !important;
}

.focus\:builder-to-pink-100:focus{
  --tw-gradient-to: #fce7f3 !important;
}

.focus\:builder-to-pink-200:focus{
  --tw-gradient-to: #fbcfe8 !important;
}

.focus\:builder-to-pink-300:focus{
  --tw-gradient-to: #f9a8d4 !important;
}

.focus\:builder-to-pink-400:focus{
  --tw-gradient-to: #f472b6 !important;
}

.focus\:builder-to-pink-500:focus{
  --tw-gradient-to: #ec4899 !important;
}

.focus\:builder-to-pink-600:focus{
  --tw-gradient-to: #db2777 !important;
}

.focus\:builder-to-pink-700:focus{
  --tw-gradient-to: #be185d !important;
}

.focus\:builder-to-pink-800:focus{
  --tw-gradient-to: #9d174d !important;
}

.focus\:builder-to-pink-900:focus{
  --tw-gradient-to: #831843 !important;
}

.focus\:builder-to-primary:focus{
  --tw-gradient-to: #2d375a !important;
}

.focus\:builder-to-secondary-dark:focus{
  --tw-gradient-to: #343434 !important;
}

.focus\:builder-to-secondary-darker:focus{
  --tw-gradient-to: #212121 !important;
}

.builder-decoration-slice{
  -webkit-box-decoration-break: slice !important;
          box-decoration-break: slice !important;
}

.builder-decoration-clone{
  -webkit-box-decoration-break: clone !important;
          box-decoration-break: clone !important;
}

.builder-bg-auto{
  background-size: auto !important;
}

.builder-bg-cover{
  background-size: cover !important;
}

.builder-bg-contain{
  background-size: contain !important;
}

.builder-bg-fixed{
  background-attachment: fixed !important;
}

.builder-bg-local{
  background-attachment: local !important;
}

.builder-bg-scroll{
  background-attachment: scroll !important;
}

.builder-bg-clip-border{
  background-clip: border-box !important;
}

.builder-bg-clip-padding{
  background-clip: padding-box !important;
}

.builder-bg-clip-content{
  background-clip: content-box !important;
}

.builder-bg-clip-text{
  -webkit-background-clip: text !important;
          background-clip: text !important;
}

.builder-bg-bottom{
  background-position: bottom !important;
}

.builder-bg-center{
  background-position: center !important;
}

.builder-bg-left{
  background-position: left !important;
}

.builder-bg-left-bottom{
  background-position: left bottom !important;
}

.builder-bg-left-top{
  background-position: left top !important;
}

.builder-bg-right{
  background-position: right !important;
}

.builder-bg-right-bottom{
  background-position: right bottom !important;
}

.builder-bg-right-top{
  background-position: right top !important;
}

.builder-bg-top{
  background-position: top !important;
}

.builder-bg-repeat{
  background-repeat: repeat !important;
}

.builder-bg-no-repeat{
  background-repeat: no-repeat !important;
}

.builder-bg-repeat-x{
  background-repeat: repeat-x !important;
}

.builder-bg-repeat-y{
  background-repeat: repeat-y !important;
}

.builder-bg-repeat-round{
  background-repeat: round !important;
}

.builder-bg-repeat-space{
  background-repeat: space !important;
}

.builder-bg-origin-border{
  background-origin: border-box !important;
}

.builder-bg-origin-padding{
  background-origin: padding-box !important;
}

.builder-bg-origin-content{
  background-origin: content-box !important;
}

.builder-fill-current{
  fill: currentColor !important;
}

.builder-stroke-current{
  stroke: currentColor !important;
}

.builder-stroke-0{
  stroke-width: 0 !important;
}

.builder-stroke-1{
  stroke-width: 1 !important;
}

.builder-stroke-2{
  stroke-width: 2 !important;
}

.builder-object-contain{
  object-fit: contain !important;
}

.builder-object-cover{
  object-fit: cover !important;
}

.builder-object-fill{
  object-fit: fill !important;
}

.builder-object-none{
  object-fit: none !important;
}

.builder-object-scale-down{
  object-fit: scale-down !important;
}

.builder-object-bottom{
  object-position: bottom !important;
}

.builder-object-center{
  object-position: center !important;
}

.builder-object-left{
  object-position: left !important;
}

.builder-object-left-bottom{
  object-position: left bottom !important;
}

.builder-object-left-top{
  object-position: left top !important;
}

.builder-object-right{
  object-position: right !important;
}

.builder-object-right-bottom{
  object-position: right bottom !important;
}

.builder-object-right-top{
  object-position: right top !important;
}

.builder-object-top{
  object-position: top !important;
}

.builder-p-0{
  padding: 0px !important;
}

.builder-p-1{
  padding: 0.25rem !important;
}

.builder-p-2{
  padding: 0.5rem !important;
}

.builder-p-3{
  padding: 0.75rem !important;
}

.builder-p-4{
  padding: 1rem !important;
}

.builder-p-5{
  padding: 1.25rem !important;
}

.builder-p-6{
  padding: 1.5rem !important;
}

.builder-p-7{
  padding: 1.75rem !important;
}

.builder-p-8{
  padding: 2rem !important;
}

.builder-p-9{
  padding: 2.25rem !important;
}

.builder-p-10{
  padding: 2.5rem !important;
}

.builder-p-11{
  padding: 2.75rem !important;
}

.builder-p-12{
  padding: 3rem !important;
}

.builder-p-14{
  padding: 3.5rem !important;
}

.builder-p-16{
  padding: 4rem !important;
}

.builder-p-20{
  padding: 5rem !important;
}

.builder-p-24{
  padding: 6rem !important;
}

.builder-p-28{
  padding: 7rem !important;
}

.builder-p-32{
  padding: 8rem !important;
}

.builder-p-36{
  padding: 9rem !important;
}

.builder-p-40{
  padding: 10rem !important;
}

.builder-p-44{
  padding: 11rem !important;
}

.builder-p-48{
  padding: 12rem !important;
}

.builder-p-52{
  padding: 13rem !important;
}

.builder-p-56{
  padding: 14rem !important;
}

.builder-p-60{
  padding: 15rem !important;
}

.builder-p-64{
  padding: 16rem !important;
}

.builder-p-72{
  padding: 18rem !important;
}

.builder-p-80{
  padding: 20rem !important;
}

.builder-p-96{
  padding: 24rem !important;
}

.builder-p-px{
  padding: 1px !important;
}

.builder-p-0\.5{
  padding: 0.125rem !important;
}

.builder-p-1\.5{
  padding: 0.375rem !important;
}

.builder-p-2\.5{
  padding: 0.625rem !important;
}

.builder-p-3\.5{
  padding: 0.875rem !important;
}

.builder-px-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.builder-px-1{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.builder-px-2{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.builder-px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.builder-px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.builder-px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.builder-px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.builder-px-7{
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.builder-px-8{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.builder-px-9{
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.builder-px-10{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.builder-px-11{
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.builder-px-12{
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.builder-px-14{
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.builder-px-16{
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.builder-px-20{
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.builder-px-24{
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.builder-px-28{
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.builder-px-32{
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.builder-px-36{
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.builder-px-40{
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.builder-px-44{
  padding-left: 11rem !important;
  padding-right: 11rem !important;
}

.builder-px-48{
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.builder-px-52{
  padding-left: 13rem !important;
  padding-right: 13rem !important;
}

.builder-px-56{
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.builder-px-60{
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.builder-px-64{
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.builder-px-72{
  padding-left: 18rem !important;
  padding-right: 18rem !important;
}

.builder-px-80{
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.builder-px-96{
  padding-left: 24rem !important;
  padding-right: 24rem !important;
}

.builder-px-px{
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.builder-px-0\.5{
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}

.builder-px-1\.5{
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.builder-px-2\.5{
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.builder-px-3\.5{
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

.builder-py-0{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.builder-py-1{
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.builder-py-2{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.builder-py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.builder-py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.builder-py-5{
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.builder-py-6{
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.builder-py-7{
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.builder-py-8{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.builder-py-9{
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.builder-py-10{
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.builder-py-11{
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.builder-py-12{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.builder-py-14{
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.builder-py-16{
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.builder-py-20{
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.builder-py-24{
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.builder-py-28{
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.builder-py-32{
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.builder-py-36{
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.builder-py-40{
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.builder-py-44{
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.builder-py-48{
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.builder-py-52{
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.builder-py-56{
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.builder-py-60{
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.builder-py-64{
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.builder-py-72{
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.builder-py-80{
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.builder-py-96{
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.builder-py-px{
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.builder-py-0\.5{
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.builder-py-1\.5{
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.builder-py-2\.5{
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.builder-py-3\.5{
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.builder-pt-0{
  padding-top: 0px !important;
}

.builder-pt-1{
  padding-top: 0.25rem !important;
}

.builder-pt-2{
  padding-top: 0.5rem !important;
}

.builder-pt-3{
  padding-top: 0.75rem !important;
}

.builder-pt-4{
  padding-top: 1rem !important;
}

.builder-pt-5{
  padding-top: 1.25rem !important;
}

.builder-pt-6{
  padding-top: 1.5rem !important;
}

.builder-pt-7{
  padding-top: 1.75rem !important;
}

.builder-pt-8{
  padding-top: 2rem !important;
}

.builder-pt-9{
  padding-top: 2.25rem !important;
}

.builder-pt-10{
  padding-top: 2.5rem !important;
}

.builder-pt-11{
  padding-top: 2.75rem !important;
}

.builder-pt-12{
  padding-top: 3rem !important;
}

.builder-pt-14{
  padding-top: 3.5rem !important;
}

.builder-pt-16{
  padding-top: 4rem !important;
}

.builder-pt-20{
  padding-top: 5rem !important;
}

.builder-pt-24{
  padding-top: 6rem !important;
}

.builder-pt-28{
  padding-top: 7rem !important;
}

.builder-pt-32{
  padding-top: 8rem !important;
}

.builder-pt-36{
  padding-top: 9rem !important;
}

.builder-pt-40{
  padding-top: 10rem !important;
}

.builder-pt-44{
  padding-top: 11rem !important;
}

.builder-pt-48{
  padding-top: 12rem !important;
}

.builder-pt-52{
  padding-top: 13rem !important;
}

.builder-pt-56{
  padding-top: 14rem !important;
}

.builder-pt-60{
  padding-top: 15rem !important;
}

.builder-pt-64{
  padding-top: 16rem !important;
}

.builder-pt-72{
  padding-top: 18rem !important;
}

.builder-pt-80{
  padding-top: 20rem !important;
}

.builder-pt-96{
  padding-top: 24rem !important;
}

.builder-pt-px{
  padding-top: 1px !important;
}

.builder-pt-0\.5{
  padding-top: 0.125rem !important;
}

.builder-pt-1\.5{
  padding-top: 0.375rem !important;
}

.builder-pt-2\.5{
  padding-top: 0.625rem !important;
}

.builder-pt-3\.5{
  padding-top: 0.875rem !important;
}

.builder-pr-0{
  padding-right: 0px !important;
}

.builder-pr-1{
  padding-right: 0.25rem !important;
}

.builder-pr-2{
  padding-right: 0.5rem !important;
}

.builder-pr-3{
  padding-right: 0.75rem !important;
}

.builder-pr-4{
  padding-right: 1rem !important;
}

.builder-pr-5{
  padding-right: 1.25rem !important;
}

.builder-pr-6{
  padding-right: 1.5rem !important;
}

.builder-pr-7{
  padding-right: 1.75rem !important;
}

.builder-pr-8{
  padding-right: 2rem !important;
}

.builder-pr-9{
  padding-right: 2.25rem !important;
}

.builder-pr-10{
  padding-right: 2.5rem !important;
}

.builder-pr-11{
  padding-right: 2.75rem !important;
}

.builder-pr-12{
  padding-right: 3rem !important;
}

.builder-pr-14{
  padding-right: 3.5rem !important;
}

.builder-pr-16{
  padding-right: 4rem !important;
}

.builder-pr-20{
  padding-right: 5rem !important;
}

.builder-pr-24{
  padding-right: 6rem !important;
}

.builder-pr-28{
  padding-right: 7rem !important;
}

.builder-pr-32{
  padding-right: 8rem !important;
}

.builder-pr-36{
  padding-right: 9rem !important;
}

.builder-pr-40{
  padding-right: 10rem !important;
}

.builder-pr-44{
  padding-right: 11rem !important;
}

.builder-pr-48{
  padding-right: 12rem !important;
}

.builder-pr-52{
  padding-right: 13rem !important;
}

.builder-pr-56{
  padding-right: 14rem !important;
}

.builder-pr-60{
  padding-right: 15rem !important;
}

.builder-pr-64{
  padding-right: 16rem !important;
}

.builder-pr-72{
  padding-right: 18rem !important;
}

.builder-pr-80{
  padding-right: 20rem !important;
}

.builder-pr-96{
  padding-right: 24rem !important;
}

.builder-pr-px{
  padding-right: 1px !important;
}

.builder-pr-0\.5{
  padding-right: 0.125rem !important;
}

.builder-pr-1\.5{
  padding-right: 0.375rem !important;
}

.builder-pr-2\.5{
  padding-right: 0.625rem !important;
}

.builder-pr-3\.5{
  padding-right: 0.875rem !important;
}

.builder-pb-0{
  padding-bottom: 0px !important;
}

.builder-pb-1{
  padding-bottom: 0.25rem !important;
}

.builder-pb-2{
  padding-bottom: 0.5rem !important;
}

.builder-pb-3{
  padding-bottom: 0.75rem !important;
}

.builder-pb-4{
  padding-bottom: 1rem !important;
}

.builder-pb-5{
  padding-bottom: 1.25rem !important;
}

.builder-pb-6{
  padding-bottom: 1.5rem !important;
}

.builder-pb-7{
  padding-bottom: 1.75rem !important;
}

.builder-pb-8{
  padding-bottom: 2rem !important;
}

.builder-pb-9{
  padding-bottom: 2.25rem !important;
}

.builder-pb-10{
  padding-bottom: 2.5rem !important;
}

.builder-pb-11{
  padding-bottom: 2.75rem !important;
}

.builder-pb-12{
  padding-bottom: 3rem !important;
}

.builder-pb-14{
  padding-bottom: 3.5rem !important;
}

.builder-pb-16{
  padding-bottom: 4rem !important;
}

.builder-pb-20{
  padding-bottom: 5rem !important;
}

.builder-pb-24{
  padding-bottom: 6rem !important;
}

.builder-pb-28{
  padding-bottom: 7rem !important;
}

.builder-pb-32{
  padding-bottom: 8rem !important;
}

.builder-pb-36{
  padding-bottom: 9rem !important;
}

.builder-pb-40{
  padding-bottom: 10rem !important;
}

.builder-pb-44{
  padding-bottom: 11rem !important;
}

.builder-pb-48{
  padding-bottom: 12rem !important;
}

.builder-pb-52{
  padding-bottom: 13rem !important;
}

.builder-pb-56{
  padding-bottom: 14rem !important;
}

.builder-pb-60{
  padding-bottom: 15rem !important;
}

.builder-pb-64{
  padding-bottom: 16rem !important;
}

.builder-pb-72{
  padding-bottom: 18rem !important;
}

.builder-pb-80{
  padding-bottom: 20rem !important;
}

.builder-pb-96{
  padding-bottom: 24rem !important;
}

.builder-pb-px{
  padding-bottom: 1px !important;
}

.builder-pb-0\.5{
  padding-bottom: 0.125rem !important;
}

.builder-pb-1\.5{
  padding-bottom: 0.375rem !important;
}

.builder-pb-2\.5{
  padding-bottom: 0.625rem !important;
}

.builder-pb-3\.5{
  padding-bottom: 0.875rem !important;
}

.builder-pl-0{
  padding-left: 0px !important;
}

.builder-pl-1{
  padding-left: 0.25rem !important;
}

.builder-pl-2{
  padding-left: 0.5rem !important;
}

.builder-pl-3{
  padding-left: 0.75rem !important;
}

.builder-pl-4{
  padding-left: 1rem !important;
}

.builder-pl-5{
  padding-left: 1.25rem !important;
}

.builder-pl-6{
  padding-left: 1.5rem !important;
}

.builder-pl-7{
  padding-left: 1.75rem !important;
}

.builder-pl-8{
  padding-left: 2rem !important;
}

.builder-pl-9{
  padding-left: 2.25rem !important;
}

.builder-pl-10{
  padding-left: 2.5rem !important;
}

.builder-pl-11{
  padding-left: 2.75rem !important;
}

.builder-pl-12{
  padding-left: 3rem !important;
}

.builder-pl-14{
  padding-left: 3.5rem !important;
}

.builder-pl-16{
  padding-left: 4rem !important;
}

.builder-pl-20{
  padding-left: 5rem !important;
}

.builder-pl-24{
  padding-left: 6rem !important;
}

.builder-pl-28{
  padding-left: 7rem !important;
}

.builder-pl-32{
  padding-left: 8rem !important;
}

.builder-pl-36{
  padding-left: 9rem !important;
}

.builder-pl-40{
  padding-left: 10rem !important;
}

.builder-pl-44{
  padding-left: 11rem !important;
}

.builder-pl-48{
  padding-left: 12rem !important;
}

.builder-pl-52{
  padding-left: 13rem !important;
}

.builder-pl-56{
  padding-left: 14rem !important;
}

.builder-pl-60{
  padding-left: 15rem !important;
}

.builder-pl-64{
  padding-left: 16rem !important;
}

.builder-pl-72{
  padding-left: 18rem !important;
}

.builder-pl-80{
  padding-left: 20rem !important;
}

.builder-pl-96{
  padding-left: 24rem !important;
}

.builder-pl-px{
  padding-left: 1px !important;
}

.builder-pl-0\.5{
  padding-left: 0.125rem !important;
}

.builder-pl-1\.5{
  padding-left: 0.375rem !important;
}

.builder-pl-2\.5{
  padding-left: 0.625rem !important;
}

.builder-pl-3\.5{
  padding-left: 0.875rem !important;
}

.builder-text-left{
  text-align: left !important;
}

.builder-text-center{
  text-align: center !important;
}

.builder-text-right{
  text-align: right !important;
}

.builder-text-justify{
  text-align: justify !important;
}

.builder-align-baseline{
  vertical-align: baseline !important;
}

.builder-align-top{
  vertical-align: top !important;
}

.builder-align-middle{
  vertical-align: middle !important;
}

.builder-align-bottom{
  vertical-align: bottom !important;
}

.builder-align-text-top{
  vertical-align: text-top !important;
}

.builder-align-text-bottom{
  vertical-align: text-bottom !important;
}

.builder-font-primary{
  font-family: proxima-nova !important;
}

.builder-text-sm{
  font-size: 14px !important;
}

.builder-text-md{
  font-size: 15px !important;
}

.builder-text-2xl{
  font-size: 24px !important;
}

.builder-font-thin{
  font-weight: 100 !important;
}

.builder-font-extralight{
  font-weight: 200 !important;
}

.builder-font-light{
  font-weight: 300 !important;
}

.builder-font-normal{
  font-weight: 400 !important;
}

.builder-font-medium{
  font-weight: 500 !important;
}

.builder-font-semibold{
  font-weight: 600 !important;
}

.builder-font-bold{
  font-weight: 700 !important;
}

.builder-font-extrabold{
  font-weight: 800 !important;
}

.builder-font-black{
  font-weight: 900 !important;
}

.builder-uppercase{
  text-transform: uppercase !important;
}

.builder-lowercase{
  text-transform: lowercase !important;
}

.builder-capitalize{
  text-transform: capitalize !important;
}

.builder-normal-case{
  text-transform: none !important;
}

.builder-italic{
  font-style: italic !important;
}

.builder-not-italic{
  font-style: normal !important;
}

.builder-ordinal, .builder-slashed-zero, .builder-lining-nums, .builder-oldstyle-nums, .builder-proportional-nums, .builder-tabular-nums, .builder-diagonal-fractions, .builder-stacked-fractions{
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.builder-normal-nums{
  font-variant-numeric: normal !important;
}

.builder-ordinal{
  --tw-ordinal: ordinal !important;
}

.builder-slashed-zero{
  --tw-slashed-zero: slashed-zero !important;
}

.builder-lining-nums{
  --tw-numeric-figure: lining-nums !important;
}

.builder-oldstyle-nums{
  --tw-numeric-figure: oldstyle-nums !important;
}

.builder-proportional-nums{
  --tw-numeric-spacing: proportional-nums !important;
}

.builder-tabular-nums{
  --tw-numeric-spacing: tabular-nums !important;
}

.builder-diagonal-fractions{
  --tw-numeric-fraction: diagonal-fractions !important;
}

.builder-stacked-fractions{
  --tw-numeric-fraction: stacked-fractions !important;
}

.builder-leading-3{
  line-height: .75rem !important;
}

.builder-leading-4{
  line-height: 1rem !important;
}

.builder-leading-5{
  line-height: 1.25rem !important;
}

.builder-leading-6{
  line-height: 1.5rem !important;
}

.builder-leading-7{
  line-height: 1.75rem !important;
}

.builder-leading-8{
  line-height: 2rem !important;
}

.builder-leading-9{
  line-height: 2.25rem !important;
}

.builder-leading-10{
  line-height: 2.5rem !important;
}

.builder-leading-none{
  line-height: 1 !important;
}

.builder-leading-tight{
  line-height: 1.25 !important;
}

.builder-leading-snug{
  line-height: 1.375 !important;
}

.builder-leading-normal{
  line-height: 1.5 !important;
}

.builder-leading-relaxed{
  line-height: 1.625 !important;
}

.builder-leading-loose{
  line-height: 2 !important;
}

.builder-tracking-tighter{
  letter-spacing: -0.05em !important;
}

.builder-tracking-tight{
  letter-spacing: -0.025em !important;
}

.builder-tracking-normal{
  letter-spacing: 0em !important;
}

.builder-tracking-wide{
  letter-spacing: 0.025em !important;
}

.builder-tracking-wider{
  letter-spacing: 0.05em !important;
}

.builder-tracking-widest{
  letter-spacing: 0.1em !important;
}

.builder-text-transparent{
  color: transparent !important;
}

.builder-text-current{
  color: currentColor !important;
}

.builder-text-black{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.builder-text-white{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.builder-text-gray-50{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.builder-text-gray-100{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.builder-text-gray-200{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.builder-text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.builder-text-gray-400{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.builder-text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.builder-text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.builder-text-gray-700{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.builder-text-gray-800{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.builder-text-gray-900{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.builder-text-red-50{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.builder-text-red-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.builder-text-red-200{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.builder-text-red-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.builder-text-red-400{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.builder-text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.builder-text-red-600{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.builder-text-red-700{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.builder-text-red-800{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.builder-text-red-900{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-50{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-200{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-400{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-500{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-600{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-700{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-800{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.builder-text-yellow-900{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.builder-text-green-50{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.builder-text-green-100{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.builder-text-green-200{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.builder-text-green-300{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.builder-text-green-400{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.builder-text-green-500{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.builder-text-green-600{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.builder-text-green-700{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.builder-text-green-800{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.builder-text-green-900{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.builder-text-blue-50{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.builder-text-blue-100{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.builder-text-blue-200{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.builder-text-blue-300{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.builder-text-blue-400{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.builder-text-blue-500{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.builder-text-blue-600{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.builder-text-blue-700{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.builder-text-blue-800{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.builder-text-blue-900{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-50{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-100{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-200{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-300{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-400{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-500{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-600{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-700{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-800{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.builder-text-indigo-900{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.builder-text-purple-50{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.builder-text-purple-100{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.builder-text-purple-200{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.builder-text-purple-300{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.builder-text-purple-400{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.builder-text-purple-500{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.builder-text-purple-600{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.builder-text-purple-700{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.builder-text-purple-800{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.builder-text-purple-900{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.builder-text-pink-50{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.builder-text-pink-100{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.builder-text-pink-200{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.builder-text-pink-300{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.builder-text-pink-400{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.builder-text-pink-500{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.builder-text-pink-600{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.builder-text-pink-700{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.builder-text-pink-800{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.builder-text-pink-900{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.builder-text-primary{
  --tw-text-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
}

.builder-text-secondary-dark{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
}

.builder-text-secondary-darker{
  --tw-text-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-transparent{
  color: transparent !important;
}

.builder-group:hover .group-hover\:builder-text-current{
  color: currentColor !important;
}

.builder-group:hover .group-hover\:builder-text-black{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-white{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-50{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-100{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-200{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-400{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-700{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-800{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-gray-900{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-50{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-200{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-400{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-600{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-700{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-800{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-red-900{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-50{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-200{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-400{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-500{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-600{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-700{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-800{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-yellow-900{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-50{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-100{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-200{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-300{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-400{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-500{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-600{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-700{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-800{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-green-900{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-50{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-100{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-200{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-300{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-400{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-500{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-600{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-700{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-800{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-blue-900{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-50{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-100{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-200{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-300{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-400{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-500{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-600{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-700{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-800{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-indigo-900{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-50{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-100{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-200{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-300{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-400{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-500{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-600{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-700{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-800{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-purple-900{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-50{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-100{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-200{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-300{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-400{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-500{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-600{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-700{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-800{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-pink-900{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-primary{
  --tw-text-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-secondary-dark{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
}

.builder-group:hover .group-hover\:builder-text-secondary-darker{
  --tw-text-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-transparent:focus-within{
  color: transparent !important;
}

.focus-within\:builder-text-current:focus-within{
  color: currentColor !important;
}

.focus-within\:builder-text-black:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-white:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-gray-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-red-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-yellow-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-green-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-blue-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-indigo-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-purple-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-pink-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-primary:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-secondary-dark:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
}

.focus-within\:builder-text-secondary-darker:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-transparent:hover{
  color: transparent !important;
}

.hover\:builder-text-current:hover{
  color: currentColor !important;
}

.hover\:builder-text-black:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-white:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-gray-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-red-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-yellow-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-green-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-blue-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-indigo-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-purple-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-pink-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-primary:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-secondary-dark:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
}

.hover\:builder-text-secondary-darker:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-transparent:focus{
  color: transparent !important;
}

.focus\:builder-text-current:focus{
  color: currentColor !important;
}

.focus\:builder-text-black:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-white:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-gray-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-red-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-yellow-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-green-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-blue-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-indigo-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-purple-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-pink-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-primary:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-secondary-dark:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
}

.focus\:builder-text-secondary-darker:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
}

.builder-text-opacity-0{
  --tw-text-opacity: 0 !important;
}

.builder-text-opacity-5{
  --tw-text-opacity: 0.05 !important;
}

.builder-text-opacity-10{
  --tw-text-opacity: 0.1 !important;
}

.builder-text-opacity-20{
  --tw-text-opacity: 0.2 !important;
}

.builder-text-opacity-25{
  --tw-text-opacity: 0.25 !important;
}

.builder-text-opacity-30{
  --tw-text-opacity: 0.3 !important;
}

.builder-text-opacity-40{
  --tw-text-opacity: 0.4 !important;
}

.builder-text-opacity-50{
  --tw-text-opacity: 0.5 !important;
}

.builder-text-opacity-60{
  --tw-text-opacity: 0.6 !important;
}

.builder-text-opacity-70{
  --tw-text-opacity: 0.7 !important;
}

.builder-text-opacity-75{
  --tw-text-opacity: 0.75 !important;
}

.builder-text-opacity-80{
  --tw-text-opacity: 0.8 !important;
}

.builder-text-opacity-90{
  --tw-text-opacity: 0.9 !important;
}

.builder-text-opacity-95{
  --tw-text-opacity: 0.95 !important;
}

.builder-text-opacity-100{
  --tw-text-opacity: 1 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-0{
  --tw-text-opacity: 0 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-5{
  --tw-text-opacity: 0.05 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-10{
  --tw-text-opacity: 0.1 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-20{
  --tw-text-opacity: 0.2 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-25{
  --tw-text-opacity: 0.25 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-30{
  --tw-text-opacity: 0.3 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-40{
  --tw-text-opacity: 0.4 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-50{
  --tw-text-opacity: 0.5 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-60{
  --tw-text-opacity: 0.6 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-70{
  --tw-text-opacity: 0.7 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-75{
  --tw-text-opacity: 0.75 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-80{
  --tw-text-opacity: 0.8 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-90{
  --tw-text-opacity: 0.9 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-95{
  --tw-text-opacity: 0.95 !important;
}

.builder-group:hover .group-hover\:builder-text-opacity-100{
  --tw-text-opacity: 1 !important;
}

.focus-within\:builder-text-opacity-0:focus-within{
  --tw-text-opacity: 0 !important;
}

.focus-within\:builder-text-opacity-5:focus-within{
  --tw-text-opacity: 0.05 !important;
}

.focus-within\:builder-text-opacity-10:focus-within{
  --tw-text-opacity: 0.1 !important;
}

.focus-within\:builder-text-opacity-20:focus-within{
  --tw-text-opacity: 0.2 !important;
}

.focus-within\:builder-text-opacity-25:focus-within{
  --tw-text-opacity: 0.25 !important;
}

.focus-within\:builder-text-opacity-30:focus-within{
  --tw-text-opacity: 0.3 !important;
}

.focus-within\:builder-text-opacity-40:focus-within{
  --tw-text-opacity: 0.4 !important;
}

.focus-within\:builder-text-opacity-50:focus-within{
  --tw-text-opacity: 0.5 !important;
}

.focus-within\:builder-text-opacity-60:focus-within{
  --tw-text-opacity: 0.6 !important;
}

.focus-within\:builder-text-opacity-70:focus-within{
  --tw-text-opacity: 0.7 !important;
}

.focus-within\:builder-text-opacity-75:focus-within{
  --tw-text-opacity: 0.75 !important;
}

.focus-within\:builder-text-opacity-80:focus-within{
  --tw-text-opacity: 0.8 !important;
}

.focus-within\:builder-text-opacity-90:focus-within{
  --tw-text-opacity: 0.9 !important;
}

.focus-within\:builder-text-opacity-95:focus-within{
  --tw-text-opacity: 0.95 !important;
}

.focus-within\:builder-text-opacity-100:focus-within{
  --tw-text-opacity: 1 !important;
}

.hover\:builder-text-opacity-0:hover{
  --tw-text-opacity: 0 !important;
}

.hover\:builder-text-opacity-5:hover{
  --tw-text-opacity: 0.05 !important;
}

.hover\:builder-text-opacity-10:hover{
  --tw-text-opacity: 0.1 !important;
}

.hover\:builder-text-opacity-20:hover{
  --tw-text-opacity: 0.2 !important;
}

.hover\:builder-text-opacity-25:hover{
  --tw-text-opacity: 0.25 !important;
}

.hover\:builder-text-opacity-30:hover{
  --tw-text-opacity: 0.3 !important;
}

.hover\:builder-text-opacity-40:hover{
  --tw-text-opacity: 0.4 !important;
}

.hover\:builder-text-opacity-50:hover{
  --tw-text-opacity: 0.5 !important;
}

.hover\:builder-text-opacity-60:hover{
  --tw-text-opacity: 0.6 !important;
}

.hover\:builder-text-opacity-70:hover{
  --tw-text-opacity: 0.7 !important;
}

.hover\:builder-text-opacity-75:hover{
  --tw-text-opacity: 0.75 !important;
}

.hover\:builder-text-opacity-80:hover{
  --tw-text-opacity: 0.8 !important;
}

.hover\:builder-text-opacity-90:hover{
  --tw-text-opacity: 0.9 !important;
}

.hover\:builder-text-opacity-95:hover{
  --tw-text-opacity: 0.95 !important;
}

.hover\:builder-text-opacity-100:hover{
  --tw-text-opacity: 1 !important;
}

.focus\:builder-text-opacity-0:focus{
  --tw-text-opacity: 0 !important;
}

.focus\:builder-text-opacity-5:focus{
  --tw-text-opacity: 0.05 !important;
}

.focus\:builder-text-opacity-10:focus{
  --tw-text-opacity: 0.1 !important;
}

.focus\:builder-text-opacity-20:focus{
  --tw-text-opacity: 0.2 !important;
}

.focus\:builder-text-opacity-25:focus{
  --tw-text-opacity: 0.25 !important;
}

.focus\:builder-text-opacity-30:focus{
  --tw-text-opacity: 0.3 !important;
}

.focus\:builder-text-opacity-40:focus{
  --tw-text-opacity: 0.4 !important;
}

.focus\:builder-text-opacity-50:focus{
  --tw-text-opacity: 0.5 !important;
}

.focus\:builder-text-opacity-60:focus{
  --tw-text-opacity: 0.6 !important;
}

.focus\:builder-text-opacity-70:focus{
  --tw-text-opacity: 0.7 !important;
}

.focus\:builder-text-opacity-75:focus{
  --tw-text-opacity: 0.75 !important;
}

.focus\:builder-text-opacity-80:focus{
  --tw-text-opacity: 0.8 !important;
}

.focus\:builder-text-opacity-90:focus{
  --tw-text-opacity: 0.9 !important;
}

.focus\:builder-text-opacity-95:focus{
  --tw-text-opacity: 0.95 !important;
}

.focus\:builder-text-opacity-100:focus{
  --tw-text-opacity: 1 !important;
}

.builder-underline{
  text-decoration: underline !important;
}

.builder-line-through{
  text-decoration: line-through !important;
}

.builder-no-underline{
  text-decoration: none !important;
}

.builder-group:hover .group-hover\:builder-underline{
  text-decoration: underline !important;
}

.builder-group:hover .group-hover\:builder-line-through{
  text-decoration: line-through !important;
}

.builder-group:hover .group-hover\:builder-no-underline{
  text-decoration: none !important;
}

.focus-within\:builder-underline:focus-within{
  text-decoration: underline !important;
}

.focus-within\:builder-line-through:focus-within{
  text-decoration: line-through !important;
}

.focus-within\:builder-no-underline:focus-within{
  text-decoration: none !important;
}

.hover\:builder-underline:hover{
  text-decoration: underline !important;
}

.hover\:builder-line-through:hover{
  text-decoration: line-through !important;
}

.hover\:builder-no-underline:hover{
  text-decoration: none !important;
}

.focus\:builder-underline:focus{
  text-decoration: underline !important;
}

.focus\:builder-line-through:focus{
  text-decoration: line-through !important;
}

.focus\:builder-no-underline:focus{
  text-decoration: none !important;
}

.builder-antialiased{
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.builder-subpixel-antialiased{
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.builder-placeholder-transparent::placeholder{
  color: transparent !important;
}

.builder-placeholder-current::placeholder{
  color: currentColor !important;
}

.builder-placeholder-black::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-white::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-gray-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-red-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-yellow-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-green-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-blue-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-indigo-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-purple-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-pink-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-primary::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-secondary-dark::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-secondary-darker::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-transparent:focus::placeholder{
  color: transparent !important;
}

.focus\:builder-placeholder-current:focus::placeholder{
  color: currentColor !important;
}

.focus\:builder-placeholder-black:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-white:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-gray-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-red-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-yellow-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-green-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-blue-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-indigo-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-purple-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-pink-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-primary:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-secondary-dark:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
}

.focus\:builder-placeholder-secondary-darker:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
}

.builder-placeholder-opacity-0::placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.builder-placeholder-opacity-5::placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.builder-placeholder-opacity-10::placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.builder-placeholder-opacity-20::placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.builder-placeholder-opacity-25::placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.builder-placeholder-opacity-30::placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.builder-placeholder-opacity-40::placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.builder-placeholder-opacity-50::placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.builder-placeholder-opacity-60::placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.builder-placeholder-opacity-70::placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.builder-placeholder-opacity-75::placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.builder-placeholder-opacity-80::placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.builder-placeholder-opacity-90::placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.builder-placeholder-opacity-95::placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.builder-placeholder-opacity-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.focus\:builder-placeholder-opacity-0:focus::placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.focus\:builder-placeholder-opacity-5:focus::placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.focus\:builder-placeholder-opacity-10:focus::placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.focus\:builder-placeholder-opacity-20:focus::placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.focus\:builder-placeholder-opacity-25:focus::placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.focus\:builder-placeholder-opacity-30:focus::placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.focus\:builder-placeholder-opacity-40:focus::placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.focus\:builder-placeholder-opacity-50:focus::placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.focus\:builder-placeholder-opacity-60:focus::placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.focus\:builder-placeholder-opacity-70:focus::placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.focus\:builder-placeholder-opacity-75:focus::placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.focus\:builder-placeholder-opacity-80:focus::placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.focus\:builder-placeholder-opacity-90:focus::placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.focus\:builder-placeholder-opacity-95:focus::placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.focus\:builder-placeholder-opacity-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.builder-opacity-0{
  opacity: 0 !important;
}

.builder-opacity-5{
  opacity: 0.05 !important;
}

.builder-opacity-10{
  opacity: 0.1 !important;
}

.builder-opacity-20{
  opacity: 0.2 !important;
}

.builder-opacity-25{
  opacity: 0.25 !important;
}

.builder-opacity-30{
  opacity: 0.3 !important;
}

.builder-opacity-40{
  opacity: 0.4 !important;
}

.builder-opacity-50{
  opacity: 0.5 !important;
}

.builder-opacity-60{
  opacity: 0.6 !important;
}

.builder-opacity-70{
  opacity: 0.7 !important;
}

.builder-opacity-75{
  opacity: 0.75 !important;
}

.builder-opacity-80{
  opacity: 0.8 !important;
}

.builder-opacity-90{
  opacity: 0.9 !important;
}

.builder-opacity-95{
  opacity: 0.95 !important;
}

.builder-opacity-100{
  opacity: 1 !important;
}

.builder-group:hover .group-hover\:builder-opacity-0{
  opacity: 0 !important;
}

.builder-group:hover .group-hover\:builder-opacity-5{
  opacity: 0.05 !important;
}

.builder-group:hover .group-hover\:builder-opacity-10{
  opacity: 0.1 !important;
}

.builder-group:hover .group-hover\:builder-opacity-20{
  opacity: 0.2 !important;
}

.builder-group:hover .group-hover\:builder-opacity-25{
  opacity: 0.25 !important;
}

.builder-group:hover .group-hover\:builder-opacity-30{
  opacity: 0.3 !important;
}

.builder-group:hover .group-hover\:builder-opacity-40{
  opacity: 0.4 !important;
}

.builder-group:hover .group-hover\:builder-opacity-50{
  opacity: 0.5 !important;
}

.builder-group:hover .group-hover\:builder-opacity-60{
  opacity: 0.6 !important;
}

.builder-group:hover .group-hover\:builder-opacity-70{
  opacity: 0.7 !important;
}

.builder-group:hover .group-hover\:builder-opacity-75{
  opacity: 0.75 !important;
}

.builder-group:hover .group-hover\:builder-opacity-80{
  opacity: 0.8 !important;
}

.builder-group:hover .group-hover\:builder-opacity-90{
  opacity: 0.9 !important;
}

.builder-group:hover .group-hover\:builder-opacity-95{
  opacity: 0.95 !important;
}

.builder-group:hover .group-hover\:builder-opacity-100{
  opacity: 1 !important;
}

.focus-within\:builder-opacity-0:focus-within{
  opacity: 0 !important;
}

.focus-within\:builder-opacity-5:focus-within{
  opacity: 0.05 !important;
}

.focus-within\:builder-opacity-10:focus-within{
  opacity: 0.1 !important;
}

.focus-within\:builder-opacity-20:focus-within{
  opacity: 0.2 !important;
}

.focus-within\:builder-opacity-25:focus-within{
  opacity: 0.25 !important;
}

.focus-within\:builder-opacity-30:focus-within{
  opacity: 0.3 !important;
}

.focus-within\:builder-opacity-40:focus-within{
  opacity: 0.4 !important;
}

.focus-within\:builder-opacity-50:focus-within{
  opacity: 0.5 !important;
}

.focus-within\:builder-opacity-60:focus-within{
  opacity: 0.6 !important;
}

.focus-within\:builder-opacity-70:focus-within{
  opacity: 0.7 !important;
}

.focus-within\:builder-opacity-75:focus-within{
  opacity: 0.75 !important;
}

.focus-within\:builder-opacity-80:focus-within{
  opacity: 0.8 !important;
}

.focus-within\:builder-opacity-90:focus-within{
  opacity: 0.9 !important;
}

.focus-within\:builder-opacity-95:focus-within{
  opacity: 0.95 !important;
}

.focus-within\:builder-opacity-100:focus-within{
  opacity: 1 !important;
}

.hover\:builder-opacity-0:hover{
  opacity: 0 !important;
}

.hover\:builder-opacity-5:hover{
  opacity: 0.05 !important;
}

.hover\:builder-opacity-10:hover{
  opacity: 0.1 !important;
}

.hover\:builder-opacity-20:hover{
  opacity: 0.2 !important;
}

.hover\:builder-opacity-25:hover{
  opacity: 0.25 !important;
}

.hover\:builder-opacity-30:hover{
  opacity: 0.3 !important;
}

.hover\:builder-opacity-40:hover{
  opacity: 0.4 !important;
}

.hover\:builder-opacity-50:hover{
  opacity: 0.5 !important;
}

.hover\:builder-opacity-60:hover{
  opacity: 0.6 !important;
}

.hover\:builder-opacity-70:hover{
  opacity: 0.7 !important;
}

.hover\:builder-opacity-75:hover{
  opacity: 0.75 !important;
}

.hover\:builder-opacity-80:hover{
  opacity: 0.8 !important;
}

.hover\:builder-opacity-90:hover{
  opacity: 0.9 !important;
}

.hover\:builder-opacity-95:hover{
  opacity: 0.95 !important;
}

.hover\:builder-opacity-100:hover{
  opacity: 1 !important;
}

.focus\:builder-opacity-0:focus{
  opacity: 0 !important;
}

.focus\:builder-opacity-5:focus{
  opacity: 0.05 !important;
}

.focus\:builder-opacity-10:focus{
  opacity: 0.1 !important;
}

.focus\:builder-opacity-20:focus{
  opacity: 0.2 !important;
}

.focus\:builder-opacity-25:focus{
  opacity: 0.25 !important;
}

.focus\:builder-opacity-30:focus{
  opacity: 0.3 !important;
}

.focus\:builder-opacity-40:focus{
  opacity: 0.4 !important;
}

.focus\:builder-opacity-50:focus{
  opacity: 0.5 !important;
}

.focus\:builder-opacity-60:focus{
  opacity: 0.6 !important;
}

.focus\:builder-opacity-70:focus{
  opacity: 0.7 !important;
}

.focus\:builder-opacity-75:focus{
  opacity: 0.75 !important;
}

.focus\:builder-opacity-80:focus{
  opacity: 0.8 !important;
}

.focus\:builder-opacity-90:focus{
  opacity: 0.9 !important;
}

.focus\:builder-opacity-95:focus{
  opacity: 0.95 !important;
}

.focus\:builder-opacity-100:focus{
  opacity: 1 !important;
}

.builder-bg-blend-normal{
  background-blend-mode: normal !important;
}

.builder-bg-blend-multiply{
  background-blend-mode: multiply !important;
}

.builder-bg-blend-screen{
  background-blend-mode: screen !important;
}

.builder-bg-blend-overlay{
  background-blend-mode: overlay !important;
}

.builder-bg-blend-darken{
  background-blend-mode: darken !important;
}

.builder-bg-blend-lighten{
  background-blend-mode: lighten !important;
}

.builder-bg-blend-color-dodge{
  background-blend-mode: color-dodge !important;
}

.builder-bg-blend-color-burn{
  background-blend-mode: color-burn !important;
}

.builder-bg-blend-hard-light{
  background-blend-mode: hard-light !important;
}

.builder-bg-blend-soft-light{
  background-blend-mode: soft-light !important;
}

.builder-bg-blend-difference{
  background-blend-mode: difference !important;
}

.builder-bg-blend-exclusion{
  background-blend-mode: exclusion !important;
}

.builder-bg-blend-hue{
  background-blend-mode: hue !important;
}

.builder-bg-blend-saturation{
  background-blend-mode: saturation !important;
}

.builder-bg-blend-color{
  background-blend-mode: color !important;
}

.builder-bg-blend-luminosity{
  background-blend-mode: luminosity !important;
}

.builder-mix-blend-normal{
  mix-blend-mode: normal !important;
}

.builder-mix-blend-multiply{
  mix-blend-mode: multiply !important;
}

.builder-mix-blend-screen{
  mix-blend-mode: screen !important;
}

.builder-mix-blend-overlay{
  mix-blend-mode: overlay !important;
}

.builder-mix-blend-darken{
  mix-blend-mode: darken !important;
}

.builder-mix-blend-lighten{
  mix-blend-mode: lighten !important;
}

.builder-mix-blend-color-dodge{
  mix-blend-mode: color-dodge !important;
}

.builder-mix-blend-color-burn{
  mix-blend-mode: color-burn !important;
}

.builder-mix-blend-hard-light{
  mix-blend-mode: hard-light !important;
}

.builder-mix-blend-soft-light{
  mix-blend-mode: soft-light !important;
}

.builder-mix-blend-difference{
  mix-blend-mode: difference !important;
}

.builder-mix-blend-exclusion{
  mix-blend-mode: exclusion !important;
}

.builder-mix-blend-hue{
  mix-blend-mode: hue !important;
}

.builder-mix-blend-saturation{
  mix-blend-mode: saturation !important;
}

.builder-mix-blend-color{
  mix-blend-mode: color !important;
}

.builder-mix-blend-luminosity{
  mix-blend-mode: luminosity !important;
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000;
}

.builder-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-shadow-none{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-group:hover .group-hover\:builder-shadow-none{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-sm:focus-within{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow:focus-within{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-md:focus-within{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-lg:focus-within{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-xl:focus-within{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-2xl:focus-within{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-inner:focus-within{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:builder-shadow-none:focus-within{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-sm:hover{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow:hover{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-md:hover{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-lg:hover{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-xl:hover{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-2xl:hover{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-inner:hover{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:builder-shadow-none:hover{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-sm:focus{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow:focus{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-md:focus{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-lg:focus{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-xl:focus{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-2xl:focus{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-inner:focus{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:builder-shadow-none:focus{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.builder-outline-none{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.builder-outline-white{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.builder-outline-black{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

.focus-within\:builder-outline-none:focus-within{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus-within\:builder-outline-white:focus-within{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.focus-within\:builder-outline-black:focus-within{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

.focus\:builder-outline-none:focus{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus\:builder-outline-white:focus{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.focus\:builder-outline-black:focus{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.builder-ring-0{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring-2{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring-4{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring-8{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:builder-ring-0:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:builder-ring-1:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:builder-ring-2:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:builder-ring-4:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:builder-ring-8:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:builder-ring:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:builder-ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:builder-ring-1:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:builder-ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:builder-ring-4:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:builder-ring-8:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:builder-ring:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.builder-ring-inset{
  --tw-ring-inset: inset !important;
}

.focus-within\:builder-ring-inset:focus-within{
  --tw-ring-inset: inset !important;
}

.focus\:builder-ring-inset:focus{
  --tw-ring-inset: inset !important;
}

.builder-ring-transparent{
  --tw-ring-color: transparent !important;
}

.builder-ring-current{
  --tw-ring-color: currentColor !important;
}

.builder-ring-black{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.builder-ring-white{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
}

.builder-ring-gray-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
}

.builder-ring-red-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
}

.builder-ring-yellow-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
}

.builder-ring-green-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
}

.builder-ring-blue-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
}

.builder-ring-indigo-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
}

.builder-ring-purple-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
}

.builder-ring-pink-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
}

.builder-ring-primary{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
}

.builder-ring-secondary-dark{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
}

.builder-ring-secondary-darker{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-transparent:focus-within{
  --tw-ring-color: transparent !important;
}

.focus-within\:builder-ring-current:focus-within{
  --tw-ring-color: currentColor !important;
}

.focus-within\:builder-ring-black:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-white:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-gray-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-red-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-yellow-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-green-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-blue-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-indigo-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-purple-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-pink-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-primary:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-secondary-dark:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
}

.focus-within\:builder-ring-secondary-darker:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-transparent:focus{
  --tw-ring-color: transparent !important;
}

.focus\:builder-ring-current:focus{
  --tw-ring-color: currentColor !important;
}

.focus\:builder-ring-black:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-white:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-gray-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-red-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-yellow-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-green-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-blue-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-indigo-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-purple-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-pink-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-primary:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-secondary-dark:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
}

.focus\:builder-ring-secondary-darker:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
}

.builder-ring-opacity-0{
  --tw-ring-opacity: 0 !important;
}

.builder-ring-opacity-5{
  --tw-ring-opacity: 0.05 !important;
}

.builder-ring-opacity-10{
  --tw-ring-opacity: 0.1 !important;
}

.builder-ring-opacity-20{
  --tw-ring-opacity: 0.2 !important;
}

.builder-ring-opacity-25{
  --tw-ring-opacity: 0.25 !important;
}

.builder-ring-opacity-30{
  --tw-ring-opacity: 0.3 !important;
}

.builder-ring-opacity-40{
  --tw-ring-opacity: 0.4 !important;
}

.builder-ring-opacity-50{
  --tw-ring-opacity: 0.5 !important;
}

.builder-ring-opacity-60{
  --tw-ring-opacity: 0.6 !important;
}

.builder-ring-opacity-70{
  --tw-ring-opacity: 0.7 !important;
}

.builder-ring-opacity-75{
  --tw-ring-opacity: 0.75 !important;
}

.builder-ring-opacity-80{
  --tw-ring-opacity: 0.8 !important;
}

.builder-ring-opacity-90{
  --tw-ring-opacity: 0.9 !important;
}

.builder-ring-opacity-95{
  --tw-ring-opacity: 0.95 !important;
}

.builder-ring-opacity-100{
  --tw-ring-opacity: 1 !important;
}

.focus-within\:builder-ring-opacity-0:focus-within{
  --tw-ring-opacity: 0 !important;
}

.focus-within\:builder-ring-opacity-5:focus-within{
  --tw-ring-opacity: 0.05 !important;
}

.focus-within\:builder-ring-opacity-10:focus-within{
  --tw-ring-opacity: 0.1 !important;
}

.focus-within\:builder-ring-opacity-20:focus-within{
  --tw-ring-opacity: 0.2 !important;
}

.focus-within\:builder-ring-opacity-25:focus-within{
  --tw-ring-opacity: 0.25 !important;
}

.focus-within\:builder-ring-opacity-30:focus-within{
  --tw-ring-opacity: 0.3 !important;
}

.focus-within\:builder-ring-opacity-40:focus-within{
  --tw-ring-opacity: 0.4 !important;
}

.focus-within\:builder-ring-opacity-50:focus-within{
  --tw-ring-opacity: 0.5 !important;
}

.focus-within\:builder-ring-opacity-60:focus-within{
  --tw-ring-opacity: 0.6 !important;
}

.focus-within\:builder-ring-opacity-70:focus-within{
  --tw-ring-opacity: 0.7 !important;
}

.focus-within\:builder-ring-opacity-75:focus-within{
  --tw-ring-opacity: 0.75 !important;
}

.focus-within\:builder-ring-opacity-80:focus-within{
  --tw-ring-opacity: 0.8 !important;
}

.focus-within\:builder-ring-opacity-90:focus-within{
  --tw-ring-opacity: 0.9 !important;
}

.focus-within\:builder-ring-opacity-95:focus-within{
  --tw-ring-opacity: 0.95 !important;
}

.focus-within\:builder-ring-opacity-100:focus-within{
  --tw-ring-opacity: 1 !important;
}

.focus\:builder-ring-opacity-0:focus{
  --tw-ring-opacity: 0 !important;
}

.focus\:builder-ring-opacity-5:focus{
  --tw-ring-opacity: 0.05 !important;
}

.focus\:builder-ring-opacity-10:focus{
  --tw-ring-opacity: 0.1 !important;
}

.focus\:builder-ring-opacity-20:focus{
  --tw-ring-opacity: 0.2 !important;
}

.focus\:builder-ring-opacity-25:focus{
  --tw-ring-opacity: 0.25 !important;
}

.focus\:builder-ring-opacity-30:focus{
  --tw-ring-opacity: 0.3 !important;
}

.focus\:builder-ring-opacity-40:focus{
  --tw-ring-opacity: 0.4 !important;
}

.focus\:builder-ring-opacity-50:focus{
  --tw-ring-opacity: 0.5 !important;
}

.focus\:builder-ring-opacity-60:focus{
  --tw-ring-opacity: 0.6 !important;
}

.focus\:builder-ring-opacity-70:focus{
  --tw-ring-opacity: 0.7 !important;
}

.focus\:builder-ring-opacity-75:focus{
  --tw-ring-opacity: 0.75 !important;
}

.focus\:builder-ring-opacity-80:focus{
  --tw-ring-opacity: 0.8 !important;
}

.focus\:builder-ring-opacity-90:focus{
  --tw-ring-opacity: 0.9 !important;
}

.focus\:builder-ring-opacity-95:focus{
  --tw-ring-opacity: 0.95 !important;
}

.focus\:builder-ring-opacity-100:focus{
  --tw-ring-opacity: 1 !important;
}

.builder-ring-offset-0{
  --tw-ring-offset-width: 0px !important;
}

.builder-ring-offset-1{
  --tw-ring-offset-width: 1px !important;
}

.builder-ring-offset-2{
  --tw-ring-offset-width: 2px !important;
}

.builder-ring-offset-4{
  --tw-ring-offset-width: 4px !important;
}

.builder-ring-offset-8{
  --tw-ring-offset-width: 8px !important;
}

.focus-within\:builder-ring-offset-0:focus-within{
  --tw-ring-offset-width: 0px !important;
}

.focus-within\:builder-ring-offset-1:focus-within{
  --tw-ring-offset-width: 1px !important;
}

.focus-within\:builder-ring-offset-2:focus-within{
  --tw-ring-offset-width: 2px !important;
}

.focus-within\:builder-ring-offset-4:focus-within{
  --tw-ring-offset-width: 4px !important;
}

.focus-within\:builder-ring-offset-8:focus-within{
  --tw-ring-offset-width: 8px !important;
}

.focus\:builder-ring-offset-0:focus{
  --tw-ring-offset-width: 0px !important;
}

.focus\:builder-ring-offset-1:focus{
  --tw-ring-offset-width: 1px !important;
}

.focus\:builder-ring-offset-2:focus{
  --tw-ring-offset-width: 2px !important;
}

.focus\:builder-ring-offset-4:focus{
  --tw-ring-offset-width: 4px !important;
}

.focus\:builder-ring-offset-8:focus{
  --tw-ring-offset-width: 8px !important;
}

.builder-ring-offset-transparent{
  --tw-ring-offset-color: transparent !important;
}

.builder-ring-offset-current{
  --tw-ring-offset-color: currentColor !important;
}

.builder-ring-offset-black{
  --tw-ring-offset-color: #000 !important;
}

.builder-ring-offset-white{
  --tw-ring-offset-color: #fff !important;
}

.builder-ring-offset-gray-50{
  --tw-ring-offset-color: #f9fafb !important;
}

.builder-ring-offset-gray-100{
  --tw-ring-offset-color: #f3f4f6 !important;
}

.builder-ring-offset-gray-200{
  --tw-ring-offset-color: #e5e7eb !important;
}

.builder-ring-offset-gray-300{
  --tw-ring-offset-color: #d1d5db !important;
}

.builder-ring-offset-gray-400{
  --tw-ring-offset-color: #9ca3af !important;
}

.builder-ring-offset-gray-500{
  --tw-ring-offset-color: #6b7280 !important;
}

.builder-ring-offset-gray-600{
  --tw-ring-offset-color: #4b5563 !important;
}

.builder-ring-offset-gray-700{
  --tw-ring-offset-color: #374151 !important;
}

.builder-ring-offset-gray-800{
  --tw-ring-offset-color: #1f2937 !important;
}

.builder-ring-offset-gray-900{
  --tw-ring-offset-color: #111827 !important;
}

.builder-ring-offset-red-50{
  --tw-ring-offset-color: #fef2f2 !important;
}

.builder-ring-offset-red-100{
  --tw-ring-offset-color: #fee2e2 !important;
}

.builder-ring-offset-red-200{
  --tw-ring-offset-color: #fecaca !important;
}

.builder-ring-offset-red-300{
  --tw-ring-offset-color: #fca5a5 !important;
}

.builder-ring-offset-red-400{
  --tw-ring-offset-color: #f87171 !important;
}

.builder-ring-offset-red-500{
  --tw-ring-offset-color: #ef4444 !important;
}

.builder-ring-offset-red-600{
  --tw-ring-offset-color: #dc2626 !important;
}

.builder-ring-offset-red-700{
  --tw-ring-offset-color: #b91c1c !important;
}

.builder-ring-offset-red-800{
  --tw-ring-offset-color: #991b1b !important;
}

.builder-ring-offset-red-900{
  --tw-ring-offset-color: #7f1d1d !important;
}

.builder-ring-offset-yellow-50{
  --tw-ring-offset-color: #fffbeb !important;
}

.builder-ring-offset-yellow-100{
  --tw-ring-offset-color: #fef3c7 !important;
}

.builder-ring-offset-yellow-200{
  --tw-ring-offset-color: #fde68a !important;
}

.builder-ring-offset-yellow-300{
  --tw-ring-offset-color: #fcd34d !important;
}

.builder-ring-offset-yellow-400{
  --tw-ring-offset-color: #fbbf24 !important;
}

.builder-ring-offset-yellow-500{
  --tw-ring-offset-color: #f59e0b !important;
}

.builder-ring-offset-yellow-600{
  --tw-ring-offset-color: #d97706 !important;
}

.builder-ring-offset-yellow-700{
  --tw-ring-offset-color: #b45309 !important;
}

.builder-ring-offset-yellow-800{
  --tw-ring-offset-color: #92400e !important;
}

.builder-ring-offset-yellow-900{
  --tw-ring-offset-color: #78350f !important;
}

.builder-ring-offset-green-50{
  --tw-ring-offset-color: #ecfdf5 !important;
}

.builder-ring-offset-green-100{
  --tw-ring-offset-color: #d1fae5 !important;
}

.builder-ring-offset-green-200{
  --tw-ring-offset-color: #a7f3d0 !important;
}

.builder-ring-offset-green-300{
  --tw-ring-offset-color: #6ee7b7 !important;
}

.builder-ring-offset-green-400{
  --tw-ring-offset-color: #34d399 !important;
}

.builder-ring-offset-green-500{
  --tw-ring-offset-color: #10b981 !important;
}

.builder-ring-offset-green-600{
  --tw-ring-offset-color: #059669 !important;
}

.builder-ring-offset-green-700{
  --tw-ring-offset-color: #047857 !important;
}

.builder-ring-offset-green-800{
  --tw-ring-offset-color: #065f46 !important;
}

.builder-ring-offset-green-900{
  --tw-ring-offset-color: #064e3b !important;
}

.builder-ring-offset-blue-50{
  --tw-ring-offset-color: #eff6ff !important;
}

.builder-ring-offset-blue-100{
  --tw-ring-offset-color: #dbeafe !important;
}

.builder-ring-offset-blue-200{
  --tw-ring-offset-color: #bfdbfe !important;
}

.builder-ring-offset-blue-300{
  --tw-ring-offset-color: #93c5fd !important;
}

.builder-ring-offset-blue-400{
  --tw-ring-offset-color: #60a5fa !important;
}

.builder-ring-offset-blue-500{
  --tw-ring-offset-color: #3b82f6 !important;
}

.builder-ring-offset-blue-600{
  --tw-ring-offset-color: #2563eb !important;
}

.builder-ring-offset-blue-700{
  --tw-ring-offset-color: #1d4ed8 !important;
}

.builder-ring-offset-blue-800{
  --tw-ring-offset-color: #1e40af !important;
}

.builder-ring-offset-blue-900{
  --tw-ring-offset-color: #1e3a8a !important;
}

.builder-ring-offset-indigo-50{
  --tw-ring-offset-color: #eef2ff !important;
}

.builder-ring-offset-indigo-100{
  --tw-ring-offset-color: #e0e7ff !important;
}

.builder-ring-offset-indigo-200{
  --tw-ring-offset-color: #c7d2fe !important;
}

.builder-ring-offset-indigo-300{
  --tw-ring-offset-color: #a5b4fc !important;
}

.builder-ring-offset-indigo-400{
  --tw-ring-offset-color: #818cf8 !important;
}

.builder-ring-offset-indigo-500{
  --tw-ring-offset-color: #6366f1 !important;
}

.builder-ring-offset-indigo-600{
  --tw-ring-offset-color: #4f46e5 !important;
}

.builder-ring-offset-indigo-700{
  --tw-ring-offset-color: #4338ca !important;
}

.builder-ring-offset-indigo-800{
  --tw-ring-offset-color: #3730a3 !important;
}

.builder-ring-offset-indigo-900{
  --tw-ring-offset-color: #312e81 !important;
}

.builder-ring-offset-purple-50{
  --tw-ring-offset-color: #f5f3ff !important;
}

.builder-ring-offset-purple-100{
  --tw-ring-offset-color: #ede9fe !important;
}

.builder-ring-offset-purple-200{
  --tw-ring-offset-color: #ddd6fe !important;
}

.builder-ring-offset-purple-300{
  --tw-ring-offset-color: #c4b5fd !important;
}

.builder-ring-offset-purple-400{
  --tw-ring-offset-color: #a78bfa !important;
}

.builder-ring-offset-purple-500{
  --tw-ring-offset-color: #8b5cf6 !important;
}

.builder-ring-offset-purple-600{
  --tw-ring-offset-color: #7c3aed !important;
}

.builder-ring-offset-purple-700{
  --tw-ring-offset-color: #6d28d9 !important;
}

.builder-ring-offset-purple-800{
  --tw-ring-offset-color: #5b21b6 !important;
}

.builder-ring-offset-purple-900{
  --tw-ring-offset-color: #4c1d95 !important;
}

.builder-ring-offset-pink-50{
  --tw-ring-offset-color: #fdf2f8 !important;
}

.builder-ring-offset-pink-100{
  --tw-ring-offset-color: #fce7f3 !important;
}

.builder-ring-offset-pink-200{
  --tw-ring-offset-color: #fbcfe8 !important;
}

.builder-ring-offset-pink-300{
  --tw-ring-offset-color: #f9a8d4 !important;
}

.builder-ring-offset-pink-400{
  --tw-ring-offset-color: #f472b6 !important;
}

.builder-ring-offset-pink-500{
  --tw-ring-offset-color: #ec4899 !important;
}

.builder-ring-offset-pink-600{
  --tw-ring-offset-color: #db2777 !important;
}

.builder-ring-offset-pink-700{
  --tw-ring-offset-color: #be185d !important;
}

.builder-ring-offset-pink-800{
  --tw-ring-offset-color: #9d174d !important;
}

.builder-ring-offset-pink-900{
  --tw-ring-offset-color: #831843 !important;
}

.builder-ring-offset-primary{
  --tw-ring-offset-color: #2d375a !important;
}

.builder-ring-offset-secondary-dark{
  --tw-ring-offset-color: #343434 !important;
}

.builder-ring-offset-secondary-darker{
  --tw-ring-offset-color: #212121 !important;
}

.focus-within\:builder-ring-offset-transparent:focus-within{
  --tw-ring-offset-color: transparent !important;
}

.focus-within\:builder-ring-offset-current:focus-within{
  --tw-ring-offset-color: currentColor !important;
}

.focus-within\:builder-ring-offset-black:focus-within{
  --tw-ring-offset-color: #000 !important;
}

.focus-within\:builder-ring-offset-white:focus-within{
  --tw-ring-offset-color: #fff !important;
}

.focus-within\:builder-ring-offset-gray-50:focus-within{
  --tw-ring-offset-color: #f9fafb !important;
}

.focus-within\:builder-ring-offset-gray-100:focus-within{
  --tw-ring-offset-color: #f3f4f6 !important;
}

.focus-within\:builder-ring-offset-gray-200:focus-within{
  --tw-ring-offset-color: #e5e7eb !important;
}

.focus-within\:builder-ring-offset-gray-300:focus-within{
  --tw-ring-offset-color: #d1d5db !important;
}

.focus-within\:builder-ring-offset-gray-400:focus-within{
  --tw-ring-offset-color: #9ca3af !important;
}

.focus-within\:builder-ring-offset-gray-500:focus-within{
  --tw-ring-offset-color: #6b7280 !important;
}

.focus-within\:builder-ring-offset-gray-600:focus-within{
  --tw-ring-offset-color: #4b5563 !important;
}

.focus-within\:builder-ring-offset-gray-700:focus-within{
  --tw-ring-offset-color: #374151 !important;
}

.focus-within\:builder-ring-offset-gray-800:focus-within{
  --tw-ring-offset-color: #1f2937 !important;
}

.focus-within\:builder-ring-offset-gray-900:focus-within{
  --tw-ring-offset-color: #111827 !important;
}

.focus-within\:builder-ring-offset-red-50:focus-within{
  --tw-ring-offset-color: #fef2f2 !important;
}

.focus-within\:builder-ring-offset-red-100:focus-within{
  --tw-ring-offset-color: #fee2e2 !important;
}

.focus-within\:builder-ring-offset-red-200:focus-within{
  --tw-ring-offset-color: #fecaca !important;
}

.focus-within\:builder-ring-offset-red-300:focus-within{
  --tw-ring-offset-color: #fca5a5 !important;
}

.focus-within\:builder-ring-offset-red-400:focus-within{
  --tw-ring-offset-color: #f87171 !important;
}

.focus-within\:builder-ring-offset-red-500:focus-within{
  --tw-ring-offset-color: #ef4444 !important;
}

.focus-within\:builder-ring-offset-red-600:focus-within{
  --tw-ring-offset-color: #dc2626 !important;
}

.focus-within\:builder-ring-offset-red-700:focus-within{
  --tw-ring-offset-color: #b91c1c !important;
}

.focus-within\:builder-ring-offset-red-800:focus-within{
  --tw-ring-offset-color: #991b1b !important;
}

.focus-within\:builder-ring-offset-red-900:focus-within{
  --tw-ring-offset-color: #7f1d1d !important;
}

.focus-within\:builder-ring-offset-yellow-50:focus-within{
  --tw-ring-offset-color: #fffbeb !important;
}

.focus-within\:builder-ring-offset-yellow-100:focus-within{
  --tw-ring-offset-color: #fef3c7 !important;
}

.focus-within\:builder-ring-offset-yellow-200:focus-within{
  --tw-ring-offset-color: #fde68a !important;
}

.focus-within\:builder-ring-offset-yellow-300:focus-within{
  --tw-ring-offset-color: #fcd34d !important;
}

.focus-within\:builder-ring-offset-yellow-400:focus-within{
  --tw-ring-offset-color: #fbbf24 !important;
}

.focus-within\:builder-ring-offset-yellow-500:focus-within{
  --tw-ring-offset-color: #f59e0b !important;
}

.focus-within\:builder-ring-offset-yellow-600:focus-within{
  --tw-ring-offset-color: #d97706 !important;
}

.focus-within\:builder-ring-offset-yellow-700:focus-within{
  --tw-ring-offset-color: #b45309 !important;
}

.focus-within\:builder-ring-offset-yellow-800:focus-within{
  --tw-ring-offset-color: #92400e !important;
}

.focus-within\:builder-ring-offset-yellow-900:focus-within{
  --tw-ring-offset-color: #78350f !important;
}

.focus-within\:builder-ring-offset-green-50:focus-within{
  --tw-ring-offset-color: #ecfdf5 !important;
}

.focus-within\:builder-ring-offset-green-100:focus-within{
  --tw-ring-offset-color: #d1fae5 !important;
}

.focus-within\:builder-ring-offset-green-200:focus-within{
  --tw-ring-offset-color: #a7f3d0 !important;
}

.focus-within\:builder-ring-offset-green-300:focus-within{
  --tw-ring-offset-color: #6ee7b7 !important;
}

.focus-within\:builder-ring-offset-green-400:focus-within{
  --tw-ring-offset-color: #34d399 !important;
}

.focus-within\:builder-ring-offset-green-500:focus-within{
  --tw-ring-offset-color: #10b981 !important;
}

.focus-within\:builder-ring-offset-green-600:focus-within{
  --tw-ring-offset-color: #059669 !important;
}

.focus-within\:builder-ring-offset-green-700:focus-within{
  --tw-ring-offset-color: #047857 !important;
}

.focus-within\:builder-ring-offset-green-800:focus-within{
  --tw-ring-offset-color: #065f46 !important;
}

.focus-within\:builder-ring-offset-green-900:focus-within{
  --tw-ring-offset-color: #064e3b !important;
}

.focus-within\:builder-ring-offset-blue-50:focus-within{
  --tw-ring-offset-color: #eff6ff !important;
}

.focus-within\:builder-ring-offset-blue-100:focus-within{
  --tw-ring-offset-color: #dbeafe !important;
}

.focus-within\:builder-ring-offset-blue-200:focus-within{
  --tw-ring-offset-color: #bfdbfe !important;
}

.focus-within\:builder-ring-offset-blue-300:focus-within{
  --tw-ring-offset-color: #93c5fd !important;
}

.focus-within\:builder-ring-offset-blue-400:focus-within{
  --tw-ring-offset-color: #60a5fa !important;
}

.focus-within\:builder-ring-offset-blue-500:focus-within{
  --tw-ring-offset-color: #3b82f6 !important;
}

.focus-within\:builder-ring-offset-blue-600:focus-within{
  --tw-ring-offset-color: #2563eb !important;
}

.focus-within\:builder-ring-offset-blue-700:focus-within{
  --tw-ring-offset-color: #1d4ed8 !important;
}

.focus-within\:builder-ring-offset-blue-800:focus-within{
  --tw-ring-offset-color: #1e40af !important;
}

.focus-within\:builder-ring-offset-blue-900:focus-within{
  --tw-ring-offset-color: #1e3a8a !important;
}

.focus-within\:builder-ring-offset-indigo-50:focus-within{
  --tw-ring-offset-color: #eef2ff !important;
}

.focus-within\:builder-ring-offset-indigo-100:focus-within{
  --tw-ring-offset-color: #e0e7ff !important;
}

.focus-within\:builder-ring-offset-indigo-200:focus-within{
  --tw-ring-offset-color: #c7d2fe !important;
}

.focus-within\:builder-ring-offset-indigo-300:focus-within{
  --tw-ring-offset-color: #a5b4fc !important;
}

.focus-within\:builder-ring-offset-indigo-400:focus-within{
  --tw-ring-offset-color: #818cf8 !important;
}

.focus-within\:builder-ring-offset-indigo-500:focus-within{
  --tw-ring-offset-color: #6366f1 !important;
}

.focus-within\:builder-ring-offset-indigo-600:focus-within{
  --tw-ring-offset-color: #4f46e5 !important;
}

.focus-within\:builder-ring-offset-indigo-700:focus-within{
  --tw-ring-offset-color: #4338ca !important;
}

.focus-within\:builder-ring-offset-indigo-800:focus-within{
  --tw-ring-offset-color: #3730a3 !important;
}

.focus-within\:builder-ring-offset-indigo-900:focus-within{
  --tw-ring-offset-color: #312e81 !important;
}

.focus-within\:builder-ring-offset-purple-50:focus-within{
  --tw-ring-offset-color: #f5f3ff !important;
}

.focus-within\:builder-ring-offset-purple-100:focus-within{
  --tw-ring-offset-color: #ede9fe !important;
}

.focus-within\:builder-ring-offset-purple-200:focus-within{
  --tw-ring-offset-color: #ddd6fe !important;
}

.focus-within\:builder-ring-offset-purple-300:focus-within{
  --tw-ring-offset-color: #c4b5fd !important;
}

.focus-within\:builder-ring-offset-purple-400:focus-within{
  --tw-ring-offset-color: #a78bfa !important;
}

.focus-within\:builder-ring-offset-purple-500:focus-within{
  --tw-ring-offset-color: #8b5cf6 !important;
}

.focus-within\:builder-ring-offset-purple-600:focus-within{
  --tw-ring-offset-color: #7c3aed !important;
}

.focus-within\:builder-ring-offset-purple-700:focus-within{
  --tw-ring-offset-color: #6d28d9 !important;
}

.focus-within\:builder-ring-offset-purple-800:focus-within{
  --tw-ring-offset-color: #5b21b6 !important;
}

.focus-within\:builder-ring-offset-purple-900:focus-within{
  --tw-ring-offset-color: #4c1d95 !important;
}

.focus-within\:builder-ring-offset-pink-50:focus-within{
  --tw-ring-offset-color: #fdf2f8 !important;
}

.focus-within\:builder-ring-offset-pink-100:focus-within{
  --tw-ring-offset-color: #fce7f3 !important;
}

.focus-within\:builder-ring-offset-pink-200:focus-within{
  --tw-ring-offset-color: #fbcfe8 !important;
}

.focus-within\:builder-ring-offset-pink-300:focus-within{
  --tw-ring-offset-color: #f9a8d4 !important;
}

.focus-within\:builder-ring-offset-pink-400:focus-within{
  --tw-ring-offset-color: #f472b6 !important;
}

.focus-within\:builder-ring-offset-pink-500:focus-within{
  --tw-ring-offset-color: #ec4899 !important;
}

.focus-within\:builder-ring-offset-pink-600:focus-within{
  --tw-ring-offset-color: #db2777 !important;
}

.focus-within\:builder-ring-offset-pink-700:focus-within{
  --tw-ring-offset-color: #be185d !important;
}

.focus-within\:builder-ring-offset-pink-800:focus-within{
  --tw-ring-offset-color: #9d174d !important;
}

.focus-within\:builder-ring-offset-pink-900:focus-within{
  --tw-ring-offset-color: #831843 !important;
}

.focus-within\:builder-ring-offset-primary:focus-within{
  --tw-ring-offset-color: #2d375a !important;
}

.focus-within\:builder-ring-offset-secondary-dark:focus-within{
  --tw-ring-offset-color: #343434 !important;
}

.focus-within\:builder-ring-offset-secondary-darker:focus-within{
  --tw-ring-offset-color: #212121 !important;
}

.focus\:builder-ring-offset-transparent:focus{
  --tw-ring-offset-color: transparent !important;
}

.focus\:builder-ring-offset-current:focus{
  --tw-ring-offset-color: currentColor !important;
}

.focus\:builder-ring-offset-black:focus{
  --tw-ring-offset-color: #000 !important;
}

.focus\:builder-ring-offset-white:focus{
  --tw-ring-offset-color: #fff !important;
}

.focus\:builder-ring-offset-gray-50:focus{
  --tw-ring-offset-color: #f9fafb !important;
}

.focus\:builder-ring-offset-gray-100:focus{
  --tw-ring-offset-color: #f3f4f6 !important;
}

.focus\:builder-ring-offset-gray-200:focus{
  --tw-ring-offset-color: #e5e7eb !important;
}

.focus\:builder-ring-offset-gray-300:focus{
  --tw-ring-offset-color: #d1d5db !important;
}

.focus\:builder-ring-offset-gray-400:focus{
  --tw-ring-offset-color: #9ca3af !important;
}

.focus\:builder-ring-offset-gray-500:focus{
  --tw-ring-offset-color: #6b7280 !important;
}

.focus\:builder-ring-offset-gray-600:focus{
  --tw-ring-offset-color: #4b5563 !important;
}

.focus\:builder-ring-offset-gray-700:focus{
  --tw-ring-offset-color: #374151 !important;
}

.focus\:builder-ring-offset-gray-800:focus{
  --tw-ring-offset-color: #1f2937 !important;
}

.focus\:builder-ring-offset-gray-900:focus{
  --tw-ring-offset-color: #111827 !important;
}

.focus\:builder-ring-offset-red-50:focus{
  --tw-ring-offset-color: #fef2f2 !important;
}

.focus\:builder-ring-offset-red-100:focus{
  --tw-ring-offset-color: #fee2e2 !important;
}

.focus\:builder-ring-offset-red-200:focus{
  --tw-ring-offset-color: #fecaca !important;
}

.focus\:builder-ring-offset-red-300:focus{
  --tw-ring-offset-color: #fca5a5 !important;
}

.focus\:builder-ring-offset-red-400:focus{
  --tw-ring-offset-color: #f87171 !important;
}

.focus\:builder-ring-offset-red-500:focus{
  --tw-ring-offset-color: #ef4444 !important;
}

.focus\:builder-ring-offset-red-600:focus{
  --tw-ring-offset-color: #dc2626 !important;
}

.focus\:builder-ring-offset-red-700:focus{
  --tw-ring-offset-color: #b91c1c !important;
}

.focus\:builder-ring-offset-red-800:focus{
  --tw-ring-offset-color: #991b1b !important;
}

.focus\:builder-ring-offset-red-900:focus{
  --tw-ring-offset-color: #7f1d1d !important;
}

.focus\:builder-ring-offset-yellow-50:focus{
  --tw-ring-offset-color: #fffbeb !important;
}

.focus\:builder-ring-offset-yellow-100:focus{
  --tw-ring-offset-color: #fef3c7 !important;
}

.focus\:builder-ring-offset-yellow-200:focus{
  --tw-ring-offset-color: #fde68a !important;
}

.focus\:builder-ring-offset-yellow-300:focus{
  --tw-ring-offset-color: #fcd34d !important;
}

.focus\:builder-ring-offset-yellow-400:focus{
  --tw-ring-offset-color: #fbbf24 !important;
}

.focus\:builder-ring-offset-yellow-500:focus{
  --tw-ring-offset-color: #f59e0b !important;
}

.focus\:builder-ring-offset-yellow-600:focus{
  --tw-ring-offset-color: #d97706 !important;
}

.focus\:builder-ring-offset-yellow-700:focus{
  --tw-ring-offset-color: #b45309 !important;
}

.focus\:builder-ring-offset-yellow-800:focus{
  --tw-ring-offset-color: #92400e !important;
}

.focus\:builder-ring-offset-yellow-900:focus{
  --tw-ring-offset-color: #78350f !important;
}

.focus\:builder-ring-offset-green-50:focus{
  --tw-ring-offset-color: #ecfdf5 !important;
}

.focus\:builder-ring-offset-green-100:focus{
  --tw-ring-offset-color: #d1fae5 !important;
}

.focus\:builder-ring-offset-green-200:focus{
  --tw-ring-offset-color: #a7f3d0 !important;
}

.focus\:builder-ring-offset-green-300:focus{
  --tw-ring-offset-color: #6ee7b7 !important;
}

.focus\:builder-ring-offset-green-400:focus{
  --tw-ring-offset-color: #34d399 !important;
}

.focus\:builder-ring-offset-green-500:focus{
  --tw-ring-offset-color: #10b981 !important;
}

.focus\:builder-ring-offset-green-600:focus{
  --tw-ring-offset-color: #059669 !important;
}

.focus\:builder-ring-offset-green-700:focus{
  --tw-ring-offset-color: #047857 !important;
}

.focus\:builder-ring-offset-green-800:focus{
  --tw-ring-offset-color: #065f46 !important;
}

.focus\:builder-ring-offset-green-900:focus{
  --tw-ring-offset-color: #064e3b !important;
}

.focus\:builder-ring-offset-blue-50:focus{
  --tw-ring-offset-color: #eff6ff !important;
}

.focus\:builder-ring-offset-blue-100:focus{
  --tw-ring-offset-color: #dbeafe !important;
}

.focus\:builder-ring-offset-blue-200:focus{
  --tw-ring-offset-color: #bfdbfe !important;
}

.focus\:builder-ring-offset-blue-300:focus{
  --tw-ring-offset-color: #93c5fd !important;
}

.focus\:builder-ring-offset-blue-400:focus{
  --tw-ring-offset-color: #60a5fa !important;
}

.focus\:builder-ring-offset-blue-500:focus{
  --tw-ring-offset-color: #3b82f6 !important;
}

.focus\:builder-ring-offset-blue-600:focus{
  --tw-ring-offset-color: #2563eb !important;
}

.focus\:builder-ring-offset-blue-700:focus{
  --tw-ring-offset-color: #1d4ed8 !important;
}

.focus\:builder-ring-offset-blue-800:focus{
  --tw-ring-offset-color: #1e40af !important;
}

.focus\:builder-ring-offset-blue-900:focus{
  --tw-ring-offset-color: #1e3a8a !important;
}

.focus\:builder-ring-offset-indigo-50:focus{
  --tw-ring-offset-color: #eef2ff !important;
}

.focus\:builder-ring-offset-indigo-100:focus{
  --tw-ring-offset-color: #e0e7ff !important;
}

.focus\:builder-ring-offset-indigo-200:focus{
  --tw-ring-offset-color: #c7d2fe !important;
}

.focus\:builder-ring-offset-indigo-300:focus{
  --tw-ring-offset-color: #a5b4fc !important;
}

.focus\:builder-ring-offset-indigo-400:focus{
  --tw-ring-offset-color: #818cf8 !important;
}

.focus\:builder-ring-offset-indigo-500:focus{
  --tw-ring-offset-color: #6366f1 !important;
}

.focus\:builder-ring-offset-indigo-600:focus{
  --tw-ring-offset-color: #4f46e5 !important;
}

.focus\:builder-ring-offset-indigo-700:focus{
  --tw-ring-offset-color: #4338ca !important;
}

.focus\:builder-ring-offset-indigo-800:focus{
  --tw-ring-offset-color: #3730a3 !important;
}

.focus\:builder-ring-offset-indigo-900:focus{
  --tw-ring-offset-color: #312e81 !important;
}

.focus\:builder-ring-offset-purple-50:focus{
  --tw-ring-offset-color: #f5f3ff !important;
}

.focus\:builder-ring-offset-purple-100:focus{
  --tw-ring-offset-color: #ede9fe !important;
}

.focus\:builder-ring-offset-purple-200:focus{
  --tw-ring-offset-color: #ddd6fe !important;
}

.focus\:builder-ring-offset-purple-300:focus{
  --tw-ring-offset-color: #c4b5fd !important;
}

.focus\:builder-ring-offset-purple-400:focus{
  --tw-ring-offset-color: #a78bfa !important;
}

.focus\:builder-ring-offset-purple-500:focus{
  --tw-ring-offset-color: #8b5cf6 !important;
}

.focus\:builder-ring-offset-purple-600:focus{
  --tw-ring-offset-color: #7c3aed !important;
}

.focus\:builder-ring-offset-purple-700:focus{
  --tw-ring-offset-color: #6d28d9 !important;
}

.focus\:builder-ring-offset-purple-800:focus{
  --tw-ring-offset-color: #5b21b6 !important;
}

.focus\:builder-ring-offset-purple-900:focus{
  --tw-ring-offset-color: #4c1d95 !important;
}

.focus\:builder-ring-offset-pink-50:focus{
  --tw-ring-offset-color: #fdf2f8 !important;
}

.focus\:builder-ring-offset-pink-100:focus{
  --tw-ring-offset-color: #fce7f3 !important;
}

.focus\:builder-ring-offset-pink-200:focus{
  --tw-ring-offset-color: #fbcfe8 !important;
}

.focus\:builder-ring-offset-pink-300:focus{
  --tw-ring-offset-color: #f9a8d4 !important;
}

.focus\:builder-ring-offset-pink-400:focus{
  --tw-ring-offset-color: #f472b6 !important;
}

.focus\:builder-ring-offset-pink-500:focus{
  --tw-ring-offset-color: #ec4899 !important;
}

.focus\:builder-ring-offset-pink-600:focus{
  --tw-ring-offset-color: #db2777 !important;
}

.focus\:builder-ring-offset-pink-700:focus{
  --tw-ring-offset-color: #be185d !important;
}

.focus\:builder-ring-offset-pink-800:focus{
  --tw-ring-offset-color: #9d174d !important;
}

.focus\:builder-ring-offset-pink-900:focus{
  --tw-ring-offset-color: #831843 !important;
}

.focus\:builder-ring-offset-primary:focus{
  --tw-ring-offset-color: #2d375a !important;
}

.focus\:builder-ring-offset-secondary-dark:focus{
  --tw-ring-offset-color: #343434 !important;
}

.focus\:builder-ring-offset-secondary-darker:focus{
  --tw-ring-offset-color: #212121 !important;
}

.builder-filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.builder-filter-none{
  filter: none !important;
}

.builder-blur-0{
  --tw-blur: blur(0) !important;
}

.builder-blur-none{
  --tw-blur: blur(0) !important;
}

.builder-blur-sm{
  --tw-blur: blur(4px) !important;
}

.builder-blur{
  --tw-blur: blur(8px) !important;
}

.builder-blur-md{
  --tw-blur: blur(12px) !important;
}

.builder-blur-lg{
  --tw-blur: blur(16px) !important;
}

.builder-blur-xl{
  --tw-blur: blur(24px) !important;
}

.builder-blur-2xl{
  --tw-blur: blur(40px) !important;
}

.builder-blur-3xl{
  --tw-blur: blur(64px) !important;
}

.builder-brightness-0{
  --tw-brightness: brightness(0) !important;
}

.builder-brightness-50{
  --tw-brightness: brightness(.5) !important;
}

.builder-brightness-75{
  --tw-brightness: brightness(.75) !important;
}

.builder-brightness-90{
  --tw-brightness: brightness(.9) !important;
}

.builder-brightness-95{
  --tw-brightness: brightness(.95) !important;
}

.builder-brightness-100{
  --tw-brightness: brightness(1) !important;
}

.builder-brightness-105{
  --tw-brightness: brightness(1.05) !important;
}

.builder-brightness-110{
  --tw-brightness: brightness(1.1) !important;
}

.builder-brightness-125{
  --tw-brightness: brightness(1.25) !important;
}

.builder-brightness-150{
  --tw-brightness: brightness(1.5) !important;
}

.builder-brightness-200{
  --tw-brightness: brightness(2) !important;
}

.builder-contrast-0{
  --tw-contrast: contrast(0) !important;
}

.builder-contrast-50{
  --tw-contrast: contrast(.5) !important;
}

.builder-contrast-75{
  --tw-contrast: contrast(.75) !important;
}

.builder-contrast-100{
  --tw-contrast: contrast(1) !important;
}

.builder-contrast-125{
  --tw-contrast: contrast(1.25) !important;
}

.builder-contrast-150{
  --tw-contrast: contrast(1.5) !important;
}

.builder-contrast-200{
  --tw-contrast: contrast(2) !important;
}

.builder-drop-shadow-sm{
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
}

.builder-drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
}

.builder-drop-shadow-md{
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
}

.builder-drop-shadow-lg{
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
}

.builder-drop-shadow-xl{
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
}

.builder-drop-shadow-2xl{
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
}

.builder-drop-shadow-none{
  --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
}

.builder-grayscale-0{
  --tw-grayscale: grayscale(0) !important;
}

.builder-grayscale{
  --tw-grayscale: grayscale(100%) !important;
}

.builder-hue-rotate-0{
  --tw-hue-rotate: hue-rotate(0deg) !important;
}

.builder-hue-rotate-15{
  --tw-hue-rotate: hue-rotate(15deg) !important;
}

.builder-hue-rotate-30{
  --tw-hue-rotate: hue-rotate(30deg) !important;
}

.builder-hue-rotate-60{
  --tw-hue-rotate: hue-rotate(60deg) !important;
}

.builder-hue-rotate-90{
  --tw-hue-rotate: hue-rotate(90deg) !important;
}

.builder-hue-rotate-180{
  --tw-hue-rotate: hue-rotate(180deg) !important;
}

.builder--hue-rotate-180{
  --tw-hue-rotate: hue-rotate(-180deg) !important;
}

.builder--hue-rotate-90{
  --tw-hue-rotate: hue-rotate(-90deg) !important;
}

.builder--hue-rotate-60{
  --tw-hue-rotate: hue-rotate(-60deg) !important;
}

.builder--hue-rotate-30{
  --tw-hue-rotate: hue-rotate(-30deg) !important;
}

.builder--hue-rotate-15{
  --tw-hue-rotate: hue-rotate(-15deg) !important;
}

.builder-invert-0{
  --tw-invert: invert(0) !important;
}

.builder-invert{
  --tw-invert: invert(100%) !important;
}

.builder-saturate-0{
  --tw-saturate: saturate(0) !important;
}

.builder-saturate-50{
  --tw-saturate: saturate(.5) !important;
}

.builder-saturate-100{
  --tw-saturate: saturate(1) !important;
}

.builder-saturate-150{
  --tw-saturate: saturate(1.5) !important;
}

.builder-saturate-200{
  --tw-saturate: saturate(2) !important;
}

.builder-sepia-0{
  --tw-sepia: sepia(0) !important;
}

.builder-sepia{
  --tw-sepia: sepia(100%) !important;
}

.builder-backdrop-filter{
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.builder-backdrop-filter-none{
  -webkit-backdrop-filter: none !important;
          backdrop-filter: none !important;
}

.builder-backdrop-blur-0{
  --tw-backdrop-blur: blur(0) !important;
}

.builder-backdrop-blur-none{
  --tw-backdrop-blur: blur(0) !important;
}

.builder-backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px) !important;
}

.builder-backdrop-blur{
  --tw-backdrop-blur: blur(8px) !important;
}

.builder-backdrop-blur-md{
  --tw-backdrop-blur: blur(12px) !important;
}

.builder-backdrop-blur-lg{
  --tw-backdrop-blur: blur(16px) !important;
}

.builder-backdrop-blur-xl{
  --tw-backdrop-blur: blur(24px) !important;
}

.builder-backdrop-blur-2xl{
  --tw-backdrop-blur: blur(40px) !important;
}

.builder-backdrop-blur-3xl{
  --tw-backdrop-blur: blur(64px) !important;
}

.builder-backdrop-brightness-0{
  --tw-backdrop-brightness: brightness(0) !important;
}

.builder-backdrop-brightness-50{
  --tw-backdrop-brightness: brightness(.5) !important;
}

.builder-backdrop-brightness-75{
  --tw-backdrop-brightness: brightness(.75) !important;
}

.builder-backdrop-brightness-90{
  --tw-backdrop-brightness: brightness(.9) !important;
}

.builder-backdrop-brightness-95{
  --tw-backdrop-brightness: brightness(.95) !important;
}

.builder-backdrop-brightness-100{
  --tw-backdrop-brightness: brightness(1) !important;
}

.builder-backdrop-brightness-105{
  --tw-backdrop-brightness: brightness(1.05) !important;
}

.builder-backdrop-brightness-110{
  --tw-backdrop-brightness: brightness(1.1) !important;
}

.builder-backdrop-brightness-125{
  --tw-backdrop-brightness: brightness(1.25) !important;
}

.builder-backdrop-brightness-150{
  --tw-backdrop-brightness: brightness(1.5) !important;
}

.builder-backdrop-brightness-200{
  --tw-backdrop-brightness: brightness(2) !important;
}

.builder-backdrop-contrast-0{
  --tw-backdrop-contrast: contrast(0) !important;
}

.builder-backdrop-contrast-50{
  --tw-backdrop-contrast: contrast(.5) !important;
}

.builder-backdrop-contrast-75{
  --tw-backdrop-contrast: contrast(.75) !important;
}

.builder-backdrop-contrast-100{
  --tw-backdrop-contrast: contrast(1) !important;
}

.builder-backdrop-contrast-125{
  --tw-backdrop-contrast: contrast(1.25) !important;
}

.builder-backdrop-contrast-150{
  --tw-backdrop-contrast: contrast(1.5) !important;
}

.builder-backdrop-contrast-200{
  --tw-backdrop-contrast: contrast(2) !important;
}

.builder-backdrop-grayscale-0{
  --tw-backdrop-grayscale: grayscale(0) !important;
}

.builder-backdrop-grayscale{
  --tw-backdrop-grayscale: grayscale(100%) !important;
}

.builder-backdrop-hue-rotate-0{
  --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
}

.builder-backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
}

.builder-backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
}

.builder-backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
}

.builder-backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
}

.builder-backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
}

.builder--backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
}

.builder--backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
}

.builder--backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
}

.builder--backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
}

.builder--backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
}

.builder-backdrop-invert-0{
  --tw-backdrop-invert: invert(0) !important;
}

.builder-backdrop-invert{
  --tw-backdrop-invert: invert(100%) !important;
}

.builder-backdrop-opacity-0{
  --tw-backdrop-opacity: opacity(0) !important;
}

.builder-backdrop-opacity-5{
  --tw-backdrop-opacity: opacity(0.05) !important;
}

.builder-backdrop-opacity-10{
  --tw-backdrop-opacity: opacity(0.1) !important;
}

.builder-backdrop-opacity-20{
  --tw-backdrop-opacity: opacity(0.2) !important;
}

.builder-backdrop-opacity-25{
  --tw-backdrop-opacity: opacity(0.25) !important;
}

.builder-backdrop-opacity-30{
  --tw-backdrop-opacity: opacity(0.3) !important;
}

.builder-backdrop-opacity-40{
  --tw-backdrop-opacity: opacity(0.4) !important;
}

.builder-backdrop-opacity-50{
  --tw-backdrop-opacity: opacity(0.5) !important;
}

.builder-backdrop-opacity-60{
  --tw-backdrop-opacity: opacity(0.6) !important;
}

.builder-backdrop-opacity-70{
  --tw-backdrop-opacity: opacity(0.7) !important;
}

.builder-backdrop-opacity-75{
  --tw-backdrop-opacity: opacity(0.75) !important;
}

.builder-backdrop-opacity-80{
  --tw-backdrop-opacity: opacity(0.8) !important;
}

.builder-backdrop-opacity-90{
  --tw-backdrop-opacity: opacity(0.9) !important;
}

.builder-backdrop-opacity-95{
  --tw-backdrop-opacity: opacity(0.95) !important;
}

.builder-backdrop-opacity-100{
  --tw-backdrop-opacity: opacity(1) !important;
}

.builder-backdrop-saturate-0{
  --tw-backdrop-saturate: saturate(0) !important;
}

.builder-backdrop-saturate-50{
  --tw-backdrop-saturate: saturate(.5) !important;
}

.builder-backdrop-saturate-100{
  --tw-backdrop-saturate: saturate(1) !important;
}

.builder-backdrop-saturate-150{
  --tw-backdrop-saturate: saturate(1.5) !important;
}

.builder-backdrop-saturate-200{
  --tw-backdrop-saturate: saturate(2) !important;
}

.builder-backdrop-sepia-0{
  --tw-backdrop-sepia: sepia(0) !important;
}

.builder-backdrop-sepia{
  --tw-backdrop-sepia: sepia(100%) !important;
}

.builder-transition-none{
  transition-property: none !important;
}

.builder-transition-all{
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.builder-transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.builder-transition-colors{
  transition-property: background-color, border-color, color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.builder-transition-opacity{
  transition-property: opacity !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.builder-transition-shadow{
  transition-property: box-shadow !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.builder-transition-transform{
  transition-property: transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.builder-delay-75{
  transition-delay: 75ms !important;
}

.builder-delay-100{
  transition-delay: 100ms !important;
}

.builder-delay-150{
  transition-delay: 150ms !important;
}

.builder-delay-200{
  transition-delay: 200ms !important;
}

.builder-delay-300{
  transition-delay: 300ms !important;
}

.builder-delay-500{
  transition-delay: 500ms !important;
}

.builder-delay-700{
  transition-delay: 700ms !important;
}

.builder-delay-1000{
  transition-delay: 1000ms !important;
}

.builder-duration-75{
  transition-duration: 75ms !important;
}

.builder-duration-100{
  transition-duration: 100ms !important;
}

.builder-duration-150{
  transition-duration: 150ms !important;
}

.builder-duration-200{
  transition-duration: 200ms !important;
}

.builder-duration-300{
  transition-duration: 300ms !important;
}

.builder-duration-500{
  transition-duration: 500ms !important;
}

.builder-duration-700{
  transition-duration: 700ms !important;
}

.builder-duration-1000{
  transition-duration: 1000ms !important;
}

.builder-ease-linear{
  transition-timing-function: linear !important;
}

.builder-ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.builder-ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.builder-ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.builder-builder-block-editor-container{
  font-family: proxima-nova !important;
}

body{
  font-family: proxima-nova !important;
}

@import "./editor.css";

.cards-drop-preview {
  background-color: rgba(214, 212, 212, 1);
  border: 1px dashed #abc;
}

.smooth-dnd-draggable-wrapper {
  overflow: visible !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 0px;
  padding-top: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring-black div {
  border-color: #000 transparent transparent transparent;
}

.lds-ring-white div {
  border-color: #fff transparent transparent transparent;
}

.changes-warning{
  transition: all 300ms linear;
}

.react-time-picker>div, .react-datetime-picker>div {
  border-color: #f5f5f5 !important;
  color: rgba(74, 85, 104, 1) !important;
}

.picker-error .react-time-picker>div, .picker-error .react-datetime-picker>div {
  border-color: #fc8181 !important;
  color: #e53e3e !important;
}

.react-time-picker__inputGroup, .react-datetime-picker__wrapper{
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  justify-content: center;
  padding: 4px !important;
  font-size: 15px !important;
}

.cls-1{
  fill:#d6b49a;
}

.cls-1,.cls-10,.cls-11,.cls-13,.cls-14,.cls-15,.cls-17,.cls-2,.cls-4,.cls-5,.cls-6{
  stroke:#000;
}

.cls-1,.cls-11,.cls-13,.cls-14,.cls-16,.cls-8{
  stroke-linecap:round;
  stroke-linejoin:round;
}

.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{
  stroke-width:3px;
}

.cls-2{
  fill:#020202;
}

.cls-10,.cls-12,.cls-15,.cls-17,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-9{
  stroke-miterlimit:10;
}

.cls-3{
  fill:#818181;
}

.cls-12,.cls-16,.cls-3,.cls-7,.cls-8,.cls-9{
  stroke:#191818;
}

.cls-4{
  fill:#dcdbda;
}

.cls-5{
  fill:#4ea7f1;
}

.cls-14,.cls-6{
  fill:#f8f3ed;
}

.cls-16,.cls-7{
  fill:#333;
}

.cls-13,.cls-8{
  fill:none;
}

.cls-9{
  fill:#f8f59c;
}

.cls-10,.cls-11{
  fill:#f3d2c9;
}

.cls-15{
  fill:#8bb174;
}

.cls-17{
  fill:#da4e22;
}

/*Picker V2*/

.rc-time-picker-input {
  border-color: #f5f5f5 !important;
  color: rgba(74, 85, 104, 1) !important;
  border-radius: 0 !important;
}

.rc-time-picker-clear{
  display: none !important;
}

@media (min-width: 640px){
  .sm\:builder-container{
    width: 100%;
  }

  @media (min-width: 640px){
    .sm\:builder-container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .sm\:builder-container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .sm\:builder-container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .sm\:builder-container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .sm\:builder-container{
      max-width: 1536px;
    }
  }

  .sm\:builder-sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:builder-not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:focus-within\:builder-sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:focus-within\:builder-not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:focus\:builder-sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:focus\:builder-not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:builder-pointer-events-none{
    pointer-events: none !important;
  }

  .sm\:builder-pointer-events-auto{
    pointer-events: auto !important;
  }

  .sm\:builder-visible{
    visibility: visible !important;
  }

  .sm\:builder-invisible{
    visibility: hidden !important;
  }

  .sm\:builder-static{
    position: static !important;
  }

  .sm\:builder-fixed{
    position: fixed !important;
  }

  .sm\:builder-absolute{
    position: absolute !important;
  }

  .sm\:builder-relative{
    position: relative !important;
  }

  .sm\:builder-sticky{
    position: sticky !important;
  }

  .sm\:builder-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .sm\:builder-inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .sm\:builder-inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .sm\:builder-inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .sm\:builder-inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .sm\:builder-inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .sm\:builder-inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .sm\:builder-inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .sm\:builder-inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .sm\:builder-inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .sm\:builder-inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .sm\:builder-inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .sm\:builder-inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .sm\:builder-inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .sm\:builder-inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .sm\:builder-inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .sm\:builder-inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .sm\:builder-inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .sm\:builder-inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .sm\:builder-inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .sm\:builder-inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .sm\:builder-inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .sm\:builder-inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .sm\:builder-inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .sm\:builder-inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .sm\:builder-inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .sm\:builder-inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .sm\:builder-inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .sm\:builder-inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .sm\:builder-inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .sm\:builder-inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .sm\:builder-inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .sm\:builder-inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .sm\:builder-inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .sm\:builder-inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .sm\:builder-inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .sm\:builder--inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .sm\:builder--inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .sm\:builder--inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .sm\:builder--inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .sm\:builder--inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .sm\:builder--inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .sm\:builder--inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .sm\:builder--inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .sm\:builder--inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .sm\:builder--inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .sm\:builder--inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .sm\:builder--inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .sm\:builder--inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .sm\:builder--inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .sm\:builder--inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .sm\:builder--inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .sm\:builder--inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .sm\:builder--inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .sm\:builder--inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .sm\:builder--inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .sm\:builder--inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .sm\:builder--inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .sm\:builder--inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .sm\:builder--inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .sm\:builder--inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .sm\:builder--inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .sm\:builder--inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .sm\:builder--inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .sm\:builder--inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .sm\:builder--inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .sm\:builder--inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .sm\:builder--inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .sm\:builder--inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .sm\:builder--inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .sm\:builder--inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .sm\:builder-inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .sm\:builder-inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .sm\:builder-inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .sm\:builder-inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .sm\:builder-inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .sm\:builder-inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .sm\:builder-inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .sm\:builder--inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .sm\:builder--inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .sm\:builder--inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .sm\:builder--inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .sm\:builder--inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .sm\:builder--inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .sm\:builder--inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .sm\:builder-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .sm\:builder-inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .sm\:builder-inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .sm\:builder-inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .sm\:builder-inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .sm\:builder-inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .sm\:builder-inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .sm\:builder-inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .sm\:builder-inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .sm\:builder-inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .sm\:builder-inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .sm\:builder-inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .sm\:builder-inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .sm\:builder-inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .sm\:builder-inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .sm\:builder-inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .sm\:builder-inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .sm\:builder-inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .sm\:builder-inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .sm\:builder-inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .sm\:builder-inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .sm\:builder-inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .sm\:builder-inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .sm\:builder-inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .sm\:builder-inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .sm\:builder-inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .sm\:builder-inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .sm\:builder-inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .sm\:builder-inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .sm\:builder-inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .sm\:builder-inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .sm\:builder-inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .sm\:builder-inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .sm\:builder-inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .sm\:builder-inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .sm\:builder-inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .sm\:builder--inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .sm\:builder--inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .sm\:builder--inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .sm\:builder--inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .sm\:builder--inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .sm\:builder--inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .sm\:builder--inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .sm\:builder--inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .sm\:builder--inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .sm\:builder--inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .sm\:builder--inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .sm\:builder--inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .sm\:builder--inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .sm\:builder--inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .sm\:builder--inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .sm\:builder--inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .sm\:builder--inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .sm\:builder--inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .sm\:builder--inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .sm\:builder--inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .sm\:builder--inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .sm\:builder--inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .sm\:builder--inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .sm\:builder--inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .sm\:builder--inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .sm\:builder--inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .sm\:builder--inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .sm\:builder--inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .sm\:builder--inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .sm\:builder--inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .sm\:builder--inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .sm\:builder--inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .sm\:builder--inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .sm\:builder--inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .sm\:builder--inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .sm\:builder-inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .sm\:builder-inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .sm\:builder-inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .sm\:builder-inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .sm\:builder-inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .sm\:builder-inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .sm\:builder-inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .sm\:builder--inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .sm\:builder--inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .sm\:builder--inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .sm\:builder--inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .sm\:builder--inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .sm\:builder--inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .sm\:builder--inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .sm\:builder-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .sm\:builder-inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .sm\:builder-inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .sm\:builder-inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .sm\:builder-inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .sm\:builder-inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .sm\:builder-inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .sm\:builder-inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .sm\:builder-inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .sm\:builder-inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .sm\:builder-inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .sm\:builder-inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .sm\:builder-inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .sm\:builder-inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .sm\:builder-inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .sm\:builder-inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .sm\:builder-inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .sm\:builder-inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .sm\:builder-inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .sm\:builder-inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .sm\:builder-inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .sm\:builder-inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .sm\:builder-inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .sm\:builder-inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .sm\:builder-inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .sm\:builder-inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .sm\:builder-inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .sm\:builder-inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .sm\:builder-inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .sm\:builder-inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .sm\:builder-inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .sm\:builder-inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .sm\:builder-inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .sm\:builder-inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .sm\:builder-inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .sm\:builder-inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .sm\:builder--inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .sm\:builder--inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .sm\:builder--inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .sm\:builder--inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .sm\:builder--inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .sm\:builder--inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .sm\:builder--inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .sm\:builder--inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .sm\:builder--inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .sm\:builder--inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .sm\:builder--inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .sm\:builder--inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .sm\:builder--inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .sm\:builder--inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .sm\:builder--inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .sm\:builder--inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .sm\:builder--inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .sm\:builder--inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .sm\:builder--inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .sm\:builder--inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .sm\:builder--inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .sm\:builder--inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .sm\:builder--inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .sm\:builder--inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .sm\:builder--inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .sm\:builder--inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .sm\:builder--inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .sm\:builder--inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .sm\:builder--inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .sm\:builder--inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .sm\:builder--inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .sm\:builder--inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .sm\:builder--inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .sm\:builder--inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .sm\:builder--inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .sm\:builder-inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .sm\:builder-inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .sm\:builder-inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .sm\:builder-inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .sm\:builder-inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .sm\:builder-inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .sm\:builder-inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .sm\:builder--inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .sm\:builder--inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .sm\:builder--inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .sm\:builder--inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .sm\:builder--inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .sm\:builder--inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .sm\:builder--inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .sm\:builder-top-0{
    top: 0px !important;
  }

  .sm\:builder-top-1{
    top: 0.25rem !important;
  }

  .sm\:builder-top-2{
    top: 0.5rem !important;
  }

  .sm\:builder-top-3{
    top: 0.75rem !important;
  }

  .sm\:builder-top-4{
    top: 1rem !important;
  }

  .sm\:builder-top-5{
    top: 1.25rem !important;
  }

  .sm\:builder-top-6{
    top: 1.5rem !important;
  }

  .sm\:builder-top-7{
    top: 1.75rem !important;
  }

  .sm\:builder-top-8{
    top: 2rem !important;
  }

  .sm\:builder-top-9{
    top: 2.25rem !important;
  }

  .sm\:builder-top-10{
    top: 2.5rem !important;
  }

  .sm\:builder-top-11{
    top: 2.75rem !important;
  }

  .sm\:builder-top-12{
    top: 3rem !important;
  }

  .sm\:builder-top-14{
    top: 3.5rem !important;
  }

  .sm\:builder-top-16{
    top: 4rem !important;
  }

  .sm\:builder-top-20{
    top: 5rem !important;
  }

  .sm\:builder-top-24{
    top: 6rem !important;
  }

  .sm\:builder-top-28{
    top: 7rem !important;
  }

  .sm\:builder-top-32{
    top: 8rem !important;
  }

  .sm\:builder-top-36{
    top: 9rem !important;
  }

  .sm\:builder-top-40{
    top: 10rem !important;
  }

  .sm\:builder-top-44{
    top: 11rem !important;
  }

  .sm\:builder-top-48{
    top: 12rem !important;
  }

  .sm\:builder-top-52{
    top: 13rem !important;
  }

  .sm\:builder-top-56{
    top: 14rem !important;
  }

  .sm\:builder-top-60{
    top: 15rem !important;
  }

  .sm\:builder-top-64{
    top: 16rem !important;
  }

  .sm\:builder-top-72{
    top: 18rem !important;
  }

  .sm\:builder-top-80{
    top: 20rem !important;
  }

  .sm\:builder-top-96{
    top: 24rem !important;
  }

  .sm\:builder-top-auto{
    top: auto !important;
  }

  .sm\:builder-top-px{
    top: 1px !important;
  }

  .sm\:builder-top-0\.5{
    top: 0.125rem !important;
  }

  .sm\:builder-top-1\.5{
    top: 0.375rem !important;
  }

  .sm\:builder-top-2\.5{
    top: 0.625rem !important;
  }

  .sm\:builder-top-3\.5{
    top: 0.875rem !important;
  }

  .sm\:builder--top-0{
    top: 0px !important;
  }

  .sm\:builder--top-1{
    top: -0.25rem !important;
  }

  .sm\:builder--top-2{
    top: -0.5rem !important;
  }

  .sm\:builder--top-3{
    top: -0.75rem !important;
  }

  .sm\:builder--top-4{
    top: -1rem !important;
  }

  .sm\:builder--top-5{
    top: -1.25rem !important;
  }

  .sm\:builder--top-6{
    top: -1.5rem !important;
  }

  .sm\:builder--top-7{
    top: -1.75rem !important;
  }

  .sm\:builder--top-8{
    top: -2rem !important;
  }

  .sm\:builder--top-9{
    top: -2.25rem !important;
  }

  .sm\:builder--top-10{
    top: -2.5rem !important;
  }

  .sm\:builder--top-11{
    top: -2.75rem !important;
  }

  .sm\:builder--top-12{
    top: -3rem !important;
  }

  .sm\:builder--top-14{
    top: -3.5rem !important;
  }

  .sm\:builder--top-16{
    top: -4rem !important;
  }

  .sm\:builder--top-20{
    top: -5rem !important;
  }

  .sm\:builder--top-24{
    top: -6rem !important;
  }

  .sm\:builder--top-28{
    top: -7rem !important;
  }

  .sm\:builder--top-32{
    top: -8rem !important;
  }

  .sm\:builder--top-36{
    top: -9rem !important;
  }

  .sm\:builder--top-40{
    top: -10rem !important;
  }

  .sm\:builder--top-44{
    top: -11rem !important;
  }

  .sm\:builder--top-48{
    top: -12rem !important;
  }

  .sm\:builder--top-52{
    top: -13rem !important;
  }

  .sm\:builder--top-56{
    top: -14rem !important;
  }

  .sm\:builder--top-60{
    top: -15rem !important;
  }

  .sm\:builder--top-64{
    top: -16rem !important;
  }

  .sm\:builder--top-72{
    top: -18rem !important;
  }

  .sm\:builder--top-80{
    top: -20rem !important;
  }

  .sm\:builder--top-96{
    top: -24rem !important;
  }

  .sm\:builder--top-px{
    top: -1px !important;
  }

  .sm\:builder--top-0\.5{
    top: -0.125rem !important;
  }

  .sm\:builder--top-1\.5{
    top: -0.375rem !important;
  }

  .sm\:builder--top-2\.5{
    top: -0.625rem !important;
  }

  .sm\:builder--top-3\.5{
    top: -0.875rem !important;
  }

  .sm\:builder-top-1\/2{
    top: 50% !important;
  }

  .sm\:builder-top-1\/3{
    top: 33.333333% !important;
  }

  .sm\:builder-top-2\/3{
    top: 66.666667% !important;
  }

  .sm\:builder-top-1\/4{
    top: 25% !important;
  }

  .sm\:builder-top-2\/4{
    top: 50% !important;
  }

  .sm\:builder-top-3\/4{
    top: 75% !important;
  }

  .sm\:builder-top-full{
    top: 100% !important;
  }

  .sm\:builder--top-1\/2{
    top: -50% !important;
  }

  .sm\:builder--top-1\/3{
    top: -33.333333% !important;
  }

  .sm\:builder--top-2\/3{
    top: -66.666667% !important;
  }

  .sm\:builder--top-1\/4{
    top: -25% !important;
  }

  .sm\:builder--top-2\/4{
    top: -50% !important;
  }

  .sm\:builder--top-3\/4{
    top: -75% !important;
  }

  .sm\:builder--top-full{
    top: -100% !important;
  }

  .sm\:builder-right-0{
    right: 0px !important;
  }

  .sm\:builder-right-1{
    right: 0.25rem !important;
  }

  .sm\:builder-right-2{
    right: 0.5rem !important;
  }

  .sm\:builder-right-3{
    right: 0.75rem !important;
  }

  .sm\:builder-right-4{
    right: 1rem !important;
  }

  .sm\:builder-right-5{
    right: 1.25rem !important;
  }

  .sm\:builder-right-6{
    right: 1.5rem !important;
  }

  .sm\:builder-right-7{
    right: 1.75rem !important;
  }

  .sm\:builder-right-8{
    right: 2rem !important;
  }

  .sm\:builder-right-9{
    right: 2.25rem !important;
  }

  .sm\:builder-right-10{
    right: 2.5rem !important;
  }

  .sm\:builder-right-11{
    right: 2.75rem !important;
  }

  .sm\:builder-right-12{
    right: 3rem !important;
  }

  .sm\:builder-right-14{
    right: 3.5rem !important;
  }

  .sm\:builder-right-16{
    right: 4rem !important;
  }

  .sm\:builder-right-20{
    right: 5rem !important;
  }

  .sm\:builder-right-24{
    right: 6rem !important;
  }

  .sm\:builder-right-28{
    right: 7rem !important;
  }

  .sm\:builder-right-32{
    right: 8rem !important;
  }

  .sm\:builder-right-36{
    right: 9rem !important;
  }

  .sm\:builder-right-40{
    right: 10rem !important;
  }

  .sm\:builder-right-44{
    right: 11rem !important;
  }

  .sm\:builder-right-48{
    right: 12rem !important;
  }

  .sm\:builder-right-52{
    right: 13rem !important;
  }

  .sm\:builder-right-56{
    right: 14rem !important;
  }

  .sm\:builder-right-60{
    right: 15rem !important;
  }

  .sm\:builder-right-64{
    right: 16rem !important;
  }

  .sm\:builder-right-72{
    right: 18rem !important;
  }

  .sm\:builder-right-80{
    right: 20rem !important;
  }

  .sm\:builder-right-96{
    right: 24rem !important;
  }

  .sm\:builder-right-auto{
    right: auto !important;
  }

  .sm\:builder-right-px{
    right: 1px !important;
  }

  .sm\:builder-right-0\.5{
    right: 0.125rem !important;
  }

  .sm\:builder-right-1\.5{
    right: 0.375rem !important;
  }

  .sm\:builder-right-2\.5{
    right: 0.625rem !important;
  }

  .sm\:builder-right-3\.5{
    right: 0.875rem !important;
  }

  .sm\:builder--right-0{
    right: 0px !important;
  }

  .sm\:builder--right-1{
    right: -0.25rem !important;
  }

  .sm\:builder--right-2{
    right: -0.5rem !important;
  }

  .sm\:builder--right-3{
    right: -0.75rem !important;
  }

  .sm\:builder--right-4{
    right: -1rem !important;
  }

  .sm\:builder--right-5{
    right: -1.25rem !important;
  }

  .sm\:builder--right-6{
    right: -1.5rem !important;
  }

  .sm\:builder--right-7{
    right: -1.75rem !important;
  }

  .sm\:builder--right-8{
    right: -2rem !important;
  }

  .sm\:builder--right-9{
    right: -2.25rem !important;
  }

  .sm\:builder--right-10{
    right: -2.5rem !important;
  }

  .sm\:builder--right-11{
    right: -2.75rem !important;
  }

  .sm\:builder--right-12{
    right: -3rem !important;
  }

  .sm\:builder--right-14{
    right: -3.5rem !important;
  }

  .sm\:builder--right-16{
    right: -4rem !important;
  }

  .sm\:builder--right-20{
    right: -5rem !important;
  }

  .sm\:builder--right-24{
    right: -6rem !important;
  }

  .sm\:builder--right-28{
    right: -7rem !important;
  }

  .sm\:builder--right-32{
    right: -8rem !important;
  }

  .sm\:builder--right-36{
    right: -9rem !important;
  }

  .sm\:builder--right-40{
    right: -10rem !important;
  }

  .sm\:builder--right-44{
    right: -11rem !important;
  }

  .sm\:builder--right-48{
    right: -12rem !important;
  }

  .sm\:builder--right-52{
    right: -13rem !important;
  }

  .sm\:builder--right-56{
    right: -14rem !important;
  }

  .sm\:builder--right-60{
    right: -15rem !important;
  }

  .sm\:builder--right-64{
    right: -16rem !important;
  }

  .sm\:builder--right-72{
    right: -18rem !important;
  }

  .sm\:builder--right-80{
    right: -20rem !important;
  }

  .sm\:builder--right-96{
    right: -24rem !important;
  }

  .sm\:builder--right-px{
    right: -1px !important;
  }

  .sm\:builder--right-0\.5{
    right: -0.125rem !important;
  }

  .sm\:builder--right-1\.5{
    right: -0.375rem !important;
  }

  .sm\:builder--right-2\.5{
    right: -0.625rem !important;
  }

  .sm\:builder--right-3\.5{
    right: -0.875rem !important;
  }

  .sm\:builder-right-1\/2{
    right: 50% !important;
  }

  .sm\:builder-right-1\/3{
    right: 33.333333% !important;
  }

  .sm\:builder-right-2\/3{
    right: 66.666667% !important;
  }

  .sm\:builder-right-1\/4{
    right: 25% !important;
  }

  .sm\:builder-right-2\/4{
    right: 50% !important;
  }

  .sm\:builder-right-3\/4{
    right: 75% !important;
  }

  .sm\:builder-right-full{
    right: 100% !important;
  }

  .sm\:builder--right-1\/2{
    right: -50% !important;
  }

  .sm\:builder--right-1\/3{
    right: -33.333333% !important;
  }

  .sm\:builder--right-2\/3{
    right: -66.666667% !important;
  }

  .sm\:builder--right-1\/4{
    right: -25% !important;
  }

  .sm\:builder--right-2\/4{
    right: -50% !important;
  }

  .sm\:builder--right-3\/4{
    right: -75% !important;
  }

  .sm\:builder--right-full{
    right: -100% !important;
  }

  .sm\:builder-bottom-0{
    bottom: 0px !important;
  }

  .sm\:builder-bottom-1{
    bottom: 0.25rem !important;
  }

  .sm\:builder-bottom-2{
    bottom: 0.5rem !important;
  }

  .sm\:builder-bottom-3{
    bottom: 0.75rem !important;
  }

  .sm\:builder-bottom-4{
    bottom: 1rem !important;
  }

  .sm\:builder-bottom-5{
    bottom: 1.25rem !important;
  }

  .sm\:builder-bottom-6{
    bottom: 1.5rem !important;
  }

  .sm\:builder-bottom-7{
    bottom: 1.75rem !important;
  }

  .sm\:builder-bottom-8{
    bottom: 2rem !important;
  }

  .sm\:builder-bottom-9{
    bottom: 2.25rem !important;
  }

  .sm\:builder-bottom-10{
    bottom: 2.5rem !important;
  }

  .sm\:builder-bottom-11{
    bottom: 2.75rem !important;
  }

  .sm\:builder-bottom-12{
    bottom: 3rem !important;
  }

  .sm\:builder-bottom-14{
    bottom: 3.5rem !important;
  }

  .sm\:builder-bottom-16{
    bottom: 4rem !important;
  }

  .sm\:builder-bottom-20{
    bottom: 5rem !important;
  }

  .sm\:builder-bottom-24{
    bottom: 6rem !important;
  }

  .sm\:builder-bottom-28{
    bottom: 7rem !important;
  }

  .sm\:builder-bottom-32{
    bottom: 8rem !important;
  }

  .sm\:builder-bottom-36{
    bottom: 9rem !important;
  }

  .sm\:builder-bottom-40{
    bottom: 10rem !important;
  }

  .sm\:builder-bottom-44{
    bottom: 11rem !important;
  }

  .sm\:builder-bottom-48{
    bottom: 12rem !important;
  }

  .sm\:builder-bottom-52{
    bottom: 13rem !important;
  }

  .sm\:builder-bottom-56{
    bottom: 14rem !important;
  }

  .sm\:builder-bottom-60{
    bottom: 15rem !important;
  }

  .sm\:builder-bottom-64{
    bottom: 16rem !important;
  }

  .sm\:builder-bottom-72{
    bottom: 18rem !important;
  }

  .sm\:builder-bottom-80{
    bottom: 20rem !important;
  }

  .sm\:builder-bottom-96{
    bottom: 24rem !important;
  }

  .sm\:builder-bottom-auto{
    bottom: auto !important;
  }

  .sm\:builder-bottom-px{
    bottom: 1px !important;
  }

  .sm\:builder-bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .sm\:builder-bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .sm\:builder-bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .sm\:builder-bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .sm\:builder--bottom-0{
    bottom: 0px !important;
  }

  .sm\:builder--bottom-1{
    bottom: -0.25rem !important;
  }

  .sm\:builder--bottom-2{
    bottom: -0.5rem !important;
  }

  .sm\:builder--bottom-3{
    bottom: -0.75rem !important;
  }

  .sm\:builder--bottom-4{
    bottom: -1rem !important;
  }

  .sm\:builder--bottom-5{
    bottom: -1.25rem !important;
  }

  .sm\:builder--bottom-6{
    bottom: -1.5rem !important;
  }

  .sm\:builder--bottom-7{
    bottom: -1.75rem !important;
  }

  .sm\:builder--bottom-8{
    bottom: -2rem !important;
  }

  .sm\:builder--bottom-9{
    bottom: -2.25rem !important;
  }

  .sm\:builder--bottom-10{
    bottom: -2.5rem !important;
  }

  .sm\:builder--bottom-11{
    bottom: -2.75rem !important;
  }

  .sm\:builder--bottom-12{
    bottom: -3rem !important;
  }

  .sm\:builder--bottom-14{
    bottom: -3.5rem !important;
  }

  .sm\:builder--bottom-16{
    bottom: -4rem !important;
  }

  .sm\:builder--bottom-20{
    bottom: -5rem !important;
  }

  .sm\:builder--bottom-24{
    bottom: -6rem !important;
  }

  .sm\:builder--bottom-28{
    bottom: -7rem !important;
  }

  .sm\:builder--bottom-32{
    bottom: -8rem !important;
  }

  .sm\:builder--bottom-36{
    bottom: -9rem !important;
  }

  .sm\:builder--bottom-40{
    bottom: -10rem !important;
  }

  .sm\:builder--bottom-44{
    bottom: -11rem !important;
  }

  .sm\:builder--bottom-48{
    bottom: -12rem !important;
  }

  .sm\:builder--bottom-52{
    bottom: -13rem !important;
  }

  .sm\:builder--bottom-56{
    bottom: -14rem !important;
  }

  .sm\:builder--bottom-60{
    bottom: -15rem !important;
  }

  .sm\:builder--bottom-64{
    bottom: -16rem !important;
  }

  .sm\:builder--bottom-72{
    bottom: -18rem !important;
  }

  .sm\:builder--bottom-80{
    bottom: -20rem !important;
  }

  .sm\:builder--bottom-96{
    bottom: -24rem !important;
  }

  .sm\:builder--bottom-px{
    bottom: -1px !important;
  }

  .sm\:builder--bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .sm\:builder--bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .sm\:builder--bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .sm\:builder--bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .sm\:builder-bottom-1\/2{
    bottom: 50% !important;
  }

  .sm\:builder-bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .sm\:builder-bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .sm\:builder-bottom-1\/4{
    bottom: 25% !important;
  }

  .sm\:builder-bottom-2\/4{
    bottom: 50% !important;
  }

  .sm\:builder-bottom-3\/4{
    bottom: 75% !important;
  }

  .sm\:builder-bottom-full{
    bottom: 100% !important;
  }

  .sm\:builder--bottom-1\/2{
    bottom: -50% !important;
  }

  .sm\:builder--bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .sm\:builder--bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .sm\:builder--bottom-1\/4{
    bottom: -25% !important;
  }

  .sm\:builder--bottom-2\/4{
    bottom: -50% !important;
  }

  .sm\:builder--bottom-3\/4{
    bottom: -75% !important;
  }

  .sm\:builder--bottom-full{
    bottom: -100% !important;
  }

  .sm\:builder-left-0{
    left: 0px !important;
  }

  .sm\:builder-left-1{
    left: 0.25rem !important;
  }

  .sm\:builder-left-2{
    left: 0.5rem !important;
  }

  .sm\:builder-left-3{
    left: 0.75rem !important;
  }

  .sm\:builder-left-4{
    left: 1rem !important;
  }

  .sm\:builder-left-5{
    left: 1.25rem !important;
  }

  .sm\:builder-left-6{
    left: 1.5rem !important;
  }

  .sm\:builder-left-7{
    left: 1.75rem !important;
  }

  .sm\:builder-left-8{
    left: 2rem !important;
  }

  .sm\:builder-left-9{
    left: 2.25rem !important;
  }

  .sm\:builder-left-10{
    left: 2.5rem !important;
  }

  .sm\:builder-left-11{
    left: 2.75rem !important;
  }

  .sm\:builder-left-12{
    left: 3rem !important;
  }

  .sm\:builder-left-14{
    left: 3.5rem !important;
  }

  .sm\:builder-left-16{
    left: 4rem !important;
  }

  .sm\:builder-left-20{
    left: 5rem !important;
  }

  .sm\:builder-left-24{
    left: 6rem !important;
  }

  .sm\:builder-left-28{
    left: 7rem !important;
  }

  .sm\:builder-left-32{
    left: 8rem !important;
  }

  .sm\:builder-left-36{
    left: 9rem !important;
  }

  .sm\:builder-left-40{
    left: 10rem !important;
  }

  .sm\:builder-left-44{
    left: 11rem !important;
  }

  .sm\:builder-left-48{
    left: 12rem !important;
  }

  .sm\:builder-left-52{
    left: 13rem !important;
  }

  .sm\:builder-left-56{
    left: 14rem !important;
  }

  .sm\:builder-left-60{
    left: 15rem !important;
  }

  .sm\:builder-left-64{
    left: 16rem !important;
  }

  .sm\:builder-left-72{
    left: 18rem !important;
  }

  .sm\:builder-left-80{
    left: 20rem !important;
  }

  .sm\:builder-left-96{
    left: 24rem !important;
  }

  .sm\:builder-left-auto{
    left: auto !important;
  }

  .sm\:builder-left-px{
    left: 1px !important;
  }

  .sm\:builder-left-0\.5{
    left: 0.125rem !important;
  }

  .sm\:builder-left-1\.5{
    left: 0.375rem !important;
  }

  .sm\:builder-left-2\.5{
    left: 0.625rem !important;
  }

  .sm\:builder-left-3\.5{
    left: 0.875rem !important;
  }

  .sm\:builder--left-0{
    left: 0px !important;
  }

  .sm\:builder--left-1{
    left: -0.25rem !important;
  }

  .sm\:builder--left-2{
    left: -0.5rem !important;
  }

  .sm\:builder--left-3{
    left: -0.75rem !important;
  }

  .sm\:builder--left-4{
    left: -1rem !important;
  }

  .sm\:builder--left-5{
    left: -1.25rem !important;
  }

  .sm\:builder--left-6{
    left: -1.5rem !important;
  }

  .sm\:builder--left-7{
    left: -1.75rem !important;
  }

  .sm\:builder--left-8{
    left: -2rem !important;
  }

  .sm\:builder--left-9{
    left: -2.25rem !important;
  }

  .sm\:builder--left-10{
    left: -2.5rem !important;
  }

  .sm\:builder--left-11{
    left: -2.75rem !important;
  }

  .sm\:builder--left-12{
    left: -3rem !important;
  }

  .sm\:builder--left-14{
    left: -3.5rem !important;
  }

  .sm\:builder--left-16{
    left: -4rem !important;
  }

  .sm\:builder--left-20{
    left: -5rem !important;
  }

  .sm\:builder--left-24{
    left: -6rem !important;
  }

  .sm\:builder--left-28{
    left: -7rem !important;
  }

  .sm\:builder--left-32{
    left: -8rem !important;
  }

  .sm\:builder--left-36{
    left: -9rem !important;
  }

  .sm\:builder--left-40{
    left: -10rem !important;
  }

  .sm\:builder--left-44{
    left: -11rem !important;
  }

  .sm\:builder--left-48{
    left: -12rem !important;
  }

  .sm\:builder--left-52{
    left: -13rem !important;
  }

  .sm\:builder--left-56{
    left: -14rem !important;
  }

  .sm\:builder--left-60{
    left: -15rem !important;
  }

  .sm\:builder--left-64{
    left: -16rem !important;
  }

  .sm\:builder--left-72{
    left: -18rem !important;
  }

  .sm\:builder--left-80{
    left: -20rem !important;
  }

  .sm\:builder--left-96{
    left: -24rem !important;
  }

  .sm\:builder--left-px{
    left: -1px !important;
  }

  .sm\:builder--left-0\.5{
    left: -0.125rem !important;
  }

  .sm\:builder--left-1\.5{
    left: -0.375rem !important;
  }

  .sm\:builder--left-2\.5{
    left: -0.625rem !important;
  }

  .sm\:builder--left-3\.5{
    left: -0.875rem !important;
  }

  .sm\:builder-left-1\/2{
    left: 50% !important;
  }

  .sm\:builder-left-1\/3{
    left: 33.333333% !important;
  }

  .sm\:builder-left-2\/3{
    left: 66.666667% !important;
  }

  .sm\:builder-left-1\/4{
    left: 25% !important;
  }

  .sm\:builder-left-2\/4{
    left: 50% !important;
  }

  .sm\:builder-left-3\/4{
    left: 75% !important;
  }

  .sm\:builder-left-full{
    left: 100% !important;
  }

  .sm\:builder--left-1\/2{
    left: -50% !important;
  }

  .sm\:builder--left-1\/3{
    left: -33.333333% !important;
  }

  .sm\:builder--left-2\/3{
    left: -66.666667% !important;
  }

  .sm\:builder--left-1\/4{
    left: -25% !important;
  }

  .sm\:builder--left-2\/4{
    left: -50% !important;
  }

  .sm\:builder--left-3\/4{
    left: -75% !important;
  }

  .sm\:builder--left-full{
    left: -100% !important;
  }

  .sm\:builder-isolate{
    isolation: isolate !important;
  }

  .sm\:builder-isolation-auto{
    isolation: auto !important;
  }

  .sm\:builder-z-0{
    z-index: 0 !important;
  }

  .sm\:builder-z-10{
    z-index: 10 !important;
  }

  .sm\:builder-z-20{
    z-index: 20 !important;
  }

  .sm\:builder-z-30{
    z-index: 30 !important;
  }

  .sm\:builder-z-40{
    z-index: 40 !important;
  }

  .sm\:builder-z-50{
    z-index: 50 !important;
  }

  .sm\:builder-z-auto{
    z-index: auto !important;
  }

  .sm\:focus-within\:builder-z-0:focus-within{
    z-index: 0 !important;
  }

  .sm\:focus-within\:builder-z-10:focus-within{
    z-index: 10 !important;
  }

  .sm\:focus-within\:builder-z-20:focus-within{
    z-index: 20 !important;
  }

  .sm\:focus-within\:builder-z-30:focus-within{
    z-index: 30 !important;
  }

  .sm\:focus-within\:builder-z-40:focus-within{
    z-index: 40 !important;
  }

  .sm\:focus-within\:builder-z-50:focus-within{
    z-index: 50 !important;
  }

  .sm\:focus-within\:builder-z-auto:focus-within{
    z-index: auto !important;
  }

  .sm\:focus\:builder-z-0:focus{
    z-index: 0 !important;
  }

  .sm\:focus\:builder-z-10:focus{
    z-index: 10 !important;
  }

  .sm\:focus\:builder-z-20:focus{
    z-index: 20 !important;
  }

  .sm\:focus\:builder-z-30:focus{
    z-index: 30 !important;
  }

  .sm\:focus\:builder-z-40:focus{
    z-index: 40 !important;
  }

  .sm\:focus\:builder-z-50:focus{
    z-index: 50 !important;
  }

  .sm\:focus\:builder-z-auto:focus{
    z-index: auto !important;
  }

  .sm\:builder-order-1{
    order: 1 !important;
  }

  .sm\:builder-order-2{
    order: 2 !important;
  }

  .sm\:builder-order-3{
    order: 3 !important;
  }

  .sm\:builder-order-4{
    order: 4 !important;
  }

  .sm\:builder-order-5{
    order: 5 !important;
  }

  .sm\:builder-order-6{
    order: 6 !important;
  }

  .sm\:builder-order-7{
    order: 7 !important;
  }

  .sm\:builder-order-8{
    order: 8 !important;
  }

  .sm\:builder-order-9{
    order: 9 !important;
  }

  .sm\:builder-order-10{
    order: 10 !important;
  }

  .sm\:builder-order-11{
    order: 11 !important;
  }

  .sm\:builder-order-12{
    order: 12 !important;
  }

  .sm\:builder-order-first{
    order: -9999 !important;
  }

  .sm\:builder-order-last{
    order: 9999 !important;
  }

  .sm\:builder-order-none{
    order: 0 !important;
  }

  .sm\:builder-col-auto{
    grid-column: auto !important;
  }

  .sm\:builder-col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .sm\:builder-col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .sm\:builder-col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .sm\:builder-col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .sm\:builder-col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .sm\:builder-col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .sm\:builder-col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .sm\:builder-col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .sm\:builder-col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .sm\:builder-col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .sm\:builder-col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .sm\:builder-col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .sm\:builder-col-span-full{
    grid-column: 1 / -1 !important;
  }

  .sm\:builder-col-start-1{
    grid-column-start: 1 !important;
  }

  .sm\:builder-col-start-2{
    grid-column-start: 2 !important;
  }

  .sm\:builder-col-start-3{
    grid-column-start: 3 !important;
  }

  .sm\:builder-col-start-4{
    grid-column-start: 4 !important;
  }

  .sm\:builder-col-start-5{
    grid-column-start: 5 !important;
  }

  .sm\:builder-col-start-6{
    grid-column-start: 6 !important;
  }

  .sm\:builder-col-start-7{
    grid-column-start: 7 !important;
  }

  .sm\:builder-col-start-8{
    grid-column-start: 8 !important;
  }

  .sm\:builder-col-start-9{
    grid-column-start: 9 !important;
  }

  .sm\:builder-col-start-10{
    grid-column-start: 10 !important;
  }

  .sm\:builder-col-start-11{
    grid-column-start: 11 !important;
  }

  .sm\:builder-col-start-12{
    grid-column-start: 12 !important;
  }

  .sm\:builder-col-start-13{
    grid-column-start: 13 !important;
  }

  .sm\:builder-col-start-auto{
    grid-column-start: auto !important;
  }

  .sm\:builder-col-end-1{
    grid-column-end: 1 !important;
  }

  .sm\:builder-col-end-2{
    grid-column-end: 2 !important;
  }

  .sm\:builder-col-end-3{
    grid-column-end: 3 !important;
  }

  .sm\:builder-col-end-4{
    grid-column-end: 4 !important;
  }

  .sm\:builder-col-end-5{
    grid-column-end: 5 !important;
  }

  .sm\:builder-col-end-6{
    grid-column-end: 6 !important;
  }

  .sm\:builder-col-end-7{
    grid-column-end: 7 !important;
  }

  .sm\:builder-col-end-8{
    grid-column-end: 8 !important;
  }

  .sm\:builder-col-end-9{
    grid-column-end: 9 !important;
  }

  .sm\:builder-col-end-10{
    grid-column-end: 10 !important;
  }

  .sm\:builder-col-end-11{
    grid-column-end: 11 !important;
  }

  .sm\:builder-col-end-12{
    grid-column-end: 12 !important;
  }

  .sm\:builder-col-end-13{
    grid-column-end: 13 !important;
  }

  .sm\:builder-col-end-auto{
    grid-column-end: auto !important;
  }

  .sm\:builder-row-auto{
    grid-row: auto !important;
  }

  .sm\:builder-row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .sm\:builder-row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .sm\:builder-row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .sm\:builder-row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .sm\:builder-row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .sm\:builder-row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .sm\:builder-row-span-full{
    grid-row: 1 / -1 !important;
  }

  .sm\:builder-row-start-1{
    grid-row-start: 1 !important;
  }

  .sm\:builder-row-start-2{
    grid-row-start: 2 !important;
  }

  .sm\:builder-row-start-3{
    grid-row-start: 3 !important;
  }

  .sm\:builder-row-start-4{
    grid-row-start: 4 !important;
  }

  .sm\:builder-row-start-5{
    grid-row-start: 5 !important;
  }

  .sm\:builder-row-start-6{
    grid-row-start: 6 !important;
  }

  .sm\:builder-row-start-7{
    grid-row-start: 7 !important;
  }

  .sm\:builder-row-start-auto{
    grid-row-start: auto !important;
  }

  .sm\:builder-row-end-1{
    grid-row-end: 1 !important;
  }

  .sm\:builder-row-end-2{
    grid-row-end: 2 !important;
  }

  .sm\:builder-row-end-3{
    grid-row-end: 3 !important;
  }

  .sm\:builder-row-end-4{
    grid-row-end: 4 !important;
  }

  .sm\:builder-row-end-5{
    grid-row-end: 5 !important;
  }

  .sm\:builder-row-end-6{
    grid-row-end: 6 !important;
  }

  .sm\:builder-row-end-7{
    grid-row-end: 7 !important;
  }

  .sm\:builder-row-end-auto{
    grid-row-end: auto !important;
  }

  .sm\:builder-float-right{
    float: right !important;
  }

  .sm\:builder-float-left{
    float: left !important;
  }

  .sm\:builder-float-none{
    float: none !important;
  }

  .sm\:builder-clear-left{
    clear: left !important;
  }

  .sm\:builder-clear-right{
    clear: right !important;
  }

  .sm\:builder-clear-both{
    clear: both !important;
  }

  .sm\:builder-clear-none{
    clear: none !important;
  }

  .sm\:builder-m-0{
    margin: 0px !important;
  }

  .sm\:builder-m-1{
    margin: 0.25rem !important;
  }

  .sm\:builder-m-2{
    margin: 0.5rem !important;
  }

  .sm\:builder-m-3{
    margin: 0.75rem !important;
  }

  .sm\:builder-m-4{
    margin: 1rem !important;
  }

  .sm\:builder-m-5{
    margin: 1.25rem !important;
  }

  .sm\:builder-m-6{
    margin: 1.5rem !important;
  }

  .sm\:builder-m-7{
    margin: 1.75rem !important;
  }

  .sm\:builder-m-8{
    margin: 2rem !important;
  }

  .sm\:builder-m-9{
    margin: 2.25rem !important;
  }

  .sm\:builder-m-10{
    margin: 2.5rem !important;
  }

  .sm\:builder-m-11{
    margin: 2.75rem !important;
  }

  .sm\:builder-m-12{
    margin: 3rem !important;
  }

  .sm\:builder-m-14{
    margin: 3.5rem !important;
  }

  .sm\:builder-m-16{
    margin: 4rem !important;
  }

  .sm\:builder-m-20{
    margin: 5rem !important;
  }

  .sm\:builder-m-24{
    margin: 6rem !important;
  }

  .sm\:builder-m-28{
    margin: 7rem !important;
  }

  .sm\:builder-m-32{
    margin: 8rem !important;
  }

  .sm\:builder-m-36{
    margin: 9rem !important;
  }

  .sm\:builder-m-40{
    margin: 10rem !important;
  }

  .sm\:builder-m-44{
    margin: 11rem !important;
  }

  .sm\:builder-m-48{
    margin: 12rem !important;
  }

  .sm\:builder-m-52{
    margin: 13rem !important;
  }

  .sm\:builder-m-56{
    margin: 14rem !important;
  }

  .sm\:builder-m-60{
    margin: 15rem !important;
  }

  .sm\:builder-m-64{
    margin: 16rem !important;
  }

  .sm\:builder-m-72{
    margin: 18rem !important;
  }

  .sm\:builder-m-80{
    margin: 20rem !important;
  }

  .sm\:builder-m-96{
    margin: 24rem !important;
  }

  .sm\:builder-m-auto{
    margin: auto !important;
  }

  .sm\:builder-m-px{
    margin: 1px !important;
  }

  .sm\:builder-m-0\.5{
    margin: 0.125rem !important;
  }

  .sm\:builder-m-1\.5{
    margin: 0.375rem !important;
  }

  .sm\:builder-m-2\.5{
    margin: 0.625rem !important;
  }

  .sm\:builder-m-3\.5{
    margin: 0.875rem !important;
  }

  .sm\:builder--m-0{
    margin: 0px !important;
  }

  .sm\:builder--m-1{
    margin: -0.25rem !important;
  }

  .sm\:builder--m-2{
    margin: -0.5rem !important;
  }

  .sm\:builder--m-3{
    margin: -0.75rem !important;
  }

  .sm\:builder--m-4{
    margin: -1rem !important;
  }

  .sm\:builder--m-5{
    margin: -1.25rem !important;
  }

  .sm\:builder--m-6{
    margin: -1.5rem !important;
  }

  .sm\:builder--m-7{
    margin: -1.75rem !important;
  }

  .sm\:builder--m-8{
    margin: -2rem !important;
  }

  .sm\:builder--m-9{
    margin: -2.25rem !important;
  }

  .sm\:builder--m-10{
    margin: -2.5rem !important;
  }

  .sm\:builder--m-11{
    margin: -2.75rem !important;
  }

  .sm\:builder--m-12{
    margin: -3rem !important;
  }

  .sm\:builder--m-14{
    margin: -3.5rem !important;
  }

  .sm\:builder--m-16{
    margin: -4rem !important;
  }

  .sm\:builder--m-20{
    margin: -5rem !important;
  }

  .sm\:builder--m-24{
    margin: -6rem !important;
  }

  .sm\:builder--m-28{
    margin: -7rem !important;
  }

  .sm\:builder--m-32{
    margin: -8rem !important;
  }

  .sm\:builder--m-36{
    margin: -9rem !important;
  }

  .sm\:builder--m-40{
    margin: -10rem !important;
  }

  .sm\:builder--m-44{
    margin: -11rem !important;
  }

  .sm\:builder--m-48{
    margin: -12rem !important;
  }

  .sm\:builder--m-52{
    margin: -13rem !important;
  }

  .sm\:builder--m-56{
    margin: -14rem !important;
  }

  .sm\:builder--m-60{
    margin: -15rem !important;
  }

  .sm\:builder--m-64{
    margin: -16rem !important;
  }

  .sm\:builder--m-72{
    margin: -18rem !important;
  }

  .sm\:builder--m-80{
    margin: -20rem !important;
  }

  .sm\:builder--m-96{
    margin: -24rem !important;
  }

  .sm\:builder--m-px{
    margin: -1px !important;
  }

  .sm\:builder--m-0\.5{
    margin: -0.125rem !important;
  }

  .sm\:builder--m-1\.5{
    margin: -0.375rem !important;
  }

  .sm\:builder--m-2\.5{
    margin: -0.625rem !important;
  }

  .sm\:builder--m-3\.5{
    margin: -0.875rem !important;
  }

  .sm\:builder-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sm\:builder-mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .sm\:builder-mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:builder-mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .sm\:builder-mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:builder-mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .sm\:builder-mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:builder-mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .sm\:builder-mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:builder-mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .sm\:builder-mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .sm\:builder-mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .sm\:builder-mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:builder-mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .sm\:builder-mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:builder-mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:builder-mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .sm\:builder-mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .sm\:builder-mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .sm\:builder-mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .sm\:builder-mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .sm\:builder-mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .sm\:builder-mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .sm\:builder-mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .sm\:builder-mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .sm\:builder-mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .sm\:builder-mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .sm\:builder-mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .sm\:builder-mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .sm\:builder-mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .sm\:builder-mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:builder-mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .sm\:builder-mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .sm\:builder-mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .sm\:builder-mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .sm\:builder-mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .sm\:builder--mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sm\:builder--mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .sm\:builder--mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .sm\:builder--mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .sm\:builder--mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:builder--mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .sm\:builder--mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:builder--mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .sm\:builder--mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:builder--mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .sm\:builder--mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .sm\:builder--mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .sm\:builder--mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:builder--mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .sm\:builder--mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:builder--mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:builder--mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .sm\:builder--mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .sm\:builder--mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .sm\:builder--mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .sm\:builder--mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .sm\:builder--mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .sm\:builder--mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .sm\:builder--mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .sm\:builder--mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .sm\:builder--mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .sm\:builder--mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .sm\:builder--mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .sm\:builder--mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .sm\:builder--mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .sm\:builder--mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .sm\:builder--mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .sm\:builder--mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .sm\:builder--mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .sm\:builder--mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .sm\:builder-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm\:builder-my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .sm\:builder-my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sm\:builder-my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .sm\:builder-my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:builder-my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .sm\:builder-my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:builder-my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .sm\:builder-my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:builder-my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .sm\:builder-my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .sm\:builder-my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .sm\:builder-my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:builder-my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .sm\:builder-my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:builder-my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:builder-my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .sm\:builder-my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .sm\:builder-my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .sm\:builder-my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .sm\:builder-my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .sm\:builder-my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .sm\:builder-my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .sm\:builder-my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .sm\:builder-my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .sm\:builder-my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .sm\:builder-my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .sm\:builder-my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .sm\:builder-my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .sm\:builder-my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .sm\:builder-my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sm\:builder-my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .sm\:builder-my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .sm\:builder-my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .sm\:builder-my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .sm\:builder-my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .sm\:builder--my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm\:builder--my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .sm\:builder--my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .sm\:builder--my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .sm\:builder--my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:builder--my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .sm\:builder--my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:builder--my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .sm\:builder--my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:builder--my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .sm\:builder--my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .sm\:builder--my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .sm\:builder--my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:builder--my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .sm\:builder--my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:builder--my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:builder--my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .sm\:builder--my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .sm\:builder--my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .sm\:builder--my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .sm\:builder--my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .sm\:builder--my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .sm\:builder--my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .sm\:builder--my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .sm\:builder--my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .sm\:builder--my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .sm\:builder--my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .sm\:builder--my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .sm\:builder--my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .sm\:builder--my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .sm\:builder--my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .sm\:builder--my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .sm\:builder--my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .sm\:builder--my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .sm\:builder--my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .sm\:builder-mt-0{
    margin-top: 0px !important;
  }

  .sm\:builder-mt-1{
    margin-top: 0.25rem !important;
  }

  .sm\:builder-mt-2{
    margin-top: 0.5rem !important;
  }

  .sm\:builder-mt-3{
    margin-top: 0.75rem !important;
  }

  .sm\:builder-mt-4{
    margin-top: 1rem !important;
  }

  .sm\:builder-mt-5{
    margin-top: 1.25rem !important;
  }

  .sm\:builder-mt-6{
    margin-top: 1.5rem !important;
  }

  .sm\:builder-mt-7{
    margin-top: 1.75rem !important;
  }

  .sm\:builder-mt-8{
    margin-top: 2rem !important;
  }

  .sm\:builder-mt-9{
    margin-top: 2.25rem !important;
  }

  .sm\:builder-mt-10{
    margin-top: 2.5rem !important;
  }

  .sm\:builder-mt-11{
    margin-top: 2.75rem !important;
  }

  .sm\:builder-mt-12{
    margin-top: 3rem !important;
  }

  .sm\:builder-mt-14{
    margin-top: 3.5rem !important;
  }

  .sm\:builder-mt-16{
    margin-top: 4rem !important;
  }

  .sm\:builder-mt-20{
    margin-top: 5rem !important;
  }

  .sm\:builder-mt-24{
    margin-top: 6rem !important;
  }

  .sm\:builder-mt-28{
    margin-top: 7rem !important;
  }

  .sm\:builder-mt-32{
    margin-top: 8rem !important;
  }

  .sm\:builder-mt-36{
    margin-top: 9rem !important;
  }

  .sm\:builder-mt-40{
    margin-top: 10rem !important;
  }

  .sm\:builder-mt-44{
    margin-top: 11rem !important;
  }

  .sm\:builder-mt-48{
    margin-top: 12rem !important;
  }

  .sm\:builder-mt-52{
    margin-top: 13rem !important;
  }

  .sm\:builder-mt-56{
    margin-top: 14rem !important;
  }

  .sm\:builder-mt-60{
    margin-top: 15rem !important;
  }

  .sm\:builder-mt-64{
    margin-top: 16rem !important;
  }

  .sm\:builder-mt-72{
    margin-top: 18rem !important;
  }

  .sm\:builder-mt-80{
    margin-top: 20rem !important;
  }

  .sm\:builder-mt-96{
    margin-top: 24rem !important;
  }

  .sm\:builder-mt-auto{
    margin-top: auto !important;
  }

  .sm\:builder-mt-px{
    margin-top: 1px !important;
  }

  .sm\:builder-mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .sm\:builder-mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .sm\:builder-mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .sm\:builder-mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .sm\:builder--mt-0{
    margin-top: 0px !important;
  }

  .sm\:builder--mt-1{
    margin-top: -0.25rem !important;
  }

  .sm\:builder--mt-2{
    margin-top: -0.5rem !important;
  }

  .sm\:builder--mt-3{
    margin-top: -0.75rem !important;
  }

  .sm\:builder--mt-4{
    margin-top: -1rem !important;
  }

  .sm\:builder--mt-5{
    margin-top: -1.25rem !important;
  }

  .sm\:builder--mt-6{
    margin-top: -1.5rem !important;
  }

  .sm\:builder--mt-7{
    margin-top: -1.75rem !important;
  }

  .sm\:builder--mt-8{
    margin-top: -2rem !important;
  }

  .sm\:builder--mt-9{
    margin-top: -2.25rem !important;
  }

  .sm\:builder--mt-10{
    margin-top: -2.5rem !important;
  }

  .sm\:builder--mt-11{
    margin-top: -2.75rem !important;
  }

  .sm\:builder--mt-12{
    margin-top: -3rem !important;
  }

  .sm\:builder--mt-14{
    margin-top: -3.5rem !important;
  }

  .sm\:builder--mt-16{
    margin-top: -4rem !important;
  }

  .sm\:builder--mt-20{
    margin-top: -5rem !important;
  }

  .sm\:builder--mt-24{
    margin-top: -6rem !important;
  }

  .sm\:builder--mt-28{
    margin-top: -7rem !important;
  }

  .sm\:builder--mt-32{
    margin-top: -8rem !important;
  }

  .sm\:builder--mt-36{
    margin-top: -9rem !important;
  }

  .sm\:builder--mt-40{
    margin-top: -10rem !important;
  }

  .sm\:builder--mt-44{
    margin-top: -11rem !important;
  }

  .sm\:builder--mt-48{
    margin-top: -12rem !important;
  }

  .sm\:builder--mt-52{
    margin-top: -13rem !important;
  }

  .sm\:builder--mt-56{
    margin-top: -14rem !important;
  }

  .sm\:builder--mt-60{
    margin-top: -15rem !important;
  }

  .sm\:builder--mt-64{
    margin-top: -16rem !important;
  }

  .sm\:builder--mt-72{
    margin-top: -18rem !important;
  }

  .sm\:builder--mt-80{
    margin-top: -20rem !important;
  }

  .sm\:builder--mt-96{
    margin-top: -24rem !important;
  }

  .sm\:builder--mt-px{
    margin-top: -1px !important;
  }

  .sm\:builder--mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .sm\:builder--mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .sm\:builder--mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .sm\:builder--mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .sm\:builder-mr-0{
    margin-right: 0px !important;
  }

  .sm\:builder-mr-1{
    margin-right: 0.25rem !important;
  }

  .sm\:builder-mr-2{
    margin-right: 0.5rem !important;
  }

  .sm\:builder-mr-3{
    margin-right: 0.75rem !important;
  }

  .sm\:builder-mr-4{
    margin-right: 1rem !important;
  }

  .sm\:builder-mr-5{
    margin-right: 1.25rem !important;
  }

  .sm\:builder-mr-6{
    margin-right: 1.5rem !important;
  }

  .sm\:builder-mr-7{
    margin-right: 1.75rem !important;
  }

  .sm\:builder-mr-8{
    margin-right: 2rem !important;
  }

  .sm\:builder-mr-9{
    margin-right: 2.25rem !important;
  }

  .sm\:builder-mr-10{
    margin-right: 2.5rem !important;
  }

  .sm\:builder-mr-11{
    margin-right: 2.75rem !important;
  }

  .sm\:builder-mr-12{
    margin-right: 3rem !important;
  }

  .sm\:builder-mr-14{
    margin-right: 3.5rem !important;
  }

  .sm\:builder-mr-16{
    margin-right: 4rem !important;
  }

  .sm\:builder-mr-20{
    margin-right: 5rem !important;
  }

  .sm\:builder-mr-24{
    margin-right: 6rem !important;
  }

  .sm\:builder-mr-28{
    margin-right: 7rem !important;
  }

  .sm\:builder-mr-32{
    margin-right: 8rem !important;
  }

  .sm\:builder-mr-36{
    margin-right: 9rem !important;
  }

  .sm\:builder-mr-40{
    margin-right: 10rem !important;
  }

  .sm\:builder-mr-44{
    margin-right: 11rem !important;
  }

  .sm\:builder-mr-48{
    margin-right: 12rem !important;
  }

  .sm\:builder-mr-52{
    margin-right: 13rem !important;
  }

  .sm\:builder-mr-56{
    margin-right: 14rem !important;
  }

  .sm\:builder-mr-60{
    margin-right: 15rem !important;
  }

  .sm\:builder-mr-64{
    margin-right: 16rem !important;
  }

  .sm\:builder-mr-72{
    margin-right: 18rem !important;
  }

  .sm\:builder-mr-80{
    margin-right: 20rem !important;
  }

  .sm\:builder-mr-96{
    margin-right: 24rem !important;
  }

  .sm\:builder-mr-auto{
    margin-right: auto !important;
  }

  .sm\:builder-mr-px{
    margin-right: 1px !important;
  }

  .sm\:builder-mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .sm\:builder-mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .sm\:builder-mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .sm\:builder-mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .sm\:builder--mr-0{
    margin-right: 0px !important;
  }

  .sm\:builder--mr-1{
    margin-right: -0.25rem !important;
  }

  .sm\:builder--mr-2{
    margin-right: -0.5rem !important;
  }

  .sm\:builder--mr-3{
    margin-right: -0.75rem !important;
  }

  .sm\:builder--mr-4{
    margin-right: -1rem !important;
  }

  .sm\:builder--mr-5{
    margin-right: -1.25rem !important;
  }

  .sm\:builder--mr-6{
    margin-right: -1.5rem !important;
  }

  .sm\:builder--mr-7{
    margin-right: -1.75rem !important;
  }

  .sm\:builder--mr-8{
    margin-right: -2rem !important;
  }

  .sm\:builder--mr-9{
    margin-right: -2.25rem !important;
  }

  .sm\:builder--mr-10{
    margin-right: -2.5rem !important;
  }

  .sm\:builder--mr-11{
    margin-right: -2.75rem !important;
  }

  .sm\:builder--mr-12{
    margin-right: -3rem !important;
  }

  .sm\:builder--mr-14{
    margin-right: -3.5rem !important;
  }

  .sm\:builder--mr-16{
    margin-right: -4rem !important;
  }

  .sm\:builder--mr-20{
    margin-right: -5rem !important;
  }

  .sm\:builder--mr-24{
    margin-right: -6rem !important;
  }

  .sm\:builder--mr-28{
    margin-right: -7rem !important;
  }

  .sm\:builder--mr-32{
    margin-right: -8rem !important;
  }

  .sm\:builder--mr-36{
    margin-right: -9rem !important;
  }

  .sm\:builder--mr-40{
    margin-right: -10rem !important;
  }

  .sm\:builder--mr-44{
    margin-right: -11rem !important;
  }

  .sm\:builder--mr-48{
    margin-right: -12rem !important;
  }

  .sm\:builder--mr-52{
    margin-right: -13rem !important;
  }

  .sm\:builder--mr-56{
    margin-right: -14rem !important;
  }

  .sm\:builder--mr-60{
    margin-right: -15rem !important;
  }

  .sm\:builder--mr-64{
    margin-right: -16rem !important;
  }

  .sm\:builder--mr-72{
    margin-right: -18rem !important;
  }

  .sm\:builder--mr-80{
    margin-right: -20rem !important;
  }

  .sm\:builder--mr-96{
    margin-right: -24rem !important;
  }

  .sm\:builder--mr-px{
    margin-right: -1px !important;
  }

  .sm\:builder--mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .sm\:builder--mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .sm\:builder--mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .sm\:builder--mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .sm\:builder-mb-0{
    margin-bottom: 0px !important;
  }

  .sm\:builder-mb-1{
    margin-bottom: 0.25rem !important;
  }

  .sm\:builder-mb-2{
    margin-bottom: 0.5rem !important;
  }

  .sm\:builder-mb-3{
    margin-bottom: 0.75rem !important;
  }

  .sm\:builder-mb-4{
    margin-bottom: 1rem !important;
  }

  .sm\:builder-mb-5{
    margin-bottom: 1.25rem !important;
  }

  .sm\:builder-mb-6{
    margin-bottom: 1.5rem !important;
  }

  .sm\:builder-mb-7{
    margin-bottom: 1.75rem !important;
  }

  .sm\:builder-mb-8{
    margin-bottom: 2rem !important;
  }

  .sm\:builder-mb-9{
    margin-bottom: 2.25rem !important;
  }

  .sm\:builder-mb-10{
    margin-bottom: 2.5rem !important;
  }

  .sm\:builder-mb-11{
    margin-bottom: 2.75rem !important;
  }

  .sm\:builder-mb-12{
    margin-bottom: 3rem !important;
  }

  .sm\:builder-mb-14{
    margin-bottom: 3.5rem !important;
  }

  .sm\:builder-mb-16{
    margin-bottom: 4rem !important;
  }

  .sm\:builder-mb-20{
    margin-bottom: 5rem !important;
  }

  .sm\:builder-mb-24{
    margin-bottom: 6rem !important;
  }

  .sm\:builder-mb-28{
    margin-bottom: 7rem !important;
  }

  .sm\:builder-mb-32{
    margin-bottom: 8rem !important;
  }

  .sm\:builder-mb-36{
    margin-bottom: 9rem !important;
  }

  .sm\:builder-mb-40{
    margin-bottom: 10rem !important;
  }

  .sm\:builder-mb-44{
    margin-bottom: 11rem !important;
  }

  .sm\:builder-mb-48{
    margin-bottom: 12rem !important;
  }

  .sm\:builder-mb-52{
    margin-bottom: 13rem !important;
  }

  .sm\:builder-mb-56{
    margin-bottom: 14rem !important;
  }

  .sm\:builder-mb-60{
    margin-bottom: 15rem !important;
  }

  .sm\:builder-mb-64{
    margin-bottom: 16rem !important;
  }

  .sm\:builder-mb-72{
    margin-bottom: 18rem !important;
  }

  .sm\:builder-mb-80{
    margin-bottom: 20rem !important;
  }

  .sm\:builder-mb-96{
    margin-bottom: 24rem !important;
  }

  .sm\:builder-mb-auto{
    margin-bottom: auto !important;
  }

  .sm\:builder-mb-px{
    margin-bottom: 1px !important;
  }

  .sm\:builder-mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .sm\:builder-mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .sm\:builder-mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .sm\:builder-mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .sm\:builder--mb-0{
    margin-bottom: 0px !important;
  }

  .sm\:builder--mb-1{
    margin-bottom: -0.25rem !important;
  }

  .sm\:builder--mb-2{
    margin-bottom: -0.5rem !important;
  }

  .sm\:builder--mb-3{
    margin-bottom: -0.75rem !important;
  }

  .sm\:builder--mb-4{
    margin-bottom: -1rem !important;
  }

  .sm\:builder--mb-5{
    margin-bottom: -1.25rem !important;
  }

  .sm\:builder--mb-6{
    margin-bottom: -1.5rem !important;
  }

  .sm\:builder--mb-7{
    margin-bottom: -1.75rem !important;
  }

  .sm\:builder--mb-8{
    margin-bottom: -2rem !important;
  }

  .sm\:builder--mb-9{
    margin-bottom: -2.25rem !important;
  }

  .sm\:builder--mb-10{
    margin-bottom: -2.5rem !important;
  }

  .sm\:builder--mb-11{
    margin-bottom: -2.75rem !important;
  }

  .sm\:builder--mb-12{
    margin-bottom: -3rem !important;
  }

  .sm\:builder--mb-14{
    margin-bottom: -3.5rem !important;
  }

  .sm\:builder--mb-16{
    margin-bottom: -4rem !important;
  }

  .sm\:builder--mb-20{
    margin-bottom: -5rem !important;
  }

  .sm\:builder--mb-24{
    margin-bottom: -6rem !important;
  }

  .sm\:builder--mb-28{
    margin-bottom: -7rem !important;
  }

  .sm\:builder--mb-32{
    margin-bottom: -8rem !important;
  }

  .sm\:builder--mb-36{
    margin-bottom: -9rem !important;
  }

  .sm\:builder--mb-40{
    margin-bottom: -10rem !important;
  }

  .sm\:builder--mb-44{
    margin-bottom: -11rem !important;
  }

  .sm\:builder--mb-48{
    margin-bottom: -12rem !important;
  }

  .sm\:builder--mb-52{
    margin-bottom: -13rem !important;
  }

  .sm\:builder--mb-56{
    margin-bottom: -14rem !important;
  }

  .sm\:builder--mb-60{
    margin-bottom: -15rem !important;
  }

  .sm\:builder--mb-64{
    margin-bottom: -16rem !important;
  }

  .sm\:builder--mb-72{
    margin-bottom: -18rem !important;
  }

  .sm\:builder--mb-80{
    margin-bottom: -20rem !important;
  }

  .sm\:builder--mb-96{
    margin-bottom: -24rem !important;
  }

  .sm\:builder--mb-px{
    margin-bottom: -1px !important;
  }

  .sm\:builder--mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .sm\:builder--mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .sm\:builder--mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .sm\:builder--mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .sm\:builder-ml-0{
    margin-left: 0px !important;
  }

  .sm\:builder-ml-1{
    margin-left: 0.25rem !important;
  }

  .sm\:builder-ml-2{
    margin-left: 0.5rem !important;
  }

  .sm\:builder-ml-3{
    margin-left: 0.75rem !important;
  }

  .sm\:builder-ml-4{
    margin-left: 1rem !important;
  }

  .sm\:builder-ml-5{
    margin-left: 1.25rem !important;
  }

  .sm\:builder-ml-6{
    margin-left: 1.5rem !important;
  }

  .sm\:builder-ml-7{
    margin-left: 1.75rem !important;
  }

  .sm\:builder-ml-8{
    margin-left: 2rem !important;
  }

  .sm\:builder-ml-9{
    margin-left: 2.25rem !important;
  }

  .sm\:builder-ml-10{
    margin-left: 2.5rem !important;
  }

  .sm\:builder-ml-11{
    margin-left: 2.75rem !important;
  }

  .sm\:builder-ml-12{
    margin-left: 3rem !important;
  }

  .sm\:builder-ml-14{
    margin-left: 3.5rem !important;
  }

  .sm\:builder-ml-16{
    margin-left: 4rem !important;
  }

  .sm\:builder-ml-20{
    margin-left: 5rem !important;
  }

  .sm\:builder-ml-24{
    margin-left: 6rem !important;
  }

  .sm\:builder-ml-28{
    margin-left: 7rem !important;
  }

  .sm\:builder-ml-32{
    margin-left: 8rem !important;
  }

  .sm\:builder-ml-36{
    margin-left: 9rem !important;
  }

  .sm\:builder-ml-40{
    margin-left: 10rem !important;
  }

  .sm\:builder-ml-44{
    margin-left: 11rem !important;
  }

  .sm\:builder-ml-48{
    margin-left: 12rem !important;
  }

  .sm\:builder-ml-52{
    margin-left: 13rem !important;
  }

  .sm\:builder-ml-56{
    margin-left: 14rem !important;
  }

  .sm\:builder-ml-60{
    margin-left: 15rem !important;
  }

  .sm\:builder-ml-64{
    margin-left: 16rem !important;
  }

  .sm\:builder-ml-72{
    margin-left: 18rem !important;
  }

  .sm\:builder-ml-80{
    margin-left: 20rem !important;
  }

  .sm\:builder-ml-96{
    margin-left: 24rem !important;
  }

  .sm\:builder-ml-auto{
    margin-left: auto !important;
  }

  .sm\:builder-ml-px{
    margin-left: 1px !important;
  }

  .sm\:builder-ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .sm\:builder-ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .sm\:builder-ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .sm\:builder-ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .sm\:builder--ml-0{
    margin-left: 0px !important;
  }

  .sm\:builder--ml-1{
    margin-left: -0.25rem !important;
  }

  .sm\:builder--ml-2{
    margin-left: -0.5rem !important;
  }

  .sm\:builder--ml-3{
    margin-left: -0.75rem !important;
  }

  .sm\:builder--ml-4{
    margin-left: -1rem !important;
  }

  .sm\:builder--ml-5{
    margin-left: -1.25rem !important;
  }

  .sm\:builder--ml-6{
    margin-left: -1.5rem !important;
  }

  .sm\:builder--ml-7{
    margin-left: -1.75rem !important;
  }

  .sm\:builder--ml-8{
    margin-left: -2rem !important;
  }

  .sm\:builder--ml-9{
    margin-left: -2.25rem !important;
  }

  .sm\:builder--ml-10{
    margin-left: -2.5rem !important;
  }

  .sm\:builder--ml-11{
    margin-left: -2.75rem !important;
  }

  .sm\:builder--ml-12{
    margin-left: -3rem !important;
  }

  .sm\:builder--ml-14{
    margin-left: -3.5rem !important;
  }

  .sm\:builder--ml-16{
    margin-left: -4rem !important;
  }

  .sm\:builder--ml-20{
    margin-left: -5rem !important;
  }

  .sm\:builder--ml-24{
    margin-left: -6rem !important;
  }

  .sm\:builder--ml-28{
    margin-left: -7rem !important;
  }

  .sm\:builder--ml-32{
    margin-left: -8rem !important;
  }

  .sm\:builder--ml-36{
    margin-left: -9rem !important;
  }

  .sm\:builder--ml-40{
    margin-left: -10rem !important;
  }

  .sm\:builder--ml-44{
    margin-left: -11rem !important;
  }

  .sm\:builder--ml-48{
    margin-left: -12rem !important;
  }

  .sm\:builder--ml-52{
    margin-left: -13rem !important;
  }

  .sm\:builder--ml-56{
    margin-left: -14rem !important;
  }

  .sm\:builder--ml-60{
    margin-left: -15rem !important;
  }

  .sm\:builder--ml-64{
    margin-left: -16rem !important;
  }

  .sm\:builder--ml-72{
    margin-left: -18rem !important;
  }

  .sm\:builder--ml-80{
    margin-left: -20rem !important;
  }

  .sm\:builder--ml-96{
    margin-left: -24rem !important;
  }

  .sm\:builder--ml-px{
    margin-left: -1px !important;
  }

  .sm\:builder--ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .sm\:builder--ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .sm\:builder--ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .sm\:builder--ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .sm\:builder-box-border{
    box-sizing: border-box !important;
  }

  .sm\:builder-box-content{
    box-sizing: content-box !important;
  }

  .sm\:builder-block{
    display: block !important;
  }

  .sm\:builder-inline-block{
    display: inline-block !important;
  }

  .sm\:builder-inline{
    display: inline !important;
  }

  .sm\:builder-flex{
    display: flex !important;
  }

  .sm\:builder-inline-flex{
    display: inline-flex !important;
  }

  .sm\:builder-table{
    display: table !important;
  }

  .sm\:builder-inline-table{
    display: inline-table !important;
  }

  .sm\:builder-table-caption{
    display: table-caption !important;
  }

  .sm\:builder-table-cell{
    display: table-cell !important;
  }

  .sm\:builder-table-column{
    display: table-column !important;
  }

  .sm\:builder-table-column-group{
    display: table-column-group !important;
  }

  .sm\:builder-table-footer-group{
    display: table-footer-group !important;
  }

  .sm\:builder-table-header-group{
    display: table-header-group !important;
  }

  .sm\:builder-table-row-group{
    display: table-row-group !important;
  }

  .sm\:builder-table-row{
    display: table-row !important;
  }

  .sm\:builder-flow-root{
    display: flow-root !important;
  }

  .sm\:builder-grid{
    display: grid !important;
  }

  .sm\:builder-inline-grid{
    display: inline-grid !important;
  }

  .sm\:builder-contents{
    display: contents !important;
  }

  .sm\:builder-list-item{
    display: list-item !important;
  }

  .sm\:builder-hidden{
    display: none !important;
  }

  .sm\:builder-h-0{
    height: 0px !important;
  }

  .sm\:builder-h-1{
    height: 0.25rem !important;
  }

  .sm\:builder-h-2{
    height: 0.5rem !important;
  }

  .sm\:builder-h-3{
    height: 0.75rem !important;
  }

  .sm\:builder-h-4{
    height: 1rem !important;
  }

  .sm\:builder-h-5{
    height: 1.25rem !important;
  }

  .sm\:builder-h-6{
    height: 1.5rem !important;
  }

  .sm\:builder-h-7{
    height: 1.75rem !important;
  }

  .sm\:builder-h-8{
    height: 2rem !important;
  }

  .sm\:builder-h-9{
    height: 2.25rem !important;
  }

  .sm\:builder-h-10{
    height: 2.5rem !important;
  }

  .sm\:builder-h-11{
    height: 2.75rem !important;
  }

  .sm\:builder-h-12{
    height: 3rem !important;
  }

  .sm\:builder-h-14{
    height: 3.5rem !important;
  }

  .sm\:builder-h-16{
    height: 4rem !important;
  }

  .sm\:builder-h-20{
    height: 5rem !important;
  }

  .sm\:builder-h-24{
    height: 6rem !important;
  }

  .sm\:builder-h-28{
    height: 7rem !important;
  }

  .sm\:builder-h-32{
    height: 8rem !important;
  }

  .sm\:builder-h-36{
    height: 9rem !important;
  }

  .sm\:builder-h-40{
    height: 10rem !important;
  }

  .sm\:builder-h-44{
    height: 11rem !important;
  }

  .sm\:builder-h-48{
    height: 12rem !important;
  }

  .sm\:builder-h-52{
    height: 13rem !important;
  }

  .sm\:builder-h-56{
    height: 14rem !important;
  }

  .sm\:builder-h-60{
    height: 15rem !important;
  }

  .sm\:builder-h-64{
    height: 16rem !important;
  }

  .sm\:builder-h-72{
    height: 18rem !important;
  }

  .sm\:builder-h-80{
    height: 20rem !important;
  }

  .sm\:builder-h-96{
    height: 24rem !important;
  }

  .sm\:builder-h-auto{
    height: auto !important;
  }

  .sm\:builder-h-px{
    height: 1px !important;
  }

  .sm\:builder-h-0\.5{
    height: 0.125rem !important;
  }

  .sm\:builder-h-1\.5{
    height: 0.375rem !important;
  }

  .sm\:builder-h-2\.5{
    height: 0.625rem !important;
  }

  .sm\:builder-h-3\.5{
    height: 0.875rem !important;
  }

  .sm\:builder-h-1\/2{
    height: 50% !important;
  }

  .sm\:builder-h-1\/3{
    height: 33.333333% !important;
  }

  .sm\:builder-h-2\/3{
    height: 66.666667% !important;
  }

  .sm\:builder-h-1\/4{
    height: 25% !important;
  }

  .sm\:builder-h-2\/4{
    height: 50% !important;
  }

  .sm\:builder-h-3\/4{
    height: 75% !important;
  }

  .sm\:builder-h-1\/5{
    height: 20% !important;
  }

  .sm\:builder-h-2\/5{
    height: 40% !important;
  }

  .sm\:builder-h-3\/5{
    height: 60% !important;
  }

  .sm\:builder-h-4\/5{
    height: 80% !important;
  }

  .sm\:builder-h-1\/6{
    height: 16.666667% !important;
  }

  .sm\:builder-h-2\/6{
    height: 33.333333% !important;
  }

  .sm\:builder-h-3\/6{
    height: 50% !important;
  }

  .sm\:builder-h-4\/6{
    height: 66.666667% !important;
  }

  .sm\:builder-h-5\/6{
    height: 83.333333% !important;
  }

  .sm\:builder-h-full{
    height: 100% !important;
  }

  .sm\:builder-h-screen{
    height: 100vh !important;
  }

  .sm\:builder-max-h-0{
    max-height: 0px !important;
  }

  .sm\:builder-max-h-1{
    max-height: 0.25rem !important;
  }

  .sm\:builder-max-h-2{
    max-height: 0.5rem !important;
  }

  .sm\:builder-max-h-3{
    max-height: 0.75rem !important;
  }

  .sm\:builder-max-h-4{
    max-height: 1rem !important;
  }

  .sm\:builder-max-h-5{
    max-height: 1.25rem !important;
  }

  .sm\:builder-max-h-6{
    max-height: 1.5rem !important;
  }

  .sm\:builder-max-h-7{
    max-height: 1.75rem !important;
  }

  .sm\:builder-max-h-8{
    max-height: 2rem !important;
  }

  .sm\:builder-max-h-9{
    max-height: 2.25rem !important;
  }

  .sm\:builder-max-h-10{
    max-height: 2.5rem !important;
  }

  .sm\:builder-max-h-11{
    max-height: 2.75rem !important;
  }

  .sm\:builder-max-h-12{
    max-height: 3rem !important;
  }

  .sm\:builder-max-h-14{
    max-height: 3.5rem !important;
  }

  .sm\:builder-max-h-16{
    max-height: 4rem !important;
  }

  .sm\:builder-max-h-20{
    max-height: 5rem !important;
  }

  .sm\:builder-max-h-24{
    max-height: 6rem !important;
  }

  .sm\:builder-max-h-28{
    max-height: 7rem !important;
  }

  .sm\:builder-max-h-32{
    max-height: 8rem !important;
  }

  .sm\:builder-max-h-36{
    max-height: 9rem !important;
  }

  .sm\:builder-max-h-40{
    max-height: 10rem !important;
  }

  .sm\:builder-max-h-44{
    max-height: 11rem !important;
  }

  .sm\:builder-max-h-48{
    max-height: 12rem !important;
  }

  .sm\:builder-max-h-52{
    max-height: 13rem !important;
  }

  .sm\:builder-max-h-56{
    max-height: 14rem !important;
  }

  .sm\:builder-max-h-60{
    max-height: 15rem !important;
  }

  .sm\:builder-max-h-64{
    max-height: 16rem !important;
  }

  .sm\:builder-max-h-72{
    max-height: 18rem !important;
  }

  .sm\:builder-max-h-80{
    max-height: 20rem !important;
  }

  .sm\:builder-max-h-96{
    max-height: 24rem !important;
  }

  .sm\:builder-max-h-px{
    max-height: 1px !important;
  }

  .sm\:builder-max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .sm\:builder-max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .sm\:builder-max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .sm\:builder-max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .sm\:builder-max-h-full{
    max-height: 100% !important;
  }

  .sm\:builder-max-h-screen{
    max-height: 100vh !important;
  }

  .sm\:builder-min-h-0{
    min-height: 0px !important;
  }

  .sm\:builder-min-h-full{
    min-height: 100% !important;
  }

  .sm\:builder-min-h-screen{
    min-height: 100vh !important;
  }

  .sm\:builder-w-0{
    width: 0px !important;
  }

  .sm\:builder-w-1{
    width: 0.25rem !important;
  }

  .sm\:builder-w-2{
    width: 0.5rem !important;
  }

  .sm\:builder-w-3{
    width: 0.75rem !important;
  }

  .sm\:builder-w-4{
    width: 1rem !important;
  }

  .sm\:builder-w-5{
    width: 1.25rem !important;
  }

  .sm\:builder-w-6{
    width: 1.5rem !important;
  }

  .sm\:builder-w-7{
    width: 1.75rem !important;
  }

  .sm\:builder-w-8{
    width: 2rem !important;
  }

  .sm\:builder-w-9{
    width: 2.25rem !important;
  }

  .sm\:builder-w-10{
    width: 2.5rem !important;
  }

  .sm\:builder-w-11{
    width: 2.75rem !important;
  }

  .sm\:builder-w-12{
    width: 3rem !important;
  }

  .sm\:builder-w-14{
    width: 3.5rem !important;
  }

  .sm\:builder-w-16{
    width: 4rem !important;
  }

  .sm\:builder-w-20{
    width: 5rem !important;
  }

  .sm\:builder-w-24{
    width: 6rem !important;
  }

  .sm\:builder-w-28{
    width: 7rem !important;
  }

  .sm\:builder-w-32{
    width: 8rem !important;
  }

  .sm\:builder-w-36{
    width: 9rem !important;
  }

  .sm\:builder-w-40{
    width: 10rem !important;
  }

  .sm\:builder-w-44{
    width: 11rem !important;
  }

  .sm\:builder-w-48{
    width: 12rem !important;
  }

  .sm\:builder-w-52{
    width: 13rem !important;
  }

  .sm\:builder-w-56{
    width: 14rem !important;
  }

  .sm\:builder-w-60{
    width: 15rem !important;
  }

  .sm\:builder-w-64{
    width: 16rem !important;
  }

  .sm\:builder-w-72{
    width: 18rem !important;
  }

  .sm\:builder-w-80{
    width: 20rem !important;
  }

  .sm\:builder-w-96{
    width: 24rem !important;
  }

  .sm\:builder-w-auto{
    width: auto !important;
  }

  .sm\:builder-w-px{
    width: 1px !important;
  }

  .sm\:builder-w-0\.5{
    width: 0.125rem !important;
  }

  .sm\:builder-w-1\.5{
    width: 0.375rem !important;
  }

  .sm\:builder-w-2\.5{
    width: 0.625rem !important;
  }

  .sm\:builder-w-3\.5{
    width: 0.875rem !important;
  }

  .sm\:builder-w-1\/2{
    width: 50% !important;
  }

  .sm\:builder-w-1\/3{
    width: 33.333333% !important;
  }

  .sm\:builder-w-2\/3{
    width: 66.666667% !important;
  }

  .sm\:builder-w-1\/4{
    width: 25% !important;
  }

  .sm\:builder-w-2\/4{
    width: 50% !important;
  }

  .sm\:builder-w-3\/4{
    width: 75% !important;
  }

  .sm\:builder-w-1\/5{
    width: 20% !important;
  }

  .sm\:builder-w-2\/5{
    width: 40% !important;
  }

  .sm\:builder-w-3\/5{
    width: 60% !important;
  }

  .sm\:builder-w-4\/5{
    width: 80% !important;
  }

  .sm\:builder-w-1\/6{
    width: 16.666667% !important;
  }

  .sm\:builder-w-2\/6{
    width: 33.333333% !important;
  }

  .sm\:builder-w-3\/6{
    width: 50% !important;
  }

  .sm\:builder-w-4\/6{
    width: 66.666667% !important;
  }

  .sm\:builder-w-5\/6{
    width: 83.333333% !important;
  }

  .sm\:builder-w-1\/12{
    width: 8.333333% !important;
  }

  .sm\:builder-w-2\/12{
    width: 16.666667% !important;
  }

  .sm\:builder-w-3\/12{
    width: 25% !important;
  }

  .sm\:builder-w-4\/12{
    width: 33.333333% !important;
  }

  .sm\:builder-w-5\/12{
    width: 41.666667% !important;
  }

  .sm\:builder-w-6\/12{
    width: 50% !important;
  }

  .sm\:builder-w-7\/12{
    width: 58.333333% !important;
  }

  .sm\:builder-w-8\/12{
    width: 66.666667% !important;
  }

  .sm\:builder-w-9\/12{
    width: 75% !important;
  }

  .sm\:builder-w-10\/12{
    width: 83.333333% !important;
  }

  .sm\:builder-w-11\/12{
    width: 91.666667% !important;
  }

  .sm\:builder-w-full{
    width: 100% !important;
  }

  .sm\:builder-w-screen{
    width: 100vw !important;
  }

  .sm\:builder-w-min{
    width: min-content !important;
  }

  .sm\:builder-w-max{
    width: max-content !important;
  }

  .sm\:builder-min-w-0{
    min-width: 0px !important;
  }

  .sm\:builder-min-w-full{
    min-width: 100% !important;
  }

  .sm\:builder-min-w-min{
    min-width: min-content !important;
  }

  .sm\:builder-min-w-max{
    min-width: max-content !important;
  }

  .sm\:builder-max-w-0{
    max-width: 0rem !important;
  }

  .sm\:builder-max-w-none{
    max-width: none !important;
  }

  .sm\:builder-max-w-xs{
    max-width: 20rem !important;
  }

  .sm\:builder-max-w-sm{
    max-width: 24rem !important;
  }

  .sm\:builder-max-w-md{
    max-width: 28rem !important;
  }

  .sm\:builder-max-w-lg{
    max-width: 32rem !important;
  }

  .sm\:builder-max-w-xl{
    max-width: 36rem !important;
  }

  .sm\:builder-max-w-2xl{
    max-width: 42rem !important;
  }

  .sm\:builder-max-w-3xl{
    max-width: 48rem !important;
  }

  .sm\:builder-max-w-4xl{
    max-width: 56rem !important;
  }

  .sm\:builder-max-w-5xl{
    max-width: 64rem !important;
  }

  .sm\:builder-max-w-6xl{
    max-width: 72rem !important;
  }

  .sm\:builder-max-w-7xl{
    max-width: 80rem !important;
  }

  .sm\:builder-max-w-full{
    max-width: 100% !important;
  }

  .sm\:builder-max-w-min{
    max-width: min-content !important;
  }

  .sm\:builder-max-w-max{
    max-width: max-content !important;
  }

  .sm\:builder-max-w-prose{
    max-width: 65ch !important;
  }

  .sm\:builder-max-w-screen-sm{
    max-width: 640px !important;
  }

  .sm\:builder-max-w-screen-md{
    max-width: 768px !important;
  }

  .sm\:builder-max-w-screen-lg{
    max-width: 1024px !important;
  }

  .sm\:builder-max-w-screen-xl{
    max-width: 1280px !important;
  }

  .sm\:builder-max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .sm\:builder-flex-1{
    flex: 1 1 0% !important;
  }

  .sm\:builder-flex-auto{
    flex: 1 1 auto !important;
  }

  .sm\:builder-flex-initial{
    flex: 0 1 auto !important;
  }

  .sm\:builder-flex-none{
    flex: none !important;
  }

  .sm\:builder-flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .sm\:builder-flex-shrink{
    flex-shrink: 1 !important;
  }

  .sm\:builder-flex-grow-0{
    flex-grow: 0 !important;
  }

  .sm\:builder-flex-grow{
    flex-grow: 1 !important;
  }

  .sm\:builder-table-auto{
    table-layout: auto !important;
  }

  .sm\:builder-table-fixed{
    table-layout: fixed !important;
  }

  .sm\:builder-border-collapse{
    border-collapse: collapse !important;
  }

  .sm\:builder-border-separate{
    border-collapse: separate !important;
  }

  .sm\:builder-origin-center{
    transform-origin: center !important;
  }

  .sm\:builder-origin-top{
    transform-origin: top !important;
  }

  .sm\:builder-origin-top-right{
    transform-origin: top right !important;
  }

  .sm\:builder-origin-right{
    transform-origin: right !important;
  }

  .sm\:builder-origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .sm\:builder-origin-bottom{
    transform-origin: bottom !important;
  }

  .sm\:builder-origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .sm\:builder-origin-left{
    transform-origin: left !important;
  }

  .sm\:builder-origin-top-left{
    transform-origin: top left !important;
  }

  .sm\:builder-transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .sm\:builder-transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .sm\:builder-transform-none{
    transform: none !important;
  }

  .sm\:builder-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .sm\:builder-translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .sm\:builder-translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .sm\:builder-translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .sm\:builder-translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .sm\:builder-translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .sm\:builder-translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .sm\:builder-translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .sm\:builder-translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .sm\:builder-translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .sm\:builder-translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .sm\:builder-translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .sm\:builder-translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .sm\:builder-translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .sm\:builder-translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .sm\:builder-translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .sm\:builder-translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .sm\:builder-translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .sm\:builder-translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .sm\:builder-translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .sm\:builder-translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .sm\:builder-translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .sm\:builder-translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .sm\:builder-translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .sm\:builder-translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .sm\:builder-translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .sm\:builder-translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .sm\:builder-translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .sm\:builder-translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .sm\:builder-translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .sm\:builder-translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .sm\:builder-translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .sm\:builder-translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .sm\:builder-translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .sm\:builder-translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .sm\:builder--translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .sm\:builder--translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .sm\:builder--translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .sm\:builder--translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .sm\:builder--translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .sm\:builder--translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .sm\:builder--translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .sm\:builder--translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .sm\:builder--translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .sm\:builder--translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .sm\:builder--translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .sm\:builder--translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .sm\:builder--translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .sm\:builder--translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .sm\:builder--translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .sm\:builder--translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .sm\:builder--translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .sm\:builder--translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .sm\:builder--translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .sm\:builder--translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .sm\:builder--translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .sm\:builder--translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .sm\:builder--translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .sm\:builder--translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .sm\:builder--translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .sm\:builder--translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .sm\:builder--translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .sm\:builder--translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .sm\:builder--translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .sm\:builder--translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .sm\:builder--translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .sm\:builder--translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .sm\:builder--translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .sm\:builder--translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .sm\:builder--translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .sm\:builder-translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .sm\:builder-translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .sm\:builder-translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .sm\:builder-translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .sm\:builder-translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .sm\:builder-translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .sm\:builder-translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .sm\:builder--translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .sm\:builder--translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .sm\:builder--translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .sm\:builder--translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .sm\:builder--translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .sm\:builder--translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .sm\:builder--translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .sm\:builder-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .sm\:builder-translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .sm\:builder-translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .sm\:builder-translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .sm\:builder-translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .sm\:builder-translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .sm\:builder-translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .sm\:builder-translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .sm\:builder-translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .sm\:builder-translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .sm\:builder-translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .sm\:builder-translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .sm\:builder-translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .sm\:builder-translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .sm\:builder-translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .sm\:builder-translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .sm\:builder-translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .sm\:builder-translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .sm\:builder-translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .sm\:builder-translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .sm\:builder-translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .sm\:builder-translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .sm\:builder-translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .sm\:builder-translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .sm\:builder-translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .sm\:builder-translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .sm\:builder-translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .sm\:builder-translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .sm\:builder-translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .sm\:builder-translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .sm\:builder-translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .sm\:builder-translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .sm\:builder-translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .sm\:builder-translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .sm\:builder-translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .sm\:builder--translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .sm\:builder--translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .sm\:builder--translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .sm\:builder--translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .sm\:builder--translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .sm\:builder--translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .sm\:builder--translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .sm\:builder--translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .sm\:builder--translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .sm\:builder--translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .sm\:builder--translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .sm\:builder--translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .sm\:builder--translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .sm\:builder--translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .sm\:builder--translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .sm\:builder--translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .sm\:builder--translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .sm\:builder--translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .sm\:builder--translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .sm\:builder--translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .sm\:builder--translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .sm\:builder--translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .sm\:builder--translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .sm\:builder--translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .sm\:builder--translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .sm\:builder--translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .sm\:builder--translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .sm\:builder--translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .sm\:builder--translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .sm\:builder--translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .sm\:builder--translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .sm\:builder--translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .sm\:builder--translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .sm\:builder--translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .sm\:builder--translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .sm\:builder-translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .sm\:builder-translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .sm\:builder-translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .sm\:builder-translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .sm\:builder-translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .sm\:builder-translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .sm\:builder-translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .sm\:builder--translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .sm\:builder--translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .sm\:builder--translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .sm\:builder--translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .sm\:builder--translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .sm\:builder--translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .sm\:builder--translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .sm\:hover\:builder-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .sm\:hover\:builder-translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .sm\:hover\:builder-translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .sm\:hover\:builder-translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .sm\:hover\:builder-translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .sm\:hover\:builder-translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .sm\:hover\:builder-translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .sm\:hover\:builder-translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .sm\:hover\:builder-translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .sm\:hover\:builder-translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .sm\:hover\:builder-translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .sm\:hover\:builder-translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .sm\:hover\:builder-translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .sm\:hover\:builder-translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .sm\:hover\:builder-translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .sm\:hover\:builder-translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .sm\:hover\:builder-translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .sm\:hover\:builder-translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .sm\:hover\:builder-translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .sm\:hover\:builder-translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .sm\:hover\:builder-translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .sm\:hover\:builder-translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .sm\:hover\:builder-translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .sm\:hover\:builder-translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .sm\:hover\:builder-translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .sm\:hover\:builder-translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .sm\:hover\:builder-translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .sm\:hover\:builder-translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .sm\:hover\:builder-translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .sm\:hover\:builder-translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .sm\:hover\:builder-translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .sm\:hover\:builder-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .sm\:hover\:builder-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .sm\:hover\:builder-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .sm\:hover\:builder-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .sm\:hover\:builder--translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .sm\:hover\:builder--translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .sm\:hover\:builder--translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .sm\:hover\:builder--translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .sm\:hover\:builder--translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .sm\:hover\:builder--translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .sm\:hover\:builder--translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .sm\:hover\:builder--translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .sm\:hover\:builder--translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .sm\:hover\:builder--translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .sm\:hover\:builder--translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .sm\:hover\:builder--translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .sm\:hover\:builder--translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .sm\:hover\:builder--translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .sm\:hover\:builder--translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .sm\:hover\:builder--translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .sm\:hover\:builder--translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .sm\:hover\:builder--translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .sm\:hover\:builder--translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .sm\:hover\:builder--translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .sm\:hover\:builder--translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .sm\:hover\:builder--translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .sm\:hover\:builder--translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .sm\:hover\:builder--translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .sm\:hover\:builder--translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .sm\:hover\:builder--translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .sm\:hover\:builder--translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .sm\:hover\:builder--translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .sm\:hover\:builder--translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .sm\:hover\:builder--translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .sm\:hover\:builder--translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .sm\:hover\:builder--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .sm\:hover\:builder--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .sm\:hover\:builder--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .sm\:hover\:builder--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .sm\:hover\:builder-translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .sm\:hover\:builder-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .sm\:hover\:builder-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .sm\:hover\:builder-translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .sm\:hover\:builder-translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .sm\:hover\:builder-translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .sm\:hover\:builder-translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .sm\:hover\:builder--translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .sm\:hover\:builder--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .sm\:hover\:builder--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .sm\:hover\:builder--translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .sm\:hover\:builder--translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .sm\:hover\:builder--translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .sm\:hover\:builder--translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .sm\:hover\:builder-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .sm\:hover\:builder-translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .sm\:hover\:builder-translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .sm\:hover\:builder-translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .sm\:hover\:builder-translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .sm\:hover\:builder-translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .sm\:hover\:builder-translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .sm\:hover\:builder-translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .sm\:hover\:builder-translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .sm\:hover\:builder-translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .sm\:hover\:builder-translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .sm\:hover\:builder-translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .sm\:hover\:builder-translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .sm\:hover\:builder-translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .sm\:hover\:builder-translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .sm\:hover\:builder-translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .sm\:hover\:builder-translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .sm\:hover\:builder-translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .sm\:hover\:builder-translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .sm\:hover\:builder-translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .sm\:hover\:builder-translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .sm\:hover\:builder-translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .sm\:hover\:builder-translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .sm\:hover\:builder-translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .sm\:hover\:builder-translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .sm\:hover\:builder-translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .sm\:hover\:builder-translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .sm\:hover\:builder-translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .sm\:hover\:builder-translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .sm\:hover\:builder-translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .sm\:hover\:builder-translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .sm\:hover\:builder-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .sm\:hover\:builder-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .sm\:hover\:builder-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .sm\:hover\:builder-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .sm\:hover\:builder--translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .sm\:hover\:builder--translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .sm\:hover\:builder--translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .sm\:hover\:builder--translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .sm\:hover\:builder--translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .sm\:hover\:builder--translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .sm\:hover\:builder--translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .sm\:hover\:builder--translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .sm\:hover\:builder--translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .sm\:hover\:builder--translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .sm\:hover\:builder--translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .sm\:hover\:builder--translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .sm\:hover\:builder--translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .sm\:hover\:builder--translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .sm\:hover\:builder--translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .sm\:hover\:builder--translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .sm\:hover\:builder--translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .sm\:hover\:builder--translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .sm\:hover\:builder--translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .sm\:hover\:builder--translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .sm\:hover\:builder--translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .sm\:hover\:builder--translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .sm\:hover\:builder--translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .sm\:hover\:builder--translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .sm\:hover\:builder--translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .sm\:hover\:builder--translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .sm\:hover\:builder--translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .sm\:hover\:builder--translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .sm\:hover\:builder--translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .sm\:hover\:builder--translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .sm\:hover\:builder--translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .sm\:hover\:builder--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .sm\:hover\:builder--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .sm\:hover\:builder--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .sm\:hover\:builder--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .sm\:hover\:builder-translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .sm\:hover\:builder-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .sm\:hover\:builder-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .sm\:hover\:builder-translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .sm\:hover\:builder-translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .sm\:hover\:builder-translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .sm\:hover\:builder-translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .sm\:hover\:builder--translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .sm\:hover\:builder--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .sm\:hover\:builder--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .sm\:hover\:builder--translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .sm\:hover\:builder--translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .sm\:hover\:builder--translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .sm\:hover\:builder--translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .sm\:focus\:builder-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .sm\:focus\:builder-translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .sm\:focus\:builder-translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .sm\:focus\:builder-translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .sm\:focus\:builder-translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .sm\:focus\:builder-translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .sm\:focus\:builder-translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .sm\:focus\:builder-translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .sm\:focus\:builder-translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .sm\:focus\:builder-translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .sm\:focus\:builder-translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .sm\:focus\:builder-translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .sm\:focus\:builder-translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .sm\:focus\:builder-translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .sm\:focus\:builder-translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .sm\:focus\:builder-translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .sm\:focus\:builder-translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .sm\:focus\:builder-translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .sm\:focus\:builder-translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .sm\:focus\:builder-translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .sm\:focus\:builder-translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .sm\:focus\:builder-translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .sm\:focus\:builder-translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .sm\:focus\:builder-translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .sm\:focus\:builder-translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .sm\:focus\:builder-translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .sm\:focus\:builder-translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .sm\:focus\:builder-translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .sm\:focus\:builder-translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .sm\:focus\:builder-translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .sm\:focus\:builder-translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .sm\:focus\:builder-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .sm\:focus\:builder-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .sm\:focus\:builder-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .sm\:focus\:builder-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .sm\:focus\:builder--translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .sm\:focus\:builder--translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .sm\:focus\:builder--translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .sm\:focus\:builder--translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .sm\:focus\:builder--translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .sm\:focus\:builder--translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .sm\:focus\:builder--translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .sm\:focus\:builder--translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .sm\:focus\:builder--translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .sm\:focus\:builder--translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .sm\:focus\:builder--translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .sm\:focus\:builder--translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .sm\:focus\:builder--translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .sm\:focus\:builder--translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .sm\:focus\:builder--translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .sm\:focus\:builder--translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .sm\:focus\:builder--translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .sm\:focus\:builder--translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .sm\:focus\:builder--translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .sm\:focus\:builder--translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .sm\:focus\:builder--translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .sm\:focus\:builder--translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .sm\:focus\:builder--translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .sm\:focus\:builder--translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .sm\:focus\:builder--translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .sm\:focus\:builder--translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .sm\:focus\:builder--translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .sm\:focus\:builder--translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .sm\:focus\:builder--translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .sm\:focus\:builder--translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .sm\:focus\:builder--translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .sm\:focus\:builder--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .sm\:focus\:builder--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .sm\:focus\:builder--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .sm\:focus\:builder--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .sm\:focus\:builder-translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .sm\:focus\:builder-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .sm\:focus\:builder-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .sm\:focus\:builder-translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .sm\:focus\:builder-translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .sm\:focus\:builder-translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .sm\:focus\:builder-translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .sm\:focus\:builder--translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .sm\:focus\:builder--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .sm\:focus\:builder--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .sm\:focus\:builder--translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .sm\:focus\:builder--translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .sm\:focus\:builder--translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .sm\:focus\:builder--translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .sm\:focus\:builder-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .sm\:focus\:builder-translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .sm\:focus\:builder-translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .sm\:focus\:builder-translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .sm\:focus\:builder-translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .sm\:focus\:builder-translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .sm\:focus\:builder-translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .sm\:focus\:builder-translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .sm\:focus\:builder-translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .sm\:focus\:builder-translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .sm\:focus\:builder-translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .sm\:focus\:builder-translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .sm\:focus\:builder-translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .sm\:focus\:builder-translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .sm\:focus\:builder-translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .sm\:focus\:builder-translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .sm\:focus\:builder-translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .sm\:focus\:builder-translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .sm\:focus\:builder-translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .sm\:focus\:builder-translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .sm\:focus\:builder-translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .sm\:focus\:builder-translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .sm\:focus\:builder-translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .sm\:focus\:builder-translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .sm\:focus\:builder-translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .sm\:focus\:builder-translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .sm\:focus\:builder-translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .sm\:focus\:builder-translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .sm\:focus\:builder-translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .sm\:focus\:builder-translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .sm\:focus\:builder-translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .sm\:focus\:builder-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .sm\:focus\:builder-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .sm\:focus\:builder-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .sm\:focus\:builder-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .sm\:focus\:builder--translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .sm\:focus\:builder--translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .sm\:focus\:builder--translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .sm\:focus\:builder--translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .sm\:focus\:builder--translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .sm\:focus\:builder--translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .sm\:focus\:builder--translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .sm\:focus\:builder--translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .sm\:focus\:builder--translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .sm\:focus\:builder--translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .sm\:focus\:builder--translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .sm\:focus\:builder--translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .sm\:focus\:builder--translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .sm\:focus\:builder--translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .sm\:focus\:builder--translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .sm\:focus\:builder--translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .sm\:focus\:builder--translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .sm\:focus\:builder--translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .sm\:focus\:builder--translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .sm\:focus\:builder--translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .sm\:focus\:builder--translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .sm\:focus\:builder--translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .sm\:focus\:builder--translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .sm\:focus\:builder--translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .sm\:focus\:builder--translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .sm\:focus\:builder--translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .sm\:focus\:builder--translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .sm\:focus\:builder--translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .sm\:focus\:builder--translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .sm\:focus\:builder--translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .sm\:focus\:builder--translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .sm\:focus\:builder--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .sm\:focus\:builder--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .sm\:focus\:builder--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .sm\:focus\:builder--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .sm\:focus\:builder-translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .sm\:focus\:builder-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .sm\:focus\:builder-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .sm\:focus\:builder-translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .sm\:focus\:builder-translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .sm\:focus\:builder-translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .sm\:focus\:builder-translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .sm\:focus\:builder--translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .sm\:focus\:builder--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .sm\:focus\:builder--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .sm\:focus\:builder--translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .sm\:focus\:builder--translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .sm\:focus\:builder--translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .sm\:focus\:builder--translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .sm\:builder-rotate-0{
    --tw-rotate: 0deg !important;
  }

  .sm\:builder-rotate-1{
    --tw-rotate: 1deg !important;
  }

  .sm\:builder-rotate-2{
    --tw-rotate: 2deg !important;
  }

  .sm\:builder-rotate-3{
    --tw-rotate: 3deg !important;
  }

  .sm\:builder-rotate-6{
    --tw-rotate: 6deg !important;
  }

  .sm\:builder-rotate-12{
    --tw-rotate: 12deg !important;
  }

  .sm\:builder-rotate-45{
    --tw-rotate: 45deg !important;
  }

  .sm\:builder-rotate-90{
    --tw-rotate: 90deg !important;
  }

  .sm\:builder-rotate-180{
    --tw-rotate: 180deg !important;
  }

  .sm\:builder--rotate-180{
    --tw-rotate: -180deg !important;
  }

  .sm\:builder--rotate-90{
    --tw-rotate: -90deg !important;
  }

  .sm\:builder--rotate-45{
    --tw-rotate: -45deg !important;
  }

  .sm\:builder--rotate-12{
    --tw-rotate: -12deg !important;
  }

  .sm\:builder--rotate-6{
    --tw-rotate: -6deg !important;
  }

  .sm\:builder--rotate-3{
    --tw-rotate: -3deg !important;
  }

  .sm\:builder--rotate-2{
    --tw-rotate: -2deg !important;
  }

  .sm\:builder--rotate-1{
    --tw-rotate: -1deg !important;
  }

  .sm\:hover\:builder-rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .sm\:hover\:builder-rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .sm\:hover\:builder-rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .sm\:hover\:builder-rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .sm\:hover\:builder-rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .sm\:hover\:builder-rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .sm\:hover\:builder-rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .sm\:hover\:builder-rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .sm\:hover\:builder-rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .sm\:hover\:builder--rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .sm\:hover\:builder--rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .sm\:hover\:builder--rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .sm\:hover\:builder--rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .sm\:hover\:builder--rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .sm\:hover\:builder--rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .sm\:hover\:builder--rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .sm\:hover\:builder--rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .sm\:focus\:builder-rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .sm\:focus\:builder-rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .sm\:focus\:builder-rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .sm\:focus\:builder-rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .sm\:focus\:builder-rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .sm\:focus\:builder-rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .sm\:focus\:builder-rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .sm\:focus\:builder-rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .sm\:focus\:builder-rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .sm\:focus\:builder--rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .sm\:focus\:builder--rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .sm\:focus\:builder--rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .sm\:focus\:builder--rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .sm\:focus\:builder--rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .sm\:focus\:builder--rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .sm\:focus\:builder--rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .sm\:focus\:builder--rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .sm\:builder-skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .sm\:builder-skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .sm\:builder-skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .sm\:builder-skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .sm\:builder-skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .sm\:builder-skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .sm\:builder--skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .sm\:builder--skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .sm\:builder--skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .sm\:builder--skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .sm\:builder--skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .sm\:builder-skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .sm\:builder-skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .sm\:builder-skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .sm\:builder-skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .sm\:builder-skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .sm\:builder-skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .sm\:builder--skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .sm\:builder--skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .sm\:builder--skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .sm\:builder--skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .sm\:builder--skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .sm\:hover\:builder-skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .sm\:hover\:builder-skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .sm\:hover\:builder-skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .sm\:hover\:builder-skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .sm\:hover\:builder-skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .sm\:hover\:builder-skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .sm\:hover\:builder--skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .sm\:hover\:builder--skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .sm\:hover\:builder--skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .sm\:hover\:builder--skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .sm\:hover\:builder--skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .sm\:hover\:builder-skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .sm\:hover\:builder-skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .sm\:hover\:builder-skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .sm\:hover\:builder-skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .sm\:hover\:builder-skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .sm\:hover\:builder-skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .sm\:hover\:builder--skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .sm\:hover\:builder--skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .sm\:hover\:builder--skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .sm\:hover\:builder--skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .sm\:hover\:builder--skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .sm\:focus\:builder-skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .sm\:focus\:builder-skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .sm\:focus\:builder-skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .sm\:focus\:builder-skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .sm\:focus\:builder-skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .sm\:focus\:builder-skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .sm\:focus\:builder--skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .sm\:focus\:builder--skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .sm\:focus\:builder--skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .sm\:focus\:builder--skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .sm\:focus\:builder--skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .sm\:focus\:builder-skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .sm\:focus\:builder-skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .sm\:focus\:builder-skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .sm\:focus\:builder-skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .sm\:focus\:builder-skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .sm\:focus\:builder-skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .sm\:focus\:builder--skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .sm\:focus\:builder--skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .sm\:focus\:builder--skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .sm\:focus\:builder--skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .sm\:focus\:builder--skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .sm\:builder-scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .sm\:builder-scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .sm\:builder-scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .sm\:builder-scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .sm\:builder-scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .sm\:builder-scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .sm\:builder-scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .sm\:builder-scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .sm\:builder-scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .sm\:builder-scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .sm\:hover\:builder-scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .sm\:hover\:builder-scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .sm\:hover\:builder-scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .sm\:hover\:builder-scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .sm\:hover\:builder-scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .sm\:hover\:builder-scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .sm\:hover\:builder-scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .sm\:hover\:builder-scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .sm\:hover\:builder-scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .sm\:hover\:builder-scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .sm\:focus\:builder-scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .sm\:focus\:builder-scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .sm\:focus\:builder-scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .sm\:focus\:builder-scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .sm\:focus\:builder-scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .sm\:focus\:builder-scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .sm\:focus\:builder-scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .sm\:focus\:builder-scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .sm\:focus\:builder-scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .sm\:focus\:builder-scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .sm\:builder-scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .sm\:builder-scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .sm\:builder-scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .sm\:builder-scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .sm\:builder-scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .sm\:builder-scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .sm\:builder-scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .sm\:builder-scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .sm\:builder-scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .sm\:builder-scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .sm\:builder-scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .sm\:builder-scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .sm\:builder-scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .sm\:builder-scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .sm\:builder-scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .sm\:builder-scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .sm\:builder-scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .sm\:builder-scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .sm\:builder-scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .sm\:builder-scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .sm\:hover\:builder-scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .sm\:hover\:builder-scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .sm\:hover\:builder-scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .sm\:hover\:builder-scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .sm\:hover\:builder-scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .sm\:hover\:builder-scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .sm\:hover\:builder-scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .sm\:hover\:builder-scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .sm\:hover\:builder-scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .sm\:hover\:builder-scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .sm\:hover\:builder-scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .sm\:hover\:builder-scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .sm\:hover\:builder-scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .sm\:hover\:builder-scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .sm\:hover\:builder-scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .sm\:hover\:builder-scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .sm\:hover\:builder-scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .sm\:hover\:builder-scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .sm\:hover\:builder-scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .sm\:hover\:builder-scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .sm\:focus\:builder-scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .sm\:focus\:builder-scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .sm\:focus\:builder-scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .sm\:focus\:builder-scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .sm\:focus\:builder-scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .sm\:focus\:builder-scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .sm\:focus\:builder-scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .sm\:focus\:builder-scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .sm\:focus\:builder-scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .sm\:focus\:builder-scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .sm\:focus\:builder-scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .sm\:focus\:builder-scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .sm\:focus\:builder-scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .sm\:focus\:builder-scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .sm\:focus\:builder-scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .sm\:focus\:builder-scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .sm\:focus\:builder-scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .sm\:focus\:builder-scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .sm\:focus\:builder-scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .sm\:focus\:builder-scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .sm\:builder-animate-none{
    animation: none !important;
  }

  .sm\:builder-animate-spin{
    animation: builder-spin 1s linear infinite !important;
  }

  .sm\:builder-animate-ping{
    animation: builder-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .sm\:builder-animate-pulse{
    animation: builder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .sm\:builder-animate-bounce{
    animation: builder-bounce 1s infinite !important;
  }

  .sm\:builder-cursor-auto{
    cursor: auto !important;
  }

  .sm\:builder-cursor-default{
    cursor: default !important;
  }

  .sm\:builder-cursor-pointer{
    cursor: pointer !important;
  }

  .sm\:builder-cursor-wait{
    cursor: wait !important;
  }

  .sm\:builder-cursor-text{
    cursor: text !important;
  }

  .sm\:builder-cursor-move{
    cursor: move !important;
  }

  .sm\:builder-cursor-help{
    cursor: help !important;
  }

  .sm\:builder-cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .sm\:builder-select-none{
    -webkit-user-select: none !important;
            user-select: none !important;
  }

  .sm\:builder-select-text{
    -webkit-user-select: text !important;
            user-select: text !important;
  }

  .sm\:builder-select-all{
    -webkit-user-select: all !important;
            user-select: all !important;
  }

  .sm\:builder-select-auto{
    -webkit-user-select: auto !important;
            user-select: auto !important;
  }

  .sm\:builder-resize-none{
    resize: none !important;
  }

  .sm\:builder-resize-y{
    resize: vertical !important;
  }

  .sm\:builder-resize-x{
    resize: horizontal !important;
  }

  .sm\:builder-resize{
    resize: both !important;
  }

  .sm\:builder-list-inside{
    list-style-position: inside !important;
  }

  .sm\:builder-list-outside{
    list-style-position: outside !important;
  }

  .sm\:builder-list-none{
    list-style-type: none !important;
  }

  .sm\:builder-list-disc{
    list-style-type: disc !important;
  }

  .sm\:builder-list-decimal{
    list-style-type: decimal !important;
  }

  .sm\:builder-appearance-none{
    appearance: none !important;
  }

  .sm\:builder-auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .sm\:builder-auto-cols-min{
    grid-auto-columns: min-content !important;
  }

  .sm\:builder-auto-cols-max{
    grid-auto-columns: max-content !important;
  }

  .sm\:builder-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .sm\:builder-grid-flow-row{
    grid-auto-flow: row !important;
  }

  .sm\:builder-grid-flow-col{
    grid-auto-flow: column !important;
  }

  .sm\:builder-grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .sm\:builder-grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .sm\:builder-auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .sm\:builder-auto-rows-min{
    grid-auto-rows: min-content !important;
  }

  .sm\:builder-auto-rows-max{
    grid-auto-rows: max-content !important;
  }

  .sm\:builder-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .sm\:builder-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-cols-none{
    grid-template-columns: none !important;
  }

  .sm\:builder-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:builder-grid-rows-none{
    grid-template-rows: none !important;
  }

  .sm\:builder-flex-row{
    flex-direction: row !important;
  }

  .sm\:builder-flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .sm\:builder-flex-col{
    flex-direction: column !important;
  }

  .sm\:builder-flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .sm\:builder-flex-wrap{
    flex-wrap: wrap !important;
  }

  .sm\:builder-flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .sm\:builder-flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .sm\:builder-place-content-center{
    place-content: center !important;
  }

  .sm\:builder-place-content-start{
    place-content: start !important;
  }

  .sm\:builder-place-content-end{
    place-content: end !important;
  }

  .sm\:builder-place-content-between{
    place-content: space-between !important;
  }

  .sm\:builder-place-content-around{
    place-content: space-around !important;
  }

  .sm\:builder-place-content-evenly{
    place-content: space-evenly !important;
  }

  .sm\:builder-place-content-stretch{
    place-content: stretch !important;
  }

  .sm\:builder-place-items-start{
    place-items: start !important;
  }

  .sm\:builder-place-items-end{
    place-items: end !important;
  }

  .sm\:builder-place-items-center{
    place-items: center !important;
  }

  .sm\:builder-place-items-stretch{
    place-items: stretch !important;
  }

  .sm\:builder-content-center{
    align-content: center !important;
  }

  .sm\:builder-content-start{
    align-content: flex-start !important;
  }

  .sm\:builder-content-end{
    align-content: flex-end !important;
  }

  .sm\:builder-content-between{
    align-content: space-between !important;
  }

  .sm\:builder-content-around{
    align-content: space-around !important;
  }

  .sm\:builder-content-evenly{
    align-content: space-evenly !important;
  }

  .sm\:builder-items-start{
    align-items: flex-start !important;
  }

  .sm\:builder-items-end{
    align-items: flex-end !important;
  }

  .sm\:builder-items-center{
    align-items: center !important;
  }

  .sm\:builder-items-baseline{
    align-items: baseline !important;
  }

  .sm\:builder-items-stretch{
    align-items: stretch !important;
  }

  .sm\:builder-justify-start{
    justify-content: flex-start !important;
  }

  .sm\:builder-justify-end{
    justify-content: flex-end !important;
  }

  .sm\:builder-justify-center{
    justify-content: center !important;
  }

  .sm\:builder-justify-between{
    justify-content: space-between !important;
  }

  .sm\:builder-justify-around{
    justify-content: space-around !important;
  }

  .sm\:builder-justify-evenly{
    justify-content: space-evenly !important;
  }

  .sm\:builder-justify-items-start{
    justify-items: start !important;
  }

  .sm\:builder-justify-items-end{
    justify-items: end !important;
  }

  .sm\:builder-justify-items-center{
    justify-items: center !important;
  }

  .sm\:builder-justify-items-stretch{
    justify-items: stretch !important;
  }

  .sm\:builder-gap-0{
    gap: 0px !important;
  }

  .sm\:builder-gap-1{
    gap: 0.25rem !important;
  }

  .sm\:builder-gap-2{
    gap: 0.5rem !important;
  }

  .sm\:builder-gap-3{
    gap: 0.75rem !important;
  }

  .sm\:builder-gap-4{
    gap: 1rem !important;
  }

  .sm\:builder-gap-5{
    gap: 1.25rem !important;
  }

  .sm\:builder-gap-6{
    gap: 1.5rem !important;
  }

  .sm\:builder-gap-7{
    gap: 1.75rem !important;
  }

  .sm\:builder-gap-8{
    gap: 2rem !important;
  }

  .sm\:builder-gap-9{
    gap: 2.25rem !important;
  }

  .sm\:builder-gap-10{
    gap: 2.5rem !important;
  }

  .sm\:builder-gap-11{
    gap: 2.75rem !important;
  }

  .sm\:builder-gap-12{
    gap: 3rem !important;
  }

  .sm\:builder-gap-14{
    gap: 3.5rem !important;
  }

  .sm\:builder-gap-16{
    gap: 4rem !important;
  }

  .sm\:builder-gap-20{
    gap: 5rem !important;
  }

  .sm\:builder-gap-24{
    gap: 6rem !important;
  }

  .sm\:builder-gap-28{
    gap: 7rem !important;
  }

  .sm\:builder-gap-32{
    gap: 8rem !important;
  }

  .sm\:builder-gap-36{
    gap: 9rem !important;
  }

  .sm\:builder-gap-40{
    gap: 10rem !important;
  }

  .sm\:builder-gap-44{
    gap: 11rem !important;
  }

  .sm\:builder-gap-48{
    gap: 12rem !important;
  }

  .sm\:builder-gap-52{
    gap: 13rem !important;
  }

  .sm\:builder-gap-56{
    gap: 14rem !important;
  }

  .sm\:builder-gap-60{
    gap: 15rem !important;
  }

  .sm\:builder-gap-64{
    gap: 16rem !important;
  }

  .sm\:builder-gap-72{
    gap: 18rem !important;
  }

  .sm\:builder-gap-80{
    gap: 20rem !important;
  }

  .sm\:builder-gap-96{
    gap: 24rem !important;
  }

  .sm\:builder-gap-px{
    gap: 1px !important;
  }

  .sm\:builder-gap-0\.5{
    gap: 0.125rem !important;
  }

  .sm\:builder-gap-1\.5{
    gap: 0.375rem !important;
  }

  .sm\:builder-gap-2\.5{
    gap: 0.625rem !important;
  }

  .sm\:builder-gap-3\.5{
    gap: 0.875rem !important;
  }

  .sm\:builder-gap-x-0{
    column-gap: 0px !important;
  }

  .sm\:builder-gap-x-1{
    column-gap: 0.25rem !important;
  }

  .sm\:builder-gap-x-2{
    column-gap: 0.5rem !important;
  }

  .sm\:builder-gap-x-3{
    column-gap: 0.75rem !important;
  }

  .sm\:builder-gap-x-4{
    column-gap: 1rem !important;
  }

  .sm\:builder-gap-x-5{
    column-gap: 1.25rem !important;
  }

  .sm\:builder-gap-x-6{
    column-gap: 1.5rem !important;
  }

  .sm\:builder-gap-x-7{
    column-gap: 1.75rem !important;
  }

  .sm\:builder-gap-x-8{
    column-gap: 2rem !important;
  }

  .sm\:builder-gap-x-9{
    column-gap: 2.25rem !important;
  }

  .sm\:builder-gap-x-10{
    column-gap: 2.5rem !important;
  }

  .sm\:builder-gap-x-11{
    column-gap: 2.75rem !important;
  }

  .sm\:builder-gap-x-12{
    column-gap: 3rem !important;
  }

  .sm\:builder-gap-x-14{
    column-gap: 3.5rem !important;
  }

  .sm\:builder-gap-x-16{
    column-gap: 4rem !important;
  }

  .sm\:builder-gap-x-20{
    column-gap: 5rem !important;
  }

  .sm\:builder-gap-x-24{
    column-gap: 6rem !important;
  }

  .sm\:builder-gap-x-28{
    column-gap: 7rem !important;
  }

  .sm\:builder-gap-x-32{
    column-gap: 8rem !important;
  }

  .sm\:builder-gap-x-36{
    column-gap: 9rem !important;
  }

  .sm\:builder-gap-x-40{
    column-gap: 10rem !important;
  }

  .sm\:builder-gap-x-44{
    column-gap: 11rem !important;
  }

  .sm\:builder-gap-x-48{
    column-gap: 12rem !important;
  }

  .sm\:builder-gap-x-52{
    column-gap: 13rem !important;
  }

  .sm\:builder-gap-x-56{
    column-gap: 14rem !important;
  }

  .sm\:builder-gap-x-60{
    column-gap: 15rem !important;
  }

  .sm\:builder-gap-x-64{
    column-gap: 16rem !important;
  }

  .sm\:builder-gap-x-72{
    column-gap: 18rem !important;
  }

  .sm\:builder-gap-x-80{
    column-gap: 20rem !important;
  }

  .sm\:builder-gap-x-96{
    column-gap: 24rem !important;
  }

  .sm\:builder-gap-x-px{
    column-gap: 1px !important;
  }

  .sm\:builder-gap-x-0\.5{
    column-gap: 0.125rem !important;
  }

  .sm\:builder-gap-x-1\.5{
    column-gap: 0.375rem !important;
  }

  .sm\:builder-gap-x-2\.5{
    column-gap: 0.625rem !important;
  }

  .sm\:builder-gap-x-3\.5{
    column-gap: 0.875rem !important;
  }

  .sm\:builder-gap-y-0{
    row-gap: 0px !important;
  }

  .sm\:builder-gap-y-1{
    row-gap: 0.25rem !important;
  }

  .sm\:builder-gap-y-2{
    row-gap: 0.5rem !important;
  }

  .sm\:builder-gap-y-3{
    row-gap: 0.75rem !important;
  }

  .sm\:builder-gap-y-4{
    row-gap: 1rem !important;
  }

  .sm\:builder-gap-y-5{
    row-gap: 1.25rem !important;
  }

  .sm\:builder-gap-y-6{
    row-gap: 1.5rem !important;
  }

  .sm\:builder-gap-y-7{
    row-gap: 1.75rem !important;
  }

  .sm\:builder-gap-y-8{
    row-gap: 2rem !important;
  }

  .sm\:builder-gap-y-9{
    row-gap: 2.25rem !important;
  }

  .sm\:builder-gap-y-10{
    row-gap: 2.5rem !important;
  }

  .sm\:builder-gap-y-11{
    row-gap: 2.75rem !important;
  }

  .sm\:builder-gap-y-12{
    row-gap: 3rem !important;
  }

  .sm\:builder-gap-y-14{
    row-gap: 3.5rem !important;
  }

  .sm\:builder-gap-y-16{
    row-gap: 4rem !important;
  }

  .sm\:builder-gap-y-20{
    row-gap: 5rem !important;
  }

  .sm\:builder-gap-y-24{
    row-gap: 6rem !important;
  }

  .sm\:builder-gap-y-28{
    row-gap: 7rem !important;
  }

  .sm\:builder-gap-y-32{
    row-gap: 8rem !important;
  }

  .sm\:builder-gap-y-36{
    row-gap: 9rem !important;
  }

  .sm\:builder-gap-y-40{
    row-gap: 10rem !important;
  }

  .sm\:builder-gap-y-44{
    row-gap: 11rem !important;
  }

  .sm\:builder-gap-y-48{
    row-gap: 12rem !important;
  }

  .sm\:builder-gap-y-52{
    row-gap: 13rem !important;
  }

  .sm\:builder-gap-y-56{
    row-gap: 14rem !important;
  }

  .sm\:builder-gap-y-60{
    row-gap: 15rem !important;
  }

  .sm\:builder-gap-y-64{
    row-gap: 16rem !important;
  }

  .sm\:builder-gap-y-72{
    row-gap: 18rem !important;
  }

  .sm\:builder-gap-y-80{
    row-gap: 20rem !important;
  }

  .sm\:builder-gap-y-96{
    row-gap: 24rem !important;
  }

  .sm\:builder-gap-y-px{
    row-gap: 1px !important;
  }

  .sm\:builder-gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .sm\:builder-gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .sm\:builder-gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .sm\:builder-gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .sm\:builder-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:builder-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:builder-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .sm\:builder-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .sm\:builder-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:builder-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:builder-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:builder-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:builder-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:builder-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:builder-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:builder-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:builder-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:builder-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:builder-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .sm\:builder-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .sm\:builder-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .sm\:builder-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .sm\:builder-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .sm\:builder-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .sm\:builder-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .sm\:builder-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .sm\:builder-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .sm\:builder-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-primary > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-secondary-dark > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-secondary-darker > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity)) !important;
  }

  .sm\:builder-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .sm\:builder-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .sm\:builder-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .sm\:builder-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .sm\:builder-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .sm\:builder-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .sm\:builder-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .sm\:builder-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .sm\:builder-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .sm\:builder-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .sm\:builder-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .sm\:builder-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .sm\:builder-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .sm\:builder-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .sm\:builder-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .sm\:builder-place-self-auto{
    place-self: auto !important;
  }

  .sm\:builder-place-self-start{
    place-self: start !important;
  }

  .sm\:builder-place-self-end{
    place-self: end !important;
  }

  .sm\:builder-place-self-center{
    place-self: center !important;
  }

  .sm\:builder-place-self-stretch{
    place-self: stretch !important;
  }

  .sm\:builder-self-auto{
    align-self: auto !important;
  }

  .sm\:builder-self-start{
    align-self: flex-start !important;
  }

  .sm\:builder-self-end{
    align-self: flex-end !important;
  }

  .sm\:builder-self-center{
    align-self: center !important;
  }

  .sm\:builder-self-stretch{
    align-self: stretch !important;
  }

  .sm\:builder-self-baseline{
    align-self: baseline !important;
  }

  .sm\:builder-justify-self-auto{
    justify-self: auto !important;
  }

  .sm\:builder-justify-self-start{
    justify-self: start !important;
  }

  .sm\:builder-justify-self-end{
    justify-self: end !important;
  }

  .sm\:builder-justify-self-center{
    justify-self: center !important;
  }

  .sm\:builder-justify-self-stretch{
    justify-self: stretch !important;
  }

  .sm\:builder-overflow-auto{
    overflow: auto !important;
  }

  .sm\:builder-overflow-hidden{
    overflow: hidden !important;
  }

  .sm\:builder-overflow-visible{
    overflow: visible !important;
  }

  .sm\:builder-overflow-scroll{
    overflow: scroll !important;
  }

  .sm\:builder-overflow-x-auto{
    overflow-x: auto !important;
  }

  .sm\:builder-overflow-y-auto{
    overflow-y: auto !important;
  }

  .sm\:builder-overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .sm\:builder-overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .sm\:builder-overflow-x-visible{
    overflow-x: visible !important;
  }

  .sm\:builder-overflow-y-visible{
    overflow-y: visible !important;
  }

  .sm\:builder-overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .sm\:builder-overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .sm\:builder-overscroll-auto{
    overscroll-behavior: auto !important;
  }

  .sm\:builder-overscroll-contain{
    overscroll-behavior: contain !important;
  }

  .sm\:builder-overscroll-none{
    overscroll-behavior: none !important;
  }

  .sm\:builder-overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .sm\:builder-overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .sm\:builder-overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .sm\:builder-overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .sm\:builder-overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .sm\:builder-overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .sm\:builder-truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .sm\:builder-overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .sm\:builder-overflow-clip{
    text-overflow: clip !important;
  }

  .sm\:builder-whitespace-normal{
    white-space: normal !important;
  }

  .sm\:builder-whitespace-nowrap{
    white-space: nowrap !important;
  }

  .sm\:builder-whitespace-pre{
    white-space: pre !important;
  }

  .sm\:builder-whitespace-pre-line{
    white-space: pre-line !important;
  }

  .sm\:builder-whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .sm\:builder-break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .sm\:builder-break-words{
    overflow-wrap: break-word !important;
  }

  .sm\:builder-break-all{
    word-break: break-all !important;
  }

  .sm\:builder-rounded-none{
    border-radius: 0px !important;
  }

  .sm\:builder-rounded-sm{
    border-radius: 0.125rem !important;
  }

  .sm\:builder-rounded{
    border-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-md{
    border-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-lg{
    border-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-xl{
    border-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-2xl{
    border-radius: 1rem !important;
  }

  .sm\:builder-rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-full{
    border-radius: 9999px !important;
  }

  .sm\:builder-rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .sm\:builder-rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .sm\:builder-rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .sm\:builder-rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .sm\:builder-rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:builder-rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:builder-rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .sm\:builder-rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:builder-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:builder-rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .sm\:builder-rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:builder-rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:builder-rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .sm\:builder-rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .sm\:builder-rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .sm\:builder-rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .sm\:builder-rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .sm\:builder-rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .sm\:builder-rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .sm\:builder-rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:builder-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:builder-rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .sm\:builder-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:builder-rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:builder-rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:builder-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:builder-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:builder-rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:builder-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:builder-rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:builder-border-0{
    border-width: 0px !important;
  }

  .sm\:builder-border-2{
    border-width: 2px !important;
  }

  .sm\:builder-border-4{
    border-width: 4px !important;
  }

  .sm\:builder-border-8{
    border-width: 8px !important;
  }

  .sm\:builder-border{
    border-width: 1px !important;
  }

  .sm\:builder-border-t-0{
    border-top-width: 0px !important;
  }

  .sm\:builder-border-t-2{
    border-top-width: 2px !important;
  }

  .sm\:builder-border-t-4{
    border-top-width: 4px !important;
  }

  .sm\:builder-border-t-8{
    border-top-width: 8px !important;
  }

  .sm\:builder-border-t{
    border-top-width: 1px !important;
  }

  .sm\:builder-border-r-0{
    border-right-width: 0px !important;
  }

  .sm\:builder-border-r-2{
    border-right-width: 2px !important;
  }

  .sm\:builder-border-r-4{
    border-right-width: 4px !important;
  }

  .sm\:builder-border-r-8{
    border-right-width: 8px !important;
  }

  .sm\:builder-border-r{
    border-right-width: 1px !important;
  }

  .sm\:builder-border-b-0{
    border-bottom-width: 0px !important;
  }

  .sm\:builder-border-b-2{
    border-bottom-width: 2px !important;
  }

  .sm\:builder-border-b-4{
    border-bottom-width: 4px !important;
  }

  .sm\:builder-border-b-8{
    border-bottom-width: 8px !important;
  }

  .sm\:builder-border-b{
    border-bottom-width: 1px !important;
  }

  .sm\:builder-border-l-0{
    border-left-width: 0px !important;
  }

  .sm\:builder-border-l-2{
    border-left-width: 2px !important;
  }

  .sm\:builder-border-l-4{
    border-left-width: 4px !important;
  }

  .sm\:builder-border-l-8{
    border-left-width: 8px !important;
  }

  .sm\:builder-border-l{
    border-left-width: 1px !important;
  }

  .sm\:builder-border-solid{
    border-style: solid !important;
  }

  .sm\:builder-border-dashed{
    border-style: dashed !important;
  }

  .sm\:builder-border-dotted{
    border-style: dotted !important;
  }

  .sm\:builder-border-double{
    border-style: double !important;
  }

  .sm\:builder-border-none{
    border-style: none !important;
  }

  .sm\:builder-border-transparent{
    border-color: transparent !important;
  }

  .sm\:builder-border-current{
    border-color: currentColor !important;
  }

  .sm\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-transparent{
    border-color: transparent !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-current{
    border-color: currentColor !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-transparent:focus-within{
    border-color: transparent !important;
  }

  .sm\:focus-within\:builder-border-current:focus-within{
    border-color: currentColor !important;
  }

  .sm\:focus-within\:builder-border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-primary:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-secondary-dark:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:builder-border-secondary-darker:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-transparent:hover{
    border-color: transparent !important;
  }

  .sm\:hover\:builder-border-current:hover{
    border-color: currentColor !important;
  }

  .sm\:hover\:builder-border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-primary:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-secondary-dark:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:builder-border-secondary-darker:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-transparent:focus{
    border-color: transparent !important;
  }

  .sm\:focus\:builder-border-current:focus{
    border-color: currentColor !important;
  }

  .sm\:focus\:builder-border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-primary:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-secondary-dark:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:builder-border-secondary-darker:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .sm\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .sm\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .sm\:focus-within\:builder-border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .sm\:focus-within\:builder-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:focus-within\:builder-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:focus-within\:builder-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:focus-within\:builder-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:focus-within\:builder-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:focus-within\:builder-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:focus-within\:builder-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:focus-within\:builder-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:focus-within\:builder-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:focus-within\:builder-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:focus-within\:builder-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:focus-within\:builder-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:focus-within\:builder-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:focus-within\:builder-border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .sm\:hover\:builder-border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .sm\:hover\:builder-border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:hover\:builder-border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:hover\:builder-border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:hover\:builder-border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:hover\:builder-border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:hover\:builder-border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:hover\:builder-border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:hover\:builder-border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:hover\:builder-border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:hover\:builder-border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:hover\:builder-border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:hover\:builder-border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:hover\:builder-border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:hover\:builder-border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .sm\:focus\:builder-border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .sm\:focus\:builder-border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:focus\:builder-border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:focus\:builder-border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:focus\:builder-border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:focus\:builder-border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:focus\:builder-border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:focus\:builder-border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:focus\:builder-border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:focus\:builder-border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:focus\:builder-border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:focus\:builder-border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:focus\:builder-border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:focus\:builder-border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:focus\:builder-border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .sm\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .sm\:builder-bg-current{
    background-color: currentColor !important;
  }

  .sm\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-current{
    background-color: currentColor !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .sm\:focus-within\:builder-bg-current:focus-within{
    background-color: currentColor !important;
  }

  .sm\:focus-within\:builder-bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-primary:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-secondary-dark:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:builder-bg-secondary-darker:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-transparent:hover{
    background-color: transparent !important;
  }

  .sm\:hover\:builder-bg-current:hover{
    background-color: currentColor !important;
  }

  .sm\:hover\:builder-bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-primary:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-secondary-dark:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:builder-bg-secondary-darker:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-transparent:focus{
    background-color: transparent !important;
  }

  .sm\:focus\:builder-bg-current:focus{
    background-color: currentColor !important;
  }

  .sm\:focus\:builder-bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-primary:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-secondary-dark:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:builder-bg-secondary-darker:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .sm\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:focus-within\:builder-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:hover\:builder-bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:hover\:builder-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:hover\:builder-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:hover\:builder-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:hover\:builder-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:hover\:builder-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:hover\:builder-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:hover\:builder-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:hover\:builder-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:hover\:builder-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:hover\:builder-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:hover\:builder-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:hover\:builder-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:hover\:builder-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:hover\:builder-bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:focus\:builder-bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:focus\:builder-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:focus\:builder-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:focus\:builder-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:focus\:builder-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:focus\:builder-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:focus\:builder-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:focus\:builder-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:focus\:builder-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:focus\:builder-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:focus\:builder-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:focus\:builder-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:focus\:builder-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:focus\:builder-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:focus\:builder-bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:builder-bg-none{
    background-image: none !important;
  }

  .sm\:builder-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .sm\:builder-from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:builder-from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:builder-from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:builder-from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:builder-from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:builder-from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:builder-from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:builder-from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:builder-from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:builder-from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:builder-from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:builder-from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:builder-from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:builder-from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:builder-from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:builder-from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:builder-from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:builder-from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:builder-from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:builder-from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:builder-from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:builder-from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:builder-from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:builder-from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:builder-from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:builder-from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:builder-from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:builder-from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:builder-from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:builder-from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:builder-from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:builder-from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:builder-from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:builder-from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:builder-from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:builder-from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:builder-from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:builder-from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:builder-from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:builder-from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:builder-from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:builder-from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:builder-from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:builder-from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:builder-from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:builder-from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:builder-from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:builder-from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:builder-from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:builder-from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:builder-from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:builder-from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:builder-from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:builder-from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:builder-from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:builder-from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:builder-from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:builder-from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:builder-from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:builder-from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:builder-from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:builder-from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:builder-from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:builder-from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:builder-from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:builder-from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:builder-from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:builder-from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:builder-from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:builder-from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:builder-from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:builder-from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:builder-from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:builder-from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:builder-from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:builder-from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:builder-from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:builder-from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:builder-from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:builder-from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:builder-from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:builder-from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:builder-from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:builder-from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:builder-from-primary{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .sm\:builder-from-secondary-dark{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .sm\:builder-from-secondary-darker{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .sm\:hover\:builder-from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:builder-from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:builder-from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:builder-from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:hover\:builder-from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:hover\:builder-from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:hover\:builder-from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:hover\:builder-from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:hover\:builder-from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:hover\:builder-from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:hover\:builder-from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:hover\:builder-from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:hover\:builder-from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:hover\:builder-from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:hover\:builder-from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:hover\:builder-from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:hover\:builder-from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:hover\:builder-from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:hover\:builder-from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:hover\:builder-from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:hover\:builder-from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:hover\:builder-from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:hover\:builder-from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:hover\:builder-from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:hover\:builder-from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:hover\:builder-from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:hover\:builder-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:hover\:builder-from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:hover\:builder-from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:hover\:builder-from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:hover\:builder-from-primary:hover{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .sm\:hover\:builder-from-secondary-dark:hover{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .sm\:hover\:builder-from-secondary-darker:hover{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .sm\:focus\:builder-from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:builder-from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:builder-from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:builder-from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:focus\:builder-from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:focus\:builder-from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:focus\:builder-from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:focus\:builder-from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:focus\:builder-from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:focus\:builder-from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:focus\:builder-from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:focus\:builder-from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:focus\:builder-from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:focus\:builder-from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:focus\:builder-from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:focus\:builder-from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:focus\:builder-from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:focus\:builder-from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:focus\:builder-from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:focus\:builder-from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:focus\:builder-from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:focus\:builder-from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:focus\:builder-from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:focus\:builder-from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:focus\:builder-from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:focus\:builder-from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:focus\:builder-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:focus\:builder-from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:focus\:builder-from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:focus\:builder-from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:focus\:builder-from-primary:focus{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .sm\:focus\:builder-from-secondary-dark:focus{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .sm\:focus\:builder-from-secondary-darker:focus{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .sm\:builder-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:builder-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:builder-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:builder-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:builder-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:builder-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:builder-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:builder-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:builder-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:builder-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:builder-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:builder-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:builder-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:builder-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:builder-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:builder-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:builder-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:builder-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:builder-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:builder-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:builder-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:builder-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:builder-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:builder-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:builder-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:builder-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:builder-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:builder-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:builder-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:builder-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:builder-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:builder-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:builder-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:builder-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:builder-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:builder-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:builder-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:builder-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:builder-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:builder-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:builder-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:builder-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:builder-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:builder-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:builder-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:builder-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:builder-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:builder-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:builder-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:builder-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:builder-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:builder-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:builder-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:builder-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:builder-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:builder-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:builder-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:builder-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:builder-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:builder-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:builder-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:builder-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:builder-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:builder-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:builder-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:builder-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:builder-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:builder-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:builder-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:builder-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:builder-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:builder-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:builder-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:builder-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:builder-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:builder-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:builder-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:builder-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:builder-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:builder-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:builder-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:builder-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:builder-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:builder-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:builder-via-primary{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .sm\:builder-via-secondary-dark{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .sm\:builder-via-secondary-darker{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .sm\:hover\:builder-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:builder-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:builder-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:builder-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:hover\:builder-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:hover\:builder-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:hover\:builder-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:hover\:builder-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:hover\:builder-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:hover\:builder-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:hover\:builder-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:hover\:builder-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:hover\:builder-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:hover\:builder-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:hover\:builder-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:hover\:builder-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:hover\:builder-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:hover\:builder-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:hover\:builder-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:hover\:builder-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:hover\:builder-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:hover\:builder-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:hover\:builder-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:hover\:builder-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:hover\:builder-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:hover\:builder-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:hover\:builder-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:hover\:builder-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:hover\:builder-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:hover\:builder-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:hover\:builder-via-primary:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .sm\:hover\:builder-via-secondary-dark:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .sm\:hover\:builder-via-secondary-darker:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .sm\:focus\:builder-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:builder-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:builder-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:builder-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:focus\:builder-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:focus\:builder-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:focus\:builder-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:focus\:builder-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:focus\:builder-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:focus\:builder-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:focus\:builder-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:focus\:builder-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:focus\:builder-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:focus\:builder-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:focus\:builder-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:focus\:builder-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:focus\:builder-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:focus\:builder-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:focus\:builder-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:focus\:builder-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:focus\:builder-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:focus\:builder-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:focus\:builder-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:focus\:builder-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:focus\:builder-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:focus\:builder-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:focus\:builder-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:focus\:builder-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:focus\:builder-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:focus\:builder-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:focus\:builder-via-primary:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .sm\:focus\:builder-via-secondary-dark:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .sm\:focus\:builder-via-secondary-darker:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .sm\:builder-to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .sm\:builder-to-current{
    --tw-gradient-to: currentColor !important;
  }

  .sm\:builder-to-black{
    --tw-gradient-to: #000 !important;
  }

  .sm\:builder-to-white{
    --tw-gradient-to: #fff !important;
  }

  .sm\:builder-to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .sm\:builder-to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .sm\:builder-to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .sm\:builder-to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .sm\:builder-to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .sm\:builder-to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .sm\:builder-to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .sm\:builder-to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .sm\:builder-to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .sm\:builder-to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .sm\:builder-to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .sm\:builder-to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .sm\:builder-to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .sm\:builder-to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .sm\:builder-to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .sm\:builder-to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .sm\:builder-to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .sm\:builder-to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .sm\:builder-to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .sm\:builder-to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .sm\:builder-to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .sm\:builder-to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .sm\:builder-to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .sm\:builder-to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .sm\:builder-to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .sm\:builder-to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .sm\:builder-to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .sm\:builder-to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .sm\:builder-to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .sm\:builder-to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .sm\:builder-to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .sm\:builder-to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .sm\:builder-to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .sm\:builder-to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .sm\:builder-to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .sm\:builder-to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .sm\:builder-to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .sm\:builder-to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .sm\:builder-to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .sm\:builder-to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .sm\:builder-to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .sm\:builder-to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .sm\:builder-to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .sm\:builder-to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .sm\:builder-to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .sm\:builder-to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .sm\:builder-to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .sm\:builder-to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .sm\:builder-to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .sm\:builder-to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .sm\:builder-to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .sm\:builder-to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .sm\:builder-to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .sm\:builder-to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .sm\:builder-to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .sm\:builder-to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .sm\:builder-to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .sm\:builder-to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .sm\:builder-to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .sm\:builder-to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .sm\:builder-to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .sm\:builder-to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .sm\:builder-to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .sm\:builder-to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .sm\:builder-to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .sm\:builder-to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .sm\:builder-to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .sm\:builder-to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .sm\:builder-to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .sm\:builder-to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .sm\:builder-to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .sm\:builder-to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .sm\:builder-to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .sm\:builder-to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .sm\:builder-to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .sm\:builder-to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .sm\:builder-to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .sm\:builder-to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .sm\:builder-to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .sm\:builder-to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .sm\:builder-to-primary{
    --tw-gradient-to: #2d375a !important;
  }

  .sm\:builder-to-secondary-dark{
    --tw-gradient-to: #343434 !important;
  }

  .sm\:builder-to-secondary-darker{
    --tw-gradient-to: #212121 !important;
  }

  .sm\:hover\:builder-to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .sm\:hover\:builder-to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .sm\:hover\:builder-to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .sm\:hover\:builder-to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .sm\:hover\:builder-to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .sm\:hover\:builder-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .sm\:hover\:builder-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .sm\:hover\:builder-to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .sm\:hover\:builder-to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .sm\:hover\:builder-to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .sm\:hover\:builder-to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .sm\:hover\:builder-to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .sm\:hover\:builder-to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .sm\:hover\:builder-to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .sm\:hover\:builder-to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .sm\:hover\:builder-to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .sm\:hover\:builder-to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .sm\:hover\:builder-to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .sm\:hover\:builder-to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .sm\:hover\:builder-to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .sm\:hover\:builder-to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .sm\:hover\:builder-to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .sm\:hover\:builder-to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .sm\:hover\:builder-to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .sm\:hover\:builder-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .sm\:hover\:builder-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .sm\:hover\:builder-to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .sm\:hover\:builder-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .sm\:hover\:builder-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .sm\:hover\:builder-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .sm\:hover\:builder-to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .sm\:hover\:builder-to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .sm\:hover\:builder-to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .sm\:hover\:builder-to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .sm\:hover\:builder-to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .sm\:hover\:builder-to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .sm\:hover\:builder-to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .sm\:hover\:builder-to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .sm\:hover\:builder-to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .sm\:hover\:builder-to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .sm\:hover\:builder-to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .sm\:hover\:builder-to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .sm\:hover\:builder-to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .sm\:hover\:builder-to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .sm\:hover\:builder-to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .sm\:hover\:builder-to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .sm\:hover\:builder-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .sm\:hover\:builder-to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .sm\:hover\:builder-to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .sm\:hover\:builder-to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .sm\:hover\:builder-to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .sm\:hover\:builder-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .sm\:hover\:builder-to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .sm\:hover\:builder-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .sm\:hover\:builder-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .sm\:hover\:builder-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .sm\:hover\:builder-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .sm\:hover\:builder-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .sm\:hover\:builder-to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .sm\:hover\:builder-to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .sm\:hover\:builder-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .sm\:hover\:builder-to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .sm\:hover\:builder-to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .sm\:hover\:builder-to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .sm\:hover\:builder-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .sm\:hover\:builder-to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .sm\:hover\:builder-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .sm\:hover\:builder-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .sm\:hover\:builder-to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .sm\:hover\:builder-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .sm\:hover\:builder-to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .sm\:hover\:builder-to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .sm\:hover\:builder-to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .sm\:hover\:builder-to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .sm\:hover\:builder-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .sm\:hover\:builder-to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .sm\:hover\:builder-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .sm\:hover\:builder-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .sm\:hover\:builder-to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .sm\:hover\:builder-to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .sm\:hover\:builder-to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .sm\:hover\:builder-to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .sm\:hover\:builder-to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .sm\:hover\:builder-to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .sm\:hover\:builder-to-primary:hover{
    --tw-gradient-to: #2d375a !important;
  }

  .sm\:hover\:builder-to-secondary-dark:hover{
    --tw-gradient-to: #343434 !important;
  }

  .sm\:hover\:builder-to-secondary-darker:hover{
    --tw-gradient-to: #212121 !important;
  }

  .sm\:focus\:builder-to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .sm\:focus\:builder-to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .sm\:focus\:builder-to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .sm\:focus\:builder-to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .sm\:focus\:builder-to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .sm\:focus\:builder-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .sm\:focus\:builder-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .sm\:focus\:builder-to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .sm\:focus\:builder-to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .sm\:focus\:builder-to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .sm\:focus\:builder-to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .sm\:focus\:builder-to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .sm\:focus\:builder-to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .sm\:focus\:builder-to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .sm\:focus\:builder-to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .sm\:focus\:builder-to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .sm\:focus\:builder-to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .sm\:focus\:builder-to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .sm\:focus\:builder-to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .sm\:focus\:builder-to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .sm\:focus\:builder-to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .sm\:focus\:builder-to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .sm\:focus\:builder-to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .sm\:focus\:builder-to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .sm\:focus\:builder-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .sm\:focus\:builder-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .sm\:focus\:builder-to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .sm\:focus\:builder-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .sm\:focus\:builder-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .sm\:focus\:builder-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .sm\:focus\:builder-to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .sm\:focus\:builder-to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .sm\:focus\:builder-to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .sm\:focus\:builder-to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .sm\:focus\:builder-to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .sm\:focus\:builder-to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .sm\:focus\:builder-to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .sm\:focus\:builder-to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .sm\:focus\:builder-to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .sm\:focus\:builder-to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .sm\:focus\:builder-to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .sm\:focus\:builder-to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .sm\:focus\:builder-to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .sm\:focus\:builder-to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .sm\:focus\:builder-to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .sm\:focus\:builder-to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .sm\:focus\:builder-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .sm\:focus\:builder-to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .sm\:focus\:builder-to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .sm\:focus\:builder-to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .sm\:focus\:builder-to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .sm\:focus\:builder-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .sm\:focus\:builder-to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .sm\:focus\:builder-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .sm\:focus\:builder-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .sm\:focus\:builder-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .sm\:focus\:builder-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .sm\:focus\:builder-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .sm\:focus\:builder-to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .sm\:focus\:builder-to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .sm\:focus\:builder-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .sm\:focus\:builder-to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .sm\:focus\:builder-to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .sm\:focus\:builder-to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .sm\:focus\:builder-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .sm\:focus\:builder-to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .sm\:focus\:builder-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .sm\:focus\:builder-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .sm\:focus\:builder-to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .sm\:focus\:builder-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .sm\:focus\:builder-to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .sm\:focus\:builder-to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .sm\:focus\:builder-to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .sm\:focus\:builder-to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .sm\:focus\:builder-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .sm\:focus\:builder-to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .sm\:focus\:builder-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .sm\:focus\:builder-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .sm\:focus\:builder-to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .sm\:focus\:builder-to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .sm\:focus\:builder-to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .sm\:focus\:builder-to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .sm\:focus\:builder-to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .sm\:focus\:builder-to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .sm\:focus\:builder-to-primary:focus{
    --tw-gradient-to: #2d375a !important;
  }

  .sm\:focus\:builder-to-secondary-dark:focus{
    --tw-gradient-to: #343434 !important;
  }

  .sm\:focus\:builder-to-secondary-darker:focus{
    --tw-gradient-to: #212121 !important;
  }

  .sm\:builder-decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .sm\:builder-decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .sm\:builder-bg-auto{
    background-size: auto !important;
  }

  .sm\:builder-bg-cover{
    background-size: cover !important;
  }

  .sm\:builder-bg-contain{
    background-size: contain !important;
  }

  .sm\:builder-bg-fixed{
    background-attachment: fixed !important;
  }

  .sm\:builder-bg-local{
    background-attachment: local !important;
  }

  .sm\:builder-bg-scroll{
    background-attachment: scroll !important;
  }

  .sm\:builder-bg-clip-border{
    background-clip: border-box !important;
  }

  .sm\:builder-bg-clip-padding{
    background-clip: padding-box !important;
  }

  .sm\:builder-bg-clip-content{
    background-clip: content-box !important;
  }

  .sm\:builder-bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .sm\:builder-bg-bottom{
    background-position: bottom !important;
  }

  .sm\:builder-bg-center{
    background-position: center !important;
  }

  .sm\:builder-bg-left{
    background-position: left !important;
  }

  .sm\:builder-bg-left-bottom{
    background-position: left bottom !important;
  }

  .sm\:builder-bg-left-top{
    background-position: left top !important;
  }

  .sm\:builder-bg-right{
    background-position: right !important;
  }

  .sm\:builder-bg-right-bottom{
    background-position: right bottom !important;
  }

  .sm\:builder-bg-right-top{
    background-position: right top !important;
  }

  .sm\:builder-bg-top{
    background-position: top !important;
  }

  .sm\:builder-bg-repeat{
    background-repeat: repeat !important;
  }

  .sm\:builder-bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .sm\:builder-bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .sm\:builder-bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .sm\:builder-bg-repeat-round{
    background-repeat: round !important;
  }

  .sm\:builder-bg-repeat-space{
    background-repeat: space !important;
  }

  .sm\:builder-bg-origin-border{
    background-origin: border-box !important;
  }

  .sm\:builder-bg-origin-padding{
    background-origin: padding-box !important;
  }

  .sm\:builder-bg-origin-content{
    background-origin: content-box !important;
  }

  .sm\:builder-fill-current{
    fill: currentColor !important;
  }

  .sm\:builder-stroke-current{
    stroke: currentColor !important;
  }

  .sm\:builder-stroke-0{
    stroke-width: 0 !important;
  }

  .sm\:builder-stroke-1{
    stroke-width: 1 !important;
  }

  .sm\:builder-stroke-2{
    stroke-width: 2 !important;
  }

  .sm\:builder-object-contain{
    object-fit: contain !important;
  }

  .sm\:builder-object-cover{
    object-fit: cover !important;
  }

  .sm\:builder-object-fill{
    object-fit: fill !important;
  }

  .sm\:builder-object-none{
    object-fit: none !important;
  }

  .sm\:builder-object-scale-down{
    object-fit: scale-down !important;
  }

  .sm\:builder-object-bottom{
    object-position: bottom !important;
  }

  .sm\:builder-object-center{
    object-position: center !important;
  }

  .sm\:builder-object-left{
    object-position: left !important;
  }

  .sm\:builder-object-left-bottom{
    object-position: left bottom !important;
  }

  .sm\:builder-object-left-top{
    object-position: left top !important;
  }

  .sm\:builder-object-right{
    object-position: right !important;
  }

  .sm\:builder-object-right-bottom{
    object-position: right bottom !important;
  }

  .sm\:builder-object-right-top{
    object-position: right top !important;
  }

  .sm\:builder-object-top{
    object-position: top !important;
  }

  .sm\:builder-p-0{
    padding: 0px !important;
  }

  .sm\:builder-p-1{
    padding: 0.25rem !important;
  }

  .sm\:builder-p-2{
    padding: 0.5rem !important;
  }

  .sm\:builder-p-3{
    padding: 0.75rem !important;
  }

  .sm\:builder-p-4{
    padding: 1rem !important;
  }

  .sm\:builder-p-5{
    padding: 1.25rem !important;
  }

  .sm\:builder-p-6{
    padding: 1.5rem !important;
  }

  .sm\:builder-p-7{
    padding: 1.75rem !important;
  }

  .sm\:builder-p-8{
    padding: 2rem !important;
  }

  .sm\:builder-p-9{
    padding: 2.25rem !important;
  }

  .sm\:builder-p-10{
    padding: 2.5rem !important;
  }

  .sm\:builder-p-11{
    padding: 2.75rem !important;
  }

  .sm\:builder-p-12{
    padding: 3rem !important;
  }

  .sm\:builder-p-14{
    padding: 3.5rem !important;
  }

  .sm\:builder-p-16{
    padding: 4rem !important;
  }

  .sm\:builder-p-20{
    padding: 5rem !important;
  }

  .sm\:builder-p-24{
    padding: 6rem !important;
  }

  .sm\:builder-p-28{
    padding: 7rem !important;
  }

  .sm\:builder-p-32{
    padding: 8rem !important;
  }

  .sm\:builder-p-36{
    padding: 9rem !important;
  }

  .sm\:builder-p-40{
    padding: 10rem !important;
  }

  .sm\:builder-p-44{
    padding: 11rem !important;
  }

  .sm\:builder-p-48{
    padding: 12rem !important;
  }

  .sm\:builder-p-52{
    padding: 13rem !important;
  }

  .sm\:builder-p-56{
    padding: 14rem !important;
  }

  .sm\:builder-p-60{
    padding: 15rem !important;
  }

  .sm\:builder-p-64{
    padding: 16rem !important;
  }

  .sm\:builder-p-72{
    padding: 18rem !important;
  }

  .sm\:builder-p-80{
    padding: 20rem !important;
  }

  .sm\:builder-p-96{
    padding: 24rem !important;
  }

  .sm\:builder-p-px{
    padding: 1px !important;
  }

  .sm\:builder-p-0\.5{
    padding: 0.125rem !important;
  }

  .sm\:builder-p-1\.5{
    padding: 0.375rem !important;
  }

  .sm\:builder-p-2\.5{
    padding: 0.625rem !important;
  }

  .sm\:builder-p-3\.5{
    padding: 0.875rem !important;
  }

  .sm\:builder-px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .sm\:builder-px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:builder-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .sm\:builder-px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .sm\:builder-px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:builder-px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .sm\:builder-px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:builder-px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .sm\:builder-px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:builder-px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .sm\:builder-px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .sm\:builder-px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .sm\:builder-px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:builder-px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .sm\:builder-px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:builder-px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:builder-px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .sm\:builder-px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .sm\:builder-px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .sm\:builder-px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .sm\:builder-px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .sm\:builder-px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .sm\:builder-px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .sm\:builder-px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .sm\:builder-px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .sm\:builder-px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .sm\:builder-px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .sm\:builder-px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .sm\:builder-px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .sm\:builder-px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .sm\:builder-px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .sm\:builder-px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .sm\:builder-px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .sm\:builder-px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .sm\:builder-px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .sm\:builder-py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .sm\:builder-py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .sm\:builder-py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:builder-py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .sm\:builder-py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:builder-py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .sm\:builder-py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:builder-py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .sm\:builder-py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:builder-py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .sm\:builder-py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .sm\:builder-py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .sm\:builder-py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:builder-py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .sm\:builder-py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:builder-py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .sm\:builder-py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .sm\:builder-py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .sm\:builder-py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .sm\:builder-py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .sm\:builder-py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .sm\:builder-py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .sm\:builder-py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .sm\:builder-py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .sm\:builder-py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .sm\:builder-py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .sm\:builder-py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .sm\:builder-py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .sm\:builder-py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .sm\:builder-py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .sm\:builder-py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .sm\:builder-py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .sm\:builder-py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .sm\:builder-py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .sm\:builder-py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .sm\:builder-pt-0{
    padding-top: 0px !important;
  }

  .sm\:builder-pt-1{
    padding-top: 0.25rem !important;
  }

  .sm\:builder-pt-2{
    padding-top: 0.5rem !important;
  }

  .sm\:builder-pt-3{
    padding-top: 0.75rem !important;
  }

  .sm\:builder-pt-4{
    padding-top: 1rem !important;
  }

  .sm\:builder-pt-5{
    padding-top: 1.25rem !important;
  }

  .sm\:builder-pt-6{
    padding-top: 1.5rem !important;
  }

  .sm\:builder-pt-7{
    padding-top: 1.75rem !important;
  }

  .sm\:builder-pt-8{
    padding-top: 2rem !important;
  }

  .sm\:builder-pt-9{
    padding-top: 2.25rem !important;
  }

  .sm\:builder-pt-10{
    padding-top: 2.5rem !important;
  }

  .sm\:builder-pt-11{
    padding-top: 2.75rem !important;
  }

  .sm\:builder-pt-12{
    padding-top: 3rem !important;
  }

  .sm\:builder-pt-14{
    padding-top: 3.5rem !important;
  }

  .sm\:builder-pt-16{
    padding-top: 4rem !important;
  }

  .sm\:builder-pt-20{
    padding-top: 5rem !important;
  }

  .sm\:builder-pt-24{
    padding-top: 6rem !important;
  }

  .sm\:builder-pt-28{
    padding-top: 7rem !important;
  }

  .sm\:builder-pt-32{
    padding-top: 8rem !important;
  }

  .sm\:builder-pt-36{
    padding-top: 9rem !important;
  }

  .sm\:builder-pt-40{
    padding-top: 10rem !important;
  }

  .sm\:builder-pt-44{
    padding-top: 11rem !important;
  }

  .sm\:builder-pt-48{
    padding-top: 12rem !important;
  }

  .sm\:builder-pt-52{
    padding-top: 13rem !important;
  }

  .sm\:builder-pt-56{
    padding-top: 14rem !important;
  }

  .sm\:builder-pt-60{
    padding-top: 15rem !important;
  }

  .sm\:builder-pt-64{
    padding-top: 16rem !important;
  }

  .sm\:builder-pt-72{
    padding-top: 18rem !important;
  }

  .sm\:builder-pt-80{
    padding-top: 20rem !important;
  }

  .sm\:builder-pt-96{
    padding-top: 24rem !important;
  }

  .sm\:builder-pt-px{
    padding-top: 1px !important;
  }

  .sm\:builder-pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .sm\:builder-pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .sm\:builder-pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .sm\:builder-pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .sm\:builder-pr-0{
    padding-right: 0px !important;
  }

  .sm\:builder-pr-1{
    padding-right: 0.25rem !important;
  }

  .sm\:builder-pr-2{
    padding-right: 0.5rem !important;
  }

  .sm\:builder-pr-3{
    padding-right: 0.75rem !important;
  }

  .sm\:builder-pr-4{
    padding-right: 1rem !important;
  }

  .sm\:builder-pr-5{
    padding-right: 1.25rem !important;
  }

  .sm\:builder-pr-6{
    padding-right: 1.5rem !important;
  }

  .sm\:builder-pr-7{
    padding-right: 1.75rem !important;
  }

  .sm\:builder-pr-8{
    padding-right: 2rem !important;
  }

  .sm\:builder-pr-9{
    padding-right: 2.25rem !important;
  }

  .sm\:builder-pr-10{
    padding-right: 2.5rem !important;
  }

  .sm\:builder-pr-11{
    padding-right: 2.75rem !important;
  }

  .sm\:builder-pr-12{
    padding-right: 3rem !important;
  }

  .sm\:builder-pr-14{
    padding-right: 3.5rem !important;
  }

  .sm\:builder-pr-16{
    padding-right: 4rem !important;
  }

  .sm\:builder-pr-20{
    padding-right: 5rem !important;
  }

  .sm\:builder-pr-24{
    padding-right: 6rem !important;
  }

  .sm\:builder-pr-28{
    padding-right: 7rem !important;
  }

  .sm\:builder-pr-32{
    padding-right: 8rem !important;
  }

  .sm\:builder-pr-36{
    padding-right: 9rem !important;
  }

  .sm\:builder-pr-40{
    padding-right: 10rem !important;
  }

  .sm\:builder-pr-44{
    padding-right: 11rem !important;
  }

  .sm\:builder-pr-48{
    padding-right: 12rem !important;
  }

  .sm\:builder-pr-52{
    padding-right: 13rem !important;
  }

  .sm\:builder-pr-56{
    padding-right: 14rem !important;
  }

  .sm\:builder-pr-60{
    padding-right: 15rem !important;
  }

  .sm\:builder-pr-64{
    padding-right: 16rem !important;
  }

  .sm\:builder-pr-72{
    padding-right: 18rem !important;
  }

  .sm\:builder-pr-80{
    padding-right: 20rem !important;
  }

  .sm\:builder-pr-96{
    padding-right: 24rem !important;
  }

  .sm\:builder-pr-px{
    padding-right: 1px !important;
  }

  .sm\:builder-pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .sm\:builder-pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .sm\:builder-pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .sm\:builder-pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .sm\:builder-pb-0{
    padding-bottom: 0px !important;
  }

  .sm\:builder-pb-1{
    padding-bottom: 0.25rem !important;
  }

  .sm\:builder-pb-2{
    padding-bottom: 0.5rem !important;
  }

  .sm\:builder-pb-3{
    padding-bottom: 0.75rem !important;
  }

  .sm\:builder-pb-4{
    padding-bottom: 1rem !important;
  }

  .sm\:builder-pb-5{
    padding-bottom: 1.25rem !important;
  }

  .sm\:builder-pb-6{
    padding-bottom: 1.5rem !important;
  }

  .sm\:builder-pb-7{
    padding-bottom: 1.75rem !important;
  }

  .sm\:builder-pb-8{
    padding-bottom: 2rem !important;
  }

  .sm\:builder-pb-9{
    padding-bottom: 2.25rem !important;
  }

  .sm\:builder-pb-10{
    padding-bottom: 2.5rem !important;
  }

  .sm\:builder-pb-11{
    padding-bottom: 2.75rem !important;
  }

  .sm\:builder-pb-12{
    padding-bottom: 3rem !important;
  }

  .sm\:builder-pb-14{
    padding-bottom: 3.5rem !important;
  }

  .sm\:builder-pb-16{
    padding-bottom: 4rem !important;
  }

  .sm\:builder-pb-20{
    padding-bottom: 5rem !important;
  }

  .sm\:builder-pb-24{
    padding-bottom: 6rem !important;
  }

  .sm\:builder-pb-28{
    padding-bottom: 7rem !important;
  }

  .sm\:builder-pb-32{
    padding-bottom: 8rem !important;
  }

  .sm\:builder-pb-36{
    padding-bottom: 9rem !important;
  }

  .sm\:builder-pb-40{
    padding-bottom: 10rem !important;
  }

  .sm\:builder-pb-44{
    padding-bottom: 11rem !important;
  }

  .sm\:builder-pb-48{
    padding-bottom: 12rem !important;
  }

  .sm\:builder-pb-52{
    padding-bottom: 13rem !important;
  }

  .sm\:builder-pb-56{
    padding-bottom: 14rem !important;
  }

  .sm\:builder-pb-60{
    padding-bottom: 15rem !important;
  }

  .sm\:builder-pb-64{
    padding-bottom: 16rem !important;
  }

  .sm\:builder-pb-72{
    padding-bottom: 18rem !important;
  }

  .sm\:builder-pb-80{
    padding-bottom: 20rem !important;
  }

  .sm\:builder-pb-96{
    padding-bottom: 24rem !important;
  }

  .sm\:builder-pb-px{
    padding-bottom: 1px !important;
  }

  .sm\:builder-pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .sm\:builder-pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .sm\:builder-pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .sm\:builder-pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .sm\:builder-pl-0{
    padding-left: 0px !important;
  }

  .sm\:builder-pl-1{
    padding-left: 0.25rem !important;
  }

  .sm\:builder-pl-2{
    padding-left: 0.5rem !important;
  }

  .sm\:builder-pl-3{
    padding-left: 0.75rem !important;
  }

  .sm\:builder-pl-4{
    padding-left: 1rem !important;
  }

  .sm\:builder-pl-5{
    padding-left: 1.25rem !important;
  }

  .sm\:builder-pl-6{
    padding-left: 1.5rem !important;
  }

  .sm\:builder-pl-7{
    padding-left: 1.75rem !important;
  }

  .sm\:builder-pl-8{
    padding-left: 2rem !important;
  }

  .sm\:builder-pl-9{
    padding-left: 2.25rem !important;
  }

  .sm\:builder-pl-10{
    padding-left: 2.5rem !important;
  }

  .sm\:builder-pl-11{
    padding-left: 2.75rem !important;
  }

  .sm\:builder-pl-12{
    padding-left: 3rem !important;
  }

  .sm\:builder-pl-14{
    padding-left: 3.5rem !important;
  }

  .sm\:builder-pl-16{
    padding-left: 4rem !important;
  }

  .sm\:builder-pl-20{
    padding-left: 5rem !important;
  }

  .sm\:builder-pl-24{
    padding-left: 6rem !important;
  }

  .sm\:builder-pl-28{
    padding-left: 7rem !important;
  }

  .sm\:builder-pl-32{
    padding-left: 8rem !important;
  }

  .sm\:builder-pl-36{
    padding-left: 9rem !important;
  }

  .sm\:builder-pl-40{
    padding-left: 10rem !important;
  }

  .sm\:builder-pl-44{
    padding-left: 11rem !important;
  }

  .sm\:builder-pl-48{
    padding-left: 12rem !important;
  }

  .sm\:builder-pl-52{
    padding-left: 13rem !important;
  }

  .sm\:builder-pl-56{
    padding-left: 14rem !important;
  }

  .sm\:builder-pl-60{
    padding-left: 15rem !important;
  }

  .sm\:builder-pl-64{
    padding-left: 16rem !important;
  }

  .sm\:builder-pl-72{
    padding-left: 18rem !important;
  }

  .sm\:builder-pl-80{
    padding-left: 20rem !important;
  }

  .sm\:builder-pl-96{
    padding-left: 24rem !important;
  }

  .sm\:builder-pl-px{
    padding-left: 1px !important;
  }

  .sm\:builder-pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .sm\:builder-pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .sm\:builder-pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .sm\:builder-pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .sm\:builder-text-left{
    text-align: left !important;
  }

  .sm\:builder-text-center{
    text-align: center !important;
  }

  .sm\:builder-text-right{
    text-align: right !important;
  }

  .sm\:builder-text-justify{
    text-align: justify !important;
  }

  .sm\:builder-align-baseline{
    vertical-align: baseline !important;
  }

  .sm\:builder-align-top{
    vertical-align: top !important;
  }

  .sm\:builder-align-middle{
    vertical-align: middle !important;
  }

  .sm\:builder-align-bottom{
    vertical-align: bottom !important;
  }

  .sm\:builder-align-text-top{
    vertical-align: text-top !important;
  }

  .sm\:builder-align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .sm\:builder-font-primary{
    font-family: proxima-nova !important;
  }

  .sm\:builder-text-sm{
    font-size: 14px !important;
  }

  .sm\:builder-text-md{
    font-size: 15px !important;
  }

  .sm\:builder-text-2xl{
    font-size: 24px !important;
  }

  .sm\:builder-font-thin{
    font-weight: 100 !important;
  }

  .sm\:builder-font-extralight{
    font-weight: 200 !important;
  }

  .sm\:builder-font-light{
    font-weight: 300 !important;
  }

  .sm\:builder-font-normal{
    font-weight: 400 !important;
  }

  .sm\:builder-font-medium{
    font-weight: 500 !important;
  }

  .sm\:builder-font-semibold{
    font-weight: 600 !important;
  }

  .sm\:builder-font-bold{
    font-weight: 700 !important;
  }

  .sm\:builder-font-extrabold{
    font-weight: 800 !important;
  }

  .sm\:builder-font-black{
    font-weight: 900 !important;
  }

  .sm\:builder-uppercase{
    text-transform: uppercase !important;
  }

  .sm\:builder-lowercase{
    text-transform: lowercase !important;
  }

  .sm\:builder-capitalize{
    text-transform: capitalize !important;
  }

  .sm\:builder-normal-case{
    text-transform: none !important;
  }

  .sm\:builder-italic{
    font-style: italic !important;
  }

  .sm\:builder-not-italic{
    font-style: normal !important;
  }

  .sm\:builder-ordinal, .sm\:builder-slashed-zero, .sm\:builder-lining-nums, .sm\:builder-oldstyle-nums, .sm\:builder-proportional-nums, .sm\:builder-tabular-nums, .sm\:builder-diagonal-fractions, .sm\:builder-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .sm\:builder-normal-nums{
    font-variant-numeric: normal !important;
  }

  .sm\:builder-ordinal{
    --tw-ordinal: ordinal !important;
  }

  .sm\:builder-slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .sm\:builder-lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .sm\:builder-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .sm\:builder-proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .sm\:builder-tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .sm\:builder-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .sm\:builder-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .sm\:builder-leading-3{
    line-height: .75rem !important;
  }

  .sm\:builder-leading-4{
    line-height: 1rem !important;
  }

  .sm\:builder-leading-5{
    line-height: 1.25rem !important;
  }

  .sm\:builder-leading-6{
    line-height: 1.5rem !important;
  }

  .sm\:builder-leading-7{
    line-height: 1.75rem !important;
  }

  .sm\:builder-leading-8{
    line-height: 2rem !important;
  }

  .sm\:builder-leading-9{
    line-height: 2.25rem !important;
  }

  .sm\:builder-leading-10{
    line-height: 2.5rem !important;
  }

  .sm\:builder-leading-none{
    line-height: 1 !important;
  }

  .sm\:builder-leading-tight{
    line-height: 1.25 !important;
  }

  .sm\:builder-leading-snug{
    line-height: 1.375 !important;
  }

  .sm\:builder-leading-normal{
    line-height: 1.5 !important;
  }

  .sm\:builder-leading-relaxed{
    line-height: 1.625 !important;
  }

  .sm\:builder-leading-loose{
    line-height: 2 !important;
  }

  .sm\:builder-tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .sm\:builder-tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .sm\:builder-tracking-normal{
    letter-spacing: 0em !important;
  }

  .sm\:builder-tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .sm\:builder-tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .sm\:builder-tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .sm\:builder-text-transparent{
    color: transparent !important;
  }

  .sm\:builder-text-current{
    color: currentColor !important;
  }

  .sm\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-transparent{
    color: transparent !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-current{
    color: currentColor !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-transparent:focus-within{
    color: transparent !important;
  }

  .sm\:focus-within\:builder-text-current:focus-within{
    color: currentColor !important;
  }

  .sm\:focus-within\:builder-text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-primary:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-secondary-dark:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:builder-text-secondary-darker:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-transparent:hover{
    color: transparent !important;
  }

  .sm\:hover\:builder-text-current:hover{
    color: currentColor !important;
  }

  .sm\:hover\:builder-text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-primary:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-secondary-dark:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:builder-text-secondary-darker:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-transparent:focus{
    color: transparent !important;
  }

  .sm\:focus\:builder-text-current:focus{
    color: currentColor !important;
  }

  .sm\:focus\:builder-text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-primary:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-secondary-dark:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:builder-text-secondary-darker:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .sm\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .sm\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .sm\:focus-within\:builder-text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .sm\:focus-within\:builder-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:focus-within\:builder-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:focus-within\:builder-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:focus-within\:builder-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:focus-within\:builder-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:focus-within\:builder-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:focus-within\:builder-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:focus-within\:builder-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:focus-within\:builder-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:focus-within\:builder-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:focus-within\:builder-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:focus-within\:builder-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:focus-within\:builder-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:focus-within\:builder-text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .sm\:hover\:builder-text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .sm\:hover\:builder-text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:hover\:builder-text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:hover\:builder-text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:hover\:builder-text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:hover\:builder-text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:hover\:builder-text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:hover\:builder-text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:hover\:builder-text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:hover\:builder-text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:hover\:builder-text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:hover\:builder-text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:hover\:builder-text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:hover\:builder-text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:hover\:builder-text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .sm\:focus\:builder-text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .sm\:focus\:builder-text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:focus\:builder-text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:focus\:builder-text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:focus\:builder-text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:focus\:builder-text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:focus\:builder-text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:focus\:builder-text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:focus\:builder-text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:focus\:builder-text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:focus\:builder-text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:focus\:builder-text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:focus\:builder-text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:focus\:builder-text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:focus\:builder-text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .sm\:builder-underline{
    text-decoration: underline !important;
  }

  .sm\:builder-line-through{
    text-decoration: line-through !important;
  }

  .sm\:builder-no-underline{
    text-decoration: none !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-underline{
    text-decoration: underline !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-line-through{
    text-decoration: line-through !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-no-underline{
    text-decoration: none !important;
  }

  .sm\:focus-within\:builder-underline:focus-within{
    text-decoration: underline !important;
  }

  .sm\:focus-within\:builder-line-through:focus-within{
    text-decoration: line-through !important;
  }

  .sm\:focus-within\:builder-no-underline:focus-within{
    text-decoration: none !important;
  }

  .sm\:hover\:builder-underline:hover{
    text-decoration: underline !important;
  }

  .sm\:hover\:builder-line-through:hover{
    text-decoration: line-through !important;
  }

  .sm\:hover\:builder-no-underline:hover{
    text-decoration: none !important;
  }

  .sm\:focus\:builder-underline:focus{
    text-decoration: underline !important;
  }

  .sm\:focus\:builder-line-through:focus{
    text-decoration: line-through !important;
  }

  .sm\:focus\:builder-no-underline:focus{
    text-decoration: none !important;
  }

  .sm\:builder-antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .sm\:builder-subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .sm\:builder-placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .sm\:builder-placeholder-current::placeholder{
    color: currentColor !important;
  }

  .sm\:builder-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-primary::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-secondary-dark::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-secondary-darker::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .sm\:focus\:builder-placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .sm\:focus\:builder-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-primary:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-secondary-dark:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:builder-placeholder-secondary-darker:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:builder-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:builder-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:builder-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:builder-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:builder-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:builder-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:builder-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:builder-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:builder-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:builder-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:builder-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:builder-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:builder-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:builder-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:builder-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:focus\:builder-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:builder-opacity-0{
    opacity: 0 !important;
  }

  .sm\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .sm\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .sm\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .sm\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .sm\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .sm\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .sm\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .sm\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .sm\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .sm\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .sm\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .sm\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .sm\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .sm\:builder-opacity-100{
    opacity: 1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-0{
    opacity: 0 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-opacity-100{
    opacity: 1 !important;
  }

  .sm\:focus-within\:builder-opacity-0:focus-within{
    opacity: 0 !important;
  }

  .sm\:focus-within\:builder-opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .sm\:focus-within\:builder-opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .sm\:focus-within\:builder-opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .sm\:focus-within\:builder-opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .sm\:focus-within\:builder-opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .sm\:focus-within\:builder-opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .sm\:focus-within\:builder-opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .sm\:focus-within\:builder-opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .sm\:focus-within\:builder-opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .sm\:focus-within\:builder-opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .sm\:focus-within\:builder-opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .sm\:focus-within\:builder-opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .sm\:focus-within\:builder-opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .sm\:focus-within\:builder-opacity-100:focus-within{
    opacity: 1 !important;
  }

  .sm\:hover\:builder-opacity-0:hover{
    opacity: 0 !important;
  }

  .sm\:hover\:builder-opacity-5:hover{
    opacity: 0.05 !important;
  }

  .sm\:hover\:builder-opacity-10:hover{
    opacity: 0.1 !important;
  }

  .sm\:hover\:builder-opacity-20:hover{
    opacity: 0.2 !important;
  }

  .sm\:hover\:builder-opacity-25:hover{
    opacity: 0.25 !important;
  }

  .sm\:hover\:builder-opacity-30:hover{
    opacity: 0.3 !important;
  }

  .sm\:hover\:builder-opacity-40:hover{
    opacity: 0.4 !important;
  }

  .sm\:hover\:builder-opacity-50:hover{
    opacity: 0.5 !important;
  }

  .sm\:hover\:builder-opacity-60:hover{
    opacity: 0.6 !important;
  }

  .sm\:hover\:builder-opacity-70:hover{
    opacity: 0.7 !important;
  }

  .sm\:hover\:builder-opacity-75:hover{
    opacity: 0.75 !important;
  }

  .sm\:hover\:builder-opacity-80:hover{
    opacity: 0.8 !important;
  }

  .sm\:hover\:builder-opacity-90:hover{
    opacity: 0.9 !important;
  }

  .sm\:hover\:builder-opacity-95:hover{
    opacity: 0.95 !important;
  }

  .sm\:hover\:builder-opacity-100:hover{
    opacity: 1 !important;
  }

  .sm\:focus\:builder-opacity-0:focus{
    opacity: 0 !important;
  }

  .sm\:focus\:builder-opacity-5:focus{
    opacity: 0.05 !important;
  }

  .sm\:focus\:builder-opacity-10:focus{
    opacity: 0.1 !important;
  }

  .sm\:focus\:builder-opacity-20:focus{
    opacity: 0.2 !important;
  }

  .sm\:focus\:builder-opacity-25:focus{
    opacity: 0.25 !important;
  }

  .sm\:focus\:builder-opacity-30:focus{
    opacity: 0.3 !important;
  }

  .sm\:focus\:builder-opacity-40:focus{
    opacity: 0.4 !important;
  }

  .sm\:focus\:builder-opacity-50:focus{
    opacity: 0.5 !important;
  }

  .sm\:focus\:builder-opacity-60:focus{
    opacity: 0.6 !important;
  }

  .sm\:focus\:builder-opacity-70:focus{
    opacity: 0.7 !important;
  }

  .sm\:focus\:builder-opacity-75:focus{
    opacity: 0.75 !important;
  }

  .sm\:focus\:builder-opacity-80:focus{
    opacity: 0.8 !important;
  }

  .sm\:focus\:builder-opacity-90:focus{
    opacity: 0.9 !important;
  }

  .sm\:focus\:builder-opacity-95:focus{
    opacity: 0.95 !important;
  }

  .sm\:focus\:builder-opacity-100:focus{
    opacity: 1 !important;
  }

  .sm\:builder-bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .sm\:builder-bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .sm\:builder-bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .sm\:builder-bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .sm\:builder-bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .sm\:builder-bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .sm\:builder-bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .sm\:builder-bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .sm\:builder-bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .sm\:builder-bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .sm\:builder-bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .sm\:builder-bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .sm\:builder-bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .sm\:builder-bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .sm\:builder-bg-blend-color{
    background-blend-mode: color !important;
  }

  .sm\:builder-bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .sm\:builder-mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .sm\:builder-mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .sm\:builder-mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .sm\:builder-mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .sm\:builder-mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .sm\:builder-mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .sm\:builder-mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .sm\:builder-mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .sm\:builder-mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .sm\:builder-mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .sm\:builder-mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .sm\:builder-mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .sm\:builder-mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .sm\:builder-mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .sm\:builder-mix-blend-color{
    mix-blend-mode: color !important;
  }

  .sm\:builder-mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .sm\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .sm\:group-hover\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:builder-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:builder-shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:builder-shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:builder-outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .sm\:builder-outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .sm\:builder-outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .sm\:focus-within\:builder-outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .sm\:focus-within\:builder-outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .sm\:focus-within\:builder-outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .sm\:focus\:builder-outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .sm\:focus\:builder-outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .sm\:focus\:builder-outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .sm\:builder-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:builder-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:builder-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:builder-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:builder-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:builder-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:builder-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:builder-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:builder-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:builder-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:builder-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:builder-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:builder-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:builder-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:builder-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:builder-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:builder-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:builder-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:builder-ring-inset{
    --tw-ring-inset: inset !important;
  }

  .sm\:focus-within\:builder-ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .sm\:focus\:builder-ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .sm\:builder-ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .sm\:builder-ring-current{
    --tw-ring-color: currentColor !important;
  }

  .sm\:builder-ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-primary{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-secondary-dark{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-secondary-darker{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .sm\:focus-within\:builder-ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .sm\:focus-within\:builder-ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-primary:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-secondary-dark:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:builder-ring-secondary-darker:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .sm\:focus\:builder-ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .sm\:focus\:builder-ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-primary:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-secondary-dark:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:builder-ring-secondary-darker:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .sm\:builder-ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .sm\:builder-ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .sm\:builder-ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .sm\:builder-ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .sm\:builder-ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .sm\:builder-ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .sm\:builder-ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .sm\:builder-ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .sm\:builder-ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .sm\:builder-ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .sm\:builder-ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .sm\:builder-ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .sm\:builder-ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .sm\:builder-ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .sm\:builder-ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .sm\:focus-within\:builder-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .sm\:focus\:builder-ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .sm\:focus\:builder-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .sm\:focus\:builder-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .sm\:focus\:builder-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .sm\:focus\:builder-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .sm\:focus\:builder-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .sm\:focus\:builder-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .sm\:focus\:builder-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .sm\:focus\:builder-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .sm\:focus\:builder-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .sm\:focus\:builder-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .sm\:focus\:builder-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .sm\:focus\:builder-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .sm\:focus\:builder-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .sm\:focus\:builder-ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .sm\:builder-ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .sm\:builder-ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .sm\:builder-ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .sm\:builder-ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .sm\:builder-ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .sm\:focus-within\:builder-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .sm\:focus-within\:builder-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .sm\:focus-within\:builder-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .sm\:focus-within\:builder-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .sm\:focus-within\:builder-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .sm\:focus\:builder-ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .sm\:focus\:builder-ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .sm\:focus\:builder-ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .sm\:focus\:builder-ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .sm\:focus\:builder-ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .sm\:builder-ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .sm\:builder-ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .sm\:builder-ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .sm\:builder-ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .sm\:builder-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .sm\:builder-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .sm\:builder-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .sm\:builder-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .sm\:builder-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .sm\:builder-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .sm\:builder-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .sm\:builder-ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .sm\:builder-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .sm\:builder-ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .sm\:builder-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .sm\:builder-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .sm\:builder-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .sm\:builder-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .sm\:builder-ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .sm\:builder-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .sm\:builder-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .sm\:builder-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .sm\:builder-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .sm\:builder-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .sm\:builder-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .sm\:builder-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .sm\:builder-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .sm\:builder-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .sm\:builder-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .sm\:builder-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .sm\:builder-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .sm\:builder-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .sm\:builder-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .sm\:builder-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .sm\:builder-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .sm\:builder-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .sm\:builder-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .sm\:builder-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .sm\:builder-ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .sm\:builder-ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .sm\:builder-ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .sm\:builder-ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .sm\:builder-ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .sm\:builder-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .sm\:builder-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .sm\:builder-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .sm\:builder-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .sm\:builder-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .sm\:builder-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .sm\:builder-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .sm\:builder-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .sm\:builder-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .sm\:builder-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .sm\:builder-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .sm\:builder-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .sm\:builder-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .sm\:builder-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .sm\:builder-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .sm\:builder-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .sm\:builder-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .sm\:builder-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .sm\:builder-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .sm\:builder-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .sm\:builder-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .sm\:builder-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .sm\:builder-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .sm\:builder-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .sm\:builder-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .sm\:builder-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .sm\:builder-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .sm\:builder-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .sm\:builder-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .sm\:builder-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .sm\:builder-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .sm\:builder-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .sm\:builder-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .sm\:builder-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .sm\:builder-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .sm\:builder-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .sm\:builder-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .sm\:builder-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .sm\:builder-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .sm\:builder-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .sm\:builder-ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .sm\:builder-ring-offset-primary{
    --tw-ring-offset-color: #2d375a !important;
  }

  .sm\:builder-ring-offset-secondary-dark{
    --tw-ring-offset-color: #343434 !important;
  }

  .sm\:builder-ring-offset-secondary-darker{
    --tw-ring-offset-color: #212121 !important;
  }

  .sm\:focus-within\:builder-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .sm\:focus-within\:builder-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .sm\:focus-within\:builder-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .sm\:focus-within\:builder-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .sm\:focus-within\:builder-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .sm\:focus-within\:builder-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .sm\:focus-within\:builder-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .sm\:focus-within\:builder-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .sm\:focus-within\:builder-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .sm\:focus-within\:builder-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .sm\:focus-within\:builder-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .sm\:focus-within\:builder-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .sm\:focus-within\:builder-ring-offset-primary:focus-within{
    --tw-ring-offset-color: #2d375a !important;
  }

  .sm\:focus-within\:builder-ring-offset-secondary-dark:focus-within{
    --tw-ring-offset-color: #343434 !important;
  }

  .sm\:focus-within\:builder-ring-offset-secondary-darker:focus-within{
    --tw-ring-offset-color: #212121 !important;
  }

  .sm\:focus\:builder-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .sm\:focus\:builder-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .sm\:focus\:builder-ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .sm\:focus\:builder-ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .sm\:focus\:builder-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .sm\:focus\:builder-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .sm\:focus\:builder-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .sm\:focus\:builder-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .sm\:focus\:builder-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .sm\:focus\:builder-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .sm\:focus\:builder-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .sm\:focus\:builder-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .sm\:focus\:builder-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .sm\:focus\:builder-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .sm\:focus\:builder-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .sm\:focus\:builder-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .sm\:focus\:builder-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .sm\:focus\:builder-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .sm\:focus\:builder-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .sm\:focus\:builder-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .sm\:focus\:builder-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .sm\:focus\:builder-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .sm\:focus\:builder-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .sm\:focus\:builder-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .sm\:focus\:builder-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .sm\:focus\:builder-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .sm\:focus\:builder-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .sm\:focus\:builder-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .sm\:focus\:builder-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .sm\:focus\:builder-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .sm\:focus\:builder-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .sm\:focus\:builder-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .sm\:focus\:builder-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .sm\:focus\:builder-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .sm\:focus\:builder-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .sm\:focus\:builder-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .sm\:focus\:builder-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .sm\:focus\:builder-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .sm\:focus\:builder-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .sm\:focus\:builder-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .sm\:focus\:builder-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .sm\:focus\:builder-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .sm\:focus\:builder-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .sm\:focus\:builder-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .sm\:focus\:builder-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .sm\:focus\:builder-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .sm\:focus\:builder-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .sm\:focus\:builder-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .sm\:focus\:builder-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .sm\:focus\:builder-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .sm\:focus\:builder-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .sm\:focus\:builder-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .sm\:focus\:builder-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .sm\:focus\:builder-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .sm\:focus\:builder-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .sm\:focus\:builder-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .sm\:focus\:builder-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .sm\:focus\:builder-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .sm\:focus\:builder-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .sm\:focus\:builder-ring-offset-primary:focus{
    --tw-ring-offset-color: #2d375a !important;
  }

  .sm\:focus\:builder-ring-offset-secondary-dark:focus{
    --tw-ring-offset-color: #343434 !important;
  }

  .sm\:focus\:builder-ring-offset-secondary-darker:focus{
    --tw-ring-offset-color: #212121 !important;
  }

  .sm\:builder-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .sm\:builder-filter-none{
    filter: none !important;
  }

  .sm\:builder-blur-0{
    --tw-blur: blur(0) !important;
  }

  .sm\:builder-blur-none{
    --tw-blur: blur(0) !important;
  }

  .sm\:builder-blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .sm\:builder-blur{
    --tw-blur: blur(8px) !important;
  }

  .sm\:builder-blur-md{
    --tw-blur: blur(12px) !important;
  }

  .sm\:builder-blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .sm\:builder-blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .sm\:builder-blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .sm\:builder-blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .sm\:builder-brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .sm\:builder-brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .sm\:builder-brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .sm\:builder-brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .sm\:builder-brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .sm\:builder-brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .sm\:builder-brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .sm\:builder-brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .sm\:builder-brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .sm\:builder-brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .sm\:builder-brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .sm\:builder-contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .sm\:builder-contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .sm\:builder-contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .sm\:builder-contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .sm\:builder-contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .sm\:builder-contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .sm\:builder-contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .sm\:builder-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .sm\:builder-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .sm\:builder-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .sm\:builder-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .sm\:builder-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .sm\:builder-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .sm\:builder-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .sm\:builder-grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .sm\:builder-grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .sm\:builder-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .sm\:builder-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .sm\:builder-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .sm\:builder-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .sm\:builder-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .sm\:builder-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .sm\:builder--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .sm\:builder--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .sm\:builder--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .sm\:builder--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .sm\:builder--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .sm\:builder-invert-0{
    --tw-invert: invert(0) !important;
  }

  .sm\:builder-invert{
    --tw-invert: invert(100%) !important;
  }

  .sm\:builder-saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .sm\:builder-saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .sm\:builder-saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .sm\:builder-saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .sm\:builder-saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .sm\:builder-sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .sm\:builder-sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .sm\:builder-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .sm\:builder-backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .sm\:builder-backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .sm\:builder-backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .sm\:builder-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .sm\:builder-backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .sm\:builder-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .sm\:builder-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .sm\:builder-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .sm\:builder-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .sm\:builder-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .sm\:builder-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .sm\:builder-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .sm\:builder-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .sm\:builder-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .sm\:builder-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .sm\:builder-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .sm\:builder-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .sm\:builder-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .sm\:builder-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .sm\:builder-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .sm\:builder-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .sm\:builder-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .sm\:builder-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .sm\:builder-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .sm\:builder-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .sm\:builder-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .sm\:builder-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .sm\:builder-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .sm\:builder-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .sm\:builder-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .sm\:builder-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .sm\:builder-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .sm\:builder-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .sm\:builder-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .sm\:builder-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .sm\:builder-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .sm\:builder--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .sm\:builder--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .sm\:builder--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .sm\:builder--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .sm\:builder--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .sm\:builder-backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .sm\:builder-backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .sm\:builder-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .sm\:builder-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .sm\:builder-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .sm\:builder-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .sm\:builder-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .sm\:builder-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .sm\:builder-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .sm\:builder-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .sm\:builder-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .sm\:builder-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .sm\:builder-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .sm\:builder-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .sm\:builder-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .sm\:builder-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .sm\:builder-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .sm\:builder-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .sm\:builder-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .sm\:builder-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .sm\:builder-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .sm\:builder-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .sm\:builder-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .sm\:builder-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .sm\:builder-transition-none{
    transition-property: none !important;
  }

  .sm\:builder-transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:builder-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:builder-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:builder-transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:builder-transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:builder-transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:builder-delay-75{
    transition-delay: 75ms !important;
  }

  .sm\:builder-delay-100{
    transition-delay: 100ms !important;
  }

  .sm\:builder-delay-150{
    transition-delay: 150ms !important;
  }

  .sm\:builder-delay-200{
    transition-delay: 200ms !important;
  }

  .sm\:builder-delay-300{
    transition-delay: 300ms !important;
  }

  .sm\:builder-delay-500{
    transition-delay: 500ms !important;
  }

  .sm\:builder-delay-700{
    transition-delay: 700ms !important;
  }

  .sm\:builder-delay-1000{
    transition-delay: 1000ms !important;
  }

  .sm\:builder-duration-75{
    transition-duration: 75ms !important;
  }

  .sm\:builder-duration-100{
    transition-duration: 100ms !important;
  }

  .sm\:builder-duration-150{
    transition-duration: 150ms !important;
  }

  .sm\:builder-duration-200{
    transition-duration: 200ms !important;
  }

  .sm\:builder-duration-300{
    transition-duration: 300ms !important;
  }

  .sm\:builder-duration-500{
    transition-duration: 500ms !important;
  }

  .sm\:builder-duration-700{
    transition-duration: 700ms !important;
  }

  .sm\:builder-duration-1000{
    transition-duration: 1000ms !important;
  }

  .sm\:builder-ease-linear{
    transition-timing-function: linear !important;
  }

  .sm\:builder-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .sm\:builder-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .sm\:builder-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 768px){
  .md\:builder-container{
    width: 100%;
  }

  @media (min-width: 640px){
    .md\:builder-container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .md\:builder-container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .md\:builder-container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .md\:builder-container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .md\:builder-container{
      max-width: 1536px;
    }
  }

  .md\:builder-sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:builder-not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:focus-within\:builder-sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:focus-within\:builder-not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:focus\:builder-sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:focus\:builder-not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:builder-pointer-events-none{
    pointer-events: none !important;
  }

  .md\:builder-pointer-events-auto{
    pointer-events: auto !important;
  }

  .md\:builder-visible{
    visibility: visible !important;
  }

  .md\:builder-invisible{
    visibility: hidden !important;
  }

  .md\:builder-static{
    position: static !important;
  }

  .md\:builder-fixed{
    position: fixed !important;
  }

  .md\:builder-absolute{
    position: absolute !important;
  }

  .md\:builder-relative{
    position: relative !important;
  }

  .md\:builder-sticky{
    position: sticky !important;
  }

  .md\:builder-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .md\:builder-inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .md\:builder-inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .md\:builder-inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .md\:builder-inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .md\:builder-inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .md\:builder-inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .md\:builder-inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .md\:builder-inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .md\:builder-inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .md\:builder-inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .md\:builder-inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .md\:builder-inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .md\:builder-inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .md\:builder-inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .md\:builder-inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .md\:builder-inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .md\:builder-inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .md\:builder-inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .md\:builder-inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .md\:builder-inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .md\:builder-inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .md\:builder-inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .md\:builder-inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .md\:builder-inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .md\:builder-inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .md\:builder-inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .md\:builder-inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .md\:builder-inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .md\:builder-inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .md\:builder-inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .md\:builder-inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .md\:builder-inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .md\:builder-inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .md\:builder-inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .md\:builder-inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .md\:builder--inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .md\:builder--inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .md\:builder--inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .md\:builder--inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .md\:builder--inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .md\:builder--inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .md\:builder--inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .md\:builder--inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .md\:builder--inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .md\:builder--inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .md\:builder--inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .md\:builder--inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .md\:builder--inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .md\:builder--inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .md\:builder--inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .md\:builder--inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .md\:builder--inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .md\:builder--inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .md\:builder--inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .md\:builder--inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .md\:builder--inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .md\:builder--inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .md\:builder--inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .md\:builder--inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .md\:builder--inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .md\:builder--inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .md\:builder--inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .md\:builder--inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .md\:builder--inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .md\:builder--inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .md\:builder--inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .md\:builder--inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .md\:builder--inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .md\:builder--inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .md\:builder--inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .md\:builder-inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .md\:builder-inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .md\:builder-inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .md\:builder-inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .md\:builder-inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .md\:builder-inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .md\:builder-inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .md\:builder--inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .md\:builder--inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .md\:builder--inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .md\:builder--inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .md\:builder--inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .md\:builder--inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .md\:builder--inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .md\:builder-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .md\:builder-inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .md\:builder-inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .md\:builder-inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .md\:builder-inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .md\:builder-inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .md\:builder-inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .md\:builder-inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .md\:builder-inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .md\:builder-inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .md\:builder-inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .md\:builder-inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .md\:builder-inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .md\:builder-inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .md\:builder-inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .md\:builder-inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .md\:builder-inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .md\:builder-inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .md\:builder-inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .md\:builder-inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .md\:builder-inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .md\:builder-inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .md\:builder-inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .md\:builder-inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .md\:builder-inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .md\:builder-inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .md\:builder-inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .md\:builder-inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .md\:builder-inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .md\:builder-inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .md\:builder-inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .md\:builder-inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .md\:builder-inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .md\:builder-inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .md\:builder-inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .md\:builder-inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .md\:builder--inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .md\:builder--inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .md\:builder--inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .md\:builder--inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .md\:builder--inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .md\:builder--inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .md\:builder--inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .md\:builder--inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .md\:builder--inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .md\:builder--inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .md\:builder--inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .md\:builder--inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .md\:builder--inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .md\:builder--inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .md\:builder--inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .md\:builder--inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .md\:builder--inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .md\:builder--inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .md\:builder--inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .md\:builder--inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .md\:builder--inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .md\:builder--inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .md\:builder--inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .md\:builder--inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .md\:builder--inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .md\:builder--inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .md\:builder--inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .md\:builder--inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .md\:builder--inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .md\:builder--inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .md\:builder--inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .md\:builder--inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .md\:builder--inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .md\:builder--inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .md\:builder--inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .md\:builder-inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .md\:builder-inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .md\:builder-inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .md\:builder-inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .md\:builder-inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .md\:builder-inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .md\:builder-inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .md\:builder--inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .md\:builder--inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .md\:builder--inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .md\:builder--inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .md\:builder--inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .md\:builder--inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .md\:builder--inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .md\:builder-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .md\:builder-inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .md\:builder-inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .md\:builder-inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .md\:builder-inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .md\:builder-inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .md\:builder-inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .md\:builder-inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .md\:builder-inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .md\:builder-inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .md\:builder-inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .md\:builder-inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .md\:builder-inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .md\:builder-inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .md\:builder-inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .md\:builder-inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .md\:builder-inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .md\:builder-inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .md\:builder-inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .md\:builder-inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .md\:builder-inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .md\:builder-inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .md\:builder-inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .md\:builder-inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .md\:builder-inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .md\:builder-inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .md\:builder-inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .md\:builder-inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .md\:builder-inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .md\:builder-inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .md\:builder-inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .md\:builder-inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .md\:builder-inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .md\:builder-inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .md\:builder-inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .md\:builder-inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .md\:builder--inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .md\:builder--inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .md\:builder--inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .md\:builder--inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .md\:builder--inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .md\:builder--inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .md\:builder--inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .md\:builder--inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .md\:builder--inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .md\:builder--inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .md\:builder--inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .md\:builder--inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .md\:builder--inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .md\:builder--inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .md\:builder--inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .md\:builder--inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .md\:builder--inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .md\:builder--inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .md\:builder--inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .md\:builder--inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .md\:builder--inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .md\:builder--inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .md\:builder--inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .md\:builder--inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .md\:builder--inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .md\:builder--inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .md\:builder--inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .md\:builder--inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .md\:builder--inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .md\:builder--inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .md\:builder--inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .md\:builder--inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .md\:builder--inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .md\:builder--inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .md\:builder--inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .md\:builder-inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .md\:builder-inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .md\:builder-inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .md\:builder-inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .md\:builder-inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .md\:builder-inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .md\:builder-inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .md\:builder--inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .md\:builder--inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .md\:builder--inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .md\:builder--inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .md\:builder--inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .md\:builder--inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .md\:builder--inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .md\:builder-top-0{
    top: 0px !important;
  }

  .md\:builder-top-1{
    top: 0.25rem !important;
  }

  .md\:builder-top-2{
    top: 0.5rem !important;
  }

  .md\:builder-top-3{
    top: 0.75rem !important;
  }

  .md\:builder-top-4{
    top: 1rem !important;
  }

  .md\:builder-top-5{
    top: 1.25rem !important;
  }

  .md\:builder-top-6{
    top: 1.5rem !important;
  }

  .md\:builder-top-7{
    top: 1.75rem !important;
  }

  .md\:builder-top-8{
    top: 2rem !important;
  }

  .md\:builder-top-9{
    top: 2.25rem !important;
  }

  .md\:builder-top-10{
    top: 2.5rem !important;
  }

  .md\:builder-top-11{
    top: 2.75rem !important;
  }

  .md\:builder-top-12{
    top: 3rem !important;
  }

  .md\:builder-top-14{
    top: 3.5rem !important;
  }

  .md\:builder-top-16{
    top: 4rem !important;
  }

  .md\:builder-top-20{
    top: 5rem !important;
  }

  .md\:builder-top-24{
    top: 6rem !important;
  }

  .md\:builder-top-28{
    top: 7rem !important;
  }

  .md\:builder-top-32{
    top: 8rem !important;
  }

  .md\:builder-top-36{
    top: 9rem !important;
  }

  .md\:builder-top-40{
    top: 10rem !important;
  }

  .md\:builder-top-44{
    top: 11rem !important;
  }

  .md\:builder-top-48{
    top: 12rem !important;
  }

  .md\:builder-top-52{
    top: 13rem !important;
  }

  .md\:builder-top-56{
    top: 14rem !important;
  }

  .md\:builder-top-60{
    top: 15rem !important;
  }

  .md\:builder-top-64{
    top: 16rem !important;
  }

  .md\:builder-top-72{
    top: 18rem !important;
  }

  .md\:builder-top-80{
    top: 20rem !important;
  }

  .md\:builder-top-96{
    top: 24rem !important;
  }

  .md\:builder-top-auto{
    top: auto !important;
  }

  .md\:builder-top-px{
    top: 1px !important;
  }

  .md\:builder-top-0\.5{
    top: 0.125rem !important;
  }

  .md\:builder-top-1\.5{
    top: 0.375rem !important;
  }

  .md\:builder-top-2\.5{
    top: 0.625rem !important;
  }

  .md\:builder-top-3\.5{
    top: 0.875rem !important;
  }

  .md\:builder--top-0{
    top: 0px !important;
  }

  .md\:builder--top-1{
    top: -0.25rem !important;
  }

  .md\:builder--top-2{
    top: -0.5rem !important;
  }

  .md\:builder--top-3{
    top: -0.75rem !important;
  }

  .md\:builder--top-4{
    top: -1rem !important;
  }

  .md\:builder--top-5{
    top: -1.25rem !important;
  }

  .md\:builder--top-6{
    top: -1.5rem !important;
  }

  .md\:builder--top-7{
    top: -1.75rem !important;
  }

  .md\:builder--top-8{
    top: -2rem !important;
  }

  .md\:builder--top-9{
    top: -2.25rem !important;
  }

  .md\:builder--top-10{
    top: -2.5rem !important;
  }

  .md\:builder--top-11{
    top: -2.75rem !important;
  }

  .md\:builder--top-12{
    top: -3rem !important;
  }

  .md\:builder--top-14{
    top: -3.5rem !important;
  }

  .md\:builder--top-16{
    top: -4rem !important;
  }

  .md\:builder--top-20{
    top: -5rem !important;
  }

  .md\:builder--top-24{
    top: -6rem !important;
  }

  .md\:builder--top-28{
    top: -7rem !important;
  }

  .md\:builder--top-32{
    top: -8rem !important;
  }

  .md\:builder--top-36{
    top: -9rem !important;
  }

  .md\:builder--top-40{
    top: -10rem !important;
  }

  .md\:builder--top-44{
    top: -11rem !important;
  }

  .md\:builder--top-48{
    top: -12rem !important;
  }

  .md\:builder--top-52{
    top: -13rem !important;
  }

  .md\:builder--top-56{
    top: -14rem !important;
  }

  .md\:builder--top-60{
    top: -15rem !important;
  }

  .md\:builder--top-64{
    top: -16rem !important;
  }

  .md\:builder--top-72{
    top: -18rem !important;
  }

  .md\:builder--top-80{
    top: -20rem !important;
  }

  .md\:builder--top-96{
    top: -24rem !important;
  }

  .md\:builder--top-px{
    top: -1px !important;
  }

  .md\:builder--top-0\.5{
    top: -0.125rem !important;
  }

  .md\:builder--top-1\.5{
    top: -0.375rem !important;
  }

  .md\:builder--top-2\.5{
    top: -0.625rem !important;
  }

  .md\:builder--top-3\.5{
    top: -0.875rem !important;
  }

  .md\:builder-top-1\/2{
    top: 50% !important;
  }

  .md\:builder-top-1\/3{
    top: 33.333333% !important;
  }

  .md\:builder-top-2\/3{
    top: 66.666667% !important;
  }

  .md\:builder-top-1\/4{
    top: 25% !important;
  }

  .md\:builder-top-2\/4{
    top: 50% !important;
  }

  .md\:builder-top-3\/4{
    top: 75% !important;
  }

  .md\:builder-top-full{
    top: 100% !important;
  }

  .md\:builder--top-1\/2{
    top: -50% !important;
  }

  .md\:builder--top-1\/3{
    top: -33.333333% !important;
  }

  .md\:builder--top-2\/3{
    top: -66.666667% !important;
  }

  .md\:builder--top-1\/4{
    top: -25% !important;
  }

  .md\:builder--top-2\/4{
    top: -50% !important;
  }

  .md\:builder--top-3\/4{
    top: -75% !important;
  }

  .md\:builder--top-full{
    top: -100% !important;
  }

  .md\:builder-right-0{
    right: 0px !important;
  }

  .md\:builder-right-1{
    right: 0.25rem !important;
  }

  .md\:builder-right-2{
    right: 0.5rem !important;
  }

  .md\:builder-right-3{
    right: 0.75rem !important;
  }

  .md\:builder-right-4{
    right: 1rem !important;
  }

  .md\:builder-right-5{
    right: 1.25rem !important;
  }

  .md\:builder-right-6{
    right: 1.5rem !important;
  }

  .md\:builder-right-7{
    right: 1.75rem !important;
  }

  .md\:builder-right-8{
    right: 2rem !important;
  }

  .md\:builder-right-9{
    right: 2.25rem !important;
  }

  .md\:builder-right-10{
    right: 2.5rem !important;
  }

  .md\:builder-right-11{
    right: 2.75rem !important;
  }

  .md\:builder-right-12{
    right: 3rem !important;
  }

  .md\:builder-right-14{
    right: 3.5rem !important;
  }

  .md\:builder-right-16{
    right: 4rem !important;
  }

  .md\:builder-right-20{
    right: 5rem !important;
  }

  .md\:builder-right-24{
    right: 6rem !important;
  }

  .md\:builder-right-28{
    right: 7rem !important;
  }

  .md\:builder-right-32{
    right: 8rem !important;
  }

  .md\:builder-right-36{
    right: 9rem !important;
  }

  .md\:builder-right-40{
    right: 10rem !important;
  }

  .md\:builder-right-44{
    right: 11rem !important;
  }

  .md\:builder-right-48{
    right: 12rem !important;
  }

  .md\:builder-right-52{
    right: 13rem !important;
  }

  .md\:builder-right-56{
    right: 14rem !important;
  }

  .md\:builder-right-60{
    right: 15rem !important;
  }

  .md\:builder-right-64{
    right: 16rem !important;
  }

  .md\:builder-right-72{
    right: 18rem !important;
  }

  .md\:builder-right-80{
    right: 20rem !important;
  }

  .md\:builder-right-96{
    right: 24rem !important;
  }

  .md\:builder-right-auto{
    right: auto !important;
  }

  .md\:builder-right-px{
    right: 1px !important;
  }

  .md\:builder-right-0\.5{
    right: 0.125rem !important;
  }

  .md\:builder-right-1\.5{
    right: 0.375rem !important;
  }

  .md\:builder-right-2\.5{
    right: 0.625rem !important;
  }

  .md\:builder-right-3\.5{
    right: 0.875rem !important;
  }

  .md\:builder--right-0{
    right: 0px !important;
  }

  .md\:builder--right-1{
    right: -0.25rem !important;
  }

  .md\:builder--right-2{
    right: -0.5rem !important;
  }

  .md\:builder--right-3{
    right: -0.75rem !important;
  }

  .md\:builder--right-4{
    right: -1rem !important;
  }

  .md\:builder--right-5{
    right: -1.25rem !important;
  }

  .md\:builder--right-6{
    right: -1.5rem !important;
  }

  .md\:builder--right-7{
    right: -1.75rem !important;
  }

  .md\:builder--right-8{
    right: -2rem !important;
  }

  .md\:builder--right-9{
    right: -2.25rem !important;
  }

  .md\:builder--right-10{
    right: -2.5rem !important;
  }

  .md\:builder--right-11{
    right: -2.75rem !important;
  }

  .md\:builder--right-12{
    right: -3rem !important;
  }

  .md\:builder--right-14{
    right: -3.5rem !important;
  }

  .md\:builder--right-16{
    right: -4rem !important;
  }

  .md\:builder--right-20{
    right: -5rem !important;
  }

  .md\:builder--right-24{
    right: -6rem !important;
  }

  .md\:builder--right-28{
    right: -7rem !important;
  }

  .md\:builder--right-32{
    right: -8rem !important;
  }

  .md\:builder--right-36{
    right: -9rem !important;
  }

  .md\:builder--right-40{
    right: -10rem !important;
  }

  .md\:builder--right-44{
    right: -11rem !important;
  }

  .md\:builder--right-48{
    right: -12rem !important;
  }

  .md\:builder--right-52{
    right: -13rem !important;
  }

  .md\:builder--right-56{
    right: -14rem !important;
  }

  .md\:builder--right-60{
    right: -15rem !important;
  }

  .md\:builder--right-64{
    right: -16rem !important;
  }

  .md\:builder--right-72{
    right: -18rem !important;
  }

  .md\:builder--right-80{
    right: -20rem !important;
  }

  .md\:builder--right-96{
    right: -24rem !important;
  }

  .md\:builder--right-px{
    right: -1px !important;
  }

  .md\:builder--right-0\.5{
    right: -0.125rem !important;
  }

  .md\:builder--right-1\.5{
    right: -0.375rem !important;
  }

  .md\:builder--right-2\.5{
    right: -0.625rem !important;
  }

  .md\:builder--right-3\.5{
    right: -0.875rem !important;
  }

  .md\:builder-right-1\/2{
    right: 50% !important;
  }

  .md\:builder-right-1\/3{
    right: 33.333333% !important;
  }

  .md\:builder-right-2\/3{
    right: 66.666667% !important;
  }

  .md\:builder-right-1\/4{
    right: 25% !important;
  }

  .md\:builder-right-2\/4{
    right: 50% !important;
  }

  .md\:builder-right-3\/4{
    right: 75% !important;
  }

  .md\:builder-right-full{
    right: 100% !important;
  }

  .md\:builder--right-1\/2{
    right: -50% !important;
  }

  .md\:builder--right-1\/3{
    right: -33.333333% !important;
  }

  .md\:builder--right-2\/3{
    right: -66.666667% !important;
  }

  .md\:builder--right-1\/4{
    right: -25% !important;
  }

  .md\:builder--right-2\/4{
    right: -50% !important;
  }

  .md\:builder--right-3\/4{
    right: -75% !important;
  }

  .md\:builder--right-full{
    right: -100% !important;
  }

  .md\:builder-bottom-0{
    bottom: 0px !important;
  }

  .md\:builder-bottom-1{
    bottom: 0.25rem !important;
  }

  .md\:builder-bottom-2{
    bottom: 0.5rem !important;
  }

  .md\:builder-bottom-3{
    bottom: 0.75rem !important;
  }

  .md\:builder-bottom-4{
    bottom: 1rem !important;
  }

  .md\:builder-bottom-5{
    bottom: 1.25rem !important;
  }

  .md\:builder-bottom-6{
    bottom: 1.5rem !important;
  }

  .md\:builder-bottom-7{
    bottom: 1.75rem !important;
  }

  .md\:builder-bottom-8{
    bottom: 2rem !important;
  }

  .md\:builder-bottom-9{
    bottom: 2.25rem !important;
  }

  .md\:builder-bottom-10{
    bottom: 2.5rem !important;
  }

  .md\:builder-bottom-11{
    bottom: 2.75rem !important;
  }

  .md\:builder-bottom-12{
    bottom: 3rem !important;
  }

  .md\:builder-bottom-14{
    bottom: 3.5rem !important;
  }

  .md\:builder-bottom-16{
    bottom: 4rem !important;
  }

  .md\:builder-bottom-20{
    bottom: 5rem !important;
  }

  .md\:builder-bottom-24{
    bottom: 6rem !important;
  }

  .md\:builder-bottom-28{
    bottom: 7rem !important;
  }

  .md\:builder-bottom-32{
    bottom: 8rem !important;
  }

  .md\:builder-bottom-36{
    bottom: 9rem !important;
  }

  .md\:builder-bottom-40{
    bottom: 10rem !important;
  }

  .md\:builder-bottom-44{
    bottom: 11rem !important;
  }

  .md\:builder-bottom-48{
    bottom: 12rem !important;
  }

  .md\:builder-bottom-52{
    bottom: 13rem !important;
  }

  .md\:builder-bottom-56{
    bottom: 14rem !important;
  }

  .md\:builder-bottom-60{
    bottom: 15rem !important;
  }

  .md\:builder-bottom-64{
    bottom: 16rem !important;
  }

  .md\:builder-bottom-72{
    bottom: 18rem !important;
  }

  .md\:builder-bottom-80{
    bottom: 20rem !important;
  }

  .md\:builder-bottom-96{
    bottom: 24rem !important;
  }

  .md\:builder-bottom-auto{
    bottom: auto !important;
  }

  .md\:builder-bottom-px{
    bottom: 1px !important;
  }

  .md\:builder-bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .md\:builder-bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .md\:builder-bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .md\:builder-bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .md\:builder--bottom-0{
    bottom: 0px !important;
  }

  .md\:builder--bottom-1{
    bottom: -0.25rem !important;
  }

  .md\:builder--bottom-2{
    bottom: -0.5rem !important;
  }

  .md\:builder--bottom-3{
    bottom: -0.75rem !important;
  }

  .md\:builder--bottom-4{
    bottom: -1rem !important;
  }

  .md\:builder--bottom-5{
    bottom: -1.25rem !important;
  }

  .md\:builder--bottom-6{
    bottom: -1.5rem !important;
  }

  .md\:builder--bottom-7{
    bottom: -1.75rem !important;
  }

  .md\:builder--bottom-8{
    bottom: -2rem !important;
  }

  .md\:builder--bottom-9{
    bottom: -2.25rem !important;
  }

  .md\:builder--bottom-10{
    bottom: -2.5rem !important;
  }

  .md\:builder--bottom-11{
    bottom: -2.75rem !important;
  }

  .md\:builder--bottom-12{
    bottom: -3rem !important;
  }

  .md\:builder--bottom-14{
    bottom: -3.5rem !important;
  }

  .md\:builder--bottom-16{
    bottom: -4rem !important;
  }

  .md\:builder--bottom-20{
    bottom: -5rem !important;
  }

  .md\:builder--bottom-24{
    bottom: -6rem !important;
  }

  .md\:builder--bottom-28{
    bottom: -7rem !important;
  }

  .md\:builder--bottom-32{
    bottom: -8rem !important;
  }

  .md\:builder--bottom-36{
    bottom: -9rem !important;
  }

  .md\:builder--bottom-40{
    bottom: -10rem !important;
  }

  .md\:builder--bottom-44{
    bottom: -11rem !important;
  }

  .md\:builder--bottom-48{
    bottom: -12rem !important;
  }

  .md\:builder--bottom-52{
    bottom: -13rem !important;
  }

  .md\:builder--bottom-56{
    bottom: -14rem !important;
  }

  .md\:builder--bottom-60{
    bottom: -15rem !important;
  }

  .md\:builder--bottom-64{
    bottom: -16rem !important;
  }

  .md\:builder--bottom-72{
    bottom: -18rem !important;
  }

  .md\:builder--bottom-80{
    bottom: -20rem !important;
  }

  .md\:builder--bottom-96{
    bottom: -24rem !important;
  }

  .md\:builder--bottom-px{
    bottom: -1px !important;
  }

  .md\:builder--bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .md\:builder--bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .md\:builder--bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .md\:builder--bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .md\:builder-bottom-1\/2{
    bottom: 50% !important;
  }

  .md\:builder-bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .md\:builder-bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .md\:builder-bottom-1\/4{
    bottom: 25% !important;
  }

  .md\:builder-bottom-2\/4{
    bottom: 50% !important;
  }

  .md\:builder-bottom-3\/4{
    bottom: 75% !important;
  }

  .md\:builder-bottom-full{
    bottom: 100% !important;
  }

  .md\:builder--bottom-1\/2{
    bottom: -50% !important;
  }

  .md\:builder--bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .md\:builder--bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .md\:builder--bottom-1\/4{
    bottom: -25% !important;
  }

  .md\:builder--bottom-2\/4{
    bottom: -50% !important;
  }

  .md\:builder--bottom-3\/4{
    bottom: -75% !important;
  }

  .md\:builder--bottom-full{
    bottom: -100% !important;
  }

  .md\:builder-left-0{
    left: 0px !important;
  }

  .md\:builder-left-1{
    left: 0.25rem !important;
  }

  .md\:builder-left-2{
    left: 0.5rem !important;
  }

  .md\:builder-left-3{
    left: 0.75rem !important;
  }

  .md\:builder-left-4{
    left: 1rem !important;
  }

  .md\:builder-left-5{
    left: 1.25rem !important;
  }

  .md\:builder-left-6{
    left: 1.5rem !important;
  }

  .md\:builder-left-7{
    left: 1.75rem !important;
  }

  .md\:builder-left-8{
    left: 2rem !important;
  }

  .md\:builder-left-9{
    left: 2.25rem !important;
  }

  .md\:builder-left-10{
    left: 2.5rem !important;
  }

  .md\:builder-left-11{
    left: 2.75rem !important;
  }

  .md\:builder-left-12{
    left: 3rem !important;
  }

  .md\:builder-left-14{
    left: 3.5rem !important;
  }

  .md\:builder-left-16{
    left: 4rem !important;
  }

  .md\:builder-left-20{
    left: 5rem !important;
  }

  .md\:builder-left-24{
    left: 6rem !important;
  }

  .md\:builder-left-28{
    left: 7rem !important;
  }

  .md\:builder-left-32{
    left: 8rem !important;
  }

  .md\:builder-left-36{
    left: 9rem !important;
  }

  .md\:builder-left-40{
    left: 10rem !important;
  }

  .md\:builder-left-44{
    left: 11rem !important;
  }

  .md\:builder-left-48{
    left: 12rem !important;
  }

  .md\:builder-left-52{
    left: 13rem !important;
  }

  .md\:builder-left-56{
    left: 14rem !important;
  }

  .md\:builder-left-60{
    left: 15rem !important;
  }

  .md\:builder-left-64{
    left: 16rem !important;
  }

  .md\:builder-left-72{
    left: 18rem !important;
  }

  .md\:builder-left-80{
    left: 20rem !important;
  }

  .md\:builder-left-96{
    left: 24rem !important;
  }

  .md\:builder-left-auto{
    left: auto !important;
  }

  .md\:builder-left-px{
    left: 1px !important;
  }

  .md\:builder-left-0\.5{
    left: 0.125rem !important;
  }

  .md\:builder-left-1\.5{
    left: 0.375rem !important;
  }

  .md\:builder-left-2\.5{
    left: 0.625rem !important;
  }

  .md\:builder-left-3\.5{
    left: 0.875rem !important;
  }

  .md\:builder--left-0{
    left: 0px !important;
  }

  .md\:builder--left-1{
    left: -0.25rem !important;
  }

  .md\:builder--left-2{
    left: -0.5rem !important;
  }

  .md\:builder--left-3{
    left: -0.75rem !important;
  }

  .md\:builder--left-4{
    left: -1rem !important;
  }

  .md\:builder--left-5{
    left: -1.25rem !important;
  }

  .md\:builder--left-6{
    left: -1.5rem !important;
  }

  .md\:builder--left-7{
    left: -1.75rem !important;
  }

  .md\:builder--left-8{
    left: -2rem !important;
  }

  .md\:builder--left-9{
    left: -2.25rem !important;
  }

  .md\:builder--left-10{
    left: -2.5rem !important;
  }

  .md\:builder--left-11{
    left: -2.75rem !important;
  }

  .md\:builder--left-12{
    left: -3rem !important;
  }

  .md\:builder--left-14{
    left: -3.5rem !important;
  }

  .md\:builder--left-16{
    left: -4rem !important;
  }

  .md\:builder--left-20{
    left: -5rem !important;
  }

  .md\:builder--left-24{
    left: -6rem !important;
  }

  .md\:builder--left-28{
    left: -7rem !important;
  }

  .md\:builder--left-32{
    left: -8rem !important;
  }

  .md\:builder--left-36{
    left: -9rem !important;
  }

  .md\:builder--left-40{
    left: -10rem !important;
  }

  .md\:builder--left-44{
    left: -11rem !important;
  }

  .md\:builder--left-48{
    left: -12rem !important;
  }

  .md\:builder--left-52{
    left: -13rem !important;
  }

  .md\:builder--left-56{
    left: -14rem !important;
  }

  .md\:builder--left-60{
    left: -15rem !important;
  }

  .md\:builder--left-64{
    left: -16rem !important;
  }

  .md\:builder--left-72{
    left: -18rem !important;
  }

  .md\:builder--left-80{
    left: -20rem !important;
  }

  .md\:builder--left-96{
    left: -24rem !important;
  }

  .md\:builder--left-px{
    left: -1px !important;
  }

  .md\:builder--left-0\.5{
    left: -0.125rem !important;
  }

  .md\:builder--left-1\.5{
    left: -0.375rem !important;
  }

  .md\:builder--left-2\.5{
    left: -0.625rem !important;
  }

  .md\:builder--left-3\.5{
    left: -0.875rem !important;
  }

  .md\:builder-left-1\/2{
    left: 50% !important;
  }

  .md\:builder-left-1\/3{
    left: 33.333333% !important;
  }

  .md\:builder-left-2\/3{
    left: 66.666667% !important;
  }

  .md\:builder-left-1\/4{
    left: 25% !important;
  }

  .md\:builder-left-2\/4{
    left: 50% !important;
  }

  .md\:builder-left-3\/4{
    left: 75% !important;
  }

  .md\:builder-left-full{
    left: 100% !important;
  }

  .md\:builder--left-1\/2{
    left: -50% !important;
  }

  .md\:builder--left-1\/3{
    left: -33.333333% !important;
  }

  .md\:builder--left-2\/3{
    left: -66.666667% !important;
  }

  .md\:builder--left-1\/4{
    left: -25% !important;
  }

  .md\:builder--left-2\/4{
    left: -50% !important;
  }

  .md\:builder--left-3\/4{
    left: -75% !important;
  }

  .md\:builder--left-full{
    left: -100% !important;
  }

  .md\:builder-isolate{
    isolation: isolate !important;
  }

  .md\:builder-isolation-auto{
    isolation: auto !important;
  }

  .md\:builder-z-0{
    z-index: 0 !important;
  }

  .md\:builder-z-10{
    z-index: 10 !important;
  }

  .md\:builder-z-20{
    z-index: 20 !important;
  }

  .md\:builder-z-30{
    z-index: 30 !important;
  }

  .md\:builder-z-40{
    z-index: 40 !important;
  }

  .md\:builder-z-50{
    z-index: 50 !important;
  }

  .md\:builder-z-auto{
    z-index: auto !important;
  }

  .md\:focus-within\:builder-z-0:focus-within{
    z-index: 0 !important;
  }

  .md\:focus-within\:builder-z-10:focus-within{
    z-index: 10 !important;
  }

  .md\:focus-within\:builder-z-20:focus-within{
    z-index: 20 !important;
  }

  .md\:focus-within\:builder-z-30:focus-within{
    z-index: 30 !important;
  }

  .md\:focus-within\:builder-z-40:focus-within{
    z-index: 40 !important;
  }

  .md\:focus-within\:builder-z-50:focus-within{
    z-index: 50 !important;
  }

  .md\:focus-within\:builder-z-auto:focus-within{
    z-index: auto !important;
  }

  .md\:focus\:builder-z-0:focus{
    z-index: 0 !important;
  }

  .md\:focus\:builder-z-10:focus{
    z-index: 10 !important;
  }

  .md\:focus\:builder-z-20:focus{
    z-index: 20 !important;
  }

  .md\:focus\:builder-z-30:focus{
    z-index: 30 !important;
  }

  .md\:focus\:builder-z-40:focus{
    z-index: 40 !important;
  }

  .md\:focus\:builder-z-50:focus{
    z-index: 50 !important;
  }

  .md\:focus\:builder-z-auto:focus{
    z-index: auto !important;
  }

  .md\:builder-order-1{
    order: 1 !important;
  }

  .md\:builder-order-2{
    order: 2 !important;
  }

  .md\:builder-order-3{
    order: 3 !important;
  }

  .md\:builder-order-4{
    order: 4 !important;
  }

  .md\:builder-order-5{
    order: 5 !important;
  }

  .md\:builder-order-6{
    order: 6 !important;
  }

  .md\:builder-order-7{
    order: 7 !important;
  }

  .md\:builder-order-8{
    order: 8 !important;
  }

  .md\:builder-order-9{
    order: 9 !important;
  }

  .md\:builder-order-10{
    order: 10 !important;
  }

  .md\:builder-order-11{
    order: 11 !important;
  }

  .md\:builder-order-12{
    order: 12 !important;
  }

  .md\:builder-order-first{
    order: -9999 !important;
  }

  .md\:builder-order-last{
    order: 9999 !important;
  }

  .md\:builder-order-none{
    order: 0 !important;
  }

  .md\:builder-col-auto{
    grid-column: auto !important;
  }

  .md\:builder-col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .md\:builder-col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .md\:builder-col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .md\:builder-col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .md\:builder-col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .md\:builder-col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .md\:builder-col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .md\:builder-col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .md\:builder-col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .md\:builder-col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .md\:builder-col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .md\:builder-col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .md\:builder-col-span-full{
    grid-column: 1 / -1 !important;
  }

  .md\:builder-col-start-1{
    grid-column-start: 1 !important;
  }

  .md\:builder-col-start-2{
    grid-column-start: 2 !important;
  }

  .md\:builder-col-start-3{
    grid-column-start: 3 !important;
  }

  .md\:builder-col-start-4{
    grid-column-start: 4 !important;
  }

  .md\:builder-col-start-5{
    grid-column-start: 5 !important;
  }

  .md\:builder-col-start-6{
    grid-column-start: 6 !important;
  }

  .md\:builder-col-start-7{
    grid-column-start: 7 !important;
  }

  .md\:builder-col-start-8{
    grid-column-start: 8 !important;
  }

  .md\:builder-col-start-9{
    grid-column-start: 9 !important;
  }

  .md\:builder-col-start-10{
    grid-column-start: 10 !important;
  }

  .md\:builder-col-start-11{
    grid-column-start: 11 !important;
  }

  .md\:builder-col-start-12{
    grid-column-start: 12 !important;
  }

  .md\:builder-col-start-13{
    grid-column-start: 13 !important;
  }

  .md\:builder-col-start-auto{
    grid-column-start: auto !important;
  }

  .md\:builder-col-end-1{
    grid-column-end: 1 !important;
  }

  .md\:builder-col-end-2{
    grid-column-end: 2 !important;
  }

  .md\:builder-col-end-3{
    grid-column-end: 3 !important;
  }

  .md\:builder-col-end-4{
    grid-column-end: 4 !important;
  }

  .md\:builder-col-end-5{
    grid-column-end: 5 !important;
  }

  .md\:builder-col-end-6{
    grid-column-end: 6 !important;
  }

  .md\:builder-col-end-7{
    grid-column-end: 7 !important;
  }

  .md\:builder-col-end-8{
    grid-column-end: 8 !important;
  }

  .md\:builder-col-end-9{
    grid-column-end: 9 !important;
  }

  .md\:builder-col-end-10{
    grid-column-end: 10 !important;
  }

  .md\:builder-col-end-11{
    grid-column-end: 11 !important;
  }

  .md\:builder-col-end-12{
    grid-column-end: 12 !important;
  }

  .md\:builder-col-end-13{
    grid-column-end: 13 !important;
  }

  .md\:builder-col-end-auto{
    grid-column-end: auto !important;
  }

  .md\:builder-row-auto{
    grid-row: auto !important;
  }

  .md\:builder-row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .md\:builder-row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .md\:builder-row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .md\:builder-row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .md\:builder-row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .md\:builder-row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .md\:builder-row-span-full{
    grid-row: 1 / -1 !important;
  }

  .md\:builder-row-start-1{
    grid-row-start: 1 !important;
  }

  .md\:builder-row-start-2{
    grid-row-start: 2 !important;
  }

  .md\:builder-row-start-3{
    grid-row-start: 3 !important;
  }

  .md\:builder-row-start-4{
    grid-row-start: 4 !important;
  }

  .md\:builder-row-start-5{
    grid-row-start: 5 !important;
  }

  .md\:builder-row-start-6{
    grid-row-start: 6 !important;
  }

  .md\:builder-row-start-7{
    grid-row-start: 7 !important;
  }

  .md\:builder-row-start-auto{
    grid-row-start: auto !important;
  }

  .md\:builder-row-end-1{
    grid-row-end: 1 !important;
  }

  .md\:builder-row-end-2{
    grid-row-end: 2 !important;
  }

  .md\:builder-row-end-3{
    grid-row-end: 3 !important;
  }

  .md\:builder-row-end-4{
    grid-row-end: 4 !important;
  }

  .md\:builder-row-end-5{
    grid-row-end: 5 !important;
  }

  .md\:builder-row-end-6{
    grid-row-end: 6 !important;
  }

  .md\:builder-row-end-7{
    grid-row-end: 7 !important;
  }

  .md\:builder-row-end-auto{
    grid-row-end: auto !important;
  }

  .md\:builder-float-right{
    float: right !important;
  }

  .md\:builder-float-left{
    float: left !important;
  }

  .md\:builder-float-none{
    float: none !important;
  }

  .md\:builder-clear-left{
    clear: left !important;
  }

  .md\:builder-clear-right{
    clear: right !important;
  }

  .md\:builder-clear-both{
    clear: both !important;
  }

  .md\:builder-clear-none{
    clear: none !important;
  }

  .md\:builder-m-0{
    margin: 0px !important;
  }

  .md\:builder-m-1{
    margin: 0.25rem !important;
  }

  .md\:builder-m-2{
    margin: 0.5rem !important;
  }

  .md\:builder-m-3{
    margin: 0.75rem !important;
  }

  .md\:builder-m-4{
    margin: 1rem !important;
  }

  .md\:builder-m-5{
    margin: 1.25rem !important;
  }

  .md\:builder-m-6{
    margin: 1.5rem !important;
  }

  .md\:builder-m-7{
    margin: 1.75rem !important;
  }

  .md\:builder-m-8{
    margin: 2rem !important;
  }

  .md\:builder-m-9{
    margin: 2.25rem !important;
  }

  .md\:builder-m-10{
    margin: 2.5rem !important;
  }

  .md\:builder-m-11{
    margin: 2.75rem !important;
  }

  .md\:builder-m-12{
    margin: 3rem !important;
  }

  .md\:builder-m-14{
    margin: 3.5rem !important;
  }

  .md\:builder-m-16{
    margin: 4rem !important;
  }

  .md\:builder-m-20{
    margin: 5rem !important;
  }

  .md\:builder-m-24{
    margin: 6rem !important;
  }

  .md\:builder-m-28{
    margin: 7rem !important;
  }

  .md\:builder-m-32{
    margin: 8rem !important;
  }

  .md\:builder-m-36{
    margin: 9rem !important;
  }

  .md\:builder-m-40{
    margin: 10rem !important;
  }

  .md\:builder-m-44{
    margin: 11rem !important;
  }

  .md\:builder-m-48{
    margin: 12rem !important;
  }

  .md\:builder-m-52{
    margin: 13rem !important;
  }

  .md\:builder-m-56{
    margin: 14rem !important;
  }

  .md\:builder-m-60{
    margin: 15rem !important;
  }

  .md\:builder-m-64{
    margin: 16rem !important;
  }

  .md\:builder-m-72{
    margin: 18rem !important;
  }

  .md\:builder-m-80{
    margin: 20rem !important;
  }

  .md\:builder-m-96{
    margin: 24rem !important;
  }

  .md\:builder-m-auto{
    margin: auto !important;
  }

  .md\:builder-m-px{
    margin: 1px !important;
  }

  .md\:builder-m-0\.5{
    margin: 0.125rem !important;
  }

  .md\:builder-m-1\.5{
    margin: 0.375rem !important;
  }

  .md\:builder-m-2\.5{
    margin: 0.625rem !important;
  }

  .md\:builder-m-3\.5{
    margin: 0.875rem !important;
  }

  .md\:builder--m-0{
    margin: 0px !important;
  }

  .md\:builder--m-1{
    margin: -0.25rem !important;
  }

  .md\:builder--m-2{
    margin: -0.5rem !important;
  }

  .md\:builder--m-3{
    margin: -0.75rem !important;
  }

  .md\:builder--m-4{
    margin: -1rem !important;
  }

  .md\:builder--m-5{
    margin: -1.25rem !important;
  }

  .md\:builder--m-6{
    margin: -1.5rem !important;
  }

  .md\:builder--m-7{
    margin: -1.75rem !important;
  }

  .md\:builder--m-8{
    margin: -2rem !important;
  }

  .md\:builder--m-9{
    margin: -2.25rem !important;
  }

  .md\:builder--m-10{
    margin: -2.5rem !important;
  }

  .md\:builder--m-11{
    margin: -2.75rem !important;
  }

  .md\:builder--m-12{
    margin: -3rem !important;
  }

  .md\:builder--m-14{
    margin: -3.5rem !important;
  }

  .md\:builder--m-16{
    margin: -4rem !important;
  }

  .md\:builder--m-20{
    margin: -5rem !important;
  }

  .md\:builder--m-24{
    margin: -6rem !important;
  }

  .md\:builder--m-28{
    margin: -7rem !important;
  }

  .md\:builder--m-32{
    margin: -8rem !important;
  }

  .md\:builder--m-36{
    margin: -9rem !important;
  }

  .md\:builder--m-40{
    margin: -10rem !important;
  }

  .md\:builder--m-44{
    margin: -11rem !important;
  }

  .md\:builder--m-48{
    margin: -12rem !important;
  }

  .md\:builder--m-52{
    margin: -13rem !important;
  }

  .md\:builder--m-56{
    margin: -14rem !important;
  }

  .md\:builder--m-60{
    margin: -15rem !important;
  }

  .md\:builder--m-64{
    margin: -16rem !important;
  }

  .md\:builder--m-72{
    margin: -18rem !important;
  }

  .md\:builder--m-80{
    margin: -20rem !important;
  }

  .md\:builder--m-96{
    margin: -24rem !important;
  }

  .md\:builder--m-px{
    margin: -1px !important;
  }

  .md\:builder--m-0\.5{
    margin: -0.125rem !important;
  }

  .md\:builder--m-1\.5{
    margin: -0.375rem !important;
  }

  .md\:builder--m-2\.5{
    margin: -0.625rem !important;
  }

  .md\:builder--m-3\.5{
    margin: -0.875rem !important;
  }

  .md\:builder-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:builder-mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .md\:builder-mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:builder-mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .md\:builder-mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:builder-mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .md\:builder-mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:builder-mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .md\:builder-mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:builder-mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .md\:builder-mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .md\:builder-mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .md\:builder-mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:builder-mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .md\:builder-mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:builder-mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:builder-mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .md\:builder-mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .md\:builder-mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .md\:builder-mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .md\:builder-mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .md\:builder-mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .md\:builder-mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .md\:builder-mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .md\:builder-mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .md\:builder-mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .md\:builder-mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .md\:builder-mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .md\:builder-mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .md\:builder-mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .md\:builder-mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:builder-mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .md\:builder-mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .md\:builder-mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .md\:builder-mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .md\:builder-mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .md\:builder--mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:builder--mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .md\:builder--mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:builder--mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .md\:builder--mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:builder--mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .md\:builder--mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:builder--mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .md\:builder--mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:builder--mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .md\:builder--mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .md\:builder--mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .md\:builder--mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:builder--mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .md\:builder--mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:builder--mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:builder--mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .md\:builder--mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .md\:builder--mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .md\:builder--mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .md\:builder--mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .md\:builder--mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .md\:builder--mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .md\:builder--mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .md\:builder--mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .md\:builder--mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .md\:builder--mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .md\:builder--mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .md\:builder--mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .md\:builder--mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .md\:builder--mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .md\:builder--mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .md\:builder--mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .md\:builder--mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .md\:builder--mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .md\:builder-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md\:builder-my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .md\:builder-my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .md\:builder-my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .md\:builder-my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:builder-my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .md\:builder-my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:builder-my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .md\:builder-my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:builder-my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .md\:builder-my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .md\:builder-my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .md\:builder-my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:builder-my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .md\:builder-my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:builder-my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:builder-my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .md\:builder-my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .md\:builder-my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .md\:builder-my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .md\:builder-my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .md\:builder-my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .md\:builder-my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .md\:builder-my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .md\:builder-my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .md\:builder-my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .md\:builder-my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .md\:builder-my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .md\:builder-my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .md\:builder-my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .md\:builder-my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .md\:builder-my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .md\:builder-my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .md\:builder-my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .md\:builder-my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .md\:builder-my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .md\:builder--my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md\:builder--my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .md\:builder--my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .md\:builder--my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .md\:builder--my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:builder--my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .md\:builder--my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:builder--my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .md\:builder--my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:builder--my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .md\:builder--my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .md\:builder--my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .md\:builder--my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:builder--my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .md\:builder--my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:builder--my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:builder--my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .md\:builder--my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .md\:builder--my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .md\:builder--my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .md\:builder--my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .md\:builder--my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .md\:builder--my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .md\:builder--my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .md\:builder--my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .md\:builder--my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .md\:builder--my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .md\:builder--my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .md\:builder--my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .md\:builder--my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .md\:builder--my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .md\:builder--my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .md\:builder--my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .md\:builder--my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .md\:builder--my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .md\:builder-mt-0{
    margin-top: 0px !important;
  }

  .md\:builder-mt-1{
    margin-top: 0.25rem !important;
  }

  .md\:builder-mt-2{
    margin-top: 0.5rem !important;
  }

  .md\:builder-mt-3{
    margin-top: 0.75rem !important;
  }

  .md\:builder-mt-4{
    margin-top: 1rem !important;
  }

  .md\:builder-mt-5{
    margin-top: 1.25rem !important;
  }

  .md\:builder-mt-6{
    margin-top: 1.5rem !important;
  }

  .md\:builder-mt-7{
    margin-top: 1.75rem !important;
  }

  .md\:builder-mt-8{
    margin-top: 2rem !important;
  }

  .md\:builder-mt-9{
    margin-top: 2.25rem !important;
  }

  .md\:builder-mt-10{
    margin-top: 2.5rem !important;
  }

  .md\:builder-mt-11{
    margin-top: 2.75rem !important;
  }

  .md\:builder-mt-12{
    margin-top: 3rem !important;
  }

  .md\:builder-mt-14{
    margin-top: 3.5rem !important;
  }

  .md\:builder-mt-16{
    margin-top: 4rem !important;
  }

  .md\:builder-mt-20{
    margin-top: 5rem !important;
  }

  .md\:builder-mt-24{
    margin-top: 6rem !important;
  }

  .md\:builder-mt-28{
    margin-top: 7rem !important;
  }

  .md\:builder-mt-32{
    margin-top: 8rem !important;
  }

  .md\:builder-mt-36{
    margin-top: 9rem !important;
  }

  .md\:builder-mt-40{
    margin-top: 10rem !important;
  }

  .md\:builder-mt-44{
    margin-top: 11rem !important;
  }

  .md\:builder-mt-48{
    margin-top: 12rem !important;
  }

  .md\:builder-mt-52{
    margin-top: 13rem !important;
  }

  .md\:builder-mt-56{
    margin-top: 14rem !important;
  }

  .md\:builder-mt-60{
    margin-top: 15rem !important;
  }

  .md\:builder-mt-64{
    margin-top: 16rem !important;
  }

  .md\:builder-mt-72{
    margin-top: 18rem !important;
  }

  .md\:builder-mt-80{
    margin-top: 20rem !important;
  }

  .md\:builder-mt-96{
    margin-top: 24rem !important;
  }

  .md\:builder-mt-auto{
    margin-top: auto !important;
  }

  .md\:builder-mt-px{
    margin-top: 1px !important;
  }

  .md\:builder-mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .md\:builder-mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .md\:builder-mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .md\:builder-mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .md\:builder--mt-0{
    margin-top: 0px !important;
  }

  .md\:builder--mt-1{
    margin-top: -0.25rem !important;
  }

  .md\:builder--mt-2{
    margin-top: -0.5rem !important;
  }

  .md\:builder--mt-3{
    margin-top: -0.75rem !important;
  }

  .md\:builder--mt-4{
    margin-top: -1rem !important;
  }

  .md\:builder--mt-5{
    margin-top: -1.25rem !important;
  }

  .md\:builder--mt-6{
    margin-top: -1.5rem !important;
  }

  .md\:builder--mt-7{
    margin-top: -1.75rem !important;
  }

  .md\:builder--mt-8{
    margin-top: -2rem !important;
  }

  .md\:builder--mt-9{
    margin-top: -2.25rem !important;
  }

  .md\:builder--mt-10{
    margin-top: -2.5rem !important;
  }

  .md\:builder--mt-11{
    margin-top: -2.75rem !important;
  }

  .md\:builder--mt-12{
    margin-top: -3rem !important;
  }

  .md\:builder--mt-14{
    margin-top: -3.5rem !important;
  }

  .md\:builder--mt-16{
    margin-top: -4rem !important;
  }

  .md\:builder--mt-20{
    margin-top: -5rem !important;
  }

  .md\:builder--mt-24{
    margin-top: -6rem !important;
  }

  .md\:builder--mt-28{
    margin-top: -7rem !important;
  }

  .md\:builder--mt-32{
    margin-top: -8rem !important;
  }

  .md\:builder--mt-36{
    margin-top: -9rem !important;
  }

  .md\:builder--mt-40{
    margin-top: -10rem !important;
  }

  .md\:builder--mt-44{
    margin-top: -11rem !important;
  }

  .md\:builder--mt-48{
    margin-top: -12rem !important;
  }

  .md\:builder--mt-52{
    margin-top: -13rem !important;
  }

  .md\:builder--mt-56{
    margin-top: -14rem !important;
  }

  .md\:builder--mt-60{
    margin-top: -15rem !important;
  }

  .md\:builder--mt-64{
    margin-top: -16rem !important;
  }

  .md\:builder--mt-72{
    margin-top: -18rem !important;
  }

  .md\:builder--mt-80{
    margin-top: -20rem !important;
  }

  .md\:builder--mt-96{
    margin-top: -24rem !important;
  }

  .md\:builder--mt-px{
    margin-top: -1px !important;
  }

  .md\:builder--mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .md\:builder--mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .md\:builder--mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .md\:builder--mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .md\:builder-mr-0{
    margin-right: 0px !important;
  }

  .md\:builder-mr-1{
    margin-right: 0.25rem !important;
  }

  .md\:builder-mr-2{
    margin-right: 0.5rem !important;
  }

  .md\:builder-mr-3{
    margin-right: 0.75rem !important;
  }

  .md\:builder-mr-4{
    margin-right: 1rem !important;
  }

  .md\:builder-mr-5{
    margin-right: 1.25rem !important;
  }

  .md\:builder-mr-6{
    margin-right: 1.5rem !important;
  }

  .md\:builder-mr-7{
    margin-right: 1.75rem !important;
  }

  .md\:builder-mr-8{
    margin-right: 2rem !important;
  }

  .md\:builder-mr-9{
    margin-right: 2.25rem !important;
  }

  .md\:builder-mr-10{
    margin-right: 2.5rem !important;
  }

  .md\:builder-mr-11{
    margin-right: 2.75rem !important;
  }

  .md\:builder-mr-12{
    margin-right: 3rem !important;
  }

  .md\:builder-mr-14{
    margin-right: 3.5rem !important;
  }

  .md\:builder-mr-16{
    margin-right: 4rem !important;
  }

  .md\:builder-mr-20{
    margin-right: 5rem !important;
  }

  .md\:builder-mr-24{
    margin-right: 6rem !important;
  }

  .md\:builder-mr-28{
    margin-right: 7rem !important;
  }

  .md\:builder-mr-32{
    margin-right: 8rem !important;
  }

  .md\:builder-mr-36{
    margin-right: 9rem !important;
  }

  .md\:builder-mr-40{
    margin-right: 10rem !important;
  }

  .md\:builder-mr-44{
    margin-right: 11rem !important;
  }

  .md\:builder-mr-48{
    margin-right: 12rem !important;
  }

  .md\:builder-mr-52{
    margin-right: 13rem !important;
  }

  .md\:builder-mr-56{
    margin-right: 14rem !important;
  }

  .md\:builder-mr-60{
    margin-right: 15rem !important;
  }

  .md\:builder-mr-64{
    margin-right: 16rem !important;
  }

  .md\:builder-mr-72{
    margin-right: 18rem !important;
  }

  .md\:builder-mr-80{
    margin-right: 20rem !important;
  }

  .md\:builder-mr-96{
    margin-right: 24rem !important;
  }

  .md\:builder-mr-auto{
    margin-right: auto !important;
  }

  .md\:builder-mr-px{
    margin-right: 1px !important;
  }

  .md\:builder-mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .md\:builder-mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .md\:builder-mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .md\:builder-mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .md\:builder--mr-0{
    margin-right: 0px !important;
  }

  .md\:builder--mr-1{
    margin-right: -0.25rem !important;
  }

  .md\:builder--mr-2{
    margin-right: -0.5rem !important;
  }

  .md\:builder--mr-3{
    margin-right: -0.75rem !important;
  }

  .md\:builder--mr-4{
    margin-right: -1rem !important;
  }

  .md\:builder--mr-5{
    margin-right: -1.25rem !important;
  }

  .md\:builder--mr-6{
    margin-right: -1.5rem !important;
  }

  .md\:builder--mr-7{
    margin-right: -1.75rem !important;
  }

  .md\:builder--mr-8{
    margin-right: -2rem !important;
  }

  .md\:builder--mr-9{
    margin-right: -2.25rem !important;
  }

  .md\:builder--mr-10{
    margin-right: -2.5rem !important;
  }

  .md\:builder--mr-11{
    margin-right: -2.75rem !important;
  }

  .md\:builder--mr-12{
    margin-right: -3rem !important;
  }

  .md\:builder--mr-14{
    margin-right: -3.5rem !important;
  }

  .md\:builder--mr-16{
    margin-right: -4rem !important;
  }

  .md\:builder--mr-20{
    margin-right: -5rem !important;
  }

  .md\:builder--mr-24{
    margin-right: -6rem !important;
  }

  .md\:builder--mr-28{
    margin-right: -7rem !important;
  }

  .md\:builder--mr-32{
    margin-right: -8rem !important;
  }

  .md\:builder--mr-36{
    margin-right: -9rem !important;
  }

  .md\:builder--mr-40{
    margin-right: -10rem !important;
  }

  .md\:builder--mr-44{
    margin-right: -11rem !important;
  }

  .md\:builder--mr-48{
    margin-right: -12rem !important;
  }

  .md\:builder--mr-52{
    margin-right: -13rem !important;
  }

  .md\:builder--mr-56{
    margin-right: -14rem !important;
  }

  .md\:builder--mr-60{
    margin-right: -15rem !important;
  }

  .md\:builder--mr-64{
    margin-right: -16rem !important;
  }

  .md\:builder--mr-72{
    margin-right: -18rem !important;
  }

  .md\:builder--mr-80{
    margin-right: -20rem !important;
  }

  .md\:builder--mr-96{
    margin-right: -24rem !important;
  }

  .md\:builder--mr-px{
    margin-right: -1px !important;
  }

  .md\:builder--mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .md\:builder--mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .md\:builder--mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .md\:builder--mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .md\:builder-mb-0{
    margin-bottom: 0px !important;
  }

  .md\:builder-mb-1{
    margin-bottom: 0.25rem !important;
  }

  .md\:builder-mb-2{
    margin-bottom: 0.5rem !important;
  }

  .md\:builder-mb-3{
    margin-bottom: 0.75rem !important;
  }

  .md\:builder-mb-4{
    margin-bottom: 1rem !important;
  }

  .md\:builder-mb-5{
    margin-bottom: 1.25rem !important;
  }

  .md\:builder-mb-6{
    margin-bottom: 1.5rem !important;
  }

  .md\:builder-mb-7{
    margin-bottom: 1.75rem !important;
  }

  .md\:builder-mb-8{
    margin-bottom: 2rem !important;
  }

  .md\:builder-mb-9{
    margin-bottom: 2.25rem !important;
  }

  .md\:builder-mb-10{
    margin-bottom: 2.5rem !important;
  }

  .md\:builder-mb-11{
    margin-bottom: 2.75rem !important;
  }

  .md\:builder-mb-12{
    margin-bottom: 3rem !important;
  }

  .md\:builder-mb-14{
    margin-bottom: 3.5rem !important;
  }

  .md\:builder-mb-16{
    margin-bottom: 4rem !important;
  }

  .md\:builder-mb-20{
    margin-bottom: 5rem !important;
  }

  .md\:builder-mb-24{
    margin-bottom: 6rem !important;
  }

  .md\:builder-mb-28{
    margin-bottom: 7rem !important;
  }

  .md\:builder-mb-32{
    margin-bottom: 8rem !important;
  }

  .md\:builder-mb-36{
    margin-bottom: 9rem !important;
  }

  .md\:builder-mb-40{
    margin-bottom: 10rem !important;
  }

  .md\:builder-mb-44{
    margin-bottom: 11rem !important;
  }

  .md\:builder-mb-48{
    margin-bottom: 12rem !important;
  }

  .md\:builder-mb-52{
    margin-bottom: 13rem !important;
  }

  .md\:builder-mb-56{
    margin-bottom: 14rem !important;
  }

  .md\:builder-mb-60{
    margin-bottom: 15rem !important;
  }

  .md\:builder-mb-64{
    margin-bottom: 16rem !important;
  }

  .md\:builder-mb-72{
    margin-bottom: 18rem !important;
  }

  .md\:builder-mb-80{
    margin-bottom: 20rem !important;
  }

  .md\:builder-mb-96{
    margin-bottom: 24rem !important;
  }

  .md\:builder-mb-auto{
    margin-bottom: auto !important;
  }

  .md\:builder-mb-px{
    margin-bottom: 1px !important;
  }

  .md\:builder-mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .md\:builder-mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .md\:builder-mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .md\:builder-mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .md\:builder--mb-0{
    margin-bottom: 0px !important;
  }

  .md\:builder--mb-1{
    margin-bottom: -0.25rem !important;
  }

  .md\:builder--mb-2{
    margin-bottom: -0.5rem !important;
  }

  .md\:builder--mb-3{
    margin-bottom: -0.75rem !important;
  }

  .md\:builder--mb-4{
    margin-bottom: -1rem !important;
  }

  .md\:builder--mb-5{
    margin-bottom: -1.25rem !important;
  }

  .md\:builder--mb-6{
    margin-bottom: -1.5rem !important;
  }

  .md\:builder--mb-7{
    margin-bottom: -1.75rem !important;
  }

  .md\:builder--mb-8{
    margin-bottom: -2rem !important;
  }

  .md\:builder--mb-9{
    margin-bottom: -2.25rem !important;
  }

  .md\:builder--mb-10{
    margin-bottom: -2.5rem !important;
  }

  .md\:builder--mb-11{
    margin-bottom: -2.75rem !important;
  }

  .md\:builder--mb-12{
    margin-bottom: -3rem !important;
  }

  .md\:builder--mb-14{
    margin-bottom: -3.5rem !important;
  }

  .md\:builder--mb-16{
    margin-bottom: -4rem !important;
  }

  .md\:builder--mb-20{
    margin-bottom: -5rem !important;
  }

  .md\:builder--mb-24{
    margin-bottom: -6rem !important;
  }

  .md\:builder--mb-28{
    margin-bottom: -7rem !important;
  }

  .md\:builder--mb-32{
    margin-bottom: -8rem !important;
  }

  .md\:builder--mb-36{
    margin-bottom: -9rem !important;
  }

  .md\:builder--mb-40{
    margin-bottom: -10rem !important;
  }

  .md\:builder--mb-44{
    margin-bottom: -11rem !important;
  }

  .md\:builder--mb-48{
    margin-bottom: -12rem !important;
  }

  .md\:builder--mb-52{
    margin-bottom: -13rem !important;
  }

  .md\:builder--mb-56{
    margin-bottom: -14rem !important;
  }

  .md\:builder--mb-60{
    margin-bottom: -15rem !important;
  }

  .md\:builder--mb-64{
    margin-bottom: -16rem !important;
  }

  .md\:builder--mb-72{
    margin-bottom: -18rem !important;
  }

  .md\:builder--mb-80{
    margin-bottom: -20rem !important;
  }

  .md\:builder--mb-96{
    margin-bottom: -24rem !important;
  }

  .md\:builder--mb-px{
    margin-bottom: -1px !important;
  }

  .md\:builder--mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .md\:builder--mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .md\:builder--mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .md\:builder--mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .md\:builder-ml-0{
    margin-left: 0px !important;
  }

  .md\:builder-ml-1{
    margin-left: 0.25rem !important;
  }

  .md\:builder-ml-2{
    margin-left: 0.5rem !important;
  }

  .md\:builder-ml-3{
    margin-left: 0.75rem !important;
  }

  .md\:builder-ml-4{
    margin-left: 1rem !important;
  }

  .md\:builder-ml-5{
    margin-left: 1.25rem !important;
  }

  .md\:builder-ml-6{
    margin-left: 1.5rem !important;
  }

  .md\:builder-ml-7{
    margin-left: 1.75rem !important;
  }

  .md\:builder-ml-8{
    margin-left: 2rem !important;
  }

  .md\:builder-ml-9{
    margin-left: 2.25rem !important;
  }

  .md\:builder-ml-10{
    margin-left: 2.5rem !important;
  }

  .md\:builder-ml-11{
    margin-left: 2.75rem !important;
  }

  .md\:builder-ml-12{
    margin-left: 3rem !important;
  }

  .md\:builder-ml-14{
    margin-left: 3.5rem !important;
  }

  .md\:builder-ml-16{
    margin-left: 4rem !important;
  }

  .md\:builder-ml-20{
    margin-left: 5rem !important;
  }

  .md\:builder-ml-24{
    margin-left: 6rem !important;
  }

  .md\:builder-ml-28{
    margin-left: 7rem !important;
  }

  .md\:builder-ml-32{
    margin-left: 8rem !important;
  }

  .md\:builder-ml-36{
    margin-left: 9rem !important;
  }

  .md\:builder-ml-40{
    margin-left: 10rem !important;
  }

  .md\:builder-ml-44{
    margin-left: 11rem !important;
  }

  .md\:builder-ml-48{
    margin-left: 12rem !important;
  }

  .md\:builder-ml-52{
    margin-left: 13rem !important;
  }

  .md\:builder-ml-56{
    margin-left: 14rem !important;
  }

  .md\:builder-ml-60{
    margin-left: 15rem !important;
  }

  .md\:builder-ml-64{
    margin-left: 16rem !important;
  }

  .md\:builder-ml-72{
    margin-left: 18rem !important;
  }

  .md\:builder-ml-80{
    margin-left: 20rem !important;
  }

  .md\:builder-ml-96{
    margin-left: 24rem !important;
  }

  .md\:builder-ml-auto{
    margin-left: auto !important;
  }

  .md\:builder-ml-px{
    margin-left: 1px !important;
  }

  .md\:builder-ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .md\:builder-ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .md\:builder-ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .md\:builder-ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .md\:builder--ml-0{
    margin-left: 0px !important;
  }

  .md\:builder--ml-1{
    margin-left: -0.25rem !important;
  }

  .md\:builder--ml-2{
    margin-left: -0.5rem !important;
  }

  .md\:builder--ml-3{
    margin-left: -0.75rem !important;
  }

  .md\:builder--ml-4{
    margin-left: -1rem !important;
  }

  .md\:builder--ml-5{
    margin-left: -1.25rem !important;
  }

  .md\:builder--ml-6{
    margin-left: -1.5rem !important;
  }

  .md\:builder--ml-7{
    margin-left: -1.75rem !important;
  }

  .md\:builder--ml-8{
    margin-left: -2rem !important;
  }

  .md\:builder--ml-9{
    margin-left: -2.25rem !important;
  }

  .md\:builder--ml-10{
    margin-left: -2.5rem !important;
  }

  .md\:builder--ml-11{
    margin-left: -2.75rem !important;
  }

  .md\:builder--ml-12{
    margin-left: -3rem !important;
  }

  .md\:builder--ml-14{
    margin-left: -3.5rem !important;
  }

  .md\:builder--ml-16{
    margin-left: -4rem !important;
  }

  .md\:builder--ml-20{
    margin-left: -5rem !important;
  }

  .md\:builder--ml-24{
    margin-left: -6rem !important;
  }

  .md\:builder--ml-28{
    margin-left: -7rem !important;
  }

  .md\:builder--ml-32{
    margin-left: -8rem !important;
  }

  .md\:builder--ml-36{
    margin-left: -9rem !important;
  }

  .md\:builder--ml-40{
    margin-left: -10rem !important;
  }

  .md\:builder--ml-44{
    margin-left: -11rem !important;
  }

  .md\:builder--ml-48{
    margin-left: -12rem !important;
  }

  .md\:builder--ml-52{
    margin-left: -13rem !important;
  }

  .md\:builder--ml-56{
    margin-left: -14rem !important;
  }

  .md\:builder--ml-60{
    margin-left: -15rem !important;
  }

  .md\:builder--ml-64{
    margin-left: -16rem !important;
  }

  .md\:builder--ml-72{
    margin-left: -18rem !important;
  }

  .md\:builder--ml-80{
    margin-left: -20rem !important;
  }

  .md\:builder--ml-96{
    margin-left: -24rem !important;
  }

  .md\:builder--ml-px{
    margin-left: -1px !important;
  }

  .md\:builder--ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .md\:builder--ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .md\:builder--ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .md\:builder--ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .md\:builder-box-border{
    box-sizing: border-box !important;
  }

  .md\:builder-box-content{
    box-sizing: content-box !important;
  }

  .md\:builder-block{
    display: block !important;
  }

  .md\:builder-inline-block{
    display: inline-block !important;
  }

  .md\:builder-inline{
    display: inline !important;
  }

  .md\:builder-flex{
    display: flex !important;
  }

  .md\:builder-inline-flex{
    display: inline-flex !important;
  }

  .md\:builder-table{
    display: table !important;
  }

  .md\:builder-inline-table{
    display: inline-table !important;
  }

  .md\:builder-table-caption{
    display: table-caption !important;
  }

  .md\:builder-table-cell{
    display: table-cell !important;
  }

  .md\:builder-table-column{
    display: table-column !important;
  }

  .md\:builder-table-column-group{
    display: table-column-group !important;
  }

  .md\:builder-table-footer-group{
    display: table-footer-group !important;
  }

  .md\:builder-table-header-group{
    display: table-header-group !important;
  }

  .md\:builder-table-row-group{
    display: table-row-group !important;
  }

  .md\:builder-table-row{
    display: table-row !important;
  }

  .md\:builder-flow-root{
    display: flow-root !important;
  }

  .md\:builder-grid{
    display: grid !important;
  }

  .md\:builder-inline-grid{
    display: inline-grid !important;
  }

  .md\:builder-contents{
    display: contents !important;
  }

  .md\:builder-list-item{
    display: list-item !important;
  }

  .md\:builder-hidden{
    display: none !important;
  }

  .md\:builder-h-0{
    height: 0px !important;
  }

  .md\:builder-h-1{
    height: 0.25rem !important;
  }

  .md\:builder-h-2{
    height: 0.5rem !important;
  }

  .md\:builder-h-3{
    height: 0.75rem !important;
  }

  .md\:builder-h-4{
    height: 1rem !important;
  }

  .md\:builder-h-5{
    height: 1.25rem !important;
  }

  .md\:builder-h-6{
    height: 1.5rem !important;
  }

  .md\:builder-h-7{
    height: 1.75rem !important;
  }

  .md\:builder-h-8{
    height: 2rem !important;
  }

  .md\:builder-h-9{
    height: 2.25rem !important;
  }

  .md\:builder-h-10{
    height: 2.5rem !important;
  }

  .md\:builder-h-11{
    height: 2.75rem !important;
  }

  .md\:builder-h-12{
    height: 3rem !important;
  }

  .md\:builder-h-14{
    height: 3.5rem !important;
  }

  .md\:builder-h-16{
    height: 4rem !important;
  }

  .md\:builder-h-20{
    height: 5rem !important;
  }

  .md\:builder-h-24{
    height: 6rem !important;
  }

  .md\:builder-h-28{
    height: 7rem !important;
  }

  .md\:builder-h-32{
    height: 8rem !important;
  }

  .md\:builder-h-36{
    height: 9rem !important;
  }

  .md\:builder-h-40{
    height: 10rem !important;
  }

  .md\:builder-h-44{
    height: 11rem !important;
  }

  .md\:builder-h-48{
    height: 12rem !important;
  }

  .md\:builder-h-52{
    height: 13rem !important;
  }

  .md\:builder-h-56{
    height: 14rem !important;
  }

  .md\:builder-h-60{
    height: 15rem !important;
  }

  .md\:builder-h-64{
    height: 16rem !important;
  }

  .md\:builder-h-72{
    height: 18rem !important;
  }

  .md\:builder-h-80{
    height: 20rem !important;
  }

  .md\:builder-h-96{
    height: 24rem !important;
  }

  .md\:builder-h-auto{
    height: auto !important;
  }

  .md\:builder-h-px{
    height: 1px !important;
  }

  .md\:builder-h-0\.5{
    height: 0.125rem !important;
  }

  .md\:builder-h-1\.5{
    height: 0.375rem !important;
  }

  .md\:builder-h-2\.5{
    height: 0.625rem !important;
  }

  .md\:builder-h-3\.5{
    height: 0.875rem !important;
  }

  .md\:builder-h-1\/2{
    height: 50% !important;
  }

  .md\:builder-h-1\/3{
    height: 33.333333% !important;
  }

  .md\:builder-h-2\/3{
    height: 66.666667% !important;
  }

  .md\:builder-h-1\/4{
    height: 25% !important;
  }

  .md\:builder-h-2\/4{
    height: 50% !important;
  }

  .md\:builder-h-3\/4{
    height: 75% !important;
  }

  .md\:builder-h-1\/5{
    height: 20% !important;
  }

  .md\:builder-h-2\/5{
    height: 40% !important;
  }

  .md\:builder-h-3\/5{
    height: 60% !important;
  }

  .md\:builder-h-4\/5{
    height: 80% !important;
  }

  .md\:builder-h-1\/6{
    height: 16.666667% !important;
  }

  .md\:builder-h-2\/6{
    height: 33.333333% !important;
  }

  .md\:builder-h-3\/6{
    height: 50% !important;
  }

  .md\:builder-h-4\/6{
    height: 66.666667% !important;
  }

  .md\:builder-h-5\/6{
    height: 83.333333% !important;
  }

  .md\:builder-h-full{
    height: 100% !important;
  }

  .md\:builder-h-screen{
    height: 100vh !important;
  }

  .md\:builder-max-h-0{
    max-height: 0px !important;
  }

  .md\:builder-max-h-1{
    max-height: 0.25rem !important;
  }

  .md\:builder-max-h-2{
    max-height: 0.5rem !important;
  }

  .md\:builder-max-h-3{
    max-height: 0.75rem !important;
  }

  .md\:builder-max-h-4{
    max-height: 1rem !important;
  }

  .md\:builder-max-h-5{
    max-height: 1.25rem !important;
  }

  .md\:builder-max-h-6{
    max-height: 1.5rem !important;
  }

  .md\:builder-max-h-7{
    max-height: 1.75rem !important;
  }

  .md\:builder-max-h-8{
    max-height: 2rem !important;
  }

  .md\:builder-max-h-9{
    max-height: 2.25rem !important;
  }

  .md\:builder-max-h-10{
    max-height: 2.5rem !important;
  }

  .md\:builder-max-h-11{
    max-height: 2.75rem !important;
  }

  .md\:builder-max-h-12{
    max-height: 3rem !important;
  }

  .md\:builder-max-h-14{
    max-height: 3.5rem !important;
  }

  .md\:builder-max-h-16{
    max-height: 4rem !important;
  }

  .md\:builder-max-h-20{
    max-height: 5rem !important;
  }

  .md\:builder-max-h-24{
    max-height: 6rem !important;
  }

  .md\:builder-max-h-28{
    max-height: 7rem !important;
  }

  .md\:builder-max-h-32{
    max-height: 8rem !important;
  }

  .md\:builder-max-h-36{
    max-height: 9rem !important;
  }

  .md\:builder-max-h-40{
    max-height: 10rem !important;
  }

  .md\:builder-max-h-44{
    max-height: 11rem !important;
  }

  .md\:builder-max-h-48{
    max-height: 12rem !important;
  }

  .md\:builder-max-h-52{
    max-height: 13rem !important;
  }

  .md\:builder-max-h-56{
    max-height: 14rem !important;
  }

  .md\:builder-max-h-60{
    max-height: 15rem !important;
  }

  .md\:builder-max-h-64{
    max-height: 16rem !important;
  }

  .md\:builder-max-h-72{
    max-height: 18rem !important;
  }

  .md\:builder-max-h-80{
    max-height: 20rem !important;
  }

  .md\:builder-max-h-96{
    max-height: 24rem !important;
  }

  .md\:builder-max-h-px{
    max-height: 1px !important;
  }

  .md\:builder-max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .md\:builder-max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .md\:builder-max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .md\:builder-max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .md\:builder-max-h-full{
    max-height: 100% !important;
  }

  .md\:builder-max-h-screen{
    max-height: 100vh !important;
  }

  .md\:builder-min-h-0{
    min-height: 0px !important;
  }

  .md\:builder-min-h-full{
    min-height: 100% !important;
  }

  .md\:builder-min-h-screen{
    min-height: 100vh !important;
  }

  .md\:builder-w-0{
    width: 0px !important;
  }

  .md\:builder-w-1{
    width: 0.25rem !important;
  }

  .md\:builder-w-2{
    width: 0.5rem !important;
  }

  .md\:builder-w-3{
    width: 0.75rem !important;
  }

  .md\:builder-w-4{
    width: 1rem !important;
  }

  .md\:builder-w-5{
    width: 1.25rem !important;
  }

  .md\:builder-w-6{
    width: 1.5rem !important;
  }

  .md\:builder-w-7{
    width: 1.75rem !important;
  }

  .md\:builder-w-8{
    width: 2rem !important;
  }

  .md\:builder-w-9{
    width: 2.25rem !important;
  }

  .md\:builder-w-10{
    width: 2.5rem !important;
  }

  .md\:builder-w-11{
    width: 2.75rem !important;
  }

  .md\:builder-w-12{
    width: 3rem !important;
  }

  .md\:builder-w-14{
    width: 3.5rem !important;
  }

  .md\:builder-w-16{
    width: 4rem !important;
  }

  .md\:builder-w-20{
    width: 5rem !important;
  }

  .md\:builder-w-24{
    width: 6rem !important;
  }

  .md\:builder-w-28{
    width: 7rem !important;
  }

  .md\:builder-w-32{
    width: 8rem !important;
  }

  .md\:builder-w-36{
    width: 9rem !important;
  }

  .md\:builder-w-40{
    width: 10rem !important;
  }

  .md\:builder-w-44{
    width: 11rem !important;
  }

  .md\:builder-w-48{
    width: 12rem !important;
  }

  .md\:builder-w-52{
    width: 13rem !important;
  }

  .md\:builder-w-56{
    width: 14rem !important;
  }

  .md\:builder-w-60{
    width: 15rem !important;
  }

  .md\:builder-w-64{
    width: 16rem !important;
  }

  .md\:builder-w-72{
    width: 18rem !important;
  }

  .md\:builder-w-80{
    width: 20rem !important;
  }

  .md\:builder-w-96{
    width: 24rem !important;
  }

  .md\:builder-w-auto{
    width: auto !important;
  }

  .md\:builder-w-px{
    width: 1px !important;
  }

  .md\:builder-w-0\.5{
    width: 0.125rem !important;
  }

  .md\:builder-w-1\.5{
    width: 0.375rem !important;
  }

  .md\:builder-w-2\.5{
    width: 0.625rem !important;
  }

  .md\:builder-w-3\.5{
    width: 0.875rem !important;
  }

  .md\:builder-w-1\/2{
    width: 50% !important;
  }

  .md\:builder-w-1\/3{
    width: 33.333333% !important;
  }

  .md\:builder-w-2\/3{
    width: 66.666667% !important;
  }

  .md\:builder-w-1\/4{
    width: 25% !important;
  }

  .md\:builder-w-2\/4{
    width: 50% !important;
  }

  .md\:builder-w-3\/4{
    width: 75% !important;
  }

  .md\:builder-w-1\/5{
    width: 20% !important;
  }

  .md\:builder-w-2\/5{
    width: 40% !important;
  }

  .md\:builder-w-3\/5{
    width: 60% !important;
  }

  .md\:builder-w-4\/5{
    width: 80% !important;
  }

  .md\:builder-w-1\/6{
    width: 16.666667% !important;
  }

  .md\:builder-w-2\/6{
    width: 33.333333% !important;
  }

  .md\:builder-w-3\/6{
    width: 50% !important;
  }

  .md\:builder-w-4\/6{
    width: 66.666667% !important;
  }

  .md\:builder-w-5\/6{
    width: 83.333333% !important;
  }

  .md\:builder-w-1\/12{
    width: 8.333333% !important;
  }

  .md\:builder-w-2\/12{
    width: 16.666667% !important;
  }

  .md\:builder-w-3\/12{
    width: 25% !important;
  }

  .md\:builder-w-4\/12{
    width: 33.333333% !important;
  }

  .md\:builder-w-5\/12{
    width: 41.666667% !important;
  }

  .md\:builder-w-6\/12{
    width: 50% !important;
  }

  .md\:builder-w-7\/12{
    width: 58.333333% !important;
  }

  .md\:builder-w-8\/12{
    width: 66.666667% !important;
  }

  .md\:builder-w-9\/12{
    width: 75% !important;
  }

  .md\:builder-w-10\/12{
    width: 83.333333% !important;
  }

  .md\:builder-w-11\/12{
    width: 91.666667% !important;
  }

  .md\:builder-w-full{
    width: 100% !important;
  }

  .md\:builder-w-screen{
    width: 100vw !important;
  }

  .md\:builder-w-min{
    width: min-content !important;
  }

  .md\:builder-w-max{
    width: max-content !important;
  }

  .md\:builder-min-w-0{
    min-width: 0px !important;
  }

  .md\:builder-min-w-full{
    min-width: 100% !important;
  }

  .md\:builder-min-w-min{
    min-width: min-content !important;
  }

  .md\:builder-min-w-max{
    min-width: max-content !important;
  }

  .md\:builder-max-w-0{
    max-width: 0rem !important;
  }

  .md\:builder-max-w-none{
    max-width: none !important;
  }

  .md\:builder-max-w-xs{
    max-width: 20rem !important;
  }

  .md\:builder-max-w-sm{
    max-width: 24rem !important;
  }

  .md\:builder-max-w-md{
    max-width: 28rem !important;
  }

  .md\:builder-max-w-lg{
    max-width: 32rem !important;
  }

  .md\:builder-max-w-xl{
    max-width: 36rem !important;
  }

  .md\:builder-max-w-2xl{
    max-width: 42rem !important;
  }

  .md\:builder-max-w-3xl{
    max-width: 48rem !important;
  }

  .md\:builder-max-w-4xl{
    max-width: 56rem !important;
  }

  .md\:builder-max-w-5xl{
    max-width: 64rem !important;
  }

  .md\:builder-max-w-6xl{
    max-width: 72rem !important;
  }

  .md\:builder-max-w-7xl{
    max-width: 80rem !important;
  }

  .md\:builder-max-w-full{
    max-width: 100% !important;
  }

  .md\:builder-max-w-min{
    max-width: min-content !important;
  }

  .md\:builder-max-w-max{
    max-width: max-content !important;
  }

  .md\:builder-max-w-prose{
    max-width: 65ch !important;
  }

  .md\:builder-max-w-screen-sm{
    max-width: 640px !important;
  }

  .md\:builder-max-w-screen-md{
    max-width: 768px !important;
  }

  .md\:builder-max-w-screen-lg{
    max-width: 1024px !important;
  }

  .md\:builder-max-w-screen-xl{
    max-width: 1280px !important;
  }

  .md\:builder-max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .md\:builder-flex-1{
    flex: 1 1 0% !important;
  }

  .md\:builder-flex-auto{
    flex: 1 1 auto !important;
  }

  .md\:builder-flex-initial{
    flex: 0 1 auto !important;
  }

  .md\:builder-flex-none{
    flex: none !important;
  }

  .md\:builder-flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .md\:builder-flex-shrink{
    flex-shrink: 1 !important;
  }

  .md\:builder-flex-grow-0{
    flex-grow: 0 !important;
  }

  .md\:builder-flex-grow{
    flex-grow: 1 !important;
  }

  .md\:builder-table-auto{
    table-layout: auto !important;
  }

  .md\:builder-table-fixed{
    table-layout: fixed !important;
  }

  .md\:builder-border-collapse{
    border-collapse: collapse !important;
  }

  .md\:builder-border-separate{
    border-collapse: separate !important;
  }

  .md\:builder-origin-center{
    transform-origin: center !important;
  }

  .md\:builder-origin-top{
    transform-origin: top !important;
  }

  .md\:builder-origin-top-right{
    transform-origin: top right !important;
  }

  .md\:builder-origin-right{
    transform-origin: right !important;
  }

  .md\:builder-origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .md\:builder-origin-bottom{
    transform-origin: bottom !important;
  }

  .md\:builder-origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .md\:builder-origin-left{
    transform-origin: left !important;
  }

  .md\:builder-origin-top-left{
    transform-origin: top left !important;
  }

  .md\:builder-transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .md\:builder-transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .md\:builder-transform-none{
    transform: none !important;
  }

  .md\:builder-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .md\:builder-translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .md\:builder-translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .md\:builder-translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .md\:builder-translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .md\:builder-translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .md\:builder-translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .md\:builder-translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .md\:builder-translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .md\:builder-translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .md\:builder-translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .md\:builder-translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .md\:builder-translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .md\:builder-translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .md\:builder-translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .md\:builder-translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .md\:builder-translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .md\:builder-translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .md\:builder-translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .md\:builder-translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .md\:builder-translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .md\:builder-translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .md\:builder-translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .md\:builder-translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .md\:builder-translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .md\:builder-translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .md\:builder-translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .md\:builder-translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .md\:builder-translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .md\:builder-translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .md\:builder-translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .md\:builder-translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .md\:builder-translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .md\:builder-translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .md\:builder-translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .md\:builder--translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .md\:builder--translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .md\:builder--translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .md\:builder--translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .md\:builder--translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .md\:builder--translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .md\:builder--translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .md\:builder--translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .md\:builder--translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .md\:builder--translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .md\:builder--translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .md\:builder--translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .md\:builder--translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .md\:builder--translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .md\:builder--translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .md\:builder--translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .md\:builder--translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .md\:builder--translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .md\:builder--translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .md\:builder--translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .md\:builder--translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .md\:builder--translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .md\:builder--translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .md\:builder--translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .md\:builder--translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .md\:builder--translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .md\:builder--translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .md\:builder--translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .md\:builder--translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .md\:builder--translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .md\:builder--translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .md\:builder--translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .md\:builder--translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .md\:builder--translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .md\:builder--translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .md\:builder-translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .md\:builder-translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .md\:builder-translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .md\:builder-translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .md\:builder-translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .md\:builder-translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .md\:builder-translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .md\:builder--translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .md\:builder--translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .md\:builder--translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .md\:builder--translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .md\:builder--translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .md\:builder--translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .md\:builder--translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .md\:builder-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .md\:builder-translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .md\:builder-translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .md\:builder-translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .md\:builder-translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .md\:builder-translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .md\:builder-translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .md\:builder-translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .md\:builder-translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .md\:builder-translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .md\:builder-translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .md\:builder-translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .md\:builder-translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .md\:builder-translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .md\:builder-translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .md\:builder-translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .md\:builder-translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .md\:builder-translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .md\:builder-translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .md\:builder-translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .md\:builder-translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .md\:builder-translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .md\:builder-translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .md\:builder-translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .md\:builder-translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .md\:builder-translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .md\:builder-translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .md\:builder-translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .md\:builder-translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .md\:builder-translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .md\:builder-translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .md\:builder-translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .md\:builder-translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .md\:builder-translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .md\:builder-translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .md\:builder--translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .md\:builder--translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .md\:builder--translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .md\:builder--translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .md\:builder--translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .md\:builder--translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .md\:builder--translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .md\:builder--translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .md\:builder--translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .md\:builder--translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .md\:builder--translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .md\:builder--translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .md\:builder--translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .md\:builder--translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .md\:builder--translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .md\:builder--translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .md\:builder--translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .md\:builder--translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .md\:builder--translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .md\:builder--translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .md\:builder--translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .md\:builder--translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .md\:builder--translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .md\:builder--translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .md\:builder--translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .md\:builder--translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .md\:builder--translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .md\:builder--translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .md\:builder--translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .md\:builder--translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .md\:builder--translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .md\:builder--translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .md\:builder--translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .md\:builder--translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .md\:builder--translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .md\:builder-translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .md\:builder-translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .md\:builder-translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .md\:builder-translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .md\:builder-translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .md\:builder-translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .md\:builder-translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .md\:builder--translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .md\:builder--translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .md\:builder--translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .md\:builder--translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .md\:builder--translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .md\:builder--translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .md\:builder--translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .md\:hover\:builder-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .md\:hover\:builder-translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .md\:hover\:builder-translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .md\:hover\:builder-translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .md\:hover\:builder-translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .md\:hover\:builder-translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .md\:hover\:builder-translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .md\:hover\:builder-translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .md\:hover\:builder-translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .md\:hover\:builder-translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .md\:hover\:builder-translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .md\:hover\:builder-translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .md\:hover\:builder-translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .md\:hover\:builder-translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .md\:hover\:builder-translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .md\:hover\:builder-translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .md\:hover\:builder-translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .md\:hover\:builder-translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .md\:hover\:builder-translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .md\:hover\:builder-translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .md\:hover\:builder-translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .md\:hover\:builder-translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .md\:hover\:builder-translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .md\:hover\:builder-translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .md\:hover\:builder-translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .md\:hover\:builder-translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .md\:hover\:builder-translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .md\:hover\:builder-translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .md\:hover\:builder-translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .md\:hover\:builder-translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .md\:hover\:builder-translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .md\:hover\:builder-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .md\:hover\:builder-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .md\:hover\:builder-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .md\:hover\:builder-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .md\:hover\:builder--translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .md\:hover\:builder--translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .md\:hover\:builder--translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .md\:hover\:builder--translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .md\:hover\:builder--translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .md\:hover\:builder--translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .md\:hover\:builder--translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .md\:hover\:builder--translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .md\:hover\:builder--translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .md\:hover\:builder--translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .md\:hover\:builder--translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .md\:hover\:builder--translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .md\:hover\:builder--translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .md\:hover\:builder--translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .md\:hover\:builder--translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .md\:hover\:builder--translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .md\:hover\:builder--translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .md\:hover\:builder--translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .md\:hover\:builder--translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .md\:hover\:builder--translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .md\:hover\:builder--translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .md\:hover\:builder--translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .md\:hover\:builder--translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .md\:hover\:builder--translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .md\:hover\:builder--translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .md\:hover\:builder--translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .md\:hover\:builder--translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .md\:hover\:builder--translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .md\:hover\:builder--translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .md\:hover\:builder--translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .md\:hover\:builder--translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .md\:hover\:builder--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .md\:hover\:builder--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .md\:hover\:builder--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .md\:hover\:builder--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .md\:hover\:builder-translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .md\:hover\:builder-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .md\:hover\:builder-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .md\:hover\:builder-translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .md\:hover\:builder-translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .md\:hover\:builder-translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .md\:hover\:builder-translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .md\:hover\:builder--translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .md\:hover\:builder--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .md\:hover\:builder--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .md\:hover\:builder--translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .md\:hover\:builder--translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .md\:hover\:builder--translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .md\:hover\:builder--translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .md\:hover\:builder-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .md\:hover\:builder-translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .md\:hover\:builder-translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .md\:hover\:builder-translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .md\:hover\:builder-translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .md\:hover\:builder-translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .md\:hover\:builder-translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .md\:hover\:builder-translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .md\:hover\:builder-translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .md\:hover\:builder-translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .md\:hover\:builder-translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .md\:hover\:builder-translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .md\:hover\:builder-translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .md\:hover\:builder-translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .md\:hover\:builder-translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .md\:hover\:builder-translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .md\:hover\:builder-translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .md\:hover\:builder-translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .md\:hover\:builder-translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .md\:hover\:builder-translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .md\:hover\:builder-translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .md\:hover\:builder-translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .md\:hover\:builder-translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .md\:hover\:builder-translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .md\:hover\:builder-translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .md\:hover\:builder-translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .md\:hover\:builder-translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .md\:hover\:builder-translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .md\:hover\:builder-translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .md\:hover\:builder-translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .md\:hover\:builder-translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .md\:hover\:builder-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .md\:hover\:builder-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .md\:hover\:builder-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .md\:hover\:builder-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .md\:hover\:builder--translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .md\:hover\:builder--translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .md\:hover\:builder--translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .md\:hover\:builder--translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .md\:hover\:builder--translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .md\:hover\:builder--translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .md\:hover\:builder--translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .md\:hover\:builder--translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .md\:hover\:builder--translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .md\:hover\:builder--translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .md\:hover\:builder--translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .md\:hover\:builder--translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .md\:hover\:builder--translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .md\:hover\:builder--translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .md\:hover\:builder--translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .md\:hover\:builder--translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .md\:hover\:builder--translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .md\:hover\:builder--translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .md\:hover\:builder--translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .md\:hover\:builder--translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .md\:hover\:builder--translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .md\:hover\:builder--translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .md\:hover\:builder--translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .md\:hover\:builder--translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .md\:hover\:builder--translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .md\:hover\:builder--translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .md\:hover\:builder--translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .md\:hover\:builder--translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .md\:hover\:builder--translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .md\:hover\:builder--translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .md\:hover\:builder--translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .md\:hover\:builder--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .md\:hover\:builder--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .md\:hover\:builder--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .md\:hover\:builder--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .md\:hover\:builder-translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .md\:hover\:builder-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .md\:hover\:builder-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .md\:hover\:builder-translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .md\:hover\:builder-translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .md\:hover\:builder-translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .md\:hover\:builder-translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .md\:hover\:builder--translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .md\:hover\:builder--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .md\:hover\:builder--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .md\:hover\:builder--translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .md\:hover\:builder--translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .md\:hover\:builder--translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .md\:hover\:builder--translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .md\:focus\:builder-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .md\:focus\:builder-translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .md\:focus\:builder-translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .md\:focus\:builder-translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .md\:focus\:builder-translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .md\:focus\:builder-translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .md\:focus\:builder-translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .md\:focus\:builder-translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .md\:focus\:builder-translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .md\:focus\:builder-translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .md\:focus\:builder-translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .md\:focus\:builder-translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .md\:focus\:builder-translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .md\:focus\:builder-translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .md\:focus\:builder-translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .md\:focus\:builder-translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .md\:focus\:builder-translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .md\:focus\:builder-translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .md\:focus\:builder-translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .md\:focus\:builder-translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .md\:focus\:builder-translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .md\:focus\:builder-translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .md\:focus\:builder-translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .md\:focus\:builder-translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .md\:focus\:builder-translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .md\:focus\:builder-translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .md\:focus\:builder-translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .md\:focus\:builder-translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .md\:focus\:builder-translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .md\:focus\:builder-translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .md\:focus\:builder-translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .md\:focus\:builder-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .md\:focus\:builder-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .md\:focus\:builder-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .md\:focus\:builder-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .md\:focus\:builder--translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .md\:focus\:builder--translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .md\:focus\:builder--translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .md\:focus\:builder--translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .md\:focus\:builder--translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .md\:focus\:builder--translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .md\:focus\:builder--translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .md\:focus\:builder--translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .md\:focus\:builder--translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .md\:focus\:builder--translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .md\:focus\:builder--translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .md\:focus\:builder--translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .md\:focus\:builder--translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .md\:focus\:builder--translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .md\:focus\:builder--translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .md\:focus\:builder--translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .md\:focus\:builder--translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .md\:focus\:builder--translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .md\:focus\:builder--translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .md\:focus\:builder--translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .md\:focus\:builder--translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .md\:focus\:builder--translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .md\:focus\:builder--translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .md\:focus\:builder--translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .md\:focus\:builder--translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .md\:focus\:builder--translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .md\:focus\:builder--translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .md\:focus\:builder--translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .md\:focus\:builder--translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .md\:focus\:builder--translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .md\:focus\:builder--translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .md\:focus\:builder--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .md\:focus\:builder--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .md\:focus\:builder--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .md\:focus\:builder--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .md\:focus\:builder-translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .md\:focus\:builder-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .md\:focus\:builder-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .md\:focus\:builder-translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .md\:focus\:builder-translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .md\:focus\:builder-translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .md\:focus\:builder-translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .md\:focus\:builder--translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .md\:focus\:builder--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .md\:focus\:builder--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .md\:focus\:builder--translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .md\:focus\:builder--translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .md\:focus\:builder--translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .md\:focus\:builder--translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .md\:focus\:builder-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .md\:focus\:builder-translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .md\:focus\:builder-translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .md\:focus\:builder-translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .md\:focus\:builder-translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .md\:focus\:builder-translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .md\:focus\:builder-translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .md\:focus\:builder-translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .md\:focus\:builder-translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .md\:focus\:builder-translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .md\:focus\:builder-translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .md\:focus\:builder-translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .md\:focus\:builder-translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .md\:focus\:builder-translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .md\:focus\:builder-translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .md\:focus\:builder-translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .md\:focus\:builder-translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .md\:focus\:builder-translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .md\:focus\:builder-translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .md\:focus\:builder-translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .md\:focus\:builder-translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .md\:focus\:builder-translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .md\:focus\:builder-translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .md\:focus\:builder-translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .md\:focus\:builder-translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .md\:focus\:builder-translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .md\:focus\:builder-translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .md\:focus\:builder-translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .md\:focus\:builder-translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .md\:focus\:builder-translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .md\:focus\:builder-translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .md\:focus\:builder-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .md\:focus\:builder-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .md\:focus\:builder-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .md\:focus\:builder-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .md\:focus\:builder--translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .md\:focus\:builder--translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .md\:focus\:builder--translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .md\:focus\:builder--translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .md\:focus\:builder--translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .md\:focus\:builder--translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .md\:focus\:builder--translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .md\:focus\:builder--translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .md\:focus\:builder--translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .md\:focus\:builder--translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .md\:focus\:builder--translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .md\:focus\:builder--translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .md\:focus\:builder--translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .md\:focus\:builder--translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .md\:focus\:builder--translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .md\:focus\:builder--translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .md\:focus\:builder--translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .md\:focus\:builder--translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .md\:focus\:builder--translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .md\:focus\:builder--translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .md\:focus\:builder--translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .md\:focus\:builder--translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .md\:focus\:builder--translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .md\:focus\:builder--translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .md\:focus\:builder--translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .md\:focus\:builder--translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .md\:focus\:builder--translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .md\:focus\:builder--translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .md\:focus\:builder--translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .md\:focus\:builder--translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .md\:focus\:builder--translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .md\:focus\:builder--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .md\:focus\:builder--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .md\:focus\:builder--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .md\:focus\:builder--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .md\:focus\:builder-translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .md\:focus\:builder-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .md\:focus\:builder-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .md\:focus\:builder-translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .md\:focus\:builder-translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .md\:focus\:builder-translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .md\:focus\:builder-translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .md\:focus\:builder--translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .md\:focus\:builder--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .md\:focus\:builder--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .md\:focus\:builder--translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .md\:focus\:builder--translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .md\:focus\:builder--translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .md\:focus\:builder--translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .md\:builder-rotate-0{
    --tw-rotate: 0deg !important;
  }

  .md\:builder-rotate-1{
    --tw-rotate: 1deg !important;
  }

  .md\:builder-rotate-2{
    --tw-rotate: 2deg !important;
  }

  .md\:builder-rotate-3{
    --tw-rotate: 3deg !important;
  }

  .md\:builder-rotate-6{
    --tw-rotate: 6deg !important;
  }

  .md\:builder-rotate-12{
    --tw-rotate: 12deg !important;
  }

  .md\:builder-rotate-45{
    --tw-rotate: 45deg !important;
  }

  .md\:builder-rotate-90{
    --tw-rotate: 90deg !important;
  }

  .md\:builder-rotate-180{
    --tw-rotate: 180deg !important;
  }

  .md\:builder--rotate-180{
    --tw-rotate: -180deg !important;
  }

  .md\:builder--rotate-90{
    --tw-rotate: -90deg !important;
  }

  .md\:builder--rotate-45{
    --tw-rotate: -45deg !important;
  }

  .md\:builder--rotate-12{
    --tw-rotate: -12deg !important;
  }

  .md\:builder--rotate-6{
    --tw-rotate: -6deg !important;
  }

  .md\:builder--rotate-3{
    --tw-rotate: -3deg !important;
  }

  .md\:builder--rotate-2{
    --tw-rotate: -2deg !important;
  }

  .md\:builder--rotate-1{
    --tw-rotate: -1deg !important;
  }

  .md\:hover\:builder-rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .md\:hover\:builder-rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .md\:hover\:builder-rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .md\:hover\:builder-rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .md\:hover\:builder-rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .md\:hover\:builder-rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .md\:hover\:builder-rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .md\:hover\:builder-rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .md\:hover\:builder-rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .md\:hover\:builder--rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .md\:hover\:builder--rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .md\:hover\:builder--rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .md\:hover\:builder--rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .md\:hover\:builder--rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .md\:hover\:builder--rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .md\:hover\:builder--rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .md\:hover\:builder--rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .md\:focus\:builder-rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .md\:focus\:builder-rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .md\:focus\:builder-rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .md\:focus\:builder-rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .md\:focus\:builder-rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .md\:focus\:builder-rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .md\:focus\:builder-rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .md\:focus\:builder-rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .md\:focus\:builder-rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .md\:focus\:builder--rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .md\:focus\:builder--rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .md\:focus\:builder--rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .md\:focus\:builder--rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .md\:focus\:builder--rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .md\:focus\:builder--rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .md\:focus\:builder--rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .md\:focus\:builder--rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .md\:builder-skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .md\:builder-skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .md\:builder-skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .md\:builder-skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .md\:builder-skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .md\:builder-skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .md\:builder--skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .md\:builder--skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .md\:builder--skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .md\:builder--skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .md\:builder--skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .md\:builder-skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .md\:builder-skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .md\:builder-skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .md\:builder-skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .md\:builder-skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .md\:builder-skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .md\:builder--skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .md\:builder--skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .md\:builder--skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .md\:builder--skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .md\:builder--skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .md\:hover\:builder-skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .md\:hover\:builder-skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .md\:hover\:builder-skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .md\:hover\:builder-skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .md\:hover\:builder-skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .md\:hover\:builder-skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .md\:hover\:builder--skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .md\:hover\:builder--skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .md\:hover\:builder--skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .md\:hover\:builder--skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .md\:hover\:builder--skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .md\:hover\:builder-skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .md\:hover\:builder-skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .md\:hover\:builder-skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .md\:hover\:builder-skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .md\:hover\:builder-skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .md\:hover\:builder-skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .md\:hover\:builder--skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .md\:hover\:builder--skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .md\:hover\:builder--skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .md\:hover\:builder--skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .md\:hover\:builder--skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .md\:focus\:builder-skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .md\:focus\:builder-skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .md\:focus\:builder-skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .md\:focus\:builder-skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .md\:focus\:builder-skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .md\:focus\:builder-skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .md\:focus\:builder--skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .md\:focus\:builder--skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .md\:focus\:builder--skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .md\:focus\:builder--skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .md\:focus\:builder--skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .md\:focus\:builder-skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .md\:focus\:builder-skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .md\:focus\:builder-skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .md\:focus\:builder-skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .md\:focus\:builder-skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .md\:focus\:builder-skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .md\:focus\:builder--skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .md\:focus\:builder--skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .md\:focus\:builder--skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .md\:focus\:builder--skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .md\:focus\:builder--skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .md\:builder-scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .md\:builder-scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .md\:builder-scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .md\:builder-scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .md\:builder-scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .md\:builder-scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .md\:builder-scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .md\:builder-scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .md\:builder-scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .md\:builder-scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .md\:hover\:builder-scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .md\:hover\:builder-scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .md\:hover\:builder-scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .md\:hover\:builder-scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .md\:hover\:builder-scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .md\:hover\:builder-scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .md\:hover\:builder-scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .md\:hover\:builder-scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .md\:hover\:builder-scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .md\:hover\:builder-scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .md\:focus\:builder-scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .md\:focus\:builder-scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .md\:focus\:builder-scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .md\:focus\:builder-scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .md\:focus\:builder-scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .md\:focus\:builder-scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .md\:focus\:builder-scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .md\:focus\:builder-scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .md\:focus\:builder-scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .md\:focus\:builder-scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .md\:builder-scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .md\:builder-scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .md\:builder-scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .md\:builder-scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .md\:builder-scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .md\:builder-scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .md\:builder-scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .md\:builder-scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .md\:builder-scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .md\:builder-scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .md\:builder-scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .md\:builder-scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .md\:builder-scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .md\:builder-scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .md\:builder-scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .md\:builder-scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .md\:builder-scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .md\:builder-scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .md\:builder-scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .md\:builder-scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .md\:hover\:builder-scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .md\:hover\:builder-scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .md\:hover\:builder-scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .md\:hover\:builder-scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .md\:hover\:builder-scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .md\:hover\:builder-scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .md\:hover\:builder-scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .md\:hover\:builder-scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .md\:hover\:builder-scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .md\:hover\:builder-scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .md\:hover\:builder-scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .md\:hover\:builder-scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .md\:hover\:builder-scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .md\:hover\:builder-scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .md\:hover\:builder-scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .md\:hover\:builder-scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .md\:hover\:builder-scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .md\:hover\:builder-scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .md\:hover\:builder-scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .md\:hover\:builder-scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .md\:focus\:builder-scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .md\:focus\:builder-scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .md\:focus\:builder-scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .md\:focus\:builder-scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .md\:focus\:builder-scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .md\:focus\:builder-scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .md\:focus\:builder-scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .md\:focus\:builder-scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .md\:focus\:builder-scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .md\:focus\:builder-scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .md\:focus\:builder-scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .md\:focus\:builder-scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .md\:focus\:builder-scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .md\:focus\:builder-scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .md\:focus\:builder-scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .md\:focus\:builder-scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .md\:focus\:builder-scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .md\:focus\:builder-scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .md\:focus\:builder-scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .md\:focus\:builder-scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .md\:builder-animate-none{
    animation: none !important;
  }

  .md\:builder-animate-spin{
    animation: builder-spin 1s linear infinite !important;
  }

  .md\:builder-animate-ping{
    animation: builder-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .md\:builder-animate-pulse{
    animation: builder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .md\:builder-animate-bounce{
    animation: builder-bounce 1s infinite !important;
  }

  .md\:builder-cursor-auto{
    cursor: auto !important;
  }

  .md\:builder-cursor-default{
    cursor: default !important;
  }

  .md\:builder-cursor-pointer{
    cursor: pointer !important;
  }

  .md\:builder-cursor-wait{
    cursor: wait !important;
  }

  .md\:builder-cursor-text{
    cursor: text !important;
  }

  .md\:builder-cursor-move{
    cursor: move !important;
  }

  .md\:builder-cursor-help{
    cursor: help !important;
  }

  .md\:builder-cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .md\:builder-select-none{
    -webkit-user-select: none !important;
            user-select: none !important;
  }

  .md\:builder-select-text{
    -webkit-user-select: text !important;
            user-select: text !important;
  }

  .md\:builder-select-all{
    -webkit-user-select: all !important;
            user-select: all !important;
  }

  .md\:builder-select-auto{
    -webkit-user-select: auto !important;
            user-select: auto !important;
  }

  .md\:builder-resize-none{
    resize: none !important;
  }

  .md\:builder-resize-y{
    resize: vertical !important;
  }

  .md\:builder-resize-x{
    resize: horizontal !important;
  }

  .md\:builder-resize{
    resize: both !important;
  }

  .md\:builder-list-inside{
    list-style-position: inside !important;
  }

  .md\:builder-list-outside{
    list-style-position: outside !important;
  }

  .md\:builder-list-none{
    list-style-type: none !important;
  }

  .md\:builder-list-disc{
    list-style-type: disc !important;
  }

  .md\:builder-list-decimal{
    list-style-type: decimal !important;
  }

  .md\:builder-appearance-none{
    appearance: none !important;
  }

  .md\:builder-auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .md\:builder-auto-cols-min{
    grid-auto-columns: min-content !important;
  }

  .md\:builder-auto-cols-max{
    grid-auto-columns: max-content !important;
  }

  .md\:builder-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .md\:builder-grid-flow-row{
    grid-auto-flow: row !important;
  }

  .md\:builder-grid-flow-col{
    grid-auto-flow: column !important;
  }

  .md\:builder-grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .md\:builder-grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .md\:builder-auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .md\:builder-auto-rows-min{
    grid-auto-rows: min-content !important;
  }

  .md\:builder-auto-rows-max{
    grid-auto-rows: max-content !important;
  }

  .md\:builder-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .md\:builder-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-cols-none{
    grid-template-columns: none !important;
  }

  .md\:builder-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:builder-grid-rows-none{
    grid-template-rows: none !important;
  }

  .md\:builder-flex-row{
    flex-direction: row !important;
  }

  .md\:builder-flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .md\:builder-flex-col{
    flex-direction: column !important;
  }

  .md\:builder-flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .md\:builder-flex-wrap{
    flex-wrap: wrap !important;
  }

  .md\:builder-flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .md\:builder-flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .md\:builder-place-content-center{
    place-content: center !important;
  }

  .md\:builder-place-content-start{
    place-content: start !important;
  }

  .md\:builder-place-content-end{
    place-content: end !important;
  }

  .md\:builder-place-content-between{
    place-content: space-between !important;
  }

  .md\:builder-place-content-around{
    place-content: space-around !important;
  }

  .md\:builder-place-content-evenly{
    place-content: space-evenly !important;
  }

  .md\:builder-place-content-stretch{
    place-content: stretch !important;
  }

  .md\:builder-place-items-start{
    place-items: start !important;
  }

  .md\:builder-place-items-end{
    place-items: end !important;
  }

  .md\:builder-place-items-center{
    place-items: center !important;
  }

  .md\:builder-place-items-stretch{
    place-items: stretch !important;
  }

  .md\:builder-content-center{
    align-content: center !important;
  }

  .md\:builder-content-start{
    align-content: flex-start !important;
  }

  .md\:builder-content-end{
    align-content: flex-end !important;
  }

  .md\:builder-content-between{
    align-content: space-between !important;
  }

  .md\:builder-content-around{
    align-content: space-around !important;
  }

  .md\:builder-content-evenly{
    align-content: space-evenly !important;
  }

  .md\:builder-items-start{
    align-items: flex-start !important;
  }

  .md\:builder-items-end{
    align-items: flex-end !important;
  }

  .md\:builder-items-center{
    align-items: center !important;
  }

  .md\:builder-items-baseline{
    align-items: baseline !important;
  }

  .md\:builder-items-stretch{
    align-items: stretch !important;
  }

  .md\:builder-justify-start{
    justify-content: flex-start !important;
  }

  .md\:builder-justify-end{
    justify-content: flex-end !important;
  }

  .md\:builder-justify-center{
    justify-content: center !important;
  }

  .md\:builder-justify-between{
    justify-content: space-between !important;
  }

  .md\:builder-justify-around{
    justify-content: space-around !important;
  }

  .md\:builder-justify-evenly{
    justify-content: space-evenly !important;
  }

  .md\:builder-justify-items-start{
    justify-items: start !important;
  }

  .md\:builder-justify-items-end{
    justify-items: end !important;
  }

  .md\:builder-justify-items-center{
    justify-items: center !important;
  }

  .md\:builder-justify-items-stretch{
    justify-items: stretch !important;
  }

  .md\:builder-gap-0{
    gap: 0px !important;
  }

  .md\:builder-gap-1{
    gap: 0.25rem !important;
  }

  .md\:builder-gap-2{
    gap: 0.5rem !important;
  }

  .md\:builder-gap-3{
    gap: 0.75rem !important;
  }

  .md\:builder-gap-4{
    gap: 1rem !important;
  }

  .md\:builder-gap-5{
    gap: 1.25rem !important;
  }

  .md\:builder-gap-6{
    gap: 1.5rem !important;
  }

  .md\:builder-gap-7{
    gap: 1.75rem !important;
  }

  .md\:builder-gap-8{
    gap: 2rem !important;
  }

  .md\:builder-gap-9{
    gap: 2.25rem !important;
  }

  .md\:builder-gap-10{
    gap: 2.5rem !important;
  }

  .md\:builder-gap-11{
    gap: 2.75rem !important;
  }

  .md\:builder-gap-12{
    gap: 3rem !important;
  }

  .md\:builder-gap-14{
    gap: 3.5rem !important;
  }

  .md\:builder-gap-16{
    gap: 4rem !important;
  }

  .md\:builder-gap-20{
    gap: 5rem !important;
  }

  .md\:builder-gap-24{
    gap: 6rem !important;
  }

  .md\:builder-gap-28{
    gap: 7rem !important;
  }

  .md\:builder-gap-32{
    gap: 8rem !important;
  }

  .md\:builder-gap-36{
    gap: 9rem !important;
  }

  .md\:builder-gap-40{
    gap: 10rem !important;
  }

  .md\:builder-gap-44{
    gap: 11rem !important;
  }

  .md\:builder-gap-48{
    gap: 12rem !important;
  }

  .md\:builder-gap-52{
    gap: 13rem !important;
  }

  .md\:builder-gap-56{
    gap: 14rem !important;
  }

  .md\:builder-gap-60{
    gap: 15rem !important;
  }

  .md\:builder-gap-64{
    gap: 16rem !important;
  }

  .md\:builder-gap-72{
    gap: 18rem !important;
  }

  .md\:builder-gap-80{
    gap: 20rem !important;
  }

  .md\:builder-gap-96{
    gap: 24rem !important;
  }

  .md\:builder-gap-px{
    gap: 1px !important;
  }

  .md\:builder-gap-0\.5{
    gap: 0.125rem !important;
  }

  .md\:builder-gap-1\.5{
    gap: 0.375rem !important;
  }

  .md\:builder-gap-2\.5{
    gap: 0.625rem !important;
  }

  .md\:builder-gap-3\.5{
    gap: 0.875rem !important;
  }

  .md\:builder-gap-x-0{
    column-gap: 0px !important;
  }

  .md\:builder-gap-x-1{
    column-gap: 0.25rem !important;
  }

  .md\:builder-gap-x-2{
    column-gap: 0.5rem !important;
  }

  .md\:builder-gap-x-3{
    column-gap: 0.75rem !important;
  }

  .md\:builder-gap-x-4{
    column-gap: 1rem !important;
  }

  .md\:builder-gap-x-5{
    column-gap: 1.25rem !important;
  }

  .md\:builder-gap-x-6{
    column-gap: 1.5rem !important;
  }

  .md\:builder-gap-x-7{
    column-gap: 1.75rem !important;
  }

  .md\:builder-gap-x-8{
    column-gap: 2rem !important;
  }

  .md\:builder-gap-x-9{
    column-gap: 2.25rem !important;
  }

  .md\:builder-gap-x-10{
    column-gap: 2.5rem !important;
  }

  .md\:builder-gap-x-11{
    column-gap: 2.75rem !important;
  }

  .md\:builder-gap-x-12{
    column-gap: 3rem !important;
  }

  .md\:builder-gap-x-14{
    column-gap: 3.5rem !important;
  }

  .md\:builder-gap-x-16{
    column-gap: 4rem !important;
  }

  .md\:builder-gap-x-20{
    column-gap: 5rem !important;
  }

  .md\:builder-gap-x-24{
    column-gap: 6rem !important;
  }

  .md\:builder-gap-x-28{
    column-gap: 7rem !important;
  }

  .md\:builder-gap-x-32{
    column-gap: 8rem !important;
  }

  .md\:builder-gap-x-36{
    column-gap: 9rem !important;
  }

  .md\:builder-gap-x-40{
    column-gap: 10rem !important;
  }

  .md\:builder-gap-x-44{
    column-gap: 11rem !important;
  }

  .md\:builder-gap-x-48{
    column-gap: 12rem !important;
  }

  .md\:builder-gap-x-52{
    column-gap: 13rem !important;
  }

  .md\:builder-gap-x-56{
    column-gap: 14rem !important;
  }

  .md\:builder-gap-x-60{
    column-gap: 15rem !important;
  }

  .md\:builder-gap-x-64{
    column-gap: 16rem !important;
  }

  .md\:builder-gap-x-72{
    column-gap: 18rem !important;
  }

  .md\:builder-gap-x-80{
    column-gap: 20rem !important;
  }

  .md\:builder-gap-x-96{
    column-gap: 24rem !important;
  }

  .md\:builder-gap-x-px{
    column-gap: 1px !important;
  }

  .md\:builder-gap-x-0\.5{
    column-gap: 0.125rem !important;
  }

  .md\:builder-gap-x-1\.5{
    column-gap: 0.375rem !important;
  }

  .md\:builder-gap-x-2\.5{
    column-gap: 0.625rem !important;
  }

  .md\:builder-gap-x-3\.5{
    column-gap: 0.875rem !important;
  }

  .md\:builder-gap-y-0{
    row-gap: 0px !important;
  }

  .md\:builder-gap-y-1{
    row-gap: 0.25rem !important;
  }

  .md\:builder-gap-y-2{
    row-gap: 0.5rem !important;
  }

  .md\:builder-gap-y-3{
    row-gap: 0.75rem !important;
  }

  .md\:builder-gap-y-4{
    row-gap: 1rem !important;
  }

  .md\:builder-gap-y-5{
    row-gap: 1.25rem !important;
  }

  .md\:builder-gap-y-6{
    row-gap: 1.5rem !important;
  }

  .md\:builder-gap-y-7{
    row-gap: 1.75rem !important;
  }

  .md\:builder-gap-y-8{
    row-gap: 2rem !important;
  }

  .md\:builder-gap-y-9{
    row-gap: 2.25rem !important;
  }

  .md\:builder-gap-y-10{
    row-gap: 2.5rem !important;
  }

  .md\:builder-gap-y-11{
    row-gap: 2.75rem !important;
  }

  .md\:builder-gap-y-12{
    row-gap: 3rem !important;
  }

  .md\:builder-gap-y-14{
    row-gap: 3.5rem !important;
  }

  .md\:builder-gap-y-16{
    row-gap: 4rem !important;
  }

  .md\:builder-gap-y-20{
    row-gap: 5rem !important;
  }

  .md\:builder-gap-y-24{
    row-gap: 6rem !important;
  }

  .md\:builder-gap-y-28{
    row-gap: 7rem !important;
  }

  .md\:builder-gap-y-32{
    row-gap: 8rem !important;
  }

  .md\:builder-gap-y-36{
    row-gap: 9rem !important;
  }

  .md\:builder-gap-y-40{
    row-gap: 10rem !important;
  }

  .md\:builder-gap-y-44{
    row-gap: 11rem !important;
  }

  .md\:builder-gap-y-48{
    row-gap: 12rem !important;
  }

  .md\:builder-gap-y-52{
    row-gap: 13rem !important;
  }

  .md\:builder-gap-y-56{
    row-gap: 14rem !important;
  }

  .md\:builder-gap-y-60{
    row-gap: 15rem !important;
  }

  .md\:builder-gap-y-64{
    row-gap: 16rem !important;
  }

  .md\:builder-gap-y-72{
    row-gap: 18rem !important;
  }

  .md\:builder-gap-y-80{
    row-gap: 20rem !important;
  }

  .md\:builder-gap-y-96{
    row-gap: 24rem !important;
  }

  .md\:builder-gap-y-px{
    row-gap: 1px !important;
  }

  .md\:builder-gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .md\:builder-gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .md\:builder-gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .md\:builder-gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .md\:builder-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:builder-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:builder-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .md\:builder-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .md\:builder-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:builder-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:builder-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:builder-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:builder-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:builder-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:builder-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:builder-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:builder-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:builder-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:builder-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .md\:builder-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .md\:builder-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .md\:builder-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .md\:builder-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .md\:builder-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .md\:builder-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .md\:builder-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .md\:builder-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .md\:builder-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-primary > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-secondary-dark > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-secondary-darker > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity)) !important;
  }

  .md\:builder-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .md\:builder-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .md\:builder-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .md\:builder-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .md\:builder-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .md\:builder-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .md\:builder-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .md\:builder-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .md\:builder-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .md\:builder-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .md\:builder-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .md\:builder-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .md\:builder-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .md\:builder-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .md\:builder-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .md\:builder-place-self-auto{
    place-self: auto !important;
  }

  .md\:builder-place-self-start{
    place-self: start !important;
  }

  .md\:builder-place-self-end{
    place-self: end !important;
  }

  .md\:builder-place-self-center{
    place-self: center !important;
  }

  .md\:builder-place-self-stretch{
    place-self: stretch !important;
  }

  .md\:builder-self-auto{
    align-self: auto !important;
  }

  .md\:builder-self-start{
    align-self: flex-start !important;
  }

  .md\:builder-self-end{
    align-self: flex-end !important;
  }

  .md\:builder-self-center{
    align-self: center !important;
  }

  .md\:builder-self-stretch{
    align-self: stretch !important;
  }

  .md\:builder-self-baseline{
    align-self: baseline !important;
  }

  .md\:builder-justify-self-auto{
    justify-self: auto !important;
  }

  .md\:builder-justify-self-start{
    justify-self: start !important;
  }

  .md\:builder-justify-self-end{
    justify-self: end !important;
  }

  .md\:builder-justify-self-center{
    justify-self: center !important;
  }

  .md\:builder-justify-self-stretch{
    justify-self: stretch !important;
  }

  .md\:builder-overflow-auto{
    overflow: auto !important;
  }

  .md\:builder-overflow-hidden{
    overflow: hidden !important;
  }

  .md\:builder-overflow-visible{
    overflow: visible !important;
  }

  .md\:builder-overflow-scroll{
    overflow: scroll !important;
  }

  .md\:builder-overflow-x-auto{
    overflow-x: auto !important;
  }

  .md\:builder-overflow-y-auto{
    overflow-y: auto !important;
  }

  .md\:builder-overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .md\:builder-overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .md\:builder-overflow-x-visible{
    overflow-x: visible !important;
  }

  .md\:builder-overflow-y-visible{
    overflow-y: visible !important;
  }

  .md\:builder-overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .md\:builder-overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .md\:builder-overscroll-auto{
    overscroll-behavior: auto !important;
  }

  .md\:builder-overscroll-contain{
    overscroll-behavior: contain !important;
  }

  .md\:builder-overscroll-none{
    overscroll-behavior: none !important;
  }

  .md\:builder-overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .md\:builder-overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .md\:builder-overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .md\:builder-overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .md\:builder-overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .md\:builder-overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .md\:builder-truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .md\:builder-overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .md\:builder-overflow-clip{
    text-overflow: clip !important;
  }

  .md\:builder-whitespace-normal{
    white-space: normal !important;
  }

  .md\:builder-whitespace-nowrap{
    white-space: nowrap !important;
  }

  .md\:builder-whitespace-pre{
    white-space: pre !important;
  }

  .md\:builder-whitespace-pre-line{
    white-space: pre-line !important;
  }

  .md\:builder-whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .md\:builder-break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .md\:builder-break-words{
    overflow-wrap: break-word !important;
  }

  .md\:builder-break-all{
    word-break: break-all !important;
  }

  .md\:builder-rounded-none{
    border-radius: 0px !important;
  }

  .md\:builder-rounded-sm{
    border-radius: 0.125rem !important;
  }

  .md\:builder-rounded{
    border-radius: 0.25rem !important;
  }

  .md\:builder-rounded-md{
    border-radius: 0.375rem !important;
  }

  .md\:builder-rounded-lg{
    border-radius: 0.5rem !important;
  }

  .md\:builder-rounded-xl{
    border-radius: 0.75rem !important;
  }

  .md\:builder-rounded-2xl{
    border-radius: 1rem !important;
  }

  .md\:builder-rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .md\:builder-rounded-full{
    border-radius: 9999px !important;
  }

  .md\:builder-rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .md\:builder-rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .md\:builder-rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .md\:builder-rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .md\:builder-rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .md\:builder-rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .md\:builder-rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .md\:builder-rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .md\:builder-rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .md\:builder-rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .md\:builder-rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:builder-rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:builder-rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:builder-rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:builder-rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .md\:builder-rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .md\:builder-rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:builder-rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .md\:builder-rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .md\:builder-rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:builder-rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:builder-rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:builder-rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:builder-rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:builder-rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:builder-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:builder-rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:builder-rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .md\:builder-rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:builder-rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:builder-rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:builder-rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:builder-rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:builder-rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:builder-rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:builder-rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:builder-rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .md\:builder-rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .md\:builder-rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .md\:builder-rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .md\:builder-rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .md\:builder-rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .md\:builder-rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .md\:builder-rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .md\:builder-rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .md\:builder-rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .md\:builder-rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .md\:builder-rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .md\:builder-rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .md\:builder-rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .md\:builder-rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .md\:builder-rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .md\:builder-rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .md\:builder-rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .md\:builder-rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .md\:builder-rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:builder-rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:builder-rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:builder-rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:builder-rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .md\:builder-rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .md\:builder-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:builder-rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .md\:builder-rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .md\:builder-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:builder-rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:builder-rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:builder-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:builder-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:builder-rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .md\:builder-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:builder-rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .md\:builder-border-0{
    border-width: 0px !important;
  }

  .md\:builder-border-2{
    border-width: 2px !important;
  }

  .md\:builder-border-4{
    border-width: 4px !important;
  }

  .md\:builder-border-8{
    border-width: 8px !important;
  }

  .md\:builder-border{
    border-width: 1px !important;
  }

  .md\:builder-border-t-0{
    border-top-width: 0px !important;
  }

  .md\:builder-border-t-2{
    border-top-width: 2px !important;
  }

  .md\:builder-border-t-4{
    border-top-width: 4px !important;
  }

  .md\:builder-border-t-8{
    border-top-width: 8px !important;
  }

  .md\:builder-border-t{
    border-top-width: 1px !important;
  }

  .md\:builder-border-r-0{
    border-right-width: 0px !important;
  }

  .md\:builder-border-r-2{
    border-right-width: 2px !important;
  }

  .md\:builder-border-r-4{
    border-right-width: 4px !important;
  }

  .md\:builder-border-r-8{
    border-right-width: 8px !important;
  }

  .md\:builder-border-r{
    border-right-width: 1px !important;
  }

  .md\:builder-border-b-0{
    border-bottom-width: 0px !important;
  }

  .md\:builder-border-b-2{
    border-bottom-width: 2px !important;
  }

  .md\:builder-border-b-4{
    border-bottom-width: 4px !important;
  }

  .md\:builder-border-b-8{
    border-bottom-width: 8px !important;
  }

  .md\:builder-border-b{
    border-bottom-width: 1px !important;
  }

  .md\:builder-border-l-0{
    border-left-width: 0px !important;
  }

  .md\:builder-border-l-2{
    border-left-width: 2px !important;
  }

  .md\:builder-border-l-4{
    border-left-width: 4px !important;
  }

  .md\:builder-border-l-8{
    border-left-width: 8px !important;
  }

  .md\:builder-border-l{
    border-left-width: 1px !important;
  }

  .md\:builder-border-solid{
    border-style: solid !important;
  }

  .md\:builder-border-dashed{
    border-style: dashed !important;
  }

  .md\:builder-border-dotted{
    border-style: dotted !important;
  }

  .md\:builder-border-double{
    border-style: double !important;
  }

  .md\:builder-border-none{
    border-style: none !important;
  }

  .md\:builder-border-transparent{
    border-color: transparent !important;
  }

  .md\:builder-border-current{
    border-color: currentColor !important;
  }

  .md\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-transparent{
    border-color: transparent !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-current{
    border-color: currentColor !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-transparent:focus-within{
    border-color: transparent !important;
  }

  .md\:focus-within\:builder-border-current:focus-within{
    border-color: currentColor !important;
  }

  .md\:focus-within\:builder-border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-primary:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-secondary-dark:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:builder-border-secondary-darker:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-transparent:hover{
    border-color: transparent !important;
  }

  .md\:hover\:builder-border-current:hover{
    border-color: currentColor !important;
  }

  .md\:hover\:builder-border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-primary:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-secondary-dark:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:builder-border-secondary-darker:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-transparent:focus{
    border-color: transparent !important;
  }

  .md\:focus\:builder-border-current:focus{
    border-color: currentColor !important;
  }

  .md\:focus\:builder-border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-primary:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-secondary-dark:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:builder-border-secondary-darker:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .md\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .md\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .md\:focus-within\:builder-border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .md\:focus-within\:builder-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:focus-within\:builder-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:focus-within\:builder-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:focus-within\:builder-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:focus-within\:builder-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:focus-within\:builder-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:focus-within\:builder-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:focus-within\:builder-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:focus-within\:builder-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:focus-within\:builder-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:focus-within\:builder-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:focus-within\:builder-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:focus-within\:builder-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:focus-within\:builder-border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .md\:hover\:builder-border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .md\:hover\:builder-border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:hover\:builder-border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:hover\:builder-border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:hover\:builder-border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:hover\:builder-border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:hover\:builder-border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:hover\:builder-border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:hover\:builder-border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:hover\:builder-border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:hover\:builder-border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:hover\:builder-border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:hover\:builder-border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:hover\:builder-border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:hover\:builder-border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .md\:focus\:builder-border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .md\:focus\:builder-border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:focus\:builder-border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:focus\:builder-border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:focus\:builder-border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:focus\:builder-border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:focus\:builder-border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:focus\:builder-border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:focus\:builder-border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:focus\:builder-border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:focus\:builder-border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:focus\:builder-border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:focus\:builder-border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:focus\:builder-border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:focus\:builder-border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .md\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .md\:builder-bg-current{
    background-color: currentColor !important;
  }

  .md\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-current{
    background-color: currentColor !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .md\:focus-within\:builder-bg-current:focus-within{
    background-color: currentColor !important;
  }

  .md\:focus-within\:builder-bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-primary:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-secondary-dark:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:builder-bg-secondary-darker:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-transparent:hover{
    background-color: transparent !important;
  }

  .md\:hover\:builder-bg-current:hover{
    background-color: currentColor !important;
  }

  .md\:hover\:builder-bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-primary:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-secondary-dark:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:builder-bg-secondary-darker:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-transparent:focus{
    background-color: transparent !important;
  }

  .md\:focus\:builder-bg-current:focus{
    background-color: currentColor !important;
  }

  .md\:focus\:builder-bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-primary:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-secondary-dark:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:builder-bg-secondary-darker:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .md\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .md\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .md\:focus-within\:builder-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .md\:focus-within\:builder-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:focus-within\:builder-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:focus-within\:builder-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:focus-within\:builder-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:focus-within\:builder-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:focus-within\:builder-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:focus-within\:builder-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:focus-within\:builder-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:focus-within\:builder-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:focus-within\:builder-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:focus-within\:builder-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:focus-within\:builder-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:focus-within\:builder-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:focus-within\:builder-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .md\:hover\:builder-bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .md\:hover\:builder-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:hover\:builder-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:hover\:builder-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:hover\:builder-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:hover\:builder-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:hover\:builder-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:hover\:builder-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:hover\:builder-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:hover\:builder-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:hover\:builder-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:hover\:builder-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:hover\:builder-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:hover\:builder-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:hover\:builder-bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .md\:focus\:builder-bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .md\:focus\:builder-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:focus\:builder-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:focus\:builder-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:focus\:builder-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:focus\:builder-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:focus\:builder-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:focus\:builder-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:focus\:builder-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:focus\:builder-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:focus\:builder-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:focus\:builder-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:focus\:builder-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:focus\:builder-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:focus\:builder-bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .md\:builder-bg-none{
    background-image: none !important;
  }

  .md\:builder-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .md\:builder-from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:builder-from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:builder-from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:builder-from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:builder-from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:builder-from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:builder-from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:builder-from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:builder-from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:builder-from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:builder-from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:builder-from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:builder-from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:builder-from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:builder-from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:builder-from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:builder-from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:builder-from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:builder-from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:builder-from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:builder-from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:builder-from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:builder-from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:builder-from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:builder-from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:builder-from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:builder-from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:builder-from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:builder-from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:builder-from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:builder-from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:builder-from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:builder-from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:builder-from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:builder-from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:builder-from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:builder-from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:builder-from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:builder-from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:builder-from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:builder-from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:builder-from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:builder-from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:builder-from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:builder-from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:builder-from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:builder-from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:builder-from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:builder-from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:builder-from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:builder-from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:builder-from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:builder-from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:builder-from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:builder-from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:builder-from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:builder-from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:builder-from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:builder-from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:builder-from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:builder-from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:builder-from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:builder-from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:builder-from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:builder-from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:builder-from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:builder-from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:builder-from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:builder-from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:builder-from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:builder-from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:builder-from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:builder-from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:builder-from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:builder-from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:builder-from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:builder-from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:builder-from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:builder-from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:builder-from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:builder-from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:builder-from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:builder-from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:builder-from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:builder-from-primary{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .md\:builder-from-secondary-dark{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .md\:builder-from-secondary-darker{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .md\:hover\:builder-from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:builder-from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:builder-from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:builder-from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:builder-from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:hover\:builder-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:hover\:builder-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:hover\:builder-from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:hover\:builder-from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:hover\:builder-from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:hover\:builder-from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:hover\:builder-from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:hover\:builder-from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:hover\:builder-from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:hover\:builder-from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:hover\:builder-from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:hover\:builder-from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:hover\:builder-from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:hover\:builder-from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:hover\:builder-from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:hover\:builder-from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:hover\:builder-from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:hover\:builder-from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:hover\:builder-from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:hover\:builder-from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:hover\:builder-from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:hover\:builder-from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:hover\:builder-from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:hover\:builder-from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:hover\:builder-from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:hover\:builder-from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:hover\:builder-from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:hover\:builder-from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:hover\:builder-from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:hover\:builder-from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:hover\:builder-from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:hover\:builder-from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:hover\:builder-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:hover\:builder-from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:hover\:builder-from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:hover\:builder-from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:hover\:builder-from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:hover\:builder-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:hover\:builder-from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:hover\:builder-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:hover\:builder-from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:hover\:builder-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:hover\:builder-from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:hover\:builder-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:hover\:builder-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:hover\:builder-from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:hover\:builder-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:hover\:builder-from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:hover\:builder-from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:hover\:builder-from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:hover\:builder-from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:hover\:builder-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:hover\:builder-from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:hover\:builder-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:hover\:builder-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:hover\:builder-from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:hover\:builder-from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:hover\:builder-from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:hover\:builder-from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:hover\:builder-from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:hover\:builder-from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:hover\:builder-from-primary:hover{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .md\:hover\:builder-from-secondary-dark:hover{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .md\:hover\:builder-from-secondary-darker:hover{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .md\:focus\:builder-from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:builder-from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:builder-from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:builder-from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:builder-from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:focus\:builder-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:focus\:builder-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:focus\:builder-from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:focus\:builder-from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:focus\:builder-from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:focus\:builder-from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:focus\:builder-from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:focus\:builder-from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:focus\:builder-from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:focus\:builder-from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:focus\:builder-from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:focus\:builder-from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:focus\:builder-from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:focus\:builder-from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:focus\:builder-from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:focus\:builder-from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:focus\:builder-from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:focus\:builder-from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:focus\:builder-from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:focus\:builder-from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:focus\:builder-from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:focus\:builder-from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:focus\:builder-from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:focus\:builder-from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:focus\:builder-from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:focus\:builder-from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:focus\:builder-from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:focus\:builder-from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:focus\:builder-from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:focus\:builder-from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:focus\:builder-from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:focus\:builder-from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:focus\:builder-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:focus\:builder-from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:focus\:builder-from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:focus\:builder-from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:focus\:builder-from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:focus\:builder-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:focus\:builder-from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:focus\:builder-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:focus\:builder-from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:focus\:builder-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:focus\:builder-from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:focus\:builder-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:focus\:builder-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:focus\:builder-from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:focus\:builder-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:focus\:builder-from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:focus\:builder-from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:focus\:builder-from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:focus\:builder-from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:focus\:builder-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:focus\:builder-from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:focus\:builder-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:focus\:builder-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:focus\:builder-from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:focus\:builder-from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:focus\:builder-from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:focus\:builder-from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:focus\:builder-from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:focus\:builder-from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:focus\:builder-from-primary:focus{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .md\:focus\:builder-from-secondary-dark:focus{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .md\:focus\:builder-from-secondary-darker:focus{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .md\:builder-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:builder-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:builder-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:builder-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:builder-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:builder-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:builder-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:builder-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:builder-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:builder-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:builder-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:builder-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:builder-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:builder-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:builder-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:builder-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:builder-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:builder-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:builder-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:builder-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:builder-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:builder-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:builder-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:builder-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:builder-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:builder-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:builder-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:builder-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:builder-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:builder-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:builder-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:builder-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:builder-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:builder-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:builder-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:builder-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:builder-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:builder-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:builder-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:builder-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:builder-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:builder-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:builder-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:builder-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:builder-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:builder-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:builder-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:builder-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:builder-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:builder-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:builder-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:builder-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:builder-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:builder-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:builder-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:builder-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:builder-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:builder-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:builder-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:builder-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:builder-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:builder-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:builder-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:builder-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:builder-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:builder-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:builder-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:builder-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:builder-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:builder-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:builder-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:builder-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:builder-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:builder-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:builder-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:builder-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:builder-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:builder-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:builder-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:builder-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:builder-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:builder-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:builder-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:builder-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:builder-via-primary{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .md\:builder-via-secondary-dark{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .md\:builder-via-secondary-darker{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .md\:hover\:builder-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:builder-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:builder-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:builder-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:builder-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:hover\:builder-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:hover\:builder-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:hover\:builder-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:hover\:builder-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:hover\:builder-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:hover\:builder-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:hover\:builder-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:hover\:builder-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:hover\:builder-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:hover\:builder-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:hover\:builder-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:hover\:builder-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:hover\:builder-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:hover\:builder-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:hover\:builder-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:hover\:builder-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:hover\:builder-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:hover\:builder-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:hover\:builder-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:hover\:builder-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:hover\:builder-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:hover\:builder-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:hover\:builder-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:hover\:builder-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:hover\:builder-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:hover\:builder-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:hover\:builder-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:hover\:builder-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:hover\:builder-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:hover\:builder-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:hover\:builder-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:hover\:builder-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:hover\:builder-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:hover\:builder-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:hover\:builder-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:hover\:builder-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:hover\:builder-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:hover\:builder-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:hover\:builder-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:hover\:builder-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:hover\:builder-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:hover\:builder-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:hover\:builder-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:hover\:builder-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:hover\:builder-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:hover\:builder-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:hover\:builder-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:hover\:builder-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:hover\:builder-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:hover\:builder-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:hover\:builder-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:hover\:builder-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:hover\:builder-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:hover\:builder-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:hover\:builder-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:hover\:builder-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:hover\:builder-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:hover\:builder-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:hover\:builder-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:hover\:builder-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:hover\:builder-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:hover\:builder-via-primary:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .md\:hover\:builder-via-secondary-dark:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .md\:hover\:builder-via-secondary-darker:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .md\:focus\:builder-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:builder-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:builder-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:builder-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:builder-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:focus\:builder-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:focus\:builder-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:focus\:builder-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:focus\:builder-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:focus\:builder-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:focus\:builder-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:focus\:builder-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:focus\:builder-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:focus\:builder-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:focus\:builder-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:focus\:builder-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:focus\:builder-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:focus\:builder-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:focus\:builder-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:focus\:builder-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:focus\:builder-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:focus\:builder-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:focus\:builder-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:focus\:builder-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:focus\:builder-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:focus\:builder-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:focus\:builder-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:focus\:builder-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:focus\:builder-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:focus\:builder-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:focus\:builder-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:focus\:builder-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:focus\:builder-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:focus\:builder-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:focus\:builder-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:focus\:builder-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:focus\:builder-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:focus\:builder-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:focus\:builder-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:focus\:builder-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:focus\:builder-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:focus\:builder-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:focus\:builder-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:focus\:builder-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:focus\:builder-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:focus\:builder-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:focus\:builder-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:focus\:builder-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:focus\:builder-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:focus\:builder-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:focus\:builder-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:focus\:builder-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:focus\:builder-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:focus\:builder-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:focus\:builder-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:focus\:builder-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:focus\:builder-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:focus\:builder-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:focus\:builder-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:focus\:builder-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:focus\:builder-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:focus\:builder-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:focus\:builder-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:focus\:builder-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:focus\:builder-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:focus\:builder-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:focus\:builder-via-primary:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .md\:focus\:builder-via-secondary-dark:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .md\:focus\:builder-via-secondary-darker:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .md\:builder-to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .md\:builder-to-current{
    --tw-gradient-to: currentColor !important;
  }

  .md\:builder-to-black{
    --tw-gradient-to: #000 !important;
  }

  .md\:builder-to-white{
    --tw-gradient-to: #fff !important;
  }

  .md\:builder-to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .md\:builder-to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .md\:builder-to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .md\:builder-to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .md\:builder-to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .md\:builder-to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .md\:builder-to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .md\:builder-to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .md\:builder-to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .md\:builder-to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .md\:builder-to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .md\:builder-to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .md\:builder-to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .md\:builder-to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .md\:builder-to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .md\:builder-to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .md\:builder-to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .md\:builder-to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .md\:builder-to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .md\:builder-to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .md\:builder-to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .md\:builder-to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .md\:builder-to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .md\:builder-to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .md\:builder-to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .md\:builder-to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .md\:builder-to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .md\:builder-to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .md\:builder-to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .md\:builder-to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .md\:builder-to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .md\:builder-to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .md\:builder-to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .md\:builder-to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .md\:builder-to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .md\:builder-to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .md\:builder-to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .md\:builder-to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .md\:builder-to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .md\:builder-to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .md\:builder-to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .md\:builder-to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .md\:builder-to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .md\:builder-to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .md\:builder-to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .md\:builder-to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .md\:builder-to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .md\:builder-to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .md\:builder-to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .md\:builder-to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .md\:builder-to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .md\:builder-to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .md\:builder-to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .md\:builder-to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .md\:builder-to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .md\:builder-to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .md\:builder-to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .md\:builder-to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .md\:builder-to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .md\:builder-to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .md\:builder-to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .md\:builder-to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .md\:builder-to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .md\:builder-to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .md\:builder-to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .md\:builder-to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .md\:builder-to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .md\:builder-to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .md\:builder-to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .md\:builder-to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .md\:builder-to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .md\:builder-to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .md\:builder-to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .md\:builder-to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .md\:builder-to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .md\:builder-to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .md\:builder-to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .md\:builder-to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .md\:builder-to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .md\:builder-to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .md\:builder-to-primary{
    --tw-gradient-to: #2d375a !important;
  }

  .md\:builder-to-secondary-dark{
    --tw-gradient-to: #343434 !important;
  }

  .md\:builder-to-secondary-darker{
    --tw-gradient-to: #212121 !important;
  }

  .md\:hover\:builder-to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .md\:hover\:builder-to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .md\:hover\:builder-to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .md\:hover\:builder-to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .md\:hover\:builder-to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .md\:hover\:builder-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .md\:hover\:builder-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .md\:hover\:builder-to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .md\:hover\:builder-to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .md\:hover\:builder-to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .md\:hover\:builder-to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .md\:hover\:builder-to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .md\:hover\:builder-to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .md\:hover\:builder-to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .md\:hover\:builder-to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .md\:hover\:builder-to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .md\:hover\:builder-to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .md\:hover\:builder-to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .md\:hover\:builder-to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .md\:hover\:builder-to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .md\:hover\:builder-to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .md\:hover\:builder-to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .md\:hover\:builder-to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .md\:hover\:builder-to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .md\:hover\:builder-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .md\:hover\:builder-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .md\:hover\:builder-to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .md\:hover\:builder-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .md\:hover\:builder-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .md\:hover\:builder-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .md\:hover\:builder-to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .md\:hover\:builder-to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .md\:hover\:builder-to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .md\:hover\:builder-to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .md\:hover\:builder-to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .md\:hover\:builder-to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .md\:hover\:builder-to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .md\:hover\:builder-to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .md\:hover\:builder-to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .md\:hover\:builder-to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .md\:hover\:builder-to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .md\:hover\:builder-to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .md\:hover\:builder-to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .md\:hover\:builder-to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .md\:hover\:builder-to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .md\:hover\:builder-to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .md\:hover\:builder-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .md\:hover\:builder-to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .md\:hover\:builder-to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .md\:hover\:builder-to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .md\:hover\:builder-to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .md\:hover\:builder-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .md\:hover\:builder-to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .md\:hover\:builder-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .md\:hover\:builder-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .md\:hover\:builder-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .md\:hover\:builder-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .md\:hover\:builder-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .md\:hover\:builder-to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .md\:hover\:builder-to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .md\:hover\:builder-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .md\:hover\:builder-to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .md\:hover\:builder-to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .md\:hover\:builder-to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .md\:hover\:builder-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .md\:hover\:builder-to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .md\:hover\:builder-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .md\:hover\:builder-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .md\:hover\:builder-to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .md\:hover\:builder-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .md\:hover\:builder-to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .md\:hover\:builder-to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .md\:hover\:builder-to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .md\:hover\:builder-to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .md\:hover\:builder-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .md\:hover\:builder-to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .md\:hover\:builder-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .md\:hover\:builder-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .md\:hover\:builder-to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .md\:hover\:builder-to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .md\:hover\:builder-to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .md\:hover\:builder-to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .md\:hover\:builder-to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .md\:hover\:builder-to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .md\:hover\:builder-to-primary:hover{
    --tw-gradient-to: #2d375a !important;
  }

  .md\:hover\:builder-to-secondary-dark:hover{
    --tw-gradient-to: #343434 !important;
  }

  .md\:hover\:builder-to-secondary-darker:hover{
    --tw-gradient-to: #212121 !important;
  }

  .md\:focus\:builder-to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .md\:focus\:builder-to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .md\:focus\:builder-to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .md\:focus\:builder-to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .md\:focus\:builder-to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .md\:focus\:builder-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .md\:focus\:builder-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .md\:focus\:builder-to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .md\:focus\:builder-to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .md\:focus\:builder-to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .md\:focus\:builder-to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .md\:focus\:builder-to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .md\:focus\:builder-to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .md\:focus\:builder-to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .md\:focus\:builder-to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .md\:focus\:builder-to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .md\:focus\:builder-to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .md\:focus\:builder-to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .md\:focus\:builder-to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .md\:focus\:builder-to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .md\:focus\:builder-to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .md\:focus\:builder-to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .md\:focus\:builder-to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .md\:focus\:builder-to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .md\:focus\:builder-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .md\:focus\:builder-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .md\:focus\:builder-to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .md\:focus\:builder-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .md\:focus\:builder-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .md\:focus\:builder-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .md\:focus\:builder-to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .md\:focus\:builder-to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .md\:focus\:builder-to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .md\:focus\:builder-to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .md\:focus\:builder-to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .md\:focus\:builder-to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .md\:focus\:builder-to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .md\:focus\:builder-to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .md\:focus\:builder-to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .md\:focus\:builder-to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .md\:focus\:builder-to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .md\:focus\:builder-to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .md\:focus\:builder-to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .md\:focus\:builder-to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .md\:focus\:builder-to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .md\:focus\:builder-to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .md\:focus\:builder-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .md\:focus\:builder-to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .md\:focus\:builder-to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .md\:focus\:builder-to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .md\:focus\:builder-to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .md\:focus\:builder-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .md\:focus\:builder-to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .md\:focus\:builder-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .md\:focus\:builder-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .md\:focus\:builder-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .md\:focus\:builder-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .md\:focus\:builder-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .md\:focus\:builder-to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .md\:focus\:builder-to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .md\:focus\:builder-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .md\:focus\:builder-to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .md\:focus\:builder-to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .md\:focus\:builder-to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .md\:focus\:builder-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .md\:focus\:builder-to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .md\:focus\:builder-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .md\:focus\:builder-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .md\:focus\:builder-to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .md\:focus\:builder-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .md\:focus\:builder-to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .md\:focus\:builder-to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .md\:focus\:builder-to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .md\:focus\:builder-to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .md\:focus\:builder-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .md\:focus\:builder-to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .md\:focus\:builder-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .md\:focus\:builder-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .md\:focus\:builder-to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .md\:focus\:builder-to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .md\:focus\:builder-to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .md\:focus\:builder-to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .md\:focus\:builder-to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .md\:focus\:builder-to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .md\:focus\:builder-to-primary:focus{
    --tw-gradient-to: #2d375a !important;
  }

  .md\:focus\:builder-to-secondary-dark:focus{
    --tw-gradient-to: #343434 !important;
  }

  .md\:focus\:builder-to-secondary-darker:focus{
    --tw-gradient-to: #212121 !important;
  }

  .md\:builder-decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .md\:builder-decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .md\:builder-bg-auto{
    background-size: auto !important;
  }

  .md\:builder-bg-cover{
    background-size: cover !important;
  }

  .md\:builder-bg-contain{
    background-size: contain !important;
  }

  .md\:builder-bg-fixed{
    background-attachment: fixed !important;
  }

  .md\:builder-bg-local{
    background-attachment: local !important;
  }

  .md\:builder-bg-scroll{
    background-attachment: scroll !important;
  }

  .md\:builder-bg-clip-border{
    background-clip: border-box !important;
  }

  .md\:builder-bg-clip-padding{
    background-clip: padding-box !important;
  }

  .md\:builder-bg-clip-content{
    background-clip: content-box !important;
  }

  .md\:builder-bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .md\:builder-bg-bottom{
    background-position: bottom !important;
  }

  .md\:builder-bg-center{
    background-position: center !important;
  }

  .md\:builder-bg-left{
    background-position: left !important;
  }

  .md\:builder-bg-left-bottom{
    background-position: left bottom !important;
  }

  .md\:builder-bg-left-top{
    background-position: left top !important;
  }

  .md\:builder-bg-right{
    background-position: right !important;
  }

  .md\:builder-bg-right-bottom{
    background-position: right bottom !important;
  }

  .md\:builder-bg-right-top{
    background-position: right top !important;
  }

  .md\:builder-bg-top{
    background-position: top !important;
  }

  .md\:builder-bg-repeat{
    background-repeat: repeat !important;
  }

  .md\:builder-bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .md\:builder-bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .md\:builder-bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .md\:builder-bg-repeat-round{
    background-repeat: round !important;
  }

  .md\:builder-bg-repeat-space{
    background-repeat: space !important;
  }

  .md\:builder-bg-origin-border{
    background-origin: border-box !important;
  }

  .md\:builder-bg-origin-padding{
    background-origin: padding-box !important;
  }

  .md\:builder-bg-origin-content{
    background-origin: content-box !important;
  }

  .md\:builder-fill-current{
    fill: currentColor !important;
  }

  .md\:builder-stroke-current{
    stroke: currentColor !important;
  }

  .md\:builder-stroke-0{
    stroke-width: 0 !important;
  }

  .md\:builder-stroke-1{
    stroke-width: 1 !important;
  }

  .md\:builder-stroke-2{
    stroke-width: 2 !important;
  }

  .md\:builder-object-contain{
    object-fit: contain !important;
  }

  .md\:builder-object-cover{
    object-fit: cover !important;
  }

  .md\:builder-object-fill{
    object-fit: fill !important;
  }

  .md\:builder-object-none{
    object-fit: none !important;
  }

  .md\:builder-object-scale-down{
    object-fit: scale-down !important;
  }

  .md\:builder-object-bottom{
    object-position: bottom !important;
  }

  .md\:builder-object-center{
    object-position: center !important;
  }

  .md\:builder-object-left{
    object-position: left !important;
  }

  .md\:builder-object-left-bottom{
    object-position: left bottom !important;
  }

  .md\:builder-object-left-top{
    object-position: left top !important;
  }

  .md\:builder-object-right{
    object-position: right !important;
  }

  .md\:builder-object-right-bottom{
    object-position: right bottom !important;
  }

  .md\:builder-object-right-top{
    object-position: right top !important;
  }

  .md\:builder-object-top{
    object-position: top !important;
  }

  .md\:builder-p-0{
    padding: 0px !important;
  }

  .md\:builder-p-1{
    padding: 0.25rem !important;
  }

  .md\:builder-p-2{
    padding: 0.5rem !important;
  }

  .md\:builder-p-3{
    padding: 0.75rem !important;
  }

  .md\:builder-p-4{
    padding: 1rem !important;
  }

  .md\:builder-p-5{
    padding: 1.25rem !important;
  }

  .md\:builder-p-6{
    padding: 1.5rem !important;
  }

  .md\:builder-p-7{
    padding: 1.75rem !important;
  }

  .md\:builder-p-8{
    padding: 2rem !important;
  }

  .md\:builder-p-9{
    padding: 2.25rem !important;
  }

  .md\:builder-p-10{
    padding: 2.5rem !important;
  }

  .md\:builder-p-11{
    padding: 2.75rem !important;
  }

  .md\:builder-p-12{
    padding: 3rem !important;
  }

  .md\:builder-p-14{
    padding: 3.5rem !important;
  }

  .md\:builder-p-16{
    padding: 4rem !important;
  }

  .md\:builder-p-20{
    padding: 5rem !important;
  }

  .md\:builder-p-24{
    padding: 6rem !important;
  }

  .md\:builder-p-28{
    padding: 7rem !important;
  }

  .md\:builder-p-32{
    padding: 8rem !important;
  }

  .md\:builder-p-36{
    padding: 9rem !important;
  }

  .md\:builder-p-40{
    padding: 10rem !important;
  }

  .md\:builder-p-44{
    padding: 11rem !important;
  }

  .md\:builder-p-48{
    padding: 12rem !important;
  }

  .md\:builder-p-52{
    padding: 13rem !important;
  }

  .md\:builder-p-56{
    padding: 14rem !important;
  }

  .md\:builder-p-60{
    padding: 15rem !important;
  }

  .md\:builder-p-64{
    padding: 16rem !important;
  }

  .md\:builder-p-72{
    padding: 18rem !important;
  }

  .md\:builder-p-80{
    padding: 20rem !important;
  }

  .md\:builder-p-96{
    padding: 24rem !important;
  }

  .md\:builder-p-px{
    padding: 1px !important;
  }

  .md\:builder-p-0\.5{
    padding: 0.125rem !important;
  }

  .md\:builder-p-1\.5{
    padding: 0.375rem !important;
  }

  .md\:builder-p-2\.5{
    padding: 0.625rem !important;
  }

  .md\:builder-p-3\.5{
    padding: 0.875rem !important;
  }

  .md\:builder-px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .md\:builder-px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:builder-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:builder-px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .md\:builder-px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:builder-px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .md\:builder-px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:builder-px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .md\:builder-px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:builder-px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .md\:builder-px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .md\:builder-px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .md\:builder-px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:builder-px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .md\:builder-px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:builder-px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:builder-px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .md\:builder-px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .md\:builder-px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .md\:builder-px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .md\:builder-px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .md\:builder-px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .md\:builder-px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .md\:builder-px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .md\:builder-px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .md\:builder-px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .md\:builder-px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .md\:builder-px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .md\:builder-px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .md\:builder-px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .md\:builder-px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .md\:builder-px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .md\:builder-px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .md\:builder-px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .md\:builder-px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .md\:builder-py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .md\:builder-py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .md\:builder-py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:builder-py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .md\:builder-py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:builder-py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .md\:builder-py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:builder-py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .md\:builder-py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:builder-py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .md\:builder-py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .md\:builder-py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .md\:builder-py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:builder-py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .md\:builder-py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:builder-py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .md\:builder-py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .md\:builder-py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .md\:builder-py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .md\:builder-py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .md\:builder-py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .md\:builder-py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .md\:builder-py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .md\:builder-py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .md\:builder-py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .md\:builder-py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .md\:builder-py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .md\:builder-py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .md\:builder-py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .md\:builder-py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .md\:builder-py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .md\:builder-py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .md\:builder-py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .md\:builder-py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .md\:builder-py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .md\:builder-pt-0{
    padding-top: 0px !important;
  }

  .md\:builder-pt-1{
    padding-top: 0.25rem !important;
  }

  .md\:builder-pt-2{
    padding-top: 0.5rem !important;
  }

  .md\:builder-pt-3{
    padding-top: 0.75rem !important;
  }

  .md\:builder-pt-4{
    padding-top: 1rem !important;
  }

  .md\:builder-pt-5{
    padding-top: 1.25rem !important;
  }

  .md\:builder-pt-6{
    padding-top: 1.5rem !important;
  }

  .md\:builder-pt-7{
    padding-top: 1.75rem !important;
  }

  .md\:builder-pt-8{
    padding-top: 2rem !important;
  }

  .md\:builder-pt-9{
    padding-top: 2.25rem !important;
  }

  .md\:builder-pt-10{
    padding-top: 2.5rem !important;
  }

  .md\:builder-pt-11{
    padding-top: 2.75rem !important;
  }

  .md\:builder-pt-12{
    padding-top: 3rem !important;
  }

  .md\:builder-pt-14{
    padding-top: 3.5rem !important;
  }

  .md\:builder-pt-16{
    padding-top: 4rem !important;
  }

  .md\:builder-pt-20{
    padding-top: 5rem !important;
  }

  .md\:builder-pt-24{
    padding-top: 6rem !important;
  }

  .md\:builder-pt-28{
    padding-top: 7rem !important;
  }

  .md\:builder-pt-32{
    padding-top: 8rem !important;
  }

  .md\:builder-pt-36{
    padding-top: 9rem !important;
  }

  .md\:builder-pt-40{
    padding-top: 10rem !important;
  }

  .md\:builder-pt-44{
    padding-top: 11rem !important;
  }

  .md\:builder-pt-48{
    padding-top: 12rem !important;
  }

  .md\:builder-pt-52{
    padding-top: 13rem !important;
  }

  .md\:builder-pt-56{
    padding-top: 14rem !important;
  }

  .md\:builder-pt-60{
    padding-top: 15rem !important;
  }

  .md\:builder-pt-64{
    padding-top: 16rem !important;
  }

  .md\:builder-pt-72{
    padding-top: 18rem !important;
  }

  .md\:builder-pt-80{
    padding-top: 20rem !important;
  }

  .md\:builder-pt-96{
    padding-top: 24rem !important;
  }

  .md\:builder-pt-px{
    padding-top: 1px !important;
  }

  .md\:builder-pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .md\:builder-pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .md\:builder-pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .md\:builder-pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .md\:builder-pr-0{
    padding-right: 0px !important;
  }

  .md\:builder-pr-1{
    padding-right: 0.25rem !important;
  }

  .md\:builder-pr-2{
    padding-right: 0.5rem !important;
  }

  .md\:builder-pr-3{
    padding-right: 0.75rem !important;
  }

  .md\:builder-pr-4{
    padding-right: 1rem !important;
  }

  .md\:builder-pr-5{
    padding-right: 1.25rem !important;
  }

  .md\:builder-pr-6{
    padding-right: 1.5rem !important;
  }

  .md\:builder-pr-7{
    padding-right: 1.75rem !important;
  }

  .md\:builder-pr-8{
    padding-right: 2rem !important;
  }

  .md\:builder-pr-9{
    padding-right: 2.25rem !important;
  }

  .md\:builder-pr-10{
    padding-right: 2.5rem !important;
  }

  .md\:builder-pr-11{
    padding-right: 2.75rem !important;
  }

  .md\:builder-pr-12{
    padding-right: 3rem !important;
  }

  .md\:builder-pr-14{
    padding-right: 3.5rem !important;
  }

  .md\:builder-pr-16{
    padding-right: 4rem !important;
  }

  .md\:builder-pr-20{
    padding-right: 5rem !important;
  }

  .md\:builder-pr-24{
    padding-right: 6rem !important;
  }

  .md\:builder-pr-28{
    padding-right: 7rem !important;
  }

  .md\:builder-pr-32{
    padding-right: 8rem !important;
  }

  .md\:builder-pr-36{
    padding-right: 9rem !important;
  }

  .md\:builder-pr-40{
    padding-right: 10rem !important;
  }

  .md\:builder-pr-44{
    padding-right: 11rem !important;
  }

  .md\:builder-pr-48{
    padding-right: 12rem !important;
  }

  .md\:builder-pr-52{
    padding-right: 13rem !important;
  }

  .md\:builder-pr-56{
    padding-right: 14rem !important;
  }

  .md\:builder-pr-60{
    padding-right: 15rem !important;
  }

  .md\:builder-pr-64{
    padding-right: 16rem !important;
  }

  .md\:builder-pr-72{
    padding-right: 18rem !important;
  }

  .md\:builder-pr-80{
    padding-right: 20rem !important;
  }

  .md\:builder-pr-96{
    padding-right: 24rem !important;
  }

  .md\:builder-pr-px{
    padding-right: 1px !important;
  }

  .md\:builder-pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .md\:builder-pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .md\:builder-pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .md\:builder-pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .md\:builder-pb-0{
    padding-bottom: 0px !important;
  }

  .md\:builder-pb-1{
    padding-bottom: 0.25rem !important;
  }

  .md\:builder-pb-2{
    padding-bottom: 0.5rem !important;
  }

  .md\:builder-pb-3{
    padding-bottom: 0.75rem !important;
  }

  .md\:builder-pb-4{
    padding-bottom: 1rem !important;
  }

  .md\:builder-pb-5{
    padding-bottom: 1.25rem !important;
  }

  .md\:builder-pb-6{
    padding-bottom: 1.5rem !important;
  }

  .md\:builder-pb-7{
    padding-bottom: 1.75rem !important;
  }

  .md\:builder-pb-8{
    padding-bottom: 2rem !important;
  }

  .md\:builder-pb-9{
    padding-bottom: 2.25rem !important;
  }

  .md\:builder-pb-10{
    padding-bottom: 2.5rem !important;
  }

  .md\:builder-pb-11{
    padding-bottom: 2.75rem !important;
  }

  .md\:builder-pb-12{
    padding-bottom: 3rem !important;
  }

  .md\:builder-pb-14{
    padding-bottom: 3.5rem !important;
  }

  .md\:builder-pb-16{
    padding-bottom: 4rem !important;
  }

  .md\:builder-pb-20{
    padding-bottom: 5rem !important;
  }

  .md\:builder-pb-24{
    padding-bottom: 6rem !important;
  }

  .md\:builder-pb-28{
    padding-bottom: 7rem !important;
  }

  .md\:builder-pb-32{
    padding-bottom: 8rem !important;
  }

  .md\:builder-pb-36{
    padding-bottom: 9rem !important;
  }

  .md\:builder-pb-40{
    padding-bottom: 10rem !important;
  }

  .md\:builder-pb-44{
    padding-bottom: 11rem !important;
  }

  .md\:builder-pb-48{
    padding-bottom: 12rem !important;
  }

  .md\:builder-pb-52{
    padding-bottom: 13rem !important;
  }

  .md\:builder-pb-56{
    padding-bottom: 14rem !important;
  }

  .md\:builder-pb-60{
    padding-bottom: 15rem !important;
  }

  .md\:builder-pb-64{
    padding-bottom: 16rem !important;
  }

  .md\:builder-pb-72{
    padding-bottom: 18rem !important;
  }

  .md\:builder-pb-80{
    padding-bottom: 20rem !important;
  }

  .md\:builder-pb-96{
    padding-bottom: 24rem !important;
  }

  .md\:builder-pb-px{
    padding-bottom: 1px !important;
  }

  .md\:builder-pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .md\:builder-pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .md\:builder-pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .md\:builder-pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .md\:builder-pl-0{
    padding-left: 0px !important;
  }

  .md\:builder-pl-1{
    padding-left: 0.25rem !important;
  }

  .md\:builder-pl-2{
    padding-left: 0.5rem !important;
  }

  .md\:builder-pl-3{
    padding-left: 0.75rem !important;
  }

  .md\:builder-pl-4{
    padding-left: 1rem !important;
  }

  .md\:builder-pl-5{
    padding-left: 1.25rem !important;
  }

  .md\:builder-pl-6{
    padding-left: 1.5rem !important;
  }

  .md\:builder-pl-7{
    padding-left: 1.75rem !important;
  }

  .md\:builder-pl-8{
    padding-left: 2rem !important;
  }

  .md\:builder-pl-9{
    padding-left: 2.25rem !important;
  }

  .md\:builder-pl-10{
    padding-left: 2.5rem !important;
  }

  .md\:builder-pl-11{
    padding-left: 2.75rem !important;
  }

  .md\:builder-pl-12{
    padding-left: 3rem !important;
  }

  .md\:builder-pl-14{
    padding-left: 3.5rem !important;
  }

  .md\:builder-pl-16{
    padding-left: 4rem !important;
  }

  .md\:builder-pl-20{
    padding-left: 5rem !important;
  }

  .md\:builder-pl-24{
    padding-left: 6rem !important;
  }

  .md\:builder-pl-28{
    padding-left: 7rem !important;
  }

  .md\:builder-pl-32{
    padding-left: 8rem !important;
  }

  .md\:builder-pl-36{
    padding-left: 9rem !important;
  }

  .md\:builder-pl-40{
    padding-left: 10rem !important;
  }

  .md\:builder-pl-44{
    padding-left: 11rem !important;
  }

  .md\:builder-pl-48{
    padding-left: 12rem !important;
  }

  .md\:builder-pl-52{
    padding-left: 13rem !important;
  }

  .md\:builder-pl-56{
    padding-left: 14rem !important;
  }

  .md\:builder-pl-60{
    padding-left: 15rem !important;
  }

  .md\:builder-pl-64{
    padding-left: 16rem !important;
  }

  .md\:builder-pl-72{
    padding-left: 18rem !important;
  }

  .md\:builder-pl-80{
    padding-left: 20rem !important;
  }

  .md\:builder-pl-96{
    padding-left: 24rem !important;
  }

  .md\:builder-pl-px{
    padding-left: 1px !important;
  }

  .md\:builder-pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .md\:builder-pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .md\:builder-pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .md\:builder-pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .md\:builder-text-left{
    text-align: left !important;
  }

  .md\:builder-text-center{
    text-align: center !important;
  }

  .md\:builder-text-right{
    text-align: right !important;
  }

  .md\:builder-text-justify{
    text-align: justify !important;
  }

  .md\:builder-align-baseline{
    vertical-align: baseline !important;
  }

  .md\:builder-align-top{
    vertical-align: top !important;
  }

  .md\:builder-align-middle{
    vertical-align: middle !important;
  }

  .md\:builder-align-bottom{
    vertical-align: bottom !important;
  }

  .md\:builder-align-text-top{
    vertical-align: text-top !important;
  }

  .md\:builder-align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .md\:builder-font-primary{
    font-family: proxima-nova !important;
  }

  .md\:builder-text-sm{
    font-size: 14px !important;
  }

  .md\:builder-text-md{
    font-size: 15px !important;
  }

  .md\:builder-text-2xl{
    font-size: 24px !important;
  }

  .md\:builder-font-thin{
    font-weight: 100 !important;
  }

  .md\:builder-font-extralight{
    font-weight: 200 !important;
  }

  .md\:builder-font-light{
    font-weight: 300 !important;
  }

  .md\:builder-font-normal{
    font-weight: 400 !important;
  }

  .md\:builder-font-medium{
    font-weight: 500 !important;
  }

  .md\:builder-font-semibold{
    font-weight: 600 !important;
  }

  .md\:builder-font-bold{
    font-weight: 700 !important;
  }

  .md\:builder-font-extrabold{
    font-weight: 800 !important;
  }

  .md\:builder-font-black{
    font-weight: 900 !important;
  }

  .md\:builder-uppercase{
    text-transform: uppercase !important;
  }

  .md\:builder-lowercase{
    text-transform: lowercase !important;
  }

  .md\:builder-capitalize{
    text-transform: capitalize !important;
  }

  .md\:builder-normal-case{
    text-transform: none !important;
  }

  .md\:builder-italic{
    font-style: italic !important;
  }

  .md\:builder-not-italic{
    font-style: normal !important;
  }

  .md\:builder-ordinal, .md\:builder-slashed-zero, .md\:builder-lining-nums, .md\:builder-oldstyle-nums, .md\:builder-proportional-nums, .md\:builder-tabular-nums, .md\:builder-diagonal-fractions, .md\:builder-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .md\:builder-normal-nums{
    font-variant-numeric: normal !important;
  }

  .md\:builder-ordinal{
    --tw-ordinal: ordinal !important;
  }

  .md\:builder-slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .md\:builder-lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .md\:builder-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .md\:builder-proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .md\:builder-tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .md\:builder-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .md\:builder-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .md\:builder-leading-3{
    line-height: .75rem !important;
  }

  .md\:builder-leading-4{
    line-height: 1rem !important;
  }

  .md\:builder-leading-5{
    line-height: 1.25rem !important;
  }

  .md\:builder-leading-6{
    line-height: 1.5rem !important;
  }

  .md\:builder-leading-7{
    line-height: 1.75rem !important;
  }

  .md\:builder-leading-8{
    line-height: 2rem !important;
  }

  .md\:builder-leading-9{
    line-height: 2.25rem !important;
  }

  .md\:builder-leading-10{
    line-height: 2.5rem !important;
  }

  .md\:builder-leading-none{
    line-height: 1 !important;
  }

  .md\:builder-leading-tight{
    line-height: 1.25 !important;
  }

  .md\:builder-leading-snug{
    line-height: 1.375 !important;
  }

  .md\:builder-leading-normal{
    line-height: 1.5 !important;
  }

  .md\:builder-leading-relaxed{
    line-height: 1.625 !important;
  }

  .md\:builder-leading-loose{
    line-height: 2 !important;
  }

  .md\:builder-tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .md\:builder-tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .md\:builder-tracking-normal{
    letter-spacing: 0em !important;
  }

  .md\:builder-tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .md\:builder-tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .md\:builder-tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .md\:builder-text-transparent{
    color: transparent !important;
  }

  .md\:builder-text-current{
    color: currentColor !important;
  }

  .md\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-transparent{
    color: transparent !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-current{
    color: currentColor !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-transparent:focus-within{
    color: transparent !important;
  }

  .md\:focus-within\:builder-text-current:focus-within{
    color: currentColor !important;
  }

  .md\:focus-within\:builder-text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-primary:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-secondary-dark:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:builder-text-secondary-darker:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-transparent:hover{
    color: transparent !important;
  }

  .md\:hover\:builder-text-current:hover{
    color: currentColor !important;
  }

  .md\:hover\:builder-text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-primary:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-secondary-dark:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:builder-text-secondary-darker:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-transparent:focus{
    color: transparent !important;
  }

  .md\:focus\:builder-text-current:focus{
    color: currentColor !important;
  }

  .md\:focus\:builder-text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-primary:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-secondary-dark:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:builder-text-secondary-darker:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .md\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .md\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .md\:focus-within\:builder-text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .md\:focus-within\:builder-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:focus-within\:builder-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:focus-within\:builder-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:focus-within\:builder-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:focus-within\:builder-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:focus-within\:builder-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:focus-within\:builder-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:focus-within\:builder-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:focus-within\:builder-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:focus-within\:builder-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:focus-within\:builder-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:focus-within\:builder-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:focus-within\:builder-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:focus-within\:builder-text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .md\:hover\:builder-text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .md\:hover\:builder-text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:hover\:builder-text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:hover\:builder-text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:hover\:builder-text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:hover\:builder-text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:hover\:builder-text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:hover\:builder-text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:hover\:builder-text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:hover\:builder-text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:hover\:builder-text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:hover\:builder-text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:hover\:builder-text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:hover\:builder-text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:hover\:builder-text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .md\:focus\:builder-text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .md\:focus\:builder-text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:focus\:builder-text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:focus\:builder-text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:focus\:builder-text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:focus\:builder-text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:focus\:builder-text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:focus\:builder-text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:focus\:builder-text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:focus\:builder-text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:focus\:builder-text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:focus\:builder-text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:focus\:builder-text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:focus\:builder-text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:focus\:builder-text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .md\:builder-underline{
    text-decoration: underline !important;
  }

  .md\:builder-line-through{
    text-decoration: line-through !important;
  }

  .md\:builder-no-underline{
    text-decoration: none !important;
  }

  .builder-group:hover .md\:group-hover\:builder-underline{
    text-decoration: underline !important;
  }

  .builder-group:hover .md\:group-hover\:builder-line-through{
    text-decoration: line-through !important;
  }

  .builder-group:hover .md\:group-hover\:builder-no-underline{
    text-decoration: none !important;
  }

  .md\:focus-within\:builder-underline:focus-within{
    text-decoration: underline !important;
  }

  .md\:focus-within\:builder-line-through:focus-within{
    text-decoration: line-through !important;
  }

  .md\:focus-within\:builder-no-underline:focus-within{
    text-decoration: none !important;
  }

  .md\:hover\:builder-underline:hover{
    text-decoration: underline !important;
  }

  .md\:hover\:builder-line-through:hover{
    text-decoration: line-through !important;
  }

  .md\:hover\:builder-no-underline:hover{
    text-decoration: none !important;
  }

  .md\:focus\:builder-underline:focus{
    text-decoration: underline !important;
  }

  .md\:focus\:builder-line-through:focus{
    text-decoration: line-through !important;
  }

  .md\:focus\:builder-no-underline:focus{
    text-decoration: none !important;
  }

  .md\:builder-antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .md\:builder-subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .md\:builder-placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .md\:builder-placeholder-current::placeholder{
    color: currentColor !important;
  }

  .md\:builder-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-primary::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-secondary-dark::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-secondary-darker::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .md\:focus\:builder-placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .md\:focus\:builder-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-primary:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-secondary-dark:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:builder-placeholder-secondary-darker:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .md\:builder-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:builder-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:builder-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:builder-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:builder-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:builder-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:builder-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:builder-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:builder-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:builder-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:builder-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:builder-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:builder-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:builder-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:builder-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:focus\:builder-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:focus\:builder-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:focus\:builder-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:focus\:builder-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:focus\:builder-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:builder-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:focus\:builder-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:focus\:builder-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:builder-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:focus\:builder-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:focus\:builder-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:builder-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:focus\:builder-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:focus\:builder-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:focus\:builder-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:builder-opacity-0{
    opacity: 0 !important;
  }

  .md\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .md\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .md\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .md\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .md\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .md\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .md\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .md\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .md\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .md\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .md\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .md\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .md\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .md\:builder-opacity-100{
    opacity: 1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-0{
    opacity: 0 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .builder-group:hover .md\:group-hover\:builder-opacity-100{
    opacity: 1 !important;
  }

  .md\:focus-within\:builder-opacity-0:focus-within{
    opacity: 0 !important;
  }

  .md\:focus-within\:builder-opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .md\:focus-within\:builder-opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .md\:focus-within\:builder-opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .md\:focus-within\:builder-opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .md\:focus-within\:builder-opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .md\:focus-within\:builder-opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .md\:focus-within\:builder-opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .md\:focus-within\:builder-opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .md\:focus-within\:builder-opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .md\:focus-within\:builder-opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .md\:focus-within\:builder-opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .md\:focus-within\:builder-opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .md\:focus-within\:builder-opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .md\:focus-within\:builder-opacity-100:focus-within{
    opacity: 1 !important;
  }

  .md\:hover\:builder-opacity-0:hover{
    opacity: 0 !important;
  }

  .md\:hover\:builder-opacity-5:hover{
    opacity: 0.05 !important;
  }

  .md\:hover\:builder-opacity-10:hover{
    opacity: 0.1 !important;
  }

  .md\:hover\:builder-opacity-20:hover{
    opacity: 0.2 !important;
  }

  .md\:hover\:builder-opacity-25:hover{
    opacity: 0.25 !important;
  }

  .md\:hover\:builder-opacity-30:hover{
    opacity: 0.3 !important;
  }

  .md\:hover\:builder-opacity-40:hover{
    opacity: 0.4 !important;
  }

  .md\:hover\:builder-opacity-50:hover{
    opacity: 0.5 !important;
  }

  .md\:hover\:builder-opacity-60:hover{
    opacity: 0.6 !important;
  }

  .md\:hover\:builder-opacity-70:hover{
    opacity: 0.7 !important;
  }

  .md\:hover\:builder-opacity-75:hover{
    opacity: 0.75 !important;
  }

  .md\:hover\:builder-opacity-80:hover{
    opacity: 0.8 !important;
  }

  .md\:hover\:builder-opacity-90:hover{
    opacity: 0.9 !important;
  }

  .md\:hover\:builder-opacity-95:hover{
    opacity: 0.95 !important;
  }

  .md\:hover\:builder-opacity-100:hover{
    opacity: 1 !important;
  }

  .md\:focus\:builder-opacity-0:focus{
    opacity: 0 !important;
  }

  .md\:focus\:builder-opacity-5:focus{
    opacity: 0.05 !important;
  }

  .md\:focus\:builder-opacity-10:focus{
    opacity: 0.1 !important;
  }

  .md\:focus\:builder-opacity-20:focus{
    opacity: 0.2 !important;
  }

  .md\:focus\:builder-opacity-25:focus{
    opacity: 0.25 !important;
  }

  .md\:focus\:builder-opacity-30:focus{
    opacity: 0.3 !important;
  }

  .md\:focus\:builder-opacity-40:focus{
    opacity: 0.4 !important;
  }

  .md\:focus\:builder-opacity-50:focus{
    opacity: 0.5 !important;
  }

  .md\:focus\:builder-opacity-60:focus{
    opacity: 0.6 !important;
  }

  .md\:focus\:builder-opacity-70:focus{
    opacity: 0.7 !important;
  }

  .md\:focus\:builder-opacity-75:focus{
    opacity: 0.75 !important;
  }

  .md\:focus\:builder-opacity-80:focus{
    opacity: 0.8 !important;
  }

  .md\:focus\:builder-opacity-90:focus{
    opacity: 0.9 !important;
  }

  .md\:focus\:builder-opacity-95:focus{
    opacity: 0.95 !important;
  }

  .md\:focus\:builder-opacity-100:focus{
    opacity: 1 !important;
  }

  .md\:builder-bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .md\:builder-bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .md\:builder-bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .md\:builder-bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .md\:builder-bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .md\:builder-bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .md\:builder-bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .md\:builder-bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .md\:builder-bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .md\:builder-bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .md\:builder-bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .md\:builder-bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .md\:builder-bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .md\:builder-bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .md\:builder-bg-blend-color{
    background-blend-mode: color !important;
  }

  .md\:builder-bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .md\:builder-mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .md\:builder-mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .md\:builder-mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .md\:builder-mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .md\:builder-mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .md\:builder-mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .md\:builder-mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .md\:builder-mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .md\:builder-mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .md\:builder-mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .md\:builder-mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .md\:builder-mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .md\:builder-mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .md\:builder-mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .md\:builder-mix-blend-color{
    mix-blend-mode: color !important;
  }

  .md\:builder-mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .md\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .md\:group-hover\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:builder-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:builder-shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:builder-shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:builder-outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .md\:builder-outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .md\:builder-outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .md\:focus-within\:builder-outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .md\:focus-within\:builder-outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .md\:focus-within\:builder-outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .md\:focus\:builder-outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .md\:focus\:builder-outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .md\:focus\:builder-outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .md\:builder-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:builder-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:builder-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:builder-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:builder-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:builder-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:builder-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:builder-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:builder-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:builder-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:builder-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:builder-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:builder-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:builder-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:builder-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:builder-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:builder-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:builder-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:builder-ring-inset{
    --tw-ring-inset: inset !important;
  }

  .md\:focus-within\:builder-ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .md\:focus\:builder-ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .md\:builder-ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .md\:builder-ring-current{
    --tw-ring-color: currentColor !important;
  }

  .md\:builder-ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-primary{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-secondary-dark{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-secondary-darker{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .md\:focus-within\:builder-ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .md\:focus-within\:builder-ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-primary:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-secondary-dark:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:builder-ring-secondary-darker:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .md\:focus\:builder-ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .md\:focus\:builder-ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-primary:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-secondary-dark:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:builder-ring-secondary-darker:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .md\:builder-ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .md\:builder-ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .md\:builder-ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .md\:builder-ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .md\:builder-ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .md\:builder-ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .md\:builder-ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .md\:builder-ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .md\:builder-ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .md\:builder-ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .md\:builder-ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .md\:builder-ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .md\:builder-ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .md\:builder-ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .md\:builder-ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .md\:focus-within\:builder-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .md\:focus-within\:builder-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .md\:focus-within\:builder-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .md\:focus-within\:builder-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .md\:focus-within\:builder-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .md\:focus-within\:builder-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .md\:focus-within\:builder-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .md\:focus-within\:builder-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .md\:focus-within\:builder-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .md\:focus-within\:builder-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .md\:focus-within\:builder-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .md\:focus-within\:builder-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .md\:focus-within\:builder-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .md\:focus-within\:builder-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .md\:focus-within\:builder-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .md\:focus\:builder-ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .md\:focus\:builder-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .md\:focus\:builder-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .md\:focus\:builder-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .md\:focus\:builder-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .md\:focus\:builder-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .md\:focus\:builder-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .md\:focus\:builder-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .md\:focus\:builder-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .md\:focus\:builder-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .md\:focus\:builder-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .md\:focus\:builder-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .md\:focus\:builder-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .md\:focus\:builder-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .md\:focus\:builder-ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .md\:builder-ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .md\:builder-ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .md\:builder-ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .md\:builder-ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .md\:builder-ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .md\:focus-within\:builder-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .md\:focus-within\:builder-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .md\:focus-within\:builder-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .md\:focus-within\:builder-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .md\:focus-within\:builder-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .md\:focus\:builder-ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .md\:focus\:builder-ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .md\:focus\:builder-ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .md\:focus\:builder-ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .md\:focus\:builder-ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .md\:builder-ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .md\:builder-ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .md\:builder-ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .md\:builder-ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .md\:builder-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .md\:builder-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .md\:builder-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .md\:builder-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .md\:builder-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .md\:builder-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .md\:builder-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .md\:builder-ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .md\:builder-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .md\:builder-ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .md\:builder-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .md\:builder-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .md\:builder-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .md\:builder-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .md\:builder-ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .md\:builder-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .md\:builder-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .md\:builder-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .md\:builder-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .md\:builder-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .md\:builder-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .md\:builder-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .md\:builder-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .md\:builder-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .md\:builder-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .md\:builder-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .md\:builder-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .md\:builder-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .md\:builder-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .md\:builder-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .md\:builder-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .md\:builder-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .md\:builder-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .md\:builder-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .md\:builder-ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .md\:builder-ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .md\:builder-ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .md\:builder-ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .md\:builder-ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .md\:builder-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .md\:builder-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .md\:builder-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .md\:builder-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .md\:builder-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .md\:builder-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .md\:builder-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .md\:builder-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .md\:builder-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .md\:builder-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .md\:builder-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .md\:builder-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .md\:builder-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .md\:builder-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .md\:builder-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .md\:builder-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .md\:builder-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .md\:builder-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .md\:builder-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .md\:builder-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .md\:builder-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .md\:builder-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .md\:builder-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .md\:builder-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .md\:builder-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .md\:builder-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .md\:builder-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .md\:builder-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .md\:builder-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .md\:builder-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .md\:builder-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .md\:builder-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .md\:builder-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .md\:builder-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .md\:builder-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .md\:builder-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .md\:builder-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .md\:builder-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .md\:builder-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .md\:builder-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .md\:builder-ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .md\:builder-ring-offset-primary{
    --tw-ring-offset-color: #2d375a !important;
  }

  .md\:builder-ring-offset-secondary-dark{
    --tw-ring-offset-color: #343434 !important;
  }

  .md\:builder-ring-offset-secondary-darker{
    --tw-ring-offset-color: #212121 !important;
  }

  .md\:focus-within\:builder-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .md\:focus-within\:builder-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .md\:focus-within\:builder-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .md\:focus-within\:builder-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .md\:focus-within\:builder-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .md\:focus-within\:builder-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .md\:focus-within\:builder-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .md\:focus-within\:builder-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .md\:focus-within\:builder-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .md\:focus-within\:builder-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .md\:focus-within\:builder-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .md\:focus-within\:builder-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .md\:focus-within\:builder-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .md\:focus-within\:builder-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .md\:focus-within\:builder-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .md\:focus-within\:builder-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .md\:focus-within\:builder-ring-offset-primary:focus-within{
    --tw-ring-offset-color: #2d375a !important;
  }

  .md\:focus-within\:builder-ring-offset-secondary-dark:focus-within{
    --tw-ring-offset-color: #343434 !important;
  }

  .md\:focus-within\:builder-ring-offset-secondary-darker:focus-within{
    --tw-ring-offset-color: #212121 !important;
  }

  .md\:focus\:builder-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .md\:focus\:builder-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .md\:focus\:builder-ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .md\:focus\:builder-ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .md\:focus\:builder-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .md\:focus\:builder-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .md\:focus\:builder-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .md\:focus\:builder-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .md\:focus\:builder-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .md\:focus\:builder-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .md\:focus\:builder-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .md\:focus\:builder-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .md\:focus\:builder-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .md\:focus\:builder-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .md\:focus\:builder-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .md\:focus\:builder-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .md\:focus\:builder-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .md\:focus\:builder-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .md\:focus\:builder-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .md\:focus\:builder-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .md\:focus\:builder-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .md\:focus\:builder-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .md\:focus\:builder-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .md\:focus\:builder-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .md\:focus\:builder-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .md\:focus\:builder-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .md\:focus\:builder-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .md\:focus\:builder-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .md\:focus\:builder-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .md\:focus\:builder-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .md\:focus\:builder-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .md\:focus\:builder-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .md\:focus\:builder-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .md\:focus\:builder-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .md\:focus\:builder-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .md\:focus\:builder-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .md\:focus\:builder-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .md\:focus\:builder-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .md\:focus\:builder-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .md\:focus\:builder-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .md\:focus\:builder-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .md\:focus\:builder-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .md\:focus\:builder-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .md\:focus\:builder-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .md\:focus\:builder-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .md\:focus\:builder-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .md\:focus\:builder-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .md\:focus\:builder-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .md\:focus\:builder-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .md\:focus\:builder-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .md\:focus\:builder-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .md\:focus\:builder-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .md\:focus\:builder-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .md\:focus\:builder-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .md\:focus\:builder-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .md\:focus\:builder-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .md\:focus\:builder-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .md\:focus\:builder-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .md\:focus\:builder-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .md\:focus\:builder-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .md\:focus\:builder-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .md\:focus\:builder-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .md\:focus\:builder-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .md\:focus\:builder-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .md\:focus\:builder-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .md\:focus\:builder-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .md\:focus\:builder-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .md\:focus\:builder-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .md\:focus\:builder-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .md\:focus\:builder-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .md\:focus\:builder-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .md\:focus\:builder-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .md\:focus\:builder-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .md\:focus\:builder-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .md\:focus\:builder-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .md\:focus\:builder-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .md\:focus\:builder-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .md\:focus\:builder-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .md\:focus\:builder-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .md\:focus\:builder-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .md\:focus\:builder-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .md\:focus\:builder-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .md\:focus\:builder-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .md\:focus\:builder-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .md\:focus\:builder-ring-offset-primary:focus{
    --tw-ring-offset-color: #2d375a !important;
  }

  .md\:focus\:builder-ring-offset-secondary-dark:focus{
    --tw-ring-offset-color: #343434 !important;
  }

  .md\:focus\:builder-ring-offset-secondary-darker:focus{
    --tw-ring-offset-color: #212121 !important;
  }

  .md\:builder-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .md\:builder-filter-none{
    filter: none !important;
  }

  .md\:builder-blur-0{
    --tw-blur: blur(0) !important;
  }

  .md\:builder-blur-none{
    --tw-blur: blur(0) !important;
  }

  .md\:builder-blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .md\:builder-blur{
    --tw-blur: blur(8px) !important;
  }

  .md\:builder-blur-md{
    --tw-blur: blur(12px) !important;
  }

  .md\:builder-blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .md\:builder-blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .md\:builder-blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .md\:builder-blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .md\:builder-brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .md\:builder-brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .md\:builder-brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .md\:builder-brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .md\:builder-brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .md\:builder-brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .md\:builder-brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .md\:builder-brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .md\:builder-brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .md\:builder-brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .md\:builder-brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .md\:builder-contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .md\:builder-contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .md\:builder-contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .md\:builder-contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .md\:builder-contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .md\:builder-contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .md\:builder-contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .md\:builder-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .md\:builder-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .md\:builder-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .md\:builder-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .md\:builder-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .md\:builder-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .md\:builder-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .md\:builder-grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .md\:builder-grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .md\:builder-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .md\:builder-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .md\:builder-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .md\:builder-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .md\:builder-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .md\:builder-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .md\:builder--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .md\:builder--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .md\:builder--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .md\:builder--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .md\:builder--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .md\:builder-invert-0{
    --tw-invert: invert(0) !important;
  }

  .md\:builder-invert{
    --tw-invert: invert(100%) !important;
  }

  .md\:builder-saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .md\:builder-saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .md\:builder-saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .md\:builder-saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .md\:builder-saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .md\:builder-sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .md\:builder-sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .md\:builder-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .md\:builder-backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .md\:builder-backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .md\:builder-backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .md\:builder-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .md\:builder-backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .md\:builder-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .md\:builder-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .md\:builder-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .md\:builder-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .md\:builder-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .md\:builder-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .md\:builder-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .md\:builder-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .md\:builder-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .md\:builder-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .md\:builder-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .md\:builder-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .md\:builder-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .md\:builder-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .md\:builder-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .md\:builder-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .md\:builder-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .md\:builder-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .md\:builder-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .md\:builder-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .md\:builder-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .md\:builder-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .md\:builder-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .md\:builder-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .md\:builder-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .md\:builder-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .md\:builder-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .md\:builder-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .md\:builder-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .md\:builder-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .md\:builder-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .md\:builder--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .md\:builder--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .md\:builder--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .md\:builder--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .md\:builder--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .md\:builder-backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .md\:builder-backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .md\:builder-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .md\:builder-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .md\:builder-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .md\:builder-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .md\:builder-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .md\:builder-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .md\:builder-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .md\:builder-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .md\:builder-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .md\:builder-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .md\:builder-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .md\:builder-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .md\:builder-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .md\:builder-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .md\:builder-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .md\:builder-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .md\:builder-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .md\:builder-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .md\:builder-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .md\:builder-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .md\:builder-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .md\:builder-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .md\:builder-transition-none{
    transition-property: none !important;
  }

  .md\:builder-transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:builder-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:builder-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:builder-transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:builder-transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:builder-transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:builder-delay-75{
    transition-delay: 75ms !important;
  }

  .md\:builder-delay-100{
    transition-delay: 100ms !important;
  }

  .md\:builder-delay-150{
    transition-delay: 150ms !important;
  }

  .md\:builder-delay-200{
    transition-delay: 200ms !important;
  }

  .md\:builder-delay-300{
    transition-delay: 300ms !important;
  }

  .md\:builder-delay-500{
    transition-delay: 500ms !important;
  }

  .md\:builder-delay-700{
    transition-delay: 700ms !important;
  }

  .md\:builder-delay-1000{
    transition-delay: 1000ms !important;
  }

  .md\:builder-duration-75{
    transition-duration: 75ms !important;
  }

  .md\:builder-duration-100{
    transition-duration: 100ms !important;
  }

  .md\:builder-duration-150{
    transition-duration: 150ms !important;
  }

  .md\:builder-duration-200{
    transition-duration: 200ms !important;
  }

  .md\:builder-duration-300{
    transition-duration: 300ms !important;
  }

  .md\:builder-duration-500{
    transition-duration: 500ms !important;
  }

  .md\:builder-duration-700{
    transition-duration: 700ms !important;
  }

  .md\:builder-duration-1000{
    transition-duration: 1000ms !important;
  }

  .md\:builder-ease-linear{
    transition-timing-function: linear !important;
  }

  .md\:builder-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .md\:builder-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .md\:builder-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 1024px){
  .lg\:builder-container{
    width: 100%;
  }

  @media (min-width: 640px){
    .lg\:builder-container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .lg\:builder-container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .lg\:builder-container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .lg\:builder-container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .lg\:builder-container{
      max-width: 1536px;
    }
  }

  .lg\:builder-sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:builder-not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:focus-within\:builder-sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:focus-within\:builder-not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:focus\:builder-sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:focus\:builder-not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:builder-pointer-events-none{
    pointer-events: none !important;
  }

  .lg\:builder-pointer-events-auto{
    pointer-events: auto !important;
  }

  .lg\:builder-visible{
    visibility: visible !important;
  }

  .lg\:builder-invisible{
    visibility: hidden !important;
  }

  .lg\:builder-static{
    position: static !important;
  }

  .lg\:builder-fixed{
    position: fixed !important;
  }

  .lg\:builder-absolute{
    position: absolute !important;
  }

  .lg\:builder-relative{
    position: relative !important;
  }

  .lg\:builder-sticky{
    position: sticky !important;
  }

  .lg\:builder-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .lg\:builder-inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .lg\:builder-inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .lg\:builder-inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .lg\:builder-inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .lg\:builder-inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .lg\:builder-inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .lg\:builder-inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .lg\:builder-inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .lg\:builder-inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .lg\:builder-inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .lg\:builder-inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .lg\:builder-inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .lg\:builder-inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .lg\:builder-inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .lg\:builder-inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .lg\:builder-inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .lg\:builder-inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .lg\:builder-inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .lg\:builder-inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .lg\:builder-inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .lg\:builder-inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .lg\:builder-inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .lg\:builder-inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .lg\:builder-inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .lg\:builder-inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .lg\:builder-inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .lg\:builder-inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .lg\:builder-inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .lg\:builder-inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .lg\:builder-inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lg\:builder-inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .lg\:builder-inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .lg\:builder-inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .lg\:builder-inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .lg\:builder-inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .lg\:builder--inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .lg\:builder--inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .lg\:builder--inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .lg\:builder--inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .lg\:builder--inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .lg\:builder--inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .lg\:builder--inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .lg\:builder--inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .lg\:builder--inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .lg\:builder--inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .lg\:builder--inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .lg\:builder--inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .lg\:builder--inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .lg\:builder--inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .lg\:builder--inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .lg\:builder--inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .lg\:builder--inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .lg\:builder--inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .lg\:builder--inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .lg\:builder--inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .lg\:builder--inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .lg\:builder--inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .lg\:builder--inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .lg\:builder--inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .lg\:builder--inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .lg\:builder--inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .lg\:builder--inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .lg\:builder--inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .lg\:builder--inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .lg\:builder--inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .lg\:builder--inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .lg\:builder--inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .lg\:builder--inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .lg\:builder--inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .lg\:builder--inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .lg\:builder-inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .lg\:builder-inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .lg\:builder-inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .lg\:builder-inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .lg\:builder-inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .lg\:builder-inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .lg\:builder-inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .lg\:builder--inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .lg\:builder--inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .lg\:builder--inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .lg\:builder--inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .lg\:builder--inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .lg\:builder--inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .lg\:builder--inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .lg\:builder-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .lg\:builder-inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .lg\:builder-inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .lg\:builder-inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .lg\:builder-inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .lg\:builder-inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .lg\:builder-inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .lg\:builder-inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .lg\:builder-inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .lg\:builder-inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .lg\:builder-inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .lg\:builder-inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .lg\:builder-inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .lg\:builder-inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .lg\:builder-inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .lg\:builder-inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .lg\:builder-inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .lg\:builder-inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .lg\:builder-inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .lg\:builder-inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .lg\:builder-inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .lg\:builder-inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .lg\:builder-inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .lg\:builder-inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .lg\:builder-inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .lg\:builder-inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .lg\:builder-inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .lg\:builder-inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .lg\:builder-inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .lg\:builder-inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .lg\:builder-inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .lg\:builder-inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .lg\:builder-inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .lg\:builder-inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .lg\:builder-inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .lg\:builder-inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .lg\:builder--inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .lg\:builder--inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .lg\:builder--inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .lg\:builder--inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .lg\:builder--inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .lg\:builder--inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .lg\:builder--inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .lg\:builder--inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .lg\:builder--inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .lg\:builder--inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .lg\:builder--inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .lg\:builder--inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .lg\:builder--inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .lg\:builder--inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .lg\:builder--inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .lg\:builder--inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .lg\:builder--inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .lg\:builder--inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .lg\:builder--inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .lg\:builder--inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .lg\:builder--inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .lg\:builder--inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .lg\:builder--inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .lg\:builder--inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .lg\:builder--inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .lg\:builder--inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .lg\:builder--inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .lg\:builder--inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .lg\:builder--inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .lg\:builder--inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .lg\:builder--inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .lg\:builder--inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .lg\:builder--inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .lg\:builder--inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .lg\:builder--inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .lg\:builder-inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .lg\:builder-inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .lg\:builder-inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .lg\:builder-inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .lg\:builder-inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .lg\:builder-inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .lg\:builder-inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .lg\:builder--inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .lg\:builder--inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .lg\:builder--inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .lg\:builder--inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .lg\:builder--inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .lg\:builder--inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .lg\:builder--inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .lg\:builder-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .lg\:builder-inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .lg\:builder-inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .lg\:builder-inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .lg\:builder-inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .lg\:builder-inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .lg\:builder-inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .lg\:builder-inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .lg\:builder-inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .lg\:builder-inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .lg\:builder-inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .lg\:builder-inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .lg\:builder-inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .lg\:builder-inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .lg\:builder-inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .lg\:builder-inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .lg\:builder-inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .lg\:builder-inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .lg\:builder-inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .lg\:builder-inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .lg\:builder-inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .lg\:builder-inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .lg\:builder-inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .lg\:builder-inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .lg\:builder-inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .lg\:builder-inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .lg\:builder-inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .lg\:builder-inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .lg\:builder-inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .lg\:builder-inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .lg\:builder-inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .lg\:builder-inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .lg\:builder-inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .lg\:builder-inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .lg\:builder-inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .lg\:builder-inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .lg\:builder--inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .lg\:builder--inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .lg\:builder--inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .lg\:builder--inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .lg\:builder--inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .lg\:builder--inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .lg\:builder--inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .lg\:builder--inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .lg\:builder--inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .lg\:builder--inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .lg\:builder--inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .lg\:builder--inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .lg\:builder--inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .lg\:builder--inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .lg\:builder--inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .lg\:builder--inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .lg\:builder--inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .lg\:builder--inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .lg\:builder--inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .lg\:builder--inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .lg\:builder--inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .lg\:builder--inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .lg\:builder--inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .lg\:builder--inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .lg\:builder--inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .lg\:builder--inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .lg\:builder--inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .lg\:builder--inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .lg\:builder--inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .lg\:builder--inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .lg\:builder--inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .lg\:builder--inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .lg\:builder--inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .lg\:builder--inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .lg\:builder--inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .lg\:builder-inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .lg\:builder-inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .lg\:builder-inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .lg\:builder-inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .lg\:builder-inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .lg\:builder-inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .lg\:builder-inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .lg\:builder--inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .lg\:builder--inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .lg\:builder--inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .lg\:builder--inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .lg\:builder--inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .lg\:builder--inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .lg\:builder--inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .lg\:builder-top-0{
    top: 0px !important;
  }

  .lg\:builder-top-1{
    top: 0.25rem !important;
  }

  .lg\:builder-top-2{
    top: 0.5rem !important;
  }

  .lg\:builder-top-3{
    top: 0.75rem !important;
  }

  .lg\:builder-top-4{
    top: 1rem !important;
  }

  .lg\:builder-top-5{
    top: 1.25rem !important;
  }

  .lg\:builder-top-6{
    top: 1.5rem !important;
  }

  .lg\:builder-top-7{
    top: 1.75rem !important;
  }

  .lg\:builder-top-8{
    top: 2rem !important;
  }

  .lg\:builder-top-9{
    top: 2.25rem !important;
  }

  .lg\:builder-top-10{
    top: 2.5rem !important;
  }

  .lg\:builder-top-11{
    top: 2.75rem !important;
  }

  .lg\:builder-top-12{
    top: 3rem !important;
  }

  .lg\:builder-top-14{
    top: 3.5rem !important;
  }

  .lg\:builder-top-16{
    top: 4rem !important;
  }

  .lg\:builder-top-20{
    top: 5rem !important;
  }

  .lg\:builder-top-24{
    top: 6rem !important;
  }

  .lg\:builder-top-28{
    top: 7rem !important;
  }

  .lg\:builder-top-32{
    top: 8rem !important;
  }

  .lg\:builder-top-36{
    top: 9rem !important;
  }

  .lg\:builder-top-40{
    top: 10rem !important;
  }

  .lg\:builder-top-44{
    top: 11rem !important;
  }

  .lg\:builder-top-48{
    top: 12rem !important;
  }

  .lg\:builder-top-52{
    top: 13rem !important;
  }

  .lg\:builder-top-56{
    top: 14rem !important;
  }

  .lg\:builder-top-60{
    top: 15rem !important;
  }

  .lg\:builder-top-64{
    top: 16rem !important;
  }

  .lg\:builder-top-72{
    top: 18rem !important;
  }

  .lg\:builder-top-80{
    top: 20rem !important;
  }

  .lg\:builder-top-96{
    top: 24rem !important;
  }

  .lg\:builder-top-auto{
    top: auto !important;
  }

  .lg\:builder-top-px{
    top: 1px !important;
  }

  .lg\:builder-top-0\.5{
    top: 0.125rem !important;
  }

  .lg\:builder-top-1\.5{
    top: 0.375rem !important;
  }

  .lg\:builder-top-2\.5{
    top: 0.625rem !important;
  }

  .lg\:builder-top-3\.5{
    top: 0.875rem !important;
  }

  .lg\:builder--top-0{
    top: 0px !important;
  }

  .lg\:builder--top-1{
    top: -0.25rem !important;
  }

  .lg\:builder--top-2{
    top: -0.5rem !important;
  }

  .lg\:builder--top-3{
    top: -0.75rem !important;
  }

  .lg\:builder--top-4{
    top: -1rem !important;
  }

  .lg\:builder--top-5{
    top: -1.25rem !important;
  }

  .lg\:builder--top-6{
    top: -1.5rem !important;
  }

  .lg\:builder--top-7{
    top: -1.75rem !important;
  }

  .lg\:builder--top-8{
    top: -2rem !important;
  }

  .lg\:builder--top-9{
    top: -2.25rem !important;
  }

  .lg\:builder--top-10{
    top: -2.5rem !important;
  }

  .lg\:builder--top-11{
    top: -2.75rem !important;
  }

  .lg\:builder--top-12{
    top: -3rem !important;
  }

  .lg\:builder--top-14{
    top: -3.5rem !important;
  }

  .lg\:builder--top-16{
    top: -4rem !important;
  }

  .lg\:builder--top-20{
    top: -5rem !important;
  }

  .lg\:builder--top-24{
    top: -6rem !important;
  }

  .lg\:builder--top-28{
    top: -7rem !important;
  }

  .lg\:builder--top-32{
    top: -8rem !important;
  }

  .lg\:builder--top-36{
    top: -9rem !important;
  }

  .lg\:builder--top-40{
    top: -10rem !important;
  }

  .lg\:builder--top-44{
    top: -11rem !important;
  }

  .lg\:builder--top-48{
    top: -12rem !important;
  }

  .lg\:builder--top-52{
    top: -13rem !important;
  }

  .lg\:builder--top-56{
    top: -14rem !important;
  }

  .lg\:builder--top-60{
    top: -15rem !important;
  }

  .lg\:builder--top-64{
    top: -16rem !important;
  }

  .lg\:builder--top-72{
    top: -18rem !important;
  }

  .lg\:builder--top-80{
    top: -20rem !important;
  }

  .lg\:builder--top-96{
    top: -24rem !important;
  }

  .lg\:builder--top-px{
    top: -1px !important;
  }

  .lg\:builder--top-0\.5{
    top: -0.125rem !important;
  }

  .lg\:builder--top-1\.5{
    top: -0.375rem !important;
  }

  .lg\:builder--top-2\.5{
    top: -0.625rem !important;
  }

  .lg\:builder--top-3\.5{
    top: -0.875rem !important;
  }

  .lg\:builder-top-1\/2{
    top: 50% !important;
  }

  .lg\:builder-top-1\/3{
    top: 33.333333% !important;
  }

  .lg\:builder-top-2\/3{
    top: 66.666667% !important;
  }

  .lg\:builder-top-1\/4{
    top: 25% !important;
  }

  .lg\:builder-top-2\/4{
    top: 50% !important;
  }

  .lg\:builder-top-3\/4{
    top: 75% !important;
  }

  .lg\:builder-top-full{
    top: 100% !important;
  }

  .lg\:builder--top-1\/2{
    top: -50% !important;
  }

  .lg\:builder--top-1\/3{
    top: -33.333333% !important;
  }

  .lg\:builder--top-2\/3{
    top: -66.666667% !important;
  }

  .lg\:builder--top-1\/4{
    top: -25% !important;
  }

  .lg\:builder--top-2\/4{
    top: -50% !important;
  }

  .lg\:builder--top-3\/4{
    top: -75% !important;
  }

  .lg\:builder--top-full{
    top: -100% !important;
  }

  .lg\:builder-right-0{
    right: 0px !important;
  }

  .lg\:builder-right-1{
    right: 0.25rem !important;
  }

  .lg\:builder-right-2{
    right: 0.5rem !important;
  }

  .lg\:builder-right-3{
    right: 0.75rem !important;
  }

  .lg\:builder-right-4{
    right: 1rem !important;
  }

  .lg\:builder-right-5{
    right: 1.25rem !important;
  }

  .lg\:builder-right-6{
    right: 1.5rem !important;
  }

  .lg\:builder-right-7{
    right: 1.75rem !important;
  }

  .lg\:builder-right-8{
    right: 2rem !important;
  }

  .lg\:builder-right-9{
    right: 2.25rem !important;
  }

  .lg\:builder-right-10{
    right: 2.5rem !important;
  }

  .lg\:builder-right-11{
    right: 2.75rem !important;
  }

  .lg\:builder-right-12{
    right: 3rem !important;
  }

  .lg\:builder-right-14{
    right: 3.5rem !important;
  }

  .lg\:builder-right-16{
    right: 4rem !important;
  }

  .lg\:builder-right-20{
    right: 5rem !important;
  }

  .lg\:builder-right-24{
    right: 6rem !important;
  }

  .lg\:builder-right-28{
    right: 7rem !important;
  }

  .lg\:builder-right-32{
    right: 8rem !important;
  }

  .lg\:builder-right-36{
    right: 9rem !important;
  }

  .lg\:builder-right-40{
    right: 10rem !important;
  }

  .lg\:builder-right-44{
    right: 11rem !important;
  }

  .lg\:builder-right-48{
    right: 12rem !important;
  }

  .lg\:builder-right-52{
    right: 13rem !important;
  }

  .lg\:builder-right-56{
    right: 14rem !important;
  }

  .lg\:builder-right-60{
    right: 15rem !important;
  }

  .lg\:builder-right-64{
    right: 16rem !important;
  }

  .lg\:builder-right-72{
    right: 18rem !important;
  }

  .lg\:builder-right-80{
    right: 20rem !important;
  }

  .lg\:builder-right-96{
    right: 24rem !important;
  }

  .lg\:builder-right-auto{
    right: auto !important;
  }

  .lg\:builder-right-px{
    right: 1px !important;
  }

  .lg\:builder-right-0\.5{
    right: 0.125rem !important;
  }

  .lg\:builder-right-1\.5{
    right: 0.375rem !important;
  }

  .lg\:builder-right-2\.5{
    right: 0.625rem !important;
  }

  .lg\:builder-right-3\.5{
    right: 0.875rem !important;
  }

  .lg\:builder--right-0{
    right: 0px !important;
  }

  .lg\:builder--right-1{
    right: -0.25rem !important;
  }

  .lg\:builder--right-2{
    right: -0.5rem !important;
  }

  .lg\:builder--right-3{
    right: -0.75rem !important;
  }

  .lg\:builder--right-4{
    right: -1rem !important;
  }

  .lg\:builder--right-5{
    right: -1.25rem !important;
  }

  .lg\:builder--right-6{
    right: -1.5rem !important;
  }

  .lg\:builder--right-7{
    right: -1.75rem !important;
  }

  .lg\:builder--right-8{
    right: -2rem !important;
  }

  .lg\:builder--right-9{
    right: -2.25rem !important;
  }

  .lg\:builder--right-10{
    right: -2.5rem !important;
  }

  .lg\:builder--right-11{
    right: -2.75rem !important;
  }

  .lg\:builder--right-12{
    right: -3rem !important;
  }

  .lg\:builder--right-14{
    right: -3.5rem !important;
  }

  .lg\:builder--right-16{
    right: -4rem !important;
  }

  .lg\:builder--right-20{
    right: -5rem !important;
  }

  .lg\:builder--right-24{
    right: -6rem !important;
  }

  .lg\:builder--right-28{
    right: -7rem !important;
  }

  .lg\:builder--right-32{
    right: -8rem !important;
  }

  .lg\:builder--right-36{
    right: -9rem !important;
  }

  .lg\:builder--right-40{
    right: -10rem !important;
  }

  .lg\:builder--right-44{
    right: -11rem !important;
  }

  .lg\:builder--right-48{
    right: -12rem !important;
  }

  .lg\:builder--right-52{
    right: -13rem !important;
  }

  .lg\:builder--right-56{
    right: -14rem !important;
  }

  .lg\:builder--right-60{
    right: -15rem !important;
  }

  .lg\:builder--right-64{
    right: -16rem !important;
  }

  .lg\:builder--right-72{
    right: -18rem !important;
  }

  .lg\:builder--right-80{
    right: -20rem !important;
  }

  .lg\:builder--right-96{
    right: -24rem !important;
  }

  .lg\:builder--right-px{
    right: -1px !important;
  }

  .lg\:builder--right-0\.5{
    right: -0.125rem !important;
  }

  .lg\:builder--right-1\.5{
    right: -0.375rem !important;
  }

  .lg\:builder--right-2\.5{
    right: -0.625rem !important;
  }

  .lg\:builder--right-3\.5{
    right: -0.875rem !important;
  }

  .lg\:builder-right-1\/2{
    right: 50% !important;
  }

  .lg\:builder-right-1\/3{
    right: 33.333333% !important;
  }

  .lg\:builder-right-2\/3{
    right: 66.666667% !important;
  }

  .lg\:builder-right-1\/4{
    right: 25% !important;
  }

  .lg\:builder-right-2\/4{
    right: 50% !important;
  }

  .lg\:builder-right-3\/4{
    right: 75% !important;
  }

  .lg\:builder-right-full{
    right: 100% !important;
  }

  .lg\:builder--right-1\/2{
    right: -50% !important;
  }

  .lg\:builder--right-1\/3{
    right: -33.333333% !important;
  }

  .lg\:builder--right-2\/3{
    right: -66.666667% !important;
  }

  .lg\:builder--right-1\/4{
    right: -25% !important;
  }

  .lg\:builder--right-2\/4{
    right: -50% !important;
  }

  .lg\:builder--right-3\/4{
    right: -75% !important;
  }

  .lg\:builder--right-full{
    right: -100% !important;
  }

  .lg\:builder-bottom-0{
    bottom: 0px !important;
  }

  .lg\:builder-bottom-1{
    bottom: 0.25rem !important;
  }

  .lg\:builder-bottom-2{
    bottom: 0.5rem !important;
  }

  .lg\:builder-bottom-3{
    bottom: 0.75rem !important;
  }

  .lg\:builder-bottom-4{
    bottom: 1rem !important;
  }

  .lg\:builder-bottom-5{
    bottom: 1.25rem !important;
  }

  .lg\:builder-bottom-6{
    bottom: 1.5rem !important;
  }

  .lg\:builder-bottom-7{
    bottom: 1.75rem !important;
  }

  .lg\:builder-bottom-8{
    bottom: 2rem !important;
  }

  .lg\:builder-bottom-9{
    bottom: 2.25rem !important;
  }

  .lg\:builder-bottom-10{
    bottom: 2.5rem !important;
  }

  .lg\:builder-bottom-11{
    bottom: 2.75rem !important;
  }

  .lg\:builder-bottom-12{
    bottom: 3rem !important;
  }

  .lg\:builder-bottom-14{
    bottom: 3.5rem !important;
  }

  .lg\:builder-bottom-16{
    bottom: 4rem !important;
  }

  .lg\:builder-bottom-20{
    bottom: 5rem !important;
  }

  .lg\:builder-bottom-24{
    bottom: 6rem !important;
  }

  .lg\:builder-bottom-28{
    bottom: 7rem !important;
  }

  .lg\:builder-bottom-32{
    bottom: 8rem !important;
  }

  .lg\:builder-bottom-36{
    bottom: 9rem !important;
  }

  .lg\:builder-bottom-40{
    bottom: 10rem !important;
  }

  .lg\:builder-bottom-44{
    bottom: 11rem !important;
  }

  .lg\:builder-bottom-48{
    bottom: 12rem !important;
  }

  .lg\:builder-bottom-52{
    bottom: 13rem !important;
  }

  .lg\:builder-bottom-56{
    bottom: 14rem !important;
  }

  .lg\:builder-bottom-60{
    bottom: 15rem !important;
  }

  .lg\:builder-bottom-64{
    bottom: 16rem !important;
  }

  .lg\:builder-bottom-72{
    bottom: 18rem !important;
  }

  .lg\:builder-bottom-80{
    bottom: 20rem !important;
  }

  .lg\:builder-bottom-96{
    bottom: 24rem !important;
  }

  .lg\:builder-bottom-auto{
    bottom: auto !important;
  }

  .lg\:builder-bottom-px{
    bottom: 1px !important;
  }

  .lg\:builder-bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .lg\:builder-bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .lg\:builder-bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .lg\:builder-bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .lg\:builder--bottom-0{
    bottom: 0px !important;
  }

  .lg\:builder--bottom-1{
    bottom: -0.25rem !important;
  }

  .lg\:builder--bottom-2{
    bottom: -0.5rem !important;
  }

  .lg\:builder--bottom-3{
    bottom: -0.75rem !important;
  }

  .lg\:builder--bottom-4{
    bottom: -1rem !important;
  }

  .lg\:builder--bottom-5{
    bottom: -1.25rem !important;
  }

  .lg\:builder--bottom-6{
    bottom: -1.5rem !important;
  }

  .lg\:builder--bottom-7{
    bottom: -1.75rem !important;
  }

  .lg\:builder--bottom-8{
    bottom: -2rem !important;
  }

  .lg\:builder--bottom-9{
    bottom: -2.25rem !important;
  }

  .lg\:builder--bottom-10{
    bottom: -2.5rem !important;
  }

  .lg\:builder--bottom-11{
    bottom: -2.75rem !important;
  }

  .lg\:builder--bottom-12{
    bottom: -3rem !important;
  }

  .lg\:builder--bottom-14{
    bottom: -3.5rem !important;
  }

  .lg\:builder--bottom-16{
    bottom: -4rem !important;
  }

  .lg\:builder--bottom-20{
    bottom: -5rem !important;
  }

  .lg\:builder--bottom-24{
    bottom: -6rem !important;
  }

  .lg\:builder--bottom-28{
    bottom: -7rem !important;
  }

  .lg\:builder--bottom-32{
    bottom: -8rem !important;
  }

  .lg\:builder--bottom-36{
    bottom: -9rem !important;
  }

  .lg\:builder--bottom-40{
    bottom: -10rem !important;
  }

  .lg\:builder--bottom-44{
    bottom: -11rem !important;
  }

  .lg\:builder--bottom-48{
    bottom: -12rem !important;
  }

  .lg\:builder--bottom-52{
    bottom: -13rem !important;
  }

  .lg\:builder--bottom-56{
    bottom: -14rem !important;
  }

  .lg\:builder--bottom-60{
    bottom: -15rem !important;
  }

  .lg\:builder--bottom-64{
    bottom: -16rem !important;
  }

  .lg\:builder--bottom-72{
    bottom: -18rem !important;
  }

  .lg\:builder--bottom-80{
    bottom: -20rem !important;
  }

  .lg\:builder--bottom-96{
    bottom: -24rem !important;
  }

  .lg\:builder--bottom-px{
    bottom: -1px !important;
  }

  .lg\:builder--bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .lg\:builder--bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .lg\:builder--bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .lg\:builder--bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .lg\:builder-bottom-1\/2{
    bottom: 50% !important;
  }

  .lg\:builder-bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .lg\:builder-bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .lg\:builder-bottom-1\/4{
    bottom: 25% !important;
  }

  .lg\:builder-bottom-2\/4{
    bottom: 50% !important;
  }

  .lg\:builder-bottom-3\/4{
    bottom: 75% !important;
  }

  .lg\:builder-bottom-full{
    bottom: 100% !important;
  }

  .lg\:builder--bottom-1\/2{
    bottom: -50% !important;
  }

  .lg\:builder--bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .lg\:builder--bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .lg\:builder--bottom-1\/4{
    bottom: -25% !important;
  }

  .lg\:builder--bottom-2\/4{
    bottom: -50% !important;
  }

  .lg\:builder--bottom-3\/4{
    bottom: -75% !important;
  }

  .lg\:builder--bottom-full{
    bottom: -100% !important;
  }

  .lg\:builder-left-0{
    left: 0px !important;
  }

  .lg\:builder-left-1{
    left: 0.25rem !important;
  }

  .lg\:builder-left-2{
    left: 0.5rem !important;
  }

  .lg\:builder-left-3{
    left: 0.75rem !important;
  }

  .lg\:builder-left-4{
    left: 1rem !important;
  }

  .lg\:builder-left-5{
    left: 1.25rem !important;
  }

  .lg\:builder-left-6{
    left: 1.5rem !important;
  }

  .lg\:builder-left-7{
    left: 1.75rem !important;
  }

  .lg\:builder-left-8{
    left: 2rem !important;
  }

  .lg\:builder-left-9{
    left: 2.25rem !important;
  }

  .lg\:builder-left-10{
    left: 2.5rem !important;
  }

  .lg\:builder-left-11{
    left: 2.75rem !important;
  }

  .lg\:builder-left-12{
    left: 3rem !important;
  }

  .lg\:builder-left-14{
    left: 3.5rem !important;
  }

  .lg\:builder-left-16{
    left: 4rem !important;
  }

  .lg\:builder-left-20{
    left: 5rem !important;
  }

  .lg\:builder-left-24{
    left: 6rem !important;
  }

  .lg\:builder-left-28{
    left: 7rem !important;
  }

  .lg\:builder-left-32{
    left: 8rem !important;
  }

  .lg\:builder-left-36{
    left: 9rem !important;
  }

  .lg\:builder-left-40{
    left: 10rem !important;
  }

  .lg\:builder-left-44{
    left: 11rem !important;
  }

  .lg\:builder-left-48{
    left: 12rem !important;
  }

  .lg\:builder-left-52{
    left: 13rem !important;
  }

  .lg\:builder-left-56{
    left: 14rem !important;
  }

  .lg\:builder-left-60{
    left: 15rem !important;
  }

  .lg\:builder-left-64{
    left: 16rem !important;
  }

  .lg\:builder-left-72{
    left: 18rem !important;
  }

  .lg\:builder-left-80{
    left: 20rem !important;
  }

  .lg\:builder-left-96{
    left: 24rem !important;
  }

  .lg\:builder-left-auto{
    left: auto !important;
  }

  .lg\:builder-left-px{
    left: 1px !important;
  }

  .lg\:builder-left-0\.5{
    left: 0.125rem !important;
  }

  .lg\:builder-left-1\.5{
    left: 0.375rem !important;
  }

  .lg\:builder-left-2\.5{
    left: 0.625rem !important;
  }

  .lg\:builder-left-3\.5{
    left: 0.875rem !important;
  }

  .lg\:builder--left-0{
    left: 0px !important;
  }

  .lg\:builder--left-1{
    left: -0.25rem !important;
  }

  .lg\:builder--left-2{
    left: -0.5rem !important;
  }

  .lg\:builder--left-3{
    left: -0.75rem !important;
  }

  .lg\:builder--left-4{
    left: -1rem !important;
  }

  .lg\:builder--left-5{
    left: -1.25rem !important;
  }

  .lg\:builder--left-6{
    left: -1.5rem !important;
  }

  .lg\:builder--left-7{
    left: -1.75rem !important;
  }

  .lg\:builder--left-8{
    left: -2rem !important;
  }

  .lg\:builder--left-9{
    left: -2.25rem !important;
  }

  .lg\:builder--left-10{
    left: -2.5rem !important;
  }

  .lg\:builder--left-11{
    left: -2.75rem !important;
  }

  .lg\:builder--left-12{
    left: -3rem !important;
  }

  .lg\:builder--left-14{
    left: -3.5rem !important;
  }

  .lg\:builder--left-16{
    left: -4rem !important;
  }

  .lg\:builder--left-20{
    left: -5rem !important;
  }

  .lg\:builder--left-24{
    left: -6rem !important;
  }

  .lg\:builder--left-28{
    left: -7rem !important;
  }

  .lg\:builder--left-32{
    left: -8rem !important;
  }

  .lg\:builder--left-36{
    left: -9rem !important;
  }

  .lg\:builder--left-40{
    left: -10rem !important;
  }

  .lg\:builder--left-44{
    left: -11rem !important;
  }

  .lg\:builder--left-48{
    left: -12rem !important;
  }

  .lg\:builder--left-52{
    left: -13rem !important;
  }

  .lg\:builder--left-56{
    left: -14rem !important;
  }

  .lg\:builder--left-60{
    left: -15rem !important;
  }

  .lg\:builder--left-64{
    left: -16rem !important;
  }

  .lg\:builder--left-72{
    left: -18rem !important;
  }

  .lg\:builder--left-80{
    left: -20rem !important;
  }

  .lg\:builder--left-96{
    left: -24rem !important;
  }

  .lg\:builder--left-px{
    left: -1px !important;
  }

  .lg\:builder--left-0\.5{
    left: -0.125rem !important;
  }

  .lg\:builder--left-1\.5{
    left: -0.375rem !important;
  }

  .lg\:builder--left-2\.5{
    left: -0.625rem !important;
  }

  .lg\:builder--left-3\.5{
    left: -0.875rem !important;
  }

  .lg\:builder-left-1\/2{
    left: 50% !important;
  }

  .lg\:builder-left-1\/3{
    left: 33.333333% !important;
  }

  .lg\:builder-left-2\/3{
    left: 66.666667% !important;
  }

  .lg\:builder-left-1\/4{
    left: 25% !important;
  }

  .lg\:builder-left-2\/4{
    left: 50% !important;
  }

  .lg\:builder-left-3\/4{
    left: 75% !important;
  }

  .lg\:builder-left-full{
    left: 100% !important;
  }

  .lg\:builder--left-1\/2{
    left: -50% !important;
  }

  .lg\:builder--left-1\/3{
    left: -33.333333% !important;
  }

  .lg\:builder--left-2\/3{
    left: -66.666667% !important;
  }

  .lg\:builder--left-1\/4{
    left: -25% !important;
  }

  .lg\:builder--left-2\/4{
    left: -50% !important;
  }

  .lg\:builder--left-3\/4{
    left: -75% !important;
  }

  .lg\:builder--left-full{
    left: -100% !important;
  }

  .lg\:builder-isolate{
    isolation: isolate !important;
  }

  .lg\:builder-isolation-auto{
    isolation: auto !important;
  }

  .lg\:builder-z-0{
    z-index: 0 !important;
  }

  .lg\:builder-z-10{
    z-index: 10 !important;
  }

  .lg\:builder-z-20{
    z-index: 20 !important;
  }

  .lg\:builder-z-30{
    z-index: 30 !important;
  }

  .lg\:builder-z-40{
    z-index: 40 !important;
  }

  .lg\:builder-z-50{
    z-index: 50 !important;
  }

  .lg\:builder-z-auto{
    z-index: auto !important;
  }

  .lg\:focus-within\:builder-z-0:focus-within{
    z-index: 0 !important;
  }

  .lg\:focus-within\:builder-z-10:focus-within{
    z-index: 10 !important;
  }

  .lg\:focus-within\:builder-z-20:focus-within{
    z-index: 20 !important;
  }

  .lg\:focus-within\:builder-z-30:focus-within{
    z-index: 30 !important;
  }

  .lg\:focus-within\:builder-z-40:focus-within{
    z-index: 40 !important;
  }

  .lg\:focus-within\:builder-z-50:focus-within{
    z-index: 50 !important;
  }

  .lg\:focus-within\:builder-z-auto:focus-within{
    z-index: auto !important;
  }

  .lg\:focus\:builder-z-0:focus{
    z-index: 0 !important;
  }

  .lg\:focus\:builder-z-10:focus{
    z-index: 10 !important;
  }

  .lg\:focus\:builder-z-20:focus{
    z-index: 20 !important;
  }

  .lg\:focus\:builder-z-30:focus{
    z-index: 30 !important;
  }

  .lg\:focus\:builder-z-40:focus{
    z-index: 40 !important;
  }

  .lg\:focus\:builder-z-50:focus{
    z-index: 50 !important;
  }

  .lg\:focus\:builder-z-auto:focus{
    z-index: auto !important;
  }

  .lg\:builder-order-1{
    order: 1 !important;
  }

  .lg\:builder-order-2{
    order: 2 !important;
  }

  .lg\:builder-order-3{
    order: 3 !important;
  }

  .lg\:builder-order-4{
    order: 4 !important;
  }

  .lg\:builder-order-5{
    order: 5 !important;
  }

  .lg\:builder-order-6{
    order: 6 !important;
  }

  .lg\:builder-order-7{
    order: 7 !important;
  }

  .lg\:builder-order-8{
    order: 8 !important;
  }

  .lg\:builder-order-9{
    order: 9 !important;
  }

  .lg\:builder-order-10{
    order: 10 !important;
  }

  .lg\:builder-order-11{
    order: 11 !important;
  }

  .lg\:builder-order-12{
    order: 12 !important;
  }

  .lg\:builder-order-first{
    order: -9999 !important;
  }

  .lg\:builder-order-last{
    order: 9999 !important;
  }

  .lg\:builder-order-none{
    order: 0 !important;
  }

  .lg\:builder-col-auto{
    grid-column: auto !important;
  }

  .lg\:builder-col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .lg\:builder-col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .lg\:builder-col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .lg\:builder-col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .lg\:builder-col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .lg\:builder-col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .lg\:builder-col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .lg\:builder-col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .lg\:builder-col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .lg\:builder-col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .lg\:builder-col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .lg\:builder-col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .lg\:builder-col-span-full{
    grid-column: 1 / -1 !important;
  }

  .lg\:builder-col-start-1{
    grid-column-start: 1 !important;
  }

  .lg\:builder-col-start-2{
    grid-column-start: 2 !important;
  }

  .lg\:builder-col-start-3{
    grid-column-start: 3 !important;
  }

  .lg\:builder-col-start-4{
    grid-column-start: 4 !important;
  }

  .lg\:builder-col-start-5{
    grid-column-start: 5 !important;
  }

  .lg\:builder-col-start-6{
    grid-column-start: 6 !important;
  }

  .lg\:builder-col-start-7{
    grid-column-start: 7 !important;
  }

  .lg\:builder-col-start-8{
    grid-column-start: 8 !important;
  }

  .lg\:builder-col-start-9{
    grid-column-start: 9 !important;
  }

  .lg\:builder-col-start-10{
    grid-column-start: 10 !important;
  }

  .lg\:builder-col-start-11{
    grid-column-start: 11 !important;
  }

  .lg\:builder-col-start-12{
    grid-column-start: 12 !important;
  }

  .lg\:builder-col-start-13{
    grid-column-start: 13 !important;
  }

  .lg\:builder-col-start-auto{
    grid-column-start: auto !important;
  }

  .lg\:builder-col-end-1{
    grid-column-end: 1 !important;
  }

  .lg\:builder-col-end-2{
    grid-column-end: 2 !important;
  }

  .lg\:builder-col-end-3{
    grid-column-end: 3 !important;
  }

  .lg\:builder-col-end-4{
    grid-column-end: 4 !important;
  }

  .lg\:builder-col-end-5{
    grid-column-end: 5 !important;
  }

  .lg\:builder-col-end-6{
    grid-column-end: 6 !important;
  }

  .lg\:builder-col-end-7{
    grid-column-end: 7 !important;
  }

  .lg\:builder-col-end-8{
    grid-column-end: 8 !important;
  }

  .lg\:builder-col-end-9{
    grid-column-end: 9 !important;
  }

  .lg\:builder-col-end-10{
    grid-column-end: 10 !important;
  }

  .lg\:builder-col-end-11{
    grid-column-end: 11 !important;
  }

  .lg\:builder-col-end-12{
    grid-column-end: 12 !important;
  }

  .lg\:builder-col-end-13{
    grid-column-end: 13 !important;
  }

  .lg\:builder-col-end-auto{
    grid-column-end: auto !important;
  }

  .lg\:builder-row-auto{
    grid-row: auto !important;
  }

  .lg\:builder-row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .lg\:builder-row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .lg\:builder-row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .lg\:builder-row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .lg\:builder-row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .lg\:builder-row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .lg\:builder-row-span-full{
    grid-row: 1 / -1 !important;
  }

  .lg\:builder-row-start-1{
    grid-row-start: 1 !important;
  }

  .lg\:builder-row-start-2{
    grid-row-start: 2 !important;
  }

  .lg\:builder-row-start-3{
    grid-row-start: 3 !important;
  }

  .lg\:builder-row-start-4{
    grid-row-start: 4 !important;
  }

  .lg\:builder-row-start-5{
    grid-row-start: 5 !important;
  }

  .lg\:builder-row-start-6{
    grid-row-start: 6 !important;
  }

  .lg\:builder-row-start-7{
    grid-row-start: 7 !important;
  }

  .lg\:builder-row-start-auto{
    grid-row-start: auto !important;
  }

  .lg\:builder-row-end-1{
    grid-row-end: 1 !important;
  }

  .lg\:builder-row-end-2{
    grid-row-end: 2 !important;
  }

  .lg\:builder-row-end-3{
    grid-row-end: 3 !important;
  }

  .lg\:builder-row-end-4{
    grid-row-end: 4 !important;
  }

  .lg\:builder-row-end-5{
    grid-row-end: 5 !important;
  }

  .lg\:builder-row-end-6{
    grid-row-end: 6 !important;
  }

  .lg\:builder-row-end-7{
    grid-row-end: 7 !important;
  }

  .lg\:builder-row-end-auto{
    grid-row-end: auto !important;
  }

  .lg\:builder-float-right{
    float: right !important;
  }

  .lg\:builder-float-left{
    float: left !important;
  }

  .lg\:builder-float-none{
    float: none !important;
  }

  .lg\:builder-clear-left{
    clear: left !important;
  }

  .lg\:builder-clear-right{
    clear: right !important;
  }

  .lg\:builder-clear-both{
    clear: both !important;
  }

  .lg\:builder-clear-none{
    clear: none !important;
  }

  .lg\:builder-m-0{
    margin: 0px !important;
  }

  .lg\:builder-m-1{
    margin: 0.25rem !important;
  }

  .lg\:builder-m-2{
    margin: 0.5rem !important;
  }

  .lg\:builder-m-3{
    margin: 0.75rem !important;
  }

  .lg\:builder-m-4{
    margin: 1rem !important;
  }

  .lg\:builder-m-5{
    margin: 1.25rem !important;
  }

  .lg\:builder-m-6{
    margin: 1.5rem !important;
  }

  .lg\:builder-m-7{
    margin: 1.75rem !important;
  }

  .lg\:builder-m-8{
    margin: 2rem !important;
  }

  .lg\:builder-m-9{
    margin: 2.25rem !important;
  }

  .lg\:builder-m-10{
    margin: 2.5rem !important;
  }

  .lg\:builder-m-11{
    margin: 2.75rem !important;
  }

  .lg\:builder-m-12{
    margin: 3rem !important;
  }

  .lg\:builder-m-14{
    margin: 3.5rem !important;
  }

  .lg\:builder-m-16{
    margin: 4rem !important;
  }

  .lg\:builder-m-20{
    margin: 5rem !important;
  }

  .lg\:builder-m-24{
    margin: 6rem !important;
  }

  .lg\:builder-m-28{
    margin: 7rem !important;
  }

  .lg\:builder-m-32{
    margin: 8rem !important;
  }

  .lg\:builder-m-36{
    margin: 9rem !important;
  }

  .lg\:builder-m-40{
    margin: 10rem !important;
  }

  .lg\:builder-m-44{
    margin: 11rem !important;
  }

  .lg\:builder-m-48{
    margin: 12rem !important;
  }

  .lg\:builder-m-52{
    margin: 13rem !important;
  }

  .lg\:builder-m-56{
    margin: 14rem !important;
  }

  .lg\:builder-m-60{
    margin: 15rem !important;
  }

  .lg\:builder-m-64{
    margin: 16rem !important;
  }

  .lg\:builder-m-72{
    margin: 18rem !important;
  }

  .lg\:builder-m-80{
    margin: 20rem !important;
  }

  .lg\:builder-m-96{
    margin: 24rem !important;
  }

  .lg\:builder-m-auto{
    margin: auto !important;
  }

  .lg\:builder-m-px{
    margin: 1px !important;
  }

  .lg\:builder-m-0\.5{
    margin: 0.125rem !important;
  }

  .lg\:builder-m-1\.5{
    margin: 0.375rem !important;
  }

  .lg\:builder-m-2\.5{
    margin: 0.625rem !important;
  }

  .lg\:builder-m-3\.5{
    margin: 0.875rem !important;
  }

  .lg\:builder--m-0{
    margin: 0px !important;
  }

  .lg\:builder--m-1{
    margin: -0.25rem !important;
  }

  .lg\:builder--m-2{
    margin: -0.5rem !important;
  }

  .lg\:builder--m-3{
    margin: -0.75rem !important;
  }

  .lg\:builder--m-4{
    margin: -1rem !important;
  }

  .lg\:builder--m-5{
    margin: -1.25rem !important;
  }

  .lg\:builder--m-6{
    margin: -1.5rem !important;
  }

  .lg\:builder--m-7{
    margin: -1.75rem !important;
  }

  .lg\:builder--m-8{
    margin: -2rem !important;
  }

  .lg\:builder--m-9{
    margin: -2.25rem !important;
  }

  .lg\:builder--m-10{
    margin: -2.5rem !important;
  }

  .lg\:builder--m-11{
    margin: -2.75rem !important;
  }

  .lg\:builder--m-12{
    margin: -3rem !important;
  }

  .lg\:builder--m-14{
    margin: -3.5rem !important;
  }

  .lg\:builder--m-16{
    margin: -4rem !important;
  }

  .lg\:builder--m-20{
    margin: -5rem !important;
  }

  .lg\:builder--m-24{
    margin: -6rem !important;
  }

  .lg\:builder--m-28{
    margin: -7rem !important;
  }

  .lg\:builder--m-32{
    margin: -8rem !important;
  }

  .lg\:builder--m-36{
    margin: -9rem !important;
  }

  .lg\:builder--m-40{
    margin: -10rem !important;
  }

  .lg\:builder--m-44{
    margin: -11rem !important;
  }

  .lg\:builder--m-48{
    margin: -12rem !important;
  }

  .lg\:builder--m-52{
    margin: -13rem !important;
  }

  .lg\:builder--m-56{
    margin: -14rem !important;
  }

  .lg\:builder--m-60{
    margin: -15rem !important;
  }

  .lg\:builder--m-64{
    margin: -16rem !important;
  }

  .lg\:builder--m-72{
    margin: -18rem !important;
  }

  .lg\:builder--m-80{
    margin: -20rem !important;
  }

  .lg\:builder--m-96{
    margin: -24rem !important;
  }

  .lg\:builder--m-px{
    margin: -1px !important;
  }

  .lg\:builder--m-0\.5{
    margin: -0.125rem !important;
  }

  .lg\:builder--m-1\.5{
    margin: -0.375rem !important;
  }

  .lg\:builder--m-2\.5{
    margin: -0.625rem !important;
  }

  .lg\:builder--m-3\.5{
    margin: -0.875rem !important;
  }

  .lg\:builder-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:builder-mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lg\:builder-mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lg\:builder-mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lg\:builder-mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:builder-mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lg\:builder-mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:builder-mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .lg\:builder-mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:builder-mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .lg\:builder-mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lg\:builder-mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .lg\:builder-mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:builder-mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .lg\:builder-mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:builder-mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:builder-mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lg\:builder-mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lg\:builder-mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lg\:builder-mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lg\:builder-mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lg\:builder-mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .lg\:builder-mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lg\:builder-mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .lg\:builder-mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lg\:builder-mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .lg\:builder-mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lg\:builder-mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .lg\:builder-mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .lg\:builder-mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .lg\:builder-mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:builder-mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lg\:builder-mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .lg\:builder-mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .lg\:builder-mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .lg\:builder-mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .lg\:builder--mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:builder--mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lg\:builder--mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lg\:builder--mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lg\:builder--mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:builder--mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lg\:builder--mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:builder--mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .lg\:builder--mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:builder--mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .lg\:builder--mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lg\:builder--mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .lg\:builder--mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:builder--mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .lg\:builder--mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:builder--mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:builder--mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lg\:builder--mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lg\:builder--mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lg\:builder--mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lg\:builder--mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lg\:builder--mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .lg\:builder--mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lg\:builder--mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .lg\:builder--mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lg\:builder--mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .lg\:builder--mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lg\:builder--mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .lg\:builder--mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .lg\:builder--mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .lg\:builder--mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lg\:builder--mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .lg\:builder--mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .lg\:builder--mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .lg\:builder--mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .lg\:builder-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg\:builder-my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lg\:builder-my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lg\:builder-my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lg\:builder-my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:builder-my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lg\:builder-my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:builder-my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .lg\:builder-my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:builder-my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .lg\:builder-my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lg\:builder-my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .lg\:builder-my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:builder-my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .lg\:builder-my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:builder-my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:builder-my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lg\:builder-my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lg\:builder-my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lg\:builder-my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lg\:builder-my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lg\:builder-my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .lg\:builder-my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lg\:builder-my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .lg\:builder-my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lg\:builder-my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .lg\:builder-my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lg\:builder-my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .lg\:builder-my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .lg\:builder-my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .lg\:builder-my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lg\:builder-my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lg\:builder-my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .lg\:builder-my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .lg\:builder-my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .lg\:builder-my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .lg\:builder--my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg\:builder--my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lg\:builder--my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lg\:builder--my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lg\:builder--my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:builder--my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lg\:builder--my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:builder--my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .lg\:builder--my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:builder--my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .lg\:builder--my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lg\:builder--my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .lg\:builder--my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:builder--my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .lg\:builder--my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:builder--my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:builder--my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lg\:builder--my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .lg\:builder--my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lg\:builder--my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .lg\:builder--my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lg\:builder--my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .lg\:builder--my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lg\:builder--my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .lg\:builder--my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lg\:builder--my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .lg\:builder--my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lg\:builder--my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .lg\:builder--my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .lg\:builder--my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .lg\:builder--my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lg\:builder--my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .lg\:builder--my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .lg\:builder--my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .lg\:builder--my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .lg\:builder-mt-0{
    margin-top: 0px !important;
  }

  .lg\:builder-mt-1{
    margin-top: 0.25rem !important;
  }

  .lg\:builder-mt-2{
    margin-top: 0.5rem !important;
  }

  .lg\:builder-mt-3{
    margin-top: 0.75rem !important;
  }

  .lg\:builder-mt-4{
    margin-top: 1rem !important;
  }

  .lg\:builder-mt-5{
    margin-top: 1.25rem !important;
  }

  .lg\:builder-mt-6{
    margin-top: 1.5rem !important;
  }

  .lg\:builder-mt-7{
    margin-top: 1.75rem !important;
  }

  .lg\:builder-mt-8{
    margin-top: 2rem !important;
  }

  .lg\:builder-mt-9{
    margin-top: 2.25rem !important;
  }

  .lg\:builder-mt-10{
    margin-top: 2.5rem !important;
  }

  .lg\:builder-mt-11{
    margin-top: 2.75rem !important;
  }

  .lg\:builder-mt-12{
    margin-top: 3rem !important;
  }

  .lg\:builder-mt-14{
    margin-top: 3.5rem !important;
  }

  .lg\:builder-mt-16{
    margin-top: 4rem !important;
  }

  .lg\:builder-mt-20{
    margin-top: 5rem !important;
  }

  .lg\:builder-mt-24{
    margin-top: 6rem !important;
  }

  .lg\:builder-mt-28{
    margin-top: 7rem !important;
  }

  .lg\:builder-mt-32{
    margin-top: 8rem !important;
  }

  .lg\:builder-mt-36{
    margin-top: 9rem !important;
  }

  .lg\:builder-mt-40{
    margin-top: 10rem !important;
  }

  .lg\:builder-mt-44{
    margin-top: 11rem !important;
  }

  .lg\:builder-mt-48{
    margin-top: 12rem !important;
  }

  .lg\:builder-mt-52{
    margin-top: 13rem !important;
  }

  .lg\:builder-mt-56{
    margin-top: 14rem !important;
  }

  .lg\:builder-mt-60{
    margin-top: 15rem !important;
  }

  .lg\:builder-mt-64{
    margin-top: 16rem !important;
  }

  .lg\:builder-mt-72{
    margin-top: 18rem !important;
  }

  .lg\:builder-mt-80{
    margin-top: 20rem !important;
  }

  .lg\:builder-mt-96{
    margin-top: 24rem !important;
  }

  .lg\:builder-mt-auto{
    margin-top: auto !important;
  }

  .lg\:builder-mt-px{
    margin-top: 1px !important;
  }

  .lg\:builder-mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .lg\:builder-mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .lg\:builder-mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .lg\:builder-mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .lg\:builder--mt-0{
    margin-top: 0px !important;
  }

  .lg\:builder--mt-1{
    margin-top: -0.25rem !important;
  }

  .lg\:builder--mt-2{
    margin-top: -0.5rem !important;
  }

  .lg\:builder--mt-3{
    margin-top: -0.75rem !important;
  }

  .lg\:builder--mt-4{
    margin-top: -1rem !important;
  }

  .lg\:builder--mt-5{
    margin-top: -1.25rem !important;
  }

  .lg\:builder--mt-6{
    margin-top: -1.5rem !important;
  }

  .lg\:builder--mt-7{
    margin-top: -1.75rem !important;
  }

  .lg\:builder--mt-8{
    margin-top: -2rem !important;
  }

  .lg\:builder--mt-9{
    margin-top: -2.25rem !important;
  }

  .lg\:builder--mt-10{
    margin-top: -2.5rem !important;
  }

  .lg\:builder--mt-11{
    margin-top: -2.75rem !important;
  }

  .lg\:builder--mt-12{
    margin-top: -3rem !important;
  }

  .lg\:builder--mt-14{
    margin-top: -3.5rem !important;
  }

  .lg\:builder--mt-16{
    margin-top: -4rem !important;
  }

  .lg\:builder--mt-20{
    margin-top: -5rem !important;
  }

  .lg\:builder--mt-24{
    margin-top: -6rem !important;
  }

  .lg\:builder--mt-28{
    margin-top: -7rem !important;
  }

  .lg\:builder--mt-32{
    margin-top: -8rem !important;
  }

  .lg\:builder--mt-36{
    margin-top: -9rem !important;
  }

  .lg\:builder--mt-40{
    margin-top: -10rem !important;
  }

  .lg\:builder--mt-44{
    margin-top: -11rem !important;
  }

  .lg\:builder--mt-48{
    margin-top: -12rem !important;
  }

  .lg\:builder--mt-52{
    margin-top: -13rem !important;
  }

  .lg\:builder--mt-56{
    margin-top: -14rem !important;
  }

  .lg\:builder--mt-60{
    margin-top: -15rem !important;
  }

  .lg\:builder--mt-64{
    margin-top: -16rem !important;
  }

  .lg\:builder--mt-72{
    margin-top: -18rem !important;
  }

  .lg\:builder--mt-80{
    margin-top: -20rem !important;
  }

  .lg\:builder--mt-96{
    margin-top: -24rem !important;
  }

  .lg\:builder--mt-px{
    margin-top: -1px !important;
  }

  .lg\:builder--mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .lg\:builder--mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .lg\:builder--mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .lg\:builder--mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .lg\:builder-mr-0{
    margin-right: 0px !important;
  }

  .lg\:builder-mr-1{
    margin-right: 0.25rem !important;
  }

  .lg\:builder-mr-2{
    margin-right: 0.5rem !important;
  }

  .lg\:builder-mr-3{
    margin-right: 0.75rem !important;
  }

  .lg\:builder-mr-4{
    margin-right: 1rem !important;
  }

  .lg\:builder-mr-5{
    margin-right: 1.25rem !important;
  }

  .lg\:builder-mr-6{
    margin-right: 1.5rem !important;
  }

  .lg\:builder-mr-7{
    margin-right: 1.75rem !important;
  }

  .lg\:builder-mr-8{
    margin-right: 2rem !important;
  }

  .lg\:builder-mr-9{
    margin-right: 2.25rem !important;
  }

  .lg\:builder-mr-10{
    margin-right: 2.5rem !important;
  }

  .lg\:builder-mr-11{
    margin-right: 2.75rem !important;
  }

  .lg\:builder-mr-12{
    margin-right: 3rem !important;
  }

  .lg\:builder-mr-14{
    margin-right: 3.5rem !important;
  }

  .lg\:builder-mr-16{
    margin-right: 4rem !important;
  }

  .lg\:builder-mr-20{
    margin-right: 5rem !important;
  }

  .lg\:builder-mr-24{
    margin-right: 6rem !important;
  }

  .lg\:builder-mr-28{
    margin-right: 7rem !important;
  }

  .lg\:builder-mr-32{
    margin-right: 8rem !important;
  }

  .lg\:builder-mr-36{
    margin-right: 9rem !important;
  }

  .lg\:builder-mr-40{
    margin-right: 10rem !important;
  }

  .lg\:builder-mr-44{
    margin-right: 11rem !important;
  }

  .lg\:builder-mr-48{
    margin-right: 12rem !important;
  }

  .lg\:builder-mr-52{
    margin-right: 13rem !important;
  }

  .lg\:builder-mr-56{
    margin-right: 14rem !important;
  }

  .lg\:builder-mr-60{
    margin-right: 15rem !important;
  }

  .lg\:builder-mr-64{
    margin-right: 16rem !important;
  }

  .lg\:builder-mr-72{
    margin-right: 18rem !important;
  }

  .lg\:builder-mr-80{
    margin-right: 20rem !important;
  }

  .lg\:builder-mr-96{
    margin-right: 24rem !important;
  }

  .lg\:builder-mr-auto{
    margin-right: auto !important;
  }

  .lg\:builder-mr-px{
    margin-right: 1px !important;
  }

  .lg\:builder-mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .lg\:builder-mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .lg\:builder-mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .lg\:builder-mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .lg\:builder--mr-0{
    margin-right: 0px !important;
  }

  .lg\:builder--mr-1{
    margin-right: -0.25rem !important;
  }

  .lg\:builder--mr-2{
    margin-right: -0.5rem !important;
  }

  .lg\:builder--mr-3{
    margin-right: -0.75rem !important;
  }

  .lg\:builder--mr-4{
    margin-right: -1rem !important;
  }

  .lg\:builder--mr-5{
    margin-right: -1.25rem !important;
  }

  .lg\:builder--mr-6{
    margin-right: -1.5rem !important;
  }

  .lg\:builder--mr-7{
    margin-right: -1.75rem !important;
  }

  .lg\:builder--mr-8{
    margin-right: -2rem !important;
  }

  .lg\:builder--mr-9{
    margin-right: -2.25rem !important;
  }

  .lg\:builder--mr-10{
    margin-right: -2.5rem !important;
  }

  .lg\:builder--mr-11{
    margin-right: -2.75rem !important;
  }

  .lg\:builder--mr-12{
    margin-right: -3rem !important;
  }

  .lg\:builder--mr-14{
    margin-right: -3.5rem !important;
  }

  .lg\:builder--mr-16{
    margin-right: -4rem !important;
  }

  .lg\:builder--mr-20{
    margin-right: -5rem !important;
  }

  .lg\:builder--mr-24{
    margin-right: -6rem !important;
  }

  .lg\:builder--mr-28{
    margin-right: -7rem !important;
  }

  .lg\:builder--mr-32{
    margin-right: -8rem !important;
  }

  .lg\:builder--mr-36{
    margin-right: -9rem !important;
  }

  .lg\:builder--mr-40{
    margin-right: -10rem !important;
  }

  .lg\:builder--mr-44{
    margin-right: -11rem !important;
  }

  .lg\:builder--mr-48{
    margin-right: -12rem !important;
  }

  .lg\:builder--mr-52{
    margin-right: -13rem !important;
  }

  .lg\:builder--mr-56{
    margin-right: -14rem !important;
  }

  .lg\:builder--mr-60{
    margin-right: -15rem !important;
  }

  .lg\:builder--mr-64{
    margin-right: -16rem !important;
  }

  .lg\:builder--mr-72{
    margin-right: -18rem !important;
  }

  .lg\:builder--mr-80{
    margin-right: -20rem !important;
  }

  .lg\:builder--mr-96{
    margin-right: -24rem !important;
  }

  .lg\:builder--mr-px{
    margin-right: -1px !important;
  }

  .lg\:builder--mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .lg\:builder--mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .lg\:builder--mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .lg\:builder--mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .lg\:builder-mb-0{
    margin-bottom: 0px !important;
  }

  .lg\:builder-mb-1{
    margin-bottom: 0.25rem !important;
  }

  .lg\:builder-mb-2{
    margin-bottom: 0.5rem !important;
  }

  .lg\:builder-mb-3{
    margin-bottom: 0.75rem !important;
  }

  .lg\:builder-mb-4{
    margin-bottom: 1rem !important;
  }

  .lg\:builder-mb-5{
    margin-bottom: 1.25rem !important;
  }

  .lg\:builder-mb-6{
    margin-bottom: 1.5rem !important;
  }

  .lg\:builder-mb-7{
    margin-bottom: 1.75rem !important;
  }

  .lg\:builder-mb-8{
    margin-bottom: 2rem !important;
  }

  .lg\:builder-mb-9{
    margin-bottom: 2.25rem !important;
  }

  .lg\:builder-mb-10{
    margin-bottom: 2.5rem !important;
  }

  .lg\:builder-mb-11{
    margin-bottom: 2.75rem !important;
  }

  .lg\:builder-mb-12{
    margin-bottom: 3rem !important;
  }

  .lg\:builder-mb-14{
    margin-bottom: 3.5rem !important;
  }

  .lg\:builder-mb-16{
    margin-bottom: 4rem !important;
  }

  .lg\:builder-mb-20{
    margin-bottom: 5rem !important;
  }

  .lg\:builder-mb-24{
    margin-bottom: 6rem !important;
  }

  .lg\:builder-mb-28{
    margin-bottom: 7rem !important;
  }

  .lg\:builder-mb-32{
    margin-bottom: 8rem !important;
  }

  .lg\:builder-mb-36{
    margin-bottom: 9rem !important;
  }

  .lg\:builder-mb-40{
    margin-bottom: 10rem !important;
  }

  .lg\:builder-mb-44{
    margin-bottom: 11rem !important;
  }

  .lg\:builder-mb-48{
    margin-bottom: 12rem !important;
  }

  .lg\:builder-mb-52{
    margin-bottom: 13rem !important;
  }

  .lg\:builder-mb-56{
    margin-bottom: 14rem !important;
  }

  .lg\:builder-mb-60{
    margin-bottom: 15rem !important;
  }

  .lg\:builder-mb-64{
    margin-bottom: 16rem !important;
  }

  .lg\:builder-mb-72{
    margin-bottom: 18rem !important;
  }

  .lg\:builder-mb-80{
    margin-bottom: 20rem !important;
  }

  .lg\:builder-mb-96{
    margin-bottom: 24rem !important;
  }

  .lg\:builder-mb-auto{
    margin-bottom: auto !important;
  }

  .lg\:builder-mb-px{
    margin-bottom: 1px !important;
  }

  .lg\:builder-mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .lg\:builder-mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .lg\:builder-mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .lg\:builder-mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .lg\:builder--mb-0{
    margin-bottom: 0px !important;
  }

  .lg\:builder--mb-1{
    margin-bottom: -0.25rem !important;
  }

  .lg\:builder--mb-2{
    margin-bottom: -0.5rem !important;
  }

  .lg\:builder--mb-3{
    margin-bottom: -0.75rem !important;
  }

  .lg\:builder--mb-4{
    margin-bottom: -1rem !important;
  }

  .lg\:builder--mb-5{
    margin-bottom: -1.25rem !important;
  }

  .lg\:builder--mb-6{
    margin-bottom: -1.5rem !important;
  }

  .lg\:builder--mb-7{
    margin-bottom: -1.75rem !important;
  }

  .lg\:builder--mb-8{
    margin-bottom: -2rem !important;
  }

  .lg\:builder--mb-9{
    margin-bottom: -2.25rem !important;
  }

  .lg\:builder--mb-10{
    margin-bottom: -2.5rem !important;
  }

  .lg\:builder--mb-11{
    margin-bottom: -2.75rem !important;
  }

  .lg\:builder--mb-12{
    margin-bottom: -3rem !important;
  }

  .lg\:builder--mb-14{
    margin-bottom: -3.5rem !important;
  }

  .lg\:builder--mb-16{
    margin-bottom: -4rem !important;
  }

  .lg\:builder--mb-20{
    margin-bottom: -5rem !important;
  }

  .lg\:builder--mb-24{
    margin-bottom: -6rem !important;
  }

  .lg\:builder--mb-28{
    margin-bottom: -7rem !important;
  }

  .lg\:builder--mb-32{
    margin-bottom: -8rem !important;
  }

  .lg\:builder--mb-36{
    margin-bottom: -9rem !important;
  }

  .lg\:builder--mb-40{
    margin-bottom: -10rem !important;
  }

  .lg\:builder--mb-44{
    margin-bottom: -11rem !important;
  }

  .lg\:builder--mb-48{
    margin-bottom: -12rem !important;
  }

  .lg\:builder--mb-52{
    margin-bottom: -13rem !important;
  }

  .lg\:builder--mb-56{
    margin-bottom: -14rem !important;
  }

  .lg\:builder--mb-60{
    margin-bottom: -15rem !important;
  }

  .lg\:builder--mb-64{
    margin-bottom: -16rem !important;
  }

  .lg\:builder--mb-72{
    margin-bottom: -18rem !important;
  }

  .lg\:builder--mb-80{
    margin-bottom: -20rem !important;
  }

  .lg\:builder--mb-96{
    margin-bottom: -24rem !important;
  }

  .lg\:builder--mb-px{
    margin-bottom: -1px !important;
  }

  .lg\:builder--mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .lg\:builder--mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .lg\:builder--mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .lg\:builder--mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .lg\:builder-ml-0{
    margin-left: 0px !important;
  }

  .lg\:builder-ml-1{
    margin-left: 0.25rem !important;
  }

  .lg\:builder-ml-2{
    margin-left: 0.5rem !important;
  }

  .lg\:builder-ml-3{
    margin-left: 0.75rem !important;
  }

  .lg\:builder-ml-4{
    margin-left: 1rem !important;
  }

  .lg\:builder-ml-5{
    margin-left: 1.25rem !important;
  }

  .lg\:builder-ml-6{
    margin-left: 1.5rem !important;
  }

  .lg\:builder-ml-7{
    margin-left: 1.75rem !important;
  }

  .lg\:builder-ml-8{
    margin-left: 2rem !important;
  }

  .lg\:builder-ml-9{
    margin-left: 2.25rem !important;
  }

  .lg\:builder-ml-10{
    margin-left: 2.5rem !important;
  }

  .lg\:builder-ml-11{
    margin-left: 2.75rem !important;
  }

  .lg\:builder-ml-12{
    margin-left: 3rem !important;
  }

  .lg\:builder-ml-14{
    margin-left: 3.5rem !important;
  }

  .lg\:builder-ml-16{
    margin-left: 4rem !important;
  }

  .lg\:builder-ml-20{
    margin-left: 5rem !important;
  }

  .lg\:builder-ml-24{
    margin-left: 6rem !important;
  }

  .lg\:builder-ml-28{
    margin-left: 7rem !important;
  }

  .lg\:builder-ml-32{
    margin-left: 8rem !important;
  }

  .lg\:builder-ml-36{
    margin-left: 9rem !important;
  }

  .lg\:builder-ml-40{
    margin-left: 10rem !important;
  }

  .lg\:builder-ml-44{
    margin-left: 11rem !important;
  }

  .lg\:builder-ml-48{
    margin-left: 12rem !important;
  }

  .lg\:builder-ml-52{
    margin-left: 13rem !important;
  }

  .lg\:builder-ml-56{
    margin-left: 14rem !important;
  }

  .lg\:builder-ml-60{
    margin-left: 15rem !important;
  }

  .lg\:builder-ml-64{
    margin-left: 16rem !important;
  }

  .lg\:builder-ml-72{
    margin-left: 18rem !important;
  }

  .lg\:builder-ml-80{
    margin-left: 20rem !important;
  }

  .lg\:builder-ml-96{
    margin-left: 24rem !important;
  }

  .lg\:builder-ml-auto{
    margin-left: auto !important;
  }

  .lg\:builder-ml-px{
    margin-left: 1px !important;
  }

  .lg\:builder-ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .lg\:builder-ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .lg\:builder-ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .lg\:builder-ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .lg\:builder--ml-0{
    margin-left: 0px !important;
  }

  .lg\:builder--ml-1{
    margin-left: -0.25rem !important;
  }

  .lg\:builder--ml-2{
    margin-left: -0.5rem !important;
  }

  .lg\:builder--ml-3{
    margin-left: -0.75rem !important;
  }

  .lg\:builder--ml-4{
    margin-left: -1rem !important;
  }

  .lg\:builder--ml-5{
    margin-left: -1.25rem !important;
  }

  .lg\:builder--ml-6{
    margin-left: -1.5rem !important;
  }

  .lg\:builder--ml-7{
    margin-left: -1.75rem !important;
  }

  .lg\:builder--ml-8{
    margin-left: -2rem !important;
  }

  .lg\:builder--ml-9{
    margin-left: -2.25rem !important;
  }

  .lg\:builder--ml-10{
    margin-left: -2.5rem !important;
  }

  .lg\:builder--ml-11{
    margin-left: -2.75rem !important;
  }

  .lg\:builder--ml-12{
    margin-left: -3rem !important;
  }

  .lg\:builder--ml-14{
    margin-left: -3.5rem !important;
  }

  .lg\:builder--ml-16{
    margin-left: -4rem !important;
  }

  .lg\:builder--ml-20{
    margin-left: -5rem !important;
  }

  .lg\:builder--ml-24{
    margin-left: -6rem !important;
  }

  .lg\:builder--ml-28{
    margin-left: -7rem !important;
  }

  .lg\:builder--ml-32{
    margin-left: -8rem !important;
  }

  .lg\:builder--ml-36{
    margin-left: -9rem !important;
  }

  .lg\:builder--ml-40{
    margin-left: -10rem !important;
  }

  .lg\:builder--ml-44{
    margin-left: -11rem !important;
  }

  .lg\:builder--ml-48{
    margin-left: -12rem !important;
  }

  .lg\:builder--ml-52{
    margin-left: -13rem !important;
  }

  .lg\:builder--ml-56{
    margin-left: -14rem !important;
  }

  .lg\:builder--ml-60{
    margin-left: -15rem !important;
  }

  .lg\:builder--ml-64{
    margin-left: -16rem !important;
  }

  .lg\:builder--ml-72{
    margin-left: -18rem !important;
  }

  .lg\:builder--ml-80{
    margin-left: -20rem !important;
  }

  .lg\:builder--ml-96{
    margin-left: -24rem !important;
  }

  .lg\:builder--ml-px{
    margin-left: -1px !important;
  }

  .lg\:builder--ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .lg\:builder--ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .lg\:builder--ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .lg\:builder--ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .lg\:builder-box-border{
    box-sizing: border-box !important;
  }

  .lg\:builder-box-content{
    box-sizing: content-box !important;
  }

  .lg\:builder-block{
    display: block !important;
  }

  .lg\:builder-inline-block{
    display: inline-block !important;
  }

  .lg\:builder-inline{
    display: inline !important;
  }

  .lg\:builder-flex{
    display: flex !important;
  }

  .lg\:builder-inline-flex{
    display: inline-flex !important;
  }

  .lg\:builder-table{
    display: table !important;
  }

  .lg\:builder-inline-table{
    display: inline-table !important;
  }

  .lg\:builder-table-caption{
    display: table-caption !important;
  }

  .lg\:builder-table-cell{
    display: table-cell !important;
  }

  .lg\:builder-table-column{
    display: table-column !important;
  }

  .lg\:builder-table-column-group{
    display: table-column-group !important;
  }

  .lg\:builder-table-footer-group{
    display: table-footer-group !important;
  }

  .lg\:builder-table-header-group{
    display: table-header-group !important;
  }

  .lg\:builder-table-row-group{
    display: table-row-group !important;
  }

  .lg\:builder-table-row{
    display: table-row !important;
  }

  .lg\:builder-flow-root{
    display: flow-root !important;
  }

  .lg\:builder-grid{
    display: grid !important;
  }

  .lg\:builder-inline-grid{
    display: inline-grid !important;
  }

  .lg\:builder-contents{
    display: contents !important;
  }

  .lg\:builder-list-item{
    display: list-item !important;
  }

  .lg\:builder-hidden{
    display: none !important;
  }

  .lg\:builder-h-0{
    height: 0px !important;
  }

  .lg\:builder-h-1{
    height: 0.25rem !important;
  }

  .lg\:builder-h-2{
    height: 0.5rem !important;
  }

  .lg\:builder-h-3{
    height: 0.75rem !important;
  }

  .lg\:builder-h-4{
    height: 1rem !important;
  }

  .lg\:builder-h-5{
    height: 1.25rem !important;
  }

  .lg\:builder-h-6{
    height: 1.5rem !important;
  }

  .lg\:builder-h-7{
    height: 1.75rem !important;
  }

  .lg\:builder-h-8{
    height: 2rem !important;
  }

  .lg\:builder-h-9{
    height: 2.25rem !important;
  }

  .lg\:builder-h-10{
    height: 2.5rem !important;
  }

  .lg\:builder-h-11{
    height: 2.75rem !important;
  }

  .lg\:builder-h-12{
    height: 3rem !important;
  }

  .lg\:builder-h-14{
    height: 3.5rem !important;
  }

  .lg\:builder-h-16{
    height: 4rem !important;
  }

  .lg\:builder-h-20{
    height: 5rem !important;
  }

  .lg\:builder-h-24{
    height: 6rem !important;
  }

  .lg\:builder-h-28{
    height: 7rem !important;
  }

  .lg\:builder-h-32{
    height: 8rem !important;
  }

  .lg\:builder-h-36{
    height: 9rem !important;
  }

  .lg\:builder-h-40{
    height: 10rem !important;
  }

  .lg\:builder-h-44{
    height: 11rem !important;
  }

  .lg\:builder-h-48{
    height: 12rem !important;
  }

  .lg\:builder-h-52{
    height: 13rem !important;
  }

  .lg\:builder-h-56{
    height: 14rem !important;
  }

  .lg\:builder-h-60{
    height: 15rem !important;
  }

  .lg\:builder-h-64{
    height: 16rem !important;
  }

  .lg\:builder-h-72{
    height: 18rem !important;
  }

  .lg\:builder-h-80{
    height: 20rem !important;
  }

  .lg\:builder-h-96{
    height: 24rem !important;
  }

  .lg\:builder-h-auto{
    height: auto !important;
  }

  .lg\:builder-h-px{
    height: 1px !important;
  }

  .lg\:builder-h-0\.5{
    height: 0.125rem !important;
  }

  .lg\:builder-h-1\.5{
    height: 0.375rem !important;
  }

  .lg\:builder-h-2\.5{
    height: 0.625rem !important;
  }

  .lg\:builder-h-3\.5{
    height: 0.875rem !important;
  }

  .lg\:builder-h-1\/2{
    height: 50% !important;
  }

  .lg\:builder-h-1\/3{
    height: 33.333333% !important;
  }

  .lg\:builder-h-2\/3{
    height: 66.666667% !important;
  }

  .lg\:builder-h-1\/4{
    height: 25% !important;
  }

  .lg\:builder-h-2\/4{
    height: 50% !important;
  }

  .lg\:builder-h-3\/4{
    height: 75% !important;
  }

  .lg\:builder-h-1\/5{
    height: 20% !important;
  }

  .lg\:builder-h-2\/5{
    height: 40% !important;
  }

  .lg\:builder-h-3\/5{
    height: 60% !important;
  }

  .lg\:builder-h-4\/5{
    height: 80% !important;
  }

  .lg\:builder-h-1\/6{
    height: 16.666667% !important;
  }

  .lg\:builder-h-2\/6{
    height: 33.333333% !important;
  }

  .lg\:builder-h-3\/6{
    height: 50% !important;
  }

  .lg\:builder-h-4\/6{
    height: 66.666667% !important;
  }

  .lg\:builder-h-5\/6{
    height: 83.333333% !important;
  }

  .lg\:builder-h-full{
    height: 100% !important;
  }

  .lg\:builder-h-screen{
    height: 100vh !important;
  }

  .lg\:builder-max-h-0{
    max-height: 0px !important;
  }

  .lg\:builder-max-h-1{
    max-height: 0.25rem !important;
  }

  .lg\:builder-max-h-2{
    max-height: 0.5rem !important;
  }

  .lg\:builder-max-h-3{
    max-height: 0.75rem !important;
  }

  .lg\:builder-max-h-4{
    max-height: 1rem !important;
  }

  .lg\:builder-max-h-5{
    max-height: 1.25rem !important;
  }

  .lg\:builder-max-h-6{
    max-height: 1.5rem !important;
  }

  .lg\:builder-max-h-7{
    max-height: 1.75rem !important;
  }

  .lg\:builder-max-h-8{
    max-height: 2rem !important;
  }

  .lg\:builder-max-h-9{
    max-height: 2.25rem !important;
  }

  .lg\:builder-max-h-10{
    max-height: 2.5rem !important;
  }

  .lg\:builder-max-h-11{
    max-height: 2.75rem !important;
  }

  .lg\:builder-max-h-12{
    max-height: 3rem !important;
  }

  .lg\:builder-max-h-14{
    max-height: 3.5rem !important;
  }

  .lg\:builder-max-h-16{
    max-height: 4rem !important;
  }

  .lg\:builder-max-h-20{
    max-height: 5rem !important;
  }

  .lg\:builder-max-h-24{
    max-height: 6rem !important;
  }

  .lg\:builder-max-h-28{
    max-height: 7rem !important;
  }

  .lg\:builder-max-h-32{
    max-height: 8rem !important;
  }

  .lg\:builder-max-h-36{
    max-height: 9rem !important;
  }

  .lg\:builder-max-h-40{
    max-height: 10rem !important;
  }

  .lg\:builder-max-h-44{
    max-height: 11rem !important;
  }

  .lg\:builder-max-h-48{
    max-height: 12rem !important;
  }

  .lg\:builder-max-h-52{
    max-height: 13rem !important;
  }

  .lg\:builder-max-h-56{
    max-height: 14rem !important;
  }

  .lg\:builder-max-h-60{
    max-height: 15rem !important;
  }

  .lg\:builder-max-h-64{
    max-height: 16rem !important;
  }

  .lg\:builder-max-h-72{
    max-height: 18rem !important;
  }

  .lg\:builder-max-h-80{
    max-height: 20rem !important;
  }

  .lg\:builder-max-h-96{
    max-height: 24rem !important;
  }

  .lg\:builder-max-h-px{
    max-height: 1px !important;
  }

  .lg\:builder-max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .lg\:builder-max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .lg\:builder-max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .lg\:builder-max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .lg\:builder-max-h-full{
    max-height: 100% !important;
  }

  .lg\:builder-max-h-screen{
    max-height: 100vh !important;
  }

  .lg\:builder-min-h-0{
    min-height: 0px !important;
  }

  .lg\:builder-min-h-full{
    min-height: 100% !important;
  }

  .lg\:builder-min-h-screen{
    min-height: 100vh !important;
  }

  .lg\:builder-w-0{
    width: 0px !important;
  }

  .lg\:builder-w-1{
    width: 0.25rem !important;
  }

  .lg\:builder-w-2{
    width: 0.5rem !important;
  }

  .lg\:builder-w-3{
    width: 0.75rem !important;
  }

  .lg\:builder-w-4{
    width: 1rem !important;
  }

  .lg\:builder-w-5{
    width: 1.25rem !important;
  }

  .lg\:builder-w-6{
    width: 1.5rem !important;
  }

  .lg\:builder-w-7{
    width: 1.75rem !important;
  }

  .lg\:builder-w-8{
    width: 2rem !important;
  }

  .lg\:builder-w-9{
    width: 2.25rem !important;
  }

  .lg\:builder-w-10{
    width: 2.5rem !important;
  }

  .lg\:builder-w-11{
    width: 2.75rem !important;
  }

  .lg\:builder-w-12{
    width: 3rem !important;
  }

  .lg\:builder-w-14{
    width: 3.5rem !important;
  }

  .lg\:builder-w-16{
    width: 4rem !important;
  }

  .lg\:builder-w-20{
    width: 5rem !important;
  }

  .lg\:builder-w-24{
    width: 6rem !important;
  }

  .lg\:builder-w-28{
    width: 7rem !important;
  }

  .lg\:builder-w-32{
    width: 8rem !important;
  }

  .lg\:builder-w-36{
    width: 9rem !important;
  }

  .lg\:builder-w-40{
    width: 10rem !important;
  }

  .lg\:builder-w-44{
    width: 11rem !important;
  }

  .lg\:builder-w-48{
    width: 12rem !important;
  }

  .lg\:builder-w-52{
    width: 13rem !important;
  }

  .lg\:builder-w-56{
    width: 14rem !important;
  }

  .lg\:builder-w-60{
    width: 15rem !important;
  }

  .lg\:builder-w-64{
    width: 16rem !important;
  }

  .lg\:builder-w-72{
    width: 18rem !important;
  }

  .lg\:builder-w-80{
    width: 20rem !important;
  }

  .lg\:builder-w-96{
    width: 24rem !important;
  }

  .lg\:builder-w-auto{
    width: auto !important;
  }

  .lg\:builder-w-px{
    width: 1px !important;
  }

  .lg\:builder-w-0\.5{
    width: 0.125rem !important;
  }

  .lg\:builder-w-1\.5{
    width: 0.375rem !important;
  }

  .lg\:builder-w-2\.5{
    width: 0.625rem !important;
  }

  .lg\:builder-w-3\.5{
    width: 0.875rem !important;
  }

  .lg\:builder-w-1\/2{
    width: 50% !important;
  }

  .lg\:builder-w-1\/3{
    width: 33.333333% !important;
  }

  .lg\:builder-w-2\/3{
    width: 66.666667% !important;
  }

  .lg\:builder-w-1\/4{
    width: 25% !important;
  }

  .lg\:builder-w-2\/4{
    width: 50% !important;
  }

  .lg\:builder-w-3\/4{
    width: 75% !important;
  }

  .lg\:builder-w-1\/5{
    width: 20% !important;
  }

  .lg\:builder-w-2\/5{
    width: 40% !important;
  }

  .lg\:builder-w-3\/5{
    width: 60% !important;
  }

  .lg\:builder-w-4\/5{
    width: 80% !important;
  }

  .lg\:builder-w-1\/6{
    width: 16.666667% !important;
  }

  .lg\:builder-w-2\/6{
    width: 33.333333% !important;
  }

  .lg\:builder-w-3\/6{
    width: 50% !important;
  }

  .lg\:builder-w-4\/6{
    width: 66.666667% !important;
  }

  .lg\:builder-w-5\/6{
    width: 83.333333% !important;
  }

  .lg\:builder-w-1\/12{
    width: 8.333333% !important;
  }

  .lg\:builder-w-2\/12{
    width: 16.666667% !important;
  }

  .lg\:builder-w-3\/12{
    width: 25% !important;
  }

  .lg\:builder-w-4\/12{
    width: 33.333333% !important;
  }

  .lg\:builder-w-5\/12{
    width: 41.666667% !important;
  }

  .lg\:builder-w-6\/12{
    width: 50% !important;
  }

  .lg\:builder-w-7\/12{
    width: 58.333333% !important;
  }

  .lg\:builder-w-8\/12{
    width: 66.666667% !important;
  }

  .lg\:builder-w-9\/12{
    width: 75% !important;
  }

  .lg\:builder-w-10\/12{
    width: 83.333333% !important;
  }

  .lg\:builder-w-11\/12{
    width: 91.666667% !important;
  }

  .lg\:builder-w-full{
    width: 100% !important;
  }

  .lg\:builder-w-screen{
    width: 100vw !important;
  }

  .lg\:builder-w-min{
    width: min-content !important;
  }

  .lg\:builder-w-max{
    width: max-content !important;
  }

  .lg\:builder-min-w-0{
    min-width: 0px !important;
  }

  .lg\:builder-min-w-full{
    min-width: 100% !important;
  }

  .lg\:builder-min-w-min{
    min-width: min-content !important;
  }

  .lg\:builder-min-w-max{
    min-width: max-content !important;
  }

  .lg\:builder-max-w-0{
    max-width: 0rem !important;
  }

  .lg\:builder-max-w-none{
    max-width: none !important;
  }

  .lg\:builder-max-w-xs{
    max-width: 20rem !important;
  }

  .lg\:builder-max-w-sm{
    max-width: 24rem !important;
  }

  .lg\:builder-max-w-md{
    max-width: 28rem !important;
  }

  .lg\:builder-max-w-lg{
    max-width: 32rem !important;
  }

  .lg\:builder-max-w-xl{
    max-width: 36rem !important;
  }

  .lg\:builder-max-w-2xl{
    max-width: 42rem !important;
  }

  .lg\:builder-max-w-3xl{
    max-width: 48rem !important;
  }

  .lg\:builder-max-w-4xl{
    max-width: 56rem !important;
  }

  .lg\:builder-max-w-5xl{
    max-width: 64rem !important;
  }

  .lg\:builder-max-w-6xl{
    max-width: 72rem !important;
  }

  .lg\:builder-max-w-7xl{
    max-width: 80rem !important;
  }

  .lg\:builder-max-w-full{
    max-width: 100% !important;
  }

  .lg\:builder-max-w-min{
    max-width: min-content !important;
  }

  .lg\:builder-max-w-max{
    max-width: max-content !important;
  }

  .lg\:builder-max-w-prose{
    max-width: 65ch !important;
  }

  .lg\:builder-max-w-screen-sm{
    max-width: 640px !important;
  }

  .lg\:builder-max-w-screen-md{
    max-width: 768px !important;
  }

  .lg\:builder-max-w-screen-lg{
    max-width: 1024px !important;
  }

  .lg\:builder-max-w-screen-xl{
    max-width: 1280px !important;
  }

  .lg\:builder-max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .lg\:builder-flex-1{
    flex: 1 1 0% !important;
  }

  .lg\:builder-flex-auto{
    flex: 1 1 auto !important;
  }

  .lg\:builder-flex-initial{
    flex: 0 1 auto !important;
  }

  .lg\:builder-flex-none{
    flex: none !important;
  }

  .lg\:builder-flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .lg\:builder-flex-shrink{
    flex-shrink: 1 !important;
  }

  .lg\:builder-flex-grow-0{
    flex-grow: 0 !important;
  }

  .lg\:builder-flex-grow{
    flex-grow: 1 !important;
  }

  .lg\:builder-table-auto{
    table-layout: auto !important;
  }

  .lg\:builder-table-fixed{
    table-layout: fixed !important;
  }

  .lg\:builder-border-collapse{
    border-collapse: collapse !important;
  }

  .lg\:builder-border-separate{
    border-collapse: separate !important;
  }

  .lg\:builder-origin-center{
    transform-origin: center !important;
  }

  .lg\:builder-origin-top{
    transform-origin: top !important;
  }

  .lg\:builder-origin-top-right{
    transform-origin: top right !important;
  }

  .lg\:builder-origin-right{
    transform-origin: right !important;
  }

  .lg\:builder-origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .lg\:builder-origin-bottom{
    transform-origin: bottom !important;
  }

  .lg\:builder-origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .lg\:builder-origin-left{
    transform-origin: left !important;
  }

  .lg\:builder-origin-top-left{
    transform-origin: top left !important;
  }

  .lg\:builder-transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .lg\:builder-transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .lg\:builder-transform-none{
    transform: none !important;
  }

  .lg\:builder-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .lg\:builder-translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .lg\:builder-translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .lg\:builder-translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .lg\:builder-translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .lg\:builder-translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .lg\:builder-translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .lg\:builder-translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .lg\:builder-translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .lg\:builder-translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .lg\:builder-translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .lg\:builder-translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .lg\:builder-translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .lg\:builder-translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .lg\:builder-translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .lg\:builder-translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .lg\:builder-translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .lg\:builder-translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .lg\:builder-translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .lg\:builder-translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .lg\:builder-translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .lg\:builder-translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .lg\:builder-translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .lg\:builder-translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .lg\:builder-translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .lg\:builder-translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .lg\:builder-translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .lg\:builder-translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .lg\:builder-translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .lg\:builder-translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .lg\:builder-translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .lg\:builder-translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .lg\:builder-translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .lg\:builder-translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .lg\:builder-translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .lg\:builder--translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .lg\:builder--translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .lg\:builder--translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .lg\:builder--translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .lg\:builder--translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .lg\:builder--translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .lg\:builder--translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .lg\:builder--translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .lg\:builder--translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .lg\:builder--translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .lg\:builder--translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .lg\:builder--translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .lg\:builder--translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .lg\:builder--translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .lg\:builder--translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .lg\:builder--translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .lg\:builder--translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .lg\:builder--translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .lg\:builder--translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .lg\:builder--translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .lg\:builder--translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .lg\:builder--translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .lg\:builder--translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .lg\:builder--translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .lg\:builder--translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .lg\:builder--translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .lg\:builder--translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .lg\:builder--translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .lg\:builder--translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .lg\:builder--translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .lg\:builder--translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .lg\:builder--translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .lg\:builder--translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .lg\:builder--translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .lg\:builder--translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .lg\:builder-translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .lg\:builder-translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .lg\:builder-translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .lg\:builder-translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .lg\:builder-translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .lg\:builder-translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .lg\:builder-translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .lg\:builder--translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .lg\:builder--translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .lg\:builder--translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .lg\:builder--translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .lg\:builder--translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .lg\:builder--translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .lg\:builder--translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .lg\:builder-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .lg\:builder-translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .lg\:builder-translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .lg\:builder-translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .lg\:builder-translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .lg\:builder-translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .lg\:builder-translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .lg\:builder-translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .lg\:builder-translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .lg\:builder-translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .lg\:builder-translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .lg\:builder-translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .lg\:builder-translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .lg\:builder-translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .lg\:builder-translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .lg\:builder-translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .lg\:builder-translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .lg\:builder-translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .lg\:builder-translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .lg\:builder-translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .lg\:builder-translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .lg\:builder-translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .lg\:builder-translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .lg\:builder-translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .lg\:builder-translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .lg\:builder-translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .lg\:builder-translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .lg\:builder-translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .lg\:builder-translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .lg\:builder-translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .lg\:builder-translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .lg\:builder-translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .lg\:builder-translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .lg\:builder-translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .lg\:builder-translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .lg\:builder--translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .lg\:builder--translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .lg\:builder--translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .lg\:builder--translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .lg\:builder--translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .lg\:builder--translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .lg\:builder--translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .lg\:builder--translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .lg\:builder--translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .lg\:builder--translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .lg\:builder--translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .lg\:builder--translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .lg\:builder--translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .lg\:builder--translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .lg\:builder--translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .lg\:builder--translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .lg\:builder--translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .lg\:builder--translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .lg\:builder--translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .lg\:builder--translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .lg\:builder--translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .lg\:builder--translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .lg\:builder--translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .lg\:builder--translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .lg\:builder--translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .lg\:builder--translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .lg\:builder--translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .lg\:builder--translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .lg\:builder--translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .lg\:builder--translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .lg\:builder--translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .lg\:builder--translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .lg\:builder--translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .lg\:builder--translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .lg\:builder--translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .lg\:builder-translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .lg\:builder-translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .lg\:builder-translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .lg\:builder-translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .lg\:builder-translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .lg\:builder-translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .lg\:builder-translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .lg\:builder--translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .lg\:builder--translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .lg\:builder--translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .lg\:builder--translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .lg\:builder--translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .lg\:builder--translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .lg\:builder--translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .lg\:hover\:builder-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .lg\:hover\:builder-translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .lg\:hover\:builder-translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .lg\:hover\:builder-translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .lg\:hover\:builder-translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .lg\:hover\:builder-translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .lg\:hover\:builder-translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .lg\:hover\:builder-translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .lg\:hover\:builder-translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .lg\:hover\:builder-translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .lg\:hover\:builder-translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .lg\:hover\:builder-translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .lg\:hover\:builder-translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .lg\:hover\:builder-translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .lg\:hover\:builder-translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .lg\:hover\:builder-translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .lg\:hover\:builder-translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .lg\:hover\:builder-translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .lg\:hover\:builder-translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .lg\:hover\:builder-translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .lg\:hover\:builder-translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .lg\:hover\:builder-translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .lg\:hover\:builder-translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .lg\:hover\:builder-translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .lg\:hover\:builder-translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .lg\:hover\:builder-translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .lg\:hover\:builder-translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .lg\:hover\:builder-translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .lg\:hover\:builder-translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .lg\:hover\:builder-translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .lg\:hover\:builder-translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .lg\:hover\:builder-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .lg\:hover\:builder-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .lg\:hover\:builder-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .lg\:hover\:builder-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .lg\:hover\:builder--translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .lg\:hover\:builder--translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .lg\:hover\:builder--translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .lg\:hover\:builder--translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .lg\:hover\:builder--translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .lg\:hover\:builder--translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .lg\:hover\:builder--translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .lg\:hover\:builder--translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .lg\:hover\:builder--translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .lg\:hover\:builder--translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .lg\:hover\:builder--translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .lg\:hover\:builder--translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .lg\:hover\:builder--translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .lg\:hover\:builder--translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .lg\:hover\:builder--translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .lg\:hover\:builder--translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .lg\:hover\:builder--translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .lg\:hover\:builder--translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .lg\:hover\:builder--translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .lg\:hover\:builder--translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .lg\:hover\:builder--translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .lg\:hover\:builder--translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .lg\:hover\:builder--translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .lg\:hover\:builder--translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .lg\:hover\:builder--translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .lg\:hover\:builder--translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .lg\:hover\:builder--translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .lg\:hover\:builder--translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .lg\:hover\:builder--translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .lg\:hover\:builder--translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .lg\:hover\:builder--translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .lg\:hover\:builder--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .lg\:hover\:builder--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .lg\:hover\:builder--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .lg\:hover\:builder--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .lg\:hover\:builder-translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .lg\:hover\:builder-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .lg\:hover\:builder-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .lg\:hover\:builder-translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .lg\:hover\:builder-translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .lg\:hover\:builder-translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .lg\:hover\:builder-translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .lg\:hover\:builder--translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .lg\:hover\:builder--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .lg\:hover\:builder--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .lg\:hover\:builder--translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .lg\:hover\:builder--translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .lg\:hover\:builder--translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .lg\:hover\:builder--translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .lg\:hover\:builder-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .lg\:hover\:builder-translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .lg\:hover\:builder-translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .lg\:hover\:builder-translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .lg\:hover\:builder-translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .lg\:hover\:builder-translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .lg\:hover\:builder-translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .lg\:hover\:builder-translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .lg\:hover\:builder-translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .lg\:hover\:builder-translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .lg\:hover\:builder-translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .lg\:hover\:builder-translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .lg\:hover\:builder-translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .lg\:hover\:builder-translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .lg\:hover\:builder-translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .lg\:hover\:builder-translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .lg\:hover\:builder-translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .lg\:hover\:builder-translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .lg\:hover\:builder-translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .lg\:hover\:builder-translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .lg\:hover\:builder-translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .lg\:hover\:builder-translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .lg\:hover\:builder-translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .lg\:hover\:builder-translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .lg\:hover\:builder-translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .lg\:hover\:builder-translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .lg\:hover\:builder-translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .lg\:hover\:builder-translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .lg\:hover\:builder-translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .lg\:hover\:builder-translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .lg\:hover\:builder-translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .lg\:hover\:builder-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .lg\:hover\:builder-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .lg\:hover\:builder-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .lg\:hover\:builder-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .lg\:hover\:builder--translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .lg\:hover\:builder--translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .lg\:hover\:builder--translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .lg\:hover\:builder--translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .lg\:hover\:builder--translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .lg\:hover\:builder--translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .lg\:hover\:builder--translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .lg\:hover\:builder--translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .lg\:hover\:builder--translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .lg\:hover\:builder--translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .lg\:hover\:builder--translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .lg\:hover\:builder--translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .lg\:hover\:builder--translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .lg\:hover\:builder--translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .lg\:hover\:builder--translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .lg\:hover\:builder--translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .lg\:hover\:builder--translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .lg\:hover\:builder--translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .lg\:hover\:builder--translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .lg\:hover\:builder--translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .lg\:hover\:builder--translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .lg\:hover\:builder--translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .lg\:hover\:builder--translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .lg\:hover\:builder--translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .lg\:hover\:builder--translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .lg\:hover\:builder--translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .lg\:hover\:builder--translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .lg\:hover\:builder--translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .lg\:hover\:builder--translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .lg\:hover\:builder--translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .lg\:hover\:builder--translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .lg\:hover\:builder--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .lg\:hover\:builder--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .lg\:hover\:builder--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .lg\:hover\:builder--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .lg\:hover\:builder-translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .lg\:hover\:builder-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .lg\:hover\:builder-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .lg\:hover\:builder-translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .lg\:hover\:builder-translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .lg\:hover\:builder-translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .lg\:hover\:builder-translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .lg\:hover\:builder--translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .lg\:hover\:builder--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .lg\:hover\:builder--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .lg\:hover\:builder--translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .lg\:hover\:builder--translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .lg\:hover\:builder--translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .lg\:hover\:builder--translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .lg\:focus\:builder-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .lg\:focus\:builder-translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .lg\:focus\:builder-translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .lg\:focus\:builder-translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .lg\:focus\:builder-translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .lg\:focus\:builder-translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .lg\:focus\:builder-translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .lg\:focus\:builder-translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .lg\:focus\:builder-translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .lg\:focus\:builder-translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .lg\:focus\:builder-translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .lg\:focus\:builder-translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .lg\:focus\:builder-translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .lg\:focus\:builder-translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .lg\:focus\:builder-translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .lg\:focus\:builder-translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .lg\:focus\:builder-translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .lg\:focus\:builder-translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .lg\:focus\:builder-translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .lg\:focus\:builder-translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .lg\:focus\:builder-translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .lg\:focus\:builder-translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .lg\:focus\:builder-translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .lg\:focus\:builder-translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .lg\:focus\:builder-translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .lg\:focus\:builder-translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .lg\:focus\:builder-translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .lg\:focus\:builder-translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .lg\:focus\:builder-translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .lg\:focus\:builder-translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .lg\:focus\:builder-translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .lg\:focus\:builder-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .lg\:focus\:builder-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .lg\:focus\:builder-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .lg\:focus\:builder-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .lg\:focus\:builder--translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .lg\:focus\:builder--translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .lg\:focus\:builder--translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .lg\:focus\:builder--translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .lg\:focus\:builder--translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .lg\:focus\:builder--translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .lg\:focus\:builder--translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .lg\:focus\:builder--translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .lg\:focus\:builder--translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .lg\:focus\:builder--translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .lg\:focus\:builder--translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .lg\:focus\:builder--translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .lg\:focus\:builder--translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .lg\:focus\:builder--translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .lg\:focus\:builder--translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .lg\:focus\:builder--translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .lg\:focus\:builder--translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .lg\:focus\:builder--translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .lg\:focus\:builder--translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .lg\:focus\:builder--translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .lg\:focus\:builder--translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .lg\:focus\:builder--translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .lg\:focus\:builder--translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .lg\:focus\:builder--translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .lg\:focus\:builder--translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .lg\:focus\:builder--translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .lg\:focus\:builder--translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .lg\:focus\:builder--translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .lg\:focus\:builder--translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .lg\:focus\:builder--translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .lg\:focus\:builder--translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .lg\:focus\:builder--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .lg\:focus\:builder--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .lg\:focus\:builder--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .lg\:focus\:builder--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .lg\:focus\:builder-translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .lg\:focus\:builder-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .lg\:focus\:builder-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .lg\:focus\:builder-translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .lg\:focus\:builder-translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .lg\:focus\:builder-translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .lg\:focus\:builder-translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .lg\:focus\:builder--translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .lg\:focus\:builder--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .lg\:focus\:builder--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .lg\:focus\:builder--translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .lg\:focus\:builder--translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .lg\:focus\:builder--translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .lg\:focus\:builder--translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .lg\:focus\:builder-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .lg\:focus\:builder-translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .lg\:focus\:builder-translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .lg\:focus\:builder-translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .lg\:focus\:builder-translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .lg\:focus\:builder-translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .lg\:focus\:builder-translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .lg\:focus\:builder-translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .lg\:focus\:builder-translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .lg\:focus\:builder-translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .lg\:focus\:builder-translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .lg\:focus\:builder-translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .lg\:focus\:builder-translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .lg\:focus\:builder-translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .lg\:focus\:builder-translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .lg\:focus\:builder-translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .lg\:focus\:builder-translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .lg\:focus\:builder-translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .lg\:focus\:builder-translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .lg\:focus\:builder-translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .lg\:focus\:builder-translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .lg\:focus\:builder-translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .lg\:focus\:builder-translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .lg\:focus\:builder-translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .lg\:focus\:builder-translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .lg\:focus\:builder-translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .lg\:focus\:builder-translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .lg\:focus\:builder-translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .lg\:focus\:builder-translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .lg\:focus\:builder-translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .lg\:focus\:builder-translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .lg\:focus\:builder-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .lg\:focus\:builder-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .lg\:focus\:builder-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .lg\:focus\:builder-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .lg\:focus\:builder--translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .lg\:focus\:builder--translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .lg\:focus\:builder--translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .lg\:focus\:builder--translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .lg\:focus\:builder--translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .lg\:focus\:builder--translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .lg\:focus\:builder--translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .lg\:focus\:builder--translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .lg\:focus\:builder--translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .lg\:focus\:builder--translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .lg\:focus\:builder--translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .lg\:focus\:builder--translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .lg\:focus\:builder--translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .lg\:focus\:builder--translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .lg\:focus\:builder--translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .lg\:focus\:builder--translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .lg\:focus\:builder--translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .lg\:focus\:builder--translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .lg\:focus\:builder--translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .lg\:focus\:builder--translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .lg\:focus\:builder--translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .lg\:focus\:builder--translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .lg\:focus\:builder--translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .lg\:focus\:builder--translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .lg\:focus\:builder--translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .lg\:focus\:builder--translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .lg\:focus\:builder--translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .lg\:focus\:builder--translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .lg\:focus\:builder--translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .lg\:focus\:builder--translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .lg\:focus\:builder--translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .lg\:focus\:builder--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .lg\:focus\:builder--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .lg\:focus\:builder--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .lg\:focus\:builder--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .lg\:focus\:builder-translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .lg\:focus\:builder-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .lg\:focus\:builder-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .lg\:focus\:builder-translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .lg\:focus\:builder-translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .lg\:focus\:builder-translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .lg\:focus\:builder-translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .lg\:focus\:builder--translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .lg\:focus\:builder--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .lg\:focus\:builder--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .lg\:focus\:builder--translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .lg\:focus\:builder--translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .lg\:focus\:builder--translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .lg\:focus\:builder--translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .lg\:builder-rotate-0{
    --tw-rotate: 0deg !important;
  }

  .lg\:builder-rotate-1{
    --tw-rotate: 1deg !important;
  }

  .lg\:builder-rotate-2{
    --tw-rotate: 2deg !important;
  }

  .lg\:builder-rotate-3{
    --tw-rotate: 3deg !important;
  }

  .lg\:builder-rotate-6{
    --tw-rotate: 6deg !important;
  }

  .lg\:builder-rotate-12{
    --tw-rotate: 12deg !important;
  }

  .lg\:builder-rotate-45{
    --tw-rotate: 45deg !important;
  }

  .lg\:builder-rotate-90{
    --tw-rotate: 90deg !important;
  }

  .lg\:builder-rotate-180{
    --tw-rotate: 180deg !important;
  }

  .lg\:builder--rotate-180{
    --tw-rotate: -180deg !important;
  }

  .lg\:builder--rotate-90{
    --tw-rotate: -90deg !important;
  }

  .lg\:builder--rotate-45{
    --tw-rotate: -45deg !important;
  }

  .lg\:builder--rotate-12{
    --tw-rotate: -12deg !important;
  }

  .lg\:builder--rotate-6{
    --tw-rotate: -6deg !important;
  }

  .lg\:builder--rotate-3{
    --tw-rotate: -3deg !important;
  }

  .lg\:builder--rotate-2{
    --tw-rotate: -2deg !important;
  }

  .lg\:builder--rotate-1{
    --tw-rotate: -1deg !important;
  }

  .lg\:hover\:builder-rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .lg\:hover\:builder-rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .lg\:hover\:builder-rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .lg\:hover\:builder-rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .lg\:hover\:builder-rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .lg\:hover\:builder-rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .lg\:hover\:builder-rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .lg\:hover\:builder-rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .lg\:hover\:builder-rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .lg\:hover\:builder--rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .lg\:hover\:builder--rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .lg\:hover\:builder--rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .lg\:hover\:builder--rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .lg\:hover\:builder--rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .lg\:hover\:builder--rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .lg\:hover\:builder--rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .lg\:hover\:builder--rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .lg\:focus\:builder-rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .lg\:focus\:builder-rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .lg\:focus\:builder-rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .lg\:focus\:builder-rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .lg\:focus\:builder-rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .lg\:focus\:builder-rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .lg\:focus\:builder-rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .lg\:focus\:builder-rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .lg\:focus\:builder-rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .lg\:focus\:builder--rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .lg\:focus\:builder--rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .lg\:focus\:builder--rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .lg\:focus\:builder--rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .lg\:focus\:builder--rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .lg\:focus\:builder--rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .lg\:focus\:builder--rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .lg\:focus\:builder--rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .lg\:builder-skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .lg\:builder-skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .lg\:builder-skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .lg\:builder-skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .lg\:builder-skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .lg\:builder-skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .lg\:builder--skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .lg\:builder--skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .lg\:builder--skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .lg\:builder--skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .lg\:builder--skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .lg\:builder-skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .lg\:builder-skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .lg\:builder-skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .lg\:builder-skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .lg\:builder-skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .lg\:builder-skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .lg\:builder--skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .lg\:builder--skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .lg\:builder--skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .lg\:builder--skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .lg\:builder--skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .lg\:hover\:builder-skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .lg\:hover\:builder-skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .lg\:hover\:builder-skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .lg\:hover\:builder-skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .lg\:hover\:builder-skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .lg\:hover\:builder-skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .lg\:hover\:builder--skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .lg\:hover\:builder--skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .lg\:hover\:builder--skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .lg\:hover\:builder--skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .lg\:hover\:builder--skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .lg\:hover\:builder-skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .lg\:hover\:builder-skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .lg\:hover\:builder-skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .lg\:hover\:builder-skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .lg\:hover\:builder-skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .lg\:hover\:builder-skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .lg\:hover\:builder--skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .lg\:hover\:builder--skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .lg\:hover\:builder--skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .lg\:hover\:builder--skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .lg\:hover\:builder--skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .lg\:focus\:builder-skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .lg\:focus\:builder-skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .lg\:focus\:builder-skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .lg\:focus\:builder-skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .lg\:focus\:builder-skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .lg\:focus\:builder-skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .lg\:focus\:builder--skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .lg\:focus\:builder--skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .lg\:focus\:builder--skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .lg\:focus\:builder--skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .lg\:focus\:builder--skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .lg\:focus\:builder-skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .lg\:focus\:builder-skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .lg\:focus\:builder-skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .lg\:focus\:builder-skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .lg\:focus\:builder-skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .lg\:focus\:builder-skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .lg\:focus\:builder--skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .lg\:focus\:builder--skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .lg\:focus\:builder--skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .lg\:focus\:builder--skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .lg\:focus\:builder--skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .lg\:builder-scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .lg\:builder-scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .lg\:builder-scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .lg\:builder-scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .lg\:builder-scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .lg\:builder-scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .lg\:builder-scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .lg\:builder-scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .lg\:builder-scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .lg\:builder-scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .lg\:hover\:builder-scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .lg\:hover\:builder-scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .lg\:hover\:builder-scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .lg\:hover\:builder-scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .lg\:hover\:builder-scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .lg\:hover\:builder-scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .lg\:hover\:builder-scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .lg\:hover\:builder-scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .lg\:hover\:builder-scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .lg\:hover\:builder-scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .lg\:focus\:builder-scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .lg\:focus\:builder-scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .lg\:focus\:builder-scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .lg\:focus\:builder-scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .lg\:focus\:builder-scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .lg\:focus\:builder-scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .lg\:focus\:builder-scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .lg\:focus\:builder-scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .lg\:focus\:builder-scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .lg\:focus\:builder-scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .lg\:builder-scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .lg\:builder-scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .lg\:builder-scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .lg\:builder-scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .lg\:builder-scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .lg\:builder-scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .lg\:builder-scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .lg\:builder-scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .lg\:builder-scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .lg\:builder-scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .lg\:builder-scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .lg\:builder-scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .lg\:builder-scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .lg\:builder-scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .lg\:builder-scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .lg\:builder-scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .lg\:builder-scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .lg\:builder-scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .lg\:builder-scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .lg\:builder-scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .lg\:hover\:builder-scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .lg\:hover\:builder-scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .lg\:hover\:builder-scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .lg\:hover\:builder-scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .lg\:hover\:builder-scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .lg\:hover\:builder-scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .lg\:hover\:builder-scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .lg\:hover\:builder-scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .lg\:hover\:builder-scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .lg\:hover\:builder-scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .lg\:hover\:builder-scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .lg\:hover\:builder-scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .lg\:hover\:builder-scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .lg\:hover\:builder-scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .lg\:hover\:builder-scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .lg\:hover\:builder-scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .lg\:hover\:builder-scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .lg\:hover\:builder-scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .lg\:hover\:builder-scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .lg\:hover\:builder-scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .lg\:focus\:builder-scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .lg\:focus\:builder-scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .lg\:focus\:builder-scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .lg\:focus\:builder-scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .lg\:focus\:builder-scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .lg\:focus\:builder-scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .lg\:focus\:builder-scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .lg\:focus\:builder-scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .lg\:focus\:builder-scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .lg\:focus\:builder-scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .lg\:focus\:builder-scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .lg\:focus\:builder-scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .lg\:focus\:builder-scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .lg\:focus\:builder-scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .lg\:focus\:builder-scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .lg\:focus\:builder-scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .lg\:focus\:builder-scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .lg\:focus\:builder-scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .lg\:focus\:builder-scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .lg\:focus\:builder-scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .lg\:builder-animate-none{
    animation: none !important;
  }

  .lg\:builder-animate-spin{
    animation: builder-spin 1s linear infinite !important;
  }

  .lg\:builder-animate-ping{
    animation: builder-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .lg\:builder-animate-pulse{
    animation: builder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .lg\:builder-animate-bounce{
    animation: builder-bounce 1s infinite !important;
  }

  .lg\:builder-cursor-auto{
    cursor: auto !important;
  }

  .lg\:builder-cursor-default{
    cursor: default !important;
  }

  .lg\:builder-cursor-pointer{
    cursor: pointer !important;
  }

  .lg\:builder-cursor-wait{
    cursor: wait !important;
  }

  .lg\:builder-cursor-text{
    cursor: text !important;
  }

  .lg\:builder-cursor-move{
    cursor: move !important;
  }

  .lg\:builder-cursor-help{
    cursor: help !important;
  }

  .lg\:builder-cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .lg\:builder-select-none{
    -webkit-user-select: none !important;
            user-select: none !important;
  }

  .lg\:builder-select-text{
    -webkit-user-select: text !important;
            user-select: text !important;
  }

  .lg\:builder-select-all{
    -webkit-user-select: all !important;
            user-select: all !important;
  }

  .lg\:builder-select-auto{
    -webkit-user-select: auto !important;
            user-select: auto !important;
  }

  .lg\:builder-resize-none{
    resize: none !important;
  }

  .lg\:builder-resize-y{
    resize: vertical !important;
  }

  .lg\:builder-resize-x{
    resize: horizontal !important;
  }

  .lg\:builder-resize{
    resize: both !important;
  }

  .lg\:builder-list-inside{
    list-style-position: inside !important;
  }

  .lg\:builder-list-outside{
    list-style-position: outside !important;
  }

  .lg\:builder-list-none{
    list-style-type: none !important;
  }

  .lg\:builder-list-disc{
    list-style-type: disc !important;
  }

  .lg\:builder-list-decimal{
    list-style-type: decimal !important;
  }

  .lg\:builder-appearance-none{
    appearance: none !important;
  }

  .lg\:builder-auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .lg\:builder-auto-cols-min{
    grid-auto-columns: min-content !important;
  }

  .lg\:builder-auto-cols-max{
    grid-auto-columns: max-content !important;
  }

  .lg\:builder-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .lg\:builder-grid-flow-row{
    grid-auto-flow: row !important;
  }

  .lg\:builder-grid-flow-col{
    grid-auto-flow: column !important;
  }

  .lg\:builder-grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .lg\:builder-grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .lg\:builder-auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .lg\:builder-auto-rows-min{
    grid-auto-rows: min-content !important;
  }

  .lg\:builder-auto-rows-max{
    grid-auto-rows: max-content !important;
  }

  .lg\:builder-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .lg\:builder-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-cols-none{
    grid-template-columns: none !important;
  }

  .lg\:builder-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:builder-grid-rows-none{
    grid-template-rows: none !important;
  }

  .lg\:builder-flex-row{
    flex-direction: row !important;
  }

  .lg\:builder-flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .lg\:builder-flex-col{
    flex-direction: column !important;
  }

  .lg\:builder-flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .lg\:builder-flex-wrap{
    flex-wrap: wrap !important;
  }

  .lg\:builder-flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .lg\:builder-flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .lg\:builder-place-content-center{
    place-content: center !important;
  }

  .lg\:builder-place-content-start{
    place-content: start !important;
  }

  .lg\:builder-place-content-end{
    place-content: end !important;
  }

  .lg\:builder-place-content-between{
    place-content: space-between !important;
  }

  .lg\:builder-place-content-around{
    place-content: space-around !important;
  }

  .lg\:builder-place-content-evenly{
    place-content: space-evenly !important;
  }

  .lg\:builder-place-content-stretch{
    place-content: stretch !important;
  }

  .lg\:builder-place-items-start{
    place-items: start !important;
  }

  .lg\:builder-place-items-end{
    place-items: end !important;
  }

  .lg\:builder-place-items-center{
    place-items: center !important;
  }

  .lg\:builder-place-items-stretch{
    place-items: stretch !important;
  }

  .lg\:builder-content-center{
    align-content: center !important;
  }

  .lg\:builder-content-start{
    align-content: flex-start !important;
  }

  .lg\:builder-content-end{
    align-content: flex-end !important;
  }

  .lg\:builder-content-between{
    align-content: space-between !important;
  }

  .lg\:builder-content-around{
    align-content: space-around !important;
  }

  .lg\:builder-content-evenly{
    align-content: space-evenly !important;
  }

  .lg\:builder-items-start{
    align-items: flex-start !important;
  }

  .lg\:builder-items-end{
    align-items: flex-end !important;
  }

  .lg\:builder-items-center{
    align-items: center !important;
  }

  .lg\:builder-items-baseline{
    align-items: baseline !important;
  }

  .lg\:builder-items-stretch{
    align-items: stretch !important;
  }

  .lg\:builder-justify-start{
    justify-content: flex-start !important;
  }

  .lg\:builder-justify-end{
    justify-content: flex-end !important;
  }

  .lg\:builder-justify-center{
    justify-content: center !important;
  }

  .lg\:builder-justify-between{
    justify-content: space-between !important;
  }

  .lg\:builder-justify-around{
    justify-content: space-around !important;
  }

  .lg\:builder-justify-evenly{
    justify-content: space-evenly !important;
  }

  .lg\:builder-justify-items-start{
    justify-items: start !important;
  }

  .lg\:builder-justify-items-end{
    justify-items: end !important;
  }

  .lg\:builder-justify-items-center{
    justify-items: center !important;
  }

  .lg\:builder-justify-items-stretch{
    justify-items: stretch !important;
  }

  .lg\:builder-gap-0{
    gap: 0px !important;
  }

  .lg\:builder-gap-1{
    gap: 0.25rem !important;
  }

  .lg\:builder-gap-2{
    gap: 0.5rem !important;
  }

  .lg\:builder-gap-3{
    gap: 0.75rem !important;
  }

  .lg\:builder-gap-4{
    gap: 1rem !important;
  }

  .lg\:builder-gap-5{
    gap: 1.25rem !important;
  }

  .lg\:builder-gap-6{
    gap: 1.5rem !important;
  }

  .lg\:builder-gap-7{
    gap: 1.75rem !important;
  }

  .lg\:builder-gap-8{
    gap: 2rem !important;
  }

  .lg\:builder-gap-9{
    gap: 2.25rem !important;
  }

  .lg\:builder-gap-10{
    gap: 2.5rem !important;
  }

  .lg\:builder-gap-11{
    gap: 2.75rem !important;
  }

  .lg\:builder-gap-12{
    gap: 3rem !important;
  }

  .lg\:builder-gap-14{
    gap: 3.5rem !important;
  }

  .lg\:builder-gap-16{
    gap: 4rem !important;
  }

  .lg\:builder-gap-20{
    gap: 5rem !important;
  }

  .lg\:builder-gap-24{
    gap: 6rem !important;
  }

  .lg\:builder-gap-28{
    gap: 7rem !important;
  }

  .lg\:builder-gap-32{
    gap: 8rem !important;
  }

  .lg\:builder-gap-36{
    gap: 9rem !important;
  }

  .lg\:builder-gap-40{
    gap: 10rem !important;
  }

  .lg\:builder-gap-44{
    gap: 11rem !important;
  }

  .lg\:builder-gap-48{
    gap: 12rem !important;
  }

  .lg\:builder-gap-52{
    gap: 13rem !important;
  }

  .lg\:builder-gap-56{
    gap: 14rem !important;
  }

  .lg\:builder-gap-60{
    gap: 15rem !important;
  }

  .lg\:builder-gap-64{
    gap: 16rem !important;
  }

  .lg\:builder-gap-72{
    gap: 18rem !important;
  }

  .lg\:builder-gap-80{
    gap: 20rem !important;
  }

  .lg\:builder-gap-96{
    gap: 24rem !important;
  }

  .lg\:builder-gap-px{
    gap: 1px !important;
  }

  .lg\:builder-gap-0\.5{
    gap: 0.125rem !important;
  }

  .lg\:builder-gap-1\.5{
    gap: 0.375rem !important;
  }

  .lg\:builder-gap-2\.5{
    gap: 0.625rem !important;
  }

  .lg\:builder-gap-3\.5{
    gap: 0.875rem !important;
  }

  .lg\:builder-gap-x-0{
    column-gap: 0px !important;
  }

  .lg\:builder-gap-x-1{
    column-gap: 0.25rem !important;
  }

  .lg\:builder-gap-x-2{
    column-gap: 0.5rem !important;
  }

  .lg\:builder-gap-x-3{
    column-gap: 0.75rem !important;
  }

  .lg\:builder-gap-x-4{
    column-gap: 1rem !important;
  }

  .lg\:builder-gap-x-5{
    column-gap: 1.25rem !important;
  }

  .lg\:builder-gap-x-6{
    column-gap: 1.5rem !important;
  }

  .lg\:builder-gap-x-7{
    column-gap: 1.75rem !important;
  }

  .lg\:builder-gap-x-8{
    column-gap: 2rem !important;
  }

  .lg\:builder-gap-x-9{
    column-gap: 2.25rem !important;
  }

  .lg\:builder-gap-x-10{
    column-gap: 2.5rem !important;
  }

  .lg\:builder-gap-x-11{
    column-gap: 2.75rem !important;
  }

  .lg\:builder-gap-x-12{
    column-gap: 3rem !important;
  }

  .lg\:builder-gap-x-14{
    column-gap: 3.5rem !important;
  }

  .lg\:builder-gap-x-16{
    column-gap: 4rem !important;
  }

  .lg\:builder-gap-x-20{
    column-gap: 5rem !important;
  }

  .lg\:builder-gap-x-24{
    column-gap: 6rem !important;
  }

  .lg\:builder-gap-x-28{
    column-gap: 7rem !important;
  }

  .lg\:builder-gap-x-32{
    column-gap: 8rem !important;
  }

  .lg\:builder-gap-x-36{
    column-gap: 9rem !important;
  }

  .lg\:builder-gap-x-40{
    column-gap: 10rem !important;
  }

  .lg\:builder-gap-x-44{
    column-gap: 11rem !important;
  }

  .lg\:builder-gap-x-48{
    column-gap: 12rem !important;
  }

  .lg\:builder-gap-x-52{
    column-gap: 13rem !important;
  }

  .lg\:builder-gap-x-56{
    column-gap: 14rem !important;
  }

  .lg\:builder-gap-x-60{
    column-gap: 15rem !important;
  }

  .lg\:builder-gap-x-64{
    column-gap: 16rem !important;
  }

  .lg\:builder-gap-x-72{
    column-gap: 18rem !important;
  }

  .lg\:builder-gap-x-80{
    column-gap: 20rem !important;
  }

  .lg\:builder-gap-x-96{
    column-gap: 24rem !important;
  }

  .lg\:builder-gap-x-px{
    column-gap: 1px !important;
  }

  .lg\:builder-gap-x-0\.5{
    column-gap: 0.125rem !important;
  }

  .lg\:builder-gap-x-1\.5{
    column-gap: 0.375rem !important;
  }

  .lg\:builder-gap-x-2\.5{
    column-gap: 0.625rem !important;
  }

  .lg\:builder-gap-x-3\.5{
    column-gap: 0.875rem !important;
  }

  .lg\:builder-gap-y-0{
    row-gap: 0px !important;
  }

  .lg\:builder-gap-y-1{
    row-gap: 0.25rem !important;
  }

  .lg\:builder-gap-y-2{
    row-gap: 0.5rem !important;
  }

  .lg\:builder-gap-y-3{
    row-gap: 0.75rem !important;
  }

  .lg\:builder-gap-y-4{
    row-gap: 1rem !important;
  }

  .lg\:builder-gap-y-5{
    row-gap: 1.25rem !important;
  }

  .lg\:builder-gap-y-6{
    row-gap: 1.5rem !important;
  }

  .lg\:builder-gap-y-7{
    row-gap: 1.75rem !important;
  }

  .lg\:builder-gap-y-8{
    row-gap: 2rem !important;
  }

  .lg\:builder-gap-y-9{
    row-gap: 2.25rem !important;
  }

  .lg\:builder-gap-y-10{
    row-gap: 2.5rem !important;
  }

  .lg\:builder-gap-y-11{
    row-gap: 2.75rem !important;
  }

  .lg\:builder-gap-y-12{
    row-gap: 3rem !important;
  }

  .lg\:builder-gap-y-14{
    row-gap: 3.5rem !important;
  }

  .lg\:builder-gap-y-16{
    row-gap: 4rem !important;
  }

  .lg\:builder-gap-y-20{
    row-gap: 5rem !important;
  }

  .lg\:builder-gap-y-24{
    row-gap: 6rem !important;
  }

  .lg\:builder-gap-y-28{
    row-gap: 7rem !important;
  }

  .lg\:builder-gap-y-32{
    row-gap: 8rem !important;
  }

  .lg\:builder-gap-y-36{
    row-gap: 9rem !important;
  }

  .lg\:builder-gap-y-40{
    row-gap: 10rem !important;
  }

  .lg\:builder-gap-y-44{
    row-gap: 11rem !important;
  }

  .lg\:builder-gap-y-48{
    row-gap: 12rem !important;
  }

  .lg\:builder-gap-y-52{
    row-gap: 13rem !important;
  }

  .lg\:builder-gap-y-56{
    row-gap: 14rem !important;
  }

  .lg\:builder-gap-y-60{
    row-gap: 15rem !important;
  }

  .lg\:builder-gap-y-64{
    row-gap: 16rem !important;
  }

  .lg\:builder-gap-y-72{
    row-gap: 18rem !important;
  }

  .lg\:builder-gap-y-80{
    row-gap: 20rem !important;
  }

  .lg\:builder-gap-y-96{
    row-gap: 24rem !important;
  }

  .lg\:builder-gap-y-px{
    row-gap: 1px !important;
  }

  .lg\:builder-gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .lg\:builder-gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .lg\:builder-gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .lg\:builder-gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .lg\:builder-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:builder-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:builder-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .lg\:builder-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .lg\:builder-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:builder-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:builder-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:builder-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:builder-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:builder-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:builder-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:builder-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:builder-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:builder-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:builder-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .lg\:builder-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .lg\:builder-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .lg\:builder-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .lg\:builder-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .lg\:builder-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .lg\:builder-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .lg\:builder-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .lg\:builder-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .lg\:builder-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-primary > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-secondary-dark > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-secondary-darker > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity)) !important;
  }

  .lg\:builder-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .lg\:builder-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .lg\:builder-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .lg\:builder-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .lg\:builder-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .lg\:builder-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .lg\:builder-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .lg\:builder-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .lg\:builder-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .lg\:builder-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .lg\:builder-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .lg\:builder-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .lg\:builder-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .lg\:builder-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .lg\:builder-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .lg\:builder-place-self-auto{
    place-self: auto !important;
  }

  .lg\:builder-place-self-start{
    place-self: start !important;
  }

  .lg\:builder-place-self-end{
    place-self: end !important;
  }

  .lg\:builder-place-self-center{
    place-self: center !important;
  }

  .lg\:builder-place-self-stretch{
    place-self: stretch !important;
  }

  .lg\:builder-self-auto{
    align-self: auto !important;
  }

  .lg\:builder-self-start{
    align-self: flex-start !important;
  }

  .lg\:builder-self-end{
    align-self: flex-end !important;
  }

  .lg\:builder-self-center{
    align-self: center !important;
  }

  .lg\:builder-self-stretch{
    align-self: stretch !important;
  }

  .lg\:builder-self-baseline{
    align-self: baseline !important;
  }

  .lg\:builder-justify-self-auto{
    justify-self: auto !important;
  }

  .lg\:builder-justify-self-start{
    justify-self: start !important;
  }

  .lg\:builder-justify-self-end{
    justify-self: end !important;
  }

  .lg\:builder-justify-self-center{
    justify-self: center !important;
  }

  .lg\:builder-justify-self-stretch{
    justify-self: stretch !important;
  }

  .lg\:builder-overflow-auto{
    overflow: auto !important;
  }

  .lg\:builder-overflow-hidden{
    overflow: hidden !important;
  }

  .lg\:builder-overflow-visible{
    overflow: visible !important;
  }

  .lg\:builder-overflow-scroll{
    overflow: scroll !important;
  }

  .lg\:builder-overflow-x-auto{
    overflow-x: auto !important;
  }

  .lg\:builder-overflow-y-auto{
    overflow-y: auto !important;
  }

  .lg\:builder-overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .lg\:builder-overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .lg\:builder-overflow-x-visible{
    overflow-x: visible !important;
  }

  .lg\:builder-overflow-y-visible{
    overflow-y: visible !important;
  }

  .lg\:builder-overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .lg\:builder-overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .lg\:builder-overscroll-auto{
    overscroll-behavior: auto !important;
  }

  .lg\:builder-overscroll-contain{
    overscroll-behavior: contain !important;
  }

  .lg\:builder-overscroll-none{
    overscroll-behavior: none !important;
  }

  .lg\:builder-overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .lg\:builder-overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .lg\:builder-overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .lg\:builder-overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .lg\:builder-overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .lg\:builder-overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .lg\:builder-truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lg\:builder-overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .lg\:builder-overflow-clip{
    text-overflow: clip !important;
  }

  .lg\:builder-whitespace-normal{
    white-space: normal !important;
  }

  .lg\:builder-whitespace-nowrap{
    white-space: nowrap !important;
  }

  .lg\:builder-whitespace-pre{
    white-space: pre !important;
  }

  .lg\:builder-whitespace-pre-line{
    white-space: pre-line !important;
  }

  .lg\:builder-whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .lg\:builder-break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lg\:builder-break-words{
    overflow-wrap: break-word !important;
  }

  .lg\:builder-break-all{
    word-break: break-all !important;
  }

  .lg\:builder-rounded-none{
    border-radius: 0px !important;
  }

  .lg\:builder-rounded-sm{
    border-radius: 0.125rem !important;
  }

  .lg\:builder-rounded{
    border-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-md{
    border-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-lg{
    border-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-xl{
    border-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-2xl{
    border-radius: 1rem !important;
  }

  .lg\:builder-rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-full{
    border-radius: 9999px !important;
  }

  .lg\:builder-rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .lg\:builder-rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .lg\:builder-rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lg\:builder-rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .lg\:builder-rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:builder-rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:builder-rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .lg\:builder-rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:builder-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:builder-rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .lg\:builder-rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:builder-rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:builder-rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .lg\:builder-rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .lg\:builder-rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .lg\:builder-rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .lg\:builder-rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .lg\:builder-rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .lg\:builder-rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .lg\:builder-rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:builder-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:builder-rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .lg\:builder-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:builder-rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:builder-rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:builder-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:builder-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:builder-rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:builder-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:builder-rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:builder-border-0{
    border-width: 0px !important;
  }

  .lg\:builder-border-2{
    border-width: 2px !important;
  }

  .lg\:builder-border-4{
    border-width: 4px !important;
  }

  .lg\:builder-border-8{
    border-width: 8px !important;
  }

  .lg\:builder-border{
    border-width: 1px !important;
  }

  .lg\:builder-border-t-0{
    border-top-width: 0px !important;
  }

  .lg\:builder-border-t-2{
    border-top-width: 2px !important;
  }

  .lg\:builder-border-t-4{
    border-top-width: 4px !important;
  }

  .lg\:builder-border-t-8{
    border-top-width: 8px !important;
  }

  .lg\:builder-border-t{
    border-top-width: 1px !important;
  }

  .lg\:builder-border-r-0{
    border-right-width: 0px !important;
  }

  .lg\:builder-border-r-2{
    border-right-width: 2px !important;
  }

  .lg\:builder-border-r-4{
    border-right-width: 4px !important;
  }

  .lg\:builder-border-r-8{
    border-right-width: 8px !important;
  }

  .lg\:builder-border-r{
    border-right-width: 1px !important;
  }

  .lg\:builder-border-b-0{
    border-bottom-width: 0px !important;
  }

  .lg\:builder-border-b-2{
    border-bottom-width: 2px !important;
  }

  .lg\:builder-border-b-4{
    border-bottom-width: 4px !important;
  }

  .lg\:builder-border-b-8{
    border-bottom-width: 8px !important;
  }

  .lg\:builder-border-b{
    border-bottom-width: 1px !important;
  }

  .lg\:builder-border-l-0{
    border-left-width: 0px !important;
  }

  .lg\:builder-border-l-2{
    border-left-width: 2px !important;
  }

  .lg\:builder-border-l-4{
    border-left-width: 4px !important;
  }

  .lg\:builder-border-l-8{
    border-left-width: 8px !important;
  }

  .lg\:builder-border-l{
    border-left-width: 1px !important;
  }

  .lg\:builder-border-solid{
    border-style: solid !important;
  }

  .lg\:builder-border-dashed{
    border-style: dashed !important;
  }

  .lg\:builder-border-dotted{
    border-style: dotted !important;
  }

  .lg\:builder-border-double{
    border-style: double !important;
  }

  .lg\:builder-border-none{
    border-style: none !important;
  }

  .lg\:builder-border-transparent{
    border-color: transparent !important;
  }

  .lg\:builder-border-current{
    border-color: currentColor !important;
  }

  .lg\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-transparent{
    border-color: transparent !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-current{
    border-color: currentColor !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-transparent:focus-within{
    border-color: transparent !important;
  }

  .lg\:focus-within\:builder-border-current:focus-within{
    border-color: currentColor !important;
  }

  .lg\:focus-within\:builder-border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-primary:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-secondary-dark:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:builder-border-secondary-darker:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-transparent:hover{
    border-color: transparent !important;
  }

  .lg\:hover\:builder-border-current:hover{
    border-color: currentColor !important;
  }

  .lg\:hover\:builder-border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-primary:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-secondary-dark:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:builder-border-secondary-darker:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-transparent:focus{
    border-color: transparent !important;
  }

  .lg\:focus\:builder-border-current:focus{
    border-color: currentColor !important;
  }

  .lg\:focus\:builder-border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-primary:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-secondary-dark:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:builder-border-secondary-darker:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .lg\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .lg\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .lg\:focus-within\:builder-border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .lg\:focus-within\:builder-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:focus-within\:builder-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:focus-within\:builder-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:focus-within\:builder-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:focus-within\:builder-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:focus-within\:builder-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:focus-within\:builder-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:focus-within\:builder-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:focus-within\:builder-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:focus-within\:builder-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:focus-within\:builder-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:focus-within\:builder-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:focus-within\:builder-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:focus-within\:builder-border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .lg\:hover\:builder-border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .lg\:hover\:builder-border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:hover\:builder-border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:hover\:builder-border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:hover\:builder-border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:hover\:builder-border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:hover\:builder-border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:hover\:builder-border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:hover\:builder-border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:hover\:builder-border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:hover\:builder-border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:hover\:builder-border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:hover\:builder-border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:hover\:builder-border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:hover\:builder-border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .lg\:focus\:builder-border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .lg\:focus\:builder-border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:focus\:builder-border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:focus\:builder-border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:focus\:builder-border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:focus\:builder-border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:focus\:builder-border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:focus\:builder-border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:focus\:builder-border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:focus\:builder-border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:focus\:builder-border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:focus\:builder-border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:focus\:builder-border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:focus\:builder-border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:focus\:builder-border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .lg\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .lg\:builder-bg-current{
    background-color: currentColor !important;
  }

  .lg\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-current{
    background-color: currentColor !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .lg\:focus-within\:builder-bg-current:focus-within{
    background-color: currentColor !important;
  }

  .lg\:focus-within\:builder-bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-primary:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-secondary-dark:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:builder-bg-secondary-darker:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-transparent:hover{
    background-color: transparent !important;
  }

  .lg\:hover\:builder-bg-current:hover{
    background-color: currentColor !important;
  }

  .lg\:hover\:builder-bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-primary:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-secondary-dark:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:builder-bg-secondary-darker:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-transparent:focus{
    background-color: transparent !important;
  }

  .lg\:focus\:builder-bg-current:focus{
    background-color: currentColor !important;
  }

  .lg\:focus\:builder-bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-primary:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-secondary-dark:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:builder-bg-secondary-darker:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .lg\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:focus-within\:builder-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:hover\:builder-bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:hover\:builder-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:hover\:builder-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:hover\:builder-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:hover\:builder-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:hover\:builder-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:hover\:builder-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:hover\:builder-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:hover\:builder-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:hover\:builder-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:hover\:builder-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:hover\:builder-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:hover\:builder-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:hover\:builder-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:hover\:builder-bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:focus\:builder-bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:focus\:builder-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:focus\:builder-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:focus\:builder-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:focus\:builder-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:focus\:builder-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:focus\:builder-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:focus\:builder-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:focus\:builder-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:focus\:builder-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:focus\:builder-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:focus\:builder-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:focus\:builder-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:focus\:builder-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:focus\:builder-bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:builder-bg-none{
    background-image: none !important;
  }

  .lg\:builder-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .lg\:builder-from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:builder-from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:builder-from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:builder-from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:builder-from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:builder-from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:builder-from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:builder-from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:builder-from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:builder-from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:builder-from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:builder-from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:builder-from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:builder-from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:builder-from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:builder-from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:builder-from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:builder-from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:builder-from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:builder-from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:builder-from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:builder-from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:builder-from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:builder-from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:builder-from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:builder-from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:builder-from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:builder-from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:builder-from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:builder-from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:builder-from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:builder-from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:builder-from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:builder-from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:builder-from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:builder-from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:builder-from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:builder-from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:builder-from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:builder-from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:builder-from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:builder-from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:builder-from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:builder-from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:builder-from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:builder-from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:builder-from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:builder-from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:builder-from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:builder-from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:builder-from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:builder-from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:builder-from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:builder-from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:builder-from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:builder-from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:builder-from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:builder-from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:builder-from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:builder-from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:builder-from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:builder-from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:builder-from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:builder-from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:builder-from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:builder-from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:builder-from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:builder-from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:builder-from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:builder-from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:builder-from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:builder-from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:builder-from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:builder-from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:builder-from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:builder-from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:builder-from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:builder-from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:builder-from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:builder-from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:builder-from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:builder-from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:builder-from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:builder-from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:builder-from-primary{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .lg\:builder-from-secondary-dark{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .lg\:builder-from-secondary-darker{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .lg\:hover\:builder-from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:builder-from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:builder-from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:builder-from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:hover\:builder-from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:hover\:builder-from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:hover\:builder-from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:hover\:builder-from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:hover\:builder-from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:hover\:builder-from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:hover\:builder-from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:hover\:builder-from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:hover\:builder-from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:hover\:builder-from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:hover\:builder-from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:hover\:builder-from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:hover\:builder-from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:hover\:builder-from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:hover\:builder-from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:hover\:builder-from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:hover\:builder-from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:hover\:builder-from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:hover\:builder-from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:hover\:builder-from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:hover\:builder-from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:hover\:builder-from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:hover\:builder-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:hover\:builder-from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:hover\:builder-from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:hover\:builder-from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:hover\:builder-from-primary:hover{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .lg\:hover\:builder-from-secondary-dark:hover{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .lg\:hover\:builder-from-secondary-darker:hover{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .lg\:focus\:builder-from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:builder-from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:builder-from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:builder-from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:focus\:builder-from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:focus\:builder-from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:focus\:builder-from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:focus\:builder-from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:focus\:builder-from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:focus\:builder-from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:focus\:builder-from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:focus\:builder-from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:focus\:builder-from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:focus\:builder-from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:focus\:builder-from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:focus\:builder-from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:focus\:builder-from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:focus\:builder-from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:focus\:builder-from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:focus\:builder-from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:focus\:builder-from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:focus\:builder-from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:focus\:builder-from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:focus\:builder-from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:focus\:builder-from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:focus\:builder-from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:focus\:builder-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:focus\:builder-from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:focus\:builder-from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:focus\:builder-from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:focus\:builder-from-primary:focus{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .lg\:focus\:builder-from-secondary-dark:focus{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .lg\:focus\:builder-from-secondary-darker:focus{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .lg\:builder-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:builder-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:builder-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:builder-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:builder-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:builder-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:builder-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:builder-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:builder-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:builder-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:builder-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:builder-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:builder-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:builder-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:builder-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:builder-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:builder-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:builder-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:builder-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:builder-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:builder-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:builder-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:builder-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:builder-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:builder-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:builder-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:builder-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:builder-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:builder-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:builder-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:builder-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:builder-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:builder-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:builder-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:builder-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:builder-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:builder-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:builder-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:builder-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:builder-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:builder-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:builder-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:builder-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:builder-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:builder-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:builder-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:builder-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:builder-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:builder-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:builder-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:builder-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:builder-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:builder-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:builder-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:builder-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:builder-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:builder-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:builder-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:builder-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:builder-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:builder-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:builder-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:builder-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:builder-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:builder-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:builder-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:builder-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:builder-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:builder-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:builder-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:builder-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:builder-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:builder-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:builder-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:builder-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:builder-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:builder-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:builder-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:builder-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:builder-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:builder-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:builder-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:builder-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:builder-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:builder-via-primary{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .lg\:builder-via-secondary-dark{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .lg\:builder-via-secondary-darker{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .lg\:hover\:builder-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:builder-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:builder-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:builder-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:hover\:builder-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:hover\:builder-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:hover\:builder-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:hover\:builder-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:hover\:builder-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:hover\:builder-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:hover\:builder-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:hover\:builder-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:hover\:builder-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:hover\:builder-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:hover\:builder-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:hover\:builder-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:hover\:builder-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:hover\:builder-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:hover\:builder-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:hover\:builder-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:hover\:builder-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:hover\:builder-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:hover\:builder-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:hover\:builder-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:hover\:builder-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:hover\:builder-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:hover\:builder-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:hover\:builder-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:hover\:builder-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:hover\:builder-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:hover\:builder-via-primary:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .lg\:hover\:builder-via-secondary-dark:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .lg\:hover\:builder-via-secondary-darker:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .lg\:focus\:builder-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:builder-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:builder-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:builder-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:focus\:builder-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:focus\:builder-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:focus\:builder-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:focus\:builder-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:focus\:builder-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:focus\:builder-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:focus\:builder-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:focus\:builder-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:focus\:builder-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:focus\:builder-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:focus\:builder-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:focus\:builder-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:focus\:builder-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:focus\:builder-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:focus\:builder-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:focus\:builder-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:focus\:builder-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:focus\:builder-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:focus\:builder-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:focus\:builder-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:focus\:builder-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:focus\:builder-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:focus\:builder-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:focus\:builder-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:focus\:builder-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:focus\:builder-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:focus\:builder-via-primary:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .lg\:focus\:builder-via-secondary-dark:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .lg\:focus\:builder-via-secondary-darker:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .lg\:builder-to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .lg\:builder-to-current{
    --tw-gradient-to: currentColor !important;
  }

  .lg\:builder-to-black{
    --tw-gradient-to: #000 !important;
  }

  .lg\:builder-to-white{
    --tw-gradient-to: #fff !important;
  }

  .lg\:builder-to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .lg\:builder-to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .lg\:builder-to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .lg\:builder-to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .lg\:builder-to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .lg\:builder-to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .lg\:builder-to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .lg\:builder-to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .lg\:builder-to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .lg\:builder-to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .lg\:builder-to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .lg\:builder-to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .lg\:builder-to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .lg\:builder-to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .lg\:builder-to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .lg\:builder-to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .lg\:builder-to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .lg\:builder-to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .lg\:builder-to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .lg\:builder-to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .lg\:builder-to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .lg\:builder-to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .lg\:builder-to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .lg\:builder-to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .lg\:builder-to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .lg\:builder-to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .lg\:builder-to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .lg\:builder-to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .lg\:builder-to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .lg\:builder-to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .lg\:builder-to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .lg\:builder-to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .lg\:builder-to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .lg\:builder-to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .lg\:builder-to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .lg\:builder-to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .lg\:builder-to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .lg\:builder-to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .lg\:builder-to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .lg\:builder-to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .lg\:builder-to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .lg\:builder-to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .lg\:builder-to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .lg\:builder-to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .lg\:builder-to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .lg\:builder-to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .lg\:builder-to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .lg\:builder-to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .lg\:builder-to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .lg\:builder-to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .lg\:builder-to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .lg\:builder-to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .lg\:builder-to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .lg\:builder-to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .lg\:builder-to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .lg\:builder-to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .lg\:builder-to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .lg\:builder-to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .lg\:builder-to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .lg\:builder-to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .lg\:builder-to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .lg\:builder-to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .lg\:builder-to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .lg\:builder-to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .lg\:builder-to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .lg\:builder-to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .lg\:builder-to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .lg\:builder-to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .lg\:builder-to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .lg\:builder-to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .lg\:builder-to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .lg\:builder-to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .lg\:builder-to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .lg\:builder-to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .lg\:builder-to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .lg\:builder-to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .lg\:builder-to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .lg\:builder-to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .lg\:builder-to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .lg\:builder-to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .lg\:builder-to-primary{
    --tw-gradient-to: #2d375a !important;
  }

  .lg\:builder-to-secondary-dark{
    --tw-gradient-to: #343434 !important;
  }

  .lg\:builder-to-secondary-darker{
    --tw-gradient-to: #212121 !important;
  }

  .lg\:hover\:builder-to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .lg\:hover\:builder-to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .lg\:hover\:builder-to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .lg\:hover\:builder-to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .lg\:hover\:builder-to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .lg\:hover\:builder-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .lg\:hover\:builder-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .lg\:hover\:builder-to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .lg\:hover\:builder-to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .lg\:hover\:builder-to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .lg\:hover\:builder-to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .lg\:hover\:builder-to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .lg\:hover\:builder-to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .lg\:hover\:builder-to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .lg\:hover\:builder-to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .lg\:hover\:builder-to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .lg\:hover\:builder-to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .lg\:hover\:builder-to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .lg\:hover\:builder-to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .lg\:hover\:builder-to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .lg\:hover\:builder-to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .lg\:hover\:builder-to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .lg\:hover\:builder-to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .lg\:hover\:builder-to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .lg\:hover\:builder-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .lg\:hover\:builder-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .lg\:hover\:builder-to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .lg\:hover\:builder-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .lg\:hover\:builder-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .lg\:hover\:builder-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .lg\:hover\:builder-to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .lg\:hover\:builder-to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .lg\:hover\:builder-to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .lg\:hover\:builder-to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .lg\:hover\:builder-to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .lg\:hover\:builder-to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .lg\:hover\:builder-to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .lg\:hover\:builder-to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .lg\:hover\:builder-to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .lg\:hover\:builder-to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .lg\:hover\:builder-to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .lg\:hover\:builder-to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .lg\:hover\:builder-to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .lg\:hover\:builder-to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .lg\:hover\:builder-to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .lg\:hover\:builder-to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .lg\:hover\:builder-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .lg\:hover\:builder-to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .lg\:hover\:builder-to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .lg\:hover\:builder-to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .lg\:hover\:builder-to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .lg\:hover\:builder-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .lg\:hover\:builder-to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .lg\:hover\:builder-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .lg\:hover\:builder-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .lg\:hover\:builder-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .lg\:hover\:builder-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .lg\:hover\:builder-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .lg\:hover\:builder-to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .lg\:hover\:builder-to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .lg\:hover\:builder-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .lg\:hover\:builder-to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .lg\:hover\:builder-to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .lg\:hover\:builder-to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .lg\:hover\:builder-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .lg\:hover\:builder-to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .lg\:hover\:builder-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .lg\:hover\:builder-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .lg\:hover\:builder-to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .lg\:hover\:builder-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .lg\:hover\:builder-to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .lg\:hover\:builder-to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .lg\:hover\:builder-to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .lg\:hover\:builder-to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .lg\:hover\:builder-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .lg\:hover\:builder-to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .lg\:hover\:builder-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .lg\:hover\:builder-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .lg\:hover\:builder-to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .lg\:hover\:builder-to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .lg\:hover\:builder-to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .lg\:hover\:builder-to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .lg\:hover\:builder-to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .lg\:hover\:builder-to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .lg\:hover\:builder-to-primary:hover{
    --tw-gradient-to: #2d375a !important;
  }

  .lg\:hover\:builder-to-secondary-dark:hover{
    --tw-gradient-to: #343434 !important;
  }

  .lg\:hover\:builder-to-secondary-darker:hover{
    --tw-gradient-to: #212121 !important;
  }

  .lg\:focus\:builder-to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .lg\:focus\:builder-to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .lg\:focus\:builder-to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .lg\:focus\:builder-to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .lg\:focus\:builder-to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .lg\:focus\:builder-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .lg\:focus\:builder-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .lg\:focus\:builder-to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .lg\:focus\:builder-to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .lg\:focus\:builder-to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .lg\:focus\:builder-to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .lg\:focus\:builder-to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .lg\:focus\:builder-to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .lg\:focus\:builder-to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .lg\:focus\:builder-to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .lg\:focus\:builder-to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .lg\:focus\:builder-to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .lg\:focus\:builder-to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .lg\:focus\:builder-to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .lg\:focus\:builder-to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .lg\:focus\:builder-to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .lg\:focus\:builder-to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .lg\:focus\:builder-to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .lg\:focus\:builder-to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .lg\:focus\:builder-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .lg\:focus\:builder-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .lg\:focus\:builder-to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .lg\:focus\:builder-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .lg\:focus\:builder-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .lg\:focus\:builder-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .lg\:focus\:builder-to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .lg\:focus\:builder-to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .lg\:focus\:builder-to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .lg\:focus\:builder-to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .lg\:focus\:builder-to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .lg\:focus\:builder-to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .lg\:focus\:builder-to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .lg\:focus\:builder-to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .lg\:focus\:builder-to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .lg\:focus\:builder-to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .lg\:focus\:builder-to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .lg\:focus\:builder-to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .lg\:focus\:builder-to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .lg\:focus\:builder-to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .lg\:focus\:builder-to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .lg\:focus\:builder-to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .lg\:focus\:builder-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .lg\:focus\:builder-to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .lg\:focus\:builder-to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .lg\:focus\:builder-to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .lg\:focus\:builder-to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .lg\:focus\:builder-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .lg\:focus\:builder-to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .lg\:focus\:builder-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .lg\:focus\:builder-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .lg\:focus\:builder-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .lg\:focus\:builder-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .lg\:focus\:builder-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .lg\:focus\:builder-to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .lg\:focus\:builder-to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .lg\:focus\:builder-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .lg\:focus\:builder-to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .lg\:focus\:builder-to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .lg\:focus\:builder-to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .lg\:focus\:builder-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .lg\:focus\:builder-to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .lg\:focus\:builder-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .lg\:focus\:builder-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .lg\:focus\:builder-to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .lg\:focus\:builder-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .lg\:focus\:builder-to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .lg\:focus\:builder-to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .lg\:focus\:builder-to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .lg\:focus\:builder-to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .lg\:focus\:builder-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .lg\:focus\:builder-to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .lg\:focus\:builder-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .lg\:focus\:builder-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .lg\:focus\:builder-to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .lg\:focus\:builder-to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .lg\:focus\:builder-to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .lg\:focus\:builder-to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .lg\:focus\:builder-to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .lg\:focus\:builder-to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .lg\:focus\:builder-to-primary:focus{
    --tw-gradient-to: #2d375a !important;
  }

  .lg\:focus\:builder-to-secondary-dark:focus{
    --tw-gradient-to: #343434 !important;
  }

  .lg\:focus\:builder-to-secondary-darker:focus{
    --tw-gradient-to: #212121 !important;
  }

  .lg\:builder-decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .lg\:builder-decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .lg\:builder-bg-auto{
    background-size: auto !important;
  }

  .lg\:builder-bg-cover{
    background-size: cover !important;
  }

  .lg\:builder-bg-contain{
    background-size: contain !important;
  }

  .lg\:builder-bg-fixed{
    background-attachment: fixed !important;
  }

  .lg\:builder-bg-local{
    background-attachment: local !important;
  }

  .lg\:builder-bg-scroll{
    background-attachment: scroll !important;
  }

  .lg\:builder-bg-clip-border{
    background-clip: border-box !important;
  }

  .lg\:builder-bg-clip-padding{
    background-clip: padding-box !important;
  }

  .lg\:builder-bg-clip-content{
    background-clip: content-box !important;
  }

  .lg\:builder-bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .lg\:builder-bg-bottom{
    background-position: bottom !important;
  }

  .lg\:builder-bg-center{
    background-position: center !important;
  }

  .lg\:builder-bg-left{
    background-position: left !important;
  }

  .lg\:builder-bg-left-bottom{
    background-position: left bottom !important;
  }

  .lg\:builder-bg-left-top{
    background-position: left top !important;
  }

  .lg\:builder-bg-right{
    background-position: right !important;
  }

  .lg\:builder-bg-right-bottom{
    background-position: right bottom !important;
  }

  .lg\:builder-bg-right-top{
    background-position: right top !important;
  }

  .lg\:builder-bg-top{
    background-position: top !important;
  }

  .lg\:builder-bg-repeat{
    background-repeat: repeat !important;
  }

  .lg\:builder-bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .lg\:builder-bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .lg\:builder-bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .lg\:builder-bg-repeat-round{
    background-repeat: round !important;
  }

  .lg\:builder-bg-repeat-space{
    background-repeat: space !important;
  }

  .lg\:builder-bg-origin-border{
    background-origin: border-box !important;
  }

  .lg\:builder-bg-origin-padding{
    background-origin: padding-box !important;
  }

  .lg\:builder-bg-origin-content{
    background-origin: content-box !important;
  }

  .lg\:builder-fill-current{
    fill: currentColor !important;
  }

  .lg\:builder-stroke-current{
    stroke: currentColor !important;
  }

  .lg\:builder-stroke-0{
    stroke-width: 0 !important;
  }

  .lg\:builder-stroke-1{
    stroke-width: 1 !important;
  }

  .lg\:builder-stroke-2{
    stroke-width: 2 !important;
  }

  .lg\:builder-object-contain{
    object-fit: contain !important;
  }

  .lg\:builder-object-cover{
    object-fit: cover !important;
  }

  .lg\:builder-object-fill{
    object-fit: fill !important;
  }

  .lg\:builder-object-none{
    object-fit: none !important;
  }

  .lg\:builder-object-scale-down{
    object-fit: scale-down !important;
  }

  .lg\:builder-object-bottom{
    object-position: bottom !important;
  }

  .lg\:builder-object-center{
    object-position: center !important;
  }

  .lg\:builder-object-left{
    object-position: left !important;
  }

  .lg\:builder-object-left-bottom{
    object-position: left bottom !important;
  }

  .lg\:builder-object-left-top{
    object-position: left top !important;
  }

  .lg\:builder-object-right{
    object-position: right !important;
  }

  .lg\:builder-object-right-bottom{
    object-position: right bottom !important;
  }

  .lg\:builder-object-right-top{
    object-position: right top !important;
  }

  .lg\:builder-object-top{
    object-position: top !important;
  }

  .lg\:builder-p-0{
    padding: 0px !important;
  }

  .lg\:builder-p-1{
    padding: 0.25rem !important;
  }

  .lg\:builder-p-2{
    padding: 0.5rem !important;
  }

  .lg\:builder-p-3{
    padding: 0.75rem !important;
  }

  .lg\:builder-p-4{
    padding: 1rem !important;
  }

  .lg\:builder-p-5{
    padding: 1.25rem !important;
  }

  .lg\:builder-p-6{
    padding: 1.5rem !important;
  }

  .lg\:builder-p-7{
    padding: 1.75rem !important;
  }

  .lg\:builder-p-8{
    padding: 2rem !important;
  }

  .lg\:builder-p-9{
    padding: 2.25rem !important;
  }

  .lg\:builder-p-10{
    padding: 2.5rem !important;
  }

  .lg\:builder-p-11{
    padding: 2.75rem !important;
  }

  .lg\:builder-p-12{
    padding: 3rem !important;
  }

  .lg\:builder-p-14{
    padding: 3.5rem !important;
  }

  .lg\:builder-p-16{
    padding: 4rem !important;
  }

  .lg\:builder-p-20{
    padding: 5rem !important;
  }

  .lg\:builder-p-24{
    padding: 6rem !important;
  }

  .lg\:builder-p-28{
    padding: 7rem !important;
  }

  .lg\:builder-p-32{
    padding: 8rem !important;
  }

  .lg\:builder-p-36{
    padding: 9rem !important;
  }

  .lg\:builder-p-40{
    padding: 10rem !important;
  }

  .lg\:builder-p-44{
    padding: 11rem !important;
  }

  .lg\:builder-p-48{
    padding: 12rem !important;
  }

  .lg\:builder-p-52{
    padding: 13rem !important;
  }

  .lg\:builder-p-56{
    padding: 14rem !important;
  }

  .lg\:builder-p-60{
    padding: 15rem !important;
  }

  .lg\:builder-p-64{
    padding: 16rem !important;
  }

  .lg\:builder-p-72{
    padding: 18rem !important;
  }

  .lg\:builder-p-80{
    padding: 20rem !important;
  }

  .lg\:builder-p-96{
    padding: 24rem !important;
  }

  .lg\:builder-p-px{
    padding: 1px !important;
  }

  .lg\:builder-p-0\.5{
    padding: 0.125rem !important;
  }

  .lg\:builder-p-1\.5{
    padding: 0.375rem !important;
  }

  .lg\:builder-p-2\.5{
    padding: 0.625rem !important;
  }

  .lg\:builder-p-3\.5{
    padding: 0.875rem !important;
  }

  .lg\:builder-px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .lg\:builder-px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lg\:builder-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lg\:builder-px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lg\:builder-px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:builder-px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lg\:builder-px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:builder-px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .lg\:builder-px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:builder-px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .lg\:builder-px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lg\:builder-px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .lg\:builder-px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:builder-px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .lg\:builder-px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:builder-px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:builder-px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lg\:builder-px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lg\:builder-px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lg\:builder-px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lg\:builder-px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lg\:builder-px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .lg\:builder-px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lg\:builder-px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .lg\:builder-px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lg\:builder-px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .lg\:builder-px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lg\:builder-px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .lg\:builder-px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .lg\:builder-px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .lg\:builder-px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lg\:builder-px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .lg\:builder-px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .lg\:builder-px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .lg\:builder-px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .lg\:builder-py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .lg\:builder-py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lg\:builder-py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lg\:builder-py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lg\:builder-py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:builder-py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lg\:builder-py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:builder-py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .lg\:builder-py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:builder-py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .lg\:builder-py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lg\:builder-py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .lg\:builder-py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:builder-py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .lg\:builder-py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:builder-py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lg\:builder-py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:builder-py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lg\:builder-py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lg\:builder-py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lg\:builder-py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lg\:builder-py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .lg\:builder-py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lg\:builder-py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .lg\:builder-py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lg\:builder-py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .lg\:builder-py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lg\:builder-py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .lg\:builder-py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .lg\:builder-py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .lg\:builder-py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lg\:builder-py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .lg\:builder-py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .lg\:builder-py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .lg\:builder-py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .lg\:builder-pt-0{
    padding-top: 0px !important;
  }

  .lg\:builder-pt-1{
    padding-top: 0.25rem !important;
  }

  .lg\:builder-pt-2{
    padding-top: 0.5rem !important;
  }

  .lg\:builder-pt-3{
    padding-top: 0.75rem !important;
  }

  .lg\:builder-pt-4{
    padding-top: 1rem !important;
  }

  .lg\:builder-pt-5{
    padding-top: 1.25rem !important;
  }

  .lg\:builder-pt-6{
    padding-top: 1.5rem !important;
  }

  .lg\:builder-pt-7{
    padding-top: 1.75rem !important;
  }

  .lg\:builder-pt-8{
    padding-top: 2rem !important;
  }

  .lg\:builder-pt-9{
    padding-top: 2.25rem !important;
  }

  .lg\:builder-pt-10{
    padding-top: 2.5rem !important;
  }

  .lg\:builder-pt-11{
    padding-top: 2.75rem !important;
  }

  .lg\:builder-pt-12{
    padding-top: 3rem !important;
  }

  .lg\:builder-pt-14{
    padding-top: 3.5rem !important;
  }

  .lg\:builder-pt-16{
    padding-top: 4rem !important;
  }

  .lg\:builder-pt-20{
    padding-top: 5rem !important;
  }

  .lg\:builder-pt-24{
    padding-top: 6rem !important;
  }

  .lg\:builder-pt-28{
    padding-top: 7rem !important;
  }

  .lg\:builder-pt-32{
    padding-top: 8rem !important;
  }

  .lg\:builder-pt-36{
    padding-top: 9rem !important;
  }

  .lg\:builder-pt-40{
    padding-top: 10rem !important;
  }

  .lg\:builder-pt-44{
    padding-top: 11rem !important;
  }

  .lg\:builder-pt-48{
    padding-top: 12rem !important;
  }

  .lg\:builder-pt-52{
    padding-top: 13rem !important;
  }

  .lg\:builder-pt-56{
    padding-top: 14rem !important;
  }

  .lg\:builder-pt-60{
    padding-top: 15rem !important;
  }

  .lg\:builder-pt-64{
    padding-top: 16rem !important;
  }

  .lg\:builder-pt-72{
    padding-top: 18rem !important;
  }

  .lg\:builder-pt-80{
    padding-top: 20rem !important;
  }

  .lg\:builder-pt-96{
    padding-top: 24rem !important;
  }

  .lg\:builder-pt-px{
    padding-top: 1px !important;
  }

  .lg\:builder-pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .lg\:builder-pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .lg\:builder-pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .lg\:builder-pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .lg\:builder-pr-0{
    padding-right: 0px !important;
  }

  .lg\:builder-pr-1{
    padding-right: 0.25rem !important;
  }

  .lg\:builder-pr-2{
    padding-right: 0.5rem !important;
  }

  .lg\:builder-pr-3{
    padding-right: 0.75rem !important;
  }

  .lg\:builder-pr-4{
    padding-right: 1rem !important;
  }

  .lg\:builder-pr-5{
    padding-right: 1.25rem !important;
  }

  .lg\:builder-pr-6{
    padding-right: 1.5rem !important;
  }

  .lg\:builder-pr-7{
    padding-right: 1.75rem !important;
  }

  .lg\:builder-pr-8{
    padding-right: 2rem !important;
  }

  .lg\:builder-pr-9{
    padding-right: 2.25rem !important;
  }

  .lg\:builder-pr-10{
    padding-right: 2.5rem !important;
  }

  .lg\:builder-pr-11{
    padding-right: 2.75rem !important;
  }

  .lg\:builder-pr-12{
    padding-right: 3rem !important;
  }

  .lg\:builder-pr-14{
    padding-right: 3.5rem !important;
  }

  .lg\:builder-pr-16{
    padding-right: 4rem !important;
  }

  .lg\:builder-pr-20{
    padding-right: 5rem !important;
  }

  .lg\:builder-pr-24{
    padding-right: 6rem !important;
  }

  .lg\:builder-pr-28{
    padding-right: 7rem !important;
  }

  .lg\:builder-pr-32{
    padding-right: 8rem !important;
  }

  .lg\:builder-pr-36{
    padding-right: 9rem !important;
  }

  .lg\:builder-pr-40{
    padding-right: 10rem !important;
  }

  .lg\:builder-pr-44{
    padding-right: 11rem !important;
  }

  .lg\:builder-pr-48{
    padding-right: 12rem !important;
  }

  .lg\:builder-pr-52{
    padding-right: 13rem !important;
  }

  .lg\:builder-pr-56{
    padding-right: 14rem !important;
  }

  .lg\:builder-pr-60{
    padding-right: 15rem !important;
  }

  .lg\:builder-pr-64{
    padding-right: 16rem !important;
  }

  .lg\:builder-pr-72{
    padding-right: 18rem !important;
  }

  .lg\:builder-pr-80{
    padding-right: 20rem !important;
  }

  .lg\:builder-pr-96{
    padding-right: 24rem !important;
  }

  .lg\:builder-pr-px{
    padding-right: 1px !important;
  }

  .lg\:builder-pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .lg\:builder-pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .lg\:builder-pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .lg\:builder-pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .lg\:builder-pb-0{
    padding-bottom: 0px !important;
  }

  .lg\:builder-pb-1{
    padding-bottom: 0.25rem !important;
  }

  .lg\:builder-pb-2{
    padding-bottom: 0.5rem !important;
  }

  .lg\:builder-pb-3{
    padding-bottom: 0.75rem !important;
  }

  .lg\:builder-pb-4{
    padding-bottom: 1rem !important;
  }

  .lg\:builder-pb-5{
    padding-bottom: 1.25rem !important;
  }

  .lg\:builder-pb-6{
    padding-bottom: 1.5rem !important;
  }

  .lg\:builder-pb-7{
    padding-bottom: 1.75rem !important;
  }

  .lg\:builder-pb-8{
    padding-bottom: 2rem !important;
  }

  .lg\:builder-pb-9{
    padding-bottom: 2.25rem !important;
  }

  .lg\:builder-pb-10{
    padding-bottom: 2.5rem !important;
  }

  .lg\:builder-pb-11{
    padding-bottom: 2.75rem !important;
  }

  .lg\:builder-pb-12{
    padding-bottom: 3rem !important;
  }

  .lg\:builder-pb-14{
    padding-bottom: 3.5rem !important;
  }

  .lg\:builder-pb-16{
    padding-bottom: 4rem !important;
  }

  .lg\:builder-pb-20{
    padding-bottom: 5rem !important;
  }

  .lg\:builder-pb-24{
    padding-bottom: 6rem !important;
  }

  .lg\:builder-pb-28{
    padding-bottom: 7rem !important;
  }

  .lg\:builder-pb-32{
    padding-bottom: 8rem !important;
  }

  .lg\:builder-pb-36{
    padding-bottom: 9rem !important;
  }

  .lg\:builder-pb-40{
    padding-bottom: 10rem !important;
  }

  .lg\:builder-pb-44{
    padding-bottom: 11rem !important;
  }

  .lg\:builder-pb-48{
    padding-bottom: 12rem !important;
  }

  .lg\:builder-pb-52{
    padding-bottom: 13rem !important;
  }

  .lg\:builder-pb-56{
    padding-bottom: 14rem !important;
  }

  .lg\:builder-pb-60{
    padding-bottom: 15rem !important;
  }

  .lg\:builder-pb-64{
    padding-bottom: 16rem !important;
  }

  .lg\:builder-pb-72{
    padding-bottom: 18rem !important;
  }

  .lg\:builder-pb-80{
    padding-bottom: 20rem !important;
  }

  .lg\:builder-pb-96{
    padding-bottom: 24rem !important;
  }

  .lg\:builder-pb-px{
    padding-bottom: 1px !important;
  }

  .lg\:builder-pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .lg\:builder-pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .lg\:builder-pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .lg\:builder-pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .lg\:builder-pl-0{
    padding-left: 0px !important;
  }

  .lg\:builder-pl-1{
    padding-left: 0.25rem !important;
  }

  .lg\:builder-pl-2{
    padding-left: 0.5rem !important;
  }

  .lg\:builder-pl-3{
    padding-left: 0.75rem !important;
  }

  .lg\:builder-pl-4{
    padding-left: 1rem !important;
  }

  .lg\:builder-pl-5{
    padding-left: 1.25rem !important;
  }

  .lg\:builder-pl-6{
    padding-left: 1.5rem !important;
  }

  .lg\:builder-pl-7{
    padding-left: 1.75rem !important;
  }

  .lg\:builder-pl-8{
    padding-left: 2rem !important;
  }

  .lg\:builder-pl-9{
    padding-left: 2.25rem !important;
  }

  .lg\:builder-pl-10{
    padding-left: 2.5rem !important;
  }

  .lg\:builder-pl-11{
    padding-left: 2.75rem !important;
  }

  .lg\:builder-pl-12{
    padding-left: 3rem !important;
  }

  .lg\:builder-pl-14{
    padding-left: 3.5rem !important;
  }

  .lg\:builder-pl-16{
    padding-left: 4rem !important;
  }

  .lg\:builder-pl-20{
    padding-left: 5rem !important;
  }

  .lg\:builder-pl-24{
    padding-left: 6rem !important;
  }

  .lg\:builder-pl-28{
    padding-left: 7rem !important;
  }

  .lg\:builder-pl-32{
    padding-left: 8rem !important;
  }

  .lg\:builder-pl-36{
    padding-left: 9rem !important;
  }

  .lg\:builder-pl-40{
    padding-left: 10rem !important;
  }

  .lg\:builder-pl-44{
    padding-left: 11rem !important;
  }

  .lg\:builder-pl-48{
    padding-left: 12rem !important;
  }

  .lg\:builder-pl-52{
    padding-left: 13rem !important;
  }

  .lg\:builder-pl-56{
    padding-left: 14rem !important;
  }

  .lg\:builder-pl-60{
    padding-left: 15rem !important;
  }

  .lg\:builder-pl-64{
    padding-left: 16rem !important;
  }

  .lg\:builder-pl-72{
    padding-left: 18rem !important;
  }

  .lg\:builder-pl-80{
    padding-left: 20rem !important;
  }

  .lg\:builder-pl-96{
    padding-left: 24rem !important;
  }

  .lg\:builder-pl-px{
    padding-left: 1px !important;
  }

  .lg\:builder-pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .lg\:builder-pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .lg\:builder-pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .lg\:builder-pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .lg\:builder-text-left{
    text-align: left !important;
  }

  .lg\:builder-text-center{
    text-align: center !important;
  }

  .lg\:builder-text-right{
    text-align: right !important;
  }

  .lg\:builder-text-justify{
    text-align: justify !important;
  }

  .lg\:builder-align-baseline{
    vertical-align: baseline !important;
  }

  .lg\:builder-align-top{
    vertical-align: top !important;
  }

  .lg\:builder-align-middle{
    vertical-align: middle !important;
  }

  .lg\:builder-align-bottom{
    vertical-align: bottom !important;
  }

  .lg\:builder-align-text-top{
    vertical-align: text-top !important;
  }

  .lg\:builder-align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .lg\:builder-font-primary{
    font-family: proxima-nova !important;
  }

  .lg\:builder-text-sm{
    font-size: 14px !important;
  }

  .lg\:builder-text-md{
    font-size: 15px !important;
  }

  .lg\:builder-text-2xl{
    font-size: 24px !important;
  }

  .lg\:builder-font-thin{
    font-weight: 100 !important;
  }

  .lg\:builder-font-extralight{
    font-weight: 200 !important;
  }

  .lg\:builder-font-light{
    font-weight: 300 !important;
  }

  .lg\:builder-font-normal{
    font-weight: 400 !important;
  }

  .lg\:builder-font-medium{
    font-weight: 500 !important;
  }

  .lg\:builder-font-semibold{
    font-weight: 600 !important;
  }

  .lg\:builder-font-bold{
    font-weight: 700 !important;
  }

  .lg\:builder-font-extrabold{
    font-weight: 800 !important;
  }

  .lg\:builder-font-black{
    font-weight: 900 !important;
  }

  .lg\:builder-uppercase{
    text-transform: uppercase !important;
  }

  .lg\:builder-lowercase{
    text-transform: lowercase !important;
  }

  .lg\:builder-capitalize{
    text-transform: capitalize !important;
  }

  .lg\:builder-normal-case{
    text-transform: none !important;
  }

  .lg\:builder-italic{
    font-style: italic !important;
  }

  .lg\:builder-not-italic{
    font-style: normal !important;
  }

  .lg\:builder-ordinal, .lg\:builder-slashed-zero, .lg\:builder-lining-nums, .lg\:builder-oldstyle-nums, .lg\:builder-proportional-nums, .lg\:builder-tabular-nums, .lg\:builder-diagonal-fractions, .lg\:builder-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .lg\:builder-normal-nums{
    font-variant-numeric: normal !important;
  }

  .lg\:builder-ordinal{
    --tw-ordinal: ordinal !important;
  }

  .lg\:builder-slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .lg\:builder-lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .lg\:builder-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .lg\:builder-proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .lg\:builder-tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .lg\:builder-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .lg\:builder-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .lg\:builder-leading-3{
    line-height: .75rem !important;
  }

  .lg\:builder-leading-4{
    line-height: 1rem !important;
  }

  .lg\:builder-leading-5{
    line-height: 1.25rem !important;
  }

  .lg\:builder-leading-6{
    line-height: 1.5rem !important;
  }

  .lg\:builder-leading-7{
    line-height: 1.75rem !important;
  }

  .lg\:builder-leading-8{
    line-height: 2rem !important;
  }

  .lg\:builder-leading-9{
    line-height: 2.25rem !important;
  }

  .lg\:builder-leading-10{
    line-height: 2.5rem !important;
  }

  .lg\:builder-leading-none{
    line-height: 1 !important;
  }

  .lg\:builder-leading-tight{
    line-height: 1.25 !important;
  }

  .lg\:builder-leading-snug{
    line-height: 1.375 !important;
  }

  .lg\:builder-leading-normal{
    line-height: 1.5 !important;
  }

  .lg\:builder-leading-relaxed{
    line-height: 1.625 !important;
  }

  .lg\:builder-leading-loose{
    line-height: 2 !important;
  }

  .lg\:builder-tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .lg\:builder-tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .lg\:builder-tracking-normal{
    letter-spacing: 0em !important;
  }

  .lg\:builder-tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .lg\:builder-tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .lg\:builder-tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .lg\:builder-text-transparent{
    color: transparent !important;
  }

  .lg\:builder-text-current{
    color: currentColor !important;
  }

  .lg\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-transparent{
    color: transparent !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-current{
    color: currentColor !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-transparent:focus-within{
    color: transparent !important;
  }

  .lg\:focus-within\:builder-text-current:focus-within{
    color: currentColor !important;
  }

  .lg\:focus-within\:builder-text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-primary:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-secondary-dark:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:builder-text-secondary-darker:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-transparent:hover{
    color: transparent !important;
  }

  .lg\:hover\:builder-text-current:hover{
    color: currentColor !important;
  }

  .lg\:hover\:builder-text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-primary:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-secondary-dark:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:builder-text-secondary-darker:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-transparent:focus{
    color: transparent !important;
  }

  .lg\:focus\:builder-text-current:focus{
    color: currentColor !important;
  }

  .lg\:focus\:builder-text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-primary:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-secondary-dark:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:builder-text-secondary-darker:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .lg\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .lg\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .lg\:focus-within\:builder-text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .lg\:focus-within\:builder-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:focus-within\:builder-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:focus-within\:builder-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:focus-within\:builder-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:focus-within\:builder-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:focus-within\:builder-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:focus-within\:builder-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:focus-within\:builder-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:focus-within\:builder-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:focus-within\:builder-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:focus-within\:builder-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:focus-within\:builder-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:focus-within\:builder-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:focus-within\:builder-text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .lg\:hover\:builder-text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .lg\:hover\:builder-text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:hover\:builder-text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:hover\:builder-text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:hover\:builder-text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:hover\:builder-text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:hover\:builder-text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:hover\:builder-text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:hover\:builder-text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:hover\:builder-text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:hover\:builder-text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:hover\:builder-text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:hover\:builder-text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:hover\:builder-text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:hover\:builder-text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .lg\:focus\:builder-text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .lg\:focus\:builder-text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:focus\:builder-text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:focus\:builder-text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:focus\:builder-text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:focus\:builder-text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:focus\:builder-text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:focus\:builder-text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:focus\:builder-text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:focus\:builder-text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:focus\:builder-text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:focus\:builder-text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:focus\:builder-text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:focus\:builder-text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:focus\:builder-text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .lg\:builder-underline{
    text-decoration: underline !important;
  }

  .lg\:builder-line-through{
    text-decoration: line-through !important;
  }

  .lg\:builder-no-underline{
    text-decoration: none !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-underline{
    text-decoration: underline !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-line-through{
    text-decoration: line-through !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-no-underline{
    text-decoration: none !important;
  }

  .lg\:focus-within\:builder-underline:focus-within{
    text-decoration: underline !important;
  }

  .lg\:focus-within\:builder-line-through:focus-within{
    text-decoration: line-through !important;
  }

  .lg\:focus-within\:builder-no-underline:focus-within{
    text-decoration: none !important;
  }

  .lg\:hover\:builder-underline:hover{
    text-decoration: underline !important;
  }

  .lg\:hover\:builder-line-through:hover{
    text-decoration: line-through !important;
  }

  .lg\:hover\:builder-no-underline:hover{
    text-decoration: none !important;
  }

  .lg\:focus\:builder-underline:focus{
    text-decoration: underline !important;
  }

  .lg\:focus\:builder-line-through:focus{
    text-decoration: line-through !important;
  }

  .lg\:focus\:builder-no-underline:focus{
    text-decoration: none !important;
  }

  .lg\:builder-antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .lg\:builder-subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .lg\:builder-placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .lg\:builder-placeholder-current::placeholder{
    color: currentColor !important;
  }

  .lg\:builder-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-primary::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-secondary-dark::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-secondary-darker::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .lg\:focus\:builder-placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .lg\:focus\:builder-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-primary:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-secondary-dark:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:builder-placeholder-secondary-darker:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:builder-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:builder-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:builder-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:builder-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:builder-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:builder-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:builder-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:builder-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:builder-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:builder-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:builder-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:builder-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:builder-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:builder-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:builder-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:focus\:builder-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:builder-opacity-0{
    opacity: 0 !important;
  }

  .lg\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .lg\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .lg\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .lg\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .lg\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .lg\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .lg\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .lg\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .lg\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .lg\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .lg\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .lg\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .lg\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .lg\:builder-opacity-100{
    opacity: 1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-0{
    opacity: 0 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-opacity-100{
    opacity: 1 !important;
  }

  .lg\:focus-within\:builder-opacity-0:focus-within{
    opacity: 0 !important;
  }

  .lg\:focus-within\:builder-opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .lg\:focus-within\:builder-opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .lg\:focus-within\:builder-opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .lg\:focus-within\:builder-opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .lg\:focus-within\:builder-opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .lg\:focus-within\:builder-opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .lg\:focus-within\:builder-opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .lg\:focus-within\:builder-opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .lg\:focus-within\:builder-opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .lg\:focus-within\:builder-opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .lg\:focus-within\:builder-opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .lg\:focus-within\:builder-opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .lg\:focus-within\:builder-opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .lg\:focus-within\:builder-opacity-100:focus-within{
    opacity: 1 !important;
  }

  .lg\:hover\:builder-opacity-0:hover{
    opacity: 0 !important;
  }

  .lg\:hover\:builder-opacity-5:hover{
    opacity: 0.05 !important;
  }

  .lg\:hover\:builder-opacity-10:hover{
    opacity: 0.1 !important;
  }

  .lg\:hover\:builder-opacity-20:hover{
    opacity: 0.2 !important;
  }

  .lg\:hover\:builder-opacity-25:hover{
    opacity: 0.25 !important;
  }

  .lg\:hover\:builder-opacity-30:hover{
    opacity: 0.3 !important;
  }

  .lg\:hover\:builder-opacity-40:hover{
    opacity: 0.4 !important;
  }

  .lg\:hover\:builder-opacity-50:hover{
    opacity: 0.5 !important;
  }

  .lg\:hover\:builder-opacity-60:hover{
    opacity: 0.6 !important;
  }

  .lg\:hover\:builder-opacity-70:hover{
    opacity: 0.7 !important;
  }

  .lg\:hover\:builder-opacity-75:hover{
    opacity: 0.75 !important;
  }

  .lg\:hover\:builder-opacity-80:hover{
    opacity: 0.8 !important;
  }

  .lg\:hover\:builder-opacity-90:hover{
    opacity: 0.9 !important;
  }

  .lg\:hover\:builder-opacity-95:hover{
    opacity: 0.95 !important;
  }

  .lg\:hover\:builder-opacity-100:hover{
    opacity: 1 !important;
  }

  .lg\:focus\:builder-opacity-0:focus{
    opacity: 0 !important;
  }

  .lg\:focus\:builder-opacity-5:focus{
    opacity: 0.05 !important;
  }

  .lg\:focus\:builder-opacity-10:focus{
    opacity: 0.1 !important;
  }

  .lg\:focus\:builder-opacity-20:focus{
    opacity: 0.2 !important;
  }

  .lg\:focus\:builder-opacity-25:focus{
    opacity: 0.25 !important;
  }

  .lg\:focus\:builder-opacity-30:focus{
    opacity: 0.3 !important;
  }

  .lg\:focus\:builder-opacity-40:focus{
    opacity: 0.4 !important;
  }

  .lg\:focus\:builder-opacity-50:focus{
    opacity: 0.5 !important;
  }

  .lg\:focus\:builder-opacity-60:focus{
    opacity: 0.6 !important;
  }

  .lg\:focus\:builder-opacity-70:focus{
    opacity: 0.7 !important;
  }

  .lg\:focus\:builder-opacity-75:focus{
    opacity: 0.75 !important;
  }

  .lg\:focus\:builder-opacity-80:focus{
    opacity: 0.8 !important;
  }

  .lg\:focus\:builder-opacity-90:focus{
    opacity: 0.9 !important;
  }

  .lg\:focus\:builder-opacity-95:focus{
    opacity: 0.95 !important;
  }

  .lg\:focus\:builder-opacity-100:focus{
    opacity: 1 !important;
  }

  .lg\:builder-bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .lg\:builder-bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .lg\:builder-bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .lg\:builder-bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .lg\:builder-bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .lg\:builder-bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .lg\:builder-bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .lg\:builder-bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .lg\:builder-bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .lg\:builder-bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .lg\:builder-bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .lg\:builder-bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .lg\:builder-bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .lg\:builder-bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .lg\:builder-bg-blend-color{
    background-blend-mode: color !important;
  }

  .lg\:builder-bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .lg\:builder-mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .lg\:builder-mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .lg\:builder-mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .lg\:builder-mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .lg\:builder-mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .lg\:builder-mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .lg\:builder-mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .lg\:builder-mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .lg\:builder-mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .lg\:builder-mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .lg\:builder-mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .lg\:builder-mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .lg\:builder-mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .lg\:builder-mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .lg\:builder-mix-blend-color{
    mix-blend-mode: color !important;
  }

  .lg\:builder-mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .lg\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .lg\:group-hover\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:builder-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:builder-shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:builder-shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:builder-outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .lg\:builder-outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .lg\:builder-outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .lg\:focus-within\:builder-outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .lg\:focus-within\:builder-outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .lg\:focus-within\:builder-outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .lg\:focus\:builder-outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .lg\:focus\:builder-outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .lg\:focus\:builder-outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .lg\:builder-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:builder-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:builder-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:builder-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:builder-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:builder-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:builder-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:builder-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:builder-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:builder-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:builder-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:builder-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:builder-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:builder-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:builder-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:builder-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:builder-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:builder-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:builder-ring-inset{
    --tw-ring-inset: inset !important;
  }

  .lg\:focus-within\:builder-ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .lg\:focus\:builder-ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .lg\:builder-ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .lg\:builder-ring-current{
    --tw-ring-color: currentColor !important;
  }

  .lg\:builder-ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-primary{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-secondary-dark{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-secondary-darker{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .lg\:focus-within\:builder-ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .lg\:focus-within\:builder-ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-primary:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-secondary-dark:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:builder-ring-secondary-darker:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .lg\:focus\:builder-ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .lg\:focus\:builder-ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-primary:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-secondary-dark:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:builder-ring-secondary-darker:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .lg\:builder-ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .lg\:builder-ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .lg\:builder-ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .lg\:builder-ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .lg\:builder-ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .lg\:builder-ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .lg\:builder-ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .lg\:builder-ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .lg\:builder-ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .lg\:builder-ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .lg\:builder-ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .lg\:builder-ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .lg\:builder-ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .lg\:builder-ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .lg\:builder-ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .lg\:focus-within\:builder-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .lg\:focus\:builder-ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .lg\:focus\:builder-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .lg\:focus\:builder-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .lg\:focus\:builder-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .lg\:focus\:builder-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .lg\:focus\:builder-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .lg\:focus\:builder-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .lg\:focus\:builder-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .lg\:focus\:builder-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .lg\:focus\:builder-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .lg\:focus\:builder-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .lg\:focus\:builder-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .lg\:focus\:builder-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .lg\:focus\:builder-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .lg\:focus\:builder-ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .lg\:builder-ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .lg\:builder-ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .lg\:builder-ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .lg\:builder-ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .lg\:builder-ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .lg\:focus-within\:builder-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .lg\:focus-within\:builder-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .lg\:focus-within\:builder-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .lg\:focus-within\:builder-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .lg\:focus-within\:builder-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .lg\:focus\:builder-ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .lg\:focus\:builder-ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .lg\:focus\:builder-ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .lg\:focus\:builder-ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .lg\:focus\:builder-ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .lg\:builder-ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .lg\:builder-ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .lg\:builder-ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .lg\:builder-ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .lg\:builder-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .lg\:builder-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .lg\:builder-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .lg\:builder-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .lg\:builder-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .lg\:builder-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .lg\:builder-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .lg\:builder-ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .lg\:builder-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .lg\:builder-ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .lg\:builder-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .lg\:builder-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .lg\:builder-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .lg\:builder-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .lg\:builder-ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .lg\:builder-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .lg\:builder-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .lg\:builder-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .lg\:builder-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .lg\:builder-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .lg\:builder-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .lg\:builder-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .lg\:builder-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .lg\:builder-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .lg\:builder-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .lg\:builder-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .lg\:builder-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .lg\:builder-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .lg\:builder-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .lg\:builder-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .lg\:builder-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .lg\:builder-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .lg\:builder-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .lg\:builder-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .lg\:builder-ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .lg\:builder-ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .lg\:builder-ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .lg\:builder-ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .lg\:builder-ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .lg\:builder-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .lg\:builder-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .lg\:builder-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .lg\:builder-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .lg\:builder-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .lg\:builder-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .lg\:builder-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .lg\:builder-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .lg\:builder-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .lg\:builder-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .lg\:builder-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .lg\:builder-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .lg\:builder-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .lg\:builder-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .lg\:builder-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .lg\:builder-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .lg\:builder-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .lg\:builder-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .lg\:builder-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .lg\:builder-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .lg\:builder-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .lg\:builder-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .lg\:builder-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .lg\:builder-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .lg\:builder-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .lg\:builder-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .lg\:builder-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .lg\:builder-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .lg\:builder-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .lg\:builder-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .lg\:builder-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .lg\:builder-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .lg\:builder-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .lg\:builder-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .lg\:builder-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .lg\:builder-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .lg\:builder-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .lg\:builder-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .lg\:builder-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .lg\:builder-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .lg\:builder-ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .lg\:builder-ring-offset-primary{
    --tw-ring-offset-color: #2d375a !important;
  }

  .lg\:builder-ring-offset-secondary-dark{
    --tw-ring-offset-color: #343434 !important;
  }

  .lg\:builder-ring-offset-secondary-darker{
    --tw-ring-offset-color: #212121 !important;
  }

  .lg\:focus-within\:builder-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .lg\:focus-within\:builder-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .lg\:focus-within\:builder-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .lg\:focus-within\:builder-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .lg\:focus-within\:builder-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .lg\:focus-within\:builder-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .lg\:focus-within\:builder-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .lg\:focus-within\:builder-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .lg\:focus-within\:builder-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .lg\:focus-within\:builder-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .lg\:focus-within\:builder-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .lg\:focus-within\:builder-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .lg\:focus-within\:builder-ring-offset-primary:focus-within{
    --tw-ring-offset-color: #2d375a !important;
  }

  .lg\:focus-within\:builder-ring-offset-secondary-dark:focus-within{
    --tw-ring-offset-color: #343434 !important;
  }

  .lg\:focus-within\:builder-ring-offset-secondary-darker:focus-within{
    --tw-ring-offset-color: #212121 !important;
  }

  .lg\:focus\:builder-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .lg\:focus\:builder-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .lg\:focus\:builder-ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .lg\:focus\:builder-ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .lg\:focus\:builder-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .lg\:focus\:builder-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .lg\:focus\:builder-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .lg\:focus\:builder-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .lg\:focus\:builder-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .lg\:focus\:builder-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .lg\:focus\:builder-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .lg\:focus\:builder-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .lg\:focus\:builder-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .lg\:focus\:builder-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .lg\:focus\:builder-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .lg\:focus\:builder-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .lg\:focus\:builder-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .lg\:focus\:builder-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .lg\:focus\:builder-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .lg\:focus\:builder-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .lg\:focus\:builder-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .lg\:focus\:builder-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .lg\:focus\:builder-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .lg\:focus\:builder-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .lg\:focus\:builder-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .lg\:focus\:builder-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .lg\:focus\:builder-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .lg\:focus\:builder-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .lg\:focus\:builder-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .lg\:focus\:builder-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .lg\:focus\:builder-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .lg\:focus\:builder-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .lg\:focus\:builder-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .lg\:focus\:builder-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .lg\:focus\:builder-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .lg\:focus\:builder-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .lg\:focus\:builder-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .lg\:focus\:builder-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .lg\:focus\:builder-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .lg\:focus\:builder-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .lg\:focus\:builder-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .lg\:focus\:builder-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .lg\:focus\:builder-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .lg\:focus\:builder-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .lg\:focus\:builder-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .lg\:focus\:builder-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .lg\:focus\:builder-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .lg\:focus\:builder-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .lg\:focus\:builder-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .lg\:focus\:builder-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .lg\:focus\:builder-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .lg\:focus\:builder-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .lg\:focus\:builder-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .lg\:focus\:builder-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .lg\:focus\:builder-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .lg\:focus\:builder-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .lg\:focus\:builder-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .lg\:focus\:builder-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .lg\:focus\:builder-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .lg\:focus\:builder-ring-offset-primary:focus{
    --tw-ring-offset-color: #2d375a !important;
  }

  .lg\:focus\:builder-ring-offset-secondary-dark:focus{
    --tw-ring-offset-color: #343434 !important;
  }

  .lg\:focus\:builder-ring-offset-secondary-darker:focus{
    --tw-ring-offset-color: #212121 !important;
  }

  .lg\:builder-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .lg\:builder-filter-none{
    filter: none !important;
  }

  .lg\:builder-blur-0{
    --tw-blur: blur(0) !important;
  }

  .lg\:builder-blur-none{
    --tw-blur: blur(0) !important;
  }

  .lg\:builder-blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .lg\:builder-blur{
    --tw-blur: blur(8px) !important;
  }

  .lg\:builder-blur-md{
    --tw-blur: blur(12px) !important;
  }

  .lg\:builder-blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .lg\:builder-blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .lg\:builder-blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .lg\:builder-blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .lg\:builder-brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .lg\:builder-brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .lg\:builder-brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .lg\:builder-brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .lg\:builder-brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .lg\:builder-brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .lg\:builder-brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .lg\:builder-brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .lg\:builder-brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .lg\:builder-brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .lg\:builder-brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .lg\:builder-contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .lg\:builder-contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .lg\:builder-contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .lg\:builder-contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .lg\:builder-contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .lg\:builder-contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .lg\:builder-contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .lg\:builder-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .lg\:builder-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .lg\:builder-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .lg\:builder-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .lg\:builder-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .lg\:builder-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .lg\:builder-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .lg\:builder-grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .lg\:builder-grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .lg\:builder-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .lg\:builder-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .lg\:builder-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .lg\:builder-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .lg\:builder-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .lg\:builder-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .lg\:builder--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .lg\:builder--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .lg\:builder--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .lg\:builder--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .lg\:builder--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .lg\:builder-invert-0{
    --tw-invert: invert(0) !important;
  }

  .lg\:builder-invert{
    --tw-invert: invert(100%) !important;
  }

  .lg\:builder-saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .lg\:builder-saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .lg\:builder-saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .lg\:builder-saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .lg\:builder-saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .lg\:builder-sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .lg\:builder-sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .lg\:builder-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .lg\:builder-backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .lg\:builder-backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .lg\:builder-backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .lg\:builder-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .lg\:builder-backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .lg\:builder-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .lg\:builder-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .lg\:builder-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .lg\:builder-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .lg\:builder-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .lg\:builder-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .lg\:builder-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .lg\:builder-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .lg\:builder-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .lg\:builder-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .lg\:builder-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .lg\:builder-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .lg\:builder-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .lg\:builder-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .lg\:builder-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .lg\:builder-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .lg\:builder-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .lg\:builder-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .lg\:builder-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .lg\:builder-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .lg\:builder-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .lg\:builder-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .lg\:builder-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .lg\:builder-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .lg\:builder-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .lg\:builder-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .lg\:builder-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .lg\:builder-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .lg\:builder-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .lg\:builder-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .lg\:builder-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .lg\:builder--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .lg\:builder--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .lg\:builder--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .lg\:builder--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .lg\:builder--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .lg\:builder-backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .lg\:builder-backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .lg\:builder-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .lg\:builder-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .lg\:builder-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .lg\:builder-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .lg\:builder-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .lg\:builder-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .lg\:builder-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .lg\:builder-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .lg\:builder-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .lg\:builder-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .lg\:builder-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .lg\:builder-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .lg\:builder-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .lg\:builder-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .lg\:builder-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .lg\:builder-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .lg\:builder-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .lg\:builder-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .lg\:builder-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .lg\:builder-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .lg\:builder-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .lg\:builder-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .lg\:builder-transition-none{
    transition-property: none !important;
  }

  .lg\:builder-transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:builder-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:builder-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:builder-transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:builder-transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:builder-transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:builder-delay-75{
    transition-delay: 75ms !important;
  }

  .lg\:builder-delay-100{
    transition-delay: 100ms !important;
  }

  .lg\:builder-delay-150{
    transition-delay: 150ms !important;
  }

  .lg\:builder-delay-200{
    transition-delay: 200ms !important;
  }

  .lg\:builder-delay-300{
    transition-delay: 300ms !important;
  }

  .lg\:builder-delay-500{
    transition-delay: 500ms !important;
  }

  .lg\:builder-delay-700{
    transition-delay: 700ms !important;
  }

  .lg\:builder-delay-1000{
    transition-delay: 1000ms !important;
  }

  .lg\:builder-duration-75{
    transition-duration: 75ms !important;
  }

  .lg\:builder-duration-100{
    transition-duration: 100ms !important;
  }

  .lg\:builder-duration-150{
    transition-duration: 150ms !important;
  }

  .lg\:builder-duration-200{
    transition-duration: 200ms !important;
  }

  .lg\:builder-duration-300{
    transition-duration: 300ms !important;
  }

  .lg\:builder-duration-500{
    transition-duration: 500ms !important;
  }

  .lg\:builder-duration-700{
    transition-duration: 700ms !important;
  }

  .lg\:builder-duration-1000{
    transition-duration: 1000ms !important;
  }

  .lg\:builder-ease-linear{
    transition-timing-function: linear !important;
  }

  .lg\:builder-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .lg\:builder-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .lg\:builder-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 1280px){
  .xl\:builder-container{
    width: 100%;
  }

  @media (min-width: 640px){
    .xl\:builder-container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .xl\:builder-container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .xl\:builder-container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .xl\:builder-container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .xl\:builder-container{
      max-width: 1536px;
    }
  }

  .xl\:builder-sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:builder-not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:focus-within\:builder-sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:focus-within\:builder-not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:focus\:builder-sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:focus\:builder-not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:builder-pointer-events-none{
    pointer-events: none !important;
  }

  .xl\:builder-pointer-events-auto{
    pointer-events: auto !important;
  }

  .xl\:builder-visible{
    visibility: visible !important;
  }

  .xl\:builder-invisible{
    visibility: hidden !important;
  }

  .xl\:builder-static{
    position: static !important;
  }

  .xl\:builder-fixed{
    position: fixed !important;
  }

  .xl\:builder-absolute{
    position: absolute !important;
  }

  .xl\:builder-relative{
    position: relative !important;
  }

  .xl\:builder-sticky{
    position: sticky !important;
  }

  .xl\:builder-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .xl\:builder-inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .xl\:builder-inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .xl\:builder-inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .xl\:builder-inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .xl\:builder-inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .xl\:builder-inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .xl\:builder-inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .xl\:builder-inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .xl\:builder-inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .xl\:builder-inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .xl\:builder-inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .xl\:builder-inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .xl\:builder-inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .xl\:builder-inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .xl\:builder-inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .xl\:builder-inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .xl\:builder-inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .xl\:builder-inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .xl\:builder-inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .xl\:builder-inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .xl\:builder-inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .xl\:builder-inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .xl\:builder-inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .xl\:builder-inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .xl\:builder-inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .xl\:builder-inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .xl\:builder-inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .xl\:builder-inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .xl\:builder-inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .xl\:builder-inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xl\:builder-inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .xl\:builder-inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .xl\:builder-inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .xl\:builder-inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .xl\:builder-inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .xl\:builder--inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .xl\:builder--inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .xl\:builder--inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .xl\:builder--inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .xl\:builder--inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .xl\:builder--inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .xl\:builder--inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .xl\:builder--inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .xl\:builder--inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .xl\:builder--inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .xl\:builder--inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .xl\:builder--inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .xl\:builder--inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .xl\:builder--inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .xl\:builder--inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .xl\:builder--inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .xl\:builder--inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .xl\:builder--inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .xl\:builder--inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .xl\:builder--inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .xl\:builder--inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .xl\:builder--inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .xl\:builder--inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .xl\:builder--inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .xl\:builder--inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .xl\:builder--inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .xl\:builder--inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .xl\:builder--inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .xl\:builder--inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .xl\:builder--inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .xl\:builder--inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .xl\:builder--inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .xl\:builder--inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .xl\:builder--inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .xl\:builder--inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .xl\:builder-inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .xl\:builder-inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .xl\:builder-inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .xl\:builder-inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .xl\:builder-inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .xl\:builder-inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .xl\:builder-inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .xl\:builder--inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .xl\:builder--inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .xl\:builder--inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .xl\:builder--inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .xl\:builder--inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .xl\:builder--inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .xl\:builder--inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .xl\:builder-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .xl\:builder-inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .xl\:builder-inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .xl\:builder-inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .xl\:builder-inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .xl\:builder-inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .xl\:builder-inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .xl\:builder-inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .xl\:builder-inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .xl\:builder-inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .xl\:builder-inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .xl\:builder-inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .xl\:builder-inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .xl\:builder-inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .xl\:builder-inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .xl\:builder-inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .xl\:builder-inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .xl\:builder-inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .xl\:builder-inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .xl\:builder-inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .xl\:builder-inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .xl\:builder-inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .xl\:builder-inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .xl\:builder-inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .xl\:builder-inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .xl\:builder-inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .xl\:builder-inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .xl\:builder-inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .xl\:builder-inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .xl\:builder-inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .xl\:builder-inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .xl\:builder-inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .xl\:builder-inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .xl\:builder-inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .xl\:builder-inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .xl\:builder-inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .xl\:builder--inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .xl\:builder--inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .xl\:builder--inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .xl\:builder--inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .xl\:builder--inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .xl\:builder--inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .xl\:builder--inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .xl\:builder--inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .xl\:builder--inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .xl\:builder--inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .xl\:builder--inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .xl\:builder--inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .xl\:builder--inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .xl\:builder--inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .xl\:builder--inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .xl\:builder--inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .xl\:builder--inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .xl\:builder--inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .xl\:builder--inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .xl\:builder--inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .xl\:builder--inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .xl\:builder--inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .xl\:builder--inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .xl\:builder--inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .xl\:builder--inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .xl\:builder--inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .xl\:builder--inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .xl\:builder--inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .xl\:builder--inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .xl\:builder--inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .xl\:builder--inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .xl\:builder--inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .xl\:builder--inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .xl\:builder--inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .xl\:builder--inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .xl\:builder-inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .xl\:builder-inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .xl\:builder-inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .xl\:builder-inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .xl\:builder-inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .xl\:builder-inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .xl\:builder-inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .xl\:builder--inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .xl\:builder--inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .xl\:builder--inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .xl\:builder--inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .xl\:builder--inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .xl\:builder--inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .xl\:builder--inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .xl\:builder-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .xl\:builder-inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .xl\:builder-inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .xl\:builder-inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .xl\:builder-inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .xl\:builder-inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .xl\:builder-inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .xl\:builder-inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .xl\:builder-inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .xl\:builder-inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .xl\:builder-inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .xl\:builder-inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .xl\:builder-inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .xl\:builder-inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .xl\:builder-inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .xl\:builder-inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .xl\:builder-inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .xl\:builder-inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .xl\:builder-inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .xl\:builder-inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .xl\:builder-inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .xl\:builder-inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .xl\:builder-inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .xl\:builder-inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .xl\:builder-inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .xl\:builder-inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .xl\:builder-inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .xl\:builder-inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .xl\:builder-inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .xl\:builder-inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .xl\:builder-inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .xl\:builder-inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .xl\:builder-inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .xl\:builder-inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .xl\:builder-inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .xl\:builder-inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .xl\:builder--inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .xl\:builder--inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .xl\:builder--inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .xl\:builder--inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .xl\:builder--inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .xl\:builder--inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .xl\:builder--inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .xl\:builder--inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .xl\:builder--inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .xl\:builder--inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .xl\:builder--inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .xl\:builder--inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .xl\:builder--inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .xl\:builder--inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .xl\:builder--inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .xl\:builder--inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .xl\:builder--inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .xl\:builder--inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .xl\:builder--inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .xl\:builder--inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .xl\:builder--inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .xl\:builder--inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .xl\:builder--inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .xl\:builder--inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .xl\:builder--inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .xl\:builder--inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .xl\:builder--inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .xl\:builder--inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .xl\:builder--inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .xl\:builder--inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .xl\:builder--inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .xl\:builder--inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .xl\:builder--inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .xl\:builder--inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .xl\:builder--inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .xl\:builder-inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .xl\:builder-inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .xl\:builder-inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .xl\:builder-inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .xl\:builder-inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .xl\:builder-inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .xl\:builder-inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .xl\:builder--inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .xl\:builder--inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .xl\:builder--inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .xl\:builder--inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .xl\:builder--inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .xl\:builder--inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .xl\:builder--inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .xl\:builder-top-0{
    top: 0px !important;
  }

  .xl\:builder-top-1{
    top: 0.25rem !important;
  }

  .xl\:builder-top-2{
    top: 0.5rem !important;
  }

  .xl\:builder-top-3{
    top: 0.75rem !important;
  }

  .xl\:builder-top-4{
    top: 1rem !important;
  }

  .xl\:builder-top-5{
    top: 1.25rem !important;
  }

  .xl\:builder-top-6{
    top: 1.5rem !important;
  }

  .xl\:builder-top-7{
    top: 1.75rem !important;
  }

  .xl\:builder-top-8{
    top: 2rem !important;
  }

  .xl\:builder-top-9{
    top: 2.25rem !important;
  }

  .xl\:builder-top-10{
    top: 2.5rem !important;
  }

  .xl\:builder-top-11{
    top: 2.75rem !important;
  }

  .xl\:builder-top-12{
    top: 3rem !important;
  }

  .xl\:builder-top-14{
    top: 3.5rem !important;
  }

  .xl\:builder-top-16{
    top: 4rem !important;
  }

  .xl\:builder-top-20{
    top: 5rem !important;
  }

  .xl\:builder-top-24{
    top: 6rem !important;
  }

  .xl\:builder-top-28{
    top: 7rem !important;
  }

  .xl\:builder-top-32{
    top: 8rem !important;
  }

  .xl\:builder-top-36{
    top: 9rem !important;
  }

  .xl\:builder-top-40{
    top: 10rem !important;
  }

  .xl\:builder-top-44{
    top: 11rem !important;
  }

  .xl\:builder-top-48{
    top: 12rem !important;
  }

  .xl\:builder-top-52{
    top: 13rem !important;
  }

  .xl\:builder-top-56{
    top: 14rem !important;
  }

  .xl\:builder-top-60{
    top: 15rem !important;
  }

  .xl\:builder-top-64{
    top: 16rem !important;
  }

  .xl\:builder-top-72{
    top: 18rem !important;
  }

  .xl\:builder-top-80{
    top: 20rem !important;
  }

  .xl\:builder-top-96{
    top: 24rem !important;
  }

  .xl\:builder-top-auto{
    top: auto !important;
  }

  .xl\:builder-top-px{
    top: 1px !important;
  }

  .xl\:builder-top-0\.5{
    top: 0.125rem !important;
  }

  .xl\:builder-top-1\.5{
    top: 0.375rem !important;
  }

  .xl\:builder-top-2\.5{
    top: 0.625rem !important;
  }

  .xl\:builder-top-3\.5{
    top: 0.875rem !important;
  }

  .xl\:builder--top-0{
    top: 0px !important;
  }

  .xl\:builder--top-1{
    top: -0.25rem !important;
  }

  .xl\:builder--top-2{
    top: -0.5rem !important;
  }

  .xl\:builder--top-3{
    top: -0.75rem !important;
  }

  .xl\:builder--top-4{
    top: -1rem !important;
  }

  .xl\:builder--top-5{
    top: -1.25rem !important;
  }

  .xl\:builder--top-6{
    top: -1.5rem !important;
  }

  .xl\:builder--top-7{
    top: -1.75rem !important;
  }

  .xl\:builder--top-8{
    top: -2rem !important;
  }

  .xl\:builder--top-9{
    top: -2.25rem !important;
  }

  .xl\:builder--top-10{
    top: -2.5rem !important;
  }

  .xl\:builder--top-11{
    top: -2.75rem !important;
  }

  .xl\:builder--top-12{
    top: -3rem !important;
  }

  .xl\:builder--top-14{
    top: -3.5rem !important;
  }

  .xl\:builder--top-16{
    top: -4rem !important;
  }

  .xl\:builder--top-20{
    top: -5rem !important;
  }

  .xl\:builder--top-24{
    top: -6rem !important;
  }

  .xl\:builder--top-28{
    top: -7rem !important;
  }

  .xl\:builder--top-32{
    top: -8rem !important;
  }

  .xl\:builder--top-36{
    top: -9rem !important;
  }

  .xl\:builder--top-40{
    top: -10rem !important;
  }

  .xl\:builder--top-44{
    top: -11rem !important;
  }

  .xl\:builder--top-48{
    top: -12rem !important;
  }

  .xl\:builder--top-52{
    top: -13rem !important;
  }

  .xl\:builder--top-56{
    top: -14rem !important;
  }

  .xl\:builder--top-60{
    top: -15rem !important;
  }

  .xl\:builder--top-64{
    top: -16rem !important;
  }

  .xl\:builder--top-72{
    top: -18rem !important;
  }

  .xl\:builder--top-80{
    top: -20rem !important;
  }

  .xl\:builder--top-96{
    top: -24rem !important;
  }

  .xl\:builder--top-px{
    top: -1px !important;
  }

  .xl\:builder--top-0\.5{
    top: -0.125rem !important;
  }

  .xl\:builder--top-1\.5{
    top: -0.375rem !important;
  }

  .xl\:builder--top-2\.5{
    top: -0.625rem !important;
  }

  .xl\:builder--top-3\.5{
    top: -0.875rem !important;
  }

  .xl\:builder-top-1\/2{
    top: 50% !important;
  }

  .xl\:builder-top-1\/3{
    top: 33.333333% !important;
  }

  .xl\:builder-top-2\/3{
    top: 66.666667% !important;
  }

  .xl\:builder-top-1\/4{
    top: 25% !important;
  }

  .xl\:builder-top-2\/4{
    top: 50% !important;
  }

  .xl\:builder-top-3\/4{
    top: 75% !important;
  }

  .xl\:builder-top-full{
    top: 100% !important;
  }

  .xl\:builder--top-1\/2{
    top: -50% !important;
  }

  .xl\:builder--top-1\/3{
    top: -33.333333% !important;
  }

  .xl\:builder--top-2\/3{
    top: -66.666667% !important;
  }

  .xl\:builder--top-1\/4{
    top: -25% !important;
  }

  .xl\:builder--top-2\/4{
    top: -50% !important;
  }

  .xl\:builder--top-3\/4{
    top: -75% !important;
  }

  .xl\:builder--top-full{
    top: -100% !important;
  }

  .xl\:builder-right-0{
    right: 0px !important;
  }

  .xl\:builder-right-1{
    right: 0.25rem !important;
  }

  .xl\:builder-right-2{
    right: 0.5rem !important;
  }

  .xl\:builder-right-3{
    right: 0.75rem !important;
  }

  .xl\:builder-right-4{
    right: 1rem !important;
  }

  .xl\:builder-right-5{
    right: 1.25rem !important;
  }

  .xl\:builder-right-6{
    right: 1.5rem !important;
  }

  .xl\:builder-right-7{
    right: 1.75rem !important;
  }

  .xl\:builder-right-8{
    right: 2rem !important;
  }

  .xl\:builder-right-9{
    right: 2.25rem !important;
  }

  .xl\:builder-right-10{
    right: 2.5rem !important;
  }

  .xl\:builder-right-11{
    right: 2.75rem !important;
  }

  .xl\:builder-right-12{
    right: 3rem !important;
  }

  .xl\:builder-right-14{
    right: 3.5rem !important;
  }

  .xl\:builder-right-16{
    right: 4rem !important;
  }

  .xl\:builder-right-20{
    right: 5rem !important;
  }

  .xl\:builder-right-24{
    right: 6rem !important;
  }

  .xl\:builder-right-28{
    right: 7rem !important;
  }

  .xl\:builder-right-32{
    right: 8rem !important;
  }

  .xl\:builder-right-36{
    right: 9rem !important;
  }

  .xl\:builder-right-40{
    right: 10rem !important;
  }

  .xl\:builder-right-44{
    right: 11rem !important;
  }

  .xl\:builder-right-48{
    right: 12rem !important;
  }

  .xl\:builder-right-52{
    right: 13rem !important;
  }

  .xl\:builder-right-56{
    right: 14rem !important;
  }

  .xl\:builder-right-60{
    right: 15rem !important;
  }

  .xl\:builder-right-64{
    right: 16rem !important;
  }

  .xl\:builder-right-72{
    right: 18rem !important;
  }

  .xl\:builder-right-80{
    right: 20rem !important;
  }

  .xl\:builder-right-96{
    right: 24rem !important;
  }

  .xl\:builder-right-auto{
    right: auto !important;
  }

  .xl\:builder-right-px{
    right: 1px !important;
  }

  .xl\:builder-right-0\.5{
    right: 0.125rem !important;
  }

  .xl\:builder-right-1\.5{
    right: 0.375rem !important;
  }

  .xl\:builder-right-2\.5{
    right: 0.625rem !important;
  }

  .xl\:builder-right-3\.5{
    right: 0.875rem !important;
  }

  .xl\:builder--right-0{
    right: 0px !important;
  }

  .xl\:builder--right-1{
    right: -0.25rem !important;
  }

  .xl\:builder--right-2{
    right: -0.5rem !important;
  }

  .xl\:builder--right-3{
    right: -0.75rem !important;
  }

  .xl\:builder--right-4{
    right: -1rem !important;
  }

  .xl\:builder--right-5{
    right: -1.25rem !important;
  }

  .xl\:builder--right-6{
    right: -1.5rem !important;
  }

  .xl\:builder--right-7{
    right: -1.75rem !important;
  }

  .xl\:builder--right-8{
    right: -2rem !important;
  }

  .xl\:builder--right-9{
    right: -2.25rem !important;
  }

  .xl\:builder--right-10{
    right: -2.5rem !important;
  }

  .xl\:builder--right-11{
    right: -2.75rem !important;
  }

  .xl\:builder--right-12{
    right: -3rem !important;
  }

  .xl\:builder--right-14{
    right: -3.5rem !important;
  }

  .xl\:builder--right-16{
    right: -4rem !important;
  }

  .xl\:builder--right-20{
    right: -5rem !important;
  }

  .xl\:builder--right-24{
    right: -6rem !important;
  }

  .xl\:builder--right-28{
    right: -7rem !important;
  }

  .xl\:builder--right-32{
    right: -8rem !important;
  }

  .xl\:builder--right-36{
    right: -9rem !important;
  }

  .xl\:builder--right-40{
    right: -10rem !important;
  }

  .xl\:builder--right-44{
    right: -11rem !important;
  }

  .xl\:builder--right-48{
    right: -12rem !important;
  }

  .xl\:builder--right-52{
    right: -13rem !important;
  }

  .xl\:builder--right-56{
    right: -14rem !important;
  }

  .xl\:builder--right-60{
    right: -15rem !important;
  }

  .xl\:builder--right-64{
    right: -16rem !important;
  }

  .xl\:builder--right-72{
    right: -18rem !important;
  }

  .xl\:builder--right-80{
    right: -20rem !important;
  }

  .xl\:builder--right-96{
    right: -24rem !important;
  }

  .xl\:builder--right-px{
    right: -1px !important;
  }

  .xl\:builder--right-0\.5{
    right: -0.125rem !important;
  }

  .xl\:builder--right-1\.5{
    right: -0.375rem !important;
  }

  .xl\:builder--right-2\.5{
    right: -0.625rem !important;
  }

  .xl\:builder--right-3\.5{
    right: -0.875rem !important;
  }

  .xl\:builder-right-1\/2{
    right: 50% !important;
  }

  .xl\:builder-right-1\/3{
    right: 33.333333% !important;
  }

  .xl\:builder-right-2\/3{
    right: 66.666667% !important;
  }

  .xl\:builder-right-1\/4{
    right: 25% !important;
  }

  .xl\:builder-right-2\/4{
    right: 50% !important;
  }

  .xl\:builder-right-3\/4{
    right: 75% !important;
  }

  .xl\:builder-right-full{
    right: 100% !important;
  }

  .xl\:builder--right-1\/2{
    right: -50% !important;
  }

  .xl\:builder--right-1\/3{
    right: -33.333333% !important;
  }

  .xl\:builder--right-2\/3{
    right: -66.666667% !important;
  }

  .xl\:builder--right-1\/4{
    right: -25% !important;
  }

  .xl\:builder--right-2\/4{
    right: -50% !important;
  }

  .xl\:builder--right-3\/4{
    right: -75% !important;
  }

  .xl\:builder--right-full{
    right: -100% !important;
  }

  .xl\:builder-bottom-0{
    bottom: 0px !important;
  }

  .xl\:builder-bottom-1{
    bottom: 0.25rem !important;
  }

  .xl\:builder-bottom-2{
    bottom: 0.5rem !important;
  }

  .xl\:builder-bottom-3{
    bottom: 0.75rem !important;
  }

  .xl\:builder-bottom-4{
    bottom: 1rem !important;
  }

  .xl\:builder-bottom-5{
    bottom: 1.25rem !important;
  }

  .xl\:builder-bottom-6{
    bottom: 1.5rem !important;
  }

  .xl\:builder-bottom-7{
    bottom: 1.75rem !important;
  }

  .xl\:builder-bottom-8{
    bottom: 2rem !important;
  }

  .xl\:builder-bottom-9{
    bottom: 2.25rem !important;
  }

  .xl\:builder-bottom-10{
    bottom: 2.5rem !important;
  }

  .xl\:builder-bottom-11{
    bottom: 2.75rem !important;
  }

  .xl\:builder-bottom-12{
    bottom: 3rem !important;
  }

  .xl\:builder-bottom-14{
    bottom: 3.5rem !important;
  }

  .xl\:builder-bottom-16{
    bottom: 4rem !important;
  }

  .xl\:builder-bottom-20{
    bottom: 5rem !important;
  }

  .xl\:builder-bottom-24{
    bottom: 6rem !important;
  }

  .xl\:builder-bottom-28{
    bottom: 7rem !important;
  }

  .xl\:builder-bottom-32{
    bottom: 8rem !important;
  }

  .xl\:builder-bottom-36{
    bottom: 9rem !important;
  }

  .xl\:builder-bottom-40{
    bottom: 10rem !important;
  }

  .xl\:builder-bottom-44{
    bottom: 11rem !important;
  }

  .xl\:builder-bottom-48{
    bottom: 12rem !important;
  }

  .xl\:builder-bottom-52{
    bottom: 13rem !important;
  }

  .xl\:builder-bottom-56{
    bottom: 14rem !important;
  }

  .xl\:builder-bottom-60{
    bottom: 15rem !important;
  }

  .xl\:builder-bottom-64{
    bottom: 16rem !important;
  }

  .xl\:builder-bottom-72{
    bottom: 18rem !important;
  }

  .xl\:builder-bottom-80{
    bottom: 20rem !important;
  }

  .xl\:builder-bottom-96{
    bottom: 24rem !important;
  }

  .xl\:builder-bottom-auto{
    bottom: auto !important;
  }

  .xl\:builder-bottom-px{
    bottom: 1px !important;
  }

  .xl\:builder-bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .xl\:builder-bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .xl\:builder-bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .xl\:builder-bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .xl\:builder--bottom-0{
    bottom: 0px !important;
  }

  .xl\:builder--bottom-1{
    bottom: -0.25rem !important;
  }

  .xl\:builder--bottom-2{
    bottom: -0.5rem !important;
  }

  .xl\:builder--bottom-3{
    bottom: -0.75rem !important;
  }

  .xl\:builder--bottom-4{
    bottom: -1rem !important;
  }

  .xl\:builder--bottom-5{
    bottom: -1.25rem !important;
  }

  .xl\:builder--bottom-6{
    bottom: -1.5rem !important;
  }

  .xl\:builder--bottom-7{
    bottom: -1.75rem !important;
  }

  .xl\:builder--bottom-8{
    bottom: -2rem !important;
  }

  .xl\:builder--bottom-9{
    bottom: -2.25rem !important;
  }

  .xl\:builder--bottom-10{
    bottom: -2.5rem !important;
  }

  .xl\:builder--bottom-11{
    bottom: -2.75rem !important;
  }

  .xl\:builder--bottom-12{
    bottom: -3rem !important;
  }

  .xl\:builder--bottom-14{
    bottom: -3.5rem !important;
  }

  .xl\:builder--bottom-16{
    bottom: -4rem !important;
  }

  .xl\:builder--bottom-20{
    bottom: -5rem !important;
  }

  .xl\:builder--bottom-24{
    bottom: -6rem !important;
  }

  .xl\:builder--bottom-28{
    bottom: -7rem !important;
  }

  .xl\:builder--bottom-32{
    bottom: -8rem !important;
  }

  .xl\:builder--bottom-36{
    bottom: -9rem !important;
  }

  .xl\:builder--bottom-40{
    bottom: -10rem !important;
  }

  .xl\:builder--bottom-44{
    bottom: -11rem !important;
  }

  .xl\:builder--bottom-48{
    bottom: -12rem !important;
  }

  .xl\:builder--bottom-52{
    bottom: -13rem !important;
  }

  .xl\:builder--bottom-56{
    bottom: -14rem !important;
  }

  .xl\:builder--bottom-60{
    bottom: -15rem !important;
  }

  .xl\:builder--bottom-64{
    bottom: -16rem !important;
  }

  .xl\:builder--bottom-72{
    bottom: -18rem !important;
  }

  .xl\:builder--bottom-80{
    bottom: -20rem !important;
  }

  .xl\:builder--bottom-96{
    bottom: -24rem !important;
  }

  .xl\:builder--bottom-px{
    bottom: -1px !important;
  }

  .xl\:builder--bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .xl\:builder--bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .xl\:builder--bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .xl\:builder--bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .xl\:builder-bottom-1\/2{
    bottom: 50% !important;
  }

  .xl\:builder-bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .xl\:builder-bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .xl\:builder-bottom-1\/4{
    bottom: 25% !important;
  }

  .xl\:builder-bottom-2\/4{
    bottom: 50% !important;
  }

  .xl\:builder-bottom-3\/4{
    bottom: 75% !important;
  }

  .xl\:builder-bottom-full{
    bottom: 100% !important;
  }

  .xl\:builder--bottom-1\/2{
    bottom: -50% !important;
  }

  .xl\:builder--bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .xl\:builder--bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .xl\:builder--bottom-1\/4{
    bottom: -25% !important;
  }

  .xl\:builder--bottom-2\/4{
    bottom: -50% !important;
  }

  .xl\:builder--bottom-3\/4{
    bottom: -75% !important;
  }

  .xl\:builder--bottom-full{
    bottom: -100% !important;
  }

  .xl\:builder-left-0{
    left: 0px !important;
  }

  .xl\:builder-left-1{
    left: 0.25rem !important;
  }

  .xl\:builder-left-2{
    left: 0.5rem !important;
  }

  .xl\:builder-left-3{
    left: 0.75rem !important;
  }

  .xl\:builder-left-4{
    left: 1rem !important;
  }

  .xl\:builder-left-5{
    left: 1.25rem !important;
  }

  .xl\:builder-left-6{
    left: 1.5rem !important;
  }

  .xl\:builder-left-7{
    left: 1.75rem !important;
  }

  .xl\:builder-left-8{
    left: 2rem !important;
  }

  .xl\:builder-left-9{
    left: 2.25rem !important;
  }

  .xl\:builder-left-10{
    left: 2.5rem !important;
  }

  .xl\:builder-left-11{
    left: 2.75rem !important;
  }

  .xl\:builder-left-12{
    left: 3rem !important;
  }

  .xl\:builder-left-14{
    left: 3.5rem !important;
  }

  .xl\:builder-left-16{
    left: 4rem !important;
  }

  .xl\:builder-left-20{
    left: 5rem !important;
  }

  .xl\:builder-left-24{
    left: 6rem !important;
  }

  .xl\:builder-left-28{
    left: 7rem !important;
  }

  .xl\:builder-left-32{
    left: 8rem !important;
  }

  .xl\:builder-left-36{
    left: 9rem !important;
  }

  .xl\:builder-left-40{
    left: 10rem !important;
  }

  .xl\:builder-left-44{
    left: 11rem !important;
  }

  .xl\:builder-left-48{
    left: 12rem !important;
  }

  .xl\:builder-left-52{
    left: 13rem !important;
  }

  .xl\:builder-left-56{
    left: 14rem !important;
  }

  .xl\:builder-left-60{
    left: 15rem !important;
  }

  .xl\:builder-left-64{
    left: 16rem !important;
  }

  .xl\:builder-left-72{
    left: 18rem !important;
  }

  .xl\:builder-left-80{
    left: 20rem !important;
  }

  .xl\:builder-left-96{
    left: 24rem !important;
  }

  .xl\:builder-left-auto{
    left: auto !important;
  }

  .xl\:builder-left-px{
    left: 1px !important;
  }

  .xl\:builder-left-0\.5{
    left: 0.125rem !important;
  }

  .xl\:builder-left-1\.5{
    left: 0.375rem !important;
  }

  .xl\:builder-left-2\.5{
    left: 0.625rem !important;
  }

  .xl\:builder-left-3\.5{
    left: 0.875rem !important;
  }

  .xl\:builder--left-0{
    left: 0px !important;
  }

  .xl\:builder--left-1{
    left: -0.25rem !important;
  }

  .xl\:builder--left-2{
    left: -0.5rem !important;
  }

  .xl\:builder--left-3{
    left: -0.75rem !important;
  }

  .xl\:builder--left-4{
    left: -1rem !important;
  }

  .xl\:builder--left-5{
    left: -1.25rem !important;
  }

  .xl\:builder--left-6{
    left: -1.5rem !important;
  }

  .xl\:builder--left-7{
    left: -1.75rem !important;
  }

  .xl\:builder--left-8{
    left: -2rem !important;
  }

  .xl\:builder--left-9{
    left: -2.25rem !important;
  }

  .xl\:builder--left-10{
    left: -2.5rem !important;
  }

  .xl\:builder--left-11{
    left: -2.75rem !important;
  }

  .xl\:builder--left-12{
    left: -3rem !important;
  }

  .xl\:builder--left-14{
    left: -3.5rem !important;
  }

  .xl\:builder--left-16{
    left: -4rem !important;
  }

  .xl\:builder--left-20{
    left: -5rem !important;
  }

  .xl\:builder--left-24{
    left: -6rem !important;
  }

  .xl\:builder--left-28{
    left: -7rem !important;
  }

  .xl\:builder--left-32{
    left: -8rem !important;
  }

  .xl\:builder--left-36{
    left: -9rem !important;
  }

  .xl\:builder--left-40{
    left: -10rem !important;
  }

  .xl\:builder--left-44{
    left: -11rem !important;
  }

  .xl\:builder--left-48{
    left: -12rem !important;
  }

  .xl\:builder--left-52{
    left: -13rem !important;
  }

  .xl\:builder--left-56{
    left: -14rem !important;
  }

  .xl\:builder--left-60{
    left: -15rem !important;
  }

  .xl\:builder--left-64{
    left: -16rem !important;
  }

  .xl\:builder--left-72{
    left: -18rem !important;
  }

  .xl\:builder--left-80{
    left: -20rem !important;
  }

  .xl\:builder--left-96{
    left: -24rem !important;
  }

  .xl\:builder--left-px{
    left: -1px !important;
  }

  .xl\:builder--left-0\.5{
    left: -0.125rem !important;
  }

  .xl\:builder--left-1\.5{
    left: -0.375rem !important;
  }

  .xl\:builder--left-2\.5{
    left: -0.625rem !important;
  }

  .xl\:builder--left-3\.5{
    left: -0.875rem !important;
  }

  .xl\:builder-left-1\/2{
    left: 50% !important;
  }

  .xl\:builder-left-1\/3{
    left: 33.333333% !important;
  }

  .xl\:builder-left-2\/3{
    left: 66.666667% !important;
  }

  .xl\:builder-left-1\/4{
    left: 25% !important;
  }

  .xl\:builder-left-2\/4{
    left: 50% !important;
  }

  .xl\:builder-left-3\/4{
    left: 75% !important;
  }

  .xl\:builder-left-full{
    left: 100% !important;
  }

  .xl\:builder--left-1\/2{
    left: -50% !important;
  }

  .xl\:builder--left-1\/3{
    left: -33.333333% !important;
  }

  .xl\:builder--left-2\/3{
    left: -66.666667% !important;
  }

  .xl\:builder--left-1\/4{
    left: -25% !important;
  }

  .xl\:builder--left-2\/4{
    left: -50% !important;
  }

  .xl\:builder--left-3\/4{
    left: -75% !important;
  }

  .xl\:builder--left-full{
    left: -100% !important;
  }

  .xl\:builder-isolate{
    isolation: isolate !important;
  }

  .xl\:builder-isolation-auto{
    isolation: auto !important;
  }

  .xl\:builder-z-0{
    z-index: 0 !important;
  }

  .xl\:builder-z-10{
    z-index: 10 !important;
  }

  .xl\:builder-z-20{
    z-index: 20 !important;
  }

  .xl\:builder-z-30{
    z-index: 30 !important;
  }

  .xl\:builder-z-40{
    z-index: 40 !important;
  }

  .xl\:builder-z-50{
    z-index: 50 !important;
  }

  .xl\:builder-z-auto{
    z-index: auto !important;
  }

  .xl\:focus-within\:builder-z-0:focus-within{
    z-index: 0 !important;
  }

  .xl\:focus-within\:builder-z-10:focus-within{
    z-index: 10 !important;
  }

  .xl\:focus-within\:builder-z-20:focus-within{
    z-index: 20 !important;
  }

  .xl\:focus-within\:builder-z-30:focus-within{
    z-index: 30 !important;
  }

  .xl\:focus-within\:builder-z-40:focus-within{
    z-index: 40 !important;
  }

  .xl\:focus-within\:builder-z-50:focus-within{
    z-index: 50 !important;
  }

  .xl\:focus-within\:builder-z-auto:focus-within{
    z-index: auto !important;
  }

  .xl\:focus\:builder-z-0:focus{
    z-index: 0 !important;
  }

  .xl\:focus\:builder-z-10:focus{
    z-index: 10 !important;
  }

  .xl\:focus\:builder-z-20:focus{
    z-index: 20 !important;
  }

  .xl\:focus\:builder-z-30:focus{
    z-index: 30 !important;
  }

  .xl\:focus\:builder-z-40:focus{
    z-index: 40 !important;
  }

  .xl\:focus\:builder-z-50:focus{
    z-index: 50 !important;
  }

  .xl\:focus\:builder-z-auto:focus{
    z-index: auto !important;
  }

  .xl\:builder-order-1{
    order: 1 !important;
  }

  .xl\:builder-order-2{
    order: 2 !important;
  }

  .xl\:builder-order-3{
    order: 3 !important;
  }

  .xl\:builder-order-4{
    order: 4 !important;
  }

  .xl\:builder-order-5{
    order: 5 !important;
  }

  .xl\:builder-order-6{
    order: 6 !important;
  }

  .xl\:builder-order-7{
    order: 7 !important;
  }

  .xl\:builder-order-8{
    order: 8 !important;
  }

  .xl\:builder-order-9{
    order: 9 !important;
  }

  .xl\:builder-order-10{
    order: 10 !important;
  }

  .xl\:builder-order-11{
    order: 11 !important;
  }

  .xl\:builder-order-12{
    order: 12 !important;
  }

  .xl\:builder-order-first{
    order: -9999 !important;
  }

  .xl\:builder-order-last{
    order: 9999 !important;
  }

  .xl\:builder-order-none{
    order: 0 !important;
  }

  .xl\:builder-col-auto{
    grid-column: auto !important;
  }

  .xl\:builder-col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .xl\:builder-col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .xl\:builder-col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .xl\:builder-col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .xl\:builder-col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .xl\:builder-col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .xl\:builder-col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .xl\:builder-col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .xl\:builder-col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .xl\:builder-col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .xl\:builder-col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .xl\:builder-col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .xl\:builder-col-span-full{
    grid-column: 1 / -1 !important;
  }

  .xl\:builder-col-start-1{
    grid-column-start: 1 !important;
  }

  .xl\:builder-col-start-2{
    grid-column-start: 2 !important;
  }

  .xl\:builder-col-start-3{
    grid-column-start: 3 !important;
  }

  .xl\:builder-col-start-4{
    grid-column-start: 4 !important;
  }

  .xl\:builder-col-start-5{
    grid-column-start: 5 !important;
  }

  .xl\:builder-col-start-6{
    grid-column-start: 6 !important;
  }

  .xl\:builder-col-start-7{
    grid-column-start: 7 !important;
  }

  .xl\:builder-col-start-8{
    grid-column-start: 8 !important;
  }

  .xl\:builder-col-start-9{
    grid-column-start: 9 !important;
  }

  .xl\:builder-col-start-10{
    grid-column-start: 10 !important;
  }

  .xl\:builder-col-start-11{
    grid-column-start: 11 !important;
  }

  .xl\:builder-col-start-12{
    grid-column-start: 12 !important;
  }

  .xl\:builder-col-start-13{
    grid-column-start: 13 !important;
  }

  .xl\:builder-col-start-auto{
    grid-column-start: auto !important;
  }

  .xl\:builder-col-end-1{
    grid-column-end: 1 !important;
  }

  .xl\:builder-col-end-2{
    grid-column-end: 2 !important;
  }

  .xl\:builder-col-end-3{
    grid-column-end: 3 !important;
  }

  .xl\:builder-col-end-4{
    grid-column-end: 4 !important;
  }

  .xl\:builder-col-end-5{
    grid-column-end: 5 !important;
  }

  .xl\:builder-col-end-6{
    grid-column-end: 6 !important;
  }

  .xl\:builder-col-end-7{
    grid-column-end: 7 !important;
  }

  .xl\:builder-col-end-8{
    grid-column-end: 8 !important;
  }

  .xl\:builder-col-end-9{
    grid-column-end: 9 !important;
  }

  .xl\:builder-col-end-10{
    grid-column-end: 10 !important;
  }

  .xl\:builder-col-end-11{
    grid-column-end: 11 !important;
  }

  .xl\:builder-col-end-12{
    grid-column-end: 12 !important;
  }

  .xl\:builder-col-end-13{
    grid-column-end: 13 !important;
  }

  .xl\:builder-col-end-auto{
    grid-column-end: auto !important;
  }

  .xl\:builder-row-auto{
    grid-row: auto !important;
  }

  .xl\:builder-row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .xl\:builder-row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .xl\:builder-row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .xl\:builder-row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .xl\:builder-row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .xl\:builder-row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .xl\:builder-row-span-full{
    grid-row: 1 / -1 !important;
  }

  .xl\:builder-row-start-1{
    grid-row-start: 1 !important;
  }

  .xl\:builder-row-start-2{
    grid-row-start: 2 !important;
  }

  .xl\:builder-row-start-3{
    grid-row-start: 3 !important;
  }

  .xl\:builder-row-start-4{
    grid-row-start: 4 !important;
  }

  .xl\:builder-row-start-5{
    grid-row-start: 5 !important;
  }

  .xl\:builder-row-start-6{
    grid-row-start: 6 !important;
  }

  .xl\:builder-row-start-7{
    grid-row-start: 7 !important;
  }

  .xl\:builder-row-start-auto{
    grid-row-start: auto !important;
  }

  .xl\:builder-row-end-1{
    grid-row-end: 1 !important;
  }

  .xl\:builder-row-end-2{
    grid-row-end: 2 !important;
  }

  .xl\:builder-row-end-3{
    grid-row-end: 3 !important;
  }

  .xl\:builder-row-end-4{
    grid-row-end: 4 !important;
  }

  .xl\:builder-row-end-5{
    grid-row-end: 5 !important;
  }

  .xl\:builder-row-end-6{
    grid-row-end: 6 !important;
  }

  .xl\:builder-row-end-7{
    grid-row-end: 7 !important;
  }

  .xl\:builder-row-end-auto{
    grid-row-end: auto !important;
  }

  .xl\:builder-float-right{
    float: right !important;
  }

  .xl\:builder-float-left{
    float: left !important;
  }

  .xl\:builder-float-none{
    float: none !important;
  }

  .xl\:builder-clear-left{
    clear: left !important;
  }

  .xl\:builder-clear-right{
    clear: right !important;
  }

  .xl\:builder-clear-both{
    clear: both !important;
  }

  .xl\:builder-clear-none{
    clear: none !important;
  }

  .xl\:builder-m-0{
    margin: 0px !important;
  }

  .xl\:builder-m-1{
    margin: 0.25rem !important;
  }

  .xl\:builder-m-2{
    margin: 0.5rem !important;
  }

  .xl\:builder-m-3{
    margin: 0.75rem !important;
  }

  .xl\:builder-m-4{
    margin: 1rem !important;
  }

  .xl\:builder-m-5{
    margin: 1.25rem !important;
  }

  .xl\:builder-m-6{
    margin: 1.5rem !important;
  }

  .xl\:builder-m-7{
    margin: 1.75rem !important;
  }

  .xl\:builder-m-8{
    margin: 2rem !important;
  }

  .xl\:builder-m-9{
    margin: 2.25rem !important;
  }

  .xl\:builder-m-10{
    margin: 2.5rem !important;
  }

  .xl\:builder-m-11{
    margin: 2.75rem !important;
  }

  .xl\:builder-m-12{
    margin: 3rem !important;
  }

  .xl\:builder-m-14{
    margin: 3.5rem !important;
  }

  .xl\:builder-m-16{
    margin: 4rem !important;
  }

  .xl\:builder-m-20{
    margin: 5rem !important;
  }

  .xl\:builder-m-24{
    margin: 6rem !important;
  }

  .xl\:builder-m-28{
    margin: 7rem !important;
  }

  .xl\:builder-m-32{
    margin: 8rem !important;
  }

  .xl\:builder-m-36{
    margin: 9rem !important;
  }

  .xl\:builder-m-40{
    margin: 10rem !important;
  }

  .xl\:builder-m-44{
    margin: 11rem !important;
  }

  .xl\:builder-m-48{
    margin: 12rem !important;
  }

  .xl\:builder-m-52{
    margin: 13rem !important;
  }

  .xl\:builder-m-56{
    margin: 14rem !important;
  }

  .xl\:builder-m-60{
    margin: 15rem !important;
  }

  .xl\:builder-m-64{
    margin: 16rem !important;
  }

  .xl\:builder-m-72{
    margin: 18rem !important;
  }

  .xl\:builder-m-80{
    margin: 20rem !important;
  }

  .xl\:builder-m-96{
    margin: 24rem !important;
  }

  .xl\:builder-m-auto{
    margin: auto !important;
  }

  .xl\:builder-m-px{
    margin: 1px !important;
  }

  .xl\:builder-m-0\.5{
    margin: 0.125rem !important;
  }

  .xl\:builder-m-1\.5{
    margin: 0.375rem !important;
  }

  .xl\:builder-m-2\.5{
    margin: 0.625rem !important;
  }

  .xl\:builder-m-3\.5{
    margin: 0.875rem !important;
  }

  .xl\:builder--m-0{
    margin: 0px !important;
  }

  .xl\:builder--m-1{
    margin: -0.25rem !important;
  }

  .xl\:builder--m-2{
    margin: -0.5rem !important;
  }

  .xl\:builder--m-3{
    margin: -0.75rem !important;
  }

  .xl\:builder--m-4{
    margin: -1rem !important;
  }

  .xl\:builder--m-5{
    margin: -1.25rem !important;
  }

  .xl\:builder--m-6{
    margin: -1.5rem !important;
  }

  .xl\:builder--m-7{
    margin: -1.75rem !important;
  }

  .xl\:builder--m-8{
    margin: -2rem !important;
  }

  .xl\:builder--m-9{
    margin: -2.25rem !important;
  }

  .xl\:builder--m-10{
    margin: -2.5rem !important;
  }

  .xl\:builder--m-11{
    margin: -2.75rem !important;
  }

  .xl\:builder--m-12{
    margin: -3rem !important;
  }

  .xl\:builder--m-14{
    margin: -3.5rem !important;
  }

  .xl\:builder--m-16{
    margin: -4rem !important;
  }

  .xl\:builder--m-20{
    margin: -5rem !important;
  }

  .xl\:builder--m-24{
    margin: -6rem !important;
  }

  .xl\:builder--m-28{
    margin: -7rem !important;
  }

  .xl\:builder--m-32{
    margin: -8rem !important;
  }

  .xl\:builder--m-36{
    margin: -9rem !important;
  }

  .xl\:builder--m-40{
    margin: -10rem !important;
  }

  .xl\:builder--m-44{
    margin: -11rem !important;
  }

  .xl\:builder--m-48{
    margin: -12rem !important;
  }

  .xl\:builder--m-52{
    margin: -13rem !important;
  }

  .xl\:builder--m-56{
    margin: -14rem !important;
  }

  .xl\:builder--m-60{
    margin: -15rem !important;
  }

  .xl\:builder--m-64{
    margin: -16rem !important;
  }

  .xl\:builder--m-72{
    margin: -18rem !important;
  }

  .xl\:builder--m-80{
    margin: -20rem !important;
  }

  .xl\:builder--m-96{
    margin: -24rem !important;
  }

  .xl\:builder--m-px{
    margin: -1px !important;
  }

  .xl\:builder--m-0\.5{
    margin: -0.125rem !important;
  }

  .xl\:builder--m-1\.5{
    margin: -0.375rem !important;
  }

  .xl\:builder--m-2\.5{
    margin: -0.625rem !important;
  }

  .xl\:builder--m-3\.5{
    margin: -0.875rem !important;
  }

  .xl\:builder-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xl\:builder-mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xl\:builder-mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xl\:builder-mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xl\:builder-mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:builder-mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xl\:builder-mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:builder-mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .xl\:builder-mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:builder-mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .xl\:builder-mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xl\:builder-mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .xl\:builder-mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:builder-mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .xl\:builder-mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:builder-mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:builder-mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xl\:builder-mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .xl\:builder-mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xl\:builder-mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .xl\:builder-mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xl\:builder-mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .xl\:builder-mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xl\:builder-mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .xl\:builder-mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xl\:builder-mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .xl\:builder-mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xl\:builder-mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .xl\:builder-mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .xl\:builder-mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .xl\:builder-mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:builder-mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xl\:builder-mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .xl\:builder-mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .xl\:builder-mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .xl\:builder-mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .xl\:builder--mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xl\:builder--mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xl\:builder--mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xl\:builder--mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xl\:builder--mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:builder--mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xl\:builder--mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:builder--mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .xl\:builder--mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:builder--mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .xl\:builder--mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xl\:builder--mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .xl\:builder--mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:builder--mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .xl\:builder--mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:builder--mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:builder--mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xl\:builder--mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .xl\:builder--mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xl\:builder--mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .xl\:builder--mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xl\:builder--mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .xl\:builder--mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xl\:builder--mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .xl\:builder--mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xl\:builder--mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .xl\:builder--mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xl\:builder--mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .xl\:builder--mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .xl\:builder--mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .xl\:builder--mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xl\:builder--mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .xl\:builder--mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .xl\:builder--mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .xl\:builder--mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .xl\:builder-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl\:builder-my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xl\:builder-my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xl\:builder-my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xl\:builder-my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:builder-my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xl\:builder-my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:builder-my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .xl\:builder-my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:builder-my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .xl\:builder-my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xl\:builder-my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .xl\:builder-my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:builder-my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .xl\:builder-my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:builder-my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:builder-my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xl\:builder-my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .xl\:builder-my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xl\:builder-my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .xl\:builder-my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xl\:builder-my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .xl\:builder-my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xl\:builder-my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .xl\:builder-my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xl\:builder-my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .xl\:builder-my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xl\:builder-my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .xl\:builder-my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .xl\:builder-my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .xl\:builder-my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xl\:builder-my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xl\:builder-my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .xl\:builder-my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .xl\:builder-my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .xl\:builder-my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .xl\:builder--my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl\:builder--my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xl\:builder--my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xl\:builder--my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xl\:builder--my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:builder--my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xl\:builder--my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:builder--my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .xl\:builder--my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:builder--my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .xl\:builder--my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xl\:builder--my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .xl\:builder--my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:builder--my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .xl\:builder--my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:builder--my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:builder--my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xl\:builder--my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .xl\:builder--my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xl\:builder--my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .xl\:builder--my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xl\:builder--my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .xl\:builder--my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xl\:builder--my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .xl\:builder--my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xl\:builder--my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .xl\:builder--my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xl\:builder--my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .xl\:builder--my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .xl\:builder--my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .xl\:builder--my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xl\:builder--my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .xl\:builder--my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .xl\:builder--my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .xl\:builder--my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .xl\:builder-mt-0{
    margin-top: 0px !important;
  }

  .xl\:builder-mt-1{
    margin-top: 0.25rem !important;
  }

  .xl\:builder-mt-2{
    margin-top: 0.5rem !important;
  }

  .xl\:builder-mt-3{
    margin-top: 0.75rem !important;
  }

  .xl\:builder-mt-4{
    margin-top: 1rem !important;
  }

  .xl\:builder-mt-5{
    margin-top: 1.25rem !important;
  }

  .xl\:builder-mt-6{
    margin-top: 1.5rem !important;
  }

  .xl\:builder-mt-7{
    margin-top: 1.75rem !important;
  }

  .xl\:builder-mt-8{
    margin-top: 2rem !important;
  }

  .xl\:builder-mt-9{
    margin-top: 2.25rem !important;
  }

  .xl\:builder-mt-10{
    margin-top: 2.5rem !important;
  }

  .xl\:builder-mt-11{
    margin-top: 2.75rem !important;
  }

  .xl\:builder-mt-12{
    margin-top: 3rem !important;
  }

  .xl\:builder-mt-14{
    margin-top: 3.5rem !important;
  }

  .xl\:builder-mt-16{
    margin-top: 4rem !important;
  }

  .xl\:builder-mt-20{
    margin-top: 5rem !important;
  }

  .xl\:builder-mt-24{
    margin-top: 6rem !important;
  }

  .xl\:builder-mt-28{
    margin-top: 7rem !important;
  }

  .xl\:builder-mt-32{
    margin-top: 8rem !important;
  }

  .xl\:builder-mt-36{
    margin-top: 9rem !important;
  }

  .xl\:builder-mt-40{
    margin-top: 10rem !important;
  }

  .xl\:builder-mt-44{
    margin-top: 11rem !important;
  }

  .xl\:builder-mt-48{
    margin-top: 12rem !important;
  }

  .xl\:builder-mt-52{
    margin-top: 13rem !important;
  }

  .xl\:builder-mt-56{
    margin-top: 14rem !important;
  }

  .xl\:builder-mt-60{
    margin-top: 15rem !important;
  }

  .xl\:builder-mt-64{
    margin-top: 16rem !important;
  }

  .xl\:builder-mt-72{
    margin-top: 18rem !important;
  }

  .xl\:builder-mt-80{
    margin-top: 20rem !important;
  }

  .xl\:builder-mt-96{
    margin-top: 24rem !important;
  }

  .xl\:builder-mt-auto{
    margin-top: auto !important;
  }

  .xl\:builder-mt-px{
    margin-top: 1px !important;
  }

  .xl\:builder-mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .xl\:builder-mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .xl\:builder-mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .xl\:builder-mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .xl\:builder--mt-0{
    margin-top: 0px !important;
  }

  .xl\:builder--mt-1{
    margin-top: -0.25rem !important;
  }

  .xl\:builder--mt-2{
    margin-top: -0.5rem !important;
  }

  .xl\:builder--mt-3{
    margin-top: -0.75rem !important;
  }

  .xl\:builder--mt-4{
    margin-top: -1rem !important;
  }

  .xl\:builder--mt-5{
    margin-top: -1.25rem !important;
  }

  .xl\:builder--mt-6{
    margin-top: -1.5rem !important;
  }

  .xl\:builder--mt-7{
    margin-top: -1.75rem !important;
  }

  .xl\:builder--mt-8{
    margin-top: -2rem !important;
  }

  .xl\:builder--mt-9{
    margin-top: -2.25rem !important;
  }

  .xl\:builder--mt-10{
    margin-top: -2.5rem !important;
  }

  .xl\:builder--mt-11{
    margin-top: -2.75rem !important;
  }

  .xl\:builder--mt-12{
    margin-top: -3rem !important;
  }

  .xl\:builder--mt-14{
    margin-top: -3.5rem !important;
  }

  .xl\:builder--mt-16{
    margin-top: -4rem !important;
  }

  .xl\:builder--mt-20{
    margin-top: -5rem !important;
  }

  .xl\:builder--mt-24{
    margin-top: -6rem !important;
  }

  .xl\:builder--mt-28{
    margin-top: -7rem !important;
  }

  .xl\:builder--mt-32{
    margin-top: -8rem !important;
  }

  .xl\:builder--mt-36{
    margin-top: -9rem !important;
  }

  .xl\:builder--mt-40{
    margin-top: -10rem !important;
  }

  .xl\:builder--mt-44{
    margin-top: -11rem !important;
  }

  .xl\:builder--mt-48{
    margin-top: -12rem !important;
  }

  .xl\:builder--mt-52{
    margin-top: -13rem !important;
  }

  .xl\:builder--mt-56{
    margin-top: -14rem !important;
  }

  .xl\:builder--mt-60{
    margin-top: -15rem !important;
  }

  .xl\:builder--mt-64{
    margin-top: -16rem !important;
  }

  .xl\:builder--mt-72{
    margin-top: -18rem !important;
  }

  .xl\:builder--mt-80{
    margin-top: -20rem !important;
  }

  .xl\:builder--mt-96{
    margin-top: -24rem !important;
  }

  .xl\:builder--mt-px{
    margin-top: -1px !important;
  }

  .xl\:builder--mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .xl\:builder--mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .xl\:builder--mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .xl\:builder--mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .xl\:builder-mr-0{
    margin-right: 0px !important;
  }

  .xl\:builder-mr-1{
    margin-right: 0.25rem !important;
  }

  .xl\:builder-mr-2{
    margin-right: 0.5rem !important;
  }

  .xl\:builder-mr-3{
    margin-right: 0.75rem !important;
  }

  .xl\:builder-mr-4{
    margin-right: 1rem !important;
  }

  .xl\:builder-mr-5{
    margin-right: 1.25rem !important;
  }

  .xl\:builder-mr-6{
    margin-right: 1.5rem !important;
  }

  .xl\:builder-mr-7{
    margin-right: 1.75rem !important;
  }

  .xl\:builder-mr-8{
    margin-right: 2rem !important;
  }

  .xl\:builder-mr-9{
    margin-right: 2.25rem !important;
  }

  .xl\:builder-mr-10{
    margin-right: 2.5rem !important;
  }

  .xl\:builder-mr-11{
    margin-right: 2.75rem !important;
  }

  .xl\:builder-mr-12{
    margin-right: 3rem !important;
  }

  .xl\:builder-mr-14{
    margin-right: 3.5rem !important;
  }

  .xl\:builder-mr-16{
    margin-right: 4rem !important;
  }

  .xl\:builder-mr-20{
    margin-right: 5rem !important;
  }

  .xl\:builder-mr-24{
    margin-right: 6rem !important;
  }

  .xl\:builder-mr-28{
    margin-right: 7rem !important;
  }

  .xl\:builder-mr-32{
    margin-right: 8rem !important;
  }

  .xl\:builder-mr-36{
    margin-right: 9rem !important;
  }

  .xl\:builder-mr-40{
    margin-right: 10rem !important;
  }

  .xl\:builder-mr-44{
    margin-right: 11rem !important;
  }

  .xl\:builder-mr-48{
    margin-right: 12rem !important;
  }

  .xl\:builder-mr-52{
    margin-right: 13rem !important;
  }

  .xl\:builder-mr-56{
    margin-right: 14rem !important;
  }

  .xl\:builder-mr-60{
    margin-right: 15rem !important;
  }

  .xl\:builder-mr-64{
    margin-right: 16rem !important;
  }

  .xl\:builder-mr-72{
    margin-right: 18rem !important;
  }

  .xl\:builder-mr-80{
    margin-right: 20rem !important;
  }

  .xl\:builder-mr-96{
    margin-right: 24rem !important;
  }

  .xl\:builder-mr-auto{
    margin-right: auto !important;
  }

  .xl\:builder-mr-px{
    margin-right: 1px !important;
  }

  .xl\:builder-mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .xl\:builder-mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .xl\:builder-mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .xl\:builder-mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .xl\:builder--mr-0{
    margin-right: 0px !important;
  }

  .xl\:builder--mr-1{
    margin-right: -0.25rem !important;
  }

  .xl\:builder--mr-2{
    margin-right: -0.5rem !important;
  }

  .xl\:builder--mr-3{
    margin-right: -0.75rem !important;
  }

  .xl\:builder--mr-4{
    margin-right: -1rem !important;
  }

  .xl\:builder--mr-5{
    margin-right: -1.25rem !important;
  }

  .xl\:builder--mr-6{
    margin-right: -1.5rem !important;
  }

  .xl\:builder--mr-7{
    margin-right: -1.75rem !important;
  }

  .xl\:builder--mr-8{
    margin-right: -2rem !important;
  }

  .xl\:builder--mr-9{
    margin-right: -2.25rem !important;
  }

  .xl\:builder--mr-10{
    margin-right: -2.5rem !important;
  }

  .xl\:builder--mr-11{
    margin-right: -2.75rem !important;
  }

  .xl\:builder--mr-12{
    margin-right: -3rem !important;
  }

  .xl\:builder--mr-14{
    margin-right: -3.5rem !important;
  }

  .xl\:builder--mr-16{
    margin-right: -4rem !important;
  }

  .xl\:builder--mr-20{
    margin-right: -5rem !important;
  }

  .xl\:builder--mr-24{
    margin-right: -6rem !important;
  }

  .xl\:builder--mr-28{
    margin-right: -7rem !important;
  }

  .xl\:builder--mr-32{
    margin-right: -8rem !important;
  }

  .xl\:builder--mr-36{
    margin-right: -9rem !important;
  }

  .xl\:builder--mr-40{
    margin-right: -10rem !important;
  }

  .xl\:builder--mr-44{
    margin-right: -11rem !important;
  }

  .xl\:builder--mr-48{
    margin-right: -12rem !important;
  }

  .xl\:builder--mr-52{
    margin-right: -13rem !important;
  }

  .xl\:builder--mr-56{
    margin-right: -14rem !important;
  }

  .xl\:builder--mr-60{
    margin-right: -15rem !important;
  }

  .xl\:builder--mr-64{
    margin-right: -16rem !important;
  }

  .xl\:builder--mr-72{
    margin-right: -18rem !important;
  }

  .xl\:builder--mr-80{
    margin-right: -20rem !important;
  }

  .xl\:builder--mr-96{
    margin-right: -24rem !important;
  }

  .xl\:builder--mr-px{
    margin-right: -1px !important;
  }

  .xl\:builder--mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .xl\:builder--mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .xl\:builder--mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .xl\:builder--mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .xl\:builder-mb-0{
    margin-bottom: 0px !important;
  }

  .xl\:builder-mb-1{
    margin-bottom: 0.25rem !important;
  }

  .xl\:builder-mb-2{
    margin-bottom: 0.5rem !important;
  }

  .xl\:builder-mb-3{
    margin-bottom: 0.75rem !important;
  }

  .xl\:builder-mb-4{
    margin-bottom: 1rem !important;
  }

  .xl\:builder-mb-5{
    margin-bottom: 1.25rem !important;
  }

  .xl\:builder-mb-6{
    margin-bottom: 1.5rem !important;
  }

  .xl\:builder-mb-7{
    margin-bottom: 1.75rem !important;
  }

  .xl\:builder-mb-8{
    margin-bottom: 2rem !important;
  }

  .xl\:builder-mb-9{
    margin-bottom: 2.25rem !important;
  }

  .xl\:builder-mb-10{
    margin-bottom: 2.5rem !important;
  }

  .xl\:builder-mb-11{
    margin-bottom: 2.75rem !important;
  }

  .xl\:builder-mb-12{
    margin-bottom: 3rem !important;
  }

  .xl\:builder-mb-14{
    margin-bottom: 3.5rem !important;
  }

  .xl\:builder-mb-16{
    margin-bottom: 4rem !important;
  }

  .xl\:builder-mb-20{
    margin-bottom: 5rem !important;
  }

  .xl\:builder-mb-24{
    margin-bottom: 6rem !important;
  }

  .xl\:builder-mb-28{
    margin-bottom: 7rem !important;
  }

  .xl\:builder-mb-32{
    margin-bottom: 8rem !important;
  }

  .xl\:builder-mb-36{
    margin-bottom: 9rem !important;
  }

  .xl\:builder-mb-40{
    margin-bottom: 10rem !important;
  }

  .xl\:builder-mb-44{
    margin-bottom: 11rem !important;
  }

  .xl\:builder-mb-48{
    margin-bottom: 12rem !important;
  }

  .xl\:builder-mb-52{
    margin-bottom: 13rem !important;
  }

  .xl\:builder-mb-56{
    margin-bottom: 14rem !important;
  }

  .xl\:builder-mb-60{
    margin-bottom: 15rem !important;
  }

  .xl\:builder-mb-64{
    margin-bottom: 16rem !important;
  }

  .xl\:builder-mb-72{
    margin-bottom: 18rem !important;
  }

  .xl\:builder-mb-80{
    margin-bottom: 20rem !important;
  }

  .xl\:builder-mb-96{
    margin-bottom: 24rem !important;
  }

  .xl\:builder-mb-auto{
    margin-bottom: auto !important;
  }

  .xl\:builder-mb-px{
    margin-bottom: 1px !important;
  }

  .xl\:builder-mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .xl\:builder-mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .xl\:builder-mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .xl\:builder-mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .xl\:builder--mb-0{
    margin-bottom: 0px !important;
  }

  .xl\:builder--mb-1{
    margin-bottom: -0.25rem !important;
  }

  .xl\:builder--mb-2{
    margin-bottom: -0.5rem !important;
  }

  .xl\:builder--mb-3{
    margin-bottom: -0.75rem !important;
  }

  .xl\:builder--mb-4{
    margin-bottom: -1rem !important;
  }

  .xl\:builder--mb-5{
    margin-bottom: -1.25rem !important;
  }

  .xl\:builder--mb-6{
    margin-bottom: -1.5rem !important;
  }

  .xl\:builder--mb-7{
    margin-bottom: -1.75rem !important;
  }

  .xl\:builder--mb-8{
    margin-bottom: -2rem !important;
  }

  .xl\:builder--mb-9{
    margin-bottom: -2.25rem !important;
  }

  .xl\:builder--mb-10{
    margin-bottom: -2.5rem !important;
  }

  .xl\:builder--mb-11{
    margin-bottom: -2.75rem !important;
  }

  .xl\:builder--mb-12{
    margin-bottom: -3rem !important;
  }

  .xl\:builder--mb-14{
    margin-bottom: -3.5rem !important;
  }

  .xl\:builder--mb-16{
    margin-bottom: -4rem !important;
  }

  .xl\:builder--mb-20{
    margin-bottom: -5rem !important;
  }

  .xl\:builder--mb-24{
    margin-bottom: -6rem !important;
  }

  .xl\:builder--mb-28{
    margin-bottom: -7rem !important;
  }

  .xl\:builder--mb-32{
    margin-bottom: -8rem !important;
  }

  .xl\:builder--mb-36{
    margin-bottom: -9rem !important;
  }

  .xl\:builder--mb-40{
    margin-bottom: -10rem !important;
  }

  .xl\:builder--mb-44{
    margin-bottom: -11rem !important;
  }

  .xl\:builder--mb-48{
    margin-bottom: -12rem !important;
  }

  .xl\:builder--mb-52{
    margin-bottom: -13rem !important;
  }

  .xl\:builder--mb-56{
    margin-bottom: -14rem !important;
  }

  .xl\:builder--mb-60{
    margin-bottom: -15rem !important;
  }

  .xl\:builder--mb-64{
    margin-bottom: -16rem !important;
  }

  .xl\:builder--mb-72{
    margin-bottom: -18rem !important;
  }

  .xl\:builder--mb-80{
    margin-bottom: -20rem !important;
  }

  .xl\:builder--mb-96{
    margin-bottom: -24rem !important;
  }

  .xl\:builder--mb-px{
    margin-bottom: -1px !important;
  }

  .xl\:builder--mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .xl\:builder--mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .xl\:builder--mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .xl\:builder--mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .xl\:builder-ml-0{
    margin-left: 0px !important;
  }

  .xl\:builder-ml-1{
    margin-left: 0.25rem !important;
  }

  .xl\:builder-ml-2{
    margin-left: 0.5rem !important;
  }

  .xl\:builder-ml-3{
    margin-left: 0.75rem !important;
  }

  .xl\:builder-ml-4{
    margin-left: 1rem !important;
  }

  .xl\:builder-ml-5{
    margin-left: 1.25rem !important;
  }

  .xl\:builder-ml-6{
    margin-left: 1.5rem !important;
  }

  .xl\:builder-ml-7{
    margin-left: 1.75rem !important;
  }

  .xl\:builder-ml-8{
    margin-left: 2rem !important;
  }

  .xl\:builder-ml-9{
    margin-left: 2.25rem !important;
  }

  .xl\:builder-ml-10{
    margin-left: 2.5rem !important;
  }

  .xl\:builder-ml-11{
    margin-left: 2.75rem !important;
  }

  .xl\:builder-ml-12{
    margin-left: 3rem !important;
  }

  .xl\:builder-ml-14{
    margin-left: 3.5rem !important;
  }

  .xl\:builder-ml-16{
    margin-left: 4rem !important;
  }

  .xl\:builder-ml-20{
    margin-left: 5rem !important;
  }

  .xl\:builder-ml-24{
    margin-left: 6rem !important;
  }

  .xl\:builder-ml-28{
    margin-left: 7rem !important;
  }

  .xl\:builder-ml-32{
    margin-left: 8rem !important;
  }

  .xl\:builder-ml-36{
    margin-left: 9rem !important;
  }

  .xl\:builder-ml-40{
    margin-left: 10rem !important;
  }

  .xl\:builder-ml-44{
    margin-left: 11rem !important;
  }

  .xl\:builder-ml-48{
    margin-left: 12rem !important;
  }

  .xl\:builder-ml-52{
    margin-left: 13rem !important;
  }

  .xl\:builder-ml-56{
    margin-left: 14rem !important;
  }

  .xl\:builder-ml-60{
    margin-left: 15rem !important;
  }

  .xl\:builder-ml-64{
    margin-left: 16rem !important;
  }

  .xl\:builder-ml-72{
    margin-left: 18rem !important;
  }

  .xl\:builder-ml-80{
    margin-left: 20rem !important;
  }

  .xl\:builder-ml-96{
    margin-left: 24rem !important;
  }

  .xl\:builder-ml-auto{
    margin-left: auto !important;
  }

  .xl\:builder-ml-px{
    margin-left: 1px !important;
  }

  .xl\:builder-ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .xl\:builder-ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .xl\:builder-ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .xl\:builder-ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .xl\:builder--ml-0{
    margin-left: 0px !important;
  }

  .xl\:builder--ml-1{
    margin-left: -0.25rem !important;
  }

  .xl\:builder--ml-2{
    margin-left: -0.5rem !important;
  }

  .xl\:builder--ml-3{
    margin-left: -0.75rem !important;
  }

  .xl\:builder--ml-4{
    margin-left: -1rem !important;
  }

  .xl\:builder--ml-5{
    margin-left: -1.25rem !important;
  }

  .xl\:builder--ml-6{
    margin-left: -1.5rem !important;
  }

  .xl\:builder--ml-7{
    margin-left: -1.75rem !important;
  }

  .xl\:builder--ml-8{
    margin-left: -2rem !important;
  }

  .xl\:builder--ml-9{
    margin-left: -2.25rem !important;
  }

  .xl\:builder--ml-10{
    margin-left: -2.5rem !important;
  }

  .xl\:builder--ml-11{
    margin-left: -2.75rem !important;
  }

  .xl\:builder--ml-12{
    margin-left: -3rem !important;
  }

  .xl\:builder--ml-14{
    margin-left: -3.5rem !important;
  }

  .xl\:builder--ml-16{
    margin-left: -4rem !important;
  }

  .xl\:builder--ml-20{
    margin-left: -5rem !important;
  }

  .xl\:builder--ml-24{
    margin-left: -6rem !important;
  }

  .xl\:builder--ml-28{
    margin-left: -7rem !important;
  }

  .xl\:builder--ml-32{
    margin-left: -8rem !important;
  }

  .xl\:builder--ml-36{
    margin-left: -9rem !important;
  }

  .xl\:builder--ml-40{
    margin-left: -10rem !important;
  }

  .xl\:builder--ml-44{
    margin-left: -11rem !important;
  }

  .xl\:builder--ml-48{
    margin-left: -12rem !important;
  }

  .xl\:builder--ml-52{
    margin-left: -13rem !important;
  }

  .xl\:builder--ml-56{
    margin-left: -14rem !important;
  }

  .xl\:builder--ml-60{
    margin-left: -15rem !important;
  }

  .xl\:builder--ml-64{
    margin-left: -16rem !important;
  }

  .xl\:builder--ml-72{
    margin-left: -18rem !important;
  }

  .xl\:builder--ml-80{
    margin-left: -20rem !important;
  }

  .xl\:builder--ml-96{
    margin-left: -24rem !important;
  }

  .xl\:builder--ml-px{
    margin-left: -1px !important;
  }

  .xl\:builder--ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .xl\:builder--ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .xl\:builder--ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .xl\:builder--ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .xl\:builder-box-border{
    box-sizing: border-box !important;
  }

  .xl\:builder-box-content{
    box-sizing: content-box !important;
  }

  .xl\:builder-block{
    display: block !important;
  }

  .xl\:builder-inline-block{
    display: inline-block !important;
  }

  .xl\:builder-inline{
    display: inline !important;
  }

  .xl\:builder-flex{
    display: flex !important;
  }

  .xl\:builder-inline-flex{
    display: inline-flex !important;
  }

  .xl\:builder-table{
    display: table !important;
  }

  .xl\:builder-inline-table{
    display: inline-table !important;
  }

  .xl\:builder-table-caption{
    display: table-caption !important;
  }

  .xl\:builder-table-cell{
    display: table-cell !important;
  }

  .xl\:builder-table-column{
    display: table-column !important;
  }

  .xl\:builder-table-column-group{
    display: table-column-group !important;
  }

  .xl\:builder-table-footer-group{
    display: table-footer-group !important;
  }

  .xl\:builder-table-header-group{
    display: table-header-group !important;
  }

  .xl\:builder-table-row-group{
    display: table-row-group !important;
  }

  .xl\:builder-table-row{
    display: table-row !important;
  }

  .xl\:builder-flow-root{
    display: flow-root !important;
  }

  .xl\:builder-grid{
    display: grid !important;
  }

  .xl\:builder-inline-grid{
    display: inline-grid !important;
  }

  .xl\:builder-contents{
    display: contents !important;
  }

  .xl\:builder-list-item{
    display: list-item !important;
  }

  .xl\:builder-hidden{
    display: none !important;
  }

  .xl\:builder-h-0{
    height: 0px !important;
  }

  .xl\:builder-h-1{
    height: 0.25rem !important;
  }

  .xl\:builder-h-2{
    height: 0.5rem !important;
  }

  .xl\:builder-h-3{
    height: 0.75rem !important;
  }

  .xl\:builder-h-4{
    height: 1rem !important;
  }

  .xl\:builder-h-5{
    height: 1.25rem !important;
  }

  .xl\:builder-h-6{
    height: 1.5rem !important;
  }

  .xl\:builder-h-7{
    height: 1.75rem !important;
  }

  .xl\:builder-h-8{
    height: 2rem !important;
  }

  .xl\:builder-h-9{
    height: 2.25rem !important;
  }

  .xl\:builder-h-10{
    height: 2.5rem !important;
  }

  .xl\:builder-h-11{
    height: 2.75rem !important;
  }

  .xl\:builder-h-12{
    height: 3rem !important;
  }

  .xl\:builder-h-14{
    height: 3.5rem !important;
  }

  .xl\:builder-h-16{
    height: 4rem !important;
  }

  .xl\:builder-h-20{
    height: 5rem !important;
  }

  .xl\:builder-h-24{
    height: 6rem !important;
  }

  .xl\:builder-h-28{
    height: 7rem !important;
  }

  .xl\:builder-h-32{
    height: 8rem !important;
  }

  .xl\:builder-h-36{
    height: 9rem !important;
  }

  .xl\:builder-h-40{
    height: 10rem !important;
  }

  .xl\:builder-h-44{
    height: 11rem !important;
  }

  .xl\:builder-h-48{
    height: 12rem !important;
  }

  .xl\:builder-h-52{
    height: 13rem !important;
  }

  .xl\:builder-h-56{
    height: 14rem !important;
  }

  .xl\:builder-h-60{
    height: 15rem !important;
  }

  .xl\:builder-h-64{
    height: 16rem !important;
  }

  .xl\:builder-h-72{
    height: 18rem !important;
  }

  .xl\:builder-h-80{
    height: 20rem !important;
  }

  .xl\:builder-h-96{
    height: 24rem !important;
  }

  .xl\:builder-h-auto{
    height: auto !important;
  }

  .xl\:builder-h-px{
    height: 1px !important;
  }

  .xl\:builder-h-0\.5{
    height: 0.125rem !important;
  }

  .xl\:builder-h-1\.5{
    height: 0.375rem !important;
  }

  .xl\:builder-h-2\.5{
    height: 0.625rem !important;
  }

  .xl\:builder-h-3\.5{
    height: 0.875rem !important;
  }

  .xl\:builder-h-1\/2{
    height: 50% !important;
  }

  .xl\:builder-h-1\/3{
    height: 33.333333% !important;
  }

  .xl\:builder-h-2\/3{
    height: 66.666667% !important;
  }

  .xl\:builder-h-1\/4{
    height: 25% !important;
  }

  .xl\:builder-h-2\/4{
    height: 50% !important;
  }

  .xl\:builder-h-3\/4{
    height: 75% !important;
  }

  .xl\:builder-h-1\/5{
    height: 20% !important;
  }

  .xl\:builder-h-2\/5{
    height: 40% !important;
  }

  .xl\:builder-h-3\/5{
    height: 60% !important;
  }

  .xl\:builder-h-4\/5{
    height: 80% !important;
  }

  .xl\:builder-h-1\/6{
    height: 16.666667% !important;
  }

  .xl\:builder-h-2\/6{
    height: 33.333333% !important;
  }

  .xl\:builder-h-3\/6{
    height: 50% !important;
  }

  .xl\:builder-h-4\/6{
    height: 66.666667% !important;
  }

  .xl\:builder-h-5\/6{
    height: 83.333333% !important;
  }

  .xl\:builder-h-full{
    height: 100% !important;
  }

  .xl\:builder-h-screen{
    height: 100vh !important;
  }

  .xl\:builder-max-h-0{
    max-height: 0px !important;
  }

  .xl\:builder-max-h-1{
    max-height: 0.25rem !important;
  }

  .xl\:builder-max-h-2{
    max-height: 0.5rem !important;
  }

  .xl\:builder-max-h-3{
    max-height: 0.75rem !important;
  }

  .xl\:builder-max-h-4{
    max-height: 1rem !important;
  }

  .xl\:builder-max-h-5{
    max-height: 1.25rem !important;
  }

  .xl\:builder-max-h-6{
    max-height: 1.5rem !important;
  }

  .xl\:builder-max-h-7{
    max-height: 1.75rem !important;
  }

  .xl\:builder-max-h-8{
    max-height: 2rem !important;
  }

  .xl\:builder-max-h-9{
    max-height: 2.25rem !important;
  }

  .xl\:builder-max-h-10{
    max-height: 2.5rem !important;
  }

  .xl\:builder-max-h-11{
    max-height: 2.75rem !important;
  }

  .xl\:builder-max-h-12{
    max-height: 3rem !important;
  }

  .xl\:builder-max-h-14{
    max-height: 3.5rem !important;
  }

  .xl\:builder-max-h-16{
    max-height: 4rem !important;
  }

  .xl\:builder-max-h-20{
    max-height: 5rem !important;
  }

  .xl\:builder-max-h-24{
    max-height: 6rem !important;
  }

  .xl\:builder-max-h-28{
    max-height: 7rem !important;
  }

  .xl\:builder-max-h-32{
    max-height: 8rem !important;
  }

  .xl\:builder-max-h-36{
    max-height: 9rem !important;
  }

  .xl\:builder-max-h-40{
    max-height: 10rem !important;
  }

  .xl\:builder-max-h-44{
    max-height: 11rem !important;
  }

  .xl\:builder-max-h-48{
    max-height: 12rem !important;
  }

  .xl\:builder-max-h-52{
    max-height: 13rem !important;
  }

  .xl\:builder-max-h-56{
    max-height: 14rem !important;
  }

  .xl\:builder-max-h-60{
    max-height: 15rem !important;
  }

  .xl\:builder-max-h-64{
    max-height: 16rem !important;
  }

  .xl\:builder-max-h-72{
    max-height: 18rem !important;
  }

  .xl\:builder-max-h-80{
    max-height: 20rem !important;
  }

  .xl\:builder-max-h-96{
    max-height: 24rem !important;
  }

  .xl\:builder-max-h-px{
    max-height: 1px !important;
  }

  .xl\:builder-max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .xl\:builder-max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .xl\:builder-max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .xl\:builder-max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .xl\:builder-max-h-full{
    max-height: 100% !important;
  }

  .xl\:builder-max-h-screen{
    max-height: 100vh !important;
  }

  .xl\:builder-min-h-0{
    min-height: 0px !important;
  }

  .xl\:builder-min-h-full{
    min-height: 100% !important;
  }

  .xl\:builder-min-h-screen{
    min-height: 100vh !important;
  }

  .xl\:builder-w-0{
    width: 0px !important;
  }

  .xl\:builder-w-1{
    width: 0.25rem !important;
  }

  .xl\:builder-w-2{
    width: 0.5rem !important;
  }

  .xl\:builder-w-3{
    width: 0.75rem !important;
  }

  .xl\:builder-w-4{
    width: 1rem !important;
  }

  .xl\:builder-w-5{
    width: 1.25rem !important;
  }

  .xl\:builder-w-6{
    width: 1.5rem !important;
  }

  .xl\:builder-w-7{
    width: 1.75rem !important;
  }

  .xl\:builder-w-8{
    width: 2rem !important;
  }

  .xl\:builder-w-9{
    width: 2.25rem !important;
  }

  .xl\:builder-w-10{
    width: 2.5rem !important;
  }

  .xl\:builder-w-11{
    width: 2.75rem !important;
  }

  .xl\:builder-w-12{
    width: 3rem !important;
  }

  .xl\:builder-w-14{
    width: 3.5rem !important;
  }

  .xl\:builder-w-16{
    width: 4rem !important;
  }

  .xl\:builder-w-20{
    width: 5rem !important;
  }

  .xl\:builder-w-24{
    width: 6rem !important;
  }

  .xl\:builder-w-28{
    width: 7rem !important;
  }

  .xl\:builder-w-32{
    width: 8rem !important;
  }

  .xl\:builder-w-36{
    width: 9rem !important;
  }

  .xl\:builder-w-40{
    width: 10rem !important;
  }

  .xl\:builder-w-44{
    width: 11rem !important;
  }

  .xl\:builder-w-48{
    width: 12rem !important;
  }

  .xl\:builder-w-52{
    width: 13rem !important;
  }

  .xl\:builder-w-56{
    width: 14rem !important;
  }

  .xl\:builder-w-60{
    width: 15rem !important;
  }

  .xl\:builder-w-64{
    width: 16rem !important;
  }

  .xl\:builder-w-72{
    width: 18rem !important;
  }

  .xl\:builder-w-80{
    width: 20rem !important;
  }

  .xl\:builder-w-96{
    width: 24rem !important;
  }

  .xl\:builder-w-auto{
    width: auto !important;
  }

  .xl\:builder-w-px{
    width: 1px !important;
  }

  .xl\:builder-w-0\.5{
    width: 0.125rem !important;
  }

  .xl\:builder-w-1\.5{
    width: 0.375rem !important;
  }

  .xl\:builder-w-2\.5{
    width: 0.625rem !important;
  }

  .xl\:builder-w-3\.5{
    width: 0.875rem !important;
  }

  .xl\:builder-w-1\/2{
    width: 50% !important;
  }

  .xl\:builder-w-1\/3{
    width: 33.333333% !important;
  }

  .xl\:builder-w-2\/3{
    width: 66.666667% !important;
  }

  .xl\:builder-w-1\/4{
    width: 25% !important;
  }

  .xl\:builder-w-2\/4{
    width: 50% !important;
  }

  .xl\:builder-w-3\/4{
    width: 75% !important;
  }

  .xl\:builder-w-1\/5{
    width: 20% !important;
  }

  .xl\:builder-w-2\/5{
    width: 40% !important;
  }

  .xl\:builder-w-3\/5{
    width: 60% !important;
  }

  .xl\:builder-w-4\/5{
    width: 80% !important;
  }

  .xl\:builder-w-1\/6{
    width: 16.666667% !important;
  }

  .xl\:builder-w-2\/6{
    width: 33.333333% !important;
  }

  .xl\:builder-w-3\/6{
    width: 50% !important;
  }

  .xl\:builder-w-4\/6{
    width: 66.666667% !important;
  }

  .xl\:builder-w-5\/6{
    width: 83.333333% !important;
  }

  .xl\:builder-w-1\/12{
    width: 8.333333% !important;
  }

  .xl\:builder-w-2\/12{
    width: 16.666667% !important;
  }

  .xl\:builder-w-3\/12{
    width: 25% !important;
  }

  .xl\:builder-w-4\/12{
    width: 33.333333% !important;
  }

  .xl\:builder-w-5\/12{
    width: 41.666667% !important;
  }

  .xl\:builder-w-6\/12{
    width: 50% !important;
  }

  .xl\:builder-w-7\/12{
    width: 58.333333% !important;
  }

  .xl\:builder-w-8\/12{
    width: 66.666667% !important;
  }

  .xl\:builder-w-9\/12{
    width: 75% !important;
  }

  .xl\:builder-w-10\/12{
    width: 83.333333% !important;
  }

  .xl\:builder-w-11\/12{
    width: 91.666667% !important;
  }

  .xl\:builder-w-full{
    width: 100% !important;
  }

  .xl\:builder-w-screen{
    width: 100vw !important;
  }

  .xl\:builder-w-min{
    width: min-content !important;
  }

  .xl\:builder-w-max{
    width: max-content !important;
  }

  .xl\:builder-min-w-0{
    min-width: 0px !important;
  }

  .xl\:builder-min-w-full{
    min-width: 100% !important;
  }

  .xl\:builder-min-w-min{
    min-width: min-content !important;
  }

  .xl\:builder-min-w-max{
    min-width: max-content !important;
  }

  .xl\:builder-max-w-0{
    max-width: 0rem !important;
  }

  .xl\:builder-max-w-none{
    max-width: none !important;
  }

  .xl\:builder-max-w-xs{
    max-width: 20rem !important;
  }

  .xl\:builder-max-w-sm{
    max-width: 24rem !important;
  }

  .xl\:builder-max-w-md{
    max-width: 28rem !important;
  }

  .xl\:builder-max-w-lg{
    max-width: 32rem !important;
  }

  .xl\:builder-max-w-xl{
    max-width: 36rem !important;
  }

  .xl\:builder-max-w-2xl{
    max-width: 42rem !important;
  }

  .xl\:builder-max-w-3xl{
    max-width: 48rem !important;
  }

  .xl\:builder-max-w-4xl{
    max-width: 56rem !important;
  }

  .xl\:builder-max-w-5xl{
    max-width: 64rem !important;
  }

  .xl\:builder-max-w-6xl{
    max-width: 72rem !important;
  }

  .xl\:builder-max-w-7xl{
    max-width: 80rem !important;
  }

  .xl\:builder-max-w-full{
    max-width: 100% !important;
  }

  .xl\:builder-max-w-min{
    max-width: min-content !important;
  }

  .xl\:builder-max-w-max{
    max-width: max-content !important;
  }

  .xl\:builder-max-w-prose{
    max-width: 65ch !important;
  }

  .xl\:builder-max-w-screen-sm{
    max-width: 640px !important;
  }

  .xl\:builder-max-w-screen-md{
    max-width: 768px !important;
  }

  .xl\:builder-max-w-screen-lg{
    max-width: 1024px !important;
  }

  .xl\:builder-max-w-screen-xl{
    max-width: 1280px !important;
  }

  .xl\:builder-max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .xl\:builder-flex-1{
    flex: 1 1 0% !important;
  }

  .xl\:builder-flex-auto{
    flex: 1 1 auto !important;
  }

  .xl\:builder-flex-initial{
    flex: 0 1 auto !important;
  }

  .xl\:builder-flex-none{
    flex: none !important;
  }

  .xl\:builder-flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .xl\:builder-flex-shrink{
    flex-shrink: 1 !important;
  }

  .xl\:builder-flex-grow-0{
    flex-grow: 0 !important;
  }

  .xl\:builder-flex-grow{
    flex-grow: 1 !important;
  }

  .xl\:builder-table-auto{
    table-layout: auto !important;
  }

  .xl\:builder-table-fixed{
    table-layout: fixed !important;
  }

  .xl\:builder-border-collapse{
    border-collapse: collapse !important;
  }

  .xl\:builder-border-separate{
    border-collapse: separate !important;
  }

  .xl\:builder-origin-center{
    transform-origin: center !important;
  }

  .xl\:builder-origin-top{
    transform-origin: top !important;
  }

  .xl\:builder-origin-top-right{
    transform-origin: top right !important;
  }

  .xl\:builder-origin-right{
    transform-origin: right !important;
  }

  .xl\:builder-origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .xl\:builder-origin-bottom{
    transform-origin: bottom !important;
  }

  .xl\:builder-origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .xl\:builder-origin-left{
    transform-origin: left !important;
  }

  .xl\:builder-origin-top-left{
    transform-origin: top left !important;
  }

  .xl\:builder-transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .xl\:builder-transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .xl\:builder-transform-none{
    transform: none !important;
  }

  .xl\:builder-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .xl\:builder-translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .xl\:builder-translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .xl\:builder-translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .xl\:builder-translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .xl\:builder-translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .xl\:builder-translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .xl\:builder-translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .xl\:builder-translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .xl\:builder-translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .xl\:builder-translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .xl\:builder-translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .xl\:builder-translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .xl\:builder-translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .xl\:builder-translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .xl\:builder-translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .xl\:builder-translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .xl\:builder-translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .xl\:builder-translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .xl\:builder-translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .xl\:builder-translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .xl\:builder-translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .xl\:builder-translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .xl\:builder-translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .xl\:builder-translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .xl\:builder-translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .xl\:builder-translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .xl\:builder-translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .xl\:builder-translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .xl\:builder-translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .xl\:builder-translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .xl\:builder-translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .xl\:builder-translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .xl\:builder-translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .xl\:builder-translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .xl\:builder--translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .xl\:builder--translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .xl\:builder--translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .xl\:builder--translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .xl\:builder--translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .xl\:builder--translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .xl\:builder--translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .xl\:builder--translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .xl\:builder--translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .xl\:builder--translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .xl\:builder--translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .xl\:builder--translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .xl\:builder--translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .xl\:builder--translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .xl\:builder--translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .xl\:builder--translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .xl\:builder--translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .xl\:builder--translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .xl\:builder--translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .xl\:builder--translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .xl\:builder--translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .xl\:builder--translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .xl\:builder--translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .xl\:builder--translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .xl\:builder--translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .xl\:builder--translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .xl\:builder--translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .xl\:builder--translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .xl\:builder--translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .xl\:builder--translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .xl\:builder--translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .xl\:builder--translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .xl\:builder--translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .xl\:builder--translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .xl\:builder--translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .xl\:builder-translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .xl\:builder-translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .xl\:builder-translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .xl\:builder-translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .xl\:builder-translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .xl\:builder-translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .xl\:builder-translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .xl\:builder--translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .xl\:builder--translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .xl\:builder--translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .xl\:builder--translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .xl\:builder--translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .xl\:builder--translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .xl\:builder--translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .xl\:builder-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .xl\:builder-translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .xl\:builder-translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .xl\:builder-translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .xl\:builder-translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .xl\:builder-translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .xl\:builder-translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .xl\:builder-translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .xl\:builder-translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .xl\:builder-translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .xl\:builder-translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .xl\:builder-translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .xl\:builder-translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .xl\:builder-translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .xl\:builder-translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .xl\:builder-translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .xl\:builder-translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .xl\:builder-translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .xl\:builder-translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .xl\:builder-translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .xl\:builder-translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .xl\:builder-translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .xl\:builder-translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .xl\:builder-translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .xl\:builder-translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .xl\:builder-translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .xl\:builder-translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .xl\:builder-translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .xl\:builder-translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .xl\:builder-translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .xl\:builder-translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .xl\:builder-translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .xl\:builder-translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .xl\:builder-translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .xl\:builder-translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .xl\:builder--translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .xl\:builder--translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .xl\:builder--translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .xl\:builder--translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .xl\:builder--translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .xl\:builder--translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .xl\:builder--translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .xl\:builder--translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .xl\:builder--translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .xl\:builder--translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .xl\:builder--translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .xl\:builder--translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .xl\:builder--translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .xl\:builder--translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .xl\:builder--translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .xl\:builder--translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .xl\:builder--translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .xl\:builder--translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .xl\:builder--translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .xl\:builder--translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .xl\:builder--translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .xl\:builder--translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .xl\:builder--translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .xl\:builder--translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .xl\:builder--translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .xl\:builder--translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .xl\:builder--translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .xl\:builder--translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .xl\:builder--translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .xl\:builder--translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .xl\:builder--translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .xl\:builder--translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .xl\:builder--translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .xl\:builder--translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .xl\:builder--translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .xl\:builder-translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .xl\:builder-translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .xl\:builder-translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .xl\:builder-translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .xl\:builder-translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .xl\:builder-translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .xl\:builder-translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .xl\:builder--translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .xl\:builder--translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .xl\:builder--translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .xl\:builder--translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .xl\:builder--translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .xl\:builder--translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .xl\:builder--translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .xl\:hover\:builder-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .xl\:hover\:builder-translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .xl\:hover\:builder-translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .xl\:hover\:builder-translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .xl\:hover\:builder-translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .xl\:hover\:builder-translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .xl\:hover\:builder-translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .xl\:hover\:builder-translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .xl\:hover\:builder-translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .xl\:hover\:builder-translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .xl\:hover\:builder-translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .xl\:hover\:builder-translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .xl\:hover\:builder-translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .xl\:hover\:builder-translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .xl\:hover\:builder-translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .xl\:hover\:builder-translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .xl\:hover\:builder-translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .xl\:hover\:builder-translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .xl\:hover\:builder-translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .xl\:hover\:builder-translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .xl\:hover\:builder-translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .xl\:hover\:builder-translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .xl\:hover\:builder-translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .xl\:hover\:builder-translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .xl\:hover\:builder-translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .xl\:hover\:builder-translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .xl\:hover\:builder-translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .xl\:hover\:builder-translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .xl\:hover\:builder-translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .xl\:hover\:builder-translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .xl\:hover\:builder-translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .xl\:hover\:builder-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .xl\:hover\:builder-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .xl\:hover\:builder-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .xl\:hover\:builder-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .xl\:hover\:builder--translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .xl\:hover\:builder--translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .xl\:hover\:builder--translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .xl\:hover\:builder--translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .xl\:hover\:builder--translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .xl\:hover\:builder--translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .xl\:hover\:builder--translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .xl\:hover\:builder--translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .xl\:hover\:builder--translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .xl\:hover\:builder--translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .xl\:hover\:builder--translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .xl\:hover\:builder--translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .xl\:hover\:builder--translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .xl\:hover\:builder--translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .xl\:hover\:builder--translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .xl\:hover\:builder--translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .xl\:hover\:builder--translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .xl\:hover\:builder--translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .xl\:hover\:builder--translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .xl\:hover\:builder--translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .xl\:hover\:builder--translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .xl\:hover\:builder--translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .xl\:hover\:builder--translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .xl\:hover\:builder--translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .xl\:hover\:builder--translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .xl\:hover\:builder--translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .xl\:hover\:builder--translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .xl\:hover\:builder--translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .xl\:hover\:builder--translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .xl\:hover\:builder--translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .xl\:hover\:builder--translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .xl\:hover\:builder--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .xl\:hover\:builder--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .xl\:hover\:builder--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .xl\:hover\:builder--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .xl\:hover\:builder-translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .xl\:hover\:builder-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .xl\:hover\:builder-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .xl\:hover\:builder-translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .xl\:hover\:builder-translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .xl\:hover\:builder-translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .xl\:hover\:builder-translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .xl\:hover\:builder--translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .xl\:hover\:builder--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .xl\:hover\:builder--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .xl\:hover\:builder--translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .xl\:hover\:builder--translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .xl\:hover\:builder--translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .xl\:hover\:builder--translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .xl\:hover\:builder-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .xl\:hover\:builder-translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .xl\:hover\:builder-translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .xl\:hover\:builder-translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .xl\:hover\:builder-translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .xl\:hover\:builder-translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .xl\:hover\:builder-translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .xl\:hover\:builder-translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .xl\:hover\:builder-translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .xl\:hover\:builder-translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .xl\:hover\:builder-translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .xl\:hover\:builder-translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .xl\:hover\:builder-translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .xl\:hover\:builder-translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .xl\:hover\:builder-translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .xl\:hover\:builder-translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .xl\:hover\:builder-translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .xl\:hover\:builder-translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .xl\:hover\:builder-translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .xl\:hover\:builder-translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .xl\:hover\:builder-translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .xl\:hover\:builder-translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .xl\:hover\:builder-translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .xl\:hover\:builder-translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .xl\:hover\:builder-translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .xl\:hover\:builder-translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .xl\:hover\:builder-translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .xl\:hover\:builder-translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .xl\:hover\:builder-translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .xl\:hover\:builder-translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .xl\:hover\:builder-translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .xl\:hover\:builder-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .xl\:hover\:builder-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .xl\:hover\:builder-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .xl\:hover\:builder-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .xl\:hover\:builder--translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .xl\:hover\:builder--translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .xl\:hover\:builder--translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .xl\:hover\:builder--translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .xl\:hover\:builder--translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .xl\:hover\:builder--translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .xl\:hover\:builder--translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .xl\:hover\:builder--translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .xl\:hover\:builder--translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .xl\:hover\:builder--translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .xl\:hover\:builder--translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .xl\:hover\:builder--translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .xl\:hover\:builder--translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .xl\:hover\:builder--translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .xl\:hover\:builder--translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .xl\:hover\:builder--translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .xl\:hover\:builder--translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .xl\:hover\:builder--translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .xl\:hover\:builder--translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .xl\:hover\:builder--translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .xl\:hover\:builder--translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .xl\:hover\:builder--translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .xl\:hover\:builder--translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .xl\:hover\:builder--translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .xl\:hover\:builder--translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .xl\:hover\:builder--translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .xl\:hover\:builder--translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .xl\:hover\:builder--translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .xl\:hover\:builder--translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .xl\:hover\:builder--translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .xl\:hover\:builder--translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .xl\:hover\:builder--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .xl\:hover\:builder--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .xl\:hover\:builder--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .xl\:hover\:builder--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .xl\:hover\:builder-translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .xl\:hover\:builder-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .xl\:hover\:builder-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .xl\:hover\:builder-translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .xl\:hover\:builder-translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .xl\:hover\:builder-translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .xl\:hover\:builder-translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .xl\:hover\:builder--translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .xl\:hover\:builder--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .xl\:hover\:builder--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .xl\:hover\:builder--translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .xl\:hover\:builder--translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .xl\:hover\:builder--translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .xl\:hover\:builder--translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .xl\:focus\:builder-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .xl\:focus\:builder-translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .xl\:focus\:builder-translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .xl\:focus\:builder-translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .xl\:focus\:builder-translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .xl\:focus\:builder-translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .xl\:focus\:builder-translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .xl\:focus\:builder-translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .xl\:focus\:builder-translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .xl\:focus\:builder-translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .xl\:focus\:builder-translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .xl\:focus\:builder-translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .xl\:focus\:builder-translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .xl\:focus\:builder-translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .xl\:focus\:builder-translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .xl\:focus\:builder-translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .xl\:focus\:builder-translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .xl\:focus\:builder-translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .xl\:focus\:builder-translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .xl\:focus\:builder-translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .xl\:focus\:builder-translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .xl\:focus\:builder-translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .xl\:focus\:builder-translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .xl\:focus\:builder-translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .xl\:focus\:builder-translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .xl\:focus\:builder-translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .xl\:focus\:builder-translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .xl\:focus\:builder-translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .xl\:focus\:builder-translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .xl\:focus\:builder-translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .xl\:focus\:builder-translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .xl\:focus\:builder-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .xl\:focus\:builder-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .xl\:focus\:builder-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .xl\:focus\:builder-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .xl\:focus\:builder--translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .xl\:focus\:builder--translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .xl\:focus\:builder--translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .xl\:focus\:builder--translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .xl\:focus\:builder--translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .xl\:focus\:builder--translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .xl\:focus\:builder--translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .xl\:focus\:builder--translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .xl\:focus\:builder--translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .xl\:focus\:builder--translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .xl\:focus\:builder--translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .xl\:focus\:builder--translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .xl\:focus\:builder--translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .xl\:focus\:builder--translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .xl\:focus\:builder--translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .xl\:focus\:builder--translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .xl\:focus\:builder--translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .xl\:focus\:builder--translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .xl\:focus\:builder--translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .xl\:focus\:builder--translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .xl\:focus\:builder--translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .xl\:focus\:builder--translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .xl\:focus\:builder--translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .xl\:focus\:builder--translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .xl\:focus\:builder--translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .xl\:focus\:builder--translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .xl\:focus\:builder--translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .xl\:focus\:builder--translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .xl\:focus\:builder--translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .xl\:focus\:builder--translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .xl\:focus\:builder--translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .xl\:focus\:builder--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .xl\:focus\:builder--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .xl\:focus\:builder--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .xl\:focus\:builder--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .xl\:focus\:builder-translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .xl\:focus\:builder-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .xl\:focus\:builder-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .xl\:focus\:builder-translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .xl\:focus\:builder-translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .xl\:focus\:builder-translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .xl\:focus\:builder-translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .xl\:focus\:builder--translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .xl\:focus\:builder--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .xl\:focus\:builder--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .xl\:focus\:builder--translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .xl\:focus\:builder--translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .xl\:focus\:builder--translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .xl\:focus\:builder--translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .xl\:focus\:builder-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .xl\:focus\:builder-translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .xl\:focus\:builder-translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .xl\:focus\:builder-translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .xl\:focus\:builder-translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .xl\:focus\:builder-translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .xl\:focus\:builder-translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .xl\:focus\:builder-translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .xl\:focus\:builder-translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .xl\:focus\:builder-translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .xl\:focus\:builder-translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .xl\:focus\:builder-translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .xl\:focus\:builder-translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .xl\:focus\:builder-translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .xl\:focus\:builder-translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .xl\:focus\:builder-translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .xl\:focus\:builder-translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .xl\:focus\:builder-translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .xl\:focus\:builder-translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .xl\:focus\:builder-translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .xl\:focus\:builder-translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .xl\:focus\:builder-translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .xl\:focus\:builder-translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .xl\:focus\:builder-translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .xl\:focus\:builder-translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .xl\:focus\:builder-translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .xl\:focus\:builder-translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .xl\:focus\:builder-translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .xl\:focus\:builder-translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .xl\:focus\:builder-translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .xl\:focus\:builder-translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .xl\:focus\:builder-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .xl\:focus\:builder-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .xl\:focus\:builder-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .xl\:focus\:builder-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .xl\:focus\:builder--translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .xl\:focus\:builder--translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .xl\:focus\:builder--translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .xl\:focus\:builder--translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .xl\:focus\:builder--translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .xl\:focus\:builder--translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .xl\:focus\:builder--translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .xl\:focus\:builder--translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .xl\:focus\:builder--translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .xl\:focus\:builder--translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .xl\:focus\:builder--translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .xl\:focus\:builder--translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .xl\:focus\:builder--translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .xl\:focus\:builder--translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .xl\:focus\:builder--translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .xl\:focus\:builder--translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .xl\:focus\:builder--translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .xl\:focus\:builder--translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .xl\:focus\:builder--translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .xl\:focus\:builder--translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .xl\:focus\:builder--translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .xl\:focus\:builder--translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .xl\:focus\:builder--translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .xl\:focus\:builder--translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .xl\:focus\:builder--translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .xl\:focus\:builder--translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .xl\:focus\:builder--translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .xl\:focus\:builder--translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .xl\:focus\:builder--translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .xl\:focus\:builder--translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .xl\:focus\:builder--translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .xl\:focus\:builder--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .xl\:focus\:builder--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .xl\:focus\:builder--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .xl\:focus\:builder--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .xl\:focus\:builder-translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .xl\:focus\:builder-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .xl\:focus\:builder-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .xl\:focus\:builder-translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .xl\:focus\:builder-translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .xl\:focus\:builder-translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .xl\:focus\:builder-translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .xl\:focus\:builder--translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .xl\:focus\:builder--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .xl\:focus\:builder--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .xl\:focus\:builder--translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .xl\:focus\:builder--translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .xl\:focus\:builder--translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .xl\:focus\:builder--translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .xl\:builder-rotate-0{
    --tw-rotate: 0deg !important;
  }

  .xl\:builder-rotate-1{
    --tw-rotate: 1deg !important;
  }

  .xl\:builder-rotate-2{
    --tw-rotate: 2deg !important;
  }

  .xl\:builder-rotate-3{
    --tw-rotate: 3deg !important;
  }

  .xl\:builder-rotate-6{
    --tw-rotate: 6deg !important;
  }

  .xl\:builder-rotate-12{
    --tw-rotate: 12deg !important;
  }

  .xl\:builder-rotate-45{
    --tw-rotate: 45deg !important;
  }

  .xl\:builder-rotate-90{
    --tw-rotate: 90deg !important;
  }

  .xl\:builder-rotate-180{
    --tw-rotate: 180deg !important;
  }

  .xl\:builder--rotate-180{
    --tw-rotate: -180deg !important;
  }

  .xl\:builder--rotate-90{
    --tw-rotate: -90deg !important;
  }

  .xl\:builder--rotate-45{
    --tw-rotate: -45deg !important;
  }

  .xl\:builder--rotate-12{
    --tw-rotate: -12deg !important;
  }

  .xl\:builder--rotate-6{
    --tw-rotate: -6deg !important;
  }

  .xl\:builder--rotate-3{
    --tw-rotate: -3deg !important;
  }

  .xl\:builder--rotate-2{
    --tw-rotate: -2deg !important;
  }

  .xl\:builder--rotate-1{
    --tw-rotate: -1deg !important;
  }

  .xl\:hover\:builder-rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .xl\:hover\:builder-rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .xl\:hover\:builder-rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .xl\:hover\:builder-rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .xl\:hover\:builder-rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .xl\:hover\:builder-rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .xl\:hover\:builder-rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .xl\:hover\:builder-rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .xl\:hover\:builder-rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .xl\:hover\:builder--rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .xl\:hover\:builder--rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .xl\:hover\:builder--rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .xl\:hover\:builder--rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .xl\:hover\:builder--rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .xl\:hover\:builder--rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .xl\:hover\:builder--rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .xl\:hover\:builder--rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .xl\:focus\:builder-rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .xl\:focus\:builder-rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .xl\:focus\:builder-rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .xl\:focus\:builder-rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .xl\:focus\:builder-rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .xl\:focus\:builder-rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .xl\:focus\:builder-rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .xl\:focus\:builder-rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .xl\:focus\:builder-rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .xl\:focus\:builder--rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .xl\:focus\:builder--rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .xl\:focus\:builder--rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .xl\:focus\:builder--rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .xl\:focus\:builder--rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .xl\:focus\:builder--rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .xl\:focus\:builder--rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .xl\:focus\:builder--rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .xl\:builder-skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .xl\:builder-skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .xl\:builder-skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .xl\:builder-skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .xl\:builder-skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .xl\:builder-skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .xl\:builder--skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .xl\:builder--skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .xl\:builder--skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .xl\:builder--skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .xl\:builder--skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .xl\:builder-skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .xl\:builder-skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .xl\:builder-skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .xl\:builder-skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .xl\:builder-skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .xl\:builder-skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .xl\:builder--skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .xl\:builder--skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .xl\:builder--skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .xl\:builder--skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .xl\:builder--skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .xl\:hover\:builder-skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .xl\:hover\:builder-skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .xl\:hover\:builder-skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .xl\:hover\:builder-skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .xl\:hover\:builder-skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .xl\:hover\:builder-skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .xl\:hover\:builder--skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .xl\:hover\:builder--skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .xl\:hover\:builder--skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .xl\:hover\:builder--skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .xl\:hover\:builder--skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .xl\:hover\:builder-skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .xl\:hover\:builder-skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .xl\:hover\:builder-skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .xl\:hover\:builder-skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .xl\:hover\:builder-skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .xl\:hover\:builder-skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .xl\:hover\:builder--skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .xl\:hover\:builder--skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .xl\:hover\:builder--skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .xl\:hover\:builder--skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .xl\:hover\:builder--skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .xl\:focus\:builder-skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .xl\:focus\:builder-skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .xl\:focus\:builder-skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .xl\:focus\:builder-skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .xl\:focus\:builder-skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .xl\:focus\:builder-skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .xl\:focus\:builder--skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .xl\:focus\:builder--skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .xl\:focus\:builder--skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .xl\:focus\:builder--skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .xl\:focus\:builder--skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .xl\:focus\:builder-skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .xl\:focus\:builder-skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .xl\:focus\:builder-skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .xl\:focus\:builder-skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .xl\:focus\:builder-skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .xl\:focus\:builder-skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .xl\:focus\:builder--skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .xl\:focus\:builder--skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .xl\:focus\:builder--skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .xl\:focus\:builder--skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .xl\:focus\:builder--skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .xl\:builder-scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .xl\:builder-scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .xl\:builder-scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .xl\:builder-scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .xl\:builder-scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .xl\:builder-scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .xl\:builder-scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .xl\:builder-scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .xl\:builder-scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .xl\:builder-scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .xl\:hover\:builder-scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .xl\:hover\:builder-scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .xl\:hover\:builder-scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .xl\:hover\:builder-scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .xl\:hover\:builder-scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .xl\:hover\:builder-scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .xl\:hover\:builder-scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .xl\:hover\:builder-scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .xl\:hover\:builder-scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .xl\:hover\:builder-scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .xl\:focus\:builder-scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .xl\:focus\:builder-scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .xl\:focus\:builder-scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .xl\:focus\:builder-scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .xl\:focus\:builder-scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .xl\:focus\:builder-scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .xl\:focus\:builder-scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .xl\:focus\:builder-scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .xl\:focus\:builder-scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .xl\:focus\:builder-scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .xl\:builder-scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .xl\:builder-scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .xl\:builder-scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .xl\:builder-scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .xl\:builder-scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .xl\:builder-scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .xl\:builder-scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .xl\:builder-scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .xl\:builder-scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .xl\:builder-scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .xl\:builder-scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .xl\:builder-scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .xl\:builder-scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .xl\:builder-scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .xl\:builder-scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .xl\:builder-scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .xl\:builder-scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .xl\:builder-scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .xl\:builder-scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .xl\:builder-scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .xl\:hover\:builder-scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .xl\:hover\:builder-scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .xl\:hover\:builder-scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .xl\:hover\:builder-scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .xl\:hover\:builder-scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .xl\:hover\:builder-scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .xl\:hover\:builder-scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .xl\:hover\:builder-scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .xl\:hover\:builder-scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .xl\:hover\:builder-scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .xl\:hover\:builder-scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .xl\:hover\:builder-scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .xl\:hover\:builder-scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .xl\:hover\:builder-scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .xl\:hover\:builder-scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .xl\:hover\:builder-scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .xl\:hover\:builder-scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .xl\:hover\:builder-scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .xl\:hover\:builder-scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .xl\:hover\:builder-scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .xl\:focus\:builder-scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .xl\:focus\:builder-scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .xl\:focus\:builder-scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .xl\:focus\:builder-scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .xl\:focus\:builder-scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .xl\:focus\:builder-scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .xl\:focus\:builder-scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .xl\:focus\:builder-scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .xl\:focus\:builder-scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .xl\:focus\:builder-scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .xl\:focus\:builder-scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .xl\:focus\:builder-scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .xl\:focus\:builder-scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .xl\:focus\:builder-scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .xl\:focus\:builder-scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .xl\:focus\:builder-scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .xl\:focus\:builder-scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .xl\:focus\:builder-scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .xl\:focus\:builder-scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .xl\:focus\:builder-scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .xl\:builder-animate-none{
    animation: none !important;
  }

  .xl\:builder-animate-spin{
    animation: builder-spin 1s linear infinite !important;
  }

  .xl\:builder-animate-ping{
    animation: builder-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .xl\:builder-animate-pulse{
    animation: builder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .xl\:builder-animate-bounce{
    animation: builder-bounce 1s infinite !important;
  }

  .xl\:builder-cursor-auto{
    cursor: auto !important;
  }

  .xl\:builder-cursor-default{
    cursor: default !important;
  }

  .xl\:builder-cursor-pointer{
    cursor: pointer !important;
  }

  .xl\:builder-cursor-wait{
    cursor: wait !important;
  }

  .xl\:builder-cursor-text{
    cursor: text !important;
  }

  .xl\:builder-cursor-move{
    cursor: move !important;
  }

  .xl\:builder-cursor-help{
    cursor: help !important;
  }

  .xl\:builder-cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .xl\:builder-select-none{
    -webkit-user-select: none !important;
            user-select: none !important;
  }

  .xl\:builder-select-text{
    -webkit-user-select: text !important;
            user-select: text !important;
  }

  .xl\:builder-select-all{
    -webkit-user-select: all !important;
            user-select: all !important;
  }

  .xl\:builder-select-auto{
    -webkit-user-select: auto !important;
            user-select: auto !important;
  }

  .xl\:builder-resize-none{
    resize: none !important;
  }

  .xl\:builder-resize-y{
    resize: vertical !important;
  }

  .xl\:builder-resize-x{
    resize: horizontal !important;
  }

  .xl\:builder-resize{
    resize: both !important;
  }

  .xl\:builder-list-inside{
    list-style-position: inside !important;
  }

  .xl\:builder-list-outside{
    list-style-position: outside !important;
  }

  .xl\:builder-list-none{
    list-style-type: none !important;
  }

  .xl\:builder-list-disc{
    list-style-type: disc !important;
  }

  .xl\:builder-list-decimal{
    list-style-type: decimal !important;
  }

  .xl\:builder-appearance-none{
    appearance: none !important;
  }

  .xl\:builder-auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .xl\:builder-auto-cols-min{
    grid-auto-columns: min-content !important;
  }

  .xl\:builder-auto-cols-max{
    grid-auto-columns: max-content !important;
  }

  .xl\:builder-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .xl\:builder-grid-flow-row{
    grid-auto-flow: row !important;
  }

  .xl\:builder-grid-flow-col{
    grid-auto-flow: column !important;
  }

  .xl\:builder-grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .xl\:builder-grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .xl\:builder-auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .xl\:builder-auto-rows-min{
    grid-auto-rows: min-content !important;
  }

  .xl\:builder-auto-rows-max{
    grid-auto-rows: max-content !important;
  }

  .xl\:builder-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .xl\:builder-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-cols-none{
    grid-template-columns: none !important;
  }

  .xl\:builder-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:builder-grid-rows-none{
    grid-template-rows: none !important;
  }

  .xl\:builder-flex-row{
    flex-direction: row !important;
  }

  .xl\:builder-flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .xl\:builder-flex-col{
    flex-direction: column !important;
  }

  .xl\:builder-flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .xl\:builder-flex-wrap{
    flex-wrap: wrap !important;
  }

  .xl\:builder-flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .xl\:builder-flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .xl\:builder-place-content-center{
    place-content: center !important;
  }

  .xl\:builder-place-content-start{
    place-content: start !important;
  }

  .xl\:builder-place-content-end{
    place-content: end !important;
  }

  .xl\:builder-place-content-between{
    place-content: space-between !important;
  }

  .xl\:builder-place-content-around{
    place-content: space-around !important;
  }

  .xl\:builder-place-content-evenly{
    place-content: space-evenly !important;
  }

  .xl\:builder-place-content-stretch{
    place-content: stretch !important;
  }

  .xl\:builder-place-items-start{
    place-items: start !important;
  }

  .xl\:builder-place-items-end{
    place-items: end !important;
  }

  .xl\:builder-place-items-center{
    place-items: center !important;
  }

  .xl\:builder-place-items-stretch{
    place-items: stretch !important;
  }

  .xl\:builder-content-center{
    align-content: center !important;
  }

  .xl\:builder-content-start{
    align-content: flex-start !important;
  }

  .xl\:builder-content-end{
    align-content: flex-end !important;
  }

  .xl\:builder-content-between{
    align-content: space-between !important;
  }

  .xl\:builder-content-around{
    align-content: space-around !important;
  }

  .xl\:builder-content-evenly{
    align-content: space-evenly !important;
  }

  .xl\:builder-items-start{
    align-items: flex-start !important;
  }

  .xl\:builder-items-end{
    align-items: flex-end !important;
  }

  .xl\:builder-items-center{
    align-items: center !important;
  }

  .xl\:builder-items-baseline{
    align-items: baseline !important;
  }

  .xl\:builder-items-stretch{
    align-items: stretch !important;
  }

  .xl\:builder-justify-start{
    justify-content: flex-start !important;
  }

  .xl\:builder-justify-end{
    justify-content: flex-end !important;
  }

  .xl\:builder-justify-center{
    justify-content: center !important;
  }

  .xl\:builder-justify-between{
    justify-content: space-between !important;
  }

  .xl\:builder-justify-around{
    justify-content: space-around !important;
  }

  .xl\:builder-justify-evenly{
    justify-content: space-evenly !important;
  }

  .xl\:builder-justify-items-start{
    justify-items: start !important;
  }

  .xl\:builder-justify-items-end{
    justify-items: end !important;
  }

  .xl\:builder-justify-items-center{
    justify-items: center !important;
  }

  .xl\:builder-justify-items-stretch{
    justify-items: stretch !important;
  }

  .xl\:builder-gap-0{
    gap: 0px !important;
  }

  .xl\:builder-gap-1{
    gap: 0.25rem !important;
  }

  .xl\:builder-gap-2{
    gap: 0.5rem !important;
  }

  .xl\:builder-gap-3{
    gap: 0.75rem !important;
  }

  .xl\:builder-gap-4{
    gap: 1rem !important;
  }

  .xl\:builder-gap-5{
    gap: 1.25rem !important;
  }

  .xl\:builder-gap-6{
    gap: 1.5rem !important;
  }

  .xl\:builder-gap-7{
    gap: 1.75rem !important;
  }

  .xl\:builder-gap-8{
    gap: 2rem !important;
  }

  .xl\:builder-gap-9{
    gap: 2.25rem !important;
  }

  .xl\:builder-gap-10{
    gap: 2.5rem !important;
  }

  .xl\:builder-gap-11{
    gap: 2.75rem !important;
  }

  .xl\:builder-gap-12{
    gap: 3rem !important;
  }

  .xl\:builder-gap-14{
    gap: 3.5rem !important;
  }

  .xl\:builder-gap-16{
    gap: 4rem !important;
  }

  .xl\:builder-gap-20{
    gap: 5rem !important;
  }

  .xl\:builder-gap-24{
    gap: 6rem !important;
  }

  .xl\:builder-gap-28{
    gap: 7rem !important;
  }

  .xl\:builder-gap-32{
    gap: 8rem !important;
  }

  .xl\:builder-gap-36{
    gap: 9rem !important;
  }

  .xl\:builder-gap-40{
    gap: 10rem !important;
  }

  .xl\:builder-gap-44{
    gap: 11rem !important;
  }

  .xl\:builder-gap-48{
    gap: 12rem !important;
  }

  .xl\:builder-gap-52{
    gap: 13rem !important;
  }

  .xl\:builder-gap-56{
    gap: 14rem !important;
  }

  .xl\:builder-gap-60{
    gap: 15rem !important;
  }

  .xl\:builder-gap-64{
    gap: 16rem !important;
  }

  .xl\:builder-gap-72{
    gap: 18rem !important;
  }

  .xl\:builder-gap-80{
    gap: 20rem !important;
  }

  .xl\:builder-gap-96{
    gap: 24rem !important;
  }

  .xl\:builder-gap-px{
    gap: 1px !important;
  }

  .xl\:builder-gap-0\.5{
    gap: 0.125rem !important;
  }

  .xl\:builder-gap-1\.5{
    gap: 0.375rem !important;
  }

  .xl\:builder-gap-2\.5{
    gap: 0.625rem !important;
  }

  .xl\:builder-gap-3\.5{
    gap: 0.875rem !important;
  }

  .xl\:builder-gap-x-0{
    column-gap: 0px !important;
  }

  .xl\:builder-gap-x-1{
    column-gap: 0.25rem !important;
  }

  .xl\:builder-gap-x-2{
    column-gap: 0.5rem !important;
  }

  .xl\:builder-gap-x-3{
    column-gap: 0.75rem !important;
  }

  .xl\:builder-gap-x-4{
    column-gap: 1rem !important;
  }

  .xl\:builder-gap-x-5{
    column-gap: 1.25rem !important;
  }

  .xl\:builder-gap-x-6{
    column-gap: 1.5rem !important;
  }

  .xl\:builder-gap-x-7{
    column-gap: 1.75rem !important;
  }

  .xl\:builder-gap-x-8{
    column-gap: 2rem !important;
  }

  .xl\:builder-gap-x-9{
    column-gap: 2.25rem !important;
  }

  .xl\:builder-gap-x-10{
    column-gap: 2.5rem !important;
  }

  .xl\:builder-gap-x-11{
    column-gap: 2.75rem !important;
  }

  .xl\:builder-gap-x-12{
    column-gap: 3rem !important;
  }

  .xl\:builder-gap-x-14{
    column-gap: 3.5rem !important;
  }

  .xl\:builder-gap-x-16{
    column-gap: 4rem !important;
  }

  .xl\:builder-gap-x-20{
    column-gap: 5rem !important;
  }

  .xl\:builder-gap-x-24{
    column-gap: 6rem !important;
  }

  .xl\:builder-gap-x-28{
    column-gap: 7rem !important;
  }

  .xl\:builder-gap-x-32{
    column-gap: 8rem !important;
  }

  .xl\:builder-gap-x-36{
    column-gap: 9rem !important;
  }

  .xl\:builder-gap-x-40{
    column-gap: 10rem !important;
  }

  .xl\:builder-gap-x-44{
    column-gap: 11rem !important;
  }

  .xl\:builder-gap-x-48{
    column-gap: 12rem !important;
  }

  .xl\:builder-gap-x-52{
    column-gap: 13rem !important;
  }

  .xl\:builder-gap-x-56{
    column-gap: 14rem !important;
  }

  .xl\:builder-gap-x-60{
    column-gap: 15rem !important;
  }

  .xl\:builder-gap-x-64{
    column-gap: 16rem !important;
  }

  .xl\:builder-gap-x-72{
    column-gap: 18rem !important;
  }

  .xl\:builder-gap-x-80{
    column-gap: 20rem !important;
  }

  .xl\:builder-gap-x-96{
    column-gap: 24rem !important;
  }

  .xl\:builder-gap-x-px{
    column-gap: 1px !important;
  }

  .xl\:builder-gap-x-0\.5{
    column-gap: 0.125rem !important;
  }

  .xl\:builder-gap-x-1\.5{
    column-gap: 0.375rem !important;
  }

  .xl\:builder-gap-x-2\.5{
    column-gap: 0.625rem !important;
  }

  .xl\:builder-gap-x-3\.5{
    column-gap: 0.875rem !important;
  }

  .xl\:builder-gap-y-0{
    row-gap: 0px !important;
  }

  .xl\:builder-gap-y-1{
    row-gap: 0.25rem !important;
  }

  .xl\:builder-gap-y-2{
    row-gap: 0.5rem !important;
  }

  .xl\:builder-gap-y-3{
    row-gap: 0.75rem !important;
  }

  .xl\:builder-gap-y-4{
    row-gap: 1rem !important;
  }

  .xl\:builder-gap-y-5{
    row-gap: 1.25rem !important;
  }

  .xl\:builder-gap-y-6{
    row-gap: 1.5rem !important;
  }

  .xl\:builder-gap-y-7{
    row-gap: 1.75rem !important;
  }

  .xl\:builder-gap-y-8{
    row-gap: 2rem !important;
  }

  .xl\:builder-gap-y-9{
    row-gap: 2.25rem !important;
  }

  .xl\:builder-gap-y-10{
    row-gap: 2.5rem !important;
  }

  .xl\:builder-gap-y-11{
    row-gap: 2.75rem !important;
  }

  .xl\:builder-gap-y-12{
    row-gap: 3rem !important;
  }

  .xl\:builder-gap-y-14{
    row-gap: 3.5rem !important;
  }

  .xl\:builder-gap-y-16{
    row-gap: 4rem !important;
  }

  .xl\:builder-gap-y-20{
    row-gap: 5rem !important;
  }

  .xl\:builder-gap-y-24{
    row-gap: 6rem !important;
  }

  .xl\:builder-gap-y-28{
    row-gap: 7rem !important;
  }

  .xl\:builder-gap-y-32{
    row-gap: 8rem !important;
  }

  .xl\:builder-gap-y-36{
    row-gap: 9rem !important;
  }

  .xl\:builder-gap-y-40{
    row-gap: 10rem !important;
  }

  .xl\:builder-gap-y-44{
    row-gap: 11rem !important;
  }

  .xl\:builder-gap-y-48{
    row-gap: 12rem !important;
  }

  .xl\:builder-gap-y-52{
    row-gap: 13rem !important;
  }

  .xl\:builder-gap-y-56{
    row-gap: 14rem !important;
  }

  .xl\:builder-gap-y-60{
    row-gap: 15rem !important;
  }

  .xl\:builder-gap-y-64{
    row-gap: 16rem !important;
  }

  .xl\:builder-gap-y-72{
    row-gap: 18rem !important;
  }

  .xl\:builder-gap-y-80{
    row-gap: 20rem !important;
  }

  .xl\:builder-gap-y-96{
    row-gap: 24rem !important;
  }

  .xl\:builder-gap-y-px{
    row-gap: 1px !important;
  }

  .xl\:builder-gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .xl\:builder-gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .xl\:builder-gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .xl\:builder-gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .xl\:builder-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:builder-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:builder-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .xl\:builder-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .xl\:builder-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:builder-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:builder-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:builder-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:builder-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:builder-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:builder-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:builder-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:builder-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:builder-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:builder-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .xl\:builder-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .xl\:builder-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .xl\:builder-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .xl\:builder-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .xl\:builder-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .xl\:builder-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .xl\:builder-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .xl\:builder-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .xl\:builder-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-primary > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-secondary-dark > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-secondary-darker > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity)) !important;
  }

  .xl\:builder-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .xl\:builder-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .xl\:builder-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .xl\:builder-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .xl\:builder-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .xl\:builder-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .xl\:builder-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .xl\:builder-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .xl\:builder-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .xl\:builder-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .xl\:builder-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .xl\:builder-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .xl\:builder-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .xl\:builder-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .xl\:builder-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .xl\:builder-place-self-auto{
    place-self: auto !important;
  }

  .xl\:builder-place-self-start{
    place-self: start !important;
  }

  .xl\:builder-place-self-end{
    place-self: end !important;
  }

  .xl\:builder-place-self-center{
    place-self: center !important;
  }

  .xl\:builder-place-self-stretch{
    place-self: stretch !important;
  }

  .xl\:builder-self-auto{
    align-self: auto !important;
  }

  .xl\:builder-self-start{
    align-self: flex-start !important;
  }

  .xl\:builder-self-end{
    align-self: flex-end !important;
  }

  .xl\:builder-self-center{
    align-self: center !important;
  }

  .xl\:builder-self-stretch{
    align-self: stretch !important;
  }

  .xl\:builder-self-baseline{
    align-self: baseline !important;
  }

  .xl\:builder-justify-self-auto{
    justify-self: auto !important;
  }

  .xl\:builder-justify-self-start{
    justify-self: start !important;
  }

  .xl\:builder-justify-self-end{
    justify-self: end !important;
  }

  .xl\:builder-justify-self-center{
    justify-self: center !important;
  }

  .xl\:builder-justify-self-stretch{
    justify-self: stretch !important;
  }

  .xl\:builder-overflow-auto{
    overflow: auto !important;
  }

  .xl\:builder-overflow-hidden{
    overflow: hidden !important;
  }

  .xl\:builder-overflow-visible{
    overflow: visible !important;
  }

  .xl\:builder-overflow-scroll{
    overflow: scroll !important;
  }

  .xl\:builder-overflow-x-auto{
    overflow-x: auto !important;
  }

  .xl\:builder-overflow-y-auto{
    overflow-y: auto !important;
  }

  .xl\:builder-overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .xl\:builder-overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .xl\:builder-overflow-x-visible{
    overflow-x: visible !important;
  }

  .xl\:builder-overflow-y-visible{
    overflow-y: visible !important;
  }

  .xl\:builder-overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .xl\:builder-overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .xl\:builder-overscroll-auto{
    overscroll-behavior: auto !important;
  }

  .xl\:builder-overscroll-contain{
    overscroll-behavior: contain !important;
  }

  .xl\:builder-overscroll-none{
    overscroll-behavior: none !important;
  }

  .xl\:builder-overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .xl\:builder-overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .xl\:builder-overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .xl\:builder-overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .xl\:builder-overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .xl\:builder-overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .xl\:builder-truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xl\:builder-overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .xl\:builder-overflow-clip{
    text-overflow: clip !important;
  }

  .xl\:builder-whitespace-normal{
    white-space: normal !important;
  }

  .xl\:builder-whitespace-nowrap{
    white-space: nowrap !important;
  }

  .xl\:builder-whitespace-pre{
    white-space: pre !important;
  }

  .xl\:builder-whitespace-pre-line{
    white-space: pre-line !important;
  }

  .xl\:builder-whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .xl\:builder-break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xl\:builder-break-words{
    overflow-wrap: break-word !important;
  }

  .xl\:builder-break-all{
    word-break: break-all !important;
  }

  .xl\:builder-rounded-none{
    border-radius: 0px !important;
  }

  .xl\:builder-rounded-sm{
    border-radius: 0.125rem !important;
  }

  .xl\:builder-rounded{
    border-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-md{
    border-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-lg{
    border-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-xl{
    border-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-2xl{
    border-radius: 1rem !important;
  }

  .xl\:builder-rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-full{
    border-radius: 9999px !important;
  }

  .xl\:builder-rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .xl\:builder-rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xl\:builder-rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xl\:builder-rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .xl\:builder-rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:builder-rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:builder-rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .xl\:builder-rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:builder-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:builder-rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .xl\:builder-rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:builder-rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:builder-rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .xl\:builder-rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .xl\:builder-rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .xl\:builder-rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .xl\:builder-rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .xl\:builder-rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .xl\:builder-rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .xl\:builder-rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:builder-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:builder-rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .xl\:builder-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:builder-rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:builder-rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:builder-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:builder-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:builder-rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:builder-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:builder-rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:builder-border-0{
    border-width: 0px !important;
  }

  .xl\:builder-border-2{
    border-width: 2px !important;
  }

  .xl\:builder-border-4{
    border-width: 4px !important;
  }

  .xl\:builder-border-8{
    border-width: 8px !important;
  }

  .xl\:builder-border{
    border-width: 1px !important;
  }

  .xl\:builder-border-t-0{
    border-top-width: 0px !important;
  }

  .xl\:builder-border-t-2{
    border-top-width: 2px !important;
  }

  .xl\:builder-border-t-4{
    border-top-width: 4px !important;
  }

  .xl\:builder-border-t-8{
    border-top-width: 8px !important;
  }

  .xl\:builder-border-t{
    border-top-width: 1px !important;
  }

  .xl\:builder-border-r-0{
    border-right-width: 0px !important;
  }

  .xl\:builder-border-r-2{
    border-right-width: 2px !important;
  }

  .xl\:builder-border-r-4{
    border-right-width: 4px !important;
  }

  .xl\:builder-border-r-8{
    border-right-width: 8px !important;
  }

  .xl\:builder-border-r{
    border-right-width: 1px !important;
  }

  .xl\:builder-border-b-0{
    border-bottom-width: 0px !important;
  }

  .xl\:builder-border-b-2{
    border-bottom-width: 2px !important;
  }

  .xl\:builder-border-b-4{
    border-bottom-width: 4px !important;
  }

  .xl\:builder-border-b-8{
    border-bottom-width: 8px !important;
  }

  .xl\:builder-border-b{
    border-bottom-width: 1px !important;
  }

  .xl\:builder-border-l-0{
    border-left-width: 0px !important;
  }

  .xl\:builder-border-l-2{
    border-left-width: 2px !important;
  }

  .xl\:builder-border-l-4{
    border-left-width: 4px !important;
  }

  .xl\:builder-border-l-8{
    border-left-width: 8px !important;
  }

  .xl\:builder-border-l{
    border-left-width: 1px !important;
  }

  .xl\:builder-border-solid{
    border-style: solid !important;
  }

  .xl\:builder-border-dashed{
    border-style: dashed !important;
  }

  .xl\:builder-border-dotted{
    border-style: dotted !important;
  }

  .xl\:builder-border-double{
    border-style: double !important;
  }

  .xl\:builder-border-none{
    border-style: none !important;
  }

  .xl\:builder-border-transparent{
    border-color: transparent !important;
  }

  .xl\:builder-border-current{
    border-color: currentColor !important;
  }

  .xl\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-transparent{
    border-color: transparent !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-current{
    border-color: currentColor !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-transparent:focus-within{
    border-color: transparent !important;
  }

  .xl\:focus-within\:builder-border-current:focus-within{
    border-color: currentColor !important;
  }

  .xl\:focus-within\:builder-border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-primary:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-secondary-dark:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:builder-border-secondary-darker:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-transparent:hover{
    border-color: transparent !important;
  }

  .xl\:hover\:builder-border-current:hover{
    border-color: currentColor !important;
  }

  .xl\:hover\:builder-border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-primary:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-secondary-dark:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:builder-border-secondary-darker:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-transparent:focus{
    border-color: transparent !important;
  }

  .xl\:focus\:builder-border-current:focus{
    border-color: currentColor !important;
  }

  .xl\:focus\:builder-border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-primary:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-secondary-dark:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:builder-border-secondary-darker:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .xl\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .xl\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .xl\:focus-within\:builder-border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .xl\:focus-within\:builder-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:focus-within\:builder-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:focus-within\:builder-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:focus-within\:builder-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:focus-within\:builder-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:focus-within\:builder-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:focus-within\:builder-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:focus-within\:builder-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:focus-within\:builder-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:focus-within\:builder-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:focus-within\:builder-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:focus-within\:builder-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:focus-within\:builder-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:focus-within\:builder-border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .xl\:hover\:builder-border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .xl\:hover\:builder-border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:hover\:builder-border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:hover\:builder-border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:hover\:builder-border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:hover\:builder-border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:hover\:builder-border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:hover\:builder-border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:hover\:builder-border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:hover\:builder-border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:hover\:builder-border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:hover\:builder-border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:hover\:builder-border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:hover\:builder-border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:hover\:builder-border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .xl\:focus\:builder-border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .xl\:focus\:builder-border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:focus\:builder-border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:focus\:builder-border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:focus\:builder-border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:focus\:builder-border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:focus\:builder-border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:focus\:builder-border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:focus\:builder-border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:focus\:builder-border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:focus\:builder-border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:focus\:builder-border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:focus\:builder-border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:focus\:builder-border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:focus\:builder-border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .xl\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .xl\:builder-bg-current{
    background-color: currentColor !important;
  }

  .xl\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-current{
    background-color: currentColor !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .xl\:focus-within\:builder-bg-current:focus-within{
    background-color: currentColor !important;
  }

  .xl\:focus-within\:builder-bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-primary:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-secondary-dark:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:builder-bg-secondary-darker:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-transparent:hover{
    background-color: transparent !important;
  }

  .xl\:hover\:builder-bg-current:hover{
    background-color: currentColor !important;
  }

  .xl\:hover\:builder-bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-primary:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-secondary-dark:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:builder-bg-secondary-darker:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-transparent:focus{
    background-color: transparent !important;
  }

  .xl\:focus\:builder-bg-current:focus{
    background-color: currentColor !important;
  }

  .xl\:focus\:builder-bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-primary:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-secondary-dark:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:builder-bg-secondary-darker:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .xl\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:focus-within\:builder-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:hover\:builder-bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:hover\:builder-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:hover\:builder-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:hover\:builder-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:hover\:builder-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:hover\:builder-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:hover\:builder-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:hover\:builder-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:hover\:builder-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:hover\:builder-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:hover\:builder-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:hover\:builder-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:hover\:builder-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:hover\:builder-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:hover\:builder-bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:focus\:builder-bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:focus\:builder-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:focus\:builder-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:focus\:builder-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:focus\:builder-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:focus\:builder-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:focus\:builder-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:focus\:builder-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:focus\:builder-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:focus\:builder-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:focus\:builder-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:focus\:builder-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:focus\:builder-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:focus\:builder-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:focus\:builder-bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:builder-bg-none{
    background-image: none !important;
  }

  .xl\:builder-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .xl\:builder-from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:builder-from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:builder-from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:builder-from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:builder-from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:builder-from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:builder-from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:builder-from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:builder-from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:builder-from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:builder-from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:builder-from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:builder-from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:builder-from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:builder-from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:builder-from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:builder-from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:builder-from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:builder-from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:builder-from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:builder-from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:builder-from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:builder-from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:builder-from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:builder-from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:builder-from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:builder-from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:builder-from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:builder-from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:builder-from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:builder-from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:builder-from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:builder-from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:builder-from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:builder-from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:builder-from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:builder-from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:builder-from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:builder-from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:builder-from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:builder-from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:builder-from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:builder-from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:builder-from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:builder-from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:builder-from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:builder-from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:builder-from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:builder-from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:builder-from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:builder-from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:builder-from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:builder-from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:builder-from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:builder-from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:builder-from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:builder-from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:builder-from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:builder-from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:builder-from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:builder-from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:builder-from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:builder-from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:builder-from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:builder-from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:builder-from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:builder-from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:builder-from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:builder-from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:builder-from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:builder-from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:builder-from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:builder-from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:builder-from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:builder-from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:builder-from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:builder-from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:builder-from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:builder-from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:builder-from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:builder-from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:builder-from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:builder-from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:builder-from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:builder-from-primary{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .xl\:builder-from-secondary-dark{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .xl\:builder-from-secondary-darker{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .xl\:hover\:builder-from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:builder-from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:builder-from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:builder-from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:hover\:builder-from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:hover\:builder-from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:hover\:builder-from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:hover\:builder-from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:hover\:builder-from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:hover\:builder-from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:hover\:builder-from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:hover\:builder-from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:hover\:builder-from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:hover\:builder-from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:hover\:builder-from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:hover\:builder-from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:hover\:builder-from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:hover\:builder-from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:hover\:builder-from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:hover\:builder-from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:hover\:builder-from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:hover\:builder-from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:hover\:builder-from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:hover\:builder-from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:hover\:builder-from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:hover\:builder-from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:hover\:builder-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:hover\:builder-from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:hover\:builder-from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:hover\:builder-from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:hover\:builder-from-primary:hover{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .xl\:hover\:builder-from-secondary-dark:hover{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .xl\:hover\:builder-from-secondary-darker:hover{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .xl\:focus\:builder-from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:builder-from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:builder-from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:builder-from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:focus\:builder-from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:focus\:builder-from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:focus\:builder-from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:focus\:builder-from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:focus\:builder-from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:focus\:builder-from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:focus\:builder-from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:focus\:builder-from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:focus\:builder-from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:focus\:builder-from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:focus\:builder-from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:focus\:builder-from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:focus\:builder-from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:focus\:builder-from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:focus\:builder-from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:focus\:builder-from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:focus\:builder-from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:focus\:builder-from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:focus\:builder-from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:focus\:builder-from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:focus\:builder-from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:focus\:builder-from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:focus\:builder-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:focus\:builder-from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:focus\:builder-from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:focus\:builder-from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:focus\:builder-from-primary:focus{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .xl\:focus\:builder-from-secondary-dark:focus{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .xl\:focus\:builder-from-secondary-darker:focus{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .xl\:builder-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:builder-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:builder-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:builder-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:builder-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:builder-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:builder-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:builder-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:builder-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:builder-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:builder-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:builder-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:builder-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:builder-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:builder-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:builder-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:builder-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:builder-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:builder-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:builder-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:builder-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:builder-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:builder-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:builder-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:builder-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:builder-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:builder-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:builder-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:builder-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:builder-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:builder-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:builder-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:builder-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:builder-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:builder-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:builder-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:builder-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:builder-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:builder-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:builder-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:builder-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:builder-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:builder-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:builder-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:builder-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:builder-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:builder-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:builder-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:builder-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:builder-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:builder-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:builder-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:builder-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:builder-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:builder-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:builder-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:builder-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:builder-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:builder-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:builder-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:builder-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:builder-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:builder-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:builder-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:builder-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:builder-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:builder-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:builder-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:builder-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:builder-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:builder-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:builder-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:builder-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:builder-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:builder-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:builder-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:builder-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:builder-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:builder-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:builder-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:builder-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:builder-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:builder-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:builder-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:builder-via-primary{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .xl\:builder-via-secondary-dark{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .xl\:builder-via-secondary-darker{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .xl\:hover\:builder-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:builder-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:builder-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:builder-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:hover\:builder-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:hover\:builder-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:hover\:builder-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:hover\:builder-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:hover\:builder-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:hover\:builder-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:hover\:builder-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:hover\:builder-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:hover\:builder-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:hover\:builder-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:hover\:builder-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:hover\:builder-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:hover\:builder-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:hover\:builder-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:hover\:builder-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:hover\:builder-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:hover\:builder-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:hover\:builder-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:hover\:builder-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:hover\:builder-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:hover\:builder-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:hover\:builder-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:hover\:builder-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:hover\:builder-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:hover\:builder-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:hover\:builder-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:hover\:builder-via-primary:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .xl\:hover\:builder-via-secondary-dark:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .xl\:hover\:builder-via-secondary-darker:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .xl\:focus\:builder-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:builder-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:builder-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:builder-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:focus\:builder-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:focus\:builder-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:focus\:builder-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:focus\:builder-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:focus\:builder-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:focus\:builder-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:focus\:builder-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:focus\:builder-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:focus\:builder-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:focus\:builder-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:focus\:builder-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:focus\:builder-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:focus\:builder-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:focus\:builder-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:focus\:builder-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:focus\:builder-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:focus\:builder-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:focus\:builder-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:focus\:builder-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:focus\:builder-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:focus\:builder-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:focus\:builder-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:focus\:builder-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:focus\:builder-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:focus\:builder-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:focus\:builder-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:focus\:builder-via-primary:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .xl\:focus\:builder-via-secondary-dark:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .xl\:focus\:builder-via-secondary-darker:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .xl\:builder-to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .xl\:builder-to-current{
    --tw-gradient-to: currentColor !important;
  }

  .xl\:builder-to-black{
    --tw-gradient-to: #000 !important;
  }

  .xl\:builder-to-white{
    --tw-gradient-to: #fff !important;
  }

  .xl\:builder-to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .xl\:builder-to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .xl\:builder-to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .xl\:builder-to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .xl\:builder-to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .xl\:builder-to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .xl\:builder-to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .xl\:builder-to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .xl\:builder-to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .xl\:builder-to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .xl\:builder-to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .xl\:builder-to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .xl\:builder-to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .xl\:builder-to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .xl\:builder-to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .xl\:builder-to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .xl\:builder-to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .xl\:builder-to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .xl\:builder-to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .xl\:builder-to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .xl\:builder-to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .xl\:builder-to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .xl\:builder-to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .xl\:builder-to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .xl\:builder-to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .xl\:builder-to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .xl\:builder-to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .xl\:builder-to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .xl\:builder-to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .xl\:builder-to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .xl\:builder-to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .xl\:builder-to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .xl\:builder-to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .xl\:builder-to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .xl\:builder-to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .xl\:builder-to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .xl\:builder-to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .xl\:builder-to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .xl\:builder-to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .xl\:builder-to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .xl\:builder-to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .xl\:builder-to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .xl\:builder-to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .xl\:builder-to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .xl\:builder-to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .xl\:builder-to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .xl\:builder-to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .xl\:builder-to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .xl\:builder-to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .xl\:builder-to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .xl\:builder-to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .xl\:builder-to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .xl\:builder-to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .xl\:builder-to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .xl\:builder-to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .xl\:builder-to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .xl\:builder-to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .xl\:builder-to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .xl\:builder-to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .xl\:builder-to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .xl\:builder-to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .xl\:builder-to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .xl\:builder-to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .xl\:builder-to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .xl\:builder-to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .xl\:builder-to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .xl\:builder-to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .xl\:builder-to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .xl\:builder-to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .xl\:builder-to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .xl\:builder-to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .xl\:builder-to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .xl\:builder-to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .xl\:builder-to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .xl\:builder-to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .xl\:builder-to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .xl\:builder-to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .xl\:builder-to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .xl\:builder-to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .xl\:builder-to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .xl\:builder-to-primary{
    --tw-gradient-to: #2d375a !important;
  }

  .xl\:builder-to-secondary-dark{
    --tw-gradient-to: #343434 !important;
  }

  .xl\:builder-to-secondary-darker{
    --tw-gradient-to: #212121 !important;
  }

  .xl\:hover\:builder-to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .xl\:hover\:builder-to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .xl\:hover\:builder-to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .xl\:hover\:builder-to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .xl\:hover\:builder-to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .xl\:hover\:builder-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .xl\:hover\:builder-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .xl\:hover\:builder-to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .xl\:hover\:builder-to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .xl\:hover\:builder-to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .xl\:hover\:builder-to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .xl\:hover\:builder-to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .xl\:hover\:builder-to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .xl\:hover\:builder-to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .xl\:hover\:builder-to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .xl\:hover\:builder-to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .xl\:hover\:builder-to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .xl\:hover\:builder-to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .xl\:hover\:builder-to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .xl\:hover\:builder-to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .xl\:hover\:builder-to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .xl\:hover\:builder-to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .xl\:hover\:builder-to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .xl\:hover\:builder-to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .xl\:hover\:builder-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .xl\:hover\:builder-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .xl\:hover\:builder-to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .xl\:hover\:builder-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .xl\:hover\:builder-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .xl\:hover\:builder-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .xl\:hover\:builder-to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .xl\:hover\:builder-to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .xl\:hover\:builder-to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .xl\:hover\:builder-to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .xl\:hover\:builder-to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .xl\:hover\:builder-to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .xl\:hover\:builder-to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .xl\:hover\:builder-to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .xl\:hover\:builder-to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .xl\:hover\:builder-to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .xl\:hover\:builder-to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .xl\:hover\:builder-to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .xl\:hover\:builder-to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .xl\:hover\:builder-to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .xl\:hover\:builder-to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .xl\:hover\:builder-to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .xl\:hover\:builder-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .xl\:hover\:builder-to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .xl\:hover\:builder-to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .xl\:hover\:builder-to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .xl\:hover\:builder-to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .xl\:hover\:builder-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .xl\:hover\:builder-to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .xl\:hover\:builder-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .xl\:hover\:builder-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .xl\:hover\:builder-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .xl\:hover\:builder-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .xl\:hover\:builder-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .xl\:hover\:builder-to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .xl\:hover\:builder-to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .xl\:hover\:builder-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .xl\:hover\:builder-to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .xl\:hover\:builder-to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .xl\:hover\:builder-to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .xl\:hover\:builder-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .xl\:hover\:builder-to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .xl\:hover\:builder-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .xl\:hover\:builder-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .xl\:hover\:builder-to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .xl\:hover\:builder-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .xl\:hover\:builder-to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .xl\:hover\:builder-to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .xl\:hover\:builder-to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .xl\:hover\:builder-to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .xl\:hover\:builder-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .xl\:hover\:builder-to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .xl\:hover\:builder-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .xl\:hover\:builder-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .xl\:hover\:builder-to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .xl\:hover\:builder-to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .xl\:hover\:builder-to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .xl\:hover\:builder-to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .xl\:hover\:builder-to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .xl\:hover\:builder-to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .xl\:hover\:builder-to-primary:hover{
    --tw-gradient-to: #2d375a !important;
  }

  .xl\:hover\:builder-to-secondary-dark:hover{
    --tw-gradient-to: #343434 !important;
  }

  .xl\:hover\:builder-to-secondary-darker:hover{
    --tw-gradient-to: #212121 !important;
  }

  .xl\:focus\:builder-to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .xl\:focus\:builder-to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .xl\:focus\:builder-to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .xl\:focus\:builder-to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .xl\:focus\:builder-to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .xl\:focus\:builder-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .xl\:focus\:builder-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .xl\:focus\:builder-to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .xl\:focus\:builder-to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .xl\:focus\:builder-to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .xl\:focus\:builder-to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .xl\:focus\:builder-to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .xl\:focus\:builder-to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .xl\:focus\:builder-to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .xl\:focus\:builder-to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .xl\:focus\:builder-to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .xl\:focus\:builder-to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .xl\:focus\:builder-to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .xl\:focus\:builder-to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .xl\:focus\:builder-to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .xl\:focus\:builder-to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .xl\:focus\:builder-to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .xl\:focus\:builder-to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .xl\:focus\:builder-to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .xl\:focus\:builder-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .xl\:focus\:builder-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .xl\:focus\:builder-to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .xl\:focus\:builder-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .xl\:focus\:builder-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .xl\:focus\:builder-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .xl\:focus\:builder-to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .xl\:focus\:builder-to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .xl\:focus\:builder-to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .xl\:focus\:builder-to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .xl\:focus\:builder-to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .xl\:focus\:builder-to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .xl\:focus\:builder-to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .xl\:focus\:builder-to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .xl\:focus\:builder-to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .xl\:focus\:builder-to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .xl\:focus\:builder-to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .xl\:focus\:builder-to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .xl\:focus\:builder-to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .xl\:focus\:builder-to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .xl\:focus\:builder-to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .xl\:focus\:builder-to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .xl\:focus\:builder-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .xl\:focus\:builder-to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .xl\:focus\:builder-to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .xl\:focus\:builder-to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .xl\:focus\:builder-to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .xl\:focus\:builder-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .xl\:focus\:builder-to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .xl\:focus\:builder-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .xl\:focus\:builder-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .xl\:focus\:builder-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .xl\:focus\:builder-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .xl\:focus\:builder-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .xl\:focus\:builder-to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .xl\:focus\:builder-to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .xl\:focus\:builder-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .xl\:focus\:builder-to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .xl\:focus\:builder-to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .xl\:focus\:builder-to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .xl\:focus\:builder-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .xl\:focus\:builder-to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .xl\:focus\:builder-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .xl\:focus\:builder-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .xl\:focus\:builder-to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .xl\:focus\:builder-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .xl\:focus\:builder-to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .xl\:focus\:builder-to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .xl\:focus\:builder-to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .xl\:focus\:builder-to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .xl\:focus\:builder-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .xl\:focus\:builder-to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .xl\:focus\:builder-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .xl\:focus\:builder-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .xl\:focus\:builder-to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .xl\:focus\:builder-to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .xl\:focus\:builder-to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .xl\:focus\:builder-to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .xl\:focus\:builder-to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .xl\:focus\:builder-to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .xl\:focus\:builder-to-primary:focus{
    --tw-gradient-to: #2d375a !important;
  }

  .xl\:focus\:builder-to-secondary-dark:focus{
    --tw-gradient-to: #343434 !important;
  }

  .xl\:focus\:builder-to-secondary-darker:focus{
    --tw-gradient-to: #212121 !important;
  }

  .xl\:builder-decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .xl\:builder-decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .xl\:builder-bg-auto{
    background-size: auto !important;
  }

  .xl\:builder-bg-cover{
    background-size: cover !important;
  }

  .xl\:builder-bg-contain{
    background-size: contain !important;
  }

  .xl\:builder-bg-fixed{
    background-attachment: fixed !important;
  }

  .xl\:builder-bg-local{
    background-attachment: local !important;
  }

  .xl\:builder-bg-scroll{
    background-attachment: scroll !important;
  }

  .xl\:builder-bg-clip-border{
    background-clip: border-box !important;
  }

  .xl\:builder-bg-clip-padding{
    background-clip: padding-box !important;
  }

  .xl\:builder-bg-clip-content{
    background-clip: content-box !important;
  }

  .xl\:builder-bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .xl\:builder-bg-bottom{
    background-position: bottom !important;
  }

  .xl\:builder-bg-center{
    background-position: center !important;
  }

  .xl\:builder-bg-left{
    background-position: left !important;
  }

  .xl\:builder-bg-left-bottom{
    background-position: left bottom !important;
  }

  .xl\:builder-bg-left-top{
    background-position: left top !important;
  }

  .xl\:builder-bg-right{
    background-position: right !important;
  }

  .xl\:builder-bg-right-bottom{
    background-position: right bottom !important;
  }

  .xl\:builder-bg-right-top{
    background-position: right top !important;
  }

  .xl\:builder-bg-top{
    background-position: top !important;
  }

  .xl\:builder-bg-repeat{
    background-repeat: repeat !important;
  }

  .xl\:builder-bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .xl\:builder-bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .xl\:builder-bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .xl\:builder-bg-repeat-round{
    background-repeat: round !important;
  }

  .xl\:builder-bg-repeat-space{
    background-repeat: space !important;
  }

  .xl\:builder-bg-origin-border{
    background-origin: border-box !important;
  }

  .xl\:builder-bg-origin-padding{
    background-origin: padding-box !important;
  }

  .xl\:builder-bg-origin-content{
    background-origin: content-box !important;
  }

  .xl\:builder-fill-current{
    fill: currentColor !important;
  }

  .xl\:builder-stroke-current{
    stroke: currentColor !important;
  }

  .xl\:builder-stroke-0{
    stroke-width: 0 !important;
  }

  .xl\:builder-stroke-1{
    stroke-width: 1 !important;
  }

  .xl\:builder-stroke-2{
    stroke-width: 2 !important;
  }

  .xl\:builder-object-contain{
    object-fit: contain !important;
  }

  .xl\:builder-object-cover{
    object-fit: cover !important;
  }

  .xl\:builder-object-fill{
    object-fit: fill !important;
  }

  .xl\:builder-object-none{
    object-fit: none !important;
  }

  .xl\:builder-object-scale-down{
    object-fit: scale-down !important;
  }

  .xl\:builder-object-bottom{
    object-position: bottom !important;
  }

  .xl\:builder-object-center{
    object-position: center !important;
  }

  .xl\:builder-object-left{
    object-position: left !important;
  }

  .xl\:builder-object-left-bottom{
    object-position: left bottom !important;
  }

  .xl\:builder-object-left-top{
    object-position: left top !important;
  }

  .xl\:builder-object-right{
    object-position: right !important;
  }

  .xl\:builder-object-right-bottom{
    object-position: right bottom !important;
  }

  .xl\:builder-object-right-top{
    object-position: right top !important;
  }

  .xl\:builder-object-top{
    object-position: top !important;
  }

  .xl\:builder-p-0{
    padding: 0px !important;
  }

  .xl\:builder-p-1{
    padding: 0.25rem !important;
  }

  .xl\:builder-p-2{
    padding: 0.5rem !important;
  }

  .xl\:builder-p-3{
    padding: 0.75rem !important;
  }

  .xl\:builder-p-4{
    padding: 1rem !important;
  }

  .xl\:builder-p-5{
    padding: 1.25rem !important;
  }

  .xl\:builder-p-6{
    padding: 1.5rem !important;
  }

  .xl\:builder-p-7{
    padding: 1.75rem !important;
  }

  .xl\:builder-p-8{
    padding: 2rem !important;
  }

  .xl\:builder-p-9{
    padding: 2.25rem !important;
  }

  .xl\:builder-p-10{
    padding: 2.5rem !important;
  }

  .xl\:builder-p-11{
    padding: 2.75rem !important;
  }

  .xl\:builder-p-12{
    padding: 3rem !important;
  }

  .xl\:builder-p-14{
    padding: 3.5rem !important;
  }

  .xl\:builder-p-16{
    padding: 4rem !important;
  }

  .xl\:builder-p-20{
    padding: 5rem !important;
  }

  .xl\:builder-p-24{
    padding: 6rem !important;
  }

  .xl\:builder-p-28{
    padding: 7rem !important;
  }

  .xl\:builder-p-32{
    padding: 8rem !important;
  }

  .xl\:builder-p-36{
    padding: 9rem !important;
  }

  .xl\:builder-p-40{
    padding: 10rem !important;
  }

  .xl\:builder-p-44{
    padding: 11rem !important;
  }

  .xl\:builder-p-48{
    padding: 12rem !important;
  }

  .xl\:builder-p-52{
    padding: 13rem !important;
  }

  .xl\:builder-p-56{
    padding: 14rem !important;
  }

  .xl\:builder-p-60{
    padding: 15rem !important;
  }

  .xl\:builder-p-64{
    padding: 16rem !important;
  }

  .xl\:builder-p-72{
    padding: 18rem !important;
  }

  .xl\:builder-p-80{
    padding: 20rem !important;
  }

  .xl\:builder-p-96{
    padding: 24rem !important;
  }

  .xl\:builder-p-px{
    padding: 1px !important;
  }

  .xl\:builder-p-0\.5{
    padding: 0.125rem !important;
  }

  .xl\:builder-p-1\.5{
    padding: 0.375rem !important;
  }

  .xl\:builder-p-2\.5{
    padding: 0.625rem !important;
  }

  .xl\:builder-p-3\.5{
    padding: 0.875rem !important;
  }

  .xl\:builder-px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .xl\:builder-px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xl\:builder-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xl\:builder-px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xl\:builder-px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:builder-px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xl\:builder-px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:builder-px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .xl\:builder-px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:builder-px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .xl\:builder-px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xl\:builder-px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .xl\:builder-px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:builder-px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .xl\:builder-px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:builder-px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:builder-px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xl\:builder-px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .xl\:builder-px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xl\:builder-px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .xl\:builder-px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xl\:builder-px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .xl\:builder-px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xl\:builder-px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .xl\:builder-px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xl\:builder-px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .xl\:builder-px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xl\:builder-px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .xl\:builder-px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .xl\:builder-px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .xl\:builder-px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xl\:builder-px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .xl\:builder-px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .xl\:builder-px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .xl\:builder-px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .xl\:builder-py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .xl\:builder-py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xl\:builder-py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xl\:builder-py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xl\:builder-py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:builder-py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xl\:builder-py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:builder-py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .xl\:builder-py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:builder-py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .xl\:builder-py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xl\:builder-py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .xl\:builder-py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:builder-py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .xl\:builder-py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:builder-py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xl\:builder-py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xl\:builder-py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .xl\:builder-py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xl\:builder-py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .xl\:builder-py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xl\:builder-py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .xl\:builder-py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xl\:builder-py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .xl\:builder-py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xl\:builder-py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .xl\:builder-py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xl\:builder-py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .xl\:builder-py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .xl\:builder-py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .xl\:builder-py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xl\:builder-py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .xl\:builder-py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .xl\:builder-py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .xl\:builder-py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .xl\:builder-pt-0{
    padding-top: 0px !important;
  }

  .xl\:builder-pt-1{
    padding-top: 0.25rem !important;
  }

  .xl\:builder-pt-2{
    padding-top: 0.5rem !important;
  }

  .xl\:builder-pt-3{
    padding-top: 0.75rem !important;
  }

  .xl\:builder-pt-4{
    padding-top: 1rem !important;
  }

  .xl\:builder-pt-5{
    padding-top: 1.25rem !important;
  }

  .xl\:builder-pt-6{
    padding-top: 1.5rem !important;
  }

  .xl\:builder-pt-7{
    padding-top: 1.75rem !important;
  }

  .xl\:builder-pt-8{
    padding-top: 2rem !important;
  }

  .xl\:builder-pt-9{
    padding-top: 2.25rem !important;
  }

  .xl\:builder-pt-10{
    padding-top: 2.5rem !important;
  }

  .xl\:builder-pt-11{
    padding-top: 2.75rem !important;
  }

  .xl\:builder-pt-12{
    padding-top: 3rem !important;
  }

  .xl\:builder-pt-14{
    padding-top: 3.5rem !important;
  }

  .xl\:builder-pt-16{
    padding-top: 4rem !important;
  }

  .xl\:builder-pt-20{
    padding-top: 5rem !important;
  }

  .xl\:builder-pt-24{
    padding-top: 6rem !important;
  }

  .xl\:builder-pt-28{
    padding-top: 7rem !important;
  }

  .xl\:builder-pt-32{
    padding-top: 8rem !important;
  }

  .xl\:builder-pt-36{
    padding-top: 9rem !important;
  }

  .xl\:builder-pt-40{
    padding-top: 10rem !important;
  }

  .xl\:builder-pt-44{
    padding-top: 11rem !important;
  }

  .xl\:builder-pt-48{
    padding-top: 12rem !important;
  }

  .xl\:builder-pt-52{
    padding-top: 13rem !important;
  }

  .xl\:builder-pt-56{
    padding-top: 14rem !important;
  }

  .xl\:builder-pt-60{
    padding-top: 15rem !important;
  }

  .xl\:builder-pt-64{
    padding-top: 16rem !important;
  }

  .xl\:builder-pt-72{
    padding-top: 18rem !important;
  }

  .xl\:builder-pt-80{
    padding-top: 20rem !important;
  }

  .xl\:builder-pt-96{
    padding-top: 24rem !important;
  }

  .xl\:builder-pt-px{
    padding-top: 1px !important;
  }

  .xl\:builder-pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .xl\:builder-pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .xl\:builder-pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .xl\:builder-pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .xl\:builder-pr-0{
    padding-right: 0px !important;
  }

  .xl\:builder-pr-1{
    padding-right: 0.25rem !important;
  }

  .xl\:builder-pr-2{
    padding-right: 0.5rem !important;
  }

  .xl\:builder-pr-3{
    padding-right: 0.75rem !important;
  }

  .xl\:builder-pr-4{
    padding-right: 1rem !important;
  }

  .xl\:builder-pr-5{
    padding-right: 1.25rem !important;
  }

  .xl\:builder-pr-6{
    padding-right: 1.5rem !important;
  }

  .xl\:builder-pr-7{
    padding-right: 1.75rem !important;
  }

  .xl\:builder-pr-8{
    padding-right: 2rem !important;
  }

  .xl\:builder-pr-9{
    padding-right: 2.25rem !important;
  }

  .xl\:builder-pr-10{
    padding-right: 2.5rem !important;
  }

  .xl\:builder-pr-11{
    padding-right: 2.75rem !important;
  }

  .xl\:builder-pr-12{
    padding-right: 3rem !important;
  }

  .xl\:builder-pr-14{
    padding-right: 3.5rem !important;
  }

  .xl\:builder-pr-16{
    padding-right: 4rem !important;
  }

  .xl\:builder-pr-20{
    padding-right: 5rem !important;
  }

  .xl\:builder-pr-24{
    padding-right: 6rem !important;
  }

  .xl\:builder-pr-28{
    padding-right: 7rem !important;
  }

  .xl\:builder-pr-32{
    padding-right: 8rem !important;
  }

  .xl\:builder-pr-36{
    padding-right: 9rem !important;
  }

  .xl\:builder-pr-40{
    padding-right: 10rem !important;
  }

  .xl\:builder-pr-44{
    padding-right: 11rem !important;
  }

  .xl\:builder-pr-48{
    padding-right: 12rem !important;
  }

  .xl\:builder-pr-52{
    padding-right: 13rem !important;
  }

  .xl\:builder-pr-56{
    padding-right: 14rem !important;
  }

  .xl\:builder-pr-60{
    padding-right: 15rem !important;
  }

  .xl\:builder-pr-64{
    padding-right: 16rem !important;
  }

  .xl\:builder-pr-72{
    padding-right: 18rem !important;
  }

  .xl\:builder-pr-80{
    padding-right: 20rem !important;
  }

  .xl\:builder-pr-96{
    padding-right: 24rem !important;
  }

  .xl\:builder-pr-px{
    padding-right: 1px !important;
  }

  .xl\:builder-pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .xl\:builder-pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .xl\:builder-pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .xl\:builder-pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .xl\:builder-pb-0{
    padding-bottom: 0px !important;
  }

  .xl\:builder-pb-1{
    padding-bottom: 0.25rem !important;
  }

  .xl\:builder-pb-2{
    padding-bottom: 0.5rem !important;
  }

  .xl\:builder-pb-3{
    padding-bottom: 0.75rem !important;
  }

  .xl\:builder-pb-4{
    padding-bottom: 1rem !important;
  }

  .xl\:builder-pb-5{
    padding-bottom: 1.25rem !important;
  }

  .xl\:builder-pb-6{
    padding-bottom: 1.5rem !important;
  }

  .xl\:builder-pb-7{
    padding-bottom: 1.75rem !important;
  }

  .xl\:builder-pb-8{
    padding-bottom: 2rem !important;
  }

  .xl\:builder-pb-9{
    padding-bottom: 2.25rem !important;
  }

  .xl\:builder-pb-10{
    padding-bottom: 2.5rem !important;
  }

  .xl\:builder-pb-11{
    padding-bottom: 2.75rem !important;
  }

  .xl\:builder-pb-12{
    padding-bottom: 3rem !important;
  }

  .xl\:builder-pb-14{
    padding-bottom: 3.5rem !important;
  }

  .xl\:builder-pb-16{
    padding-bottom: 4rem !important;
  }

  .xl\:builder-pb-20{
    padding-bottom: 5rem !important;
  }

  .xl\:builder-pb-24{
    padding-bottom: 6rem !important;
  }

  .xl\:builder-pb-28{
    padding-bottom: 7rem !important;
  }

  .xl\:builder-pb-32{
    padding-bottom: 8rem !important;
  }

  .xl\:builder-pb-36{
    padding-bottom: 9rem !important;
  }

  .xl\:builder-pb-40{
    padding-bottom: 10rem !important;
  }

  .xl\:builder-pb-44{
    padding-bottom: 11rem !important;
  }

  .xl\:builder-pb-48{
    padding-bottom: 12rem !important;
  }

  .xl\:builder-pb-52{
    padding-bottom: 13rem !important;
  }

  .xl\:builder-pb-56{
    padding-bottom: 14rem !important;
  }

  .xl\:builder-pb-60{
    padding-bottom: 15rem !important;
  }

  .xl\:builder-pb-64{
    padding-bottom: 16rem !important;
  }

  .xl\:builder-pb-72{
    padding-bottom: 18rem !important;
  }

  .xl\:builder-pb-80{
    padding-bottom: 20rem !important;
  }

  .xl\:builder-pb-96{
    padding-bottom: 24rem !important;
  }

  .xl\:builder-pb-px{
    padding-bottom: 1px !important;
  }

  .xl\:builder-pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .xl\:builder-pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .xl\:builder-pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .xl\:builder-pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .xl\:builder-pl-0{
    padding-left: 0px !important;
  }

  .xl\:builder-pl-1{
    padding-left: 0.25rem !important;
  }

  .xl\:builder-pl-2{
    padding-left: 0.5rem !important;
  }

  .xl\:builder-pl-3{
    padding-left: 0.75rem !important;
  }

  .xl\:builder-pl-4{
    padding-left: 1rem !important;
  }

  .xl\:builder-pl-5{
    padding-left: 1.25rem !important;
  }

  .xl\:builder-pl-6{
    padding-left: 1.5rem !important;
  }

  .xl\:builder-pl-7{
    padding-left: 1.75rem !important;
  }

  .xl\:builder-pl-8{
    padding-left: 2rem !important;
  }

  .xl\:builder-pl-9{
    padding-left: 2.25rem !important;
  }

  .xl\:builder-pl-10{
    padding-left: 2.5rem !important;
  }

  .xl\:builder-pl-11{
    padding-left: 2.75rem !important;
  }

  .xl\:builder-pl-12{
    padding-left: 3rem !important;
  }

  .xl\:builder-pl-14{
    padding-left: 3.5rem !important;
  }

  .xl\:builder-pl-16{
    padding-left: 4rem !important;
  }

  .xl\:builder-pl-20{
    padding-left: 5rem !important;
  }

  .xl\:builder-pl-24{
    padding-left: 6rem !important;
  }

  .xl\:builder-pl-28{
    padding-left: 7rem !important;
  }

  .xl\:builder-pl-32{
    padding-left: 8rem !important;
  }

  .xl\:builder-pl-36{
    padding-left: 9rem !important;
  }

  .xl\:builder-pl-40{
    padding-left: 10rem !important;
  }

  .xl\:builder-pl-44{
    padding-left: 11rem !important;
  }

  .xl\:builder-pl-48{
    padding-left: 12rem !important;
  }

  .xl\:builder-pl-52{
    padding-left: 13rem !important;
  }

  .xl\:builder-pl-56{
    padding-left: 14rem !important;
  }

  .xl\:builder-pl-60{
    padding-left: 15rem !important;
  }

  .xl\:builder-pl-64{
    padding-left: 16rem !important;
  }

  .xl\:builder-pl-72{
    padding-left: 18rem !important;
  }

  .xl\:builder-pl-80{
    padding-left: 20rem !important;
  }

  .xl\:builder-pl-96{
    padding-left: 24rem !important;
  }

  .xl\:builder-pl-px{
    padding-left: 1px !important;
  }

  .xl\:builder-pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .xl\:builder-pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .xl\:builder-pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .xl\:builder-pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .xl\:builder-text-left{
    text-align: left !important;
  }

  .xl\:builder-text-center{
    text-align: center !important;
  }

  .xl\:builder-text-right{
    text-align: right !important;
  }

  .xl\:builder-text-justify{
    text-align: justify !important;
  }

  .xl\:builder-align-baseline{
    vertical-align: baseline !important;
  }

  .xl\:builder-align-top{
    vertical-align: top !important;
  }

  .xl\:builder-align-middle{
    vertical-align: middle !important;
  }

  .xl\:builder-align-bottom{
    vertical-align: bottom !important;
  }

  .xl\:builder-align-text-top{
    vertical-align: text-top !important;
  }

  .xl\:builder-align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .xl\:builder-font-primary{
    font-family: proxima-nova !important;
  }

  .xl\:builder-text-sm{
    font-size: 14px !important;
  }

  .xl\:builder-text-md{
    font-size: 15px !important;
  }

  .xl\:builder-text-2xl{
    font-size: 24px !important;
  }

  .xl\:builder-font-thin{
    font-weight: 100 !important;
  }

  .xl\:builder-font-extralight{
    font-weight: 200 !important;
  }

  .xl\:builder-font-light{
    font-weight: 300 !important;
  }

  .xl\:builder-font-normal{
    font-weight: 400 !important;
  }

  .xl\:builder-font-medium{
    font-weight: 500 !important;
  }

  .xl\:builder-font-semibold{
    font-weight: 600 !important;
  }

  .xl\:builder-font-bold{
    font-weight: 700 !important;
  }

  .xl\:builder-font-extrabold{
    font-weight: 800 !important;
  }

  .xl\:builder-font-black{
    font-weight: 900 !important;
  }

  .xl\:builder-uppercase{
    text-transform: uppercase !important;
  }

  .xl\:builder-lowercase{
    text-transform: lowercase !important;
  }

  .xl\:builder-capitalize{
    text-transform: capitalize !important;
  }

  .xl\:builder-normal-case{
    text-transform: none !important;
  }

  .xl\:builder-italic{
    font-style: italic !important;
  }

  .xl\:builder-not-italic{
    font-style: normal !important;
  }

  .xl\:builder-ordinal, .xl\:builder-slashed-zero, .xl\:builder-lining-nums, .xl\:builder-oldstyle-nums, .xl\:builder-proportional-nums, .xl\:builder-tabular-nums, .xl\:builder-diagonal-fractions, .xl\:builder-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .xl\:builder-normal-nums{
    font-variant-numeric: normal !important;
  }

  .xl\:builder-ordinal{
    --tw-ordinal: ordinal !important;
  }

  .xl\:builder-slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .xl\:builder-lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .xl\:builder-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .xl\:builder-proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .xl\:builder-tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .xl\:builder-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .xl\:builder-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .xl\:builder-leading-3{
    line-height: .75rem !important;
  }

  .xl\:builder-leading-4{
    line-height: 1rem !important;
  }

  .xl\:builder-leading-5{
    line-height: 1.25rem !important;
  }

  .xl\:builder-leading-6{
    line-height: 1.5rem !important;
  }

  .xl\:builder-leading-7{
    line-height: 1.75rem !important;
  }

  .xl\:builder-leading-8{
    line-height: 2rem !important;
  }

  .xl\:builder-leading-9{
    line-height: 2.25rem !important;
  }

  .xl\:builder-leading-10{
    line-height: 2.5rem !important;
  }

  .xl\:builder-leading-none{
    line-height: 1 !important;
  }

  .xl\:builder-leading-tight{
    line-height: 1.25 !important;
  }

  .xl\:builder-leading-snug{
    line-height: 1.375 !important;
  }

  .xl\:builder-leading-normal{
    line-height: 1.5 !important;
  }

  .xl\:builder-leading-relaxed{
    line-height: 1.625 !important;
  }

  .xl\:builder-leading-loose{
    line-height: 2 !important;
  }

  .xl\:builder-tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .xl\:builder-tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .xl\:builder-tracking-normal{
    letter-spacing: 0em !important;
  }

  .xl\:builder-tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .xl\:builder-tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .xl\:builder-tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .xl\:builder-text-transparent{
    color: transparent !important;
  }

  .xl\:builder-text-current{
    color: currentColor !important;
  }

  .xl\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-transparent{
    color: transparent !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-current{
    color: currentColor !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-transparent:focus-within{
    color: transparent !important;
  }

  .xl\:focus-within\:builder-text-current:focus-within{
    color: currentColor !important;
  }

  .xl\:focus-within\:builder-text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-primary:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-secondary-dark:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:builder-text-secondary-darker:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-transparent:hover{
    color: transparent !important;
  }

  .xl\:hover\:builder-text-current:hover{
    color: currentColor !important;
  }

  .xl\:hover\:builder-text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-primary:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-secondary-dark:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:builder-text-secondary-darker:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-transparent:focus{
    color: transparent !important;
  }

  .xl\:focus\:builder-text-current:focus{
    color: currentColor !important;
  }

  .xl\:focus\:builder-text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-primary:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-secondary-dark:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:builder-text-secondary-darker:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .xl\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .xl\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .xl\:focus-within\:builder-text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .xl\:focus-within\:builder-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:focus-within\:builder-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:focus-within\:builder-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:focus-within\:builder-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:focus-within\:builder-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:focus-within\:builder-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:focus-within\:builder-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:focus-within\:builder-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:focus-within\:builder-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:focus-within\:builder-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:focus-within\:builder-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:focus-within\:builder-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:focus-within\:builder-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:focus-within\:builder-text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .xl\:hover\:builder-text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .xl\:hover\:builder-text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:hover\:builder-text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:hover\:builder-text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:hover\:builder-text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:hover\:builder-text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:hover\:builder-text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:hover\:builder-text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:hover\:builder-text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:hover\:builder-text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:hover\:builder-text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:hover\:builder-text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:hover\:builder-text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:hover\:builder-text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:hover\:builder-text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .xl\:focus\:builder-text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .xl\:focus\:builder-text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:focus\:builder-text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:focus\:builder-text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:focus\:builder-text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:focus\:builder-text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:focus\:builder-text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:focus\:builder-text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:focus\:builder-text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:focus\:builder-text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:focus\:builder-text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:focus\:builder-text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:focus\:builder-text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:focus\:builder-text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:focus\:builder-text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .xl\:builder-underline{
    text-decoration: underline !important;
  }

  .xl\:builder-line-through{
    text-decoration: line-through !important;
  }

  .xl\:builder-no-underline{
    text-decoration: none !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-underline{
    text-decoration: underline !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-line-through{
    text-decoration: line-through !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-no-underline{
    text-decoration: none !important;
  }

  .xl\:focus-within\:builder-underline:focus-within{
    text-decoration: underline !important;
  }

  .xl\:focus-within\:builder-line-through:focus-within{
    text-decoration: line-through !important;
  }

  .xl\:focus-within\:builder-no-underline:focus-within{
    text-decoration: none !important;
  }

  .xl\:hover\:builder-underline:hover{
    text-decoration: underline !important;
  }

  .xl\:hover\:builder-line-through:hover{
    text-decoration: line-through !important;
  }

  .xl\:hover\:builder-no-underline:hover{
    text-decoration: none !important;
  }

  .xl\:focus\:builder-underline:focus{
    text-decoration: underline !important;
  }

  .xl\:focus\:builder-line-through:focus{
    text-decoration: line-through !important;
  }

  .xl\:focus\:builder-no-underline:focus{
    text-decoration: none !important;
  }

  .xl\:builder-antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .xl\:builder-subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .xl\:builder-placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .xl\:builder-placeholder-current::placeholder{
    color: currentColor !important;
  }

  .xl\:builder-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-primary::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-secondary-dark::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-secondary-darker::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .xl\:focus\:builder-placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .xl\:focus\:builder-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-primary:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-secondary-dark:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:builder-placeholder-secondary-darker:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:builder-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:builder-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:builder-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:builder-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:builder-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:builder-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:builder-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:builder-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:builder-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:builder-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:builder-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:builder-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:builder-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:builder-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:builder-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:focus\:builder-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:builder-opacity-0{
    opacity: 0 !important;
  }

  .xl\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .xl\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .xl\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .xl\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .xl\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .xl\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .xl\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .xl\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .xl\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .xl\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .xl\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .xl\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .xl\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .xl\:builder-opacity-100{
    opacity: 1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-0{
    opacity: 0 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-opacity-100{
    opacity: 1 !important;
  }

  .xl\:focus-within\:builder-opacity-0:focus-within{
    opacity: 0 !important;
  }

  .xl\:focus-within\:builder-opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .xl\:focus-within\:builder-opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .xl\:focus-within\:builder-opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .xl\:focus-within\:builder-opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .xl\:focus-within\:builder-opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .xl\:focus-within\:builder-opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .xl\:focus-within\:builder-opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .xl\:focus-within\:builder-opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .xl\:focus-within\:builder-opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .xl\:focus-within\:builder-opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .xl\:focus-within\:builder-opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .xl\:focus-within\:builder-opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .xl\:focus-within\:builder-opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .xl\:focus-within\:builder-opacity-100:focus-within{
    opacity: 1 !important;
  }

  .xl\:hover\:builder-opacity-0:hover{
    opacity: 0 !important;
  }

  .xl\:hover\:builder-opacity-5:hover{
    opacity: 0.05 !important;
  }

  .xl\:hover\:builder-opacity-10:hover{
    opacity: 0.1 !important;
  }

  .xl\:hover\:builder-opacity-20:hover{
    opacity: 0.2 !important;
  }

  .xl\:hover\:builder-opacity-25:hover{
    opacity: 0.25 !important;
  }

  .xl\:hover\:builder-opacity-30:hover{
    opacity: 0.3 !important;
  }

  .xl\:hover\:builder-opacity-40:hover{
    opacity: 0.4 !important;
  }

  .xl\:hover\:builder-opacity-50:hover{
    opacity: 0.5 !important;
  }

  .xl\:hover\:builder-opacity-60:hover{
    opacity: 0.6 !important;
  }

  .xl\:hover\:builder-opacity-70:hover{
    opacity: 0.7 !important;
  }

  .xl\:hover\:builder-opacity-75:hover{
    opacity: 0.75 !important;
  }

  .xl\:hover\:builder-opacity-80:hover{
    opacity: 0.8 !important;
  }

  .xl\:hover\:builder-opacity-90:hover{
    opacity: 0.9 !important;
  }

  .xl\:hover\:builder-opacity-95:hover{
    opacity: 0.95 !important;
  }

  .xl\:hover\:builder-opacity-100:hover{
    opacity: 1 !important;
  }

  .xl\:focus\:builder-opacity-0:focus{
    opacity: 0 !important;
  }

  .xl\:focus\:builder-opacity-5:focus{
    opacity: 0.05 !important;
  }

  .xl\:focus\:builder-opacity-10:focus{
    opacity: 0.1 !important;
  }

  .xl\:focus\:builder-opacity-20:focus{
    opacity: 0.2 !important;
  }

  .xl\:focus\:builder-opacity-25:focus{
    opacity: 0.25 !important;
  }

  .xl\:focus\:builder-opacity-30:focus{
    opacity: 0.3 !important;
  }

  .xl\:focus\:builder-opacity-40:focus{
    opacity: 0.4 !important;
  }

  .xl\:focus\:builder-opacity-50:focus{
    opacity: 0.5 !important;
  }

  .xl\:focus\:builder-opacity-60:focus{
    opacity: 0.6 !important;
  }

  .xl\:focus\:builder-opacity-70:focus{
    opacity: 0.7 !important;
  }

  .xl\:focus\:builder-opacity-75:focus{
    opacity: 0.75 !important;
  }

  .xl\:focus\:builder-opacity-80:focus{
    opacity: 0.8 !important;
  }

  .xl\:focus\:builder-opacity-90:focus{
    opacity: 0.9 !important;
  }

  .xl\:focus\:builder-opacity-95:focus{
    opacity: 0.95 !important;
  }

  .xl\:focus\:builder-opacity-100:focus{
    opacity: 1 !important;
  }

  .xl\:builder-bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .xl\:builder-bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .xl\:builder-bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .xl\:builder-bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .xl\:builder-bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .xl\:builder-bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .xl\:builder-bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .xl\:builder-bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .xl\:builder-bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .xl\:builder-bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .xl\:builder-bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .xl\:builder-bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .xl\:builder-bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .xl\:builder-bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .xl\:builder-bg-blend-color{
    background-blend-mode: color !important;
  }

  .xl\:builder-bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .xl\:builder-mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .xl\:builder-mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .xl\:builder-mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .xl\:builder-mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .xl\:builder-mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .xl\:builder-mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .xl\:builder-mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .xl\:builder-mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .xl\:builder-mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .xl\:builder-mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .xl\:builder-mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .xl\:builder-mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .xl\:builder-mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .xl\:builder-mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .xl\:builder-mix-blend-color{
    mix-blend-mode: color !important;
  }

  .xl\:builder-mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .xl\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .xl\:group-hover\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:builder-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:builder-shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:builder-shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:builder-outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .xl\:builder-outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .xl\:builder-outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .xl\:focus-within\:builder-outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .xl\:focus-within\:builder-outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .xl\:focus-within\:builder-outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .xl\:focus\:builder-outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .xl\:focus\:builder-outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .xl\:focus\:builder-outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .xl\:builder-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:builder-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:builder-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:builder-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:builder-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:builder-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:builder-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:builder-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:builder-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:builder-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:builder-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:builder-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:builder-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:builder-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:builder-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:builder-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:builder-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:builder-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:builder-ring-inset{
    --tw-ring-inset: inset !important;
  }

  .xl\:focus-within\:builder-ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .xl\:focus\:builder-ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .xl\:builder-ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .xl\:builder-ring-current{
    --tw-ring-color: currentColor !important;
  }

  .xl\:builder-ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-primary{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-secondary-dark{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-secondary-darker{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .xl\:focus-within\:builder-ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .xl\:focus-within\:builder-ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-primary:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-secondary-dark:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:builder-ring-secondary-darker:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .xl\:focus\:builder-ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .xl\:focus\:builder-ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-primary:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-secondary-dark:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:builder-ring-secondary-darker:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .xl\:builder-ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .xl\:builder-ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .xl\:builder-ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .xl\:builder-ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .xl\:builder-ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .xl\:builder-ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .xl\:builder-ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .xl\:builder-ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .xl\:builder-ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .xl\:builder-ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .xl\:builder-ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .xl\:builder-ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .xl\:builder-ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .xl\:builder-ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .xl\:builder-ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .xl\:focus-within\:builder-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .xl\:focus\:builder-ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .xl\:focus\:builder-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .xl\:focus\:builder-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .xl\:focus\:builder-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .xl\:focus\:builder-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .xl\:focus\:builder-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .xl\:focus\:builder-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .xl\:focus\:builder-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .xl\:focus\:builder-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .xl\:focus\:builder-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .xl\:focus\:builder-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .xl\:focus\:builder-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .xl\:focus\:builder-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .xl\:focus\:builder-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .xl\:focus\:builder-ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .xl\:builder-ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .xl\:builder-ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .xl\:builder-ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .xl\:builder-ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .xl\:builder-ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .xl\:focus-within\:builder-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .xl\:focus-within\:builder-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .xl\:focus-within\:builder-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .xl\:focus-within\:builder-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .xl\:focus-within\:builder-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .xl\:focus\:builder-ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .xl\:focus\:builder-ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .xl\:focus\:builder-ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .xl\:focus\:builder-ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .xl\:focus\:builder-ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .xl\:builder-ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .xl\:builder-ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .xl\:builder-ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .xl\:builder-ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .xl\:builder-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .xl\:builder-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .xl\:builder-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .xl\:builder-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .xl\:builder-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .xl\:builder-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .xl\:builder-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .xl\:builder-ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .xl\:builder-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .xl\:builder-ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .xl\:builder-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .xl\:builder-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .xl\:builder-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .xl\:builder-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .xl\:builder-ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .xl\:builder-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .xl\:builder-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .xl\:builder-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .xl\:builder-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .xl\:builder-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .xl\:builder-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .xl\:builder-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .xl\:builder-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .xl\:builder-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .xl\:builder-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .xl\:builder-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .xl\:builder-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .xl\:builder-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .xl\:builder-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .xl\:builder-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .xl\:builder-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .xl\:builder-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .xl\:builder-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .xl\:builder-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .xl\:builder-ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .xl\:builder-ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .xl\:builder-ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .xl\:builder-ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .xl\:builder-ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .xl\:builder-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .xl\:builder-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .xl\:builder-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .xl\:builder-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .xl\:builder-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .xl\:builder-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .xl\:builder-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .xl\:builder-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .xl\:builder-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .xl\:builder-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .xl\:builder-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .xl\:builder-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .xl\:builder-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .xl\:builder-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .xl\:builder-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .xl\:builder-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .xl\:builder-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .xl\:builder-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .xl\:builder-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .xl\:builder-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .xl\:builder-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .xl\:builder-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .xl\:builder-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .xl\:builder-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .xl\:builder-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .xl\:builder-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .xl\:builder-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .xl\:builder-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .xl\:builder-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .xl\:builder-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .xl\:builder-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .xl\:builder-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .xl\:builder-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .xl\:builder-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .xl\:builder-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .xl\:builder-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .xl\:builder-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .xl\:builder-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .xl\:builder-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .xl\:builder-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .xl\:builder-ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .xl\:builder-ring-offset-primary{
    --tw-ring-offset-color: #2d375a !important;
  }

  .xl\:builder-ring-offset-secondary-dark{
    --tw-ring-offset-color: #343434 !important;
  }

  .xl\:builder-ring-offset-secondary-darker{
    --tw-ring-offset-color: #212121 !important;
  }

  .xl\:focus-within\:builder-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .xl\:focus-within\:builder-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .xl\:focus-within\:builder-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .xl\:focus-within\:builder-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .xl\:focus-within\:builder-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .xl\:focus-within\:builder-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .xl\:focus-within\:builder-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .xl\:focus-within\:builder-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .xl\:focus-within\:builder-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .xl\:focus-within\:builder-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .xl\:focus-within\:builder-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .xl\:focus-within\:builder-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .xl\:focus-within\:builder-ring-offset-primary:focus-within{
    --tw-ring-offset-color: #2d375a !important;
  }

  .xl\:focus-within\:builder-ring-offset-secondary-dark:focus-within{
    --tw-ring-offset-color: #343434 !important;
  }

  .xl\:focus-within\:builder-ring-offset-secondary-darker:focus-within{
    --tw-ring-offset-color: #212121 !important;
  }

  .xl\:focus\:builder-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .xl\:focus\:builder-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .xl\:focus\:builder-ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .xl\:focus\:builder-ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .xl\:focus\:builder-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .xl\:focus\:builder-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .xl\:focus\:builder-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .xl\:focus\:builder-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .xl\:focus\:builder-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .xl\:focus\:builder-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .xl\:focus\:builder-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .xl\:focus\:builder-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .xl\:focus\:builder-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .xl\:focus\:builder-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .xl\:focus\:builder-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .xl\:focus\:builder-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .xl\:focus\:builder-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .xl\:focus\:builder-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .xl\:focus\:builder-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .xl\:focus\:builder-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .xl\:focus\:builder-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .xl\:focus\:builder-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .xl\:focus\:builder-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .xl\:focus\:builder-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .xl\:focus\:builder-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .xl\:focus\:builder-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .xl\:focus\:builder-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .xl\:focus\:builder-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .xl\:focus\:builder-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .xl\:focus\:builder-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .xl\:focus\:builder-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .xl\:focus\:builder-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .xl\:focus\:builder-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .xl\:focus\:builder-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .xl\:focus\:builder-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .xl\:focus\:builder-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .xl\:focus\:builder-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .xl\:focus\:builder-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .xl\:focus\:builder-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .xl\:focus\:builder-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .xl\:focus\:builder-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .xl\:focus\:builder-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .xl\:focus\:builder-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .xl\:focus\:builder-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .xl\:focus\:builder-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .xl\:focus\:builder-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .xl\:focus\:builder-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .xl\:focus\:builder-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .xl\:focus\:builder-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .xl\:focus\:builder-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .xl\:focus\:builder-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .xl\:focus\:builder-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .xl\:focus\:builder-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .xl\:focus\:builder-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .xl\:focus\:builder-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .xl\:focus\:builder-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .xl\:focus\:builder-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .xl\:focus\:builder-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .xl\:focus\:builder-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .xl\:focus\:builder-ring-offset-primary:focus{
    --tw-ring-offset-color: #2d375a !important;
  }

  .xl\:focus\:builder-ring-offset-secondary-dark:focus{
    --tw-ring-offset-color: #343434 !important;
  }

  .xl\:focus\:builder-ring-offset-secondary-darker:focus{
    --tw-ring-offset-color: #212121 !important;
  }

  .xl\:builder-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .xl\:builder-filter-none{
    filter: none !important;
  }

  .xl\:builder-blur-0{
    --tw-blur: blur(0) !important;
  }

  .xl\:builder-blur-none{
    --tw-blur: blur(0) !important;
  }

  .xl\:builder-blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .xl\:builder-blur{
    --tw-blur: blur(8px) !important;
  }

  .xl\:builder-blur-md{
    --tw-blur: blur(12px) !important;
  }

  .xl\:builder-blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .xl\:builder-blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .xl\:builder-blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .xl\:builder-blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .xl\:builder-brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .xl\:builder-brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .xl\:builder-brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .xl\:builder-brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .xl\:builder-brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .xl\:builder-brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .xl\:builder-brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .xl\:builder-brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .xl\:builder-brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .xl\:builder-brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .xl\:builder-brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .xl\:builder-contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .xl\:builder-contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .xl\:builder-contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .xl\:builder-contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .xl\:builder-contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .xl\:builder-contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .xl\:builder-contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .xl\:builder-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .xl\:builder-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .xl\:builder-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .xl\:builder-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .xl\:builder-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .xl\:builder-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .xl\:builder-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .xl\:builder-grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .xl\:builder-grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .xl\:builder-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .xl\:builder-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .xl\:builder-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .xl\:builder-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .xl\:builder-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .xl\:builder-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .xl\:builder--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .xl\:builder--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .xl\:builder--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .xl\:builder--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .xl\:builder--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .xl\:builder-invert-0{
    --tw-invert: invert(0) !important;
  }

  .xl\:builder-invert{
    --tw-invert: invert(100%) !important;
  }

  .xl\:builder-saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .xl\:builder-saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .xl\:builder-saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .xl\:builder-saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .xl\:builder-saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .xl\:builder-sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .xl\:builder-sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .xl\:builder-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .xl\:builder-backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .xl\:builder-backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .xl\:builder-backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .xl\:builder-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .xl\:builder-backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .xl\:builder-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .xl\:builder-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .xl\:builder-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .xl\:builder-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .xl\:builder-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .xl\:builder-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .xl\:builder-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .xl\:builder-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .xl\:builder-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .xl\:builder-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .xl\:builder-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .xl\:builder-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .xl\:builder-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .xl\:builder-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .xl\:builder-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .xl\:builder-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .xl\:builder-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .xl\:builder-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .xl\:builder-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .xl\:builder-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .xl\:builder-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .xl\:builder-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .xl\:builder-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .xl\:builder-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .xl\:builder-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .xl\:builder-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .xl\:builder-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .xl\:builder-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .xl\:builder-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .xl\:builder-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .xl\:builder-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .xl\:builder--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .xl\:builder--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .xl\:builder--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .xl\:builder--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .xl\:builder--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .xl\:builder-backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .xl\:builder-backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .xl\:builder-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .xl\:builder-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .xl\:builder-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .xl\:builder-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .xl\:builder-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .xl\:builder-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .xl\:builder-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .xl\:builder-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .xl\:builder-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .xl\:builder-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .xl\:builder-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .xl\:builder-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .xl\:builder-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .xl\:builder-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .xl\:builder-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .xl\:builder-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .xl\:builder-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .xl\:builder-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .xl\:builder-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .xl\:builder-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .xl\:builder-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .xl\:builder-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .xl\:builder-transition-none{
    transition-property: none !important;
  }

  .xl\:builder-transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:builder-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:builder-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:builder-transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:builder-transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:builder-transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:builder-delay-75{
    transition-delay: 75ms !important;
  }

  .xl\:builder-delay-100{
    transition-delay: 100ms !important;
  }

  .xl\:builder-delay-150{
    transition-delay: 150ms !important;
  }

  .xl\:builder-delay-200{
    transition-delay: 200ms !important;
  }

  .xl\:builder-delay-300{
    transition-delay: 300ms !important;
  }

  .xl\:builder-delay-500{
    transition-delay: 500ms !important;
  }

  .xl\:builder-delay-700{
    transition-delay: 700ms !important;
  }

  .xl\:builder-delay-1000{
    transition-delay: 1000ms !important;
  }

  .xl\:builder-duration-75{
    transition-duration: 75ms !important;
  }

  .xl\:builder-duration-100{
    transition-duration: 100ms !important;
  }

  .xl\:builder-duration-150{
    transition-duration: 150ms !important;
  }

  .xl\:builder-duration-200{
    transition-duration: 200ms !important;
  }

  .xl\:builder-duration-300{
    transition-duration: 300ms !important;
  }

  .xl\:builder-duration-500{
    transition-duration: 500ms !important;
  }

  .xl\:builder-duration-700{
    transition-duration: 700ms !important;
  }

  .xl\:builder-duration-1000{
    transition-duration: 1000ms !important;
  }

  .xl\:builder-ease-linear{
    transition-timing-function: linear !important;
  }

  .xl\:builder-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .xl\:builder-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .xl\:builder-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 1536px){
  .\32xl\:builder-container{
    width: 100%;
  }

  @media (min-width: 640px){
    .\32xl\:builder-container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .\32xl\:builder-container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .\32xl\:builder-container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .\32xl\:builder-container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .\32xl\:builder-container{
      max-width: 1536px;
    }
  }

  .\32xl\:builder-sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .\32xl\:builder-not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .\32xl\:focus-within\:builder-sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .\32xl\:focus-within\:builder-not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .\32xl\:focus\:builder-sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .\32xl\:focus\:builder-not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .\32xl\:builder-pointer-events-none{
    pointer-events: none !important;
  }

  .\32xl\:builder-pointer-events-auto{
    pointer-events: auto !important;
  }

  .\32xl\:builder-visible{
    visibility: visible !important;
  }

  .\32xl\:builder-invisible{
    visibility: hidden !important;
  }

  .\32xl\:builder-static{
    position: static !important;
  }

  .\32xl\:builder-fixed{
    position: fixed !important;
  }

  .\32xl\:builder-absolute{
    position: absolute !important;
  }

  .\32xl\:builder-relative{
    position: relative !important;
  }

  .\32xl\:builder-sticky{
    position: sticky !important;
  }

  .\32xl\:builder-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .\32xl\:builder-inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .\32xl\:builder-inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .\32xl\:builder-inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .\32xl\:builder-inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .\32xl\:builder-inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .\32xl\:builder-inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .\32xl\:builder-inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .\32xl\:builder-inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .\32xl\:builder-inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .\32xl\:builder-inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .\32xl\:builder-inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .\32xl\:builder-inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .\32xl\:builder-inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .\32xl\:builder-inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .\32xl\:builder-inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .\32xl\:builder-inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .\32xl\:builder-inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .\32xl\:builder-inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .\32xl\:builder-inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .\32xl\:builder-inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .\32xl\:builder-inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .\32xl\:builder-inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .\32xl\:builder-inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .\32xl\:builder-inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .\32xl\:builder-inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .\32xl\:builder-inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .\32xl\:builder-inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .\32xl\:builder-inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .\32xl\:builder-inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .\32xl\:builder-inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .\32xl\:builder-inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .\32xl\:builder-inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .\32xl\:builder-inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .\32xl\:builder-inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .\32xl\:builder-inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .\32xl\:builder--inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .\32xl\:builder--inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .\32xl\:builder--inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .\32xl\:builder--inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .\32xl\:builder--inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .\32xl\:builder--inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .\32xl\:builder--inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .\32xl\:builder--inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .\32xl\:builder--inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .\32xl\:builder--inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .\32xl\:builder--inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .\32xl\:builder--inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .\32xl\:builder--inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .\32xl\:builder--inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .\32xl\:builder--inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .\32xl\:builder--inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .\32xl\:builder--inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .\32xl\:builder--inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .\32xl\:builder--inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .\32xl\:builder--inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .\32xl\:builder--inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .\32xl\:builder--inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .\32xl\:builder--inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .\32xl\:builder--inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .\32xl\:builder--inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .\32xl\:builder--inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .\32xl\:builder--inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .\32xl\:builder--inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .\32xl\:builder--inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .\32xl\:builder--inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .\32xl\:builder--inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .\32xl\:builder--inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .\32xl\:builder--inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .\32xl\:builder--inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .\32xl\:builder--inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .\32xl\:builder-inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .\32xl\:builder-inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .\32xl\:builder-inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .\32xl\:builder-inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .\32xl\:builder-inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .\32xl\:builder-inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .\32xl\:builder-inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .\32xl\:builder--inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .\32xl\:builder--inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .\32xl\:builder--inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .\32xl\:builder--inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .\32xl\:builder--inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .\32xl\:builder--inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .\32xl\:builder--inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .\32xl\:builder-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .\32xl\:builder-inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .\32xl\:builder-inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .\32xl\:builder-inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .\32xl\:builder-inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .\32xl\:builder-inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .\32xl\:builder-inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .\32xl\:builder-inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .\32xl\:builder-inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .\32xl\:builder-inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .\32xl\:builder-inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .\32xl\:builder-inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .\32xl\:builder-inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .\32xl\:builder-inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .\32xl\:builder-inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .\32xl\:builder-inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .\32xl\:builder-inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .\32xl\:builder-inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .\32xl\:builder-inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .\32xl\:builder-inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .\32xl\:builder-inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .\32xl\:builder-inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .\32xl\:builder-inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .\32xl\:builder-inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .\32xl\:builder-inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .\32xl\:builder-inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .\32xl\:builder-inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .\32xl\:builder-inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .\32xl\:builder-inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .\32xl\:builder-inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .\32xl\:builder-inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .\32xl\:builder-inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .\32xl\:builder-inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .\32xl\:builder-inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .\32xl\:builder-inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .\32xl\:builder-inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .\32xl\:builder--inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .\32xl\:builder--inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .\32xl\:builder--inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .\32xl\:builder--inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .\32xl\:builder--inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .\32xl\:builder--inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .\32xl\:builder--inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .\32xl\:builder--inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .\32xl\:builder--inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .\32xl\:builder--inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .\32xl\:builder--inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .\32xl\:builder--inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .\32xl\:builder--inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .\32xl\:builder--inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .\32xl\:builder--inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .\32xl\:builder--inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .\32xl\:builder--inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .\32xl\:builder--inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .\32xl\:builder--inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .\32xl\:builder--inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .\32xl\:builder--inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .\32xl\:builder--inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .\32xl\:builder--inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .\32xl\:builder--inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .\32xl\:builder--inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .\32xl\:builder--inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .\32xl\:builder--inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .\32xl\:builder--inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .\32xl\:builder--inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .\32xl\:builder--inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .\32xl\:builder--inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .\32xl\:builder--inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .\32xl\:builder--inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .\32xl\:builder--inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .\32xl\:builder--inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .\32xl\:builder-inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .\32xl\:builder-inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .\32xl\:builder-inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .\32xl\:builder-inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .\32xl\:builder-inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .\32xl\:builder-inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .\32xl\:builder-inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .\32xl\:builder--inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .\32xl\:builder--inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .\32xl\:builder--inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .\32xl\:builder--inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .\32xl\:builder--inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .\32xl\:builder--inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .\32xl\:builder--inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .\32xl\:builder-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .\32xl\:builder-inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .\32xl\:builder-inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .\32xl\:builder-inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .\32xl\:builder-inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .\32xl\:builder-inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .\32xl\:builder-inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .\32xl\:builder-inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .\32xl\:builder-inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .\32xl\:builder-inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .\32xl\:builder-inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .\32xl\:builder-inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .\32xl\:builder-inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .\32xl\:builder-inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .\32xl\:builder-inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .\32xl\:builder-inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .\32xl\:builder-inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .\32xl\:builder-inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .\32xl\:builder-inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .\32xl\:builder-inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .\32xl\:builder-inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .\32xl\:builder-inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .\32xl\:builder-inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .\32xl\:builder-inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .\32xl\:builder-inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .\32xl\:builder-inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .\32xl\:builder-inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .\32xl\:builder-inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .\32xl\:builder-inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .\32xl\:builder-inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .\32xl\:builder-inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .\32xl\:builder-inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .\32xl\:builder-inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .\32xl\:builder-inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .\32xl\:builder-inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .\32xl\:builder-inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .\32xl\:builder--inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .\32xl\:builder--inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .\32xl\:builder--inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .\32xl\:builder--inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .\32xl\:builder--inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .\32xl\:builder--inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .\32xl\:builder--inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .\32xl\:builder--inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .\32xl\:builder--inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .\32xl\:builder--inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .\32xl\:builder--inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .\32xl\:builder--inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .\32xl\:builder--inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .\32xl\:builder--inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .\32xl\:builder--inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .\32xl\:builder--inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .\32xl\:builder--inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .\32xl\:builder--inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .\32xl\:builder--inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .\32xl\:builder--inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .\32xl\:builder--inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .\32xl\:builder--inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .\32xl\:builder--inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .\32xl\:builder--inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .\32xl\:builder--inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .\32xl\:builder--inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .\32xl\:builder--inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .\32xl\:builder--inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .\32xl\:builder--inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .\32xl\:builder--inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .\32xl\:builder--inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .\32xl\:builder--inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .\32xl\:builder--inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .\32xl\:builder--inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .\32xl\:builder--inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .\32xl\:builder-inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .\32xl\:builder-inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .\32xl\:builder-inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .\32xl\:builder-inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .\32xl\:builder-inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .\32xl\:builder-inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .\32xl\:builder-inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .\32xl\:builder--inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .\32xl\:builder--inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .\32xl\:builder--inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .\32xl\:builder--inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .\32xl\:builder--inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .\32xl\:builder--inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .\32xl\:builder--inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .\32xl\:builder-top-0{
    top: 0px !important;
  }

  .\32xl\:builder-top-1{
    top: 0.25rem !important;
  }

  .\32xl\:builder-top-2{
    top: 0.5rem !important;
  }

  .\32xl\:builder-top-3{
    top: 0.75rem !important;
  }

  .\32xl\:builder-top-4{
    top: 1rem !important;
  }

  .\32xl\:builder-top-5{
    top: 1.25rem !important;
  }

  .\32xl\:builder-top-6{
    top: 1.5rem !important;
  }

  .\32xl\:builder-top-7{
    top: 1.75rem !important;
  }

  .\32xl\:builder-top-8{
    top: 2rem !important;
  }

  .\32xl\:builder-top-9{
    top: 2.25rem !important;
  }

  .\32xl\:builder-top-10{
    top: 2.5rem !important;
  }

  .\32xl\:builder-top-11{
    top: 2.75rem !important;
  }

  .\32xl\:builder-top-12{
    top: 3rem !important;
  }

  .\32xl\:builder-top-14{
    top: 3.5rem !important;
  }

  .\32xl\:builder-top-16{
    top: 4rem !important;
  }

  .\32xl\:builder-top-20{
    top: 5rem !important;
  }

  .\32xl\:builder-top-24{
    top: 6rem !important;
  }

  .\32xl\:builder-top-28{
    top: 7rem !important;
  }

  .\32xl\:builder-top-32{
    top: 8rem !important;
  }

  .\32xl\:builder-top-36{
    top: 9rem !important;
  }

  .\32xl\:builder-top-40{
    top: 10rem !important;
  }

  .\32xl\:builder-top-44{
    top: 11rem !important;
  }

  .\32xl\:builder-top-48{
    top: 12rem !important;
  }

  .\32xl\:builder-top-52{
    top: 13rem !important;
  }

  .\32xl\:builder-top-56{
    top: 14rem !important;
  }

  .\32xl\:builder-top-60{
    top: 15rem !important;
  }

  .\32xl\:builder-top-64{
    top: 16rem !important;
  }

  .\32xl\:builder-top-72{
    top: 18rem !important;
  }

  .\32xl\:builder-top-80{
    top: 20rem !important;
  }

  .\32xl\:builder-top-96{
    top: 24rem !important;
  }

  .\32xl\:builder-top-auto{
    top: auto !important;
  }

  .\32xl\:builder-top-px{
    top: 1px !important;
  }

  .\32xl\:builder-top-0\.5{
    top: 0.125rem !important;
  }

  .\32xl\:builder-top-1\.5{
    top: 0.375rem !important;
  }

  .\32xl\:builder-top-2\.5{
    top: 0.625rem !important;
  }

  .\32xl\:builder-top-3\.5{
    top: 0.875rem !important;
  }

  .\32xl\:builder--top-0{
    top: 0px !important;
  }

  .\32xl\:builder--top-1{
    top: -0.25rem !important;
  }

  .\32xl\:builder--top-2{
    top: -0.5rem !important;
  }

  .\32xl\:builder--top-3{
    top: -0.75rem !important;
  }

  .\32xl\:builder--top-4{
    top: -1rem !important;
  }

  .\32xl\:builder--top-5{
    top: -1.25rem !important;
  }

  .\32xl\:builder--top-6{
    top: -1.5rem !important;
  }

  .\32xl\:builder--top-7{
    top: -1.75rem !important;
  }

  .\32xl\:builder--top-8{
    top: -2rem !important;
  }

  .\32xl\:builder--top-9{
    top: -2.25rem !important;
  }

  .\32xl\:builder--top-10{
    top: -2.5rem !important;
  }

  .\32xl\:builder--top-11{
    top: -2.75rem !important;
  }

  .\32xl\:builder--top-12{
    top: -3rem !important;
  }

  .\32xl\:builder--top-14{
    top: -3.5rem !important;
  }

  .\32xl\:builder--top-16{
    top: -4rem !important;
  }

  .\32xl\:builder--top-20{
    top: -5rem !important;
  }

  .\32xl\:builder--top-24{
    top: -6rem !important;
  }

  .\32xl\:builder--top-28{
    top: -7rem !important;
  }

  .\32xl\:builder--top-32{
    top: -8rem !important;
  }

  .\32xl\:builder--top-36{
    top: -9rem !important;
  }

  .\32xl\:builder--top-40{
    top: -10rem !important;
  }

  .\32xl\:builder--top-44{
    top: -11rem !important;
  }

  .\32xl\:builder--top-48{
    top: -12rem !important;
  }

  .\32xl\:builder--top-52{
    top: -13rem !important;
  }

  .\32xl\:builder--top-56{
    top: -14rem !important;
  }

  .\32xl\:builder--top-60{
    top: -15rem !important;
  }

  .\32xl\:builder--top-64{
    top: -16rem !important;
  }

  .\32xl\:builder--top-72{
    top: -18rem !important;
  }

  .\32xl\:builder--top-80{
    top: -20rem !important;
  }

  .\32xl\:builder--top-96{
    top: -24rem !important;
  }

  .\32xl\:builder--top-px{
    top: -1px !important;
  }

  .\32xl\:builder--top-0\.5{
    top: -0.125rem !important;
  }

  .\32xl\:builder--top-1\.5{
    top: -0.375rem !important;
  }

  .\32xl\:builder--top-2\.5{
    top: -0.625rem !important;
  }

  .\32xl\:builder--top-3\.5{
    top: -0.875rem !important;
  }

  .\32xl\:builder-top-1\/2{
    top: 50% !important;
  }

  .\32xl\:builder-top-1\/3{
    top: 33.333333% !important;
  }

  .\32xl\:builder-top-2\/3{
    top: 66.666667% !important;
  }

  .\32xl\:builder-top-1\/4{
    top: 25% !important;
  }

  .\32xl\:builder-top-2\/4{
    top: 50% !important;
  }

  .\32xl\:builder-top-3\/4{
    top: 75% !important;
  }

  .\32xl\:builder-top-full{
    top: 100% !important;
  }

  .\32xl\:builder--top-1\/2{
    top: -50% !important;
  }

  .\32xl\:builder--top-1\/3{
    top: -33.333333% !important;
  }

  .\32xl\:builder--top-2\/3{
    top: -66.666667% !important;
  }

  .\32xl\:builder--top-1\/4{
    top: -25% !important;
  }

  .\32xl\:builder--top-2\/4{
    top: -50% !important;
  }

  .\32xl\:builder--top-3\/4{
    top: -75% !important;
  }

  .\32xl\:builder--top-full{
    top: -100% !important;
  }

  .\32xl\:builder-right-0{
    right: 0px !important;
  }

  .\32xl\:builder-right-1{
    right: 0.25rem !important;
  }

  .\32xl\:builder-right-2{
    right: 0.5rem !important;
  }

  .\32xl\:builder-right-3{
    right: 0.75rem !important;
  }

  .\32xl\:builder-right-4{
    right: 1rem !important;
  }

  .\32xl\:builder-right-5{
    right: 1.25rem !important;
  }

  .\32xl\:builder-right-6{
    right: 1.5rem !important;
  }

  .\32xl\:builder-right-7{
    right: 1.75rem !important;
  }

  .\32xl\:builder-right-8{
    right: 2rem !important;
  }

  .\32xl\:builder-right-9{
    right: 2.25rem !important;
  }

  .\32xl\:builder-right-10{
    right: 2.5rem !important;
  }

  .\32xl\:builder-right-11{
    right: 2.75rem !important;
  }

  .\32xl\:builder-right-12{
    right: 3rem !important;
  }

  .\32xl\:builder-right-14{
    right: 3.5rem !important;
  }

  .\32xl\:builder-right-16{
    right: 4rem !important;
  }

  .\32xl\:builder-right-20{
    right: 5rem !important;
  }

  .\32xl\:builder-right-24{
    right: 6rem !important;
  }

  .\32xl\:builder-right-28{
    right: 7rem !important;
  }

  .\32xl\:builder-right-32{
    right: 8rem !important;
  }

  .\32xl\:builder-right-36{
    right: 9rem !important;
  }

  .\32xl\:builder-right-40{
    right: 10rem !important;
  }

  .\32xl\:builder-right-44{
    right: 11rem !important;
  }

  .\32xl\:builder-right-48{
    right: 12rem !important;
  }

  .\32xl\:builder-right-52{
    right: 13rem !important;
  }

  .\32xl\:builder-right-56{
    right: 14rem !important;
  }

  .\32xl\:builder-right-60{
    right: 15rem !important;
  }

  .\32xl\:builder-right-64{
    right: 16rem !important;
  }

  .\32xl\:builder-right-72{
    right: 18rem !important;
  }

  .\32xl\:builder-right-80{
    right: 20rem !important;
  }

  .\32xl\:builder-right-96{
    right: 24rem !important;
  }

  .\32xl\:builder-right-auto{
    right: auto !important;
  }

  .\32xl\:builder-right-px{
    right: 1px !important;
  }

  .\32xl\:builder-right-0\.5{
    right: 0.125rem !important;
  }

  .\32xl\:builder-right-1\.5{
    right: 0.375rem !important;
  }

  .\32xl\:builder-right-2\.5{
    right: 0.625rem !important;
  }

  .\32xl\:builder-right-3\.5{
    right: 0.875rem !important;
  }

  .\32xl\:builder--right-0{
    right: 0px !important;
  }

  .\32xl\:builder--right-1{
    right: -0.25rem !important;
  }

  .\32xl\:builder--right-2{
    right: -0.5rem !important;
  }

  .\32xl\:builder--right-3{
    right: -0.75rem !important;
  }

  .\32xl\:builder--right-4{
    right: -1rem !important;
  }

  .\32xl\:builder--right-5{
    right: -1.25rem !important;
  }

  .\32xl\:builder--right-6{
    right: -1.5rem !important;
  }

  .\32xl\:builder--right-7{
    right: -1.75rem !important;
  }

  .\32xl\:builder--right-8{
    right: -2rem !important;
  }

  .\32xl\:builder--right-9{
    right: -2.25rem !important;
  }

  .\32xl\:builder--right-10{
    right: -2.5rem !important;
  }

  .\32xl\:builder--right-11{
    right: -2.75rem !important;
  }

  .\32xl\:builder--right-12{
    right: -3rem !important;
  }

  .\32xl\:builder--right-14{
    right: -3.5rem !important;
  }

  .\32xl\:builder--right-16{
    right: -4rem !important;
  }

  .\32xl\:builder--right-20{
    right: -5rem !important;
  }

  .\32xl\:builder--right-24{
    right: -6rem !important;
  }

  .\32xl\:builder--right-28{
    right: -7rem !important;
  }

  .\32xl\:builder--right-32{
    right: -8rem !important;
  }

  .\32xl\:builder--right-36{
    right: -9rem !important;
  }

  .\32xl\:builder--right-40{
    right: -10rem !important;
  }

  .\32xl\:builder--right-44{
    right: -11rem !important;
  }

  .\32xl\:builder--right-48{
    right: -12rem !important;
  }

  .\32xl\:builder--right-52{
    right: -13rem !important;
  }

  .\32xl\:builder--right-56{
    right: -14rem !important;
  }

  .\32xl\:builder--right-60{
    right: -15rem !important;
  }

  .\32xl\:builder--right-64{
    right: -16rem !important;
  }

  .\32xl\:builder--right-72{
    right: -18rem !important;
  }

  .\32xl\:builder--right-80{
    right: -20rem !important;
  }

  .\32xl\:builder--right-96{
    right: -24rem !important;
  }

  .\32xl\:builder--right-px{
    right: -1px !important;
  }

  .\32xl\:builder--right-0\.5{
    right: -0.125rem !important;
  }

  .\32xl\:builder--right-1\.5{
    right: -0.375rem !important;
  }

  .\32xl\:builder--right-2\.5{
    right: -0.625rem !important;
  }

  .\32xl\:builder--right-3\.5{
    right: -0.875rem !important;
  }

  .\32xl\:builder-right-1\/2{
    right: 50% !important;
  }

  .\32xl\:builder-right-1\/3{
    right: 33.333333% !important;
  }

  .\32xl\:builder-right-2\/3{
    right: 66.666667% !important;
  }

  .\32xl\:builder-right-1\/4{
    right: 25% !important;
  }

  .\32xl\:builder-right-2\/4{
    right: 50% !important;
  }

  .\32xl\:builder-right-3\/4{
    right: 75% !important;
  }

  .\32xl\:builder-right-full{
    right: 100% !important;
  }

  .\32xl\:builder--right-1\/2{
    right: -50% !important;
  }

  .\32xl\:builder--right-1\/3{
    right: -33.333333% !important;
  }

  .\32xl\:builder--right-2\/3{
    right: -66.666667% !important;
  }

  .\32xl\:builder--right-1\/4{
    right: -25% !important;
  }

  .\32xl\:builder--right-2\/4{
    right: -50% !important;
  }

  .\32xl\:builder--right-3\/4{
    right: -75% !important;
  }

  .\32xl\:builder--right-full{
    right: -100% !important;
  }

  .\32xl\:builder-bottom-0{
    bottom: 0px !important;
  }

  .\32xl\:builder-bottom-1{
    bottom: 0.25rem !important;
  }

  .\32xl\:builder-bottom-2{
    bottom: 0.5rem !important;
  }

  .\32xl\:builder-bottom-3{
    bottom: 0.75rem !important;
  }

  .\32xl\:builder-bottom-4{
    bottom: 1rem !important;
  }

  .\32xl\:builder-bottom-5{
    bottom: 1.25rem !important;
  }

  .\32xl\:builder-bottom-6{
    bottom: 1.5rem !important;
  }

  .\32xl\:builder-bottom-7{
    bottom: 1.75rem !important;
  }

  .\32xl\:builder-bottom-8{
    bottom: 2rem !important;
  }

  .\32xl\:builder-bottom-9{
    bottom: 2.25rem !important;
  }

  .\32xl\:builder-bottom-10{
    bottom: 2.5rem !important;
  }

  .\32xl\:builder-bottom-11{
    bottom: 2.75rem !important;
  }

  .\32xl\:builder-bottom-12{
    bottom: 3rem !important;
  }

  .\32xl\:builder-bottom-14{
    bottom: 3.5rem !important;
  }

  .\32xl\:builder-bottom-16{
    bottom: 4rem !important;
  }

  .\32xl\:builder-bottom-20{
    bottom: 5rem !important;
  }

  .\32xl\:builder-bottom-24{
    bottom: 6rem !important;
  }

  .\32xl\:builder-bottom-28{
    bottom: 7rem !important;
  }

  .\32xl\:builder-bottom-32{
    bottom: 8rem !important;
  }

  .\32xl\:builder-bottom-36{
    bottom: 9rem !important;
  }

  .\32xl\:builder-bottom-40{
    bottom: 10rem !important;
  }

  .\32xl\:builder-bottom-44{
    bottom: 11rem !important;
  }

  .\32xl\:builder-bottom-48{
    bottom: 12rem !important;
  }

  .\32xl\:builder-bottom-52{
    bottom: 13rem !important;
  }

  .\32xl\:builder-bottom-56{
    bottom: 14rem !important;
  }

  .\32xl\:builder-bottom-60{
    bottom: 15rem !important;
  }

  .\32xl\:builder-bottom-64{
    bottom: 16rem !important;
  }

  .\32xl\:builder-bottom-72{
    bottom: 18rem !important;
  }

  .\32xl\:builder-bottom-80{
    bottom: 20rem !important;
  }

  .\32xl\:builder-bottom-96{
    bottom: 24rem !important;
  }

  .\32xl\:builder-bottom-auto{
    bottom: auto !important;
  }

  .\32xl\:builder-bottom-px{
    bottom: 1px !important;
  }

  .\32xl\:builder-bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .\32xl\:builder-bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .\32xl\:builder-bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .\32xl\:builder-bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .\32xl\:builder--bottom-0{
    bottom: 0px !important;
  }

  .\32xl\:builder--bottom-1{
    bottom: -0.25rem !important;
  }

  .\32xl\:builder--bottom-2{
    bottom: -0.5rem !important;
  }

  .\32xl\:builder--bottom-3{
    bottom: -0.75rem !important;
  }

  .\32xl\:builder--bottom-4{
    bottom: -1rem !important;
  }

  .\32xl\:builder--bottom-5{
    bottom: -1.25rem !important;
  }

  .\32xl\:builder--bottom-6{
    bottom: -1.5rem !important;
  }

  .\32xl\:builder--bottom-7{
    bottom: -1.75rem !important;
  }

  .\32xl\:builder--bottom-8{
    bottom: -2rem !important;
  }

  .\32xl\:builder--bottom-9{
    bottom: -2.25rem !important;
  }

  .\32xl\:builder--bottom-10{
    bottom: -2.5rem !important;
  }

  .\32xl\:builder--bottom-11{
    bottom: -2.75rem !important;
  }

  .\32xl\:builder--bottom-12{
    bottom: -3rem !important;
  }

  .\32xl\:builder--bottom-14{
    bottom: -3.5rem !important;
  }

  .\32xl\:builder--bottom-16{
    bottom: -4rem !important;
  }

  .\32xl\:builder--bottom-20{
    bottom: -5rem !important;
  }

  .\32xl\:builder--bottom-24{
    bottom: -6rem !important;
  }

  .\32xl\:builder--bottom-28{
    bottom: -7rem !important;
  }

  .\32xl\:builder--bottom-32{
    bottom: -8rem !important;
  }

  .\32xl\:builder--bottom-36{
    bottom: -9rem !important;
  }

  .\32xl\:builder--bottom-40{
    bottom: -10rem !important;
  }

  .\32xl\:builder--bottom-44{
    bottom: -11rem !important;
  }

  .\32xl\:builder--bottom-48{
    bottom: -12rem !important;
  }

  .\32xl\:builder--bottom-52{
    bottom: -13rem !important;
  }

  .\32xl\:builder--bottom-56{
    bottom: -14rem !important;
  }

  .\32xl\:builder--bottom-60{
    bottom: -15rem !important;
  }

  .\32xl\:builder--bottom-64{
    bottom: -16rem !important;
  }

  .\32xl\:builder--bottom-72{
    bottom: -18rem !important;
  }

  .\32xl\:builder--bottom-80{
    bottom: -20rem !important;
  }

  .\32xl\:builder--bottom-96{
    bottom: -24rem !important;
  }

  .\32xl\:builder--bottom-px{
    bottom: -1px !important;
  }

  .\32xl\:builder--bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .\32xl\:builder--bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .\32xl\:builder--bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .\32xl\:builder--bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .\32xl\:builder-bottom-1\/2{
    bottom: 50% !important;
  }

  .\32xl\:builder-bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .\32xl\:builder-bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .\32xl\:builder-bottom-1\/4{
    bottom: 25% !important;
  }

  .\32xl\:builder-bottom-2\/4{
    bottom: 50% !important;
  }

  .\32xl\:builder-bottom-3\/4{
    bottom: 75% !important;
  }

  .\32xl\:builder-bottom-full{
    bottom: 100% !important;
  }

  .\32xl\:builder--bottom-1\/2{
    bottom: -50% !important;
  }

  .\32xl\:builder--bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .\32xl\:builder--bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .\32xl\:builder--bottom-1\/4{
    bottom: -25% !important;
  }

  .\32xl\:builder--bottom-2\/4{
    bottom: -50% !important;
  }

  .\32xl\:builder--bottom-3\/4{
    bottom: -75% !important;
  }

  .\32xl\:builder--bottom-full{
    bottom: -100% !important;
  }

  .\32xl\:builder-left-0{
    left: 0px !important;
  }

  .\32xl\:builder-left-1{
    left: 0.25rem !important;
  }

  .\32xl\:builder-left-2{
    left: 0.5rem !important;
  }

  .\32xl\:builder-left-3{
    left: 0.75rem !important;
  }

  .\32xl\:builder-left-4{
    left: 1rem !important;
  }

  .\32xl\:builder-left-5{
    left: 1.25rem !important;
  }

  .\32xl\:builder-left-6{
    left: 1.5rem !important;
  }

  .\32xl\:builder-left-7{
    left: 1.75rem !important;
  }

  .\32xl\:builder-left-8{
    left: 2rem !important;
  }

  .\32xl\:builder-left-9{
    left: 2.25rem !important;
  }

  .\32xl\:builder-left-10{
    left: 2.5rem !important;
  }

  .\32xl\:builder-left-11{
    left: 2.75rem !important;
  }

  .\32xl\:builder-left-12{
    left: 3rem !important;
  }

  .\32xl\:builder-left-14{
    left: 3.5rem !important;
  }

  .\32xl\:builder-left-16{
    left: 4rem !important;
  }

  .\32xl\:builder-left-20{
    left: 5rem !important;
  }

  .\32xl\:builder-left-24{
    left: 6rem !important;
  }

  .\32xl\:builder-left-28{
    left: 7rem !important;
  }

  .\32xl\:builder-left-32{
    left: 8rem !important;
  }

  .\32xl\:builder-left-36{
    left: 9rem !important;
  }

  .\32xl\:builder-left-40{
    left: 10rem !important;
  }

  .\32xl\:builder-left-44{
    left: 11rem !important;
  }

  .\32xl\:builder-left-48{
    left: 12rem !important;
  }

  .\32xl\:builder-left-52{
    left: 13rem !important;
  }

  .\32xl\:builder-left-56{
    left: 14rem !important;
  }

  .\32xl\:builder-left-60{
    left: 15rem !important;
  }

  .\32xl\:builder-left-64{
    left: 16rem !important;
  }

  .\32xl\:builder-left-72{
    left: 18rem !important;
  }

  .\32xl\:builder-left-80{
    left: 20rem !important;
  }

  .\32xl\:builder-left-96{
    left: 24rem !important;
  }

  .\32xl\:builder-left-auto{
    left: auto !important;
  }

  .\32xl\:builder-left-px{
    left: 1px !important;
  }

  .\32xl\:builder-left-0\.5{
    left: 0.125rem !important;
  }

  .\32xl\:builder-left-1\.5{
    left: 0.375rem !important;
  }

  .\32xl\:builder-left-2\.5{
    left: 0.625rem !important;
  }

  .\32xl\:builder-left-3\.5{
    left: 0.875rem !important;
  }

  .\32xl\:builder--left-0{
    left: 0px !important;
  }

  .\32xl\:builder--left-1{
    left: -0.25rem !important;
  }

  .\32xl\:builder--left-2{
    left: -0.5rem !important;
  }

  .\32xl\:builder--left-3{
    left: -0.75rem !important;
  }

  .\32xl\:builder--left-4{
    left: -1rem !important;
  }

  .\32xl\:builder--left-5{
    left: -1.25rem !important;
  }

  .\32xl\:builder--left-6{
    left: -1.5rem !important;
  }

  .\32xl\:builder--left-7{
    left: -1.75rem !important;
  }

  .\32xl\:builder--left-8{
    left: -2rem !important;
  }

  .\32xl\:builder--left-9{
    left: -2.25rem !important;
  }

  .\32xl\:builder--left-10{
    left: -2.5rem !important;
  }

  .\32xl\:builder--left-11{
    left: -2.75rem !important;
  }

  .\32xl\:builder--left-12{
    left: -3rem !important;
  }

  .\32xl\:builder--left-14{
    left: -3.5rem !important;
  }

  .\32xl\:builder--left-16{
    left: -4rem !important;
  }

  .\32xl\:builder--left-20{
    left: -5rem !important;
  }

  .\32xl\:builder--left-24{
    left: -6rem !important;
  }

  .\32xl\:builder--left-28{
    left: -7rem !important;
  }

  .\32xl\:builder--left-32{
    left: -8rem !important;
  }

  .\32xl\:builder--left-36{
    left: -9rem !important;
  }

  .\32xl\:builder--left-40{
    left: -10rem !important;
  }

  .\32xl\:builder--left-44{
    left: -11rem !important;
  }

  .\32xl\:builder--left-48{
    left: -12rem !important;
  }

  .\32xl\:builder--left-52{
    left: -13rem !important;
  }

  .\32xl\:builder--left-56{
    left: -14rem !important;
  }

  .\32xl\:builder--left-60{
    left: -15rem !important;
  }

  .\32xl\:builder--left-64{
    left: -16rem !important;
  }

  .\32xl\:builder--left-72{
    left: -18rem !important;
  }

  .\32xl\:builder--left-80{
    left: -20rem !important;
  }

  .\32xl\:builder--left-96{
    left: -24rem !important;
  }

  .\32xl\:builder--left-px{
    left: -1px !important;
  }

  .\32xl\:builder--left-0\.5{
    left: -0.125rem !important;
  }

  .\32xl\:builder--left-1\.5{
    left: -0.375rem !important;
  }

  .\32xl\:builder--left-2\.5{
    left: -0.625rem !important;
  }

  .\32xl\:builder--left-3\.5{
    left: -0.875rem !important;
  }

  .\32xl\:builder-left-1\/2{
    left: 50% !important;
  }

  .\32xl\:builder-left-1\/3{
    left: 33.333333% !important;
  }

  .\32xl\:builder-left-2\/3{
    left: 66.666667% !important;
  }

  .\32xl\:builder-left-1\/4{
    left: 25% !important;
  }

  .\32xl\:builder-left-2\/4{
    left: 50% !important;
  }

  .\32xl\:builder-left-3\/4{
    left: 75% !important;
  }

  .\32xl\:builder-left-full{
    left: 100% !important;
  }

  .\32xl\:builder--left-1\/2{
    left: -50% !important;
  }

  .\32xl\:builder--left-1\/3{
    left: -33.333333% !important;
  }

  .\32xl\:builder--left-2\/3{
    left: -66.666667% !important;
  }

  .\32xl\:builder--left-1\/4{
    left: -25% !important;
  }

  .\32xl\:builder--left-2\/4{
    left: -50% !important;
  }

  .\32xl\:builder--left-3\/4{
    left: -75% !important;
  }

  .\32xl\:builder--left-full{
    left: -100% !important;
  }

  .\32xl\:builder-isolate{
    isolation: isolate !important;
  }

  .\32xl\:builder-isolation-auto{
    isolation: auto !important;
  }

  .\32xl\:builder-z-0{
    z-index: 0 !important;
  }

  .\32xl\:builder-z-10{
    z-index: 10 !important;
  }

  .\32xl\:builder-z-20{
    z-index: 20 !important;
  }

  .\32xl\:builder-z-30{
    z-index: 30 !important;
  }

  .\32xl\:builder-z-40{
    z-index: 40 !important;
  }

  .\32xl\:builder-z-50{
    z-index: 50 !important;
  }

  .\32xl\:builder-z-auto{
    z-index: auto !important;
  }

  .\32xl\:focus-within\:builder-z-0:focus-within{
    z-index: 0 !important;
  }

  .\32xl\:focus-within\:builder-z-10:focus-within{
    z-index: 10 !important;
  }

  .\32xl\:focus-within\:builder-z-20:focus-within{
    z-index: 20 !important;
  }

  .\32xl\:focus-within\:builder-z-30:focus-within{
    z-index: 30 !important;
  }

  .\32xl\:focus-within\:builder-z-40:focus-within{
    z-index: 40 !important;
  }

  .\32xl\:focus-within\:builder-z-50:focus-within{
    z-index: 50 !important;
  }

  .\32xl\:focus-within\:builder-z-auto:focus-within{
    z-index: auto !important;
  }

  .\32xl\:focus\:builder-z-0:focus{
    z-index: 0 !important;
  }

  .\32xl\:focus\:builder-z-10:focus{
    z-index: 10 !important;
  }

  .\32xl\:focus\:builder-z-20:focus{
    z-index: 20 !important;
  }

  .\32xl\:focus\:builder-z-30:focus{
    z-index: 30 !important;
  }

  .\32xl\:focus\:builder-z-40:focus{
    z-index: 40 !important;
  }

  .\32xl\:focus\:builder-z-50:focus{
    z-index: 50 !important;
  }

  .\32xl\:focus\:builder-z-auto:focus{
    z-index: auto !important;
  }

  .\32xl\:builder-order-1{
    order: 1 !important;
  }

  .\32xl\:builder-order-2{
    order: 2 !important;
  }

  .\32xl\:builder-order-3{
    order: 3 !important;
  }

  .\32xl\:builder-order-4{
    order: 4 !important;
  }

  .\32xl\:builder-order-5{
    order: 5 !important;
  }

  .\32xl\:builder-order-6{
    order: 6 !important;
  }

  .\32xl\:builder-order-7{
    order: 7 !important;
  }

  .\32xl\:builder-order-8{
    order: 8 !important;
  }

  .\32xl\:builder-order-9{
    order: 9 !important;
  }

  .\32xl\:builder-order-10{
    order: 10 !important;
  }

  .\32xl\:builder-order-11{
    order: 11 !important;
  }

  .\32xl\:builder-order-12{
    order: 12 !important;
  }

  .\32xl\:builder-order-first{
    order: -9999 !important;
  }

  .\32xl\:builder-order-last{
    order: 9999 !important;
  }

  .\32xl\:builder-order-none{
    order: 0 !important;
  }

  .\32xl\:builder-col-auto{
    grid-column: auto !important;
  }

  .\32xl\:builder-col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .\32xl\:builder-col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .\32xl\:builder-col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .\32xl\:builder-col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .\32xl\:builder-col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .\32xl\:builder-col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .\32xl\:builder-col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .\32xl\:builder-col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .\32xl\:builder-col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .\32xl\:builder-col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .\32xl\:builder-col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .\32xl\:builder-col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .\32xl\:builder-col-span-full{
    grid-column: 1 / -1 !important;
  }

  .\32xl\:builder-col-start-1{
    grid-column-start: 1 !important;
  }

  .\32xl\:builder-col-start-2{
    grid-column-start: 2 !important;
  }

  .\32xl\:builder-col-start-3{
    grid-column-start: 3 !important;
  }

  .\32xl\:builder-col-start-4{
    grid-column-start: 4 !important;
  }

  .\32xl\:builder-col-start-5{
    grid-column-start: 5 !important;
  }

  .\32xl\:builder-col-start-6{
    grid-column-start: 6 !important;
  }

  .\32xl\:builder-col-start-7{
    grid-column-start: 7 !important;
  }

  .\32xl\:builder-col-start-8{
    grid-column-start: 8 !important;
  }

  .\32xl\:builder-col-start-9{
    grid-column-start: 9 !important;
  }

  .\32xl\:builder-col-start-10{
    grid-column-start: 10 !important;
  }

  .\32xl\:builder-col-start-11{
    grid-column-start: 11 !important;
  }

  .\32xl\:builder-col-start-12{
    grid-column-start: 12 !important;
  }

  .\32xl\:builder-col-start-13{
    grid-column-start: 13 !important;
  }

  .\32xl\:builder-col-start-auto{
    grid-column-start: auto !important;
  }

  .\32xl\:builder-col-end-1{
    grid-column-end: 1 !important;
  }

  .\32xl\:builder-col-end-2{
    grid-column-end: 2 !important;
  }

  .\32xl\:builder-col-end-3{
    grid-column-end: 3 !important;
  }

  .\32xl\:builder-col-end-4{
    grid-column-end: 4 !important;
  }

  .\32xl\:builder-col-end-5{
    grid-column-end: 5 !important;
  }

  .\32xl\:builder-col-end-6{
    grid-column-end: 6 !important;
  }

  .\32xl\:builder-col-end-7{
    grid-column-end: 7 !important;
  }

  .\32xl\:builder-col-end-8{
    grid-column-end: 8 !important;
  }

  .\32xl\:builder-col-end-9{
    grid-column-end: 9 !important;
  }

  .\32xl\:builder-col-end-10{
    grid-column-end: 10 !important;
  }

  .\32xl\:builder-col-end-11{
    grid-column-end: 11 !important;
  }

  .\32xl\:builder-col-end-12{
    grid-column-end: 12 !important;
  }

  .\32xl\:builder-col-end-13{
    grid-column-end: 13 !important;
  }

  .\32xl\:builder-col-end-auto{
    grid-column-end: auto !important;
  }

  .\32xl\:builder-row-auto{
    grid-row: auto !important;
  }

  .\32xl\:builder-row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .\32xl\:builder-row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .\32xl\:builder-row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .\32xl\:builder-row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .\32xl\:builder-row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .\32xl\:builder-row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .\32xl\:builder-row-span-full{
    grid-row: 1 / -1 !important;
  }

  .\32xl\:builder-row-start-1{
    grid-row-start: 1 !important;
  }

  .\32xl\:builder-row-start-2{
    grid-row-start: 2 !important;
  }

  .\32xl\:builder-row-start-3{
    grid-row-start: 3 !important;
  }

  .\32xl\:builder-row-start-4{
    grid-row-start: 4 !important;
  }

  .\32xl\:builder-row-start-5{
    grid-row-start: 5 !important;
  }

  .\32xl\:builder-row-start-6{
    grid-row-start: 6 !important;
  }

  .\32xl\:builder-row-start-7{
    grid-row-start: 7 !important;
  }

  .\32xl\:builder-row-start-auto{
    grid-row-start: auto !important;
  }

  .\32xl\:builder-row-end-1{
    grid-row-end: 1 !important;
  }

  .\32xl\:builder-row-end-2{
    grid-row-end: 2 !important;
  }

  .\32xl\:builder-row-end-3{
    grid-row-end: 3 !important;
  }

  .\32xl\:builder-row-end-4{
    grid-row-end: 4 !important;
  }

  .\32xl\:builder-row-end-5{
    grid-row-end: 5 !important;
  }

  .\32xl\:builder-row-end-6{
    grid-row-end: 6 !important;
  }

  .\32xl\:builder-row-end-7{
    grid-row-end: 7 !important;
  }

  .\32xl\:builder-row-end-auto{
    grid-row-end: auto !important;
  }

  .\32xl\:builder-float-right{
    float: right !important;
  }

  .\32xl\:builder-float-left{
    float: left !important;
  }

  .\32xl\:builder-float-none{
    float: none !important;
  }

  .\32xl\:builder-clear-left{
    clear: left !important;
  }

  .\32xl\:builder-clear-right{
    clear: right !important;
  }

  .\32xl\:builder-clear-both{
    clear: both !important;
  }

  .\32xl\:builder-clear-none{
    clear: none !important;
  }

  .\32xl\:builder-m-0{
    margin: 0px !important;
  }

  .\32xl\:builder-m-1{
    margin: 0.25rem !important;
  }

  .\32xl\:builder-m-2{
    margin: 0.5rem !important;
  }

  .\32xl\:builder-m-3{
    margin: 0.75rem !important;
  }

  .\32xl\:builder-m-4{
    margin: 1rem !important;
  }

  .\32xl\:builder-m-5{
    margin: 1.25rem !important;
  }

  .\32xl\:builder-m-6{
    margin: 1.5rem !important;
  }

  .\32xl\:builder-m-7{
    margin: 1.75rem !important;
  }

  .\32xl\:builder-m-8{
    margin: 2rem !important;
  }

  .\32xl\:builder-m-9{
    margin: 2.25rem !important;
  }

  .\32xl\:builder-m-10{
    margin: 2.5rem !important;
  }

  .\32xl\:builder-m-11{
    margin: 2.75rem !important;
  }

  .\32xl\:builder-m-12{
    margin: 3rem !important;
  }

  .\32xl\:builder-m-14{
    margin: 3.5rem !important;
  }

  .\32xl\:builder-m-16{
    margin: 4rem !important;
  }

  .\32xl\:builder-m-20{
    margin: 5rem !important;
  }

  .\32xl\:builder-m-24{
    margin: 6rem !important;
  }

  .\32xl\:builder-m-28{
    margin: 7rem !important;
  }

  .\32xl\:builder-m-32{
    margin: 8rem !important;
  }

  .\32xl\:builder-m-36{
    margin: 9rem !important;
  }

  .\32xl\:builder-m-40{
    margin: 10rem !important;
  }

  .\32xl\:builder-m-44{
    margin: 11rem !important;
  }

  .\32xl\:builder-m-48{
    margin: 12rem !important;
  }

  .\32xl\:builder-m-52{
    margin: 13rem !important;
  }

  .\32xl\:builder-m-56{
    margin: 14rem !important;
  }

  .\32xl\:builder-m-60{
    margin: 15rem !important;
  }

  .\32xl\:builder-m-64{
    margin: 16rem !important;
  }

  .\32xl\:builder-m-72{
    margin: 18rem !important;
  }

  .\32xl\:builder-m-80{
    margin: 20rem !important;
  }

  .\32xl\:builder-m-96{
    margin: 24rem !important;
  }

  .\32xl\:builder-m-auto{
    margin: auto !important;
  }

  .\32xl\:builder-m-px{
    margin: 1px !important;
  }

  .\32xl\:builder-m-0\.5{
    margin: 0.125rem !important;
  }

  .\32xl\:builder-m-1\.5{
    margin: 0.375rem !important;
  }

  .\32xl\:builder-m-2\.5{
    margin: 0.625rem !important;
  }

  .\32xl\:builder-m-3\.5{
    margin: 0.875rem !important;
  }

  .\32xl\:builder--m-0{
    margin: 0px !important;
  }

  .\32xl\:builder--m-1{
    margin: -0.25rem !important;
  }

  .\32xl\:builder--m-2{
    margin: -0.5rem !important;
  }

  .\32xl\:builder--m-3{
    margin: -0.75rem !important;
  }

  .\32xl\:builder--m-4{
    margin: -1rem !important;
  }

  .\32xl\:builder--m-5{
    margin: -1.25rem !important;
  }

  .\32xl\:builder--m-6{
    margin: -1.5rem !important;
  }

  .\32xl\:builder--m-7{
    margin: -1.75rem !important;
  }

  .\32xl\:builder--m-8{
    margin: -2rem !important;
  }

  .\32xl\:builder--m-9{
    margin: -2.25rem !important;
  }

  .\32xl\:builder--m-10{
    margin: -2.5rem !important;
  }

  .\32xl\:builder--m-11{
    margin: -2.75rem !important;
  }

  .\32xl\:builder--m-12{
    margin: -3rem !important;
  }

  .\32xl\:builder--m-14{
    margin: -3.5rem !important;
  }

  .\32xl\:builder--m-16{
    margin: -4rem !important;
  }

  .\32xl\:builder--m-20{
    margin: -5rem !important;
  }

  .\32xl\:builder--m-24{
    margin: -6rem !important;
  }

  .\32xl\:builder--m-28{
    margin: -7rem !important;
  }

  .\32xl\:builder--m-32{
    margin: -8rem !important;
  }

  .\32xl\:builder--m-36{
    margin: -9rem !important;
  }

  .\32xl\:builder--m-40{
    margin: -10rem !important;
  }

  .\32xl\:builder--m-44{
    margin: -11rem !important;
  }

  .\32xl\:builder--m-48{
    margin: -12rem !important;
  }

  .\32xl\:builder--m-52{
    margin: -13rem !important;
  }

  .\32xl\:builder--m-56{
    margin: -14rem !important;
  }

  .\32xl\:builder--m-60{
    margin: -15rem !important;
  }

  .\32xl\:builder--m-64{
    margin: -16rem !important;
  }

  .\32xl\:builder--m-72{
    margin: -18rem !important;
  }

  .\32xl\:builder--m-80{
    margin: -20rem !important;
  }

  .\32xl\:builder--m-96{
    margin: -24rem !important;
  }

  .\32xl\:builder--m-px{
    margin: -1px !important;
  }

  .\32xl\:builder--m-0\.5{
    margin: -0.125rem !important;
  }

  .\32xl\:builder--m-1\.5{
    margin: -0.375rem !important;
  }

  .\32xl\:builder--m-2\.5{
    margin: -0.625rem !important;
  }

  .\32xl\:builder--m-3\.5{
    margin: -0.875rem !important;
  }

  .\32xl\:builder-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .\32xl\:builder-mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .\32xl\:builder-mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .\32xl\:builder-mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .\32xl\:builder-mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .\32xl\:builder-mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .\32xl\:builder-mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .\32xl\:builder-mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .\32xl\:builder-mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .\32xl\:builder-mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .\32xl\:builder-mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .\32xl\:builder-mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .\32xl\:builder-mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .\32xl\:builder-mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .\32xl\:builder-mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .\32xl\:builder-mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .\32xl\:builder-mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .\32xl\:builder-mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .\32xl\:builder-mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .\32xl\:builder-mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .\32xl\:builder-mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .\32xl\:builder-mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .\32xl\:builder-mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .\32xl\:builder-mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .\32xl\:builder-mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .\32xl\:builder-mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .\32xl\:builder-mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .\32xl\:builder-mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .\32xl\:builder-mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .\32xl\:builder-mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .\32xl\:builder-mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .\32xl\:builder-mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .\32xl\:builder-mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .\32xl\:builder-mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .\32xl\:builder-mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .\32xl\:builder-mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .\32xl\:builder--mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .\32xl\:builder--mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .\32xl\:builder--mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .\32xl\:builder--mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .\32xl\:builder--mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .\32xl\:builder--mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .\32xl\:builder--mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .\32xl\:builder--mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .\32xl\:builder--mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .\32xl\:builder--mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .\32xl\:builder--mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .\32xl\:builder--mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .\32xl\:builder--mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .\32xl\:builder--mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .\32xl\:builder--mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .\32xl\:builder--mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .\32xl\:builder--mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .\32xl\:builder--mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .\32xl\:builder--mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .\32xl\:builder--mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .\32xl\:builder--mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .\32xl\:builder--mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .\32xl\:builder--mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .\32xl\:builder--mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .\32xl\:builder--mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .\32xl\:builder--mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .\32xl\:builder--mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .\32xl\:builder--mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .\32xl\:builder--mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .\32xl\:builder--mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .\32xl\:builder--mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .\32xl\:builder--mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .\32xl\:builder--mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .\32xl\:builder--mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .\32xl\:builder--mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .\32xl\:builder-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .\32xl\:builder-my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .\32xl\:builder-my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .\32xl\:builder-my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .\32xl\:builder-my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .\32xl\:builder-my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .\32xl\:builder-my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .\32xl\:builder-my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .\32xl\:builder-my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .\32xl\:builder-my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .\32xl\:builder-my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .\32xl\:builder-my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .\32xl\:builder-my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .\32xl\:builder-my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .\32xl\:builder-my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .\32xl\:builder-my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .\32xl\:builder-my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .\32xl\:builder-my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .\32xl\:builder-my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .\32xl\:builder-my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .\32xl\:builder-my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .\32xl\:builder-my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .\32xl\:builder-my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .\32xl\:builder-my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .\32xl\:builder-my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .\32xl\:builder-my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .\32xl\:builder-my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .\32xl\:builder-my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .\32xl\:builder-my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .\32xl\:builder-my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .\32xl\:builder-my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .\32xl\:builder-my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .\32xl\:builder-my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .\32xl\:builder-my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .\32xl\:builder-my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .\32xl\:builder-my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .\32xl\:builder--my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .\32xl\:builder--my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .\32xl\:builder--my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .\32xl\:builder--my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .\32xl\:builder--my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .\32xl\:builder--my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .\32xl\:builder--my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .\32xl\:builder--my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .\32xl\:builder--my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .\32xl\:builder--my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .\32xl\:builder--my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .\32xl\:builder--my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .\32xl\:builder--my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .\32xl\:builder--my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .\32xl\:builder--my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .\32xl\:builder--my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .\32xl\:builder--my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .\32xl\:builder--my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .\32xl\:builder--my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .\32xl\:builder--my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .\32xl\:builder--my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .\32xl\:builder--my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .\32xl\:builder--my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .\32xl\:builder--my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .\32xl\:builder--my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .\32xl\:builder--my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .\32xl\:builder--my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .\32xl\:builder--my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .\32xl\:builder--my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .\32xl\:builder--my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .\32xl\:builder--my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .\32xl\:builder--my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .\32xl\:builder--my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .\32xl\:builder--my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .\32xl\:builder--my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .\32xl\:builder-mt-0{
    margin-top: 0px !important;
  }

  .\32xl\:builder-mt-1{
    margin-top: 0.25rem !important;
  }

  .\32xl\:builder-mt-2{
    margin-top: 0.5rem !important;
  }

  .\32xl\:builder-mt-3{
    margin-top: 0.75rem !important;
  }

  .\32xl\:builder-mt-4{
    margin-top: 1rem !important;
  }

  .\32xl\:builder-mt-5{
    margin-top: 1.25rem !important;
  }

  .\32xl\:builder-mt-6{
    margin-top: 1.5rem !important;
  }

  .\32xl\:builder-mt-7{
    margin-top: 1.75rem !important;
  }

  .\32xl\:builder-mt-8{
    margin-top: 2rem !important;
  }

  .\32xl\:builder-mt-9{
    margin-top: 2.25rem !important;
  }

  .\32xl\:builder-mt-10{
    margin-top: 2.5rem !important;
  }

  .\32xl\:builder-mt-11{
    margin-top: 2.75rem !important;
  }

  .\32xl\:builder-mt-12{
    margin-top: 3rem !important;
  }

  .\32xl\:builder-mt-14{
    margin-top: 3.5rem !important;
  }

  .\32xl\:builder-mt-16{
    margin-top: 4rem !important;
  }

  .\32xl\:builder-mt-20{
    margin-top: 5rem !important;
  }

  .\32xl\:builder-mt-24{
    margin-top: 6rem !important;
  }

  .\32xl\:builder-mt-28{
    margin-top: 7rem !important;
  }

  .\32xl\:builder-mt-32{
    margin-top: 8rem !important;
  }

  .\32xl\:builder-mt-36{
    margin-top: 9rem !important;
  }

  .\32xl\:builder-mt-40{
    margin-top: 10rem !important;
  }

  .\32xl\:builder-mt-44{
    margin-top: 11rem !important;
  }

  .\32xl\:builder-mt-48{
    margin-top: 12rem !important;
  }

  .\32xl\:builder-mt-52{
    margin-top: 13rem !important;
  }

  .\32xl\:builder-mt-56{
    margin-top: 14rem !important;
  }

  .\32xl\:builder-mt-60{
    margin-top: 15rem !important;
  }

  .\32xl\:builder-mt-64{
    margin-top: 16rem !important;
  }

  .\32xl\:builder-mt-72{
    margin-top: 18rem !important;
  }

  .\32xl\:builder-mt-80{
    margin-top: 20rem !important;
  }

  .\32xl\:builder-mt-96{
    margin-top: 24rem !important;
  }

  .\32xl\:builder-mt-auto{
    margin-top: auto !important;
  }

  .\32xl\:builder-mt-px{
    margin-top: 1px !important;
  }

  .\32xl\:builder-mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .\32xl\:builder-mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .\32xl\:builder-mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .\32xl\:builder-mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .\32xl\:builder--mt-0{
    margin-top: 0px !important;
  }

  .\32xl\:builder--mt-1{
    margin-top: -0.25rem !important;
  }

  .\32xl\:builder--mt-2{
    margin-top: -0.5rem !important;
  }

  .\32xl\:builder--mt-3{
    margin-top: -0.75rem !important;
  }

  .\32xl\:builder--mt-4{
    margin-top: -1rem !important;
  }

  .\32xl\:builder--mt-5{
    margin-top: -1.25rem !important;
  }

  .\32xl\:builder--mt-6{
    margin-top: -1.5rem !important;
  }

  .\32xl\:builder--mt-7{
    margin-top: -1.75rem !important;
  }

  .\32xl\:builder--mt-8{
    margin-top: -2rem !important;
  }

  .\32xl\:builder--mt-9{
    margin-top: -2.25rem !important;
  }

  .\32xl\:builder--mt-10{
    margin-top: -2.5rem !important;
  }

  .\32xl\:builder--mt-11{
    margin-top: -2.75rem !important;
  }

  .\32xl\:builder--mt-12{
    margin-top: -3rem !important;
  }

  .\32xl\:builder--mt-14{
    margin-top: -3.5rem !important;
  }

  .\32xl\:builder--mt-16{
    margin-top: -4rem !important;
  }

  .\32xl\:builder--mt-20{
    margin-top: -5rem !important;
  }

  .\32xl\:builder--mt-24{
    margin-top: -6rem !important;
  }

  .\32xl\:builder--mt-28{
    margin-top: -7rem !important;
  }

  .\32xl\:builder--mt-32{
    margin-top: -8rem !important;
  }

  .\32xl\:builder--mt-36{
    margin-top: -9rem !important;
  }

  .\32xl\:builder--mt-40{
    margin-top: -10rem !important;
  }

  .\32xl\:builder--mt-44{
    margin-top: -11rem !important;
  }

  .\32xl\:builder--mt-48{
    margin-top: -12rem !important;
  }

  .\32xl\:builder--mt-52{
    margin-top: -13rem !important;
  }

  .\32xl\:builder--mt-56{
    margin-top: -14rem !important;
  }

  .\32xl\:builder--mt-60{
    margin-top: -15rem !important;
  }

  .\32xl\:builder--mt-64{
    margin-top: -16rem !important;
  }

  .\32xl\:builder--mt-72{
    margin-top: -18rem !important;
  }

  .\32xl\:builder--mt-80{
    margin-top: -20rem !important;
  }

  .\32xl\:builder--mt-96{
    margin-top: -24rem !important;
  }

  .\32xl\:builder--mt-px{
    margin-top: -1px !important;
  }

  .\32xl\:builder--mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .\32xl\:builder--mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .\32xl\:builder--mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .\32xl\:builder--mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .\32xl\:builder-mr-0{
    margin-right: 0px !important;
  }

  .\32xl\:builder-mr-1{
    margin-right: 0.25rem !important;
  }

  .\32xl\:builder-mr-2{
    margin-right: 0.5rem !important;
  }

  .\32xl\:builder-mr-3{
    margin-right: 0.75rem !important;
  }

  .\32xl\:builder-mr-4{
    margin-right: 1rem !important;
  }

  .\32xl\:builder-mr-5{
    margin-right: 1.25rem !important;
  }

  .\32xl\:builder-mr-6{
    margin-right: 1.5rem !important;
  }

  .\32xl\:builder-mr-7{
    margin-right: 1.75rem !important;
  }

  .\32xl\:builder-mr-8{
    margin-right: 2rem !important;
  }

  .\32xl\:builder-mr-9{
    margin-right: 2.25rem !important;
  }

  .\32xl\:builder-mr-10{
    margin-right: 2.5rem !important;
  }

  .\32xl\:builder-mr-11{
    margin-right: 2.75rem !important;
  }

  .\32xl\:builder-mr-12{
    margin-right: 3rem !important;
  }

  .\32xl\:builder-mr-14{
    margin-right: 3.5rem !important;
  }

  .\32xl\:builder-mr-16{
    margin-right: 4rem !important;
  }

  .\32xl\:builder-mr-20{
    margin-right: 5rem !important;
  }

  .\32xl\:builder-mr-24{
    margin-right: 6rem !important;
  }

  .\32xl\:builder-mr-28{
    margin-right: 7rem !important;
  }

  .\32xl\:builder-mr-32{
    margin-right: 8rem !important;
  }

  .\32xl\:builder-mr-36{
    margin-right: 9rem !important;
  }

  .\32xl\:builder-mr-40{
    margin-right: 10rem !important;
  }

  .\32xl\:builder-mr-44{
    margin-right: 11rem !important;
  }

  .\32xl\:builder-mr-48{
    margin-right: 12rem !important;
  }

  .\32xl\:builder-mr-52{
    margin-right: 13rem !important;
  }

  .\32xl\:builder-mr-56{
    margin-right: 14rem !important;
  }

  .\32xl\:builder-mr-60{
    margin-right: 15rem !important;
  }

  .\32xl\:builder-mr-64{
    margin-right: 16rem !important;
  }

  .\32xl\:builder-mr-72{
    margin-right: 18rem !important;
  }

  .\32xl\:builder-mr-80{
    margin-right: 20rem !important;
  }

  .\32xl\:builder-mr-96{
    margin-right: 24rem !important;
  }

  .\32xl\:builder-mr-auto{
    margin-right: auto !important;
  }

  .\32xl\:builder-mr-px{
    margin-right: 1px !important;
  }

  .\32xl\:builder-mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .\32xl\:builder-mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .\32xl\:builder-mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .\32xl\:builder-mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .\32xl\:builder--mr-0{
    margin-right: 0px !important;
  }

  .\32xl\:builder--mr-1{
    margin-right: -0.25rem !important;
  }

  .\32xl\:builder--mr-2{
    margin-right: -0.5rem !important;
  }

  .\32xl\:builder--mr-3{
    margin-right: -0.75rem !important;
  }

  .\32xl\:builder--mr-4{
    margin-right: -1rem !important;
  }

  .\32xl\:builder--mr-5{
    margin-right: -1.25rem !important;
  }

  .\32xl\:builder--mr-6{
    margin-right: -1.5rem !important;
  }

  .\32xl\:builder--mr-7{
    margin-right: -1.75rem !important;
  }

  .\32xl\:builder--mr-8{
    margin-right: -2rem !important;
  }

  .\32xl\:builder--mr-9{
    margin-right: -2.25rem !important;
  }

  .\32xl\:builder--mr-10{
    margin-right: -2.5rem !important;
  }

  .\32xl\:builder--mr-11{
    margin-right: -2.75rem !important;
  }

  .\32xl\:builder--mr-12{
    margin-right: -3rem !important;
  }

  .\32xl\:builder--mr-14{
    margin-right: -3.5rem !important;
  }

  .\32xl\:builder--mr-16{
    margin-right: -4rem !important;
  }

  .\32xl\:builder--mr-20{
    margin-right: -5rem !important;
  }

  .\32xl\:builder--mr-24{
    margin-right: -6rem !important;
  }

  .\32xl\:builder--mr-28{
    margin-right: -7rem !important;
  }

  .\32xl\:builder--mr-32{
    margin-right: -8rem !important;
  }

  .\32xl\:builder--mr-36{
    margin-right: -9rem !important;
  }

  .\32xl\:builder--mr-40{
    margin-right: -10rem !important;
  }

  .\32xl\:builder--mr-44{
    margin-right: -11rem !important;
  }

  .\32xl\:builder--mr-48{
    margin-right: -12rem !important;
  }

  .\32xl\:builder--mr-52{
    margin-right: -13rem !important;
  }

  .\32xl\:builder--mr-56{
    margin-right: -14rem !important;
  }

  .\32xl\:builder--mr-60{
    margin-right: -15rem !important;
  }

  .\32xl\:builder--mr-64{
    margin-right: -16rem !important;
  }

  .\32xl\:builder--mr-72{
    margin-right: -18rem !important;
  }

  .\32xl\:builder--mr-80{
    margin-right: -20rem !important;
  }

  .\32xl\:builder--mr-96{
    margin-right: -24rem !important;
  }

  .\32xl\:builder--mr-px{
    margin-right: -1px !important;
  }

  .\32xl\:builder--mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .\32xl\:builder--mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .\32xl\:builder--mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .\32xl\:builder--mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .\32xl\:builder-mb-0{
    margin-bottom: 0px !important;
  }

  .\32xl\:builder-mb-1{
    margin-bottom: 0.25rem !important;
  }

  .\32xl\:builder-mb-2{
    margin-bottom: 0.5rem !important;
  }

  .\32xl\:builder-mb-3{
    margin-bottom: 0.75rem !important;
  }

  .\32xl\:builder-mb-4{
    margin-bottom: 1rem !important;
  }

  .\32xl\:builder-mb-5{
    margin-bottom: 1.25rem !important;
  }

  .\32xl\:builder-mb-6{
    margin-bottom: 1.5rem !important;
  }

  .\32xl\:builder-mb-7{
    margin-bottom: 1.75rem !important;
  }

  .\32xl\:builder-mb-8{
    margin-bottom: 2rem !important;
  }

  .\32xl\:builder-mb-9{
    margin-bottom: 2.25rem !important;
  }

  .\32xl\:builder-mb-10{
    margin-bottom: 2.5rem !important;
  }

  .\32xl\:builder-mb-11{
    margin-bottom: 2.75rem !important;
  }

  .\32xl\:builder-mb-12{
    margin-bottom: 3rem !important;
  }

  .\32xl\:builder-mb-14{
    margin-bottom: 3.5rem !important;
  }

  .\32xl\:builder-mb-16{
    margin-bottom: 4rem !important;
  }

  .\32xl\:builder-mb-20{
    margin-bottom: 5rem !important;
  }

  .\32xl\:builder-mb-24{
    margin-bottom: 6rem !important;
  }

  .\32xl\:builder-mb-28{
    margin-bottom: 7rem !important;
  }

  .\32xl\:builder-mb-32{
    margin-bottom: 8rem !important;
  }

  .\32xl\:builder-mb-36{
    margin-bottom: 9rem !important;
  }

  .\32xl\:builder-mb-40{
    margin-bottom: 10rem !important;
  }

  .\32xl\:builder-mb-44{
    margin-bottom: 11rem !important;
  }

  .\32xl\:builder-mb-48{
    margin-bottom: 12rem !important;
  }

  .\32xl\:builder-mb-52{
    margin-bottom: 13rem !important;
  }

  .\32xl\:builder-mb-56{
    margin-bottom: 14rem !important;
  }

  .\32xl\:builder-mb-60{
    margin-bottom: 15rem !important;
  }

  .\32xl\:builder-mb-64{
    margin-bottom: 16rem !important;
  }

  .\32xl\:builder-mb-72{
    margin-bottom: 18rem !important;
  }

  .\32xl\:builder-mb-80{
    margin-bottom: 20rem !important;
  }

  .\32xl\:builder-mb-96{
    margin-bottom: 24rem !important;
  }

  .\32xl\:builder-mb-auto{
    margin-bottom: auto !important;
  }

  .\32xl\:builder-mb-px{
    margin-bottom: 1px !important;
  }

  .\32xl\:builder-mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .\32xl\:builder-mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .\32xl\:builder-mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .\32xl\:builder-mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .\32xl\:builder--mb-0{
    margin-bottom: 0px !important;
  }

  .\32xl\:builder--mb-1{
    margin-bottom: -0.25rem !important;
  }

  .\32xl\:builder--mb-2{
    margin-bottom: -0.5rem !important;
  }

  .\32xl\:builder--mb-3{
    margin-bottom: -0.75rem !important;
  }

  .\32xl\:builder--mb-4{
    margin-bottom: -1rem !important;
  }

  .\32xl\:builder--mb-5{
    margin-bottom: -1.25rem !important;
  }

  .\32xl\:builder--mb-6{
    margin-bottom: -1.5rem !important;
  }

  .\32xl\:builder--mb-7{
    margin-bottom: -1.75rem !important;
  }

  .\32xl\:builder--mb-8{
    margin-bottom: -2rem !important;
  }

  .\32xl\:builder--mb-9{
    margin-bottom: -2.25rem !important;
  }

  .\32xl\:builder--mb-10{
    margin-bottom: -2.5rem !important;
  }

  .\32xl\:builder--mb-11{
    margin-bottom: -2.75rem !important;
  }

  .\32xl\:builder--mb-12{
    margin-bottom: -3rem !important;
  }

  .\32xl\:builder--mb-14{
    margin-bottom: -3.5rem !important;
  }

  .\32xl\:builder--mb-16{
    margin-bottom: -4rem !important;
  }

  .\32xl\:builder--mb-20{
    margin-bottom: -5rem !important;
  }

  .\32xl\:builder--mb-24{
    margin-bottom: -6rem !important;
  }

  .\32xl\:builder--mb-28{
    margin-bottom: -7rem !important;
  }

  .\32xl\:builder--mb-32{
    margin-bottom: -8rem !important;
  }

  .\32xl\:builder--mb-36{
    margin-bottom: -9rem !important;
  }

  .\32xl\:builder--mb-40{
    margin-bottom: -10rem !important;
  }

  .\32xl\:builder--mb-44{
    margin-bottom: -11rem !important;
  }

  .\32xl\:builder--mb-48{
    margin-bottom: -12rem !important;
  }

  .\32xl\:builder--mb-52{
    margin-bottom: -13rem !important;
  }

  .\32xl\:builder--mb-56{
    margin-bottom: -14rem !important;
  }

  .\32xl\:builder--mb-60{
    margin-bottom: -15rem !important;
  }

  .\32xl\:builder--mb-64{
    margin-bottom: -16rem !important;
  }

  .\32xl\:builder--mb-72{
    margin-bottom: -18rem !important;
  }

  .\32xl\:builder--mb-80{
    margin-bottom: -20rem !important;
  }

  .\32xl\:builder--mb-96{
    margin-bottom: -24rem !important;
  }

  .\32xl\:builder--mb-px{
    margin-bottom: -1px !important;
  }

  .\32xl\:builder--mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .\32xl\:builder--mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .\32xl\:builder--mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .\32xl\:builder--mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .\32xl\:builder-ml-0{
    margin-left: 0px !important;
  }

  .\32xl\:builder-ml-1{
    margin-left: 0.25rem !important;
  }

  .\32xl\:builder-ml-2{
    margin-left: 0.5rem !important;
  }

  .\32xl\:builder-ml-3{
    margin-left: 0.75rem !important;
  }

  .\32xl\:builder-ml-4{
    margin-left: 1rem !important;
  }

  .\32xl\:builder-ml-5{
    margin-left: 1.25rem !important;
  }

  .\32xl\:builder-ml-6{
    margin-left: 1.5rem !important;
  }

  .\32xl\:builder-ml-7{
    margin-left: 1.75rem !important;
  }

  .\32xl\:builder-ml-8{
    margin-left: 2rem !important;
  }

  .\32xl\:builder-ml-9{
    margin-left: 2.25rem !important;
  }

  .\32xl\:builder-ml-10{
    margin-left: 2.5rem !important;
  }

  .\32xl\:builder-ml-11{
    margin-left: 2.75rem !important;
  }

  .\32xl\:builder-ml-12{
    margin-left: 3rem !important;
  }

  .\32xl\:builder-ml-14{
    margin-left: 3.5rem !important;
  }

  .\32xl\:builder-ml-16{
    margin-left: 4rem !important;
  }

  .\32xl\:builder-ml-20{
    margin-left: 5rem !important;
  }

  .\32xl\:builder-ml-24{
    margin-left: 6rem !important;
  }

  .\32xl\:builder-ml-28{
    margin-left: 7rem !important;
  }

  .\32xl\:builder-ml-32{
    margin-left: 8rem !important;
  }

  .\32xl\:builder-ml-36{
    margin-left: 9rem !important;
  }

  .\32xl\:builder-ml-40{
    margin-left: 10rem !important;
  }

  .\32xl\:builder-ml-44{
    margin-left: 11rem !important;
  }

  .\32xl\:builder-ml-48{
    margin-left: 12rem !important;
  }

  .\32xl\:builder-ml-52{
    margin-left: 13rem !important;
  }

  .\32xl\:builder-ml-56{
    margin-left: 14rem !important;
  }

  .\32xl\:builder-ml-60{
    margin-left: 15rem !important;
  }

  .\32xl\:builder-ml-64{
    margin-left: 16rem !important;
  }

  .\32xl\:builder-ml-72{
    margin-left: 18rem !important;
  }

  .\32xl\:builder-ml-80{
    margin-left: 20rem !important;
  }

  .\32xl\:builder-ml-96{
    margin-left: 24rem !important;
  }

  .\32xl\:builder-ml-auto{
    margin-left: auto !important;
  }

  .\32xl\:builder-ml-px{
    margin-left: 1px !important;
  }

  .\32xl\:builder-ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .\32xl\:builder-ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .\32xl\:builder-ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .\32xl\:builder-ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .\32xl\:builder--ml-0{
    margin-left: 0px !important;
  }

  .\32xl\:builder--ml-1{
    margin-left: -0.25rem !important;
  }

  .\32xl\:builder--ml-2{
    margin-left: -0.5rem !important;
  }

  .\32xl\:builder--ml-3{
    margin-left: -0.75rem !important;
  }

  .\32xl\:builder--ml-4{
    margin-left: -1rem !important;
  }

  .\32xl\:builder--ml-5{
    margin-left: -1.25rem !important;
  }

  .\32xl\:builder--ml-6{
    margin-left: -1.5rem !important;
  }

  .\32xl\:builder--ml-7{
    margin-left: -1.75rem !important;
  }

  .\32xl\:builder--ml-8{
    margin-left: -2rem !important;
  }

  .\32xl\:builder--ml-9{
    margin-left: -2.25rem !important;
  }

  .\32xl\:builder--ml-10{
    margin-left: -2.5rem !important;
  }

  .\32xl\:builder--ml-11{
    margin-left: -2.75rem !important;
  }

  .\32xl\:builder--ml-12{
    margin-left: -3rem !important;
  }

  .\32xl\:builder--ml-14{
    margin-left: -3.5rem !important;
  }

  .\32xl\:builder--ml-16{
    margin-left: -4rem !important;
  }

  .\32xl\:builder--ml-20{
    margin-left: -5rem !important;
  }

  .\32xl\:builder--ml-24{
    margin-left: -6rem !important;
  }

  .\32xl\:builder--ml-28{
    margin-left: -7rem !important;
  }

  .\32xl\:builder--ml-32{
    margin-left: -8rem !important;
  }

  .\32xl\:builder--ml-36{
    margin-left: -9rem !important;
  }

  .\32xl\:builder--ml-40{
    margin-left: -10rem !important;
  }

  .\32xl\:builder--ml-44{
    margin-left: -11rem !important;
  }

  .\32xl\:builder--ml-48{
    margin-left: -12rem !important;
  }

  .\32xl\:builder--ml-52{
    margin-left: -13rem !important;
  }

  .\32xl\:builder--ml-56{
    margin-left: -14rem !important;
  }

  .\32xl\:builder--ml-60{
    margin-left: -15rem !important;
  }

  .\32xl\:builder--ml-64{
    margin-left: -16rem !important;
  }

  .\32xl\:builder--ml-72{
    margin-left: -18rem !important;
  }

  .\32xl\:builder--ml-80{
    margin-left: -20rem !important;
  }

  .\32xl\:builder--ml-96{
    margin-left: -24rem !important;
  }

  .\32xl\:builder--ml-px{
    margin-left: -1px !important;
  }

  .\32xl\:builder--ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .\32xl\:builder--ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .\32xl\:builder--ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .\32xl\:builder--ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .\32xl\:builder-box-border{
    box-sizing: border-box !important;
  }

  .\32xl\:builder-box-content{
    box-sizing: content-box !important;
  }

  .\32xl\:builder-block{
    display: block !important;
  }

  .\32xl\:builder-inline-block{
    display: inline-block !important;
  }

  .\32xl\:builder-inline{
    display: inline !important;
  }

  .\32xl\:builder-flex{
    display: flex !important;
  }

  .\32xl\:builder-inline-flex{
    display: inline-flex !important;
  }

  .\32xl\:builder-table{
    display: table !important;
  }

  .\32xl\:builder-inline-table{
    display: inline-table !important;
  }

  .\32xl\:builder-table-caption{
    display: table-caption !important;
  }

  .\32xl\:builder-table-cell{
    display: table-cell !important;
  }

  .\32xl\:builder-table-column{
    display: table-column !important;
  }

  .\32xl\:builder-table-column-group{
    display: table-column-group !important;
  }

  .\32xl\:builder-table-footer-group{
    display: table-footer-group !important;
  }

  .\32xl\:builder-table-header-group{
    display: table-header-group !important;
  }

  .\32xl\:builder-table-row-group{
    display: table-row-group !important;
  }

  .\32xl\:builder-table-row{
    display: table-row !important;
  }

  .\32xl\:builder-flow-root{
    display: flow-root !important;
  }

  .\32xl\:builder-grid{
    display: grid !important;
  }

  .\32xl\:builder-inline-grid{
    display: inline-grid !important;
  }

  .\32xl\:builder-contents{
    display: contents !important;
  }

  .\32xl\:builder-list-item{
    display: list-item !important;
  }

  .\32xl\:builder-hidden{
    display: none !important;
  }

  .\32xl\:builder-h-0{
    height: 0px !important;
  }

  .\32xl\:builder-h-1{
    height: 0.25rem !important;
  }

  .\32xl\:builder-h-2{
    height: 0.5rem !important;
  }

  .\32xl\:builder-h-3{
    height: 0.75rem !important;
  }

  .\32xl\:builder-h-4{
    height: 1rem !important;
  }

  .\32xl\:builder-h-5{
    height: 1.25rem !important;
  }

  .\32xl\:builder-h-6{
    height: 1.5rem !important;
  }

  .\32xl\:builder-h-7{
    height: 1.75rem !important;
  }

  .\32xl\:builder-h-8{
    height: 2rem !important;
  }

  .\32xl\:builder-h-9{
    height: 2.25rem !important;
  }

  .\32xl\:builder-h-10{
    height: 2.5rem !important;
  }

  .\32xl\:builder-h-11{
    height: 2.75rem !important;
  }

  .\32xl\:builder-h-12{
    height: 3rem !important;
  }

  .\32xl\:builder-h-14{
    height: 3.5rem !important;
  }

  .\32xl\:builder-h-16{
    height: 4rem !important;
  }

  .\32xl\:builder-h-20{
    height: 5rem !important;
  }

  .\32xl\:builder-h-24{
    height: 6rem !important;
  }

  .\32xl\:builder-h-28{
    height: 7rem !important;
  }

  .\32xl\:builder-h-32{
    height: 8rem !important;
  }

  .\32xl\:builder-h-36{
    height: 9rem !important;
  }

  .\32xl\:builder-h-40{
    height: 10rem !important;
  }

  .\32xl\:builder-h-44{
    height: 11rem !important;
  }

  .\32xl\:builder-h-48{
    height: 12rem !important;
  }

  .\32xl\:builder-h-52{
    height: 13rem !important;
  }

  .\32xl\:builder-h-56{
    height: 14rem !important;
  }

  .\32xl\:builder-h-60{
    height: 15rem !important;
  }

  .\32xl\:builder-h-64{
    height: 16rem !important;
  }

  .\32xl\:builder-h-72{
    height: 18rem !important;
  }

  .\32xl\:builder-h-80{
    height: 20rem !important;
  }

  .\32xl\:builder-h-96{
    height: 24rem !important;
  }

  .\32xl\:builder-h-auto{
    height: auto !important;
  }

  .\32xl\:builder-h-px{
    height: 1px !important;
  }

  .\32xl\:builder-h-0\.5{
    height: 0.125rem !important;
  }

  .\32xl\:builder-h-1\.5{
    height: 0.375rem !important;
  }

  .\32xl\:builder-h-2\.5{
    height: 0.625rem !important;
  }

  .\32xl\:builder-h-3\.5{
    height: 0.875rem !important;
  }

  .\32xl\:builder-h-1\/2{
    height: 50% !important;
  }

  .\32xl\:builder-h-1\/3{
    height: 33.333333% !important;
  }

  .\32xl\:builder-h-2\/3{
    height: 66.666667% !important;
  }

  .\32xl\:builder-h-1\/4{
    height: 25% !important;
  }

  .\32xl\:builder-h-2\/4{
    height: 50% !important;
  }

  .\32xl\:builder-h-3\/4{
    height: 75% !important;
  }

  .\32xl\:builder-h-1\/5{
    height: 20% !important;
  }

  .\32xl\:builder-h-2\/5{
    height: 40% !important;
  }

  .\32xl\:builder-h-3\/5{
    height: 60% !important;
  }

  .\32xl\:builder-h-4\/5{
    height: 80% !important;
  }

  .\32xl\:builder-h-1\/6{
    height: 16.666667% !important;
  }

  .\32xl\:builder-h-2\/6{
    height: 33.333333% !important;
  }

  .\32xl\:builder-h-3\/6{
    height: 50% !important;
  }

  .\32xl\:builder-h-4\/6{
    height: 66.666667% !important;
  }

  .\32xl\:builder-h-5\/6{
    height: 83.333333% !important;
  }

  .\32xl\:builder-h-full{
    height: 100% !important;
  }

  .\32xl\:builder-h-screen{
    height: 100vh !important;
  }

  .\32xl\:builder-max-h-0{
    max-height: 0px !important;
  }

  .\32xl\:builder-max-h-1{
    max-height: 0.25rem !important;
  }

  .\32xl\:builder-max-h-2{
    max-height: 0.5rem !important;
  }

  .\32xl\:builder-max-h-3{
    max-height: 0.75rem !important;
  }

  .\32xl\:builder-max-h-4{
    max-height: 1rem !important;
  }

  .\32xl\:builder-max-h-5{
    max-height: 1.25rem !important;
  }

  .\32xl\:builder-max-h-6{
    max-height: 1.5rem !important;
  }

  .\32xl\:builder-max-h-7{
    max-height: 1.75rem !important;
  }

  .\32xl\:builder-max-h-8{
    max-height: 2rem !important;
  }

  .\32xl\:builder-max-h-9{
    max-height: 2.25rem !important;
  }

  .\32xl\:builder-max-h-10{
    max-height: 2.5rem !important;
  }

  .\32xl\:builder-max-h-11{
    max-height: 2.75rem !important;
  }

  .\32xl\:builder-max-h-12{
    max-height: 3rem !important;
  }

  .\32xl\:builder-max-h-14{
    max-height: 3.5rem !important;
  }

  .\32xl\:builder-max-h-16{
    max-height: 4rem !important;
  }

  .\32xl\:builder-max-h-20{
    max-height: 5rem !important;
  }

  .\32xl\:builder-max-h-24{
    max-height: 6rem !important;
  }

  .\32xl\:builder-max-h-28{
    max-height: 7rem !important;
  }

  .\32xl\:builder-max-h-32{
    max-height: 8rem !important;
  }

  .\32xl\:builder-max-h-36{
    max-height: 9rem !important;
  }

  .\32xl\:builder-max-h-40{
    max-height: 10rem !important;
  }

  .\32xl\:builder-max-h-44{
    max-height: 11rem !important;
  }

  .\32xl\:builder-max-h-48{
    max-height: 12rem !important;
  }

  .\32xl\:builder-max-h-52{
    max-height: 13rem !important;
  }

  .\32xl\:builder-max-h-56{
    max-height: 14rem !important;
  }

  .\32xl\:builder-max-h-60{
    max-height: 15rem !important;
  }

  .\32xl\:builder-max-h-64{
    max-height: 16rem !important;
  }

  .\32xl\:builder-max-h-72{
    max-height: 18rem !important;
  }

  .\32xl\:builder-max-h-80{
    max-height: 20rem !important;
  }

  .\32xl\:builder-max-h-96{
    max-height: 24rem !important;
  }

  .\32xl\:builder-max-h-px{
    max-height: 1px !important;
  }

  .\32xl\:builder-max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .\32xl\:builder-max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .\32xl\:builder-max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .\32xl\:builder-max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .\32xl\:builder-max-h-full{
    max-height: 100% !important;
  }

  .\32xl\:builder-max-h-screen{
    max-height: 100vh !important;
  }

  .\32xl\:builder-min-h-0{
    min-height: 0px !important;
  }

  .\32xl\:builder-min-h-full{
    min-height: 100% !important;
  }

  .\32xl\:builder-min-h-screen{
    min-height: 100vh !important;
  }

  .\32xl\:builder-w-0{
    width: 0px !important;
  }

  .\32xl\:builder-w-1{
    width: 0.25rem !important;
  }

  .\32xl\:builder-w-2{
    width: 0.5rem !important;
  }

  .\32xl\:builder-w-3{
    width: 0.75rem !important;
  }

  .\32xl\:builder-w-4{
    width: 1rem !important;
  }

  .\32xl\:builder-w-5{
    width: 1.25rem !important;
  }

  .\32xl\:builder-w-6{
    width: 1.5rem !important;
  }

  .\32xl\:builder-w-7{
    width: 1.75rem !important;
  }

  .\32xl\:builder-w-8{
    width: 2rem !important;
  }

  .\32xl\:builder-w-9{
    width: 2.25rem !important;
  }

  .\32xl\:builder-w-10{
    width: 2.5rem !important;
  }

  .\32xl\:builder-w-11{
    width: 2.75rem !important;
  }

  .\32xl\:builder-w-12{
    width: 3rem !important;
  }

  .\32xl\:builder-w-14{
    width: 3.5rem !important;
  }

  .\32xl\:builder-w-16{
    width: 4rem !important;
  }

  .\32xl\:builder-w-20{
    width: 5rem !important;
  }

  .\32xl\:builder-w-24{
    width: 6rem !important;
  }

  .\32xl\:builder-w-28{
    width: 7rem !important;
  }

  .\32xl\:builder-w-32{
    width: 8rem !important;
  }

  .\32xl\:builder-w-36{
    width: 9rem !important;
  }

  .\32xl\:builder-w-40{
    width: 10rem !important;
  }

  .\32xl\:builder-w-44{
    width: 11rem !important;
  }

  .\32xl\:builder-w-48{
    width: 12rem !important;
  }

  .\32xl\:builder-w-52{
    width: 13rem !important;
  }

  .\32xl\:builder-w-56{
    width: 14rem !important;
  }

  .\32xl\:builder-w-60{
    width: 15rem !important;
  }

  .\32xl\:builder-w-64{
    width: 16rem !important;
  }

  .\32xl\:builder-w-72{
    width: 18rem !important;
  }

  .\32xl\:builder-w-80{
    width: 20rem !important;
  }

  .\32xl\:builder-w-96{
    width: 24rem !important;
  }

  .\32xl\:builder-w-auto{
    width: auto !important;
  }

  .\32xl\:builder-w-px{
    width: 1px !important;
  }

  .\32xl\:builder-w-0\.5{
    width: 0.125rem !important;
  }

  .\32xl\:builder-w-1\.5{
    width: 0.375rem !important;
  }

  .\32xl\:builder-w-2\.5{
    width: 0.625rem !important;
  }

  .\32xl\:builder-w-3\.5{
    width: 0.875rem !important;
  }

  .\32xl\:builder-w-1\/2{
    width: 50% !important;
  }

  .\32xl\:builder-w-1\/3{
    width: 33.333333% !important;
  }

  .\32xl\:builder-w-2\/3{
    width: 66.666667% !important;
  }

  .\32xl\:builder-w-1\/4{
    width: 25% !important;
  }

  .\32xl\:builder-w-2\/4{
    width: 50% !important;
  }

  .\32xl\:builder-w-3\/4{
    width: 75% !important;
  }

  .\32xl\:builder-w-1\/5{
    width: 20% !important;
  }

  .\32xl\:builder-w-2\/5{
    width: 40% !important;
  }

  .\32xl\:builder-w-3\/5{
    width: 60% !important;
  }

  .\32xl\:builder-w-4\/5{
    width: 80% !important;
  }

  .\32xl\:builder-w-1\/6{
    width: 16.666667% !important;
  }

  .\32xl\:builder-w-2\/6{
    width: 33.333333% !important;
  }

  .\32xl\:builder-w-3\/6{
    width: 50% !important;
  }

  .\32xl\:builder-w-4\/6{
    width: 66.666667% !important;
  }

  .\32xl\:builder-w-5\/6{
    width: 83.333333% !important;
  }

  .\32xl\:builder-w-1\/12{
    width: 8.333333% !important;
  }

  .\32xl\:builder-w-2\/12{
    width: 16.666667% !important;
  }

  .\32xl\:builder-w-3\/12{
    width: 25% !important;
  }

  .\32xl\:builder-w-4\/12{
    width: 33.333333% !important;
  }

  .\32xl\:builder-w-5\/12{
    width: 41.666667% !important;
  }

  .\32xl\:builder-w-6\/12{
    width: 50% !important;
  }

  .\32xl\:builder-w-7\/12{
    width: 58.333333% !important;
  }

  .\32xl\:builder-w-8\/12{
    width: 66.666667% !important;
  }

  .\32xl\:builder-w-9\/12{
    width: 75% !important;
  }

  .\32xl\:builder-w-10\/12{
    width: 83.333333% !important;
  }

  .\32xl\:builder-w-11\/12{
    width: 91.666667% !important;
  }

  .\32xl\:builder-w-full{
    width: 100% !important;
  }

  .\32xl\:builder-w-screen{
    width: 100vw !important;
  }

  .\32xl\:builder-w-min{
    width: min-content !important;
  }

  .\32xl\:builder-w-max{
    width: max-content !important;
  }

  .\32xl\:builder-min-w-0{
    min-width: 0px !important;
  }

  .\32xl\:builder-min-w-full{
    min-width: 100% !important;
  }

  .\32xl\:builder-min-w-min{
    min-width: min-content !important;
  }

  .\32xl\:builder-min-w-max{
    min-width: max-content !important;
  }

  .\32xl\:builder-max-w-0{
    max-width: 0rem !important;
  }

  .\32xl\:builder-max-w-none{
    max-width: none !important;
  }

  .\32xl\:builder-max-w-xs{
    max-width: 20rem !important;
  }

  .\32xl\:builder-max-w-sm{
    max-width: 24rem !important;
  }

  .\32xl\:builder-max-w-md{
    max-width: 28rem !important;
  }

  .\32xl\:builder-max-w-lg{
    max-width: 32rem !important;
  }

  .\32xl\:builder-max-w-xl{
    max-width: 36rem !important;
  }

  .\32xl\:builder-max-w-2xl{
    max-width: 42rem !important;
  }

  .\32xl\:builder-max-w-3xl{
    max-width: 48rem !important;
  }

  .\32xl\:builder-max-w-4xl{
    max-width: 56rem !important;
  }

  .\32xl\:builder-max-w-5xl{
    max-width: 64rem !important;
  }

  .\32xl\:builder-max-w-6xl{
    max-width: 72rem !important;
  }

  .\32xl\:builder-max-w-7xl{
    max-width: 80rem !important;
  }

  .\32xl\:builder-max-w-full{
    max-width: 100% !important;
  }

  .\32xl\:builder-max-w-min{
    max-width: min-content !important;
  }

  .\32xl\:builder-max-w-max{
    max-width: max-content !important;
  }

  .\32xl\:builder-max-w-prose{
    max-width: 65ch !important;
  }

  .\32xl\:builder-max-w-screen-sm{
    max-width: 640px !important;
  }

  .\32xl\:builder-max-w-screen-md{
    max-width: 768px !important;
  }

  .\32xl\:builder-max-w-screen-lg{
    max-width: 1024px !important;
  }

  .\32xl\:builder-max-w-screen-xl{
    max-width: 1280px !important;
  }

  .\32xl\:builder-max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .\32xl\:builder-flex-1{
    flex: 1 1 0% !important;
  }

  .\32xl\:builder-flex-auto{
    flex: 1 1 auto !important;
  }

  .\32xl\:builder-flex-initial{
    flex: 0 1 auto !important;
  }

  .\32xl\:builder-flex-none{
    flex: none !important;
  }

  .\32xl\:builder-flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .\32xl\:builder-flex-shrink{
    flex-shrink: 1 !important;
  }

  .\32xl\:builder-flex-grow-0{
    flex-grow: 0 !important;
  }

  .\32xl\:builder-flex-grow{
    flex-grow: 1 !important;
  }

  .\32xl\:builder-table-auto{
    table-layout: auto !important;
  }

  .\32xl\:builder-table-fixed{
    table-layout: fixed !important;
  }

  .\32xl\:builder-border-collapse{
    border-collapse: collapse !important;
  }

  .\32xl\:builder-border-separate{
    border-collapse: separate !important;
  }

  .\32xl\:builder-origin-center{
    transform-origin: center !important;
  }

  .\32xl\:builder-origin-top{
    transform-origin: top !important;
  }

  .\32xl\:builder-origin-top-right{
    transform-origin: top right !important;
  }

  .\32xl\:builder-origin-right{
    transform-origin: right !important;
  }

  .\32xl\:builder-origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .\32xl\:builder-origin-bottom{
    transform-origin: bottom !important;
  }

  .\32xl\:builder-origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .\32xl\:builder-origin-left{
    transform-origin: left !important;
  }

  .\32xl\:builder-origin-top-left{
    transform-origin: top left !important;
  }

  .\32xl\:builder-transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\32xl\:builder-transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\32xl\:builder-transform-none{
    transform: none !important;
  }

  .\32xl\:builder-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:builder-translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .\32xl\:builder-translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .\32xl\:builder-translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .\32xl\:builder-translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .\32xl\:builder-translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .\32xl\:builder-translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .\32xl\:builder-translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .\32xl\:builder-translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .\32xl\:builder-translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .\32xl\:builder-translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .\32xl\:builder-translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .\32xl\:builder-translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .\32xl\:builder-translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .\32xl\:builder-translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .\32xl\:builder-translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .\32xl\:builder-translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .\32xl\:builder-translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .\32xl\:builder-translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .\32xl\:builder-translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .\32xl\:builder-translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .\32xl\:builder-translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .\32xl\:builder-translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .\32xl\:builder-translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .\32xl\:builder-translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .\32xl\:builder-translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .\32xl\:builder-translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .\32xl\:builder-translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .\32xl\:builder-translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .\32xl\:builder-translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .\32xl\:builder-translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .\32xl\:builder-translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .\32xl\:builder-translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .\32xl\:builder-translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .\32xl\:builder-translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .\32xl\:builder--translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:builder--translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .\32xl\:builder--translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .\32xl\:builder--translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .\32xl\:builder--translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .\32xl\:builder--translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .\32xl\:builder--translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .\32xl\:builder--translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .\32xl\:builder--translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .\32xl\:builder--translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .\32xl\:builder--translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .\32xl\:builder--translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .\32xl\:builder--translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .\32xl\:builder--translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .\32xl\:builder--translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .\32xl\:builder--translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .\32xl\:builder--translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .\32xl\:builder--translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .\32xl\:builder--translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .\32xl\:builder--translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .\32xl\:builder--translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .\32xl\:builder--translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .\32xl\:builder--translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .\32xl\:builder--translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .\32xl\:builder--translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .\32xl\:builder--translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .\32xl\:builder--translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .\32xl\:builder--translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .\32xl\:builder--translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .\32xl\:builder--translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .\32xl\:builder--translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .\32xl\:builder--translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .\32xl\:builder--translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .\32xl\:builder--translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .\32xl\:builder--translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .\32xl\:builder-translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:builder-translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .\32xl\:builder-translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .\32xl\:builder-translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .\32xl\:builder-translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:builder-translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .\32xl\:builder-translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .\32xl\:builder--translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:builder--translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .\32xl\:builder--translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .\32xl\:builder--translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .\32xl\:builder--translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:builder--translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .\32xl\:builder--translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .\32xl\:builder-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:builder-translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .\32xl\:builder-translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .\32xl\:builder-translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .\32xl\:builder-translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .\32xl\:builder-translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .\32xl\:builder-translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .\32xl\:builder-translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .\32xl\:builder-translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .\32xl\:builder-translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .\32xl\:builder-translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .\32xl\:builder-translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .\32xl\:builder-translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .\32xl\:builder-translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .\32xl\:builder-translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .\32xl\:builder-translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .\32xl\:builder-translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .\32xl\:builder-translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .\32xl\:builder-translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .\32xl\:builder-translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .\32xl\:builder-translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .\32xl\:builder-translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .\32xl\:builder-translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .\32xl\:builder-translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .\32xl\:builder-translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .\32xl\:builder-translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .\32xl\:builder-translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .\32xl\:builder-translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .\32xl\:builder-translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .\32xl\:builder-translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .\32xl\:builder-translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .\32xl\:builder-translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .\32xl\:builder-translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .\32xl\:builder-translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .\32xl\:builder-translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .\32xl\:builder--translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:builder--translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .\32xl\:builder--translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .\32xl\:builder--translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .\32xl\:builder--translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .\32xl\:builder--translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .\32xl\:builder--translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .\32xl\:builder--translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .\32xl\:builder--translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .\32xl\:builder--translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .\32xl\:builder--translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .\32xl\:builder--translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .\32xl\:builder--translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .\32xl\:builder--translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .\32xl\:builder--translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .\32xl\:builder--translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .\32xl\:builder--translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .\32xl\:builder--translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .\32xl\:builder--translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .\32xl\:builder--translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .\32xl\:builder--translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .\32xl\:builder--translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .\32xl\:builder--translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .\32xl\:builder--translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .\32xl\:builder--translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .\32xl\:builder--translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .\32xl\:builder--translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .\32xl\:builder--translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .\32xl\:builder--translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .\32xl\:builder--translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .\32xl\:builder--translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .\32xl\:builder--translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .\32xl\:builder--translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .\32xl\:builder--translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .\32xl\:builder--translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .\32xl\:builder-translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:builder-translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .\32xl\:builder-translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .\32xl\:builder-translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .\32xl\:builder-translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:builder-translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .\32xl\:builder-translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .\32xl\:builder--translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:builder--translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .\32xl\:builder--translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .\32xl\:builder--translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .\32xl\:builder--translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:builder--translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .\32xl\:builder--translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .\32xl\:hover\:builder-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:hover\:builder-translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .\32xl\:hover\:builder-translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .\32xl\:hover\:builder-translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .\32xl\:hover\:builder-translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .\32xl\:hover\:builder-translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .\32xl\:hover\:builder-translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .\32xl\:hover\:builder-translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .\32xl\:hover\:builder-translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .\32xl\:hover\:builder-translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .\32xl\:hover\:builder-translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .\32xl\:hover\:builder-translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .\32xl\:hover\:builder-translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .\32xl\:hover\:builder-translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .\32xl\:hover\:builder-translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .\32xl\:hover\:builder-translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .\32xl\:hover\:builder-translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .\32xl\:hover\:builder-translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .\32xl\:hover\:builder-translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .\32xl\:hover\:builder-translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .\32xl\:hover\:builder-translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .\32xl\:hover\:builder-translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .\32xl\:hover\:builder-translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .\32xl\:hover\:builder-translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .\32xl\:hover\:builder-translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .\32xl\:hover\:builder-translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .\32xl\:hover\:builder-translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .\32xl\:hover\:builder-translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .\32xl\:hover\:builder-translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .\32xl\:hover\:builder-translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .\32xl\:hover\:builder-translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .\32xl\:hover\:builder-translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .\32xl\:hover\:builder-translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .\32xl\:hover\:builder-translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .\32xl\:hover\:builder-translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .\32xl\:hover\:builder--translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:hover\:builder--translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .\32xl\:hover\:builder--translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .\32xl\:hover\:builder--translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .\32xl\:hover\:builder--translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .\32xl\:hover\:builder--translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .\32xl\:hover\:builder--translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .\32xl\:hover\:builder--translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .\32xl\:hover\:builder--translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .\32xl\:hover\:builder--translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .\32xl\:hover\:builder--translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .\32xl\:hover\:builder--translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .\32xl\:hover\:builder--translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .\32xl\:hover\:builder--translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .\32xl\:hover\:builder--translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .\32xl\:hover\:builder--translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .\32xl\:hover\:builder--translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .\32xl\:hover\:builder--translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .\32xl\:hover\:builder--translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .\32xl\:hover\:builder--translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .\32xl\:hover\:builder--translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .\32xl\:hover\:builder--translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .\32xl\:hover\:builder--translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .\32xl\:hover\:builder--translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .\32xl\:hover\:builder--translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .\32xl\:hover\:builder--translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .\32xl\:hover\:builder--translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .\32xl\:hover\:builder--translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .\32xl\:hover\:builder--translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .\32xl\:hover\:builder--translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .\32xl\:hover\:builder--translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .\32xl\:hover\:builder--translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .\32xl\:hover\:builder--translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .\32xl\:hover\:builder--translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .\32xl\:hover\:builder--translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .\32xl\:hover\:builder-translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:hover\:builder-translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .\32xl\:hover\:builder-translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .\32xl\:hover\:builder-translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .\32xl\:hover\:builder-translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:hover\:builder-translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .\32xl\:hover\:builder-translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .\32xl\:hover\:builder--translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:hover\:builder--translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .\32xl\:hover\:builder--translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .\32xl\:hover\:builder--translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .\32xl\:hover\:builder--translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:hover\:builder--translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .\32xl\:hover\:builder--translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .\32xl\:hover\:builder-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:hover\:builder-translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .\32xl\:hover\:builder-translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .\32xl\:hover\:builder-translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .\32xl\:hover\:builder-translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .\32xl\:hover\:builder-translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .\32xl\:hover\:builder-translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .\32xl\:hover\:builder-translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .\32xl\:hover\:builder-translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .\32xl\:hover\:builder-translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .\32xl\:hover\:builder-translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .\32xl\:hover\:builder-translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .\32xl\:hover\:builder-translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .\32xl\:hover\:builder-translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .\32xl\:hover\:builder-translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .\32xl\:hover\:builder-translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .\32xl\:hover\:builder-translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .\32xl\:hover\:builder-translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .\32xl\:hover\:builder-translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .\32xl\:hover\:builder-translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .\32xl\:hover\:builder-translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .\32xl\:hover\:builder-translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .\32xl\:hover\:builder-translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .\32xl\:hover\:builder-translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .\32xl\:hover\:builder-translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .\32xl\:hover\:builder-translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .\32xl\:hover\:builder-translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .\32xl\:hover\:builder-translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .\32xl\:hover\:builder-translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .\32xl\:hover\:builder-translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .\32xl\:hover\:builder-translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .\32xl\:hover\:builder-translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .\32xl\:hover\:builder-translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .\32xl\:hover\:builder-translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .\32xl\:hover\:builder-translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .\32xl\:hover\:builder--translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:hover\:builder--translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .\32xl\:hover\:builder--translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .\32xl\:hover\:builder--translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .\32xl\:hover\:builder--translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .\32xl\:hover\:builder--translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .\32xl\:hover\:builder--translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .\32xl\:hover\:builder--translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .\32xl\:hover\:builder--translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .\32xl\:hover\:builder--translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .\32xl\:hover\:builder--translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .\32xl\:hover\:builder--translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .\32xl\:hover\:builder--translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .\32xl\:hover\:builder--translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .\32xl\:hover\:builder--translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .\32xl\:hover\:builder--translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .\32xl\:hover\:builder--translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .\32xl\:hover\:builder--translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .\32xl\:hover\:builder--translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .\32xl\:hover\:builder--translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .\32xl\:hover\:builder--translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .\32xl\:hover\:builder--translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .\32xl\:hover\:builder--translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .\32xl\:hover\:builder--translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .\32xl\:hover\:builder--translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .\32xl\:hover\:builder--translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .\32xl\:hover\:builder--translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .\32xl\:hover\:builder--translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .\32xl\:hover\:builder--translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .\32xl\:hover\:builder--translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .\32xl\:hover\:builder--translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .\32xl\:hover\:builder--translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .\32xl\:hover\:builder--translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .\32xl\:hover\:builder--translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .\32xl\:hover\:builder--translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .\32xl\:hover\:builder-translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:hover\:builder-translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .\32xl\:hover\:builder-translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .\32xl\:hover\:builder-translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .\32xl\:hover\:builder-translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:hover\:builder-translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .\32xl\:hover\:builder-translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .\32xl\:hover\:builder--translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:hover\:builder--translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .\32xl\:hover\:builder--translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .\32xl\:hover\:builder--translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .\32xl\:hover\:builder--translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:hover\:builder--translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .\32xl\:hover\:builder--translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .\32xl\:focus\:builder-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:focus\:builder-translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .\32xl\:focus\:builder-translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .\32xl\:focus\:builder-translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .\32xl\:focus\:builder-translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .\32xl\:focus\:builder-translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .\32xl\:focus\:builder-translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .\32xl\:focus\:builder-translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .\32xl\:focus\:builder-translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .\32xl\:focus\:builder-translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .\32xl\:focus\:builder-translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .\32xl\:focus\:builder-translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .\32xl\:focus\:builder-translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .\32xl\:focus\:builder-translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .\32xl\:focus\:builder-translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .\32xl\:focus\:builder-translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .\32xl\:focus\:builder-translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .\32xl\:focus\:builder-translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .\32xl\:focus\:builder-translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .\32xl\:focus\:builder-translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .\32xl\:focus\:builder-translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .\32xl\:focus\:builder-translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .\32xl\:focus\:builder-translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .\32xl\:focus\:builder-translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .\32xl\:focus\:builder-translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .\32xl\:focus\:builder-translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .\32xl\:focus\:builder-translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .\32xl\:focus\:builder-translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .\32xl\:focus\:builder-translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .\32xl\:focus\:builder-translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .\32xl\:focus\:builder-translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .\32xl\:focus\:builder-translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .\32xl\:focus\:builder-translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .\32xl\:focus\:builder-translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .\32xl\:focus\:builder-translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .\32xl\:focus\:builder--translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:focus\:builder--translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .\32xl\:focus\:builder--translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .\32xl\:focus\:builder--translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .\32xl\:focus\:builder--translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .\32xl\:focus\:builder--translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .\32xl\:focus\:builder--translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .\32xl\:focus\:builder--translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .\32xl\:focus\:builder--translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .\32xl\:focus\:builder--translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .\32xl\:focus\:builder--translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .\32xl\:focus\:builder--translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .\32xl\:focus\:builder--translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .\32xl\:focus\:builder--translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .\32xl\:focus\:builder--translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .\32xl\:focus\:builder--translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .\32xl\:focus\:builder--translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .\32xl\:focus\:builder--translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .\32xl\:focus\:builder--translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .\32xl\:focus\:builder--translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .\32xl\:focus\:builder--translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .\32xl\:focus\:builder--translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .\32xl\:focus\:builder--translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .\32xl\:focus\:builder--translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .\32xl\:focus\:builder--translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .\32xl\:focus\:builder--translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .\32xl\:focus\:builder--translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .\32xl\:focus\:builder--translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .\32xl\:focus\:builder--translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .\32xl\:focus\:builder--translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .\32xl\:focus\:builder--translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .\32xl\:focus\:builder--translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .\32xl\:focus\:builder--translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .\32xl\:focus\:builder--translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .\32xl\:focus\:builder--translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .\32xl\:focus\:builder-translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:focus\:builder-translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .\32xl\:focus\:builder-translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .\32xl\:focus\:builder-translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .\32xl\:focus\:builder-translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:focus\:builder-translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .\32xl\:focus\:builder-translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .\32xl\:focus\:builder--translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:focus\:builder--translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .\32xl\:focus\:builder--translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .\32xl\:focus\:builder--translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .\32xl\:focus\:builder--translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:focus\:builder--translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .\32xl\:focus\:builder--translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .\32xl\:focus\:builder-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:focus\:builder-translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .\32xl\:focus\:builder-translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .\32xl\:focus\:builder-translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .\32xl\:focus\:builder-translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .\32xl\:focus\:builder-translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .\32xl\:focus\:builder-translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .\32xl\:focus\:builder-translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .\32xl\:focus\:builder-translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .\32xl\:focus\:builder-translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .\32xl\:focus\:builder-translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .\32xl\:focus\:builder-translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .\32xl\:focus\:builder-translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .\32xl\:focus\:builder-translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .\32xl\:focus\:builder-translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .\32xl\:focus\:builder-translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .\32xl\:focus\:builder-translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .\32xl\:focus\:builder-translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .\32xl\:focus\:builder-translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .\32xl\:focus\:builder-translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .\32xl\:focus\:builder-translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .\32xl\:focus\:builder-translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .\32xl\:focus\:builder-translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .\32xl\:focus\:builder-translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .\32xl\:focus\:builder-translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .\32xl\:focus\:builder-translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .\32xl\:focus\:builder-translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .\32xl\:focus\:builder-translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .\32xl\:focus\:builder-translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .\32xl\:focus\:builder-translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .\32xl\:focus\:builder-translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .\32xl\:focus\:builder-translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .\32xl\:focus\:builder-translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .\32xl\:focus\:builder-translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .\32xl\:focus\:builder-translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .\32xl\:focus\:builder--translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:focus\:builder--translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .\32xl\:focus\:builder--translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .\32xl\:focus\:builder--translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .\32xl\:focus\:builder--translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .\32xl\:focus\:builder--translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .\32xl\:focus\:builder--translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .\32xl\:focus\:builder--translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .\32xl\:focus\:builder--translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .\32xl\:focus\:builder--translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .\32xl\:focus\:builder--translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .\32xl\:focus\:builder--translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .\32xl\:focus\:builder--translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .\32xl\:focus\:builder--translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .\32xl\:focus\:builder--translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .\32xl\:focus\:builder--translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .\32xl\:focus\:builder--translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .\32xl\:focus\:builder--translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .\32xl\:focus\:builder--translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .\32xl\:focus\:builder--translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .\32xl\:focus\:builder--translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .\32xl\:focus\:builder--translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .\32xl\:focus\:builder--translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .\32xl\:focus\:builder--translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .\32xl\:focus\:builder--translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .\32xl\:focus\:builder--translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .\32xl\:focus\:builder--translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .\32xl\:focus\:builder--translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .\32xl\:focus\:builder--translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .\32xl\:focus\:builder--translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .\32xl\:focus\:builder--translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .\32xl\:focus\:builder--translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .\32xl\:focus\:builder--translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .\32xl\:focus\:builder--translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .\32xl\:focus\:builder--translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .\32xl\:focus\:builder-translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:focus\:builder-translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .\32xl\:focus\:builder-translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .\32xl\:focus\:builder-translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .\32xl\:focus\:builder-translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:focus\:builder-translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .\32xl\:focus\:builder-translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .\32xl\:focus\:builder--translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:focus\:builder--translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .\32xl\:focus\:builder--translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .\32xl\:focus\:builder--translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .\32xl\:focus\:builder--translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:focus\:builder--translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .\32xl\:focus\:builder--translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .\32xl\:builder-rotate-0{
    --tw-rotate: 0deg !important;
  }

  .\32xl\:builder-rotate-1{
    --tw-rotate: 1deg !important;
  }

  .\32xl\:builder-rotate-2{
    --tw-rotate: 2deg !important;
  }

  .\32xl\:builder-rotate-3{
    --tw-rotate: 3deg !important;
  }

  .\32xl\:builder-rotate-6{
    --tw-rotate: 6deg !important;
  }

  .\32xl\:builder-rotate-12{
    --tw-rotate: 12deg !important;
  }

  .\32xl\:builder-rotate-45{
    --tw-rotate: 45deg !important;
  }

  .\32xl\:builder-rotate-90{
    --tw-rotate: 90deg !important;
  }

  .\32xl\:builder-rotate-180{
    --tw-rotate: 180deg !important;
  }

  .\32xl\:builder--rotate-180{
    --tw-rotate: -180deg !important;
  }

  .\32xl\:builder--rotate-90{
    --tw-rotate: -90deg !important;
  }

  .\32xl\:builder--rotate-45{
    --tw-rotate: -45deg !important;
  }

  .\32xl\:builder--rotate-12{
    --tw-rotate: -12deg !important;
  }

  .\32xl\:builder--rotate-6{
    --tw-rotate: -6deg !important;
  }

  .\32xl\:builder--rotate-3{
    --tw-rotate: -3deg !important;
  }

  .\32xl\:builder--rotate-2{
    --tw-rotate: -2deg !important;
  }

  .\32xl\:builder--rotate-1{
    --tw-rotate: -1deg !important;
  }

  .\32xl\:hover\:builder-rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .\32xl\:hover\:builder-rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .\32xl\:hover\:builder-rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .\32xl\:hover\:builder-rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .\32xl\:hover\:builder-rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .\32xl\:hover\:builder-rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .\32xl\:hover\:builder-rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .\32xl\:hover\:builder-rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .\32xl\:hover\:builder-rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .\32xl\:hover\:builder--rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .\32xl\:hover\:builder--rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .\32xl\:hover\:builder--rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .\32xl\:hover\:builder--rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .\32xl\:hover\:builder--rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .\32xl\:hover\:builder--rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .\32xl\:hover\:builder--rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .\32xl\:hover\:builder--rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .\32xl\:focus\:builder-rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .\32xl\:focus\:builder-rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .\32xl\:focus\:builder-rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .\32xl\:focus\:builder-rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .\32xl\:focus\:builder-rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .\32xl\:focus\:builder-rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .\32xl\:focus\:builder-rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .\32xl\:focus\:builder-rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .\32xl\:focus\:builder-rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .\32xl\:focus\:builder--rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .\32xl\:focus\:builder--rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .\32xl\:focus\:builder--rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .\32xl\:focus\:builder--rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .\32xl\:focus\:builder--rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .\32xl\:focus\:builder--rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .\32xl\:focus\:builder--rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .\32xl\:focus\:builder--rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .\32xl\:builder-skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .\32xl\:builder-skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .\32xl\:builder-skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .\32xl\:builder-skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .\32xl\:builder-skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .\32xl\:builder-skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .\32xl\:builder--skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .\32xl\:builder--skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .\32xl\:builder--skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .\32xl\:builder--skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .\32xl\:builder--skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .\32xl\:builder-skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .\32xl\:builder-skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .\32xl\:builder-skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .\32xl\:builder-skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .\32xl\:builder-skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .\32xl\:builder-skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .\32xl\:builder--skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .\32xl\:builder--skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .\32xl\:builder--skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .\32xl\:builder--skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .\32xl\:builder--skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .\32xl\:hover\:builder-skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .\32xl\:hover\:builder-skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .\32xl\:hover\:builder-skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .\32xl\:hover\:builder-skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .\32xl\:hover\:builder-skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .\32xl\:hover\:builder-skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .\32xl\:hover\:builder--skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .\32xl\:hover\:builder--skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .\32xl\:hover\:builder--skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .\32xl\:hover\:builder--skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .\32xl\:hover\:builder--skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .\32xl\:hover\:builder-skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .\32xl\:hover\:builder-skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .\32xl\:hover\:builder-skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .\32xl\:hover\:builder-skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .\32xl\:hover\:builder-skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .\32xl\:hover\:builder-skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .\32xl\:hover\:builder--skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .\32xl\:hover\:builder--skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .\32xl\:hover\:builder--skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .\32xl\:hover\:builder--skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .\32xl\:hover\:builder--skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .\32xl\:focus\:builder-skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .\32xl\:focus\:builder-skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .\32xl\:focus\:builder-skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .\32xl\:focus\:builder-skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .\32xl\:focus\:builder-skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .\32xl\:focus\:builder-skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .\32xl\:focus\:builder--skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .\32xl\:focus\:builder--skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .\32xl\:focus\:builder--skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .\32xl\:focus\:builder--skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .\32xl\:focus\:builder--skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .\32xl\:focus\:builder-skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .\32xl\:focus\:builder-skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .\32xl\:focus\:builder-skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .\32xl\:focus\:builder-skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .\32xl\:focus\:builder-skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .\32xl\:focus\:builder-skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .\32xl\:focus\:builder--skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .\32xl\:focus\:builder--skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .\32xl\:focus\:builder--skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .\32xl\:focus\:builder--skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .\32xl\:focus\:builder--skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .\32xl\:builder-scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .\32xl\:builder-scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .\32xl\:builder-scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .\32xl\:builder-scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .\32xl\:builder-scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .\32xl\:builder-scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .\32xl\:builder-scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:builder-scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:builder-scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:builder-scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:hover\:builder-scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .\32xl\:hover\:builder-scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .\32xl\:hover\:builder-scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .\32xl\:hover\:builder-scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .\32xl\:hover\:builder-scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .\32xl\:hover\:builder-scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .\32xl\:hover\:builder-scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:hover\:builder-scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:hover\:builder-scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:hover\:builder-scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:focus\:builder-scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .\32xl\:focus\:builder-scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .\32xl\:focus\:builder-scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .\32xl\:focus\:builder-scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .\32xl\:focus\:builder-scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .\32xl\:focus\:builder-scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .\32xl\:focus\:builder-scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:focus\:builder-scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:focus\:builder-scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:focus\:builder-scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:builder-scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .\32xl\:builder-scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .\32xl\:builder-scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .\32xl\:builder-scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .\32xl\:builder-scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .\32xl\:builder-scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .\32xl\:builder-scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .\32xl\:builder-scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .\32xl\:builder-scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .\32xl\:builder-scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .\32xl\:builder-scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .\32xl\:builder-scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .\32xl\:builder-scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .\32xl\:builder-scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .\32xl\:builder-scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .\32xl\:builder-scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .\32xl\:builder-scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:builder-scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:builder-scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:builder-scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:hover\:builder-scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .\32xl\:hover\:builder-scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .\32xl\:hover\:builder-scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .\32xl\:hover\:builder-scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .\32xl\:hover\:builder-scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .\32xl\:hover\:builder-scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .\32xl\:hover\:builder-scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .\32xl\:hover\:builder-scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .\32xl\:hover\:builder-scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .\32xl\:hover\:builder-scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .\32xl\:hover\:builder-scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .\32xl\:hover\:builder-scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .\32xl\:hover\:builder-scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .\32xl\:hover\:builder-scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .\32xl\:hover\:builder-scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .\32xl\:hover\:builder-scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .\32xl\:hover\:builder-scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:hover\:builder-scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:hover\:builder-scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:hover\:builder-scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:focus\:builder-scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .\32xl\:focus\:builder-scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .\32xl\:focus\:builder-scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .\32xl\:focus\:builder-scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .\32xl\:focus\:builder-scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .\32xl\:focus\:builder-scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .\32xl\:focus\:builder-scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .\32xl\:focus\:builder-scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .\32xl\:focus\:builder-scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .\32xl\:focus\:builder-scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .\32xl\:focus\:builder-scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .\32xl\:focus\:builder-scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .\32xl\:focus\:builder-scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .\32xl\:focus\:builder-scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .\32xl\:focus\:builder-scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .\32xl\:focus\:builder-scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .\32xl\:focus\:builder-scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:focus\:builder-scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:focus\:builder-scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:focus\:builder-scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:builder-animate-none{
    animation: none !important;
  }

  .\32xl\:builder-animate-spin{
    animation: builder-spin 1s linear infinite !important;
  }

  .\32xl\:builder-animate-ping{
    animation: builder-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .\32xl\:builder-animate-pulse{
    animation: builder-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .\32xl\:builder-animate-bounce{
    animation: builder-bounce 1s infinite !important;
  }

  .\32xl\:builder-cursor-auto{
    cursor: auto !important;
  }

  .\32xl\:builder-cursor-default{
    cursor: default !important;
  }

  .\32xl\:builder-cursor-pointer{
    cursor: pointer !important;
  }

  .\32xl\:builder-cursor-wait{
    cursor: wait !important;
  }

  .\32xl\:builder-cursor-text{
    cursor: text !important;
  }

  .\32xl\:builder-cursor-move{
    cursor: move !important;
  }

  .\32xl\:builder-cursor-help{
    cursor: help !important;
  }

  .\32xl\:builder-cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .\32xl\:builder-select-none{
    -webkit-user-select: none !important;
            user-select: none !important;
  }

  .\32xl\:builder-select-text{
    -webkit-user-select: text !important;
            user-select: text !important;
  }

  .\32xl\:builder-select-all{
    -webkit-user-select: all !important;
            user-select: all !important;
  }

  .\32xl\:builder-select-auto{
    -webkit-user-select: auto !important;
            user-select: auto !important;
  }

  .\32xl\:builder-resize-none{
    resize: none !important;
  }

  .\32xl\:builder-resize-y{
    resize: vertical !important;
  }

  .\32xl\:builder-resize-x{
    resize: horizontal !important;
  }

  .\32xl\:builder-resize{
    resize: both !important;
  }

  .\32xl\:builder-list-inside{
    list-style-position: inside !important;
  }

  .\32xl\:builder-list-outside{
    list-style-position: outside !important;
  }

  .\32xl\:builder-list-none{
    list-style-type: none !important;
  }

  .\32xl\:builder-list-disc{
    list-style-type: disc !important;
  }

  .\32xl\:builder-list-decimal{
    list-style-type: decimal !important;
  }

  .\32xl\:builder-appearance-none{
    appearance: none !important;
  }

  .\32xl\:builder-auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .\32xl\:builder-auto-cols-min{
    grid-auto-columns: min-content !important;
  }

  .\32xl\:builder-auto-cols-max{
    grid-auto-columns: max-content !important;
  }

  .\32xl\:builder-auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .\32xl\:builder-grid-flow-row{
    grid-auto-flow: row !important;
  }

  .\32xl\:builder-grid-flow-col{
    grid-auto-flow: column !important;
  }

  .\32xl\:builder-grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .\32xl\:builder-grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .\32xl\:builder-auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .\32xl\:builder-auto-rows-min{
    grid-auto-rows: min-content !important;
  }

  .\32xl\:builder-auto-rows-max{
    grid-auto-rows: max-content !important;
  }

  .\32xl\:builder-auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .\32xl\:builder-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-cols-none{
    grid-template-columns: none !important;
  }

  .\32xl\:builder-grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .\32xl\:builder-grid-rows-none{
    grid-template-rows: none !important;
  }

  .\32xl\:builder-flex-row{
    flex-direction: row !important;
  }

  .\32xl\:builder-flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .\32xl\:builder-flex-col{
    flex-direction: column !important;
  }

  .\32xl\:builder-flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .\32xl\:builder-flex-wrap{
    flex-wrap: wrap !important;
  }

  .\32xl\:builder-flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .\32xl\:builder-flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .\32xl\:builder-place-content-center{
    place-content: center !important;
  }

  .\32xl\:builder-place-content-start{
    place-content: start !important;
  }

  .\32xl\:builder-place-content-end{
    place-content: end !important;
  }

  .\32xl\:builder-place-content-between{
    place-content: space-between !important;
  }

  .\32xl\:builder-place-content-around{
    place-content: space-around !important;
  }

  .\32xl\:builder-place-content-evenly{
    place-content: space-evenly !important;
  }

  .\32xl\:builder-place-content-stretch{
    place-content: stretch !important;
  }

  .\32xl\:builder-place-items-start{
    place-items: start !important;
  }

  .\32xl\:builder-place-items-end{
    place-items: end !important;
  }

  .\32xl\:builder-place-items-center{
    place-items: center !important;
  }

  .\32xl\:builder-place-items-stretch{
    place-items: stretch !important;
  }

  .\32xl\:builder-content-center{
    align-content: center !important;
  }

  .\32xl\:builder-content-start{
    align-content: flex-start !important;
  }

  .\32xl\:builder-content-end{
    align-content: flex-end !important;
  }

  .\32xl\:builder-content-between{
    align-content: space-between !important;
  }

  .\32xl\:builder-content-around{
    align-content: space-around !important;
  }

  .\32xl\:builder-content-evenly{
    align-content: space-evenly !important;
  }

  .\32xl\:builder-items-start{
    align-items: flex-start !important;
  }

  .\32xl\:builder-items-end{
    align-items: flex-end !important;
  }

  .\32xl\:builder-items-center{
    align-items: center !important;
  }

  .\32xl\:builder-items-baseline{
    align-items: baseline !important;
  }

  .\32xl\:builder-items-stretch{
    align-items: stretch !important;
  }

  .\32xl\:builder-justify-start{
    justify-content: flex-start !important;
  }

  .\32xl\:builder-justify-end{
    justify-content: flex-end !important;
  }

  .\32xl\:builder-justify-center{
    justify-content: center !important;
  }

  .\32xl\:builder-justify-between{
    justify-content: space-between !important;
  }

  .\32xl\:builder-justify-around{
    justify-content: space-around !important;
  }

  .\32xl\:builder-justify-evenly{
    justify-content: space-evenly !important;
  }

  .\32xl\:builder-justify-items-start{
    justify-items: start !important;
  }

  .\32xl\:builder-justify-items-end{
    justify-items: end !important;
  }

  .\32xl\:builder-justify-items-center{
    justify-items: center !important;
  }

  .\32xl\:builder-justify-items-stretch{
    justify-items: stretch !important;
  }

  .\32xl\:builder-gap-0{
    gap: 0px !important;
  }

  .\32xl\:builder-gap-1{
    gap: 0.25rem !important;
  }

  .\32xl\:builder-gap-2{
    gap: 0.5rem !important;
  }

  .\32xl\:builder-gap-3{
    gap: 0.75rem !important;
  }

  .\32xl\:builder-gap-4{
    gap: 1rem !important;
  }

  .\32xl\:builder-gap-5{
    gap: 1.25rem !important;
  }

  .\32xl\:builder-gap-6{
    gap: 1.5rem !important;
  }

  .\32xl\:builder-gap-7{
    gap: 1.75rem !important;
  }

  .\32xl\:builder-gap-8{
    gap: 2rem !important;
  }

  .\32xl\:builder-gap-9{
    gap: 2.25rem !important;
  }

  .\32xl\:builder-gap-10{
    gap: 2.5rem !important;
  }

  .\32xl\:builder-gap-11{
    gap: 2.75rem !important;
  }

  .\32xl\:builder-gap-12{
    gap: 3rem !important;
  }

  .\32xl\:builder-gap-14{
    gap: 3.5rem !important;
  }

  .\32xl\:builder-gap-16{
    gap: 4rem !important;
  }

  .\32xl\:builder-gap-20{
    gap: 5rem !important;
  }

  .\32xl\:builder-gap-24{
    gap: 6rem !important;
  }

  .\32xl\:builder-gap-28{
    gap: 7rem !important;
  }

  .\32xl\:builder-gap-32{
    gap: 8rem !important;
  }

  .\32xl\:builder-gap-36{
    gap: 9rem !important;
  }

  .\32xl\:builder-gap-40{
    gap: 10rem !important;
  }

  .\32xl\:builder-gap-44{
    gap: 11rem !important;
  }

  .\32xl\:builder-gap-48{
    gap: 12rem !important;
  }

  .\32xl\:builder-gap-52{
    gap: 13rem !important;
  }

  .\32xl\:builder-gap-56{
    gap: 14rem !important;
  }

  .\32xl\:builder-gap-60{
    gap: 15rem !important;
  }

  .\32xl\:builder-gap-64{
    gap: 16rem !important;
  }

  .\32xl\:builder-gap-72{
    gap: 18rem !important;
  }

  .\32xl\:builder-gap-80{
    gap: 20rem !important;
  }

  .\32xl\:builder-gap-96{
    gap: 24rem !important;
  }

  .\32xl\:builder-gap-px{
    gap: 1px !important;
  }

  .\32xl\:builder-gap-0\.5{
    gap: 0.125rem !important;
  }

  .\32xl\:builder-gap-1\.5{
    gap: 0.375rem !important;
  }

  .\32xl\:builder-gap-2\.5{
    gap: 0.625rem !important;
  }

  .\32xl\:builder-gap-3\.5{
    gap: 0.875rem !important;
  }

  .\32xl\:builder-gap-x-0{
    column-gap: 0px !important;
  }

  .\32xl\:builder-gap-x-1{
    column-gap: 0.25rem !important;
  }

  .\32xl\:builder-gap-x-2{
    column-gap: 0.5rem !important;
  }

  .\32xl\:builder-gap-x-3{
    column-gap: 0.75rem !important;
  }

  .\32xl\:builder-gap-x-4{
    column-gap: 1rem !important;
  }

  .\32xl\:builder-gap-x-5{
    column-gap: 1.25rem !important;
  }

  .\32xl\:builder-gap-x-6{
    column-gap: 1.5rem !important;
  }

  .\32xl\:builder-gap-x-7{
    column-gap: 1.75rem !important;
  }

  .\32xl\:builder-gap-x-8{
    column-gap: 2rem !important;
  }

  .\32xl\:builder-gap-x-9{
    column-gap: 2.25rem !important;
  }

  .\32xl\:builder-gap-x-10{
    column-gap: 2.5rem !important;
  }

  .\32xl\:builder-gap-x-11{
    column-gap: 2.75rem !important;
  }

  .\32xl\:builder-gap-x-12{
    column-gap: 3rem !important;
  }

  .\32xl\:builder-gap-x-14{
    column-gap: 3.5rem !important;
  }

  .\32xl\:builder-gap-x-16{
    column-gap: 4rem !important;
  }

  .\32xl\:builder-gap-x-20{
    column-gap: 5rem !important;
  }

  .\32xl\:builder-gap-x-24{
    column-gap: 6rem !important;
  }

  .\32xl\:builder-gap-x-28{
    column-gap: 7rem !important;
  }

  .\32xl\:builder-gap-x-32{
    column-gap: 8rem !important;
  }

  .\32xl\:builder-gap-x-36{
    column-gap: 9rem !important;
  }

  .\32xl\:builder-gap-x-40{
    column-gap: 10rem !important;
  }

  .\32xl\:builder-gap-x-44{
    column-gap: 11rem !important;
  }

  .\32xl\:builder-gap-x-48{
    column-gap: 12rem !important;
  }

  .\32xl\:builder-gap-x-52{
    column-gap: 13rem !important;
  }

  .\32xl\:builder-gap-x-56{
    column-gap: 14rem !important;
  }

  .\32xl\:builder-gap-x-60{
    column-gap: 15rem !important;
  }

  .\32xl\:builder-gap-x-64{
    column-gap: 16rem !important;
  }

  .\32xl\:builder-gap-x-72{
    column-gap: 18rem !important;
  }

  .\32xl\:builder-gap-x-80{
    column-gap: 20rem !important;
  }

  .\32xl\:builder-gap-x-96{
    column-gap: 24rem !important;
  }

  .\32xl\:builder-gap-x-px{
    column-gap: 1px !important;
  }

  .\32xl\:builder-gap-x-0\.5{
    column-gap: 0.125rem !important;
  }

  .\32xl\:builder-gap-x-1\.5{
    column-gap: 0.375rem !important;
  }

  .\32xl\:builder-gap-x-2\.5{
    column-gap: 0.625rem !important;
  }

  .\32xl\:builder-gap-x-3\.5{
    column-gap: 0.875rem !important;
  }

  .\32xl\:builder-gap-y-0{
    row-gap: 0px !important;
  }

  .\32xl\:builder-gap-y-1{
    row-gap: 0.25rem !important;
  }

  .\32xl\:builder-gap-y-2{
    row-gap: 0.5rem !important;
  }

  .\32xl\:builder-gap-y-3{
    row-gap: 0.75rem !important;
  }

  .\32xl\:builder-gap-y-4{
    row-gap: 1rem !important;
  }

  .\32xl\:builder-gap-y-5{
    row-gap: 1.25rem !important;
  }

  .\32xl\:builder-gap-y-6{
    row-gap: 1.5rem !important;
  }

  .\32xl\:builder-gap-y-7{
    row-gap: 1.75rem !important;
  }

  .\32xl\:builder-gap-y-8{
    row-gap: 2rem !important;
  }

  .\32xl\:builder-gap-y-9{
    row-gap: 2.25rem !important;
  }

  .\32xl\:builder-gap-y-10{
    row-gap: 2.5rem !important;
  }

  .\32xl\:builder-gap-y-11{
    row-gap: 2.75rem !important;
  }

  .\32xl\:builder-gap-y-12{
    row-gap: 3rem !important;
  }

  .\32xl\:builder-gap-y-14{
    row-gap: 3.5rem !important;
  }

  .\32xl\:builder-gap-y-16{
    row-gap: 4rem !important;
  }

  .\32xl\:builder-gap-y-20{
    row-gap: 5rem !important;
  }

  .\32xl\:builder-gap-y-24{
    row-gap: 6rem !important;
  }

  .\32xl\:builder-gap-y-28{
    row-gap: 7rem !important;
  }

  .\32xl\:builder-gap-y-32{
    row-gap: 8rem !important;
  }

  .\32xl\:builder-gap-y-36{
    row-gap: 9rem !important;
  }

  .\32xl\:builder-gap-y-40{
    row-gap: 10rem !important;
  }

  .\32xl\:builder-gap-y-44{
    row-gap: 11rem !important;
  }

  .\32xl\:builder-gap-y-48{
    row-gap: 12rem !important;
  }

  .\32xl\:builder-gap-y-52{
    row-gap: 13rem !important;
  }

  .\32xl\:builder-gap-y-56{
    row-gap: 14rem !important;
  }

  .\32xl\:builder-gap-y-60{
    row-gap: 15rem !important;
  }

  .\32xl\:builder-gap-y-64{
    row-gap: 16rem !important;
  }

  .\32xl\:builder-gap-y-72{
    row-gap: 18rem !important;
  }

  .\32xl\:builder-gap-y-80{
    row-gap: 20rem !important;
  }

  .\32xl\:builder-gap-y-96{
    row-gap: 24rem !important;
  }

  .\32xl\:builder-gap-y-px{
    row-gap: 1px !important;
  }

  .\32xl\:builder-gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .\32xl\:builder-gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .\32xl\:builder-gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .\32xl\:builder-gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .\32xl\:builder-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder--space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:builder-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder--space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:builder-space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .\32xl\:builder-space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .\32xl\:builder-divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:builder-divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:builder-divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:builder-divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:builder-divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:builder-divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:builder-divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:builder-divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:builder-divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:builder-divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:builder-divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .\32xl\:builder-divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .\32xl\:builder-divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .\32xl\:builder-divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .\32xl\:builder-divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .\32xl\:builder-divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .\32xl\:builder-divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .\32xl\:builder-divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .\32xl\:builder-divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .\32xl\:builder-divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-primary > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-secondary-dark > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-secondary-darker > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:builder-divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .\32xl\:builder-divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .\32xl\:builder-divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .\32xl\:builder-divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .\32xl\:builder-divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .\32xl\:builder-divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .\32xl\:builder-divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .\32xl\:builder-divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .\32xl\:builder-divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .\32xl\:builder-divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .\32xl\:builder-divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .\32xl\:builder-divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .\32xl\:builder-divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .\32xl\:builder-divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .\32xl\:builder-divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .\32xl\:builder-place-self-auto{
    place-self: auto !important;
  }

  .\32xl\:builder-place-self-start{
    place-self: start !important;
  }

  .\32xl\:builder-place-self-end{
    place-self: end !important;
  }

  .\32xl\:builder-place-self-center{
    place-self: center !important;
  }

  .\32xl\:builder-place-self-stretch{
    place-self: stretch !important;
  }

  .\32xl\:builder-self-auto{
    align-self: auto !important;
  }

  .\32xl\:builder-self-start{
    align-self: flex-start !important;
  }

  .\32xl\:builder-self-end{
    align-self: flex-end !important;
  }

  .\32xl\:builder-self-center{
    align-self: center !important;
  }

  .\32xl\:builder-self-stretch{
    align-self: stretch !important;
  }

  .\32xl\:builder-self-baseline{
    align-self: baseline !important;
  }

  .\32xl\:builder-justify-self-auto{
    justify-self: auto !important;
  }

  .\32xl\:builder-justify-self-start{
    justify-self: start !important;
  }

  .\32xl\:builder-justify-self-end{
    justify-self: end !important;
  }

  .\32xl\:builder-justify-self-center{
    justify-self: center !important;
  }

  .\32xl\:builder-justify-self-stretch{
    justify-self: stretch !important;
  }

  .\32xl\:builder-overflow-auto{
    overflow: auto !important;
  }

  .\32xl\:builder-overflow-hidden{
    overflow: hidden !important;
  }

  .\32xl\:builder-overflow-visible{
    overflow: visible !important;
  }

  .\32xl\:builder-overflow-scroll{
    overflow: scroll !important;
  }

  .\32xl\:builder-overflow-x-auto{
    overflow-x: auto !important;
  }

  .\32xl\:builder-overflow-y-auto{
    overflow-y: auto !important;
  }

  .\32xl\:builder-overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .\32xl\:builder-overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .\32xl\:builder-overflow-x-visible{
    overflow-x: visible !important;
  }

  .\32xl\:builder-overflow-y-visible{
    overflow-y: visible !important;
  }

  .\32xl\:builder-overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .\32xl\:builder-overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .\32xl\:builder-overscroll-auto{
    overscroll-behavior: auto !important;
  }

  .\32xl\:builder-overscroll-contain{
    overscroll-behavior: contain !important;
  }

  .\32xl\:builder-overscroll-none{
    overscroll-behavior: none !important;
  }

  .\32xl\:builder-overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .\32xl\:builder-overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .\32xl\:builder-overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .\32xl\:builder-overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .\32xl\:builder-overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .\32xl\:builder-overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .\32xl\:builder-truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .\32xl\:builder-overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .\32xl\:builder-overflow-clip{
    text-overflow: clip !important;
  }

  .\32xl\:builder-whitespace-normal{
    white-space: normal !important;
  }

  .\32xl\:builder-whitespace-nowrap{
    white-space: nowrap !important;
  }

  .\32xl\:builder-whitespace-pre{
    white-space: pre !important;
  }

  .\32xl\:builder-whitespace-pre-line{
    white-space: pre-line !important;
  }

  .\32xl\:builder-whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .\32xl\:builder-break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .\32xl\:builder-break-words{
    overflow-wrap: break-word !important;
  }

  .\32xl\:builder-break-all{
    word-break: break-all !important;
  }

  .\32xl\:builder-rounded-none{
    border-radius: 0px !important;
  }

  .\32xl\:builder-rounded-sm{
    border-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded{
    border-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-md{
    border-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-lg{
    border-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-xl{
    border-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-2xl{
    border-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-full{
    border-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .\32xl\:builder-rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .\32xl\:builder-rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .\32xl\:builder-rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .\32xl\:builder-rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .\32xl\:builder-rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .\32xl\:builder-rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .\32xl\:builder-rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .\32xl\:builder-rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .\32xl\:builder-rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .\32xl\:builder-rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .\32xl\:builder-rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .\32xl\:builder-rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .\32xl\:builder-rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .\32xl\:builder-rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .\32xl\:builder-rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .\32xl\:builder-rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .\32xl\:builder-border-0{
    border-width: 0px !important;
  }

  .\32xl\:builder-border-2{
    border-width: 2px !important;
  }

  .\32xl\:builder-border-4{
    border-width: 4px !important;
  }

  .\32xl\:builder-border-8{
    border-width: 8px !important;
  }

  .\32xl\:builder-border{
    border-width: 1px !important;
  }

  .\32xl\:builder-border-t-0{
    border-top-width: 0px !important;
  }

  .\32xl\:builder-border-t-2{
    border-top-width: 2px !important;
  }

  .\32xl\:builder-border-t-4{
    border-top-width: 4px !important;
  }

  .\32xl\:builder-border-t-8{
    border-top-width: 8px !important;
  }

  .\32xl\:builder-border-t{
    border-top-width: 1px !important;
  }

  .\32xl\:builder-border-r-0{
    border-right-width: 0px !important;
  }

  .\32xl\:builder-border-r-2{
    border-right-width: 2px !important;
  }

  .\32xl\:builder-border-r-4{
    border-right-width: 4px !important;
  }

  .\32xl\:builder-border-r-8{
    border-right-width: 8px !important;
  }

  .\32xl\:builder-border-r{
    border-right-width: 1px !important;
  }

  .\32xl\:builder-border-b-0{
    border-bottom-width: 0px !important;
  }

  .\32xl\:builder-border-b-2{
    border-bottom-width: 2px !important;
  }

  .\32xl\:builder-border-b-4{
    border-bottom-width: 4px !important;
  }

  .\32xl\:builder-border-b-8{
    border-bottom-width: 8px !important;
  }

  .\32xl\:builder-border-b{
    border-bottom-width: 1px !important;
  }

  .\32xl\:builder-border-l-0{
    border-left-width: 0px !important;
  }

  .\32xl\:builder-border-l-2{
    border-left-width: 2px !important;
  }

  .\32xl\:builder-border-l-4{
    border-left-width: 4px !important;
  }

  .\32xl\:builder-border-l-8{
    border-left-width: 8px !important;
  }

  .\32xl\:builder-border-l{
    border-left-width: 1px !important;
  }

  .\32xl\:builder-border-solid{
    border-style: solid !important;
  }

  .\32xl\:builder-border-dashed{
    border-style: dashed !important;
  }

  .\32xl\:builder-border-dotted{
    border-style: dotted !important;
  }

  .\32xl\:builder-border-double{
    border-style: double !important;
  }

  .\32xl\:builder-border-none{
    border-style: none !important;
  }

  .\32xl\:builder-border-transparent{
    border-color: transparent !important;
  }

  .\32xl\:builder-border-current{
    border-color: currentColor !important;
  }

  .\32xl\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-transparent{
    border-color: transparent !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-current{
    border-color: currentColor !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-primary{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-secondary-dark{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-secondary-darker{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-transparent:focus-within{
    border-color: transparent !important;
  }

  .\32xl\:focus-within\:builder-border-current:focus-within{
    border-color: currentColor !important;
  }

  .\32xl\:focus-within\:builder-border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-primary:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-secondary-dark:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-border-secondary-darker:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-transparent:hover{
    border-color: transparent !important;
  }

  .\32xl\:hover\:builder-border-current:hover{
    border-color: currentColor !important;
  }

  .\32xl\:hover\:builder-border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-primary:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-secondary-dark:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:builder-border-secondary-darker:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-transparent:focus{
    border-color: transparent !important;
  }

  .\32xl\:focus\:builder-border-current:focus{
    border-color: currentColor !important;
  }

  .\32xl\:focus\:builder-border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-primary:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(45, 55, 90, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-secondary-dark:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 52, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:builder-border-secondary-darker:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(33, 33, 33, var(--tw-border-opacity)) !important;
  }

  .\32xl\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:builder-border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:hover\:builder-border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:hover\:builder-border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:hover\:builder-border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:hover\:builder-border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:hover\:builder-border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:hover\:builder-border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:hover\:builder-border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:hover\:builder-border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:hover\:builder-border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:hover\:builder-border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:hover\:builder-border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:hover\:builder-border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:hover\:builder-border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:hover\:builder-border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:hover\:builder-border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:focus\:builder-border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:focus\:builder-border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:focus\:builder-border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:focus\:builder-border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:focus\:builder-border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:focus\:builder-border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:focus\:builder-border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:focus\:builder-border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:focus\:builder-border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:focus\:builder-border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:focus\:builder-border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:focus\:builder-border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:focus\:builder-border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:focus\:builder-border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:focus\:builder-border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .\32xl\:builder-bg-current{
    background-color: currentColor !important;
  }

  .\32xl\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-transparent{
    background-color: transparent !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-current{
    background-color: currentColor !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-secondary-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-secondary-darker{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .\32xl\:focus-within\:builder-bg-current:focus-within{
    background-color: currentColor !important;
  }

  .\32xl\:focus-within\:builder-bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-primary:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-secondary-dark:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-bg-secondary-darker:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-transparent:hover{
    background-color: transparent !important;
  }

  .\32xl\:hover\:builder-bg-current:hover{
    background-color: currentColor !important;
  }

  .\32xl\:hover\:builder-bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-primary:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-secondary-dark:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:builder-bg-secondary-darker:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-transparent:focus{
    background-color: transparent !important;
  }

  .\32xl\:focus\:builder-bg-current:focus{
    background-color: currentColor !important;
  }

  .\32xl\:focus\:builder-bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-primary:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(45, 55, 90, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-secondary-dark:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 52, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:builder-bg-secondary-darker:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(33, 33, 33, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:builder-bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:hover\:builder-bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:focus\:builder-bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:builder-bg-none{
    background-image: none !important;
  }

  .\32xl\:builder-bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:builder-from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:builder-from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:builder-from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:builder-from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:builder-from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:builder-from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:builder-from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:builder-from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:builder-from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:builder-from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:builder-from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:builder-from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:builder-from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:builder-from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:builder-from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:builder-from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:builder-from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:builder-from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:builder-from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:builder-from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:builder-from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:builder-from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:builder-from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:builder-from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:builder-from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:builder-from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:builder-from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:builder-from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:builder-from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:builder-from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:builder-from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:builder-from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:builder-from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:builder-from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:builder-from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:builder-from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:builder-from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:builder-from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:builder-from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:builder-from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:builder-from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:builder-from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:builder-from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:builder-from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:builder-from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:builder-from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:builder-from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:builder-from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:builder-from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:builder-from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:builder-from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:builder-from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:builder-from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:builder-from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:builder-from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:builder-from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:builder-from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:builder-from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:builder-from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:builder-from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:builder-from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:builder-from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:builder-from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:builder-from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:builder-from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:builder-from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:builder-from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:builder-from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:builder-from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:builder-from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:builder-from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:builder-from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:builder-from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:builder-from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:builder-from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:builder-from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:builder-from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:builder-from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:builder-from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:builder-from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:builder-from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:builder-from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:builder-from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:builder-from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:builder-from-primary{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .\32xl\:builder-from-secondary-dark{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .\32xl\:builder-from-secondary-darker{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .\32xl\:hover\:builder-from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:builder-from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:builder-from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:hover\:builder-from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:hover\:builder-from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:hover\:builder-from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:hover\:builder-from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:hover\:builder-from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:hover\:builder-from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:hover\:builder-from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:hover\:builder-from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:hover\:builder-from-primary:hover{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .\32xl\:hover\:builder-from-secondary-dark:hover{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .\32xl\:hover\:builder-from-secondary-darker:hover{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .\32xl\:focus\:builder-from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:builder-from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:builder-from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:focus\:builder-from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:focus\:builder-from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:focus\:builder-from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:focus\:builder-from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:focus\:builder-from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:focus\:builder-from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:focus\:builder-from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:focus\:builder-from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:focus\:builder-from-primary:focus{
    --tw-gradient-from: #2d375a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .\32xl\:focus\:builder-from-secondary-dark:focus{
    --tw-gradient-from: #343434 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .\32xl\:focus\:builder-from-secondary-darker:focus{
    --tw-gradient-from: #212121 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .\32xl\:builder-via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:builder-via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:builder-via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:builder-via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:builder-via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:builder-via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:builder-via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:builder-via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:builder-via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:builder-via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:builder-via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:builder-via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:builder-via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:builder-via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:builder-via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:builder-via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:builder-via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:builder-via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:builder-via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:builder-via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:builder-via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:builder-via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:builder-via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:builder-via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:builder-via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:builder-via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:builder-via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:builder-via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:builder-via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:builder-via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:builder-via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:builder-via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:builder-via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:builder-via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:builder-via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:builder-via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:builder-via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:builder-via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:builder-via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:builder-via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:builder-via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:builder-via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:builder-via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:builder-via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:builder-via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:builder-via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:builder-via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:builder-via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:builder-via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:builder-via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:builder-via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:builder-via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:builder-via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:builder-via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:builder-via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:builder-via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:builder-via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:builder-via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:builder-via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:builder-via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:builder-via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:builder-via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:builder-via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:builder-via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:builder-via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:builder-via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:builder-via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:builder-via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:builder-via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:builder-via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:builder-via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:builder-via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:builder-via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:builder-via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:builder-via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:builder-via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:builder-via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:builder-via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:builder-via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:builder-via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:builder-via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:builder-via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:builder-via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:builder-via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:builder-via-primary{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .\32xl\:builder-via-secondary-dark{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .\32xl\:builder-via-secondary-darker{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .\32xl\:hover\:builder-via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:builder-via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:builder-via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:hover\:builder-via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:hover\:builder-via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:hover\:builder-via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:hover\:builder-via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:hover\:builder-via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:hover\:builder-via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:hover\:builder-via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:hover\:builder-via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:hover\:builder-via-primary:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .\32xl\:hover\:builder-via-secondary-dark:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .\32xl\:hover\:builder-via-secondary-darker:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .\32xl\:focus\:builder-via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:builder-via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:builder-via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:focus\:builder-via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:focus\:builder-via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:focus\:builder-via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:focus\:builder-via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:focus\:builder-via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:focus\:builder-via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:focus\:builder-via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:focus\:builder-via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:focus\:builder-via-primary:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2d375a, var(--tw-gradient-to, rgba(45, 55, 90, 0)) !important;
  }

  .\32xl\:focus\:builder-via-secondary-dark:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #343434, var(--tw-gradient-to, rgba(52, 52, 52, 0)) !important;
  }

  .\32xl\:focus\:builder-via-secondary-darker:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #212121, var(--tw-gradient-to, rgba(33, 33, 33, 0)) !important;
  }

  .\32xl\:builder-to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .\32xl\:builder-to-current{
    --tw-gradient-to: currentColor !important;
  }

  .\32xl\:builder-to-black{
    --tw-gradient-to: #000 !important;
  }

  .\32xl\:builder-to-white{
    --tw-gradient-to: #fff !important;
  }

  .\32xl\:builder-to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .\32xl\:builder-to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .\32xl\:builder-to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .\32xl\:builder-to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .\32xl\:builder-to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .\32xl\:builder-to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .\32xl\:builder-to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .\32xl\:builder-to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .\32xl\:builder-to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .\32xl\:builder-to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .\32xl\:builder-to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .\32xl\:builder-to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .\32xl\:builder-to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .\32xl\:builder-to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .\32xl\:builder-to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .\32xl\:builder-to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .\32xl\:builder-to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .\32xl\:builder-to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .\32xl\:builder-to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .\32xl\:builder-to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .\32xl\:builder-to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .\32xl\:builder-to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .\32xl\:builder-to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .\32xl\:builder-to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .\32xl\:builder-to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .\32xl\:builder-to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .\32xl\:builder-to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .\32xl\:builder-to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .\32xl\:builder-to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .\32xl\:builder-to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .\32xl\:builder-to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .\32xl\:builder-to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .\32xl\:builder-to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .\32xl\:builder-to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .\32xl\:builder-to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .\32xl\:builder-to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .\32xl\:builder-to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .\32xl\:builder-to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .\32xl\:builder-to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .\32xl\:builder-to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .\32xl\:builder-to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .\32xl\:builder-to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .\32xl\:builder-to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .\32xl\:builder-to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .\32xl\:builder-to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .\32xl\:builder-to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .\32xl\:builder-to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .\32xl\:builder-to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .\32xl\:builder-to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .\32xl\:builder-to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .\32xl\:builder-to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .\32xl\:builder-to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .\32xl\:builder-to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .\32xl\:builder-to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .\32xl\:builder-to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .\32xl\:builder-to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .\32xl\:builder-to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .\32xl\:builder-to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .\32xl\:builder-to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .\32xl\:builder-to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .\32xl\:builder-to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .\32xl\:builder-to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .\32xl\:builder-to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .\32xl\:builder-to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .\32xl\:builder-to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .\32xl\:builder-to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .\32xl\:builder-to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .\32xl\:builder-to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .\32xl\:builder-to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .\32xl\:builder-to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .\32xl\:builder-to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .\32xl\:builder-to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .\32xl\:builder-to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .\32xl\:builder-to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .\32xl\:builder-to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .\32xl\:builder-to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .\32xl\:builder-to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .\32xl\:builder-to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .\32xl\:builder-to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .\32xl\:builder-to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .\32xl\:builder-to-primary{
    --tw-gradient-to: #2d375a !important;
  }

  .\32xl\:builder-to-secondary-dark{
    --tw-gradient-to: #343434 !important;
  }

  .\32xl\:builder-to-secondary-darker{
    --tw-gradient-to: #212121 !important;
  }

  .\32xl\:hover\:builder-to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .\32xl\:hover\:builder-to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .\32xl\:hover\:builder-to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .\32xl\:hover\:builder-to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .\32xl\:hover\:builder-to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .\32xl\:hover\:builder-to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .\32xl\:hover\:builder-to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .\32xl\:hover\:builder-to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .\32xl\:hover\:builder-to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .\32xl\:hover\:builder-to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .\32xl\:hover\:builder-to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .\32xl\:hover\:builder-to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .\32xl\:hover\:builder-to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .\32xl\:hover\:builder-to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .\32xl\:hover\:builder-to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .\32xl\:hover\:builder-to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .\32xl\:hover\:builder-to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .\32xl\:hover\:builder-to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .\32xl\:hover\:builder-to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .\32xl\:hover\:builder-to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .\32xl\:hover\:builder-to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .\32xl\:hover\:builder-to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .\32xl\:hover\:builder-to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .\32xl\:hover\:builder-to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .\32xl\:hover\:builder-to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .\32xl\:hover\:builder-to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .\32xl\:hover\:builder-to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .\32xl\:hover\:builder-to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .\32xl\:hover\:builder-to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .\32xl\:hover\:builder-to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .\32xl\:hover\:builder-to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .\32xl\:hover\:builder-to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .\32xl\:hover\:builder-to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .\32xl\:hover\:builder-to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .\32xl\:hover\:builder-to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .\32xl\:hover\:builder-to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .\32xl\:hover\:builder-to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .\32xl\:hover\:builder-to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .\32xl\:hover\:builder-to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .\32xl\:hover\:builder-to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .\32xl\:hover\:builder-to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .\32xl\:hover\:builder-to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .\32xl\:hover\:builder-to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .\32xl\:hover\:builder-to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .\32xl\:hover\:builder-to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .\32xl\:hover\:builder-to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .\32xl\:hover\:builder-to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .\32xl\:hover\:builder-to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .\32xl\:hover\:builder-to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .\32xl\:hover\:builder-to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .\32xl\:hover\:builder-to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .\32xl\:hover\:builder-to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .\32xl\:hover\:builder-to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .\32xl\:hover\:builder-to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .\32xl\:hover\:builder-to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .\32xl\:hover\:builder-to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .\32xl\:hover\:builder-to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .\32xl\:hover\:builder-to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .\32xl\:hover\:builder-to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .\32xl\:hover\:builder-to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .\32xl\:hover\:builder-to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .\32xl\:hover\:builder-to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .\32xl\:hover\:builder-to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .\32xl\:hover\:builder-to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .\32xl\:hover\:builder-to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .\32xl\:hover\:builder-to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .\32xl\:hover\:builder-to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .\32xl\:hover\:builder-to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .\32xl\:hover\:builder-to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .\32xl\:hover\:builder-to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .\32xl\:hover\:builder-to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .\32xl\:hover\:builder-to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .\32xl\:hover\:builder-to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .\32xl\:hover\:builder-to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .\32xl\:hover\:builder-to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .\32xl\:hover\:builder-to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .\32xl\:hover\:builder-to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .\32xl\:hover\:builder-to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .\32xl\:hover\:builder-to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .\32xl\:hover\:builder-to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .\32xl\:hover\:builder-to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .\32xl\:hover\:builder-to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .\32xl\:hover\:builder-to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .\32xl\:hover\:builder-to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .\32xl\:hover\:builder-to-primary:hover{
    --tw-gradient-to: #2d375a !important;
  }

  .\32xl\:hover\:builder-to-secondary-dark:hover{
    --tw-gradient-to: #343434 !important;
  }

  .\32xl\:hover\:builder-to-secondary-darker:hover{
    --tw-gradient-to: #212121 !important;
  }

  .\32xl\:focus\:builder-to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .\32xl\:focus\:builder-to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .\32xl\:focus\:builder-to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .\32xl\:focus\:builder-to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .\32xl\:focus\:builder-to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .\32xl\:focus\:builder-to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .\32xl\:focus\:builder-to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .\32xl\:focus\:builder-to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .\32xl\:focus\:builder-to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .\32xl\:focus\:builder-to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .\32xl\:focus\:builder-to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .\32xl\:focus\:builder-to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .\32xl\:focus\:builder-to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .\32xl\:focus\:builder-to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .\32xl\:focus\:builder-to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .\32xl\:focus\:builder-to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .\32xl\:focus\:builder-to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .\32xl\:focus\:builder-to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .\32xl\:focus\:builder-to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .\32xl\:focus\:builder-to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .\32xl\:focus\:builder-to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .\32xl\:focus\:builder-to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .\32xl\:focus\:builder-to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .\32xl\:focus\:builder-to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .\32xl\:focus\:builder-to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .\32xl\:focus\:builder-to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .\32xl\:focus\:builder-to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .\32xl\:focus\:builder-to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .\32xl\:focus\:builder-to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .\32xl\:focus\:builder-to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .\32xl\:focus\:builder-to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .\32xl\:focus\:builder-to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .\32xl\:focus\:builder-to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .\32xl\:focus\:builder-to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .\32xl\:focus\:builder-to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .\32xl\:focus\:builder-to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .\32xl\:focus\:builder-to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .\32xl\:focus\:builder-to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .\32xl\:focus\:builder-to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .\32xl\:focus\:builder-to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .\32xl\:focus\:builder-to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .\32xl\:focus\:builder-to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .\32xl\:focus\:builder-to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .\32xl\:focus\:builder-to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .\32xl\:focus\:builder-to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .\32xl\:focus\:builder-to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .\32xl\:focus\:builder-to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .\32xl\:focus\:builder-to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .\32xl\:focus\:builder-to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .\32xl\:focus\:builder-to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .\32xl\:focus\:builder-to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .\32xl\:focus\:builder-to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .\32xl\:focus\:builder-to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .\32xl\:focus\:builder-to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .\32xl\:focus\:builder-to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .\32xl\:focus\:builder-to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .\32xl\:focus\:builder-to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .\32xl\:focus\:builder-to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .\32xl\:focus\:builder-to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .\32xl\:focus\:builder-to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .\32xl\:focus\:builder-to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .\32xl\:focus\:builder-to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .\32xl\:focus\:builder-to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .\32xl\:focus\:builder-to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .\32xl\:focus\:builder-to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .\32xl\:focus\:builder-to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .\32xl\:focus\:builder-to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .\32xl\:focus\:builder-to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .\32xl\:focus\:builder-to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .\32xl\:focus\:builder-to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .\32xl\:focus\:builder-to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .\32xl\:focus\:builder-to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .\32xl\:focus\:builder-to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .\32xl\:focus\:builder-to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .\32xl\:focus\:builder-to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .\32xl\:focus\:builder-to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .\32xl\:focus\:builder-to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .\32xl\:focus\:builder-to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .\32xl\:focus\:builder-to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .\32xl\:focus\:builder-to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .\32xl\:focus\:builder-to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .\32xl\:focus\:builder-to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .\32xl\:focus\:builder-to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .\32xl\:focus\:builder-to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .\32xl\:focus\:builder-to-primary:focus{
    --tw-gradient-to: #2d375a !important;
  }

  .\32xl\:focus\:builder-to-secondary-dark:focus{
    --tw-gradient-to: #343434 !important;
  }

  .\32xl\:focus\:builder-to-secondary-darker:focus{
    --tw-gradient-to: #212121 !important;
  }

  .\32xl\:builder-decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .\32xl\:builder-decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .\32xl\:builder-bg-auto{
    background-size: auto !important;
  }

  .\32xl\:builder-bg-cover{
    background-size: cover !important;
  }

  .\32xl\:builder-bg-contain{
    background-size: contain !important;
  }

  .\32xl\:builder-bg-fixed{
    background-attachment: fixed !important;
  }

  .\32xl\:builder-bg-local{
    background-attachment: local !important;
  }

  .\32xl\:builder-bg-scroll{
    background-attachment: scroll !important;
  }

  .\32xl\:builder-bg-clip-border{
    background-clip: border-box !important;
  }

  .\32xl\:builder-bg-clip-padding{
    background-clip: padding-box !important;
  }

  .\32xl\:builder-bg-clip-content{
    background-clip: content-box !important;
  }

  .\32xl\:builder-bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .\32xl\:builder-bg-bottom{
    background-position: bottom !important;
  }

  .\32xl\:builder-bg-center{
    background-position: center !important;
  }

  .\32xl\:builder-bg-left{
    background-position: left !important;
  }

  .\32xl\:builder-bg-left-bottom{
    background-position: left bottom !important;
  }

  .\32xl\:builder-bg-left-top{
    background-position: left top !important;
  }

  .\32xl\:builder-bg-right{
    background-position: right !important;
  }

  .\32xl\:builder-bg-right-bottom{
    background-position: right bottom !important;
  }

  .\32xl\:builder-bg-right-top{
    background-position: right top !important;
  }

  .\32xl\:builder-bg-top{
    background-position: top !important;
  }

  .\32xl\:builder-bg-repeat{
    background-repeat: repeat !important;
  }

  .\32xl\:builder-bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .\32xl\:builder-bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .\32xl\:builder-bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .\32xl\:builder-bg-repeat-round{
    background-repeat: round !important;
  }

  .\32xl\:builder-bg-repeat-space{
    background-repeat: space !important;
  }

  .\32xl\:builder-bg-origin-border{
    background-origin: border-box !important;
  }

  .\32xl\:builder-bg-origin-padding{
    background-origin: padding-box !important;
  }

  .\32xl\:builder-bg-origin-content{
    background-origin: content-box !important;
  }

  .\32xl\:builder-fill-current{
    fill: currentColor !important;
  }

  .\32xl\:builder-stroke-current{
    stroke: currentColor !important;
  }

  .\32xl\:builder-stroke-0{
    stroke-width: 0 !important;
  }

  .\32xl\:builder-stroke-1{
    stroke-width: 1 !important;
  }

  .\32xl\:builder-stroke-2{
    stroke-width: 2 !important;
  }

  .\32xl\:builder-object-contain{
    object-fit: contain !important;
  }

  .\32xl\:builder-object-cover{
    object-fit: cover !important;
  }

  .\32xl\:builder-object-fill{
    object-fit: fill !important;
  }

  .\32xl\:builder-object-none{
    object-fit: none !important;
  }

  .\32xl\:builder-object-scale-down{
    object-fit: scale-down !important;
  }

  .\32xl\:builder-object-bottom{
    object-position: bottom !important;
  }

  .\32xl\:builder-object-center{
    object-position: center !important;
  }

  .\32xl\:builder-object-left{
    object-position: left !important;
  }

  .\32xl\:builder-object-left-bottom{
    object-position: left bottom !important;
  }

  .\32xl\:builder-object-left-top{
    object-position: left top !important;
  }

  .\32xl\:builder-object-right{
    object-position: right !important;
  }

  .\32xl\:builder-object-right-bottom{
    object-position: right bottom !important;
  }

  .\32xl\:builder-object-right-top{
    object-position: right top !important;
  }

  .\32xl\:builder-object-top{
    object-position: top !important;
  }

  .\32xl\:builder-p-0{
    padding: 0px !important;
  }

  .\32xl\:builder-p-1{
    padding: 0.25rem !important;
  }

  .\32xl\:builder-p-2{
    padding: 0.5rem !important;
  }

  .\32xl\:builder-p-3{
    padding: 0.75rem !important;
  }

  .\32xl\:builder-p-4{
    padding: 1rem !important;
  }

  .\32xl\:builder-p-5{
    padding: 1.25rem !important;
  }

  .\32xl\:builder-p-6{
    padding: 1.5rem !important;
  }

  .\32xl\:builder-p-7{
    padding: 1.75rem !important;
  }

  .\32xl\:builder-p-8{
    padding: 2rem !important;
  }

  .\32xl\:builder-p-9{
    padding: 2.25rem !important;
  }

  .\32xl\:builder-p-10{
    padding: 2.5rem !important;
  }

  .\32xl\:builder-p-11{
    padding: 2.75rem !important;
  }

  .\32xl\:builder-p-12{
    padding: 3rem !important;
  }

  .\32xl\:builder-p-14{
    padding: 3.5rem !important;
  }

  .\32xl\:builder-p-16{
    padding: 4rem !important;
  }

  .\32xl\:builder-p-20{
    padding: 5rem !important;
  }

  .\32xl\:builder-p-24{
    padding: 6rem !important;
  }

  .\32xl\:builder-p-28{
    padding: 7rem !important;
  }

  .\32xl\:builder-p-32{
    padding: 8rem !important;
  }

  .\32xl\:builder-p-36{
    padding: 9rem !important;
  }

  .\32xl\:builder-p-40{
    padding: 10rem !important;
  }

  .\32xl\:builder-p-44{
    padding: 11rem !important;
  }

  .\32xl\:builder-p-48{
    padding: 12rem !important;
  }

  .\32xl\:builder-p-52{
    padding: 13rem !important;
  }

  .\32xl\:builder-p-56{
    padding: 14rem !important;
  }

  .\32xl\:builder-p-60{
    padding: 15rem !important;
  }

  .\32xl\:builder-p-64{
    padding: 16rem !important;
  }

  .\32xl\:builder-p-72{
    padding: 18rem !important;
  }

  .\32xl\:builder-p-80{
    padding: 20rem !important;
  }

  .\32xl\:builder-p-96{
    padding: 24rem !important;
  }

  .\32xl\:builder-p-px{
    padding: 1px !important;
  }

  .\32xl\:builder-p-0\.5{
    padding: 0.125rem !important;
  }

  .\32xl\:builder-p-1\.5{
    padding: 0.375rem !important;
  }

  .\32xl\:builder-p-2\.5{
    padding: 0.625rem !important;
  }

  .\32xl\:builder-p-3\.5{
    padding: 0.875rem !important;
  }

  .\32xl\:builder-px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .\32xl\:builder-px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .\32xl\:builder-px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .\32xl\:builder-px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .\32xl\:builder-px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .\32xl\:builder-px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .\32xl\:builder-px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .\32xl\:builder-px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .\32xl\:builder-px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .\32xl\:builder-px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .\32xl\:builder-px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .\32xl\:builder-px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .\32xl\:builder-px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .\32xl\:builder-px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .\32xl\:builder-px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .\32xl\:builder-px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .\32xl\:builder-px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .\32xl\:builder-px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .\32xl\:builder-px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .\32xl\:builder-px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .\32xl\:builder-px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .\32xl\:builder-px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .\32xl\:builder-px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .\32xl\:builder-px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .\32xl\:builder-px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .\32xl\:builder-px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .\32xl\:builder-px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .\32xl\:builder-px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .\32xl\:builder-px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .\32xl\:builder-px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .\32xl\:builder-px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .\32xl\:builder-px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .\32xl\:builder-px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .\32xl\:builder-px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .\32xl\:builder-px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .\32xl\:builder-py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .\32xl\:builder-py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .\32xl\:builder-py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .\32xl\:builder-py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .\32xl\:builder-py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .\32xl\:builder-py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .\32xl\:builder-py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .\32xl\:builder-py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .\32xl\:builder-py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .\32xl\:builder-py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .\32xl\:builder-py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .\32xl\:builder-py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .\32xl\:builder-py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .\32xl\:builder-py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .\32xl\:builder-py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .\32xl\:builder-py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .\32xl\:builder-py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .\32xl\:builder-py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .\32xl\:builder-py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .\32xl\:builder-py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .\32xl\:builder-py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .\32xl\:builder-py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .\32xl\:builder-py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .\32xl\:builder-py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .\32xl\:builder-py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .\32xl\:builder-py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .\32xl\:builder-py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .\32xl\:builder-py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .\32xl\:builder-py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .\32xl\:builder-py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .\32xl\:builder-py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .\32xl\:builder-py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .\32xl\:builder-py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .\32xl\:builder-py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .\32xl\:builder-py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .\32xl\:builder-pt-0{
    padding-top: 0px !important;
  }

  .\32xl\:builder-pt-1{
    padding-top: 0.25rem !important;
  }

  .\32xl\:builder-pt-2{
    padding-top: 0.5rem !important;
  }

  .\32xl\:builder-pt-3{
    padding-top: 0.75rem !important;
  }

  .\32xl\:builder-pt-4{
    padding-top: 1rem !important;
  }

  .\32xl\:builder-pt-5{
    padding-top: 1.25rem !important;
  }

  .\32xl\:builder-pt-6{
    padding-top: 1.5rem !important;
  }

  .\32xl\:builder-pt-7{
    padding-top: 1.75rem !important;
  }

  .\32xl\:builder-pt-8{
    padding-top: 2rem !important;
  }

  .\32xl\:builder-pt-9{
    padding-top: 2.25rem !important;
  }

  .\32xl\:builder-pt-10{
    padding-top: 2.5rem !important;
  }

  .\32xl\:builder-pt-11{
    padding-top: 2.75rem !important;
  }

  .\32xl\:builder-pt-12{
    padding-top: 3rem !important;
  }

  .\32xl\:builder-pt-14{
    padding-top: 3.5rem !important;
  }

  .\32xl\:builder-pt-16{
    padding-top: 4rem !important;
  }

  .\32xl\:builder-pt-20{
    padding-top: 5rem !important;
  }

  .\32xl\:builder-pt-24{
    padding-top: 6rem !important;
  }

  .\32xl\:builder-pt-28{
    padding-top: 7rem !important;
  }

  .\32xl\:builder-pt-32{
    padding-top: 8rem !important;
  }

  .\32xl\:builder-pt-36{
    padding-top: 9rem !important;
  }

  .\32xl\:builder-pt-40{
    padding-top: 10rem !important;
  }

  .\32xl\:builder-pt-44{
    padding-top: 11rem !important;
  }

  .\32xl\:builder-pt-48{
    padding-top: 12rem !important;
  }

  .\32xl\:builder-pt-52{
    padding-top: 13rem !important;
  }

  .\32xl\:builder-pt-56{
    padding-top: 14rem !important;
  }

  .\32xl\:builder-pt-60{
    padding-top: 15rem !important;
  }

  .\32xl\:builder-pt-64{
    padding-top: 16rem !important;
  }

  .\32xl\:builder-pt-72{
    padding-top: 18rem !important;
  }

  .\32xl\:builder-pt-80{
    padding-top: 20rem !important;
  }

  .\32xl\:builder-pt-96{
    padding-top: 24rem !important;
  }

  .\32xl\:builder-pt-px{
    padding-top: 1px !important;
  }

  .\32xl\:builder-pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .\32xl\:builder-pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .\32xl\:builder-pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .\32xl\:builder-pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .\32xl\:builder-pr-0{
    padding-right: 0px !important;
  }

  .\32xl\:builder-pr-1{
    padding-right: 0.25rem !important;
  }

  .\32xl\:builder-pr-2{
    padding-right: 0.5rem !important;
  }

  .\32xl\:builder-pr-3{
    padding-right: 0.75rem !important;
  }

  .\32xl\:builder-pr-4{
    padding-right: 1rem !important;
  }

  .\32xl\:builder-pr-5{
    padding-right: 1.25rem !important;
  }

  .\32xl\:builder-pr-6{
    padding-right: 1.5rem !important;
  }

  .\32xl\:builder-pr-7{
    padding-right: 1.75rem !important;
  }

  .\32xl\:builder-pr-8{
    padding-right: 2rem !important;
  }

  .\32xl\:builder-pr-9{
    padding-right: 2.25rem !important;
  }

  .\32xl\:builder-pr-10{
    padding-right: 2.5rem !important;
  }

  .\32xl\:builder-pr-11{
    padding-right: 2.75rem !important;
  }

  .\32xl\:builder-pr-12{
    padding-right: 3rem !important;
  }

  .\32xl\:builder-pr-14{
    padding-right: 3.5rem !important;
  }

  .\32xl\:builder-pr-16{
    padding-right: 4rem !important;
  }

  .\32xl\:builder-pr-20{
    padding-right: 5rem !important;
  }

  .\32xl\:builder-pr-24{
    padding-right: 6rem !important;
  }

  .\32xl\:builder-pr-28{
    padding-right: 7rem !important;
  }

  .\32xl\:builder-pr-32{
    padding-right: 8rem !important;
  }

  .\32xl\:builder-pr-36{
    padding-right: 9rem !important;
  }

  .\32xl\:builder-pr-40{
    padding-right: 10rem !important;
  }

  .\32xl\:builder-pr-44{
    padding-right: 11rem !important;
  }

  .\32xl\:builder-pr-48{
    padding-right: 12rem !important;
  }

  .\32xl\:builder-pr-52{
    padding-right: 13rem !important;
  }

  .\32xl\:builder-pr-56{
    padding-right: 14rem !important;
  }

  .\32xl\:builder-pr-60{
    padding-right: 15rem !important;
  }

  .\32xl\:builder-pr-64{
    padding-right: 16rem !important;
  }

  .\32xl\:builder-pr-72{
    padding-right: 18rem !important;
  }

  .\32xl\:builder-pr-80{
    padding-right: 20rem !important;
  }

  .\32xl\:builder-pr-96{
    padding-right: 24rem !important;
  }

  .\32xl\:builder-pr-px{
    padding-right: 1px !important;
  }

  .\32xl\:builder-pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .\32xl\:builder-pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .\32xl\:builder-pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .\32xl\:builder-pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .\32xl\:builder-pb-0{
    padding-bottom: 0px !important;
  }

  .\32xl\:builder-pb-1{
    padding-bottom: 0.25rem !important;
  }

  .\32xl\:builder-pb-2{
    padding-bottom: 0.5rem !important;
  }

  .\32xl\:builder-pb-3{
    padding-bottom: 0.75rem !important;
  }

  .\32xl\:builder-pb-4{
    padding-bottom: 1rem !important;
  }

  .\32xl\:builder-pb-5{
    padding-bottom: 1.25rem !important;
  }

  .\32xl\:builder-pb-6{
    padding-bottom: 1.5rem !important;
  }

  .\32xl\:builder-pb-7{
    padding-bottom: 1.75rem !important;
  }

  .\32xl\:builder-pb-8{
    padding-bottom: 2rem !important;
  }

  .\32xl\:builder-pb-9{
    padding-bottom: 2.25rem !important;
  }

  .\32xl\:builder-pb-10{
    padding-bottom: 2.5rem !important;
  }

  .\32xl\:builder-pb-11{
    padding-bottom: 2.75rem !important;
  }

  .\32xl\:builder-pb-12{
    padding-bottom: 3rem !important;
  }

  .\32xl\:builder-pb-14{
    padding-bottom: 3.5rem !important;
  }

  .\32xl\:builder-pb-16{
    padding-bottom: 4rem !important;
  }

  .\32xl\:builder-pb-20{
    padding-bottom: 5rem !important;
  }

  .\32xl\:builder-pb-24{
    padding-bottom: 6rem !important;
  }

  .\32xl\:builder-pb-28{
    padding-bottom: 7rem !important;
  }

  .\32xl\:builder-pb-32{
    padding-bottom: 8rem !important;
  }

  .\32xl\:builder-pb-36{
    padding-bottom: 9rem !important;
  }

  .\32xl\:builder-pb-40{
    padding-bottom: 10rem !important;
  }

  .\32xl\:builder-pb-44{
    padding-bottom: 11rem !important;
  }

  .\32xl\:builder-pb-48{
    padding-bottom: 12rem !important;
  }

  .\32xl\:builder-pb-52{
    padding-bottom: 13rem !important;
  }

  .\32xl\:builder-pb-56{
    padding-bottom: 14rem !important;
  }

  .\32xl\:builder-pb-60{
    padding-bottom: 15rem !important;
  }

  .\32xl\:builder-pb-64{
    padding-bottom: 16rem !important;
  }

  .\32xl\:builder-pb-72{
    padding-bottom: 18rem !important;
  }

  .\32xl\:builder-pb-80{
    padding-bottom: 20rem !important;
  }

  .\32xl\:builder-pb-96{
    padding-bottom: 24rem !important;
  }

  .\32xl\:builder-pb-px{
    padding-bottom: 1px !important;
  }

  .\32xl\:builder-pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .\32xl\:builder-pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .\32xl\:builder-pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .\32xl\:builder-pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .\32xl\:builder-pl-0{
    padding-left: 0px !important;
  }

  .\32xl\:builder-pl-1{
    padding-left: 0.25rem !important;
  }

  .\32xl\:builder-pl-2{
    padding-left: 0.5rem !important;
  }

  .\32xl\:builder-pl-3{
    padding-left: 0.75rem !important;
  }

  .\32xl\:builder-pl-4{
    padding-left: 1rem !important;
  }

  .\32xl\:builder-pl-5{
    padding-left: 1.25rem !important;
  }

  .\32xl\:builder-pl-6{
    padding-left: 1.5rem !important;
  }

  .\32xl\:builder-pl-7{
    padding-left: 1.75rem !important;
  }

  .\32xl\:builder-pl-8{
    padding-left: 2rem !important;
  }

  .\32xl\:builder-pl-9{
    padding-left: 2.25rem !important;
  }

  .\32xl\:builder-pl-10{
    padding-left: 2.5rem !important;
  }

  .\32xl\:builder-pl-11{
    padding-left: 2.75rem !important;
  }

  .\32xl\:builder-pl-12{
    padding-left: 3rem !important;
  }

  .\32xl\:builder-pl-14{
    padding-left: 3.5rem !important;
  }

  .\32xl\:builder-pl-16{
    padding-left: 4rem !important;
  }

  .\32xl\:builder-pl-20{
    padding-left: 5rem !important;
  }

  .\32xl\:builder-pl-24{
    padding-left: 6rem !important;
  }

  .\32xl\:builder-pl-28{
    padding-left: 7rem !important;
  }

  .\32xl\:builder-pl-32{
    padding-left: 8rem !important;
  }

  .\32xl\:builder-pl-36{
    padding-left: 9rem !important;
  }

  .\32xl\:builder-pl-40{
    padding-left: 10rem !important;
  }

  .\32xl\:builder-pl-44{
    padding-left: 11rem !important;
  }

  .\32xl\:builder-pl-48{
    padding-left: 12rem !important;
  }

  .\32xl\:builder-pl-52{
    padding-left: 13rem !important;
  }

  .\32xl\:builder-pl-56{
    padding-left: 14rem !important;
  }

  .\32xl\:builder-pl-60{
    padding-left: 15rem !important;
  }

  .\32xl\:builder-pl-64{
    padding-left: 16rem !important;
  }

  .\32xl\:builder-pl-72{
    padding-left: 18rem !important;
  }

  .\32xl\:builder-pl-80{
    padding-left: 20rem !important;
  }

  .\32xl\:builder-pl-96{
    padding-left: 24rem !important;
  }

  .\32xl\:builder-pl-px{
    padding-left: 1px !important;
  }

  .\32xl\:builder-pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .\32xl\:builder-pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .\32xl\:builder-pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .\32xl\:builder-pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .\32xl\:builder-text-left{
    text-align: left !important;
  }

  .\32xl\:builder-text-center{
    text-align: center !important;
  }

  .\32xl\:builder-text-right{
    text-align: right !important;
  }

  .\32xl\:builder-text-justify{
    text-align: justify !important;
  }

  .\32xl\:builder-align-baseline{
    vertical-align: baseline !important;
  }

  .\32xl\:builder-align-top{
    vertical-align: top !important;
  }

  .\32xl\:builder-align-middle{
    vertical-align: middle !important;
  }

  .\32xl\:builder-align-bottom{
    vertical-align: bottom !important;
  }

  .\32xl\:builder-align-text-top{
    vertical-align: text-top !important;
  }

  .\32xl\:builder-align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .\32xl\:builder-font-primary{
    font-family: proxima-nova !important;
  }

  .\32xl\:builder-text-sm{
    font-size: 14px !important;
  }

  .\32xl\:builder-text-md{
    font-size: 15px !important;
  }

  .\32xl\:builder-text-2xl{
    font-size: 24px !important;
  }

  .\32xl\:builder-font-thin{
    font-weight: 100 !important;
  }

  .\32xl\:builder-font-extralight{
    font-weight: 200 !important;
  }

  .\32xl\:builder-font-light{
    font-weight: 300 !important;
  }

  .\32xl\:builder-font-normal{
    font-weight: 400 !important;
  }

  .\32xl\:builder-font-medium{
    font-weight: 500 !important;
  }

  .\32xl\:builder-font-semibold{
    font-weight: 600 !important;
  }

  .\32xl\:builder-font-bold{
    font-weight: 700 !important;
  }

  .\32xl\:builder-font-extrabold{
    font-weight: 800 !important;
  }

  .\32xl\:builder-font-black{
    font-weight: 900 !important;
  }

  .\32xl\:builder-uppercase{
    text-transform: uppercase !important;
  }

  .\32xl\:builder-lowercase{
    text-transform: lowercase !important;
  }

  .\32xl\:builder-capitalize{
    text-transform: capitalize !important;
  }

  .\32xl\:builder-normal-case{
    text-transform: none !important;
  }

  .\32xl\:builder-italic{
    font-style: italic !important;
  }

  .\32xl\:builder-not-italic{
    font-style: normal !important;
  }

  .\32xl\:builder-ordinal, .\32xl\:builder-slashed-zero, .\32xl\:builder-lining-nums, .\32xl\:builder-oldstyle-nums, .\32xl\:builder-proportional-nums, .\32xl\:builder-tabular-nums, .\32xl\:builder-diagonal-fractions, .\32xl\:builder-stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .\32xl\:builder-normal-nums{
    font-variant-numeric: normal !important;
  }

  .\32xl\:builder-ordinal{
    --tw-ordinal: ordinal !important;
  }

  .\32xl\:builder-slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .\32xl\:builder-lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .\32xl\:builder-oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .\32xl\:builder-proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .\32xl\:builder-tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .\32xl\:builder-diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .\32xl\:builder-stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .\32xl\:builder-leading-3{
    line-height: .75rem !important;
  }

  .\32xl\:builder-leading-4{
    line-height: 1rem !important;
  }

  .\32xl\:builder-leading-5{
    line-height: 1.25rem !important;
  }

  .\32xl\:builder-leading-6{
    line-height: 1.5rem !important;
  }

  .\32xl\:builder-leading-7{
    line-height: 1.75rem !important;
  }

  .\32xl\:builder-leading-8{
    line-height: 2rem !important;
  }

  .\32xl\:builder-leading-9{
    line-height: 2.25rem !important;
  }

  .\32xl\:builder-leading-10{
    line-height: 2.5rem !important;
  }

  .\32xl\:builder-leading-none{
    line-height: 1 !important;
  }

  .\32xl\:builder-leading-tight{
    line-height: 1.25 !important;
  }

  .\32xl\:builder-leading-snug{
    line-height: 1.375 !important;
  }

  .\32xl\:builder-leading-normal{
    line-height: 1.5 !important;
  }

  .\32xl\:builder-leading-relaxed{
    line-height: 1.625 !important;
  }

  .\32xl\:builder-leading-loose{
    line-height: 2 !important;
  }

  .\32xl\:builder-tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .\32xl\:builder-tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .\32xl\:builder-tracking-normal{
    letter-spacing: 0em !important;
  }

  .\32xl\:builder-tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .\32xl\:builder-tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .\32xl\:builder-tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .\32xl\:builder-text-transparent{
    color: transparent !important;
  }

  .\32xl\:builder-text-current{
    color: currentColor !important;
  }

  .\32xl\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-transparent{
    color: transparent !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-current{
    color: currentColor !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-primary{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-secondary-dark{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-secondary-darker{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-transparent:focus-within{
    color: transparent !important;
  }

  .\32xl\:focus-within\:builder-text-current:focus-within{
    color: currentColor !important;
  }

  .\32xl\:focus-within\:builder-text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-primary:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-secondary-dark:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-text-secondary-darker:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-transparent:hover{
    color: transparent !important;
  }

  .\32xl\:hover\:builder-text-current:hover{
    color: currentColor !important;
  }

  .\32xl\:hover\:builder-text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-primary:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-secondary-dark:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:builder-text-secondary-darker:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-transparent:focus{
    color: transparent !important;
  }

  .\32xl\:focus\:builder-text-current:focus{
    color: currentColor !important;
  }

  .\32xl\:focus\:builder-text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-primary:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-secondary-dark:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:builder-text-secondary-darker:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-text-opacity)) !important;
  }

  .\32xl\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:builder-text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:hover\:builder-text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:hover\:builder-text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:hover\:builder-text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:hover\:builder-text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:hover\:builder-text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:hover\:builder-text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:hover\:builder-text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:hover\:builder-text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:hover\:builder-text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:hover\:builder-text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:hover\:builder-text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:hover\:builder-text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:hover\:builder-text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:hover\:builder-text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:hover\:builder-text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:focus\:builder-text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:focus\:builder-text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:focus\:builder-text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:focus\:builder-text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:focus\:builder-text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:focus\:builder-text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:focus\:builder-text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:focus\:builder-text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:focus\:builder-text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:focus\:builder-text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:focus\:builder-text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:focus\:builder-text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:focus\:builder-text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:focus\:builder-text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:focus\:builder-text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:builder-underline{
    text-decoration: underline !important;
  }

  .\32xl\:builder-line-through{
    text-decoration: line-through !important;
  }

  .\32xl\:builder-no-underline{
    text-decoration: none !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-underline{
    text-decoration: underline !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-line-through{
    text-decoration: line-through !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-no-underline{
    text-decoration: none !important;
  }

  .\32xl\:focus-within\:builder-underline:focus-within{
    text-decoration: underline !important;
  }

  .\32xl\:focus-within\:builder-line-through:focus-within{
    text-decoration: line-through !important;
  }

  .\32xl\:focus-within\:builder-no-underline:focus-within{
    text-decoration: none !important;
  }

  .\32xl\:hover\:builder-underline:hover{
    text-decoration: underline !important;
  }

  .\32xl\:hover\:builder-line-through:hover{
    text-decoration: line-through !important;
  }

  .\32xl\:hover\:builder-no-underline:hover{
    text-decoration: none !important;
  }

  .\32xl\:focus\:builder-underline:focus{
    text-decoration: underline !important;
  }

  .\32xl\:focus\:builder-line-through:focus{
    text-decoration: line-through !important;
  }

  .\32xl\:focus\:builder-no-underline:focus{
    text-decoration: none !important;
  }

  .\32xl\:builder-antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .\32xl\:builder-subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .\32xl\:builder-placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .\32xl\:builder-placeholder-current::placeholder{
    color: currentColor !important;
  }

  .\32xl\:builder-placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-primary::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-secondary-dark::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-secondary-darker::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .\32xl\:focus\:builder-placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .\32xl\:focus\:builder-placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-primary:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(45, 55, 90, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-secondary-dark:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 52, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:builder-placeholder-secondary-darker:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(33, 33, 33, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:builder-placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:builder-placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:builder-placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:builder-placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:builder-placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:builder-placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:builder-placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:builder-placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:builder-placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:builder-placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:builder-placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:builder-placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:builder-placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:builder-placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:builder-placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:focus\:builder-placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:builder-opacity-0{
    opacity: 0 !important;
  }

  .\32xl\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .\32xl\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .\32xl\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .\32xl\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .\32xl\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .\32xl\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .\32xl\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .\32xl\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .\32xl\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .\32xl\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .\32xl\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .\32xl\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .\32xl\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .\32xl\:builder-opacity-100{
    opacity: 1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-0{
    opacity: 0 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-5{
    opacity: 0.05 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-10{
    opacity: 0.1 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-20{
    opacity: 0.2 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-25{
    opacity: 0.25 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-30{
    opacity: 0.3 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-40{
    opacity: 0.4 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-50{
    opacity: 0.5 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-60{
    opacity: 0.6 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-70{
    opacity: 0.7 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-75{
    opacity: 0.75 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-80{
    opacity: 0.8 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-90{
    opacity: 0.9 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-95{
    opacity: 0.95 !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-opacity-100{
    opacity: 1 !important;
  }

  .\32xl\:focus-within\:builder-opacity-0:focus-within{
    opacity: 0 !important;
  }

  .\32xl\:focus-within\:builder-opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:builder-opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:builder-opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:builder-opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:builder-opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:builder-opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:builder-opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:builder-opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:builder-opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:builder-opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:builder-opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:builder-opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:builder-opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:builder-opacity-100:focus-within{
    opacity: 1 !important;
  }

  .\32xl\:hover\:builder-opacity-0:hover{
    opacity: 0 !important;
  }

  .\32xl\:hover\:builder-opacity-5:hover{
    opacity: 0.05 !important;
  }

  .\32xl\:hover\:builder-opacity-10:hover{
    opacity: 0.1 !important;
  }

  .\32xl\:hover\:builder-opacity-20:hover{
    opacity: 0.2 !important;
  }

  .\32xl\:hover\:builder-opacity-25:hover{
    opacity: 0.25 !important;
  }

  .\32xl\:hover\:builder-opacity-30:hover{
    opacity: 0.3 !important;
  }

  .\32xl\:hover\:builder-opacity-40:hover{
    opacity: 0.4 !important;
  }

  .\32xl\:hover\:builder-opacity-50:hover{
    opacity: 0.5 !important;
  }

  .\32xl\:hover\:builder-opacity-60:hover{
    opacity: 0.6 !important;
  }

  .\32xl\:hover\:builder-opacity-70:hover{
    opacity: 0.7 !important;
  }

  .\32xl\:hover\:builder-opacity-75:hover{
    opacity: 0.75 !important;
  }

  .\32xl\:hover\:builder-opacity-80:hover{
    opacity: 0.8 !important;
  }

  .\32xl\:hover\:builder-opacity-90:hover{
    opacity: 0.9 !important;
  }

  .\32xl\:hover\:builder-opacity-95:hover{
    opacity: 0.95 !important;
  }

  .\32xl\:hover\:builder-opacity-100:hover{
    opacity: 1 !important;
  }

  .\32xl\:focus\:builder-opacity-0:focus{
    opacity: 0 !important;
  }

  .\32xl\:focus\:builder-opacity-5:focus{
    opacity: 0.05 !important;
  }

  .\32xl\:focus\:builder-opacity-10:focus{
    opacity: 0.1 !important;
  }

  .\32xl\:focus\:builder-opacity-20:focus{
    opacity: 0.2 !important;
  }

  .\32xl\:focus\:builder-opacity-25:focus{
    opacity: 0.25 !important;
  }

  .\32xl\:focus\:builder-opacity-30:focus{
    opacity: 0.3 !important;
  }

  .\32xl\:focus\:builder-opacity-40:focus{
    opacity: 0.4 !important;
  }

  .\32xl\:focus\:builder-opacity-50:focus{
    opacity: 0.5 !important;
  }

  .\32xl\:focus\:builder-opacity-60:focus{
    opacity: 0.6 !important;
  }

  .\32xl\:focus\:builder-opacity-70:focus{
    opacity: 0.7 !important;
  }

  .\32xl\:focus\:builder-opacity-75:focus{
    opacity: 0.75 !important;
  }

  .\32xl\:focus\:builder-opacity-80:focus{
    opacity: 0.8 !important;
  }

  .\32xl\:focus\:builder-opacity-90:focus{
    opacity: 0.9 !important;
  }

  .\32xl\:focus\:builder-opacity-95:focus{
    opacity: 0.95 !important;
  }

  .\32xl\:focus\:builder-opacity-100:focus{
    opacity: 1 !important;
  }

  .\32xl\:builder-bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .\32xl\:builder-bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .\32xl\:builder-bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .\32xl\:builder-bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .\32xl\:builder-bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .\32xl\:builder-bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .\32xl\:builder-bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .\32xl\:builder-bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .\32xl\:builder-bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .\32xl\:builder-bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .\32xl\:builder-bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .\32xl\:builder-bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .\32xl\:builder-bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .\32xl\:builder-bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .\32xl\:builder-bg-blend-color{
    background-blend-mode: color !important;
  }

  .\32xl\:builder-bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .\32xl\:builder-mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .\32xl\:builder-mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .\32xl\:builder-mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .\32xl\:builder-mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .\32xl\:builder-mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .\32xl\:builder-mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .\32xl\:builder-mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .\32xl\:builder-mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .\32xl\:builder-mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .\32xl\:builder-mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .\32xl\:builder-mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .\32xl\:builder-mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .\32xl\:builder-mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .\32xl\:builder-mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .\32xl\:builder-mix-blend-color{
    mix-blend-mode: color !important;
  }

  .\32xl\:builder-mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .\32xl\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .builder-group:hover .\32xl\:group-hover\:builder-shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:builder-shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:builder-shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:builder-shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:builder-outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .\32xl\:builder-outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .\32xl\:builder-outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus-within\:builder-outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus-within\:builder-outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus-within\:builder-outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus\:builder-outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus\:builder-outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus\:builder-outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .\32xl\:builder-ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:builder-ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:builder-ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:builder-ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:builder-ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:builder-ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:builder-ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:builder-ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:builder-ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:builder-ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:builder-ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:builder-ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:builder-ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:builder-ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:builder-ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:builder-ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:builder-ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:builder-ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:builder-ring-inset{
    --tw-ring-inset: inset !important;
  }

  .\32xl\:focus-within\:builder-ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .\32xl\:focus\:builder-ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .\32xl\:builder-ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .\32xl\:builder-ring-current{
    --tw-ring-color: currentColor !important;
  }

  .\32xl\:builder-ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-primary{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-secondary-dark{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-secondary-darker{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .\32xl\:focus-within\:builder-ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .\32xl\:focus-within\:builder-ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-primary:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-secondary-dark:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:builder-ring-secondary-darker:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .\32xl\:focus\:builder-ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .\32xl\:focus\:builder-ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-primary:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(45, 55, 90, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-secondary-dark:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 52, 52, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:builder-ring-secondary-darker:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(33, 33, 33, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:builder-ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .\32xl\:builder-ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .\32xl\:builder-ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .\32xl\:builder-ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .\32xl\:builder-ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .\32xl\:builder-ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .\32xl\:builder-ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .\32xl\:builder-ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .\32xl\:builder-ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .\32xl\:builder-ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .\32xl\:builder-ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .\32xl\:builder-ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .\32xl\:builder-ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .\32xl\:builder-ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .\32xl\:builder-ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:builder-ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .\32xl\:focus\:builder-ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .\32xl\:builder-ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .\32xl\:builder-ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .\32xl\:builder-ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .\32xl\:builder-ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .\32xl\:builder-ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .\32xl\:focus\:builder-ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .\32xl\:focus\:builder-ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .\32xl\:focus\:builder-ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .\32xl\:focus\:builder-ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .\32xl\:focus\:builder-ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .\32xl\:builder-ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .\32xl\:builder-ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .\32xl\:builder-ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .\32xl\:builder-ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .\32xl\:builder-ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .\32xl\:builder-ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .\32xl\:builder-ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .\32xl\:builder-ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .\32xl\:builder-ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .\32xl\:builder-ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .\32xl\:builder-ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .\32xl\:builder-ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .\32xl\:builder-ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .\32xl\:builder-ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .\32xl\:builder-ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .\32xl\:builder-ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .\32xl\:builder-ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .\32xl\:builder-ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .\32xl\:builder-ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .\32xl\:builder-ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .\32xl\:builder-ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .\32xl\:builder-ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .\32xl\:builder-ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .\32xl\:builder-ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .\32xl\:builder-ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .\32xl\:builder-ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .\32xl\:builder-ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .\32xl\:builder-ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .\32xl\:builder-ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .\32xl\:builder-ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .\32xl\:builder-ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .\32xl\:builder-ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .\32xl\:builder-ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .\32xl\:builder-ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .\32xl\:builder-ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .\32xl\:builder-ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .\32xl\:builder-ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .\32xl\:builder-ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .\32xl\:builder-ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .\32xl\:builder-ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .\32xl\:builder-ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .\32xl\:builder-ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .\32xl\:builder-ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .\32xl\:builder-ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .\32xl\:builder-ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .\32xl\:builder-ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .\32xl\:builder-ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .\32xl\:builder-ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .\32xl\:builder-ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .\32xl\:builder-ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .\32xl\:builder-ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .\32xl\:builder-ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .\32xl\:builder-ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .\32xl\:builder-ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .\32xl\:builder-ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .\32xl\:builder-ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .\32xl\:builder-ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .\32xl\:builder-ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .\32xl\:builder-ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .\32xl\:builder-ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .\32xl\:builder-ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .\32xl\:builder-ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .\32xl\:builder-ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .\32xl\:builder-ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .\32xl\:builder-ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .\32xl\:builder-ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .\32xl\:builder-ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .\32xl\:builder-ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .\32xl\:builder-ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .\32xl\:builder-ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .\32xl\:builder-ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .\32xl\:builder-ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .\32xl\:builder-ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .\32xl\:builder-ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .\32xl\:builder-ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .\32xl\:builder-ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .\32xl\:builder-ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .\32xl\:builder-ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .\32xl\:builder-ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .\32xl\:builder-ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .\32xl\:builder-ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .\32xl\:builder-ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .\32xl\:builder-ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .\32xl\:builder-ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .\32xl\:builder-ring-offset-primary{
    --tw-ring-offset-color: #2d375a !important;
  }

  .\32xl\:builder-ring-offset-secondary-dark{
    --tw-ring-offset-color: #343434 !important;
  }

  .\32xl\:builder-ring-offset-secondary-darker{
    --tw-ring-offset-color: #212121 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-primary:focus-within{
    --tw-ring-offset-color: #2d375a !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-secondary-dark:focus-within{
    --tw-ring-offset-color: #343434 !important;
  }

  .\32xl\:focus-within\:builder-ring-offset-secondary-darker:focus-within{
    --tw-ring-offset-color: #212121 !important;
  }

  .\32xl\:focus\:builder-ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .\32xl\:focus\:builder-ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .\32xl\:focus\:builder-ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .\32xl\:focus\:builder-ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .\32xl\:focus\:builder-ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .\32xl\:focus\:builder-ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .\32xl\:focus\:builder-ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .\32xl\:focus\:builder-ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .\32xl\:focus\:builder-ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .\32xl\:focus\:builder-ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .\32xl\:focus\:builder-ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .\32xl\:focus\:builder-ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .\32xl\:focus\:builder-ring-offset-primary:focus{
    --tw-ring-offset-color: #2d375a !important;
  }

  .\32xl\:focus\:builder-ring-offset-secondary-dark:focus{
    --tw-ring-offset-color: #343434 !important;
  }

  .\32xl\:focus\:builder-ring-offset-secondary-darker:focus{
    --tw-ring-offset-color: #212121 !important;
  }

  .\32xl\:builder-filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .\32xl\:builder-filter-none{
    filter: none !important;
  }

  .\32xl\:builder-blur-0{
    --tw-blur: blur(0) !important;
  }

  .\32xl\:builder-blur-none{
    --tw-blur: blur(0) !important;
  }

  .\32xl\:builder-blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .\32xl\:builder-blur{
    --tw-blur: blur(8px) !important;
  }

  .\32xl\:builder-blur-md{
    --tw-blur: blur(12px) !important;
  }

  .\32xl\:builder-blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .\32xl\:builder-blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .\32xl\:builder-blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .\32xl\:builder-blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .\32xl\:builder-brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .\32xl\:builder-brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .\32xl\:builder-brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .\32xl\:builder-brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .\32xl\:builder-brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .\32xl\:builder-brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .\32xl\:builder-brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .\32xl\:builder-brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .\32xl\:builder-brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .\32xl\:builder-brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .\32xl\:builder-brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .\32xl\:builder-contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .\32xl\:builder-contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .\32xl\:builder-contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .\32xl\:builder-contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .\32xl\:builder-contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .\32xl\:builder-contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .\32xl\:builder-contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .\32xl\:builder-drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .\32xl\:builder-drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .\32xl\:builder-drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .\32xl\:builder-drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .\32xl\:builder-drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .\32xl\:builder-drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .\32xl\:builder-drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .\32xl\:builder-grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .\32xl\:builder-grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .\32xl\:builder-hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .\32xl\:builder-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .\32xl\:builder-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .\32xl\:builder-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .\32xl\:builder-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .\32xl\:builder-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .\32xl\:builder--hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .\32xl\:builder--hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .\32xl\:builder--hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .\32xl\:builder--hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .\32xl\:builder--hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .\32xl\:builder-invert-0{
    --tw-invert: invert(0) !important;
  }

  .\32xl\:builder-invert{
    --tw-invert: invert(100%) !important;
  }

  .\32xl\:builder-saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .\32xl\:builder-saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .\32xl\:builder-saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .\32xl\:builder-saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .\32xl\:builder-saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .\32xl\:builder-sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .\32xl\:builder-sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .\32xl\:builder-backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .\32xl\:builder-backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .\32xl\:builder-backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .\32xl\:builder-backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .\32xl\:builder-backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .\32xl\:builder-backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .\32xl\:builder-backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .\32xl\:builder-backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .\32xl\:builder-backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .\32xl\:builder-backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .\32xl\:builder-backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .\32xl\:builder-backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .\32xl\:builder-backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .\32xl\:builder-backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .\32xl\:builder-backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .\32xl\:builder-backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .\32xl\:builder-backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .\32xl\:builder-backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .\32xl\:builder-backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .\32xl\:builder-backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .\32xl\:builder-backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .\32xl\:builder-backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .\32xl\:builder-backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .\32xl\:builder-backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .\32xl\:builder-backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .\32xl\:builder-backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .\32xl\:builder-backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .\32xl\:builder-backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .\32xl\:builder-backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .\32xl\:builder-backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .\32xl\:builder-backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .\32xl\:builder-backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .\32xl\:builder-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .\32xl\:builder-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .\32xl\:builder-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .\32xl\:builder-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .\32xl\:builder-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .\32xl\:builder--backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .\32xl\:builder--backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .\32xl\:builder--backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .\32xl\:builder--backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .\32xl\:builder--backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .\32xl\:builder-backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .\32xl\:builder-backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .\32xl\:builder-backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .\32xl\:builder-backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .\32xl\:builder-backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .\32xl\:builder-backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .\32xl\:builder-backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .\32xl\:builder-backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .\32xl\:builder-backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .\32xl\:builder-backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .\32xl\:builder-backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .\32xl\:builder-backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .\32xl\:builder-backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .\32xl\:builder-backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .\32xl\:builder-backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .\32xl\:builder-backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .\32xl\:builder-backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .\32xl\:builder-backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .\32xl\:builder-backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .\32xl\:builder-backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .\32xl\:builder-backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .\32xl\:builder-backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .\32xl\:builder-backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .\32xl\:builder-backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .\32xl\:builder-transition-none{
    transition-property: none !important;
  }

  .\32xl\:builder-transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-delay-75{
    transition-delay: 75ms !important;
  }

  .\32xl\:builder-delay-100{
    transition-delay: 100ms !important;
  }

  .\32xl\:builder-delay-150{
    transition-delay: 150ms !important;
  }

  .\32xl\:builder-delay-200{
    transition-delay: 200ms !important;
  }

  .\32xl\:builder-delay-300{
    transition-delay: 300ms !important;
  }

  .\32xl\:builder-delay-500{
    transition-delay: 500ms !important;
  }

  .\32xl\:builder-delay-700{
    transition-delay: 700ms !important;
  }

  .\32xl\:builder-delay-1000{
    transition-delay: 1000ms !important;
  }

  .\32xl\:builder-duration-75{
    transition-duration: 75ms !important;
  }

  .\32xl\:builder-duration-100{
    transition-duration: 100ms !important;
  }

  .\32xl\:builder-duration-150{
    transition-duration: 150ms !important;
  }

  .\32xl\:builder-duration-200{
    transition-duration: 200ms !important;
  }

  .\32xl\:builder-duration-300{
    transition-duration: 300ms !important;
  }

  .\32xl\:builder-duration-500{
    transition-duration: 500ms !important;
  }

  .\32xl\:builder-duration-700{
    transition-duration: 700ms !important;
  }

  .\32xl\:builder-duration-1000{
    transition-duration: 1000ms !important;
  }

  .\32xl\:builder-ease-linear{
    transition-timing-function: linear !important;
  }

  .\32xl\:builder-ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .\32xl\:builder-ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .\32xl\:builder-ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}
